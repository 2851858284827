import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { CompanyApiService, DynamicFieldService, JobApiService, UserApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogConfig } from '../../../shared/components/dialog/dialog-config';
import { DialogRef } from '../../../shared/components/dialog/dialog-ref';

@Component({
  selector: 'ag-job-create-dialog',
  templateUrl: './job-create-dialog.component.html',
  styleUrls: ['./job-create-dialog.component.scss'],
})
export class JobCreateDialogComponent implements OnInit, OnDestroy {
  meta: FormMeta;
  readonly model = { departmentId: null, enterprise: null };
  validity = false;
  inClose = false;

  confirmSubscription: Subscription;

  constructor(
    public readonly dialog: DialogRef,
    private config: DialogConfig<{ departmentId?: number; enterpriseId?: number }>,
    private jobService: JobApiService,
    private dynamicService: DynamicFieldService,
    private companyService: CompanyApiService,
    private userService: UserApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    if (this.config && this.config.data) {
      this.model.departmentId = this.config.data.departmentId;
      this.model.enterprise = this.config.data.enterpriseId;
    }
    const enterpriseRow = this.dynamicService.getFormRows(
      { fieldName: 'enterprise', fieldType: 'enterprise', label: '企業' },
      null,
      'half',
    )[0];
    enterpriseRow.fields[0].linkTo = ['departmentId'];
    enterpriseRow.hidden = this.config.data.enterpriseId != null;
    this.meta = {
      groups: [
        {
          title: '新規求人を登録します。',
          class: 'form__group--no-title-border no-border-around no-background',
          rows: [
            enterpriseRow,
            {
              title: '部署',
              fields: [
                {
                  name: 'departmentId',
                  type: 'dropdown',
                  class: 'half tall',
                  valueField: 'id',
                  labelField: 'label',
                  options: [],
                  allowOn: { enterprise: null },
                  supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                    const linkValue = getValue('enterprise');
                    return linkValue
                      ? this.companyService.getDepartments(linkValue).pipe(
                          map((v) => ({
                            options: v.enterpriseDepartments.map((e) => {
                              e.dynamicData.id = e.id;
                              e.dynamicData.label = `${e.id}  ${e.dynamicData.name}  ${
                                e.dynamicData.contact1 ? e.dynamicData.contact1.name : ''
                              }`;
                              return e.dynamicData;
                            }),
                            value: value,
                          })),
                        )
                      : { value: value, options: [] };
                  },
                  validators: { required: true },
                },
              ],
            },
          ],
        },
      ],
    };
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onValidityChange(valid: boolean): void {
    this.validity = valid;
  }

  create(): void {
    if (!this.validity || this.inClose || this.dialog.inClose) {
      return;
    }
    this.inClose = true;
    this.jobService.addJob(this.model.departmentId).subscribe(
      (res) => {
        this.jobService.refreshEvent.emit();
        this.inClose = false;
        this.close(res);
      },
      () => (this.inClose = false),
    );
  }

  close(param?: any): void {
    this.dialog.close(param);
  }
}
