import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants';
import { DynamicField, FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { deepClone, multiFill } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
})
export class CompanySearchComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();
  @Output() search = new EventEmitter<{}>();

  innerSearchModel: any = {};

  get searchModel(): any {
    return this.innerSearchModel;
  }

  @Input()
  set searchModel(model: any) {
    this.innerSearchModel = { ...model };
  }

  leftMeta: FormMeta;
  rightMeta: FormMeta;
  customMeta: FormMeta;

  private fieldSubscription: Subscription;

  metadataLeft: FormMeta;
  metadataRight: FormMeta;
  metadataCustomFields: FormMeta;

  constructor(private dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataLeft = {
        groups: [
          {
            title: '担当情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'departmentTeamId',
                fieldType: 'multi-team',
                label: '担当チーム(部署)',
                displayStyle: { placeholder: 'チーム名' },
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'enterpriseUserId',
                fieldType: 'multi-user',
                label: '担当者(企業)',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'departmentUserId',
                fieldType: 'multi-user',
                label: '担当者(部署)',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'departmentAssistantUserId',
                fieldType: 'multi-user',
                label: 'アシスタント(部署)',
              }),
            ],
          },
          {
            title: '対応履歴',
            class: 'form__group--red-title-border',
            rows: [
              {
                title: 'アクション',
                fields: [
                  {
                    name: 'actionCode',
                    type: 'dropdown',
                    multi: true,
                    valueField: 'value',
                    labelField: 'label',
                    placeholder: '項目を選択',
                    options: [
                      { value: 1, label: '電話' },
                      { value: 2, label: 'メール' },
                      { value: 3, label: '訪問' },
                      { value: 4, label: '先TEL' },
                      { value: 5, label: 'APO獲得' },
                      { value: 6, label: 'アサイン' },
                      { value: 7, label: '契約締結' },
                      { value: 8, label: '受注' },
                      { value: 9, label: '移管' },
                      { value: 10, label: 'クレーム' },
                      { value: 11, label: 'DC決定' },
                      { value: 12, label: 'その他' },
                    ],
                  },
                ],
              },
              ...this.dynamicService.getFormRows({
                fieldName: 'action',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: 'アクション期間',
              }),
            ],
          },
          {
            title: '日付',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'createdAt',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '入力日',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'updatedAt',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '更新日',
              }),
            ],
          },
        ],
      };

      this.metadataRight = {
        groups: [
          {
            title: '基本情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill(
                  {
                    ...this.dynamicService.getDefinition('enterprise', 'enterpriseStatus'),
                    fieldType: 'multi-list',
                  },
                  false,
                ),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'clientManagement'),
                  fieldType: 'multi-list',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'companyClassification'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'numberEmployees'),
                  fieldType: 'number',
                  displayType: 'number-range',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'agClientStatus'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'drClientStatus'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'rininKRank'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),

              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'drAgreement'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'drOfferAgency'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'drLandingRoute'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'gpsContract'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'fairContract'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('enterprise', 'plusUsage'))),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'industry'),
                  displayType: 'multi-enterprise',
                }),
              ),
            ],
          },
          {
            title: '契約情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('contract', 'contractCategory'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              {
                title: '契約サービス',
                fields: [
                  {
                    name: 'contractService',
                    type: 'radio',
                    options: [NULL_SELECTED_VALUE, 'AG', 'DR', 'その他'],
                  },
                ],
              },
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('contract', 'contractClassification'),
                  fieldType: 'multi-list',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('contract', 'approvalClassification'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('contract', 'signedDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('contract', 'contractType'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('contract', 'status'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  fieldName: 'hasRefund',
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                  label: '返金情報',
                  validationStyle: {
                    options: ['有り', 'なし'],
                  },
                }),
              ),
            ],
          },
          {
            title: '請求先情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('billingAddress', 'btoBBillignAddressRegistrationDate'),
                  fieldType: 'date-range',
                  displayType: 'date-advanced',
                }),
              ),
            ],
          },
        ],
      };

      const customs = this.dynamicService.getDefinitions('enterprise').filter((def) => def.isCustomField && def.isSearchable);
      if (customs.length) {
        this.metadataCustomFields = {
          groups: [
            {
              title: 'カスタムフィールド',
              class: 'form__group--red-title-border',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onCloseClick() {
    this.closed.emit();
  }

  public doSearch(event?: any): void {
    if (!event || !event['isComposing']) {
      this.search.emit({ ...this.searchModel });
    }
  }
}
