import { APPROVAL_CANCEL_STATES } from '@agent-ds/shared/constants';
import { ActionMeta, GroupMeta, RowMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import { SalesDetailPageComponent } from '../sales-detail-page.component';
import { HelperBase } from './helper-base';

@Injectable()
export class CancellationInformationHelper extends HelperBase {
  private calculateRefoundAmountProp: { actions?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] } = { subscribers: [] };
  private cancellationButtonsProp: TemplateRef<any> = null;
  set cancellationButtons(value: TemplateRef<any>) {
    this.cancellationButtonsProp = value;
  }

  set calculateRefoundAmount(value: ActionMeta[]) {
    this.setupActionProperty(this.calculateRefoundAmountProp, value);
  }

  private cancelApproved: GroupMeta = { rows: [] };
  private cancelNotApproved: GroupMeta = { rows: [] };
  private matrix: GroupMeta[][] = [];

  constructor(dynamicService: DynamicFieldService, datePipe: SafeDatePipe, private decimalPipe: DecimalPipe) {
    super(dynamicService, datePipe);
  }

  getTemplate(editable: boolean): GroupMeta {
    let res: GroupMeta;

    res = {
      // Cancellation information
      title: 'キャンセル情報',
      class: 'form__group--golden-title-border',
      injectToHeader: this.cancellationButtonsProp,
      rows: [
        {
          title: 'キャンセル承認区分',
          fields: [
            {
              type: 'label',
              name: 'sales.cancelStatus',
              supplier: (data, callType, getter) => {
                const value = getter('sales.cancelStatus', true);
                return value ? (APPROVAL_CANCEL_STATES.find((state) => state.code === value) || { name: '' }).name : '';
              },
            },
          ],
        },
        ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'joinTiming'), null, 'sales.dynamicData'),
        ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'cancelDate'), null, 'sales.dynamicData'),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'cancelClassification'),
          null,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundDate'), null, 'sales.dynamicData'),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'refundMonth'),
          null,
          'sales.dynamicData',
          'half',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'refundPercent'),
          this.calculateRefoundAmountProp,
          'sales.dynamicData',
          'half',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'refundPrice'),
          null,
          'sales.dynamicData',
          'half',
          (val) => this.decimalPipe.transform(val),
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'refundRemarks'),
          null,
          'sales.dynamicData',
          'full',
        ),
        ...this.getDynamicRowOrLabel(
          true,
          this.dynamicService.getDefinition('sales', 'refundRejectReason'),
          null,
          'sales.dynamicData',
          'fill',
        ),
      ],
    };

    return res;
  }

  updateFieldDefinitions(): void {
    this.cancelApproved = this.getTemplate(false);
    this.cancelNotApproved = this.getTemplate(true);

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [null, null, null, null], // Approval state: undefined
      [null, null, null, null], // Approval state: 1 (Not Approved)
      [this.cancelApproved, this.cancelNotApproved, this.cancelNotApproved, this.cancelApproved], // Approval state: 2 (Approved)
      [null, null, null, null], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus]];
  }
}
