import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-job-registration-dialog',
  templateUrl: './job-registration-dialog.component.html',
  styleUrls: ['./job-registration-dialog.component.scss'],
})
export class JobRegistrationDialogComponent implements OnInit {
  model: any = {};
  metadata: FormMeta = { groups: [] };
  isValid = false;

  constructor(public dialog: DialogRef, public config: DialogConfig, private dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.metadata = {
      groups: [
        {
          class: 'no-title-column no-border',
          rows: [
            ...this.dynamicService.getFormRows(
              {
                fieldName: 'jobId',
                fieldType: 'job',
                displayType: 'job',
                validationStyle: {
                  required: true,
                },
              },
              null,
              'three-quarter',
            ),
          ],
        },
      ],
    };
  }

  register(): void {
    if (!this.isValid || this.model.jobId == null || !this.config.data || !this.config.data.addRequestFn) {
      return;
    }

    let addRequest;
    if (this.config.data.studentId != null) {
      addRequest = this.config.data.addRequestFn(this.config.data.studentId, this.config.data.seminarId, this.model.jobId);
    } else if (this.config.data.seminarId != null) {
      addRequest = this.config.data.addRequestFn(this.config.data.seminarId, this.model.jobId);
    } else if (this.config.data.areaId != null) {
      addRequest = this.config.data.addRequestFn(this.config.data.areaId, this.model.jobId);
    }

    if (addRequest) {
      addRequest.subscribe(() => {
        this.close(true);
        PopupControlComponent.instance.open({
          content: '登録が完了しました。',
          confirmText: '閉じる',
          confirmCallback: () => void 0,
          title: 'セミナー 紹介求人新規登録',
          height: '357px',
          width: '600px',
        });
      });
    }
  }

  close(result?: any): void {
    this.dialog.close(result);
  }

  onValidityChange(valid: boolean) {
    this.isValid = valid;
  }
}
