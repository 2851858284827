import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { User } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'ag-settings-users-page',
  templateUrl: './settings-users-page.component.html',
  styleUrls: ['./settings-users-page.component.scss'],
})
export class SettingsUsersPageComponent implements OnInit, OnDestroy {
  @ViewChild('editButton', { static: true }) editButtonTemplate: TemplateRef<any>;
  @ViewChild('team', { static: true }) teamTemplate: TemplateRef<any>;
  @ViewChild('name', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('email', { static: true }) emailTemplate: TemplateRef<any>;
  @ViewChild('userPopup', { static: true }) userPopupTemplate: TemplateRef<any>;
  @ViewChild('userForm', { static: false }) userFormComponent: DynamicFormComponent;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<any>;

  tableConfig: TableConfig;
  userPopupModel: any;
  userPopupMeta: FormMeta;
  filteredUsers: User[] = [];
  search = '';
  private users: User[] = [];
  private searchInputSubscription: Subscription;

  constructor(
    public readonly userTeamProvider: UserTeamInjectorProvider,
    private userApiService: UserApiService,
    private dynamicFieldService: DynamicFieldService,
  ) {}

  ngOnInit() {
    this.userApiService.getAll().subscribe((users: User[]) => {
      this.users = users;
      if (this.search) {
        this.filterUsers(this.search);
      } else {
        this.filteredUsers = [...this.users];
      }
    });
    this.userPopupMeta = {
      groups: [
        {
          class: 'no-border no-background title-align-top title-w90 row-gap-20',
          rows: [
            {
              title: 'ユーザーID',
              fields: [
                {
                  name: 'id',
                  type: 'label',
                },
              ],
            },
            {
              title: '氏名',
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  validators: { required: true },
                  class: 'tall three-quarter',
                },
              ],
            },
            {
              title: 'パスワード',
              class: 'vertical',
              fields: [
                {
                  name: 'password',
                  type: 'text',
                  class: 'tall three-quarter',
                  validators: {
                    password: true,
                  },
                },
                {
                  name: 'passwordHint',
                  type: 'label',
                  supplier: () => '※ 変更する場合は入力してください',
                  style: {
                    'font-size': '12px',
                    color: '#9F9F9F',
                  },
                },
              ],
            },
            {
              title: 'メールアドレス',
              fields: [
                {
                  name: 'email',
                  type: 'text',
                  validators: { required: true },
                  class: 'tall three-quarter',
                },
              ],
            },
            (() => {
              const row = this.dynamicFieldService.getFormRows({
                fieldName: 'teamId',
                fieldType: 'team',
                label: '所属チーム',
              })[0];
              row.fields[0].class = 'tall third';
              row.fields[0].validators = { required: true };
              return row;
            })(),
            {
              title: '管理者権限',
              fields: [
                {
                  name: 'role',
                  type: 'checkbox',
                  labelAfter: '有',
                },
              ],
            },
            {
              title: '状態',
              fields: [
                {
                  name: 'status',
                  type: 'radio',
                  valueField: 'value',
                  labelField: 'label',
                  options: [
                    { label: '退職', value: 0 },
                    { label: '在職', value: 1 },
                    { label: '非表示', value: 2 },
                    { label: 'ログイン不可', value: 3 },
                  ],
                  class: 'vertical',
                  validators: { required: true },
                },
              ],
            },
            {
              title: 'ダッシュボード',
              fields: [
                {
                  name: 'dashboardType',
                  type: 'radio',
                  valueField: 'value',
                  labelField: 'label',
                  options: [
                    { label: '求職者担当', value: 3 },
                    { label: '求職者マネージャー', value: 4 },
                    { label: '求人担当', value: 0 },
                    { label: '求人マネージャー', value: 1 },
                    { label: '求人アシスタント', value: 2 },
                    { label: '求職者副担当', value: 5 },
                  ],
                  class: 'vertical',
                  validators: { required: true },
                },
              ],
            },
          ],
        },
      ],
    };
    this.tableConfig = {
      head: {
        style: {
          padding: '7px 0',
        },
        config: [
          {
            columns: [
              {
                fields: [
                  {
                    name: 'id',
                    title: 'ユーザーID',
                    defaultSort: SortOrder.ASCENDING,
                  },
                ],
                style: {
                  width: '130px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'name',
                    title: '氏名',
                    cellTemplate: this.nameTemplate,
                    sortable: false,
                  },
                ],
                style: {
                  width: '140px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'email',
                    title: 'メールアドレス',
                    cellTemplate: this.emailTemplate,
                    sortable: false,
                  },
                ],
                style: {
                  width: '200px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'teamId',
                    title: '所属チーム',
                    cellTemplate: this.teamTemplate,
                    sortable: false,
                  },
                ],
                style: {
                  flex: 1,
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: '',
                    title: 'ステータス',
                    formatter: (data) => {
                      if (data && data.status != null) {
                        let statusText = '';
                        switch (data.status) {
                          case 0:
                            statusText = '退職';
                            break;
                          case 1:
                            statusText = '在職';
                            break;
                          case 2:
                            statusText = '非表示';
                            break;
                          case 3:
                            statusText = 'ログイン不可';
                            break;
                          default:
                            break;
                        }
                        return statusText ? `${statusText}${data.role === 1 ? ' [管理者]' : ''}` : '';
                      }
                      return '';
                    },
                    sortable: false,
                  },
                ],
                style: {
                  width: '140px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'actions',
                    title: '',
                    sortable: false,
                    cellTemplate: this.editButtonTemplate,
                  },
                ],
                style: {
                  width: '48px',
                  'padding-right': 0,
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
            ],
          },
        ],
      },
      body: {
        checkbox: false,
        arrow: false,
        class: 'no-hover',
        style: {
          height: '34px',
        },
      },
    };
    this.searchInputSubscription = fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.filterUsers(this.search));
  }

  ngOnDestroy() {
    if (this.searchInputSubscription) {
      this.searchInputSubscription.unsubscribe();
      this.searchInputSubscription = null;
    }
  }

  openUserEdit(user?: User): void {
    this.userPopupModel = { ...user };

    const passwordField = this.userPopupMeta.groups[0].rows[1].fields[0];
    passwordField.validators = {
      ...passwordField.validators,
      required: this.userPopupModel.id == null,
    };

    PopupControlComponent.instance.open({
      title: this.userPopupModel.id ? 'ユーザー詳細' : 'ユーザー登録',
      cancelText: 'キャンセル',
      confirmText: this.userPopupModel.id ? '更新' : '登録',
      width: '800px',
      height: '640px',
      content: this.userPopupTemplate,
      confirmEnabled: () => this.userFormComponent && this.userFormComponent.myForm.valid,
      confirmCallback: () => {
        const requestObj = {
          ...this.userPopupModel,
          name: this.userPopupModel.name.trim(),
          role: this.userPopupModel.role ? 1 : 0,
          email: this.userPopupModel.email.trim(),
        };
        const request = requestObj.id ? this.userApiService.update(requestObj.id, requestObj) : this.userApiService.add(requestObj);

        return request.pipe(
          concatMap(() =>
            this.userApiService.getAll().pipe(
              tap((users: User[]) => {
                this.users = users;
                if (this.search) {
                  this.filterUsers(this.search);
                } else {
                  this.filteredUsers = [...this.users];
                }
              }),
            ),
          ),
        );
      },
    });
  }

  clearSearch(): void {
    this.search = '';
    this.filteredUsers = [...this.users];
  }

  private filterUsers(text: string): void {
    text = text && text.trim();
    const filteredUsers = text ? [...this.users.filter((user) => user.name.includes(text) || user.email.includes(text))] : null;
    this.filteredUsers = filteredUsers ? filteredUsers : this.users;
  }
}
