import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ag-sales-report-filter',
  templateUrl: './sales-report-filter.component.html',
  styleUrls: ['./sales-report-filter.component.scss'],
})
export class SalesReportFilterComponent implements OnInit {
  @Output() filter = new EventEmitter<any>();

  constructor(private dynamicService: DynamicFieldService) {}

  model = {};

  metadata: FormMeta = {
    groups: [
      {
        title: '担当別月間売上検索',
        class: 'form__group--golden-title-border',
        rows: [
          ...this.dynamicService.getFormRows({
            fieldName: 'contractDate',
            fieldType: 'date-range',
            displayType: 'date-more-advanced',
            label: '期間',
          }),
          ...this.dynamicService.getFormRows({
            fieldName: 'interviewDate',
            fieldType: 'date-range',
            displayType: 'date-more-advanced',
            label: '面談期間',
          }),
          (() => {
            const row = this.dynamicService.getFormRows(
              {
                fieldName: 'teamId',
                fieldType: 'team',
                displayType: 'dropdown',
                // required validation is added in order to prevent '-----' being added to the options.
                // The validation is then removed below though.
                validationStyle: { required: true },
                label: 'チーム',
              },
              null,
              'quarter',
            )[0];
            row.fields[0].multi = true;
            row.showRequired = false;
            delete row.fields[0].validators.required;
            return row;
          })(),
        ],
      },
    ],
  };

  ngOnInit() {}

  onFilterClick() {
    this.filter.emit(this.model);
  }
}
