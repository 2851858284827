import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { ProgressRecommendConfig } from '@agent-ds/shared/components/mail-send/configs/progress-recommend-config';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { IndustryType, JobFavoriteListItem, JobType } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, JobApiService, MasterApiService } from '@agent-ds/shared/services';
import { download, getFileNameFromContentDispositionHeader, getValueFromObject } from '@agent-ds/shared/util/util';
import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { RegisterStudentDialogComponent } from '../../components/register-student-dialog/register-student-dialog.component';
import { JobDetailPageComponent } from '../job-detail-page/job-detail-page.component';
import { FAV_JOBS_TABLE_CONFIG } from './favorite-job-list-table-config';

const RecommendationHeatClasses: { [key: string]: string } = {
  次月: 'badge--yellow-light',
  次月注力: 'badge--yellow-light',
  月内: 'badge--red-light-alt',
};

@Component({
  selector: 'ag-favorite-job-list-page',
  templateUrl: './favorite-job-list-page.component.html',
  styleUrls: ['./favorite-job-list-page.component.scss'],
})
export class FavoriteJobListPageComponent implements AfterViewInit {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('company', { static: false }) companyTemplate: TemplateRef<any>;
  @ViewChild('position', { static: false }) positionTemplate: TemplateRef<any>;
  @ViewChild('heat', { static: false }) heatTemplate: TemplateRef<any>;

  classMap = {
    中堅以上: 'badge--green-light',
    中小: 'badge--blue',
    販サOS介護: 'badge--orange-seg',
    IT注力: 'badge--purple-light2',
  };

  tableConfig: TableConfig = null;
  listConfiguration: any = { body: { checkbox: true } };
  jobTypes: JobType[] = [];
  industryTypes: IndustryType[] = [];
  options: any = { filterEnded: false, body: { checkbox: true } };
  jobs: JobFavoriteListItem[];

  postingConfig = new JobPostingConfig();
  recommendConfig = new ProgressRecommendConfig();

  readonly recommendationHeatClasses = RecommendationHeatClasses;

  constructor(
    public readonly masterApiService: MasterApiService,
    private service: JobApiService,
    private readonly datePipe: SafeDatePipe,
    private cdr: ChangeDetectorRef,
    private dialog: DialogService,
  ) {}

  ngAfterViewInit(): void {
    this.tableConfig = FAV_JOBS_TABLE_CONFIG(this.companyTemplate, this.positionTemplate, this.heatTemplate, (date) =>
      this.datePipe.transform(date, 'yyyy/MM/dd'),
    );
    this.cdr.detectChanges();

    this.service.getFavorites().subscribe((jobs: JobFavoriteListItem[]) => {
      this.jobs = jobs;
    });
  }

  onRemoveFromFavorites(): void {
    forkJoin(this.table.checkedItems.map((item) => this.service.deleteFavorite(item.id))).subscribe(() => {
      this.service.getFavorites().subscribe((jobs) => {
        this.jobs = jobs;
      });
    });
    this.table.checkedItems.length = 0;
  }

  public onRegisterStudents() {
    this.dialog.open(RegisterStudentDialogComponent, { data: this.table.checkedItems });
  }

  getJobsAsPdf(): void {
    this.service.getJobsAsPdf(this.table.checkedItems.map((job) => job.id)).subscribe((resp) => {
      download(resp.body, getFileNameFromContentDispositionHeader(resp));
    });
  }

  onItemSelected(job: JobFavoriteListItem): void {
    JobDetailPageComponent.instance.referenceId = job.id;
    JobDetailPageComponent.instance.open();
  }

  openMail(config: MailSendConfig): void {
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      jobs: this.table.checkedItems.map((item: JobFavoriteListItem) => ({
        id: item.id,
        frontId: item.frontId,
        position: getValueFromObject(item.dynamicData, 'position'),
        enterpriseName: item.enterpriseName,
        enterpriseId: item.enterpriseId,
        frontEnterpriseId: item.frontEnterpriseId,
        enterpriseDepartmentId: item.enterpriseDepartmentId,
      })),
    });
    MailSendFlowComponent.instance.start();
  }
}
