import { CounselingContentType, CounselingNavService } from '@agent-ds/counseling/services/counseling-nav.service';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS } from '@agent-ds/shared/constants';
import { CounselingBatchResponse, Seminar } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewSeminarRegistrationDialogComponent } from '../new-seminar-registration-dialog/new-seminar-registration-dialog.component';
import { SEMINAR_LIST_TABLE_CONFIG } from './seminar-lister-table-config';

@Component({
  selector: 'ag-seminar-lister',
  templateUrl: './seminar-lister.component.html',
  styleUrls: ['./seminar-lister.component.scss'],
})
export class SeminarListerComponent implements OnInit, OnDestroy {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('actions', { static: true }) buttonsTemplate: TemplateRef<any>;
  @Input() set area(v: { id: number; name: string }) {
    this.selectedTabIndex = v && v.id != null ? this.areas.findIndex((area) => area.id === v.id) : this.selectedTabIndex;
  }
  public tabCode = 0;

  areas: { id: number; name: string }[] = Object.keys(AREAS).map((key) => ({ id: +key, name: AREAS[key] }));
  selectedArea: { id: number; name: string };
  tableConfig: TableConfig;
  filterModel: any = {
    filterRange: Date.currentWeek(),
  };
  filterMetadata: FormMeta = {
    groups: [],
  };
  seminars: (Seminar & { isSeminarAtPastDate?: boolean; areaName?: string })[];

  public get selectedTabIndex(): number {
    return this.tabCode;
  }

  public set selectedTabIndex(index: number) {
    this.tabCode = index;
    const nextSelectedArea = this.areas ? this.areas[index] : null;
    if (nextSelectedArea !== this.selectedArea) {
      this.selectedArea = nextSelectedArea;
      this.getSeminars();
    }
  }

  private seminarCreateDialogSubscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(
    private dialog: DialogService,
    private counselingApiService: CounselingApiService,
    private datePipe: SafeDatePipe,
    private dynamicService: DynamicFieldService,
    private cdr: ChangeDetectorRef,
    private navService: CounselingNavService,
  ) {}

  ngOnInit() {
    this.tableConfig = SEMINAR_LIST_TABLE_CONFIG(
      this.buttonsTemplate,
      (date) => this.datePipe.transform(date, 'yyyy/MM/dd (E)'),
      (date) => this.datePipe.transform(date, 'HH:mm〜'),
    );
    this.filterMetadata = {
      groups: [
        {
          class: 'form__group--no-title-border no-border-around no-background',
          rows: [
            ...this.dynamicService.getFormRows({
              fieldName: 'filterRange',
              fieldType: 'date-range',
              displayType: 'date-seminar-list',
            }),
          ],
        },
      ],
    };
    this.cdr.detectChanges();
    this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(() => this.getSeminars());
  }

  ngOnDestroy() {
    if (this.seminarCreateDialogSubscription) {
      this.seminarCreateDialogSubscription.unsubscribe();
      this.seminarCreateDialogSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  openSeminarRegistrationDialog(): void {
    const seminarCreateDialogRef = this.dialog.open(NewSeminarRegistrationDialogComponent);
    this.seminarCreateDialogSubscription = seminarCreateDialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.counselingApiService.refreshEvent.next();
      }
    });
  }

  onFilterChanged(): void {
    this.getSeminars();
  }

  onDelete(): void {
    if (!this.table.checkedItems.length) {
      return;
    }
    PopupControlComponent.instance.open({
      content: 'セミナーを削除します。よろしいですか？',
      confirmText: 'OK',
      confirmCallback: () => {
        PopupControlComponent.instance.close();
        this.counselingApiService
          .deleteSeminars(this.table.checkedItems.map((seminar) => seminar.id))
          .subscribe((res: CounselingBatchResponse) => {
            if (res && res.successIds && res.successIds.length) {
              this.counselingApiService.refreshEvent.next();
            }
            if (res && res.errorIds && res.errorIds.length) {
              PopupControlComponent.subInstance.open({
                content:
                  'データ削除に失敗しました。\n求職者が登録されているセミナーを削除しようとしたか、\n既に削除されている可能性があります。',
                confirmText: '閉じる',
                confirmCallback: () => false,
                title: 'セミナー削除',
                height: '357px',
                width: '600px',
              });
            }
          });
      },
      title: 'セミナー削除',
      height: '357px',
      width: '600px',
      cancelText: 'キャンセル',
    });
  }

  onAttendeeListClick(seminar: Seminar): void {
    this.navService.changePageContent.next({
      content: CounselingContentType.attendeeList,
      area: this.selectedArea,
      data: { seminar: seminar },
    });
  }

  onJobListClick(seminar: Seminar): void {
    this.navService.changePageContent.next({
      content: CounselingContentType.jobList,
      area: this.selectedArea,
      data: { seminar: seminar },
    });
  }

  onMasterJobListClick(): void {
    this.navService.changePageContent.next({
      content: CounselingContentType.jobList,
      area: this.selectedArea,
    });
  }

  private getSeminars(): void {
    this.counselingApiService
      .getSeminars(this.selectedArea.id, this.filterModel['filterRange'].from, this.filterModel['filterRange'].to)
      .subscribe((response: Seminar[]) => {
        if (this.table) {
          this.table.checkAll(true);
        }
        this.seminars = response.map((res) => ({
          ...res,
          areaName: AREAS[res.area] || '',
          isSeminarAtPastDate: new Date(res.seminarAt) < new Date(),
        }));
      });
  }
}
