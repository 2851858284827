import { EnterprisesKpi, Kpi, ProgressKpi, StudentsKpi, TargetKpi } from '@agent-ds/shared/interfaces/dashboard';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const DASHBOARD_API_URL = '/api/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private http: HttpClient) {}
  public readonly refreshEvent = new EventEmitter<void>();

  getTeamKPI(teamId: number, dashboardType: number): Observable<Kpi> {
    return this.http.get<Kpi>(`${DASHBOARD_API_URL}/team/${teamId}/kpi/${dashboardType}`);
  }

  getKPI(dashboardType: number): Observable<Kpi> {
    return this.http.get<Kpi>(`${DASHBOARD_API_URL}/kpi/${dashboardType}`);
  }

  getProgress(dashboardType: number, year: number): Observable<ProgressKpi> {
    return this.http.get<ProgressKpi>(`${DASHBOARD_API_URL}/progress/${dashboardType}/${year}`);
  }

  getTeamProgress(teamId: number, dashboardType: number, year: number): Observable<ProgressKpi> {
    return this.http.get<ProgressKpi>(`${DASHBOARD_API_URL}/team/${teamId}/progress/${dashboardType}/${year}`);
  }

  getProgressWithNextYear(dashboardType: number, year: number): Observable<ProgressKpi> {
    return this.http.get<ProgressKpi>(`${DASHBOARD_API_URL}/progress/${dashboardType}/${year}/with-next-year`);
  }

  getTeamProgressWithNextYear(teamId: number, dashboardType: number, year: number): Observable<ProgressKpi> {
    return this.http.get<ProgressKpi>(`${DASHBOARD_API_URL}/team/${teamId}/progress/${dashboardType}/${year}/with-next-year`);
  }

  getEnterprises(): Observable<EnterprisesKpi> {
    return this.http.get<EnterprisesKpi>(`${DASHBOARD_API_URL}/enterprises`);
  }

  getTeamEnterprises(teamId: number): Observable<EnterprisesKpi> {
    return this.http.get<EnterprisesKpi>(`${DASHBOARD_API_URL}/team/${teamId}/enterprises`);
  }

  getEnterprisesOfAssistant(): Observable<EnterprisesKpi> {
    return this.http.get<EnterprisesKpi>(`${DASHBOARD_API_URL}/enterprises/ra-assistant`);
  }

  getStudents(): Observable<StudentsKpi> {
    return this.http.get<StudentsKpi>(`${DASHBOARD_API_URL}/students`);
  }

  getTeamStudents(teamId: number): Observable<StudentsKpi> {
    return this.http.get<StudentsKpi>(`${DASHBOARD_API_URL}/team/${teamId}/students`);
  }

  updateKpi(kpi: { kpiTarget: TargetKpi }): Observable<HttpResponse<void>> {
    return this.http.put<void>(`${DASHBOARD_API_URL}/kpi/target`, kpi, { observe: 'response' });
  }

  updateTeamKpi(teamId: number, kpi: { kpiTarget: TargetKpi }): Observable<HttpResponse<void>> {
    return this.http.put<void>(`${DASHBOARD_API_URL}/team/${teamId}/kpi/target`, kpi, { observe: 'response' });
  }
}
