import { CounselingContentType, CounselingNavService } from '@agent-ds/counseling/services/counseling-nav.service';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { THANKS_MAIL_TYPES } from '@agent-ds/shared/constants';
import { CounselingBatchResponse, Seminar, SeminarThanksMailRequest, Student } from '@agent-ds/shared/interfaces';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SEMINAR_ATTENDEE_LIST_TABLE_CONFIG } from './seminar-attendee-list-table-config';

@Component({
  selector: 'ag-seminar-attendee-list',
  templateUrl: './seminar-attendee-list.component.html',
  styleUrls: ['./seminar-attendee-list.component.scss'],
})
export class SeminarAttendeeListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('thanksMailType', { static: false }) thanksMailTypeTemplate: TemplateRef<any>;
  @ViewChild('student', { static: false }) studentTemplate: TemplateRef<any>;
  @ViewChild('school', { static: false }) schoolTemplate: TemplateRef<any>;

  area: { id: number; name: string };
  seminar: Seminar;
  attendees: (Student & { mailType?: number })[] = [];
  model: { [key: number]: any } = [];
  tableConfig: TableConfig;
  readOnly: boolean;
  thanksMailTypes: { id: number; name: string }[] = Object.keys(THANKS_MAIL_TYPES).map((key) => ({
    id: +key,
    name: THANKS_MAIL_TYPES[key],
  }));
  readonly THANKS_MAIL_TYPES = THANKS_MAIL_TYPES;

  private navSubscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private counselingApiService: CounselingApiService,
    private navService: CounselingNavService,
  ) {}

  ngOnInit(): void {
    this.navSubscription = this.navService.changePageContent.subscribe(
      (subject: { content: CounselingContentType; area?: { id: number; name: string }; areaId?: number; data?: any }) => {
        this.area = subject.area ? subject.area : null;
        this.seminar = subject.data && subject.data.seminar ? subject.data.seminar : null;
        this.readOnly = this.seminar && !!this.seminar.isSubmitted;
        if (this.seminar) {
          this.getStudentsForSeminar(this.seminar.id);
        }
      },
    );
    this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(() => {
      if (this.seminar) {
        this.getStudentsForSeminar(this.seminar.id);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
      this.navSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  ngAfterViewInit(): void {
    this.tableConfig = SEMINAR_ATTENDEE_LIST_TABLE_CONFIG(this.thanksMailTypeTemplate, this.studentTemplate, this.schoolTemplate);
    this.cdr.detectChanges();
  }

  onAttendeeSelect(selectedStudent: Student): void {
    StudentDetailPageComponent.instance.referenceId = selectedStudent ? selectedStudent.id : null;
    StudentDetailPageComponent.instance.open();
  }

  saveThanksMails(): void {
    if (!this.attendees || !this.attendees.length) {
      return;
    }
    const thanksMailRequestObj: SeminarThanksMailRequest = {
      thanksMail: Object.keys(this.model).map((studentId) => ({ studentId: +studentId, mailType: this.model[studentId].thanksMailType })),
    };
    this.counselingApiService
      .addStudentsThanksMailForSeminar(this.seminar.id, thanksMailRequestObj)
      .subscribe((res: CounselingBatchResponse) => {
        if (res.successIds.length) {
          Object.keys(this.model).forEach((studentId) => {
            const attendee = this.attendees.find((a) => a.id === +studentId);
            attendee.mailType = this.model[studentId].thanksMailType;
          });
          this.readOnly = true;
        }
      });
  }

  private getStudentsForSeminar(seminarId: number): void {
    this.counselingApiService.getStudentsForSeminar(seminarId).subscribe((students: Student[]) => {
      students.forEach((student) => (this.model[student.id] = { thanksMailType: 4 }));
      this.attendees = students;
    });
  }
}
