import { ContactHistory } from './contact';
import { User } from './user';

/** TODO: make all field required */
export interface CompanyContact {
  email?: string;
  emailAvailable?: string;
  fax?: string;
  name?: string;
  phoneticName?: string;
  position?: string;
  remarks?: string;
  tel?: string;

  /** TODO: remove fields below fields over integration steps */
  responseDate?: number;
  action?: string;
  responsible?: string;
  responsibleName?: string;
  nextContactDate?: number;
  message?: string;
}

export interface EnterpriseSuggestResponse {
  id: number;
  frontId: string;
  name: string;
  enterpriseNo: number;
}

export interface EnterpriseDepthContacts {
  enterpriseBillingAddresses: CompanyBillingAddress[];
  enterpriseDepartments: EnterpriseDepartment[];
}

export enum EnterpriseDepartmentUserType {
  'アシ' = 0,
  'RA' = 1,
  'PA' = 2,
  'CA' = 3,
}

export interface EnterpriseDepartment {
  createdAt: Date;
  deletedAt?: any;
  updatedAt: Date;
  syncTriggerAt: Date;
  dynamicData: any;
  id: number;
  enterpriseId: number;
  enterpriseDepartmentUsers?: EnterpriseDepartmentUser[];
}

export interface EnterpriseDepartmentUser {
  type: number;
  userId: number;
  id: number;
}

export interface Contract {
  createdAt: Date;
  deletedAt?: any;
  dynamicData: any;
  enterpriseId: number;
  id: number;
  teamId: number;
  updatedAt: Date;
  frontId: string;
}

export enum ApprovalClassification {
  UNAPPROVED = '未承認',
  WAITING_FOR_RE_APPLICATION = '再申請待ち',
  APPROVAL_PENDING = '承認待ち',
  APPROVED = '承認済',
}

// TODO: check with real data. This is based on swagger
// used by:
//    CompanyOverviewTabComponent
// Generated by https://quicktype.io
export interface Company {
  '@timestamp': Date;
  '@version': string;
  contract: Contract[];
  createdAt: Date;
  deletedAt?: any;
  dynamicData: any;
  enterpriseContactHistory: ContactHistory[];
  enterpriseDepartments: EnterpriseDepartment[];
  enterpriseDepartmentUsers: EnterpriseDepartmentUser[];
  id: number;
  jobCount: number;
  salesAmount: number;
  syncTriggerAt: Date;
  tags: string[];
  textId: string;
  frontId: string;
  type: string;
  updatedAt: Date;
  userId: number;
  updateUserId: number;
}

/*** OLD TS FILES */
export interface Enterprise {
  id: number;
  frontId: string;
  department: EnterpriseDepartment[];
  dynamicData: any;
  user?: User;
}

export interface CompanyList {
  total: number;
  enterprises: Company[];
}

export interface CompanyDetail {
  id: number;
  frontId: string;
  name: string;
  business_number: string;
  AG_customer_status: string;
  DR_customer_status: string;
  order?: boolean;
  registration_rank: string;
  closing_performance: string;
  total_closing_amount: string;
  company_representative_information: string[];
  dynamicData: any;
  user: User;
  userId: number;
  salesAmount: number;
}

export interface CompanyProgress {
  id: string;
  name: string;
  degrees: number;
  status: string;
  has_degree: boolean;
  applicant_contact_team_name: string;
  applicant_contact_name: string;
  company_id: string;
  company_name: string;
  company_contact_team_name: string;
  company_contact_name: string;
  job_id: string;
  job_name: string;
  job_open: boolean;
  updated: number;
  note: string;
  note_day: number;
}

export interface CompanySale {
  id: string;
  persons_in_charge: {
    label: string;
    name: string;
    area: string;
  }[];
  close_date: string;
  hire_date: string;
  fee: string;
  approval_indicator: string;
  cancel_date: string;
  cancel_approval_indicator: string;
  company_name: string;
  job: string;
  job_seeker: string;
}

export interface CompanyJob {
  ended?: boolean;
  new_arrival: string;
  job_status: string;
  stats: string;
  heat: {
    label: string;
    level: number;
  }[];
  company_id: string;
  company_name: string;
  position_id: string;
  position_name: string;
  industry: string;
  job_type: string;
  update_date: string;
  persons_in_charge: {
    label: string;
    name: string;
    area: string;
  }[];
}

export interface CompanyBillingAddress {
  id: number;
  frontId: string;
  dynamicData: any;
  user?: User;
}

export interface ImportCompleted {
  successCount: number;
  errorCount: number;
  errorList: {
    line: number;
    error: {
      message: string;
      data: {
        input: number;
        messages: string[];
      }[];
    };
  }[];
}
