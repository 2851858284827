import { ExportModel, ExportTemplate, ExportTemplateListResponse } from '@agent-ds/shared/interfaces/export';
import { downloadResponse, toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExportApiService {
  constructor(private http: HttpClient) {}

  export(model: ExportModel, templateId: number, params: any): Observable<any> {
    return this.http
      .get(`/api/export/${model}/${templateId}`, { responseType: 'blob', observe: 'response', params: params })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportContracts(params: any): Observable<any> {
    return this.http
      .get('/api/enterprise/csv/contract/export', {
        responseType: 'blob',
        observe: 'response',
        params: params,
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportSales(salesIds: number[], requestObj?: any): Observable<any> {
    return this.http
      .get('/api/sales/file/csv', {
        responseType: 'blob',
        observe: 'response',
        params: requestObj || { salesId: salesIds },
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportInvoice(salesId: number): Observable<any> {
    return this.http
      .get(`/api/sales/${salesId}/invoice/export`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportProgresses(params: any): Observable<any> {
    return this.http
      .get('/api/progress/csv', {
        responseType: 'blob',
        observe: 'response',
        params: params,
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportStudentsForSeminar(seminarId: number): Observable<any> {
    return this.http
      .get(`/api/seminar/${seminarId}/student/excel`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  exportJobsForSeminar(seminarId: number, jobIds: number[]): Observable<any> {
    return this.http
      .get(`/api/seminar/${seminarId}/sheet`, {
        responseType: 'blob',
        observe: 'response',
        params: toAsialRequestParameters({ jobIds: jobIds }) as any,
      })
      .pipe(tap((res) => downloadResponse(res)));
  }

  getFields(model: ExportModel): Observable<string[]> {
    return this.http.get<string[]>('/api/export/templates/fields', { params: { model: model } as any });
  }

  getTemplates(model: ExportModel, sort?: string, order?: string): Observable<ExportTemplateListResponse> {
    return this.http.get<ExportTemplateListResponse>('/api/export/templates', {
      params: { model: model, sort: sort || 'updatedAt', order: order || 'desc' } as any,
    });
  }

  getTemplate(templateId: number): Observable<ExportTemplate> {
    return this.http.get<ExportTemplate>(`/api/export/templates/${templateId}`);
  }

  addTemplate(template: ExportTemplate): Observable<any> {
    return this.http.post('/api/export/templates', template);
  }

  updateTemplate(id: number, template: ExportTemplate): Observable<any> {
    return this.http.put(`/api/export/templates/${id}`, template);
  }

  deleteTemplate(templateId: number): Observable<any> {
    return this.http.delete(`/api/export/templates/${templateId}`);
  }
}
