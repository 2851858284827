import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';
import { ZoneGuard } from '@agent-ds/shared/guard/zone.guard';
import {
  SettingsCustomFieldsPageComponent,
  SettingsFieldDefinitionsPageComponent,
  SettingsListPageComponent,
  SettingsMailTemplatesPageComponent,
  SettingsSystemConfigPageComponent,
  SettingsTeamsPageComponent,
  SettingsUsersPageComponent,
} from './pages';
import { SettingsCsvReportingPageComponent } from './pages/settings-csv-reporting-page/settings-csv-reporting-page.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    canActivate: [AuthGuard],
    component: SettingsListPageComponent,
  },
  {
    path: 'users',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsUsersPageComponent,
  },
  {
    path: 'field-definitions',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsFieldDefinitionsPageComponent,
  },
  {
    path: 'teams',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsTeamsPageComponent,
  },
  {
    path: 'mail-templates',
    canActivate: [AuthGuard],
    component: SettingsMailTemplatesPageComponent,
  },
  {
    path: 'custom-fields',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsCustomFieldsPageComponent,
  },
  {
    path: 'system',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsSystemConfigPageComponent,
  },
  {
    path: 'csv-reporting',
    canActivate: [AuthGuard, ZoneGuard],
    component: SettingsCsvReportingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
