import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { TabGroupComponent } from '@agent-ds/shared/components/tab-group';
import { CompanyDetail, ContactHistory, Contract, DashboardType, JobList, JobListItem, SituationMemo } from '@agent-ds/shared/interfaces';
import { SalesListItem, SalesListResponse } from '@agent-ds/shared/interfaces/sale';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { AuthService, CompanyApiService, JobApiService } from '@agent-ds/shared/services';
import { SalesApiService } from '@agent-ds/shared/services/api/sales-api.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyOverviewTabComponent } from './tabs/company-overview-tab/company-overview-tab.component';
import { CompanyTabs, CompanyTabsForIntakeCa } from './tabs/company-tabs.enum';

@Component({
  selector: 'ag-company-detail-page',
  templateUrl: './company-detail-page.component.html',
  styleUrls: ['./company-detail-page.component.scss'],
})
export class CompanyDetailPageComponent extends DetailPage implements OnInit, OnDestroy {
  @ViewChild(CompanyOverviewTabComponent, { static: false }) companyOverviewTabComponent: CompanyOverviewTabComponent;
  @ViewChild(TabGroupComponent, { static: false }) tabGroup: TabGroupComponent;
  public sideActions: SideActionConfig[] = [{ label: '詳細', phases: ['1000px', '100%'] }, { label: '担当者', phases: ['450px'] }];
  protected tabs: typeof CompanyTabs | typeof CompanyTabsForIntakeCa = CompanyTabs;
  protected urlTag = 'enterprises';

  private isIntakeCa = false;
  private loginSubscription: Subscription;

  company: CompanyDetail;
  contactHistory: ContactHistory[];
  jobList: JobListItem[];
  innerInfo: SituationMemo[];
  sales: SalesListItem[];
  contracts: Contract[];

  constructor(
    private companyService: CompanyApiService,
    private jobApiService: JobApiService,
    private salesApiService: SalesApiService,
    private readonly authService: AuthService,
    protected activeRoute: ActivatedRoute,
    public router: Router,
  ) {
    super(router, companyService, activeRoute);
  }

  ngOnInit(): void {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
      // 権限を見て、タブの一覧を設定
      this.tabs = this.isIntakeCa ? CompanyTabsForIntakeCa : CompanyTabs;
      // 副担当者かどうかを確認してから初期化
      super.ngOnInit();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  protected fill(): void {
    this.updateUrl();
    if (this.referenceId) {
      this.companyService.getDetail(this.referenceId).subscribe((company: CompanyDetail) => {
        this.company = company;
        if (this.company.user) {
          this.company.userId = this.company.user.id;
        }
      });

      this.companyService.getContacts(this.referenceId).subscribe((history: ContactHistory[]) => {
        this.contactHistory = history;
      });
      this.jobApiService
        .getList({
          from: 0,
          size: 100,
          sort: 'updatedAt',
          order: 'desc',
          enterpriseId: this.referenceId,
        })
        .subscribe((jobs: JobList) => {
          this.jobList = jobs.jobs;
        });
      this.companyService.getMemos(this.referenceId).subscribe((info: SituationMemo[]) => {
        this.innerInfo = info;
      });

      // インテークCAの場合は、概要に成約と契約・覚書を表示しないので、APIを実行しない
      if (this.isIntakeCa) {
        return;
      }
      this.salesApiService
        .getList({
          from: 0,
          size: 5,
          sort: 'id',
          order: 'desc',
          enterpriseId: [this.referenceId],
        })
        .subscribe((res: SalesListResponse) => {
          this.sales = res.saleses;
        });
      this.companyService.getContracts(this.referenceId).subscribe((info: Contract[]) => {
        this.contracts = info.sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1));
      });
    }
  }

  onJobShow(id: number): void {
    JobDetailPageComponent.instance.referenceId = id;
    JobDetailPageComponent.instance.open();
  }
}
