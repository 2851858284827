import { ProgressStatus } from '../interfaces';
import { PROGRESS_ACTIONS as actions } from './progress-actions';
import { PROGRESS_TRANSITIONS as transitions } from './progress-bulk-edit';

export const PROGRESS_STATUSES: { [key: number]: ProgressStatus } = {
  10: { label: 'RAレコメンド中', actions: [[actions[10]], [actions[11], actions[12]], [actions[210]]] },
  20: { label: '本人OK待ち', actions: [[actions[20]], [actions[21], actions[22]]] },
  30: { label: '営業書類提出前', actions: [[actions[30]], [actions[31], actions[32], actions[33]]] },
  40: {
    label: '営業書類結果待ち',
    actions: [null, [actions[40], actions[41], actions[42]]],
    directTransitions: [transitions[40][70], transitions[40][100]],
  },
  50: {
    label: '企業書類提出待ち',
    actions: [[actions[51]], [actions[52], actions[53]]],
    directTransitions: [transitions[50][70], transitions[50][100]],
  },
  60: { label: '企業書類結果待ち', actions: [null, [actions[60], actions[61], actions[62], actions[63], actions[64]]] },
  70: {
    label: 'セミナー設定中',
    actions: [[actions[74]], [actions[72], actions[73]]],
    directTransitions: [transitions[70][30], transitions[70][50]],
  },
  80: {
    label: 'セミナー確認中',
    actions: [[actions[82]], [actions[80], actions[81]]],
    directTransitions: [transitions[80][30], transitions[80][50]],
  },
  90: { label: 'セミナー結果待ち', actions: [[actions[94]], [actions[90], actions[91], actions[92], actions[93]]] },
  100: {
    label: '面接設定中',
    actions: [[actions[74]], [actions[102], actions[104]]],
    directTransitions: [transitions[100][30], transitions[100][50]],
  },
  110: {
    label: '面接確認中',
    actions: [[actions[112]], [actions[110], actions[111]]],
    directTransitions: [transitions[110][30], transitions[110][50]],
  },
  120: { label: '面接結果待ち', actions: [[actions[124]], [actions[120], actions[121], actions[122], actions[123]]] },
  130: { label: '本人意思待ち', actions: [null, [actions[130], actions[131]]] },
  140: { label: '内定承諾', actions: [null, [actions[140], actions[141]]] },
  150: { label: '請求処理中', actions: [null, [actions[151]]] },
  160: { label: '完了', actions: [null, []] },
  170: { label: 'NG連絡前', actions: [null, [actions[170]]] },
  180: { label: '辞退連絡前', actions: [null, [actions[180]]] },
  190: { label: 'NG終了', actions: [] },
  200: { label: '辞退終了', actions: [] },
  210: { label: '削除終了', actions: [] },
};

export const PROGRESS_STATUS_CODE = {
  RA_RECOMMEND: 10, // 初期ステータス: RAレコメンド中
  WAITING_STUDENT_OK: 20, // 本人OK待ち
  BEFORE_SALES_DOCUMENTS_SUBMIT: 30, // 営業書類提出前
  WAITING_SALES_DOCUMENTS_RESULTS: 40, // 営業書類結果待ち
  WAITING_ENTERPRISE_DOCUMENTS_SUBMIT: 50, // 企業書類提出待ち
  WAITING_ENTERPRISE_DOCUMENTS_RESULTS: 60, // 企業書類結果待ち
  SETTING_SEMINAR: 70, // セミナー設定中
  CONFIRMING_SEMINAR: 80, // セミナー確認中
  WAITING_SEMINAR_RESULT: 90, // セミナー結果待ち
  SETTING_INTERVIEW: 100, // 面接設定中
  CONFIRMING_INTERVIEW: 110, // 面接確認中
  WAITING_INTERVIEW_RESULT: 120, // 面接結果待ち
  WAITING_STUDENT_DECISION: 130, // 本人意思待ち
  ACCEPT_OFFER: 140, // 内定承諾
  INVOICE_PROCESSING: 150, // 請求処理中
  COMPLETION: 160, // 完了
  NG_BEFORE_END: 170, // NG連絡前
  DECLINE_BEFORE_END: 180, // 辞退連絡前
  NG_END: 190, // NG終了
  DECLINE_END: 200, // 辞退終了
  DELETE_END: 210, // 削除終了
};
