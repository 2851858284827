import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeof',
})
export class TypeofPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return typeOf(value);
  }
}

/**
 * Extends the typeof operator's functionality with returning 'null' instead of 'object' on null parameters.
 */
export function typeOf(value: any): string {
  return value === null ? 'null' : typeof value;
}
