import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogConfig } from '../dialog/dialog-config';
import { FileUploadDialogFileData } from './file-upload-dialog-interface';

@Component({
  selector: 'ag-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit, OnDestroy {
  constructor(public dialog: DialogRef, public config: DialogConfig<{ options: string[] }>) {}

  public uploadFilesData: FileUploadDialogFileData[] = [];
  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.upload());
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  public close(result?: FileUploadDialogFileData[]) {
    this.dialog.close(result);
  }

  public onFilesChanged(files: any): void {
    const optionsObj: { [option: string]: boolean } = {};
    this.config.data.options.forEach((option) => (optionsObj[option] = false));
    for (const file of files) {
      this.uploadFilesData.push({
        file: file,
        options: { ...optionsObj },
      });
    }
  }

  public upload() {
    if (this.uploadFilesData.length === 0 || this.dialog.inClose) {
      return;
    }
    this.close(this.uploadFilesData);
  }

  public removeFile(index: number): void {
    this.uploadFilesData.splice(index, 1);
  }
}
