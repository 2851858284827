import { BehaviorSubject } from 'rxjs';

export enum CounselingContentType {
  seminarList = 0,
  attendeeList,
  jobList,
}

export class CounselingNavService {
  constructor() {}

  changePageContent = new BehaviorSubject<{ content: CounselingContentType; area?: { id: number; name: string }; data?: any }>({
    content: CounselingContentType.seminarList,
  });
}
