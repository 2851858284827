import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { ProgressBulkEditAction, ProgressBulkEditRequest } from '@agent-ds/shared/interfaces';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogConfig } from '../dialog/dialog-config';

@Component({
  selector: 'ag-bulk-progress-change-dialog',
  templateUrl: './bulk-progress-change-dialog.component.html',
  styleUrls: ['./bulk-progress-change-dialog.component.scss'],
})
export class BulkProgressChangeDialogComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-object-literal-type-assertion
  public selected: ProgressBulkEditAction = {} as ProgressBulkEditAction;
  private confirmSubscription: Subscription;

  constructor(public dialog: DialogRef, public config: DialogConfig<{ options: ProgressBulkEditAction[] }>) {}

  get selectedStatusNeedsReason(): boolean {
    return this.selected && this.selected.needsReason;
  }

  get selectedStatusHasRemarks(): boolean {
    return this.selected && !this.selectedStatusNeedsReason;
  }

  get invalid(): boolean {
    return !this.selected || !this.selected.toStatus || (this.selectedStatusNeedsReason && !this.selected.stopReason);
  }

  ngOnInit() {
    if (!this.config.data || !this.config.data.options || !this.config.data.options.length) {
      this.close();
      return;
    }
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.submit());
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onTargetStatusChange(selected: ProgressBulkEditAction) {
    const remarksToKeep = this.selected.remarks;
    this.selected = selected;
    this.selected.stopReason = null;
    this.selected.remarks = remarksToKeep || null;
  }

  public close(result?: ProgressBulkEditRequest) {
    this.dialog.close(result);
  }

  public submit() {
    if (this.dialog.inClose) {
      return;
    }

    this.close(this.selected);
  }
}
