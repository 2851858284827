/**
 * ページネーション
 */
export class Pagination {
  private total = 0;
  private page = 1;
  private perPage = 20;
  private start = 0;
  private end = 0;

  /**
   * ページ番号を取得する
   */
  getPage(): number {
    return this.page;
  }

  /**
   * ページ番号を設定する
   */
  setPage(page: number) {
    this.page = Number(page);
    this.calc();
  }

  /**
   * 1ページ辺りの表示件数を取得する
   */
  getPerPage(): number {
    return this.perPage;
  }

  /**
   * 1ページ辺りの表示件数を設定する
   */
  setPerPage(perPage: number) {
    this.page = 1;
    this.perPage = Number(perPage);
    this.calc();
  }

  /**
   * 総数を取得する
   */
  getTotal(): number {
    return this.total;
  }

  /**
   * 総数を設定する
   */
  setTotal(total: number) {
    this.total = Number(total);
    this.calc();
  }

  /**
   * 表示開始件数を取得する
   */
  getStart(): number {
    return this.start;
  }

  /**
   * 表示終了件数を取得する
   */
  getEnd(): number {
    return this.end;
  }

  /**
   * 各種値を計算する
   */
  private calc() {
    if (this.total === 0) {
      this.start = 0;
      this.end = 0;
    } else {
      this.start = (this.page - 1) * this.perPage + 1;
      this.end = this.page * this.perPage;

      if (this.end > this.total) {
        this.end = this.total;
      }
    }
  }
}
