import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { StudentApiService } from '@agent-ds/shared/services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ag-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() size: number;
  @Input() displaySize: number;
  public loading = true;

  public src: SafeUrl;

  constructor(private studentApiService: StudentApiService, private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || (changes.id && changes.id.currentValue !== changes.id.previousValue)) {
      this.src = null;
      this.loading = true;
      this.studentApiService.getProfileImage(this.id, this.size).subscribe((url) => this.afterLoad(url));
    }
  }

  reload(): void {
    this.ngOnChanges();
  }

  private afterLoad(url: string): void {
    this.src = this.sanitizer.bypassSecurityTrustUrl(url);
    this.loading = false;
  }
}
