import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfirmDialogConfig } from '../components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { AuthGuard, USAGE_MODE } from '../guard';
import { Toast } from '../models/toast';
import {
  AuthService,
  DialogService,
  HIDE_ERROR_DIALOG_HEADER,
  HIDE_ERROR_MESSAGE_HEADER,
  REPORT_SUCCESS_HEADER,
  ToasterService,
} from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public static readonly FIELD_ERROR_EVENT = new EventEmitter<{ [key: string]: string }>();
  private readonly FILE_ERROR_CODES = [480, 431];
  private blockDialogs: any;

  constructor(private authService: AuthService, private toasterService: ToasterService, private dialogService: DialogService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({ withCredentials: true })).pipe(
      tap((event: HttpResponse<any>) => {
        if (
          event.type &&
          (req.headers.get(REPORT_SUCCESS_HEADER) || (req.url.includes('url') && event.body.errorIds && event.body.errorIds.length))
        ) {
          if (req.method === 'POST') {
            this.showDialog('登録に成功しました', ['データの登録を行いました。'], event.body);
          } else if (req.method === 'PUT') {
            this.showDialog('更新に成功しました', ['データの更新を行いました。'], event.body);
          }
        }
      }),
      catchError((event: HttpErrorResponse) => {
        if (event.status === 401) {
          this.authService.logout();
        } else if (event.status === 404) {
          if (AuthGuard.CURRENT_USAGE === USAGE_MODE.APP) {
            this.showDialog('エラーが発生しました', ['APIの呼び出しに失敗しました。対象のデータが存在しません。']);
          } else {
            return throwError(event);
          }
        } else if (event.status >= 400 && event.status < 500 && !this.FILE_ERROR_CODES.includes(event.status)) {
          let messages = [];
          if (event.status === 400 && event.error.fields) {
            const fieldKeys = Object.keys(event.error.fields);
            const fields = {};
            const hideMsg = req.headers.get(HIDE_ERROR_MESSAGE_HEADER);
            messages = fieldKeys
              .map((key) => {
                const message = Array.isArray(event.error.fields[key]) ? event.error.fields[key].join(',\n') : event.error.fields[key];
                fields[key] = hideMsg ? null : message;
                return message;
              })
              .filter((v) => v);
            AuthInterceptor.FIELD_ERROR_EVENT.emit(fields);
          }
          if (event.error && !req.headers.get(HIDE_ERROR_DIALOG_HEADER)) {
            // Parse output in event.error
            if (event.error instanceof Blob) {
              const response = new Response(event.error);
              (event.error.type === 'application/json' ? response.json() : response.text())
                .then((errorResult) => {
                  this.showDialog('入力内容が不正です', [typeof errorResult === 'object' ? errorResult.message : errorResult]);
                  return throwError(
                    new HttpErrorResponse({
                      error: errorResult,
                      headers: event.headers,
                      status: event.status,
                      statusText: event.statusText,
                      url: event.url,
                    }),
                  );
                })
                .catch((e) => this.showDialog('不正なデータが返却されました', ['不正なデータが返却されました。']));
            } else {
              this.showDialog(
                messages.length ? event.error.message : '入力内容が正しくありません',
                messages.length ? messages : [event.error.message],
              );
            }
          }
        } else if ((event.status >= 500 && event.status < 600) || event.status === 0) {
          this.toasterService.addToast(new Toast(event.url, event.name, event.error.message));
        }
        return throwError(event);
      }),
    );
  }

  private showDialog(title: string, messages: string[], req?: HttpRequest<any>, res?: HttpResponse<any>): void {
    if (!this.blockDialogs) {
      this.blockDialogs = setTimeout(() => (this.blockDialogs = null), 3000);
      const dialogConfig = new ConfirmDialogConfig();
      dialogConfig.zIndex = 8000;
      dialogConfig.title = title;
      dialogConfig.buttons = { yes: 'OK', no: '', hideNo: true };
      dialogConfig.messages = messages;

      if (res && res.body && res.body.errorIds && res.body.errorIds.length) {
        messages.push('下記のIDに対する進捗登録は失敗しました。', '');
        if (typeof res.body.errorIds[0] === 'object') {
          const studentIds = [];
          const jobIds = [];
          res.body.errorIds.forEach((e: any) => {
            studentIds.push(e.studentId);
            jobIds.push(e.jobId);
          });
          if (jobIds.length) {
            messages.push(`求人ID: ${jobIds.join('、')}`);
          }
          if (studentIds.length) {
            messages.push(`求職者ID: ${studentIds.join('、')}`);
          }
        } else {
          messages.push(
            `${
              req.url.includes('progress/bulk/job') ? '求職者ID' : req.url.includes('progress/bulk/student') ? '求人ID' : '進捗ID'
            }: ${res.body.errorIds.join('、')}`,
          );
        }
      }

      this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    }
  }
}
