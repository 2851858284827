import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupConfig, MailPopupType } from '../config';
import { StudentJobConfig } from './student-job-config';

export class ProgressRecommendConfig extends StudentJobConfig {
  public getTemplateId(): number {
    return 6;
  }

  public getTemplateTypeId(): number[] {
    return [4];
  }

  public getPopupConfig(): MailPopupConfig[] {
    return [
      { type: MailPopupType.JOB_CHECK, customFilter: { jobUserType: EnterpriseDepartmentUserType.RA }, title: '推薦依頼送信' },
      { type: MailPopupType.STUDENT, bodyArea: true, stepBack: 2, title: '推薦依頼送信', message: ' ' },
    ];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.progressId = params.progressId;
      this.sender.model.progressType = params.progressType;
      this.sender.model.progressN = params.progressN;
      this.sender.model.progressSeminarType = params.progressSeminarType;
      this.sender.model.progressSeminarAt = params.progressSeminarAt;
      this.sender.model.progressSeminarAtMailSendFlag = params.progressSeminarAtMailSendFlag;
      this.sender.model.progressIsStudentDelay = params.progressIsStudentDelay;
      this.sender.model.progressHasInterview = params.progressHasInterview;
      this.sender.model.progressStatus = params.progressStatus;
    }
    super.setParams(params);
  }

  sendAfterFlow(): boolean {
    return true;
  }

  public initForm(): void {
    return;
  }

  public update(): void {
    return;
  }

  public save(toSave: any): void {
    toSave.jobIds = this.sender.model.jobs.map((j) => j.id);
    toSave.studentId = this.sender.model.students[0].id;
    this.sender.userService.getAll().subscribe((users) => {
      const raUsers = this.sender.model.jobs
        .map((j) =>
          j.jobUsers
            .filter((u) => u.type === EnterpriseDepartmentUserType.RA)
            .map((u) => (users.find((user) => user.id === u.userId) || { email: null }).email)
            .filter((m) => m),
        )
        .flatten();
      const paUsers = this.sender.model.jobs
        .map((j) =>
          j.jobUsers
            .filter((u) => u.type === EnterpriseDepartmentUserType.PA)
            .map((u) => (users.find((user) => user.id === u.userId) || { email: null }).email)
            .filter((m) => m),
        )
        .flatten();
      const asUsers = this.sender.model.jobs
        .map((j) =>
          j.jobUsers
            .filter((u) => u.type === EnterpriseDepartmentUserType.アシ)
            .map((u) => (users.find((user) => user.id === u.userId) || { email: null }).email)
            .filter((m) => m),
        )
        .flatten();
      toSave.to = raUsers.length ? raUsers : paUsers;
      toSave.cc = asUsers;
      if (this.sender.model.progressType === 1) {
        toSave.cc.push(this.sender.authService.loginUser.email);
      }
      if (this.sender.model.progressType == null) {
        this.sender.progressApiService.checkProgress(this.sender.model.students.map((s) => s.id), toSave.jobIds).subscribe((res) => {
          if (res && res.length) {
            this.sender.progressErrorInfo = res;
            PopupControlComponent.instance.open({
              cancelText: '中止',
              cancelCallback: () => this.sender.close(),
              confirmText: null,
              content: this.sender.progressErrorTemplate,
              title: '進捗重複',
              confirmCallback: null,
              multiConfirm: [
                {
                  confirmText: '重複案件は上書きしないで送信のみ行う',
                  confirmCallback: () =>
                    this.sender.mailApiService.bulkRecommend(toSave).pipe(tap((result) => this.sender.afterSend(result))),
                },
                {
                  confirmText: '重複案件は上書き',
                  confirmCallback: () =>
                    forkJoin(
                      this.sender.model.students.map((s) => this.sender.progressApiService.bulkRecommendStudent(s.id, toSave.jobIds)),
                    ).pipe(
                      mergeMap(() => this.sender.mailApiService.bulkRecommend(toSave).pipe(tap((result) => this.sender.afterSend(result)))),
                    ),
                },
                {
                  confirmText: '重複案件を除いて送信',
                  confirmEnabled: () =>
                    // disable when all of selected have '進捗重複' errors
                    !(this.sender.progressErrorInfo.length > 0 && this.sender.progressErrorInfo.length === toSave.jobIds.length),
                  confirmCallback: () => {
                    const studentsWithJobIds = {};
                    this.sender.model.students.forEach(
                      (student) =>
                        (studentsWithJobIds[student.id] = toSave.jobIds.filter(
                          (jobId) =>
                            !this.sender.progressErrorInfo.some((error) => error.studentId === student.id && error.jobId === jobId),
                        )),
                    );
                    return forkJoin(
                      this.sender.model.students
                        .filter((student) => studentsWithJobIds[student.id] && studentsWithJobIds[student.id].length)
                        .map((student) => this.sender.progressApiService.bulkRecommendStudent(student.id, studentsWithJobIds[student.id])),
                    ).pipe(
                      mergeMap(() =>
                        this.sender.mailApiService
                          .bulkRecommend({ ...toSave, jobIds: studentsWithJobIds[toSave.studentId] })
                          .pipe(tap((result) => this.sender.afterSend(result))),
                      ),
                    );
                  },
                  confirmClass: 'btn--green',
                },
              ],
            });
          } else {
            forkJoin(
              this.sender.model.students.map((s) => this.sender.progressApiService.bulkRecommendStudent(s.id, toSave.jobIds)),
            ).subscribe(() => {
              this.sender.mailApiService.bulkRecommend(toSave).subscribe((result) => this.sender.afterSend(result));
            });
          }
        });
      } else {
        this.sender.progressApiService
          .updateWithAction(this.sender.model.progressId, {
            action: this.sender.model.progressStatus === 20 ? 23 : 34,
            type: this.sender.model.progressType,
            hasInterview: this.sender.model.progressHasInterview,
            n: this.sender.model.progressN,
            seminarType: this.sender.model.progressSeminarType,
            seminarAt: this.sender.model.progressSeminarAt,
            seminarAtMailSendFlag: this.sender.model.progressSeminarAtMailSendFlag,
            isStudentDelay: this.sender.model.progressIsStudentDelay,
          })
          .pipe(mergeMap(() => this.sender.mailApiService.bulkRecommend(toSave)))
          .subscribe((result) => this.sender.afterSend(result));
      }
    });
  }
}
