import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@agent-ds/environments/environment';

/**
 * HTTPリクエストURLインターセプター
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  /**
   * HTTPリクエストURL設定処理
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        url: this.modifyUrl(req.url),
      }),
    );
  }

  /**
   * リクエストを修正する
   */
  private modifyUrl(url: string): string {
    if (/^https?:\/\//i.test(url)) {
      return url;
    }

    return url.startsWith('/api') ? `${environment.apiUrl}${url}` : url;
  }
}
