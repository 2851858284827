import { User } from '@agent-ds/shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private users: Observable<User[]>;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isLogined().subscribe((logined) => {
      if (logined) {
        this.users = null;
      }
    });
  }

  get(id: string): Observable<User> {
    return this.http.get<User>('/api/master/users/' + id);
  }

  getAll(): Observable<User[]> {
    if (!this.users) {
      this.users = this.http.get<User[]>('/api/master/users').pipe(shareReplay(1));
    }
    return this.users.pipe(first());
  }

  add(user: User): Observable<{ id: number }> {
    return this.http.post<{ id: number }>('/api/master/users', user).pipe(tap(() => (this.users = null)));
  }

  update(id: string, user: User): Observable<{ id: number }> {
    return this.http.put<{ id: number }>('/api/master/users/' + id, user).pipe(tap(() => (this.users = null)));
  }

  delete(id: string): void {
    this.http.delete('/api/master/users/' + id).pipe(tap(() => (this.users = null)));
  }
}
