import { AccountingInformationHelper } from './accounting-information-helper';
import { BillingExtraInformationHelper } from './billing-extra-info-helper';
import { BillingInformationHelper } from './billing-information-helper';
import { CancellationInformationHelper } from './cancellation-information-helper';
import { ClosingInformationHelper } from './closing-info-helper';
import { ContractorInformationHelper } from './contractor-info-helper';
import { OrderInformationHelper } from './order-info-helper';
import { OverviewHelper } from './overview-helper';
import { RemarkHelper } from './remark-helper';

export const SALES_DETAIL_HELPERS = [
  OverviewHelper,
  ClosingInformationHelper,
  OrderInformationHelper,
  CancellationInformationHelper,
  ContractorInformationHelper,
  BillingInformationHelper,
  BillingExtraInformationHelper,
  AccountingInformationHelper,
  RemarkHelper,
];
