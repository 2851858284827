import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentBulkConfig } from '@agent-ds/shared/components/mail-send/configs/student-bulk-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { StudentListComponent } from '@agent-ds/shared/components/student-list/student-list.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, DialogService, ExportApiService } from '@agent-ds/shared/services';
import { cleanObject } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentDetailPageComponent } from '../student-detail-page/student-detail-page.component';

@Component({
  selector: 'ag-student-list-page',
  templateUrl: './student-list-page.component.html',
  styleUrls: ['./student-list-page.component.scss'],
})
export class StudentListPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild('studentList', { static: false }) table: StudentListComponent;
  @ViewChild('export', { static: false }) exportTemplate: TemplateRef<any>;
  protected readonly model = 'students';
  protected readonly detail = StudentDetailPageComponent as any;
  protected readonly exportModel = ExportModel.student;

  listConfiguration: any = { body: { checkbox: true } };

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
    private dialog: DialogService,
    private authService: AuthService,
  ) {
    super(router, location, activeRoute, exportService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService.isLogined().subscribe(() => {
      // 副担当者かどうかを確認
      const isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
      if (isIntakeCa) {
        this.router.navigate(['/students/searchWithTodaysCounseling']);
      }
    });
  }

  sendMail(): void {
    const bulkSendConfig = new StudentBulkConfig();
    MailSendFlowComponent.instance.config = bulkSendConfig;
    bulkSendConfig.setParams({ condition: this.searchObj });
    MailSendFlowComponent.instance.start();
  }

  bulkUpdate(): void {
    this.dialog.open(BatchUpdateDialogComponent, {
      data: {
        condition: cleanObject({
          ...this.searchObj,
          from: 0,
          size: this.table.totalSize,
        }),
      },
    });
  }

  showExport(): void {
    PopupControlComponent.instance.open({
      title: 'CSV出力',
      content: this.exportTemplate,
      confirmText: 'ダウンロード',
      confirmEnabled: () => this.exportTemplateId != null,
      confirmCallback: () => this.exportService.export(this.exportModel, this.exportTemplateId, this.searchObj),
      cancelText: '閉じる',
    });
  }
}
