import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { TAX_PERCENTAGE } from '@agent-ds/shared/constants/consts';
import { CompanyDetail, Contract, EnterpriseDepthContacts, Job, SalesNotificationMailSendRequest } from '@agent-ds/shared/interfaces';
import { SaleDetail } from '@agent-ds/shared/interfaces/sale';
import { FormMeta, GroupMeta } from '@agent-ds/shared/models';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import {
  AuthService,
  CompanyApiService,
  DialogService,
  DynamicFieldService,
  JobApiService,
  MailApiService,
  MasterApiService,
  ProgressApiService,
  SalesApiService,
  StudentApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, asNativeElements, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AccountingInformationHelper } from './helpers/accounting-information-helper';
import { BillingExtraInformationHelper } from './helpers/billing-extra-info-helper';
import { BillingInformationHelper } from './helpers/billing-information-helper';
import { CancellationInformationHelper } from './helpers/cancellation-information-helper';
import { ClosingInformationHelper } from './helpers/closing-info-helper';
import { ContractorInformationHelper } from './helpers/contractor-info-helper';
import { MAX_FEE_VALUE, OrderInformationHelper } from './helpers/order-info-helper';
import { OverviewHelper } from './helpers/overview-helper';
import { RemarkHelper } from './helpers/remark-helper';

@Component({
  selector: 'ag-sales-detail-page',
  templateUrl: './sales-detail-page.component.html',
  styleUrls: ['./sales-detail-page.component.scss'],
})
export class SalesDetailPageComponent extends DetailPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('leftForm', { static: false }) leftForm: DynamicFormComponent;
  @ViewChild('rightForm', { static: false }) rightForm: DynamicFormComponent;
  @ViewChild('bottomForm', { static: false }) bottomForm: DynamicFormComponent;
  @ViewChild('cancellationHeader', { static: false }) cancellationHeaderButtons: TemplateRef<any>;
  @ViewChild('reApplyPopup', { static: false }) reApplyPopupTemplate: TemplateRef<any>;
  public sideActions: SideActionConfig[] = [{ phases: ['1000px', '100%'] }];
  protected tabs = {};
  protected urlTag = 'sales';
  protected cancellationInfo: GroupMeta[] = [];
  protected billingExtraInfo: GroupMeta[] = [];

  sale: SaleDetail;

  private fieldSubscription: Subscription;

  model: any = {};
  modelLeft = {};
  modelRight = {};
  modelBottomFields = {};
  footerButtons: { id?: number; title: string; action: (item?: string) => void }[] = [];

  metadataLeft: FormMeta = { groups: [] };
  metadataRight: FormMeta = { groups: [] };
  metadataBottomFields: FormMeta = { groups: [] };

  validity = false;
  private validityArray = [false, false];

  private buttonMetaByState = [
    [], // undefined
    [
      {
        // not approved
        id: 1,
        title: '計上承認', // approve
        action: () => {
          this.setStatusAndUpdate(2);
        },
      },
      {
        id: 2,
        title: '否認', // deny
        action: () => {
          this.setStatusAndUpdate(3);
        },
      },
    ],
    [
      {
        // approved
        id: 3,
        title: '承認取消', // cancel approval
        action: () => {
          this.resetFormForApprovalCancellation();
          this.setStatusAndUpdate(1, 1);
        },
      },
    ],
    [
      {
        // denied
        id: 4,
        title: '再申請', // apply again
        action: () => {
          this.setStatusAndUpdate(1, null, this.sendSalesNotificationPreconditions.bind(this), this.sendSalesNotificationEmail.bind(this));
        },
      },
    ],
  ];

  fieldsUpToDate = false;
  contractOptions: any[];
  contractSubject: Subject<any[]> = new Subject<any[]>();
  billingOptions: any[];
  billingSubject: Subject<{ billingOptions: any[]; enterpriseFrontid: string }> = new Subject<{
    billingOptions: any[];
    enterpriseFrontid: string;
  }>();
  saleSubject: Subject<SaleDetail> = new Subject<SaleDetail>();
  updatePending = false;
  emailModel: any = {};

  constructor(
    private dynamicService: DynamicFieldService,
    private dialogService: DialogService,
    private salesApiService: SalesApiService,
    private jobService: JobApiService,
    private progressService: ProgressApiService,
    private enterpriseService: CompanyApiService,
    private studentService: StudentApiService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    private overviewHelper: OverviewHelper,
    private closingInformationHelper: ClosingInformationHelper,
    private orderInformationHelper: OrderInformationHelper,
    private cancellationInformationHelper: CancellationInformationHelper,
    private contractorInformationHelper: ContractorInformationHelper,
    private billingInformationHelper: BillingInformationHelper,
    private billingExtraInformationHelper: BillingExtraInformationHelper,
    private accountingInformationHelper: AccountingInformationHelper,
    private remarkHelper: RemarkHelper,
    private numberFormat: DecimalPipe,
    private mailApiService: MailApiService,
    private authService: AuthService,
    private userApiService: UserApiService,
    private masterApiService: MasterApiService,
  ) {
    super(router, salesApiService, activeRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.fieldsUpToDate = false;
      this.updateAllMetadata();
    });
  }

  registerActions(): void {
    this.orderInformationHelper.calculateTaxActions = [
      {
        title: '計算',
        type: 'RUNNABLE',
        runnable: () => {
          if (!this.validityArray[0] && (isNaN(this.model.sales.dynamicData.fee) || this.model.sales.dynamicData.fee > MAX_FEE_VALUE)) {
            const config: ConfirmDialogConfig = {
              title: '入力値が不正です',
              messages: ['フィーの金額が正しくありません'],
              style: {
                height: '245px',
                width: '510px',
              },
              buttons: {
                no: '',
                hideNo: true,
                yes: 'OK',
              },
            };
            this.dialogService.open(ConfirmDialogComponent, config);
          } else if (this.sale && this.sale.dynamicData) {
            this.sale.dynamicData.taxFee = Math.floor(this.sale.dynamicData.fee * TAX_PERCENTAGE);
            this.sale.dynamicData.chargeFee = Math.floor(this.sale.dynamicData.fee + this.sale.dynamicData.taxFee);

            if (this.sale.raPercentage) {
              this.sale.raReward = Math.floor(this.sale.dynamicData.fee * this.sale.raPercentage * 0.01);
            }
            if (this.sale.paPercentage) {
              this.sale.paReward = Math.floor(this.sale.dynamicData.fee * this.sale.paPercentage * 0.01);
            }
            if (this.sale.caPercentage) {
              this.sale.caReward = Math.floor(this.sale.dynamicData.fee * this.sale.caPercentage * 0.01);
            }
          }
        },
      },
    ];

    this.cancellationInformationHelper.calculateRefoundAmount = [
      {
        title: '返金額計算',
        type: 'RUNNABLE',
        runnable: () => {
          if (this.sale && this.sale.dynamicData.refundPercent) {
            if (this.sale.dynamicData.chargeFee) {
              this.sale.dynamicData.refundPrice = Math.floor(this.sale.dynamicData.fee * this.sale.dynamicData.refundPercent * 0.01);
            }
            if (this.sale.caPercentage) {
              this.sale.caCancelPrice = Math.floor(this.sale.dynamicData.refundPrice * this.sale.caPercentage * 0.01);
            }
            if (this.sale.raPercentage) {
              this.sale.raCancelPrice = Math.floor(this.sale.dynamicData.refundPrice * this.sale.raPercentage * 0.01);
            }
            if (this.sale.paPercentage) {
              this.sale.paCancelPrice = Math.floor(this.sale.dynamicData.refundPrice * this.sale.paPercentage * 0.01);
            }
          }
        },
      },
    ];
  }

  ngAfterViewInit(): void {
    this.closingInformationHelper.init(this);
    this.orderInformationHelper.init(this);
    this.cancellationInformationHelper.init(this);
    this.contractorInformationHelper.init(this);
    this.billingInformationHelper.init(this);
    this.billingExtraInformationHelper.init(this);
    this.accountingInformationHelper.init(this);
    this.cancellationInformationHelper.cancellationButtons = this.cancellationHeaderButtons;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }

    this.contractSubject.complete();
    this.billingSubject.complete();
    this.saleSubject.complete();
  }

  protected fill(): void {
    if (this.referenceId) {
      this.updateUrl();
      this.salesApiService.getDetail(this.referenceId).subscribe((sale: SaleDetail) => {
        const prevSale = this.sale;
        this.sale = sale;
        this.model = { sales: sale };

        if (this.sale) {
          if (!prevSale || this.sale.status !== prevSale.status || this.sale.cancelStatus !== prevSale.cancelStatus) {
            this.updateAllMetadata();
          }

          this.loadJobAndEnterprise(sale);
          this.loadProgressAndStudent(sale);
        }

        this.saleSubject.next(this.sale);
      });
    }
  }

  updateAllMetadata(): void {
    if (this.sale) {
      this.metadataLeft = this.getFormMetadataLeft(this.sale.status, this.sale.cancelStatus);
      this.metadataRight = this.getFormMetadataRight(this.sale.status, this.sale.cancelStatus);
      this.metadataBottomFields = this.getFormMetadataBottom(this.sale.status, this.sale.cancelStatus);
      this.footerButtons = this.getFooterButtons(this.sale.status);
      this.fieldsUpToDate = true;
      this.registerActions();
    }
  }

  loadProgressAndStudent(sale: SaleDetail): void {
    if (sale.progressId) {
      this.progressService.getDetail(sale.progressId).subscribe((progress) => {
        this.model.progress = progress;

        if (progress && progress.studentId) {
          this.studentService.getDetail(progress.studentId).subscribe((student) => {
            this.model.student = student;
          });
        }
      });
    } else if (sale.studentId) {
      this.studentService.getDetail(sale.studentId).subscribe((student) => {
        this.model.student = student;
      });
    }
  }

  loadJobAndEnterprise(sale: SaleDetail): void {
    if (sale.jobId) {
      this.jobService.getDetail(sale.jobId).subscribe((job: Job) => {
        this.model.job = job;
        if (job && job.enterpriseId) {
          const enterpriseFrontIdSubject: Subject<string> = new Subject<string>();

          this.enterpriseService.getDetail(job.enterpriseId).subscribe((enterprise: CompanyDetail) => {
            this.model.enterprise = enterprise;
            enterpriseFrontIdSubject.next(enterprise && enterprise.frontId ? enterprise.frontId : '');
            enterpriseFrontIdSubject.unsubscribe();
          });

          this.enterpriseService.getContracts(job.enterpriseId).subscribe((contracts: Contract[]) => {
            this.contractOptions = contracts.map((contract: Contract) => {
              contract.dynamicData.id = contract.id;
              contract.dynamicData.frontId = contract.frontId;
              contract.dynamicData.label = [
                contract.frontId,
                contract.dynamicData.companyName,
                contract.dynamicData.departmentName,
                contract.dynamicData.contractClassification,
              ]
                .filter((v) => v)
                .join(' ');
              return contract.dynamicData;
            });
            this.contractSubject.next(this.contractOptions);

            let text = '';
            if (this.sale && this.sale.contractId) {
              const contract = this.contractOptions.find((contr) => {
                return contr.id === this.sale.contractId;
              });
              if (contract) {
                for (let i = 1; i < 6; i++) {
                  if (contract['fee' + i + 'Price']) {
                    text += `${this.numberFormat.transform(contract['fee' + i + 'Price'])}\nフィー規定備考: ${
                      contract['fee' + i + 'Remarks']
                    }\n\n`;
                  }
                }
              }
            }
            this.model['contract-priceInformation'] = text;
            this.rightForm.updateModel('sales.contractId');
          });

          this.enterpriseService.getDepartments(job.enterpriseId).subscribe((contacts: EnterpriseDepthContacts) => {
            this.model.department = contacts.enterpriseDepartments.find((department) => department.id === job.enterpriseDepartmentId);
            this.billingOptions =
              contacts && contacts.enterpriseBillingAddresses && contacts.enterpriseBillingAddresses.length
                ? contacts.enterpriseBillingAddresses.map((billing) => {
                    billing.dynamicData.id = billing.id;
                    billing.dynamicData.label = [
                      billing.dynamicData.companyName,
                      billing.dynamicData.departmentName,
                      billing.dynamicData.contact.name,
                    ]
                      .filter((v) => v)
                      .join(' ');
                    return billing.dynamicData;
                  })
                : [];

            if (enterpriseFrontIdSubject.closed) {
              this.billingSubject.next({
                billingOptions: this.billingOptions,
                enterpriseFrontid: this.model.enterprise && this.model.enterprise.frontId ? this.model.enterprise.frontId : '',
              });
              this.rightForm.updateModel('sales.enterpriseBillingAddressId');
            } else {
              enterpriseFrontIdSubject.subscribe((frontId) => {
                this.billingSubject.next({ billingOptions: this.billingOptions, enterpriseFrontid: frontId });
                this.rightForm.updateModel('sales.enterpriseBillingAddressId');
              });
            }
          });
        }
      });
    }
  }

  getFormMetadataLeft(approvalStatus: number, cancellationStaus: number): FormMeta {
    approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
    cancellationStaus = cancellationStaus || 0;

    const res: FormMeta = {
      groups: [],
    };

    const overview: GroupMeta[] = this.overviewHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...overview);

    const closingInfo: GroupMeta[] = this.closingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...closingInfo);

    const orderInfo: GroupMeta[] = this.orderInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...orderInfo);

    this.cancellationInfo = this.cancellationInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...this.cancellationInfo);
    res.groups = res.groups.filter((group) => (group ? true : false));

    return res;
  }

  getFormMetadataRight(approvalStatus: number, cancellationStaus: number): FormMeta {
    approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
    cancellationStaus = cancellationStaus || 0;

    const res: FormMeta = {
      groups: [],
    };

    const contractorInfo: GroupMeta[] = this.contractorInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...contractorInfo);

    const billingInfo: GroupMeta[] = this.billingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...billingInfo);

    this.billingExtraInfo = this.billingExtraInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...this.billingExtraInfo);
    res.groups = res.groups.filter((group) => (group ? true : false));
    return res;
  }

  getFormMetadataBottom(approvalStatus: number, cancellationStaus: number): FormMeta {
    approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
    cancellationStaus = cancellationStaus || 0;

    const res: FormMeta = {
      groups: [],
    };

    const accounting: GroupMeta[] = this.accountingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...accounting);

    const remrks: GroupMeta[] = this.remarkHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
    res.groups.push(...remrks);
    res.groups = res.groups.filter((group) => (group ? true : false));

    return res;
  }

  getFooterButtons(approvalStatus: number): { id?: number; title: string; action: (item?: string) => void }[] {
    approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0

    return this.buttonMetaByState[approvalStatus];
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  resetFormForApprovalCancellation() {
    // clear dynamic fields
    const editableCancellationFieldNames: string[] = [];

    if (this.cancellationInfo && this.cancellationInfo.length) {
      editableCancellationFieldNames.push(
        ...this.cancellationInfo[0].rows.filter((row) => row.fields.length).map((item) => item.fields[0].name),
      );
    }

    if (this.billingExtraInfo && this.billingExtraInfo.length) {
      editableCancellationFieldNames.push(
        ...this.billingExtraInfo[0].rows.filter((row) => row.fields.length).map((item) => item.fields[0].name),
      );
    }

    editableCancellationFieldNames.forEach((name) => {
      const splitted = name.split('.');

      const accessAndClear = (value: any, level: number) => {
        if (level < splitted.length - 1) {
          accessAndClear(value[splitted[level]], level + 1);
        } else {
          value[splitted[level]] = null;
        }
      };
      accessAndClear(this.model, 0);
    });

    // clear static fields
    this.model.sales.caCancelPrice = null;
    this.model.sales.raCancelPrice = null;
    this.model.sales.paCancelPrice = null;
  }

  setStatusAndUpdate(
    newStatus: number,
    cancellationStatus?: number,
    preconditionHook?: () => Promise<any>,
    successAction?: (context: any) => void,
  ): void {
    if (this.sale) {
      this.updateSale(newStatus, cancellationStatus, preconditionHook, successAction);
    }
  }

  updateSaleInternal(newStatus: number, cancellationStatus?: number, successAction?: (context: any) => void, context?: any) {
    this.salesApiService.update(this.sale, newStatus, cancellationStatus).subscribe(
      () => {
        this.sale.status = newStatus;
        this.sale.cancelStatus = cancellationStatus;
        this.updateAllMetadata();
        if (successAction) {
          successAction(context);
        }
        this.salesApiService.refreshEvent.emit();
        this.updatePending = false;
      },
      () => {
        this.updatePending = false;
      },
    );
  }

  updateSale(
    newStatus: number,
    cancellationStatus?: number,
    preconditionHook?: () => Promise<any>,
    successAction?: (context: any) => void,
  ) {
    if (this.sale) {
      this.updatePending = true;
      if (preconditionHook) {
        preconditionHook().then(
          (context) => {
            this.updateSaleInternal(newStatus, cancellationStatus, successAction, context);
          },
          () => (this.updatePending = false),
        );
      } else {
        this.updateSaleInternal(newStatus, cancellationStatus, successAction);
      }
    }
  }

  updateSaleInternalBtoB() {
    this.salesApiService.linkBtoBSale(this.sale.id).subscribe(
      () => {
        this.updateAllMetadata();
        this.salesApiService.refreshEvent.emit();
        this.updatePending = false;
      },
      () => {
        this.updatePending = false;
      },
    );
  }

  updateSaleBtoB() {
    if (this.sale) {
      this.updatePending = true;
      this.updateSaleInternalBtoB();
    }
  }

  openSaleLinkBtoBDialog(): void {
    if (!(this.sale && this.sale.status === 2)) {
      return;
    }

    const config: ConfirmDialogConfig = {
      title: '請求書連携 → BtoB',
      messages: [`この制約[${this.sale.frontId}]をBtoBへ連携し、請求書を作成します。よろしいですか？`],
      style: {
        height: 'auto',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config, (result: boolean) => {
      if (result) {
        this.updateSaleBtoB();
      }
    });
  }

  sendSalesNotificationPreconditions(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.sale && this.sale.id != null && this.sale.raUserId) {
        const teams = this.masterApiService.getTeams();
        const users = this.userApiService.getAll();
        const templates = this.mailApiService.getMailTemplates();

        forkJoin([teams, users, templates]).subscribe((results) => {
          const context: any = {};

          context.raUser = results[1].find((user) => user.id === this.sale.raUserId);
          context.caUser = results[1].find((user) => user.id === this.sale.caUserId);
          context.paUser = results[1].find((user) => user.id === this.sale.paUserId);
          context.raTeam = results[0].find((team) => team.id === context.raUser.teamId);
          context.template = results[2].find((mailTemplate) => mailTemplate.id === 22);

          const issues = [];

          if (!(context.raTeam && context.raTeam.manager)) {
            issues.push(' 担当RAのチームに代表者が設定されていません。');
          }
          if (!context.template) {
            issues.push('メールテンプレートが設定されていません');
          }
          if (
            !(this.authService.loginUser && this.authService.loginUser.email) ||
            (context.raTeam.manager && !context.raTeam.manager.email)
          ) {
            issues.push('Technical issue with user emails');
          }

          if (issues.length) {
            const config: ConfirmDialogConfig = {
              title: '入力値が不正です',
              messages: issues,
              style: {
                height: '245px',
                width: '510px',
              },
              buttons: {
                no: 'キャンセル',
                hideNo: true,
                yes: 'OK',
              },
            };
            this.dialogService.open(ConfirmDialogComponent, config);
            reject(issues);
          } else {
            this.emailModel.email = null;
            PopupControlComponent.instance.open({
              title: '再申請',
              content: this.reApplyPopupTemplate,
              confirmText: '再申請',
              confirmEnabled: () => this.emailModel['email'] != null && this.emailModel['email'].trim(),
              confirmCallback: () => {
                resolve(context);
              },
              cancelText: 'キャンセル',
              cancelCallback: () => reject('cancel'),
            });
          }
        });
      } else {
        reject([]);
      }
    });
  }

  sendSalesNotificationEmail(context: any) {
    if (this.sale && this.sale.id != null && this.sale.raUserId) {
      const notifyUsers = [context.raUser, context.caUser, context.paUser];

      const salesNotificationRequest: SalesNotificationMailSendRequest = {
        userId: this.authService.loginUser.id,
        subject: context.template.subject,
        text: `${context.template.body && context.template.body.trim() ? context.template.body.trim() + '\n\n' : ''}${this.emailModel[
          'email'
        ].trim()}`,
        from: this.authService.loginUser.email,
        to: [context.raTeam.manager.email],
        cc: notifyUsers.filter((u) => u && u.email).map((usr) => usr.email),
        salesId: this.sale.id,
      };

      this.mailApiService
        .replaceTemplate(context.template.mailTemplateTypeId, {
          from: salesNotificationRequest.from,
          to: salesNotificationRequest.to,
          salesId: salesNotificationRequest.salesId,
          subject: salesNotificationRequest.subject,
          text: salesNotificationRequest.text,
        })
        .pipe(
          mergeMap((replaced) =>
            this.mailApiService.sendSalesNotification({
              ...salesNotificationRequest,
              text: replaced.body,
              subject: replaced.subject,
            }),
          ),
        )
        .subscribe();
    }
  }
}
