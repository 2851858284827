import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { DynamicField, FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { deepClone, multiFill } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss'],
})
export class JobSearchComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();
  @Output() search = new EventEmitter<{}>();
  @Output() filterChanged = new EventEmitter<{}>();

  innerSearchModel: any = {};

  get searchModel(): any {
    return this.innerSearchModel;
  }

  @Input()
  set searchModel(model: any) {
    this.innerSearchModel = { ...model };
    if (this.innerSearchModel.status == null) {
      this.innerSearchModel.status = ['募集中'];
    }
  }

  leftMeta: FormMeta;
  rightMeta: FormMeta;
  customMeta: FormMeta;

  private fieldSubscription: Subscription;

  constructor(private dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.leftMeta = {
        groups: [
          {
            title: '業種・職種',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'industry'),
                  displayType: 'multi-enterprise',
                }),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'jobType', label: '職種', fieldType: 'job-type', displayType: 'multi-job' },
                null,
                'third',
              ),
            ],
          },
          {
            title: '企業情報',
            class: 'form__group--green-title-border',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows(
                  multiFill(this.dynamicService.getDefinition('enterprise', 'name')),
                  null,
                  'half',
                )[0];
                row.fields[0].name = 'enterpriseName';
                return row;
              })(),
              (() => {
                const row = this.dynamicService.getFormRows(
                  multiFill(this.dynamicService.getDefinition('enterprise', 'phoneticName')),
                  null,
                  'half',
                )[0];
                row.fields[0].name = 'enterprisePhoneticName';
                return row;
              })(),
            ],
          },
          {
            title: '基本情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'gender'), displayType: 'checkbox' }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'transfer'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'jobTransfer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'firstPlaceOfAssignment')),
              {
                title: '勤務地の都道府県',
                fields: [
                  {
                    name: 'prefecture',
                    type: 'dropdown',
                    class: 'third',
                    multi: true,
                    options: [
                      NULL_SELECTED_VALUE,
                      '北海道',
                      '青森県',
                      '岩手県',
                      '宮城県',
                      '秋田県',
                      '山形県',
                      '福島県',
                      '茨城県',
                      '栃木県',
                      '群馬県',
                      '埼玉県',
                      '千葉県',
                      '東京都',
                      '神奈川県',
                      '新潟県',
                      '富山県',
                      '石川県',
                      '福井県',
                      '山梨県',
                      '長野県',
                      '岐阜県',
                      '静岡県',
                      '愛知県',
                      '三重県',
                      '滋賀県',
                      '京都府',
                      '大阪府',
                      '兵庫県',
                      '奈良県',
                      '和歌山県',
                      '鳥取県',
                      '島根県',
                      '岡山県',
                      '広島県',
                      '山口県',
                      '徳島県',
                      '香川県',
                      '愛媛県',
                      '高知県',
                      '福岡県',
                      '佐賀県',
                      '長崎県',
                      '熊本県',
                      '大分県',
                      '宮崎県',
                      '鹿児島県',
                      '沖縄県',
                    ],
                  },
                ],
              },
              {
                title: '年間休日',
                fields: [
                  {
                    name: 'annualHolidays',
                    type: 'checkbox',
                    multi: true,
                    options: [
                      NULL_SELECTED_VALUE,
                      '120日以上',
                      '完全週休2日制（かつ土日祝日）',
                      '土日いずれか固定休',
                      '月1でも土日希望休可（シフト）',
                    ],
                  },
                ],
              },
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'overtimeWork')),
            ],
          },
          {
            title: '担当情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows({ fieldName: 'paraTeamId', label: 'PA・RAチーム', fieldType: 'multi-team' }, null, 'half'),
              ...this.dynamicService.getFormRows({ fieldName: 'raId', label: 'RA', fieldType: 'multi-user' }, null, 'half'),
              ...this.dynamicService.getFormRows({ fieldName: 'paId', label: 'PA', fieldType: 'multi-user' }, null, 'half'),
              ...this.dynamicService.getFormRows({ fieldName: 'asiId', label: 'アシスタント', fieldType: 'multi-user' }, null, 'half'),
            ],
          },
          {
            title: 'その他',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'characterAndType1'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'characterAndType2'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'characterAndType3'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'characterAndType4'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'characterAndType5'))),
              {
                title: '直近3年 成約実績',
                fields: [
                  {
                    name: 'hasSales',
                    type: 'checkbox',
                    multi: true,
                    options: ['有り', '無し'],
                  },
                ],
              },
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('enterprise', 'marketName'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              {
                title: '従業員数',
                fields: [
                  {
                    name: 'numberEmployees',
                    type: 'checkbox',
                    multi: true,
                    options: ['50名未満', '50名以上', '100名以上', '500名以上', '1000名以上'],
                  },
                ],
              },
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itTypeCareer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itTypeArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itAppealFlag')),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'pgExperience'))),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salesJobFlag')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salesJobProduct')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'flexibleWorkingFlag')),
            ],
          },
        ],
      };
      this.rightMeta = {
        groups: [
          {
            title: 'ステータス',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('job', 'recommendationDateKind'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                  label: '採用熟度',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill(
                  {
                    ...this.dynamicService.getDefinition('job', 'status'),
                    fieldType: 'multi-list',
                  },
                  false,
                ),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'recruitYear'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'screeningArea')), null, 'full'),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'workArea')), null, 'full'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'webBasedScreening')),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'rank'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
            ],
          },
          {
            title: '学歴・スキル情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'communicationSkill'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'impressionAndCharm'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'lastAcademicRecord')), null, 'third'),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'afterGraduate'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'postGraduate'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'universityLevel')), null, 'third'),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'academicFieldType')), null, 'third'),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'academicCourseType'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'firstPriorityFaculty'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'secondPriorityFaculty'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'scienceProjectFlag'))),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'scienceRecruitmentCategory'), fieldType: 'multi-list' }),
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'repeatYearType'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'roninYearType'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'certificates'))),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'languageSkill')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'exceptionalExperience')),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('job', 'internationalStudent'), fieldType: 'multi-list' }),
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('job', 'pastGlobalCountry'))),
            ],
          },
        ],
      };
      const customs = this.dynamicService.getDefinitions('job').filter((def) => def.isCustomField && def.isSearchable);
      if (customs.length) {
        this.customMeta = {
          groups: [
            {
              title: 'カスタムフィールド',
              class: 'form__group--green-title-border',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onCloseClick(): void {
    this.closed.emit();
  }

  public doSearch(event?: any): void {
    if (!event || !event['isComposing']) {
      this.search.emit({ ...this.searchModel });
    }
  }

  onFormChange(): void {
    this.filterChanged.emit({ ...this.searchModel });
  }
}
