import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataFile, ReportCsv, StudentDataFile } from '../../interfaces';
import { download, openFile } from '../../util/util';
import { StudentApiService } from './student-api.service';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  constructor(private http: HttpClient, private studentService: StudentApiService) {}

  getFiles(model: 'students' | 'enterprise' = 'students', id: number): Observable<DataFile[]> {
    return this.http.get<DataFile[]>(`/api/${model}/${id}/files`);
  }

  uploadFile(
    model: 'students' | 'enterprise' = 'students',
    id: number,
    file: File,
    fileName?: string,
    fileId?: number,
    enterpriseSendFlag?: number,
    studentSendFlag?: number,
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, fileName || file.name);
    formData.append('enterpriseSendFlag', `${enterpriseSendFlag}`);
    formData.append('studentSendFlag', `${studentSendFlag}`);
    const url = `/api/${model}/${id}/file` + (fileId ? `/${fileId}/replace` : '');
    const obs = fileId ? this.http.put(url, formData) : this.http.post(url, formData);
    return model === 'students' && fileName && fileName.startsWith('face_')
      ? obs.pipe(
          tap(() => {
            CACHE.clear(`/api/students/${id}/profile-image/`);
            this.studentService.refreshEvent.emit();
          }),
        )
      : obs;
  }

  downloadFile(model: 'students' | 'enterprise' = 'students', id: number, fileId: number, fileName?: string): void {
    this.http.get(`/api/${model}/${id}/file/${fileId}`, { responseType: 'blob' }).subscribe((res) => download(res, fileName));
  }

  openFile(model: 'students' | 'enterprise' = 'students', id: number, fileId: number, fileName?: string): void {
    this.http.get(`/api/${model}/${id}/file/${fileId}`, { responseType: 'blob' }).subscribe((res) => openFile(res, fileName));
  }

  deleteFile(model: 'students' | 'enterprise' = 'students', id: number, fileId: number): Observable<any> {
    return this.http.delete(`/api/${model}/${id}/file/${fileId}`);
  }

  downloadSecondMatchCsv(from: string, to: string): Observable<HttpResponse<Blob>> {
    return this.http.get('/api/second-matching/file/csv', {
      responseType: 'blob',
      observe: 'response',
      params: { from: from, to: to },
      headers: {
        'Content-Type': 'application/csv',
        Accept: 'application/csv',
      },
    });
  }

  getReportCsvList(): Observable<ReportCsv[]> {
    return this.http.get<ReportCsv[]>('/api/published-csv');
  }

  downloadReportCsv(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`/api/published-csv/${id}`, { responseType: 'blob', observe: 'response' });
  }

  getUnconfirmedFiles(from: number, size: number): Observable<{ totalSize: number; files: StudentDataFile[] }> {
    return this.http.get<{ totalSize: number; files: StudentDataFile[] }>('/api/students/uploads', {
      params: {
        from: from,
        size: 9999, // page-scroll-table.component のスクロール処理が動かなかったので、一旦sizeを大きくする
      } as any,
    });
  }

  renameStudentUploadedFile(studentId: number, fileId: number, fileName: string): Observable<DataFile> {
    return this.http.put<DataFile>(`/api/students/${studentId}/file/${fileId}/rename`, { name: fileName });
  }

  changeToSendable(studentId: number, fileId: number): Observable<DataFile> {
    return this.http.put<DataFile>(`/api/students/${studentId}/file/${fileId}/change-to-sendable`, {});
  }
}
