import { environment } from '@agent-ds/environments/environment';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUser, EnterpriseDepartmentUserType, MailTemplateResponse } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupConfig, MailPopupType } from '../config';
import { StudentSendConfig } from './student-send-config';

export class StudentDetailConfig extends StudentSendConfig {
  private contacts: any[];

  public getTemplateId(): number {
    return 2;
  }

  public getTemplateTypeId(): number[] {
    return [3];
  }

  public getPopupConfig(): MailPopupConfig[] {
    return [{ type: MailPopupType.JOB }];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.jobs = params.jobs || [];
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.student || !this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    const enterprise = this.sender.model.enterprise;
    const department = this.sender.model.department;

    metaData.groups[0].rows.push({
      title: 'To (企業・部署)',
      showRequired: true,
      class: 'ou',
      fields: [
        {
          name: 'enterpriseName',
          type: 'label',
          class: 'pre',
          supplier: () =>
            `${enterprise.frontId} ${enterprise.dynamicData.name}${enterprise.dynamicData.enterpriseNo}\n${department.dynamicData.name}`,
        },
        !this.sender.inConfirm
          ? {
              name: 'to',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              class: 'multi-ou',
              style: { 'margin-left': '29px' },
              multi: true,
              options: department
                ? this.contacts.map((c) => {
                    return {
                      label: `${c.name} <${c.email}>`,
                      value: c.email,
                    };
                  })
                : [],
              validators: { required: true },
            }
          : {
              name: 'to',
              type: 'label',
              supplier: () =>
                this.sender ? this.contacts.filter((c) => this.sender.model.to.includes(c.email)).map((c) => `${c.name} <${c.email}>`) : '',
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (企業、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        if (!this.sender.inConfirm) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              labelBefore: '企業',
              class: 'full multi-ou sticky-label',
              multi: true,
              options: department
                ? this.contacts.map((c) => {
                    return {
                      label: `${c.name} <${c.email}>`,
                      value: c.email,
                    };
                  })
                : [],
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        } else if (this.sender.model.cc_single.length) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'label',
              class: 'full',
              supplier: () =>
                this.contacts.filter((c) => this.sender.model.cc_single.includes(c.email)).map((c) => `${c.name} <${c.email}>`),
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        }
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: 'テンプレート選択',
        fields: [
          {
            name: 'dummyTemplateType',
            type: 'autocomplete',
            linkTo: ['subject', 'body'],
            placeholder: 'テンプレート名',
            labelField: 'name',
            class: 'half',
            options: [],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const typeIds = getValue('templateTypeIds');
              return this.sender.mailApiService.getMailTemplateNames().pipe(
                map((res) => {
                  const filtered = res.filter((r) => r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)));
                  return {
                    options: filtered,
                  };
                }),
              );
            },
          },
        ],
      });
    }
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                if (value) {
                  return value;
                }
                const template = getValue('dummyTemplateType');
                if (!template) {
                  return undefined;
                }
                const templateId: number = template ? template.id : 0;
                return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                  map((res: MailTemplateResponse) => {
                    return {
                      value: res ? res.subject : undefined,
                    };
                  }),
                );
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });

    metaData.groups[0].rows.push(
      (() => {
        const row = ({
          title: '添付ファイル',
          fields: [
            {
              type: 'label',
              name: 'jobs',
              linkTo: ['enterpriseFileIds'],
              supplier: () => this.sender.model.jobs.map((j: any) => `${j.frontEnterpriseId} ${j.enterpriseName}`).join(', '),
            },
          ],
        } as unknown) as RowMeta;
        row.class = 'ou';
        if (!this.sender.inConfirm) {
          row.fields[0].labelBefore = '選択した企業(求人)：';
          row.fields[0].linkTo = ['enterpriseFileIds'];
        } else {
          row.fields.length = 0;
        }

        if (!this.sender.inConfirm) {
          row.fields.push({
            name: 'enterpriseFileIds',
            type: 'autocomplete',
            labelField: [
              {
                name: 'name',
              },
            ],
            labelBefore: '企業：',
            valueField: 'id',
            multi: true,
            class: 'options-only half',
            options: [],
            actions: [
              {
                title: 'データフォルダから選択',
                type: 'RUNNABLE',
                runnable: () => {
                  this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
                  PopupControlComponent.instance.open({
                    content: this.sender.fileSelectTemplate,
                    confirmText: '添付',
                    cancelText: 'キャンセル',
                    title: 'データフォルダ',
                    confirmCallback: () => {
                      this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
                    },
                  });
                },
              },
            ],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              this.sender.enterpriseFiles = {};
              this.sender.model.jobs.forEach(
                (j) => (this.sender.enterpriseInfoById[j.enterpriseId] = `${j.frontEnterpriseId} ${j.enterpriseName}`),
              );
              return forkJoin(
                this.sender.model.jobs.map((j) => {
                  return this.sender.fileService.getFiles('enterprise', j.enterpriseId).pipe(
                    map((res) => {
                      this.sender.enterpriseFiles[j.enterpriseId] = res.filter((f) => f.enterpriseSendFlag);
                      return { options: this.sender.enterpriseFiles[j.enterpriseId] };
                    }),
                  );
                }),
              ).pipe(map((res) => ({ options: Object.values(this.sender.enterpriseFiles).flatten() })));
            },
          });
          row.fields.push({
            name: 'file-hr',
            type: 'hr',
            class: 'full',
          });
          row.fields.push({
            name: 'studentFileIds',
            type: 'checkbox',
            class: 'multi-ou sticky-label full',
            labelField: 'name',
            valueField: 'id',
            labelBefore: '求職者：',
            multi: true,
            options: [],
            supplier: () => ({ options: this.sender.files }),
          });
        } else if (this.sender.model.enterpriseFileIds.length || this.sender.model.studentFileIds.length) {
          row.fields.push({
            type: 'label',
            name: 'zip',
            class: 'link',
            clickAction: () =>
              this.sender.companyApiService.downloadEnterpriseArchiveFile(this.sender.model.enterpriseId, {
                studentId: this.sender.model.student.id,
                enterpriseDataFileIds: this.sender.model.enterpriseFileIds,
                studentDataFileIds: this.sender.model.studentFileIds,
              }),
            default: 'candidate_resume.zip',
          });
        }
        return row;
      })(),
    );

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const template = getValue('dummyTemplateType');
              if (!template) {
                return undefined;
              }
              const templateId: number = template ? template.id : 0;
              return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                map((res: MailTemplateResponse) => {
                  return {
                    value: res ? res.body : undefined,
                  };
                }),
              );
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public update(): void {
    if (this.sender.model.student) {
      if (this.sender.studentIdForFiles !== this.sender.model.student.id) {
        this.sender.studentIdForFiles = this.sender.model.student.id;
        this.sender.fileService.getFiles('students', this.sender.model.student.id).subscribe((res) => {
          this.sender.files.length = 0;
          this.sender.files.push(...res.filter((f) => f.enterpriseSendFlag));
          this.initForm();
        });
      }
      if (this.sender.model.enterprise && this.sender.model.jobs && this.sender.model.jobs.length) {
        this.sender.companyApiService.getDepartments(this.sender.model.enterprise.id).subscribe((deps) => {
          this.sender.model.department = deps.enterpriseDepartments.find((d) => d.id === this.sender.model.jobs[0].enterpriseDepartmentId);
          this.sender.model.departmentId = (this.sender.model.department || {}).id;
          this.sender.progressApiService
            .checkProgress([this.sender.model.student.id], this.sender.model.jobs.map((j) => j.id))
            .subscribe((res) => {
              this.sender.model.bcc = res.find((ch) => ch.progressType === 1)
                ? this.sender.model.jobs[0].jobUsers.filter((u) => u.type === EnterpriseDepartmentUserType.PA).map((u) => u.userId)
                : [];
            });
          this.contacts = (this.sender.model.department
            ? [
                this.sender.model.department.dynamicData.contact1,
                this.sender.model.department.dynamicData.contact2,
                this.sender.model.department.dynamicData.contact3,
                this.sender.model.department.dynamicData.contact4,
                this.sender.model.department.dynamicData.contact5,
              ]
            : []
          ).filter((c) => c && c.name && c.email && !c.emailAvailable);

          if (environment.rpaUserIds.includes(this.sender.authService.loginUser.id)) {
            // ログインしているユーザーがRPAの場合、TOにチェックを入れず、CCを設定しない
            // see https://benesse-g.backlog.jp/view/MACH_PJ-157
          } else {
            this.contacts.forEach((c, i) => {
              if (!i) {
                this.sender.model.to.push(c.email);
              } else {
                this.sender.model.cc_single.push(c.email);
              }
            });
          }
          this.sender.model.cc = [
            this.sender.authService.loginUser.id,
            ...(this.sender.model.jobs[0].jobUsers || [])
              .filter(
                (u: EnterpriseDepartmentUser) => u.type === EnterpriseDepartmentUserType.RA || u.type === EnterpriseDepartmentUserType.アシ,
              )
              .map((u: EnterpriseDepartmentUser) => u.userId),
          ];
          this.initForm();
        });
      }
    }
  }

  public save(toSave: any): void {
    toSave.jobId = this.sender.model.jobs.map((j) => j.id)[0];
    toSave.studentId = this.sender.model.studentId;
    toSave.zip = true;
    this.sender.progressApiService.checkProgress([this.sender.model.student.id], [toSave.jobId]).subscribe((res) => {
      if (res && res.length) {
        this.sender.progressErrorInfo = res;
        PopupControlComponent.instance.open({
          cancelText: '中止',
          cancelCallback: () => this.sender.close(),
          confirmText: '重複案件は上書き',
          content: this.sender.progressErrorTemplate,
          title: '進捗重複',
          confirmCallback: () => {
            toSave.updateProgress = true;
            return this.sender.mailApiService
              .sendEnterpriseResumeMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
              .pipe(tap((result) => this.sender.afterSend(result)));
          },
          multiConfirm: [
            {
              confirmText: '重複案件は上書きしないで送信のみ行う',
              confirmCallback: () => {
                toSave.updateProgress = false;
                return this.sender.mailApiService
                  .sendEnterpriseResumeMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
                  .pipe(tap((result) => this.sender.afterSend(result)));
              },
            },
          ],
        });
      } else {
        this.sender.mailApiService
          .sendEnterpriseResumeMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
          .subscribe((result) => this.sender.afterSend(result));
      }
    });
  }
}
