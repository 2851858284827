import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { TemplateRef } from '@angular/core';

export const STUDENT_SEMINAR_LIST_TABLE_CONFIG: (
  buttonsTemplate: TemplateRef<any>,
  dateFormatter: (date: any) => string,
  timeFormatter: (date: any) => string,
) => TableConfig = (buttonsTemplate, dateFormatter, timeFormatter) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'seminarAt',
                title: '開催日',
                formatter: (data) => (data.seminarAt ? dateFormatter(data.seminarAt) : null),
                sortable: false,
              },
            ],
            style: {
              width: '121px',
            },
          },
          {
            fields: [
              {
                name: 'seminarAtTime',
                title: '開催時間',
                formatter: (data) => (data.seminarAt ? timeFormatter(data.seminarAt) : null),
                sortable: false,
              },
            ],
            style: {
              width: '81px',
            },
          },
          {
            fields: [
              {
                name: 'type',
                title: 'セミナー属性',
                sortable: false,
              },
            ],
            style: {
              width: '90px',
            },
          },
          {
            fields: [
              {
                name: 'areaName',
                title: 'セミナーエリア',
                sortable: false,
              },
            ],
            style: {
              flex: 1,
            },
          },
          {
            fields: [
              {
                name: 'buttons',
                hidden: true,
                sortable: false,
                cellTemplate: buttonsTemplate,
              },
            ],
            style: {
              width: '175px',
              'font-size': '11px',
            },
          },
        ],
        dynamicStyle: {
          'background-color': (data) => (data.isSeminarAtPastDate ? '#F7F7F7' : null),
        },
      },
    ],
  },
  body: {
    checkbox: false,
    arrow: false,
    class: 'no-hover',
  },
});
