import { DashboardType, JobFavoriteListItem } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models';
import { AuthService, JobApiService } from '@agent-ds/shared/services';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

interface NavPopupConfig {
  routerLink: string;
  label: string;
}

@Component({
  selector: 'ag-global-menu',
  templateUrl: './global-menu.component.html',
  styleUrls: ['./global-menu.component.scss'],
})
export class GlobalMenuComponent implements AfterViewInit {
  activePopupConfig: NavPopupConfig[];
  activePopupMenuIndex: number | null = null;
  mouseLeaveSubcriptions: Subscription[] = [];
  favorites = 0;
  updateFavTimer: any;
  isIntakeCa = false;

  private popupConfigs: NavPopupConfig[][] = [
    undefined,
    [
      { routerLink: '/students/search', label: '求職者検索' },
      { routerLink: '/students/uploads', label: 'アップロードデータ一覧' },
      { routerLink: '/students/import', label: '求職者データ取り込み' },
    ],
    [{ routerLink: '/enterprises/search', label: '企業検索' }, { routerLink: '/enterprises/import', label: '企業データ取り込み' }],
    undefined,
    undefined,
    [
      { routerLink: '/progresses/search', label: '進捗検索' },
      { routerLink: '/progresses/export', label: '進捗リスト' },
      { routerLink: '/progresses/report', label: '月間進捗' },
    ],
    [{ routerLink: '/sales/search', label: '成約検索' }, { routerLink: '/sales/report', label: '担当別月間売上' }],
    undefined,
  ];

  private popupConfigsForIntakeCa: NavPopupConfig[][] = [
    undefined,
    [
      { routerLink: '/students/searchWithTodaysCounseling', label: '当日カウ検索' },
      { routerLink: '/students/searchWithSubUser', label: '担当求職者検索' },
    ],
    [{ routerLink: '/enterprises/search', label: '企業検索' }],
    undefined,
    undefined,
    [
      { routerLink: '/progresses/search', label: '進捗検索' },
      { routerLink: '/progresses/export', label: '進捗リスト' },
      { routerLink: '/progresses/report', label: '月間進捗' },
    ],
    [{ routerLink: '/sales/search', label: '成約検索' }, { routerLink: '/sales/report', label: '担当別月間売上' }],
    undefined,
  ];

  @ViewChild('jobFavorites', { static: false }) jobFavorites;

  get popupMenuPaddingTop(): number {
    return (this.activePopupMenuIndex - (!this.favorites && this.activePopupMenuIndex > 4 ? 1 : 0)) * 60;
  }

  constructor(private router: Router, private authService: AuthService, private jobService: JobApiService) {
    this.authService.isLogined().subscribe(() => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });
  }

  ngAfterViewInit() {
    this.authService.isLogined().subscribe((loggedIn) => {
      if (loggedIn) {
        this.updateFavorites();
      } else {
        this.favorites = 0;
        DetailPage.resetAll();
      }
    });

    this.jobService.jobFavoritesChanged.subscribe(() => {
      this.updateFavorites();
    });
  }

  updateFavorites() {
    if (this.updateFavTimer) {
      clearTimeout(this.updateFavTimer);
      this.updateFavTimer = null;
    }
    this.updateFavTimer = setTimeout(() => {
      this.jobService.getFavorites().subscribe((favorites: JobFavoriteListItem[]) => {
        this.favorites = favorites.length;
      });
    }, 2000);
  }

  onMouseEnter(index: number) {
    if (this.mouseLeaveSubcriptions && this.mouseLeaveSubcriptions.length) {
      this.mouseLeaveSubcriptions.forEach((s) => s.unsubscribe());
      this.mouseLeaveSubcriptions = [];
    }

    if (this.activePopupMenuIndex !== index) {
      // インテークCA用のメニューと通常メニューの出し分け
      this.activePopupConfig = this.isIntakeCa ? this.popupConfigsForIntakeCa[index] : this.popupConfigs[index];
      this.activePopupMenuIndex = index;
    }
  }

  onMouseLeave() {
    this.mouseLeaveSubcriptions.push(
      timer(500).subscribe(() => {
        this.activePopupConfig = null;
        this.activePopupMenuIndex = null;
      }),
    );
  }

  openLink(link: string): void {
    this.activePopupConfig = null;
    this.activePopupMenuIndex = null;
    this.router.navigateByUrl(link);
  }
}
