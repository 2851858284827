import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUser, EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailSendConfig } from '../config';

export class JobCompanyConfig extends MailSendConfig {
  private contacts: any[];

  public getTemplateId(): number {
    return 5;
  }

  public getTemplateTypeId(): number[] {
    return [5];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.enterprise = params.enterprise || this.sender.model.enterprise;
      if (this.sender.model.enterprise) {
        this.sender.model.enterprises = [this.sender.model.enterprise];
      }
      this.sender.model.enterpriseId = this.sender.model.enterprise.id;
      this.sender.model.department = params.department || this.sender.model.department;
      this.sender.model.departmentId = this.sender.model.department.id;
      this.sender.model.jobs = params.jobs || this.sender.model.jobs;
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    const enterprise = this.sender.model.enterprise;
    const department = this.sender.model.department;

    const mappedContacts = this.contacts.map((c) => {
      return {
        label: `${c.name} <${c.email}>`,
        value: c.email,
      };
    });

    metaData.groups[0].rows.push({
      title: 'To (企業・部署)',
      showRequired: true,
      class: 'ou',
      fields: [
        {
          name: 'enterpriseName',
          type: 'label',
          class: 'pre',
          supplier: () =>
            `${enterprise.frontId} ${enterprise.dynamicData.name}${enterprise.dynamicData.enterpriseNo}\n${department.dynamicData.name}`,
        },
        !this.sender.inConfirm
          ? {
              name: 'to',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              class: 'multi-ou',
              style: { 'margin-left': '29px' },
              multi: true,
              options: mappedContacts,
              validators: { required: true },
            }
          : {
              name: 'to',
              type: 'label',
              supplier: () =>
                this.sender ? this.contacts.filter((c) => this.sender.model.to.includes(c.email)).map((c) => `${c.name} <${c.email}>`) : '',
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (企業、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        if (!this.sender.inConfirm) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              labelBefore: '企業',
              class: 'full multi-ou sticky-label',
              multi: true,
              options: mappedContacts.length > 1 ? mappedContacts : [],
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        } else if (this.sender.model.cc_single.length) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'label',
              class: 'full',
              supplier: () =>
                this.contacts.filter((c) => this.sender.model.cc_single.includes(c.email)).map((c) => `${c.name} <${c.email}>`),
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        }
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const template = getValue('dummyTemplateType');
                return template ? value || template.subject : undefined;
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });

    const fileRow = {
      title: '添付ファイル',
      class: 'ou',
      fields: [],
    };
    metaData.groups[0].rows.push(fileRow);

    if (this.sender.inConfirm) {
      fileRow.fields.push({
        type: 'label',
        name: 'zip',
        class: 'link',
        clickAction: () => this.sender.jobApiService.downloadExcel(this.sender.model.jobs[0].id).subscribe((res) => downloadResponse(res)),
        default: '求人票.xlsx',
      });
    }
    const entField = this.sender.dynamicService.getFormRows(
      {
        fieldType: 'multi-enterprise',
        label: '添付ファイル',
        fieldName: 'enterprises',
      },
      null,
      'half',
    )[0].fields[0];
    if (!this.sender.inConfirm) {
      fileRow.fields.push(entField);
      entField.valueField = null;
      entField.labelBefore = '企業：';
      entField.linkTo = ['enterpriseFileIds'];
      entField.actions = [
        {
          title: 'データフォルダから選択',
          type: 'RUNNABLE',
          allowOn: { enterpriseIds: null },
          runnable: () => {
            this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
            PopupControlComponent.instance.open({
              content: this.sender.fileSelectTemplate,
              confirmText: '添付',
              cancelText: 'キャンセル',
              title: 'データフォルダ',
              confirmCallback: () => {
                this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
              },
            });
          },
        },
      ];
    }

    if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
      fileRow.fields.push({
        name: 'enterpriseFileIds',
        type: 'autocomplete',
        labelField: [
          {
            name: 'name',
            class: this.sender.inConfirm ? 'link' : null,
            action: this.sender.inConfirm
              ? (file) =>
                  this.sender.fileService.downloadFile(
                    'enterprise',
                    +Object.keys(this.sender.enterpriseFiles).find(
                      (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                    ),
                    file.id,
                    file.name,
                  )
              : null,
          },
        ],
        labelBefore: this.sender.inConfirm ? '企業：' : null,
        valueField: 'id',
        multi: true,
        style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
        class: 'options-only half',
        options: [],
        supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
          const enterprises = getValue('enterprises');
          this.sender.model.enterpriseIds = enterprises
            ? enterprises.map((e) => {
                this.sender.enterpriseInfoById[e.id] = `${e.frontId} ${e.name}`;
                return e.id;
              })
            : [];
          const ids = this.sender.model.enterpriseIds;
          const selectedFiles = (Object.values(this.sender.enterpriseFiles).flatten() as any).filter((f) =>
            this.sender.model.enterpriseFileIds.includes(f.id),
          );
          if (!ids || !ids.length) {
            return { options: selectedFiles };
          } else {
            return forkJoin(
              ids.map((id) =>
                this.sender.fileService.getFiles('enterprise', id).pipe(
                  map((res) => {
                    this.sender.enterpriseFiles[id] = res.filter((f) => f.enterpriseSendFlag);
                    return this.sender.enterpriseFiles[id];
                  }),
                ),
              ),
            ).pipe(map((val) => ({ options: val.flatten() })));
          }
        },
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const template = getValue('dummyTemplateType');
              return template ? value || template.body : undefined;
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public update(): void {
    if (this.sender.model.department) {
      this.contacts = [
        this.sender.model.department.dynamicData.contact1,
        this.sender.model.department.dynamicData.contact2,
        this.sender.model.department.dynamicData.contact3,
        this.sender.model.department.dynamicData.contact4,
        this.sender.model.department.dynamicData.contact5,
      ].filter((c) => c && c.name && c.email && !c.emailAvailable);
      this.contacts.forEach((c, i) => {
        if (!i) {
          this.sender.model.to.push(c.email);
        } else if (this.sender.model.cc_single) {
          this.sender.model.cc_single.push(c.email);
        } else {
          // do nothing
        }
      });
      this.sender.model.bcc = this.sender.model.jobs[0].jobUsers
        .filter((u: EnterpriseDepartmentUser) => u.type === EnterpriseDepartmentUserType.RA)
        .map((u: EnterpriseDepartmentUser) => u.userId);
    }
  }

  public save(toSave: any): void {
    this.sender.mailApiService
      .sendJobEnterpriseMail(this.sender.model.jobs[0].id, this.sender.model.enterprise.id, this.sender.model.department.id, toSave)
      .subscribe((res) => this.sender.afterSend(res));
  }
}
