import { SalesSearchRequest } from '@agent-ds/sales/dto/SalesSearchRequest';
import {
  SaleDetail,
  SaleLinkBtoBResult,
  SalesAmount,
  SalesCreateRequest,
  SalesLinkBtoBResult,
  SalesListResponse,
} from '@agent-ds/shared/interfaces/sale';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';

const SALES_API_URL = '/api/sales';
@Injectable({
  providedIn: 'root',
})
export class SalesApiService extends ApiService {
  constructor(private http: HttpClient, private userMapper: UserTeamInjectorProvider) {
    super();
  }

  getDetail(salesId: number): Observable<SaleDetail> {
    return this.http.get<SaleDetail>(`${SALES_API_URL}/${salesId}`);
  }

  getList(search?: SalesSearchRequest): Observable<SalesListResponse> {
    const params = search ? toAsialRequestParameters(search) : undefined;
    const sales = this.http.get<SalesListResponse>(SALES_API_URL + '/search', { params: (params ? params : {}) as any });
    return this.userMapper.getRequestAndProcessList(sales, 'saleses');
  }

  getReport(query: any): Observable<SalesAmount> {
    const params = query ? toAsialRequestParameters(query) : undefined;
    return this.http.get<SalesAmount>(`${SALES_API_URL}/amount`, { params: (params ? params : {}) as any });
  }

  create(salesCreateRequest: SalesCreateRequest): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(SALES_API_URL, salesCreateRequest, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  update(sale: SaleDetail, status: number, cancelStatus: number): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(
      `${SALES_API_URL}/${sale.id}`,
      { ...sale, status: status, cancelStatus: cancelStatus },
      { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } },
    );
  }

  linkBtoBSales(salesIds: number[]): Observable<SalesLinkBtoBResult> {
    return this.http.put<SalesLinkBtoBResult>(`${SALES_API_URL}/bulk/invoce/btob`, { salesIds: salesIds });
  }

  linkBtoBSale(saleId: number): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(
      `${SALES_API_URL}/${saleId}/invoce/btob`,
      { saleId: saleId },
      { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } },
    );
  }
}
