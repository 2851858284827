import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SharedModule } from '@agent-ds/shared';

import { STUDENT_COMPONENTS, STUDENT_DIALOG_COMPONENTS } from './components';
import { StudentDetailHeaderComponent } from './components/student-detail-header/student-detail-header.component';
import { StudentSearchComponent } from './components/student-search/student-search.component';
import { STUDENT_PAGES } from './pages';
import { StudentRoutingModule } from './student-routing.module';

@NgModule({
  declarations: [STUDENT_COMPONENTS, STUDENT_PAGES, STUDENT_DIALOG_COMPONENTS],
  imports: [HttpClientModule, SharedModule, StudentRoutingModule],
  exports: [STUDENT_PAGES, StudentSearchComponent, StudentDetailHeaderComponent],
  entryComponents: [STUDENT_DIALOG_COMPONENTS],
})
export class StudentModule {}
