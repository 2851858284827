import { Pipe, PipeTransform } from '@angular/core';
import { formatAddress } from '../util/util';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(value: any, long?: boolean): any {
    return formatAddress(value, long);
  }
}
