import { MatchingCreateDialogComponent } from '@agent-ds/matching/components/matching-create-dialog/matching-create-dialog.component';
import { ProgressCreateDialogComponent } from '@agent-ds/progress/components';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { JobListItem, StudentDetail } from '@agent-ds/shared/interfaces';
import { StudentMatching } from '@agent-ds/shared/interfaces/student-matching';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, JobApiService, StudentApiService } from '@agent-ds/shared/services';
import { MatchingApiService } from '@agent-ds/shared/services/api/matching-api.service';
import { deepClone, downloadResponse, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ag-student-job-matching-page',
  templateUrl: './student-job-matching-page.component.html',
  styleUrls: ['./student-job-matching-page.component.scss'],
})
export class StudentJobMatchingPageComponent extends DetailPage implements OnInit, AfterViewInit {
  @ViewChild('matchingStudentDetails', { static: false }) studentDetails: StudentDetailPageComponent;
  @ViewChild('matchingJobList', { static: false }) jobList: any;
  jobSearchObj: any = { keyword: '' };
  matchings: StudentMatching[] = [];
  selectedMatching: StudentMatching;
  student: StudentDetail;
  showList: boolean;
  listConfiguration: any = { body: { checkbox: true, hideCreateButton: true } };
  postingConfig = new JobPostingConfig();
  isSecondMatchingUpdateInProgress = false;
  is2ndMatchOnState = false;

  public sideActions: SideActionConfig[] = [{ phases: ['100%'] }];
  protected tabs: any = {};
  protected urlTag = 'matching/student-job';

  constructor(
    private matchingApi: MatchingApiService,
    private dialog: DialogService,
    protected service: JobApiService,
    private studentApiService: StudentApiService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
  ) {
    super(router, service, activeRoute);
    this.refreshSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.studentDetails.studentDetailLoaded.subscribe((student) => {
      this.student = student;
      this.is2ndMatchOnState =
        this.student &&
        this.student.dynamicData &&
        this.student.dynamicData.is2ndMatch &&
        this.student.dynamicData.is2ndMatch.length &&
        this.student.dynamicData.is2ndMatch[0] === '有効';

      // マッチング条件で検索項目のデフォルト値を設定する
      this.jobSearchObj = {
        universityLevel: [student.dynamicData.universityLevel],
        gender: (() => {
          const value = student.dynamicData.gender;
          if (value === '回答しない') {
            return ['その他'];
          }
          return [value];
        })(),
        academicFieldType: [student.dynamicData.academicFieldType],
        recruitYear: [student.dynamicData.supportYear],
        status: ['募集中'],
        communicationSkill: [student.dynamicData.communicationSkill],
        impressionAndCharm: [student.dynamicData.impressionAndCharm],
        // 求職者: 最終学歴 -> マッチング: 最終学歴
        lastAcademicRecord: [student.dynamicData.lastAcademicRecord],
        // 求職者: 日本語能力検定 -> マッチング: 留学生
        internationalStudent: (() => {
          const value = student.dynamicData.japaneseLanguageProficiencyTest;
          if (
            value && (
            value.find((a) => a === 'N1') ||
            value.find((a) => a === 'N2') ||
            value.find((a) => a === 'N3') ||
            value.find((a) => a === 'N4') ||
            value.find((a) => a === 'N5') ||
            value.find((a) => a === 'None（第一言語が日本語以外の言語で、日本語検定を取得していない）')
            ))
          {
            return ['積極採用', '通常採用'];
          }
          return [];
        })(),
        // 求職者: 勤務エリア比率（首都圏・関西・東海） -> マッチング: 勤務エリア
        workArea: (() => {
          const workArea = [];
          // console.log('student.dynamicData: ', student.dynamicData);
          const metropolitanArea = student.dynamicData.workingAreaRatioMetropolitanArea;
          // console.log('metropolitanArea: ', metropolitanArea);
          if (metropolitanArea && metropolitanArea.length >= 1 && (metropolitanArea.find((a) => a === '首都圏100%、首都圏以外0%') || metropolitanArea.find((a) => a === '首都圏80%、首都圏以外20%') || metropolitanArea.find((a) => a === '首都圏60%、首都圏以外40%'))) {
            workArea.push('関東');
          }
          const kansaiArea = student.dynamicData.workingAreaRatioKansaiArea;
          // console.log('kansaiArea: ', kansaiArea);
          if (kansaiArea && kansaiArea.length >= 1 && (kansaiArea.find((a) => a === '関西100%、関西以外0%') || kansaiArea.find((a) => a === '関西80%、関西以外20%') || kansaiArea.find((a) => a === '関西60%、関西以外40%'))) {
            workArea.push('関西');
          }
          const chubuArea = student.dynamicData.workingAreaRatioChubuArea;
          // console.log('chubuArea: ', chubuArea);
          if (chubuArea && chubuArea.length >= 1 && (chubuArea.find((a) => a === '東海100%、東海以外0%') || chubuArea.find((a) => a === '東海80%、東海以外20%') || chubuArea.find((a) => a === '東海60%、東海以外40%'))) {
            workArea.push('東海');
          }
          // console.log('workArea: ', workArea);
          return workArea;
        })(),
        ...this.jobSearchObj,
      };

      this.loadMatchings(student.id);
      this.updateUrl();
    });
  }

  protected fill(onRefresh?: boolean): void {
    if (this.referenceId) {
      this.showList = false;
      this.jobSearchObj = {};
      this.studentDetails.referenceId = this.referenceId;
      this.studentDetails.open();
    }
  }

  loadMatchings(studentId: number, selectedId?: number): void {
    this.matchingApi.getMatchingsForStudent(studentId).subscribe((matchings) => {
      this.matchings = matchings;
      this.selectedMatching = this.matchings.find((m) => m.id === selectedId);
    });
  }

  onFilterChanged(changes: any): void {
    if (this.selectedMatching) {
      this.jobSearchObj = {
        ...deepClone(this.jobSearchObj),
        ...changes,
      };
      this.selectedMatching = undefined;
    } else {
      this.jobSearchObj = {
        ...this.jobSearchObj,
        ...changes,
      };
    }
  }

  toggleSecondMatching(): void {
    this.isSecondMatchingUpdateInProgress = true;
    const is2ndMatchDynamicData =
      (this.student.dynamicData.is2ndMatch && !this.student.dynamicData.is2ndMatch.length) ||
      (this.student.dynamicData.is2ndMatch &&
        this.student.dynamicData.is2ndMatch.length &&
        this.student.dynamicData.is2ndMatch[0] !== '有効')
        ? { is2ndMatch: ['有効'] }
        : { is2ndMatch: [] };
    this.studentApiService.update(this.student.id, { ...this.student, dynamicData: is2ndMatchDynamicData }).subscribe(
      (res) => {
        this.isSecondMatchingUpdateInProgress = false;
        this.student = { ...this.student, dynamicData: { ...this.student.dynamicData, ...is2ndMatchDynamicData } };
        this.is2ndMatchOnState =
          this.student &&
          this.student.dynamicData &&
          this.student.dynamicData.is2ndMatch &&
          this.student.dynamicData.is2ndMatch.length &&
          this.student.dynamicData.is2ndMatch[0] === '有効';
      },
      () => {
        this.isSecondMatchingUpdateInProgress = false;
      },
    );
  }

  matchingSelected(selected: boolean, matching: StudentMatching): void {
    this.selectedMatching = matching;
    this.jobSearchObj = this.selectedMatching && selected ? deepClone(this.selectedMatching.matchingParameter) : { keyword: '' };
  }

  saveMatching(): void {
    this.dialog.open(MatchingCreateDialogComponent, null, (dialogModel) => {
      if (dialogModel) {
        const matching: StudentMatching = {
          name: dialogModel.name,
          studentId: this.student.id,
          matchingParameter: this.jobSearchObj,
        };
        return this.matchingApi.saveMatchingForStudent(matching).pipe(
          tap((res) => {
            this.loadMatchings(this.student.id, res.id);
          }),
        );
      }
    });
  }

  deleteMatching(matching) {
    const config: ConfirmDialogConfig = {
      title: '求人検索マッチング条件の削除',
      messages: ['マッチング条件を削除してもよろしいですか？'],
      style: {
        height: '245px',
        width: '510px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialog.open(ConfirmDialogComponent, config, (confirmed) => {
      if (confirmed) {
        return this.matchingApi.deleteStudentMatching(matching.id).pipe(
          tap(() => {
            this.loadMatchings(this.student.id);
          }),
        );
      }
    });
  }

  public doJobSearch(params: any): void {
    this.showList = true;
    setTimeout(
      () =>
        (this.jobSearchObj = {
          ...this.jobSearchObj,
          ...params,
        }),
    );
  }

  toSearch(): void {
    DetailPage.closeAll([StudentJobMatchingPageComponent]);
    this.showList = false;
  }

  getJobsAsPdf(jobs: JobListItem[]): void {
    this.service.getJobsAsPdf(jobs.map((job) => job.id)).subscribe((resp) => {
      downloadResponse(resp);
    });
  }

  openMail(config: MailSendConfig): void {
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      jobs: this.jobList.table.checkedItems.map((item: JobListItem) => ({
        id: item.id,
        frontId: item.frontJobId,
        position: getValueFromObject(item.dynamicData, 'position'),
        enterpriseName: item.enterpriseName,
        enterpriseId: item.enterpriseId,
        frontEnterpriseId: item.frontEnterpriseId,
        enterpriseDepartmentId: item.enterpriseDepartmentId,
      })),
      students: [
        {
          id: this.student.id,
          frontId: this.student.frontId,
          firstName: this.student.dynamicData.firstName,
          lastName: this.student.dynamicData.lastName,
          age: null,
          prefecture: null,
          schoolName: null,
          schoolDepartmentName: null,
          studentUser: this.student.studentUsers,
          emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
          emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
          emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
          emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
        },
      ],
    });
    MailSendFlowComponent.instance.start();
  }

  createProgress(): void {
    this.dialog.open(ProgressCreateDialogComponent, {
      data: { studentList: [this.student], jobList: this.jobList.checkedItems, matchingMode: true },
    });
  }
}
