import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogConfig } from '../components/dialog/dialog-config';
import { DialogInjector } from '../components/dialog/dialog-injector';
import { DialogRef } from '../components/dialog/dialog-ref';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogComponentRef: ComponentRef<DialogComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  public open(componentType: Type<any>, config?: DialogConfig, beforeClose?: (params?: any) => void | Observable<any>): DialogRef {
    if (!config) {
      config = { data: {} };
    }
    const dialogRef = this.appendDialogComponentToBody(config, beforeClose);

    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(config: DialogConfig, beforeClose?: (params?: any) => void | Observable<any>): DialogRef {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef(beforeClose);
    map.set(DialogRef, dialogRef);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));
    if (config && config.zIndex) {
      componentRef.instance.zIndex = config.zIndex;
    }

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody(componentRef);
      sub.unsubscribe();
    });

    componentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody(componentRef);
    });

    return dialogRef;
  }

  private removeDialogComponentFromBody(componentRef: ComponentRef<DialogComponent>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
