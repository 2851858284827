import { ProgressDetailComponent } from '@agent-ds/progress/pages/progress-detail/progress-detail.component';
import { AuthGuard } from '@agent-ds/shared/guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgressListExportPageComponent, ProgressListPageComponent, ProgressReportPageComponent } from './pages';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'list', component: ProgressListPageComponent },
      { path: 'add', component: ProgressListPageComponent },
      { path: 'search', component: ProgressListPageComponent },
      { path: 'report', component: ProgressReportPageComponent },
      { path: 'export', component: ProgressListExportPageComponent },
      { path: ':id', component: ProgressDetailComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProgressRoutingModule {}
