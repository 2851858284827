import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-student-contact-history-tab',
  templateUrl: './student-contact-history-tab.component.html',
  styleUrls: ['./student-contact-history-tab.component.scss'],
})
export class StudentContactHistoryTabComponent extends Tab {
  @Input() studentId: number;

  constructor(public readonly studentApiService: StudentApiService, private dialogService: DialogService) {
    super();
  }

  public errorGetContactsCallback(error: HttpErrorResponse): void {
    if (!error.status) {
      // ネットワークに繋がらなかったときはエラーを通知する
      this.showErrorDialog();
      this.studentApiService.notifyConnectionErrorEvent.emit();
    } else {
      // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
    }
  }

  private showErrorDialog() {
    const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
      '対応履歴の取得中に通信エラーが発生しました。',
      '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
    ]);
    this.dialogService.open(ConfirmDialogComponent, dialogConfig);
  }
}
