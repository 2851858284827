import { mergeMap, tap } from 'rxjs/operators';
import { MailPopupConfig, MailPopupType } from '../config';
import { StudentDetailConfig } from './student-detail-config';

export class ProgressSendResumeConfig extends StudentDetailConfig {
  public getPopupConfig(): MailPopupConfig[] {
    return [{ type: MailPopupType.ENTERPRISE_RESUME_PASSWORD_CHECK }];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.jobs = params.jobs || this.sender.model.jobs;
      this.sender.model.enterpriseId = params.enterpriseId || this.sender.model.enterpriseId;
      this.sender.model.progressId = params.progressId;
      this.sender.model.progressType = params.progressType;
      this.sender.model.progressN = params.progressN;
      this.sender.model.progressSeminarType = params.progressSeminarType;
      this.sender.model.progressSeminarAt = params.progressSeminarAt;
      this.sender.model.progressSeminarAtMailSendFlag = params.progressSeminarAtMailSendFlag;
      this.sender.model.progressIsStudentDelay = params.progressIsStudentDelay;
      this.sender.model.progressHasInterview = params.progressHasInterview;
    }
    super.setParams(params);
  }

  public save(toSave: any): void {
    if (this.sender.model.progressType != null) {
      toSave.jobId = this.sender.model.jobs.map((j) => j.id)[0];
      toSave.studentId = this.sender.model.studentId;
      toSave.updateProgress = false;
      this.sender.mailApiService
        .sendEnterpriseResumeMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
        .pipe(
          mergeMap((result) =>
            this.sender.progressApiService
              .updateWithAction(this.sender.model.progressId, {
                action: 54,
                type: this.sender.model.progressType,
                hasInterview: this.sender.model.progressHasInterview,
                n: this.sender.model.progressN,
                seminarType: this.sender.model.progressSeminarType,
                seminarAt: this.sender.model.progressSeminarAt,
                seminarAtMailSendFlag: this.sender.model.progressSeminarAtMailSendFlag,
                isStudentDelay: this.sender.model.progressIsStudentDelay,
              })
              .pipe(tap(() => this.sender.afterSend(result))),
          ),
        )
        .subscribe();
    } else {
      super.save(toSave);
    }
  }
}
