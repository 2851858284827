import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-student-create-dialog',
  templateUrl: './student-create-dialog.component.html',
  styleUrls: ['./student-create-dialog.component.scss'],
})
export class StudentCreateDialogComponent implements OnInit, OnDestroy {
  headMeta: FormMeta;
  leftMeta: FormMeta;
  rightMeta: FormMeta;
  bottomMeta: FormMeta;
  customMeta: FormMeta;

  student: { dynamicData: any };
  private validityArray = [true, false, false, true, true];
  validity = false;
  inClose = false;

  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private studentService: StudentApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.register());
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.headMeta = {
        groups: [
          {
            class: 'oneliner',
            rows: [
              ...this.dynamicService.getFormRows(
                {
                  fieldName: this.dynamicService.getDefinition('student', 'interviewStatus').fieldName,
                  fieldType: 'list',
                  displayType: 'dropdown',
                  label: this.dynamicService.getDefinition('student', 'interviewStatus').label,
                  validationStyle: this.dynamicService.getDefinition('student', 'interviewStatus').validationStyle,
                },
                'dynamicData',
              ),
            ],
          },
        ],
      };
      this.leftMeta = {
        groups: [
          {
            title: '氏名・生年月日',
            rows: [
              {
                title: '求職者氏名',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstName'))[0].fields,
                ],
                showRequired: true,
              },
              {
                title: 'フリガナ',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticLastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticFirstName'))[0].fields,
                ],
                showRequired: true,
              },
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'gender')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'birthday')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportYear')),
            ],
          },
          {
            title: '住所・住居',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'address')),
          },
          {
            class: 'mt-10',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationAddress')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationTel')),
            ],
          },
          {
            title: '職歴概要',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskTypeFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevelFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel03')),
            ],
          },
          {
            title: 'グローバル',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nationality'), null, 'full'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'motherLanguage'), null, 'full'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'japaneseLevel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'abroadFee')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'visa')),
            ],
          },
        ],
      };
      this.rightMeta = {
        groups: [
          {
            title: '担当',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportBranch'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                {
                  label: '担当者',
                  fieldType: 'user',
                  fieldName: 'userId',
                },
                null,
                'three-quarter',
              ),
            ],
          },
          {
            title: 'ステータス',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'rank'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mediumId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'acquisitionRoute'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusMailmag'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusServiceNg'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nonRecruitmentIntroduction'), 'dynamicData'),
            ],
          },
          {
            title: '連絡先',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailMain'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailSub'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mobileTel'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'houseTel'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'contactNotes'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'skypeId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lineId'), 'dynamicData'),
            ],
          },
          {
            title: '登録・媒体',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRoute'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstRegistrationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastRegistrationRoute'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastRegistrationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRouteDetail'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewType'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewSettingDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'sortingDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'serviceinDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationTime'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'inflowClassification'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'pptSms'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationType'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarShortCounseling'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationDate'),
                'dynamicData',
              ),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationUniversity'),
                'dynamicData',
                'full',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroduction'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionName'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'friendIntroductionEntryDate'),
                'dynamicData',
              ),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'friendIntroductionEffectTarget'),
                'dynamicData',
              ),
            ],
          },
        ],
      };
      this.bottomMeta = {
        groups: [
          {
            title: '備考',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewClassification')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'implementationCategory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'snsType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'chanceToLearnAboutCompany'), null, 'half'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hookEntryHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindThreeDaysAgo')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindDayBefore')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'officialRegistrationPatrol'), null, 'half'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'officialRegistrationPatrolDesiredTime'),
                null,
                'half',
              ),
            ],
          },
        ],
      };
      const customs = this.dynamicService.getDefinitions('student').filter((def) => def.isCustomField);
      if (customs.length) {
        this.customMeta = {
          groups: [
            {
              title: 'カスタムフィールド',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }
      this.student = { dynamicData: {} };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  register(): void {
    if (!this.validity || this.inClose || this.dialog.inClose) {
      return;
    }
    this.inClose = true;
    this.studentService.create(this.student).subscribe(
      (res) => {
        this.studentService.refreshEvent.emit();
        this.inClose = false;
        this.close(res);
      },
      () => (this.inClose = false),
    );
  }

  close(param?: any): void {
    this.dialog.close(param);
  }
}
