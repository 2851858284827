import { Team } from './team';
import { User } from './user';

export interface ContactHistory {
  id?: number;
  studentId?: number;
  userId?: number;
  action: number | ContactHistoryAction;
  actionAt: string | Date;
  message: string;
  nextContactAt?: string | Date;
  user?: User;
  team?: Team;
}

export enum ContactHistoryAction {
  '呼び込み' = 1, // Call
  'コンタクト' = 2, // Contact
  '履歴書' = 3, // Resume
  'その他' = 4, // Other
  '面接対策キャンセル' = 8,
  'ステータス変更' = 5, // Status change
  '面接対策(対面)' = 6,
  '面接対策(非対面)' = 7,
  'マイページから送信' = 9,
}

export enum ContactHistoryEnterpriseAction {
  '電話' = 1,
  'メール' = 2,
  '訪問' = 3,
  '先TEL' = 4,
  'APO獲得' = 5,
  'アサイン' = 6,
  '契約締結' = 7,
  '受注' = 8,
  '移管' = 9,
  'クレーム' = 10,
  'DC決定' = 11,
  'DR成功報酬決定' = 13,
  'その他' = 12,
}
