import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageFloaterComponent } from '../components/page-floater/page-floater.component';
import { ExportModel, ExportTemplate } from '../interfaces/export';
import { ExportApiService } from '../services';
import { parseQueryParam } from '../util/util';
import { DetailPage } from './detail-page';

export abstract class ListPage implements OnInit, OnDestroy {
  protected abstract readonly model: 'students' | 'enterprises' | 'progresses' | 'jobs' | 'sales';
  protected abstract readonly detail: typeof DetailPage;
  protected abstract readonly exportModel: ExportModel;
  protected readonly exportTemplates: ExportTemplate[] = [];
  searchObj: { [key: string]: any } = {};
  isSearchOpen: boolean;
  protected origUrl: string;
  protected exportTemplateId: number;

  private paramSubscription: Subscription;
  private routerEventSubscription: Subscription;

  constructor(
    protected router: Router,
    protected location: Location,
    protected activeRoute: ActivatedRoute,
    protected exportService: ExportApiService,
  ) {}

  ngOnInit() {
    this.routerEventSubscription = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
    this.checkRoute();

    if (this.exportModel) {
      this.exportTemplates.length = 0;
      this.exportService.getTemplates(this.exportModel).subscribe((res) => this.exportTemplates.push(...res.templates));
    }

    this.paramSubscription = this.activeRoute.queryParams.subscribe((params: Params) => {
      this.searchObj = parseQueryParam(params);
      if (!this.router.url.endsWith(`/${this.model}/search`) && !this.router.url.endsWith(`/${this.model}/add`)) {
        setTimeout(() => this.doSearch({}), 0);
      }
    });
    this.origUrl = this.router.routerState.snapshot.url;
    if (!this.origUrl.startsWith(`/${this.model}/list`)) {
      this.origUrl = `/${this.model}/list`;
    }
    setTimeout(() => (this.detail.instance.origUrl = this.origUrl));
  }

  ngOnDestroy(): void {
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
      this.routerEventSubscription = null;
    }
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
      this.paramSubscription = null;
    }
  }

  protected checkRoute(): void {
    if (this.router.url.endsWith(`/${this.model}/search`)) {
      this.searchObj = { keyword: '' };
      this.openSearch();
    } else {
      this.closeSearch();
      if (!this.router.url.endsWith(`/${this.model}/add`)) {
        this.doSearch({});
      }
    }
  }
  protected doSearch(params: any): void {
    this.searchObj = {
      ...this.searchObj,
      ...params,
    };
    this.closeSearch();
  }
  openSearch(): void {
    if (this.isSearchOpen) {
      return;
    }
    PageFloaterComponent.closeAll();
    this.location.go(`/${this.model}/search`);
    this.isSearchOpen = true;

    const navigationSubscription = this.location.subscribe(() => {
      this.closeSearch();
      navigationSubscription.unsubscribe();
    });
  }
  protected closeSearch(): void {
    if (!this.isSearchOpen) {
      return;
    }
    this.location.go(this.origUrl);
    this.isSearchOpen = false;
  }
}
