export enum StudentTabs {
  OVERVIEW,
  DETAIL,
  ACADEMIC,
  PREFERENCES,
  PROGRESS,
  CONTACTS,
  MESSAGE,
  MEMO,
  FILES,
  SEMINARS,
  MATCHES,
}
