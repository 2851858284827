import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { CompanyDetail, Contract, DashboardType, Job, ProgressItemResponse, SituationMemo } from '@agent-ds/shared/interfaces';
import { SalesListItem } from '@agent-ds/shared/interfaces/sale';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { AuthService, CompanyApiService, JobApiService, ProgressApiService, SalesApiService } from '@agent-ds/shared/services';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JobTabs, JobTabsForIntakeCa } from './tabs/job-tabs.enum';

@Component({
  selector: 'ag-job-detail-page',
  templateUrl: './job-detail-page.component.html',
  styleUrls: ['./job-detail-page.component.scss'],
})
export class JobDetailPageComponent extends DetailPage implements OnInit, OnDestroy {
  public sideActions: SideActionConfig[] = [{ label: '詳細', phases: ['1030px', '100%'] }, { label: '担当者', phases: ['450px'] }];
  protected tabs: typeof JobTabs | typeof JobTabsForIntakeCa = JobTabs;
  protected urlTag = 'jobs';

  job: Job;

  memos: SituationMemo[] = [];
  sales: SalesListItem[] = [];
  contracts: Contract[] = [];
  progresses: ProgressItemResponse[] = [];
  company: CompanyDetail;

  private isIntakeCa = false;
  private loginSubscription: Subscription;

  public readonly jobDetailLoaded: EventEmitter<Job> = new EventEmitter();

  constructor(
    private jobApiService: JobApiService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    private progressApiService: ProgressApiService,
    private companyApiService: CompanyApiService,
    private salesApiService: SalesApiService,
    private readonly authService: AuthService,
  ) {
    super(router, jobApiService, activeRoute);
  }
  ngOnInit(): void {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
      // 権限を見て、タブの一覧を設定
      this.tabs = this.isIntakeCa ? JobTabsForIntakeCa : JobTabs;
      // 副担当者かどうかを確認してから初期化
      super.ngOnInit();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  protected fill(onRefresh?: boolean): void {
    if (this.referenceId) {
      this.updateUrl();
      this.jobApiService.getDetail(this.referenceId).subscribe((job: Job) => {
        this.job = job;
        if (!this.readonly) {
          this.companyApiService.getDetail(this.job.enterpriseId).subscribe((company) => (this.company = company));
        }
        if (!onRefresh) {
          this.jobDetailLoaded.emit(job);
        }
      });
      this.jobApiService.getMemos(this.referenceId, 'updatedAt', 'desc').subscribe((memos: SituationMemo[]) => (this.memos = memos));

      // インテークCAの場合は、概要に進捗と成約を表示しないので、APIを実行しない
      if (this.isIntakeCa) {
        return;
      }
      this.progressApiService
        .getList({ jobId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 100 })
        .subscribe((result) => (this.progresses = result.progresses || []));
      this.salesApiService
        .getList({ jobId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 100 })
        .subscribe((result) => (this.sales = result.saleses || []));
    }
  }
}
