import { JobDetailTabComponent } from './job-detail-tab/job-detail-tab.component';
import { JobMailTabComponent } from './job-mail-tab/job-mail-tab.component';
import { JobMemoTabComponent } from './job-memo-tab/job-memo-tab.component';
import { JobOverviewTabComponent } from './job-overview-tab/job-overview-tab.component';
import { JobProgressTabComponent } from './job-progress-tab/job-progress-tab.component';
import { JobSalesTabComponent } from './job-sales-tab/job-sales-tab/job-sales-tab.component';

export const JOB_DETAIL_TABS = [
  JobOverviewTabComponent,
  JobDetailTabComponent,
  JobProgressTabComponent,
  JobSalesTabComponent,
  JobMemoTabComponent,
  JobMailTabComponent,
];
