import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { EnterprisesKpi, StudentsKpi } from '@agent-ds/shared/interfaces/dashboard';
import { cleanObject } from '@agent-ds/shared/util/util';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-responsible-company',
  templateUrl: './responsible-company.component.html',
  styleUrls: ['./responsible-company.component.scss'],
})
export class ResponsibleCompanyComponent implements OnInit, OnChanges {
  @Input() enterpriseModel: EnterprisesKpi = EnterprisesKpi.empty();
  @Input() studentModel: StudentsKpi = StudentsKpi.empty();
  @Input() dashboardType: DashboardType = DashboardType.RAPA;
  @Input() selectedTeamId = null;
  @Input() loginUserId;
  @Input() collapsedNextYear = false;
  isManager = false;
  isCA = false;
  isRa = false;
  isAssistant = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardType']) {
      this.isManager = this.dashboardType === DashboardType.CA_Manager || this.dashboardType === DashboardType.RA_Manager;
      this.isCA = this.dashboardType === DashboardType.CA || this.dashboardType === DashboardType.CA_Manager;
      this.isRa = this.dashboardType === DashboardType.RA_Manager || this.dashboardType === DashboardType.RA_Assistant;
      this.isAssistant = this.dashboardType === DashboardType.RA_Assistant;
    }
  }

  today(): string {
    return new Date().toISOString().split('T')[0];
  }

  tomorrow(): string {
    return new Date()
      .addDays(1)
      .toISOString()
      .split('T')[0];
  }

  yesterday(): string {
    return new Date()
      .addDays(-1)
      .toISOString()
      .split('T')[0];
  }

  getStudentLink(hasActive: string, rank?: string): { userId: number; registrationStatus: string; rank?: string; hasActive?: number } {
    return cleanObject({
      userId: !this.isCA || !this.isManager ? this.loginUserId : null,
      teamId: this.isCA && this.isManager ? this.selectedTeamId : null,
      registrationStatus: '本登録',
      rank: rank === 'OTHER' ? ['C1', 'C2', 'D1', 'D2', NULL_SELECTED_VALUE] : rank,
      hasActive: hasActive === 'active' ? '1' : null,
      hasPreActive: hasActive === 'preactive' ? '1' : null,
    });
  }

  getParaJobLinkParams(status: string): { status: string; paraUserId?: number; paraTeamId?: number } {
    const res: { status: string; paraUserId?: number; paraTeamId?: number; asiId?: number } = {
      status: status,
    };

    if (this.isManager) {
      res.paraTeamId = this.selectedTeamId;
    } else if (this.isAssistant && this.isRa) {
      res.asiId = this.loginUserId;
    } else {
      res.paraUserId = this.loginUserId;
    }

    return res;
  }

  getParaJobLinkParamsWithRank(
    progressType: number,
    operationStatus: string,
    rank?: string,
  ): {
    progressType: number;
    operationStatus?: string;
    rank?: string;
    paraUserId?: number;
    paraTeamId?: number;
    status?: string;
    asiId?: number;
  } {
    const res: {
      progressType: number;
      operationStatus?: string;
      rank?: string;
      paraUserId?: number;
      paraTeamId?: number;
      status?: string;
      asiId?: number;
    } = {
      progressType: progressType,
      operationStatus: operationStatus,
      rank: rank,
      status: '募集中',
    };

    if (this.isManager) {
      res.paraTeamId = this.selectedTeamId;
    }
    if (this.isAssistant) {
      res.asiId = this.loginUserId;
    } else if (!this.isManager || !this.isRa) {
      res.paraUserId = this.loginUserId;
    }

    return cleanObject(res);
  }

  getProgressLinkParamsWithRank(
    type: number,
    jobRank?: string,
  ): { status: number[]; type: number; jobRank?: string; enterpriseUserId?: number; enterpriseTeamId?: number } {
    const res: { status: number[]; type: number; jobRank?: string; enterpriseUserId?: number; enterpriseTeamId?: number } = {
      status: [100, 110, 120, 130],
      jobRank: jobRank,
      type: type,
    };

    if (this.isManager) {
      res.enterpriseTeamId = this.selectedTeamId;
    } else {
      res.enterpriseUserId = this.loginUserId;
    }

    return res;
  }

  getParaEnterpriseLinkParams(status: string): { enterpriseStatus: string; departmentUserId?: number; departmentTeamId?: number } {
    const res: { enterpriseStatus: string; departmentUserId?: number; departmentTeamId?: number } = {
      enterpriseStatus: status,
    };

    if (this.isManager) {
      res.departmentTeamId = this.selectedTeamId;
    } else {
      res.departmentUserId = this.loginUserId;
    }

    return res;
  }

  getStudentLinkByUser(
    progressType: number,
  ): {
    hasProgress: number;
    progressType: number;
    hasProgressWithUser?: number[];
    hasProgressWithTeam?: number[];
    teamId?: number[];
    userId?: number[];
  } {
    if (this.isAssistant) {
      return { hasProgress: 1, progressType: progressType, hasProgressWithUser: [this.loginUserId] };
    } else if (this.isCA && this.isManager) {
      return { hasProgress: 1, progressType: progressType, teamId: [this.selectedTeamId] };
    } else if (this.isCA) {
      return { hasProgress: 1, progressType: progressType, userId: [this.loginUserId] };
    } else if (this.isManager) {
      // RA Manager
      return { hasProgress: 1, progressType: progressType, hasProgressWithTeam: [this.selectedTeamId] };
    } else {
      // RA
      return { hasProgress: 1, progressType: progressType, hasProgressWithUser: [this.loginUserId] };
    }
  }
}
