import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef } from './dialog-ref';

@Component({
  selector: 'ag-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @ViewChild('dialogHost', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;
  @ViewChild('dialog', { static: true }) dialogEl: ElementRef<HTMLElement>;

  private readonly closeSubject = new Subject<any>();

  public childComponentType: Type<any>;
  public onClose = this.closeSubject.asObservable();

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef, private dialogRef: DialogRef) {}

  @HostListener('keydown', ['$event'])
  keyHandler(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.onCloseClicked();
    } else if (event.code === 'Enter' && !event['isComposing']) {
      this.dialogRef.confirmed.emit();
    }
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public set zIndex(zIndex: number) {
    this.dialogEl.nativeElement.style.zIndex = zIndex + '';
  }

  public onOverlayClicked(evt: MouseEvent) {
    // this.dialogRef.close();
  }

  public onCloseClicked(evt?: MouseEvent) {
    this.dialogRef.close();
    this.close();
  }

  private loadChildComponent(componentType: Type<any>) {
    this.viewContainerRef.clear();
    if (componentType) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
      this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    }
    this.dialogEl.nativeElement.tabIndex = -1;
    this.dialogEl.nativeElement.focus();
  }

  private close() {
    this.closeSubject.next();
  }
}
