import {
  EnterpriseDepartmentUserType,
  Job,
  MailReplacedTemplateRequest,
  MailTemplateResponse,
  ProgressBulkSettingInterviewRequest,
  StudentDetail,
  StudentMailBulkSendRequest,
} from '@agent-ds/shared/interfaces';
import {
  AuthService,
  DialogService,
  JobApiService,
  MailApiService,
  ProgressApiService,
  StudentApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ConfirmDialogConfig } from '../../../shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '../../../shared/components/dialog/dialog-config';
import { DialogRef } from '../../../shared/components/dialog/dialog-ref';

@Component({
  selector: 'ag-progress-schedule-adjust-dialog',
  templateUrl: './progress-schedule-adjust-dialog.component.html',
  styleUrls: ['./progress-schedule-adjust-dialog.component.scss'],
})
export class ProgressScheduleAdjustDialogComponent implements OnInit, OnDestroy {
  model: {
    to: string;
    toEmails: string[];
    cc: string[];
    cc1: string;
    cc2: string;
    cc3: string;
    enterpriseName: string;
    jobPosition: string;
    selectionName: string;
    request1?: boolean;
    request2?: boolean;
    request3?: boolean;
    subject?: string;
    body?: string;
  };
  scheduleRadio = 0;
  scheduleText = '';
  schedulePreviewList: string[] = [];
  message = '';
  screeningInfo: { request1?: string; request2?: string; request3?: string };
  screeningInfoRequests: any[];
  inConfirm = false;
  isTemplateLoadInProgress = false;
  isSendInProgress = false;
  private mailTemplateSubscription: Subscription | null = null;
  private sendSubscription: Subscription | null = null;
  confirmSubscription: Subscription;
  confirmedMailTemplateTypeId: number;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private studentApiService: StudentApiService,
    private jobApiService: JobApiService,
    private mailApiService: MailApiService,
    private authService: AuthService,
    private progressApiService: ProgressApiService,
    private dialogService: DialogService,
    private userService: UserApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => (this.inConfirm ? this.send() : this.getMailTemplate()));
    this.load();
  }

  ngOnDestroy() {
    this.cancelMailTemplate();
    this.cancelSend();

    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  isValidForConfirm(): boolean {
    return this.model && !!this.model.toEmails;
  }

  clearScheduleText(): void {
    this.scheduleText = '';
  }

  generatePreview(): void {
    this.schedulePreviewList = this.scheduleText
      .split('\n')
      .filter((v) => v && v.trim())
      .map((v) => v.trim());
  }

  private load(): void {
    const jobId = this.config.data.jobId;
    const studentIds = this.config.data.studentIds;
    const progressStatus = this.config.data.progressStatus;
    const progressN = this.config.data.progressN;

    if (jobId == null || studentIds == null || progressStatus == null || (progressN == null && progressStatus !== 70)) {
      return;
    }

    forkJoin(studentIds.map((studentId) => this.studentApiService.getDetail(studentId))).subscribe((students: StudentDetail[]) => {
      this.jobApiService.getDetail(jobId).subscribe((job: Job) => {
        this.userService.getAll().subscribe((users) => {
          const raAsUserObjs = job.enterpriseDepartmentUsers.filter((u) => u.type === EnterpriseDepartmentUserType.アシ);
          const raAsUserObjsIds = raAsUserObjs.map((r) => r.userId);
          const raAsUsers = users.filter((u) => raAsUserObjsIds.includes(u.id));
          const raMails = raAsUsers.map((r) => r.email).filter((e) => e);
          this.model = {
            to: students
              .map((student) => {
                const name = (student.dynamicData.lastName || '') + (student.dynamicData.firstName || '');
                const mail = getValueFromObject(student, 'dynamicData.emailMain');
                return mail && !mail.emailAvailable && mail.email ? `${name} <${mail.email}>` : `【送信不可】${name}`;
              })
              .filter((v) => v && v.trim())
              .join(', '),
            toEmails: students
              .map((student) => {
                const mail = getValueFromObject(student, 'dynamicData.emailMain');
                return mail && mail.email ? mail.email : null;
              })
              .filter((v) => v && v.trim()),
            cc: [
              ...(this.config.data.title
                ? students
                    .map((student) => {
                      const mail = this.config.data.title ? getValueFromObject(student, 'dynamicData.emailSub') : null;
                      return mail && mail.email ? mail.email : null;
                    })
                    .filter((v) => v && v.trim())
                : []),
              ...(this.config.data.title && this.config.data.progressType === 0
                ? students
                    .map((student) => {
                      const studentUserObj = student.studentUsers.find((u) => u.type === EnterpriseDepartmentUserType.CA);
                      const studentUser = studentUserObj ? users.find((u) => u.id === studentUserObj.userId) : null;
                      return studentUser ? studentUser.email : null;
                    })
                    .filter((v) => v && v.trim())
                : []),
              ...(raMails && raMails.length ? raMails : []),
            ],
            cc1: !this.config.data.title
              ? '※E-mailサブにも送信'
              : students
                  .map((student) => {
                    const name = (student.dynamicData.lastName || '') + (student.dynamicData.firstName || '');
                    const mail = this.config.data.title ? getValueFromObject(student, 'dynamicData.emailSub') : null;
                    return mail && !mail.emailAvailable && mail.email ? `${name} <${mail.email}>` : name;
                  })
                  .filter((v) => v && v.trim())
                  .join(', '),
            cc2: !this.config.data.title
              ? '※求職者担当にも送信'
              : this.config.data.progressType === 0
              ? students
                  .map((student) => {
                    const studentUserObj = student.studentUsers.find((u) => u.type === EnterpriseDepartmentUserType.CA);
                    const studentUser = studentUserObj ? users.find((u) => u.id === studentUserObj.userId) : null;
                    return studentUser ? `${studentUser.name} <${studentUser.email}>` : name;
                  })
                  .filter((v) => v && v.trim())
                  .join(', ')
              : '',
            cc3: raAsUsers && raAsUsers.length ? raAsUsers.map((r) => `${r.name} <${r.email}>`).join(',') : '',
            enterpriseName: job.enterpriseName,
            jobPosition: job.dynamicData.position,
            selectionName: progressStatus === 70 ? 'セミナー' : progressStatus === 100 ? `${progressN}次面接` : '',
          };
          this.screeningInfo =
            progressStatus === 70
              ? job.dynamicData.screeningInfoSeminar
              : progressStatus === 100
              ? job.dynamicData[`screeningInfo${progressN >= 5 ? 5 : progressN}`]
              : null;

          if (this.screeningInfo) {
            if (this.screeningInfo.request1) {
              this.model.request1 = true;
            }
            if (this.screeningInfo.request2) {
              this.model.request2 = true;
            }
            if (this.screeningInfo.request3) {
              this.model.request3 = true;
            }
          }
        });
      });
    });
  }

  getMailTemplate(): void {
    if (!this.isValidForConfirm() || this.isTemplateLoadInProgress) {
      return;
    }

    this.model.subject = null;
    this.model.body = null;
    this.isTemplateLoadInProgress = true;
    this.mailTemplateSubscription = this.mailApiService
      .getMailTemplates()
      .pipe(
        map((templates: MailTemplateResponse[]) => {
          const progressType = this.config.data.progressType;
          return templates.find((t) => t.id === (progressType === 0 ? 9 : progressType === 1 ? 17 : null));
        }),
        concatMap((template: MailTemplateResponse) => {
          const templateObj: MailReplacedTemplateRequest = {
            from: this.authService.loginUser.email,
            subject: template.subject,
            text: template.body,
            to: this.model.toEmails,
            jobId: this.config.data.jobId,
            progress: { n: this.config.data.progressN, seminarType: this.config.data.progressSeminarType },
          };
          if (this.config.data.studentIds && this.config.data.studentIds.length === 1) {
            templateObj.studentId = this.config.data.studentIds[0];
          }
          const possibleDate =
            this.scheduleText &&
            this.scheduleText
              .split('\n')
              .filter((v) => v && v.trim())
              .map((v) => v.trim())
              .join('\n');
          templateObj.additionalReplacements = templateObj.additionalReplacements || [];
          templateObj.additionalReplacements.push({
            label: '対応事項1',
            value: (this.model.request1 && this.screeningInfo.request1) || '',
          });
          templateObj.additionalReplacements.push({
            label: '対応事項2',
            value: (this.model.request2 && this.screeningInfo.request2) || '',
          });
          templateObj.additionalReplacements.push({
            label: '対応事項3',
            value: (this.model.request3 && this.screeningInfo.request3) || '',
          });
          templateObj.additionalReplacements.push({ label: '連絡事項', value: (this.message || '').trim() });
          templateObj.additionalReplacements.push({ label: '日程調整テキスト', value: (possibleDate || '').trim() });
          this.confirmedMailTemplateTypeId = template.mailTemplateTypeId;
          return this.mailApiService.replaceTemplate(template.mailTemplateTypeId, templateObj);
        }),
      )
      .subscribe(
        (replacedMailTemplate: Partial<MailTemplateResponse>) => {
          this.model.subject = replacedMailTemplate.subject;
          this.model.body = replacedMailTemplate.body;
          this.inConfirm = true;
          this.isTemplateLoadInProgress = false;
          this.mailTemplateSubscription.unsubscribe();
        },
        () => this.cancelMailTemplate(),
      );
  }

  cancelMailTemplate(): void {
    if (this.mailTemplateSubscription) {
      this.mailTemplateSubscription.unsubscribe();
      this.mailTemplateSubscription = null;
    }
    this.inConfirm = false;
    this.isTemplateLoadInProgress = false;
  }

  send(): void {
    if (this.isSendInProgress) {
      return;
    }

    const bulkSettingInterviewRequest: ProgressBulkSettingInterviewRequest = {
      progressIds: this.config.data.progressIds,
      dynamicData: {},
    };
    const possibleDate =
      this.scheduleText &&
      this.scheduleText
        .split('\n')
        .filter((v) => v && v.trim())
        .map((v) => v.trim())
        .join('\n');
    if (possibleDate) {
      bulkSettingInterviewRequest.dynamicData.possibleDate = possibleDate;
    }
    if (this.message && this.message.trim()) {
      bulkSettingInterviewRequest.dynamicData.contactFromAdvisor = this.message.trim();
    }
    const correspondence =
      this.screeningInfo &&
      [
        this.model.request1 && this.screeningInfo.request1,
        this.model.request2 && this.screeningInfo.request2,
        this.model.request3 && this.screeningInfo.request3,
      ]
        .filter((v) => v)
        .join('\n');
    if (correspondence) {
      bulkSettingInterviewRequest.dynamicData.correspondence = correspondence;
    }
    const bulkMailRequest: StudentMailBulkSendRequest = {
      userId: this.authService.loginUser.id,
      subject: this.model.subject,
      text: this.model.body,
      from: this.authService.loginUser.email,
      to: this.model.toEmails,
      cc: this.model.cc,
      jobId: this.config.data.jobId,
      templateTypeId: this.confirmedMailTemplateTypeId,
    };

    const request = this.model.toEmails.length
      ? this.mailApiService
          .sendStudentBulkMail(bulkMailRequest)
          .pipe(concatMap(() => this.progressApiService.bulkSettingInterview(bulkSettingInterviewRequest)))
      : this.progressApiService.bulkSettingInterview(bulkSettingInterviewRequest);
    this.isSendInProgress = true;
    this.sendSubscription = request.subscribe(
      () => {
        this.isSendInProgress = false;
        this.cancelSend();
        this.close(true);
        this.openSuccessConfirmDialog();
      },
      () => {
        this.isSendInProgress = false;
        this.cancelSend();
      },
    );
  }

  cancelSend(): void {
    if (this.sendSubscription) {
      this.sendSubscription.unsubscribe();
      this.sendSubscription = null;
    }
  }

  private openSuccessConfirmDialog(): void {
    const config: ConfirmDialogConfig = {
      title: '日程調整',
      messages: [
        '処理を完了しました。',
        '※「送信不可」のメールアドレスにはメールは送信されません。',
        '※求職者担当が設定されていない場合メールは送信されません。',
      ],
      style: {
        height: '239px',
        width: '400px',
      },
      buttons: {
        no: '',
        hideNo: true,
        yes: '閉じる',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config);
  }

  close(param?: any) {
    this.dialog.close(param);
  }
}
