import { GroupMeta } from '@agent-ds/shared/models';
import { SalesDetailPageComponent } from '../sales-detail-page.component';
import { HelperBase } from './helper-base';

export class BillingInformationHelper extends HelperBase {
  billingOptions: any[] = [];
  enterpriseFrontId = '';

  private editable: GroupMeta = { rows: [] };

  private readonly: GroupMeta = { rows: [] };

  private matrix: GroupMeta[][];

  init(detailPage: SalesDetailPageComponent): void {
    detailPage.billingSubject.subscribe((data) => {
      // the object reference of "this.billingOptions" must be unchanged
      this.billingOptions.splice(0, this.billingOptions.length);
      this.billingOptions.push(...data.billingOptions);
      this.enterpriseFrontId = this.enterpriseFrontId;
    });
  }

  updateFieldDefinitions(): void {
    this.editable = this.getTemplate(true);
    this.readonly = this.getTemplate(false);

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: undefined
      [this.editable, this.editable, this.editable, this.editable], // Approval state: 1 (Not Approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: 2 (Approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus]];
  }

  private getTemplate(editable: boolean): GroupMeta {
    return {
      // Billing information
      title: '請求先情報',
      class: 'form__group--golden-title-border',
      rows: [
        editable
          ? {
              title: '請求先',
              showRequired: true,
              fields: [
                {
                  name: 'sales.enterpriseBillingAddressId',
                  type: 'dropdown',
                  class: 'fill',
                  valueField: 'id',
                  labelField: 'label',
                  options: this.billingOptions,
                  linkTo: [
                    'billing.companyName',
                    'billing.contact.department',
                    'billing.contact.name',
                    'billing.contact.position',
                    'billing.contact.tel',
                    'billing.contact.fax',
                    'billing.contact.address',
                    'billing.btobId',
                    'billing.billignAddressService',
                    'billing.machTransferBankList',
                  ],
                  validators: { required: true },
                },
              ],
            }
          : {
              title: '請求先',
              fields: [
                {
                  name: 'sales.enterpriseBillingAddressId',
                  type: 'label',
                  hidden: true,
                  linkTo: [
                    'billing.title',
                    'billing.companyName',
                    'billing.contact.department',
                    'billing.contact.name',
                    'billing.contact.position',
                    'billing.contact.tel',
                    'billing.contact.fax',
                    'billing.contact.address',
                    'billing.btobId',
                    'billing.billignAddressService',
                    'billing.machTransferBankList',
                  ],
                },
                {
                  name: 'billing.title',
                  type: 'label',
                  supplier: (value, callType, getter) => {
                    const billingAddressId = getter('sales.enterpriseBillingAddressId');
                    const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                    if (billing) {
                      return billing
                        ? [billing.companyName, billing.departmentName, billing.contact.name].filter((v) => !!v).join(' ')
                        : '-';
                    }
                  },
                },
              ],
            },

        {
          title: (this.dynamicService.getDefinition('billingAddress', 'companyName') || { label: '請求先名' }).label,
          fields: [
            {
              type: 'label',
              name: 'billing.companyName',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                if (billing) {
                  return [this.enterpriseFrontId, billing && billing.companyName ? billing.companyName : ''].filter((v) => !!v).join('-');
                }
              },
            },
          ],
        },
        {
          title: (this.dynamicService.getDefinition('billingAddress', 'departmentName') || { label: '担当者部署名' }).label,
          fields: [
            {
              type: 'label',
              name: 'billing.contact.department',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.departmentName ? billing.departmentName : '';
              },
            },
          ],
        },
        {
          title: '担当者役職',
          fields: [
            {
              name: 'billing.contact.position',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.contact && billing.contact.position ? billing.contact.position : '';
              },
            },
          ],
        },
        {
          title: (this.dynamicService.getDefinition('billingAddress', 'contact') || { label: '担当者名' }).label,
          fields: [
            {
              type: 'label',
              name: 'billing.contact.name',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.contact && billing.contact.name ? billing.contact.name : '';
              },
            },
          ],
        },
        {
          title: '担当者Tel',
          fields: [
            {
              name: 'billing.contact.tel',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.contact && billing.contact.tel ? billing.contact.tel : '';
              },
            },
          ],
        },
        {
          title: '担当者Fax',
          fields: [
            {
              name: 'billing.contact.fax',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.contact && billing.contact.fax ? billing.contact.fax : '';
              },
            },
          ],
        },
        {
          title: (this.dynamicService.getDefinition('billingAddress', 'address') || { label: '住所' }).label,
          fields: [
            {
              name: 'billing.contact.address',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.address
                  ? [
                      `${billing.address.zip ? billing.address.zip.slice(0, 3) + '-' + billing.address.zip.slice(3) : ''}`,
                      `${billing.address.prefecture ? billing.address.prefecture : ''}`,
                      `${billing.address.address1 ? billing.address.address1 : ''}`,
                      `${billing.address.address2 ? billing.address.address2 : ''}`,
                      `${billing.address.address3 ? billing.address.address3 : ''}`,
                    ].join(' ')
                  : '';
              },
            },
          ],
        },
        {
          title: '発行先ID',
          fields: [
            {
              name: 'billing.btobId',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.billignAddressCode && billing.billignBikeCD
                  ? `${billing.billignBikeCD}${billing.billignAddressCode}`
                  : '';
              },
            },
          ],
        },
        {
          title: '請求先サービス',
          fields: [
            {
              name: 'billing.billignAddressService',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.billignAddressService ? billing.billignAddressService : '';
              },
            },
          ],
        },
        {
          title: '振込先',
          fields: [
            {
              name: 'billing.machTransferBankList',
              type: 'label',
              supplier: (value, callType, getter) => {
                const billingAddressId = getter('sales.enterpriseBillingAddressId');
                const billing = this.billingOptions.find((v) => v.id === billingAddressId);
                return billing && billing.machTransferBankList ? billing.machTransferBankList : '';
              },
            },
          ],
        },
        ...this.getDynamicRowOrLabel(
          true,
          this.dynamicService.getDefinition('sales', 'billingFrontRemark'),
          null,
          'sales.dynamicData',
          'fill',
        ),
      ],
    };
  }
}
