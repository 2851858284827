import { JobMatching } from '@agent-ds/shared/interfaces/job-matching';
import { StudentMatching } from '@agent-ds/shared/interfaces/student-matching';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchingApiService {
  public readonly refreshEvent = new EventEmitter<void>();

  constructor(private http: HttpClient) {}

  getMatchingsForStudent(studentId: number): Observable<StudentMatching[]> {
    return this.http.get<StudentMatching[]>(`/api/matching/student/${studentId}`);
  }

  saveMatchingForStudent(matching: StudentMatching): Observable<any> {
    return this.http.post('/api/matching/student', matching);
  }

  deleteStudentMatching(studentMatchingId: number): Observable<any> {
    return this.http.delete(`/api/matching/student/${studentMatchingId}`);
  }

  getMatchingsForJob(jobId: number): Observable<JobMatching[]> {
    return this.http.get<JobMatching[]>(`/api/matching/job/${jobId}`);
  }

  saveMatchingForJob(matching: JobMatching): Observable<any> {
    return this.http.post('/api/matching/job', matching);
  }

  deleteJobMatching(jobMatchingId: number): Observable<any> {
    return this.http.delete(`/api/matching/job/${jobMatchingId}`);
  }
}
