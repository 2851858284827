import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ag-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
  @Input() label: string;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Output() removed: EventEmitter<void> = new EventEmitter();
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.selected = !this.selected;
    this.toggled.emit(this.selected);
  }

  remove(event) {
    event.stopPropagation();
    this.removed.emit();
  }
}
