import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';
import { CounselingPageComponent } from './pages/counseling-page/counseling-page.component';

/**
 * Route定数
 */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: CounselingPageComponent,
  },
];

/**
 * 求職者ルーティング
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CounselingRoutingModule {}
