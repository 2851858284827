import { ProgressListComponent } from '@agent-ds/shared/components';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProgressDetailComponent } from '../progress-detail/progress-detail.component';

@Component({
  selector: 'ag-progress-list-page',
  templateUrl: './progress-list-page.component.html',
  styleUrls: ['./progress-list-page.component.scss'],
})
export class ProgressListPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild(ProgressListComponent, { static: false }) progressList: ProgressListComponent;
  @ViewChild('export', { static: false }) exportTemplate: TemplateRef<any>;
  protected readonly model = 'progresses';
  protected readonly detail = ProgressDetailComponent as any;
  protected readonly exportModel = ExportModel.progress;
  private loginSubscription: Subscription;

  isIntakeCa = false;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
    protected authService: AuthService,
  ) {
    super(router, location, activeRoute, exportService);
    // 副担当者のログインかどうかを確認
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      this.isIntakeCa = this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  showExport(): void {
    PopupControlComponent.instance.open({
      title: 'CSV出力',
      content: this.exportTemplate,
      confirmText: 'ダウンロード',
      confirmEnabled: () => this.exportTemplateId != null,
      confirmCallback: () => this.exportService.export(this.exportModel, this.exportTemplateId, this.searchObj),
      cancelText: '閉じる',
    });
  }

  proxySearch(obj: any): void {
    this.doSearch(obj);
  }
}
