import { JobApiService } from '@agent-ds/shared/services';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ag-job-memo-tab',
  templateUrl: './job-memo-tab.component.html',
  styleUrls: ['./job-memo-tab.component.scss'],
})
export class JobMemoTabComponent implements OnInit {
  @Input() jobId: number;

  constructor(public readonly jobApiService: JobApiService) {}

  ngOnInit() {}
}
