import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ImportCompleted } from '@agent-ds/shared/interfaces';
import { CompanyApiService } from '@agent-ds/shared/services';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ag-company-import-page',
  templateUrl: './company-import-page.component.html',
  styleUrls: ['./company-import-page.component.scss'],
})
export class CompanyImportPageComponent implements OnInit {
  @ViewChild('fileinput', { static: false }) private fileinput: HTMLInputElement;
  fileToUpload: File | null = null;
  isImportInProgress = false;
  importData: ImportCompleted | null = null;

  constructor(private companyApiService: CompanyApiService) {}

  ngOnInit() {}

  onInputChanged(event): void {
    this.fileToUpload = event && event.srcElement && event.srcElement.files ? event.srcElement.files[0] : this.fileToUpload;
  }

  onImport() {
    if (!this.fileToUpload) {
      return;
    }
    this.isImportInProgress = true;
    this.companyApiService.uploadCompanyImportFile(this.fileToUpload).subscribe(
      (importData: ImportCompleted) => {
        this.importData = importData;
        this.isImportInProgress = false;
        if (this.fileinput) {
          this.fileinput.value = undefined;
        }
        this.fileToUpload = null;
        if (importData.errorCount === 0 && importData.successCount > 0) {
          PopupControlComponent.instance.open({
            title: 'データ取り込み',
            content: '正常に取り込めました。',
            confirmText: 'OK',
            confirmCallback: () => false,
          });
        }
      },
      () => {
        this.isImportInProgress = false;
        if (this.fileinput) {
          this.fileinput.value = undefined;
        }
      },
    );
  }

  downloadExampleCsv(): void {
    this.companyApiService.downloadImportExampleFile();
  }
}
