import { APPROVAL_STATES } from '@agent-ds/shared/constants';
import { Team } from '@agent-ds/shared/interfaces';
import { MappedUser } from '@agent-ds/shared/interfaces/mapped-user';
import { SaleDetail } from '@agent-ds/shared/interfaces/sale';
import { ExportApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-sales-detail-header',
  templateUrl: './sales-detail-header.component.html',
  styleUrls: ['./sales-detail-header.component.scss'],
})
export class SalesDetailHeaderComponent implements OnInit, OnChanges {
  @Input() sale: SaleDetail;
  @Input() showOnlyMainInfo = false;
  mappedRegisterUser: MappedUser;
  saleRegisterUserTeam: Team;

  APPROVAL_STATES = APPROVAL_STATES;

  inExport = false;

  constructor(private userService: UserTeamInjectorProvider, private exportService: ExportApiService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sale']) {
      if (this.sale) {
        this.userService.getUserInfoById(this.sale.registerUserId).subscribe((info) => (this.mappedRegisterUser = info));
      }
    }
  }

  exportInvoice(): void {
    this.inExport = true;
    this.exportService.exportInvoice(this.sale.id).subscribe(() => (this.inExport = false), () => (this.inExport = false));
  }
}
