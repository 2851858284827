import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { PageFloaterComponent } from '@agent-ds/shared/components/page-floater/page-floater.component';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { StudentImportFile, StudentImportQueue, StudentImportQueueError } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { STUDENT_IMPORT_TABLE_CONFIG } from './student-import-page-table-config';

enum StudentImportErrors {
  '登録内容に不備があります。' = 1,
  'ファイル内で重複しています。' = 2,
  '既に登録されています。' = 3,
  'E-Mailが重複しています。' = 4,
}

enum FloaterContent {
  COMPARE,
  DETAIL,
}

enum ImportStatus {
  START = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
  FAILURE = 4,
  CANCEL = 5,
}

@Component({
  selector: 'ag-student-import-page',
  templateUrl: './student-import-page.component.html',
  styleUrls: ['./student-import-page.component.scss'],
})
export class StudentImportPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('fileinput', { static: false }) private fileinput: HTMLInputElement;
  @ViewChild('errorTypeTemplate', { static: false }) errorTypeTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild(PageFloaterComponent, { static: false }) floater: PageFloaterComponent;
  @ViewChild(PageScrollTableComponent, { static: false }) set tableView(v: PageScrollTableComponent) {
    this.table = v;
    this.chRef.detectChanges();
  }
  table: PageScrollTableComponent | null = null;
  fileToUpload: File | null = null;
  isImportInProgress = false;
  DEFAULT_IMPORT_HISTORY_OPTION = { label: NULL_SELECTED_VALUE, value: null };
  DEFAULT_FIRST_REGISTRATION_ROUTE = { label: 'すべての媒体', value: null };
  importData: StudentImportQueue | null = null;
  importFiles: StudentImportFile[];
  selectedFileId: number | null = this.DEFAULT_IMPORT_HISTORY_OPTION.value;
  registrationRouteOptions: string[];
  selectedFirstRegistrationRoute: string | null = this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
  tableConfig: TableConfig;
  sideActions: SideActionConfig[] = [{ phases: ['1000px', '100%'] }];
  isFloaterOpen: boolean;
  selectedStudentImportErrorId: number | null = null;
  selectedDuplicatedStudentId: number | null = null;
  selectedErrorType: 3 | 4 | null = null;
  floaterContent: FloaterContent;
  readonly StudentImportErrors = StudentImportErrors;
  readonly FloaterContent = FloaterContent;
  private fileImportStatusRetrySubscription: Subscription | null = null;
  private getImportFileSubscription: Subscription | null = null;
  private getImportFileRetrySubscriptions: Subscription[] = [];
  private isLastOperationImport = false;

  constructor(
    private studentApiService: StudentApiService,
    private dynamicFieldService: DynamicFieldService,
    private datePipe: SafeDatePipe,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getImportFileOptions();
    this.getFirstRegistrationRouteOptions();
  }

  ngAfterViewInit() {
    this.tableConfig = STUDENT_IMPORT_TABLE_CONFIG(this.errorTypeTemplate, this.actionsTemplate, (date) => {
      try {
        return this.datePipe.transform(date, 'yyyy/MM/dd (E)');
      } catch (e) {
        return '';
      }
    });
    this.chRef.detectChanges();
  }

  ngOnDestroy() {
    this.cancelImportFileRetry();
  }

  onCloseWithRefresh() {
    this.floater.close();
    this.getImportData(this.selectedFileId, this.selectedFirstRegistrationRoute);
  }

  onInputChanged(event): void {
    this.fileToUpload = event && event.srcElement && event.srcElement.files ? event.srcElement.files[0] : this.fileToUpload;
  }

  onImport() {
    if (!this.fileToUpload) {
      return;
    }
    this.isImportInProgress = true;
    this.isLastOperationImport = true;
    this.studentApiService.uploadStudentImportFile(this.fileToUpload).subscribe(
      (resp: { id: number }) => {
        this.fileImportStatusRetrySubscription = interval(3000)
          .pipe(startWith(0))
          .subscribe(() => {
            this.importData = null;
            this.selectedFileId = null;
            this.getImportFileRetrySubscriptions.push(
              this.studentApiService.getImportFile(resp.id).subscribe(
                (importData: StudentImportQueue) => {
                  if (importData.status === ImportStatus.COMPLETE || importData.status === ImportStatus.FAILURE) {
                    if (this.isLastOperationImport) {
                      this.importData = importData;
                      this.table.allChecked = false;
                    }
                    this.getImportFileOptions(() => {
                      this.selectedFileId = resp.id;
                    });
                    this.isImportInProgress = false;
                    this.fileToUpload = undefined;
                    if (this.fileinput) {
                      this.fileinput.value = undefined;
                    }
                    this.cancelImportFileRetry();
                  }
                },
                () => {
                  this.isImportInProgress = false;
                  if (this.fileinput) {
                    this.fileinput.value = undefined;
                  }
                  this.cancelImportFileRetry();
                },
              ),
            );
          });
      },
      () => {
        this.isImportInProgress = false;
        if (this.fileinput) {
          this.fileinput.value = undefined;
        }
      },
    );
  }

  onClearImportHistory() {
    this.isLastOperationImport = false;
    PopupControlComponent.instance.open({
      width: '600px',
      height: '357px',
      title: '取り込み履歴データのクリア',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: '確定',
      confirmCallback: () => {
        return this.studentApiService.deleteStudentImportFiles().pipe(
          tap(() => {
            this.selectedFirstRegistrationRoute = this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
            this.selectedFileId = this.DEFAULT_IMPORT_HISTORY_OPTION.value;
            this.importFiles = [];
            this.importData = null;
          }),
        );
      },
      cancelText: 'キャンセル',
    });
  }

  onImportErrorSelect(importError: StudentImportQueueError, studentId?: number): void {
    this.isLastOperationImport = false;
    this.selectedStudentImportErrorId = importError.id;
    this.selectedDuplicatedStudentId = studentId;
    this.selectedErrorType = importError.type === 3 ? 3 : importError.type === 4 ? 4 : null;
    if (!this.isFloaterOpen) {
      this.floater.open();
    }
  }

  onDelete(ids: number[]): void {
    PopupControlComponent.instance.open({
      title: '削除',
      cancelText: 'キャンセル',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: 'OK',
      confirmCallback: () => {
        if (this.isFloaterOpen) {
          this.floater.close();
        }
        this.isLastOperationImport = false;
        this.studentApiService.deleteImportError(ids).subscribe(() => {
          this.getImportData(this.selectedFileId, this.selectedFirstRegistrationRoute);
        });
      },
    });
  }

  onBatchDeleteErrors(): void {
    if (!this.table) {
      return;
    }
    this.onDelete(this.table.checkedItems.map((item) => item.id));
  }

  onAccept(studentImportErrorId: number): void {
    this.isLastOperationImport = false;
    this.studentApiService.addImportError(studentImportErrorId, { force: 1 }).subscribe(() => {
      this.getImportData(this.selectedFileId, this.selectedFirstRegistrationRoute);
    });
  }

  downloadExampleCsv(): void {
    this.studentApiService.downloadImportExampleFile();
  }

  getErrorDuplicatesCount(errors: any[]): number {
    return errors && errors.length ? errors.filter((error) => error.type === 3 || error.type === 4).length : 0;
  }

  onFileSelect(fileId: number): void {
    this.isLastOperationImport = false;
    this.selectedFirstRegistrationRoute = this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
    if (isNaN(fileId)) {
      this.importData = null;
      return;
    }
    this.table.allChecked = false;
    this.getImportData(fileId);
  }

  onFirstRegistrationRouteSelect(fileId: number, registrationRoute: string): void {
    this.isLastOperationImport = false;
    this.getImportData(fileId, registrationRoute);
  }

  private getImportData(fileId: number, registrationRoute?: string): void {
    if (!registrationRoute || registrationRoute === `${this.DEFAULT_FIRST_REGISTRATION_ROUTE.value}`) {
      registrationRoute = null;
    }
    if (this.getImportFileSubscription) {
      this.getImportFileSubscription.unsubscribe();
    }
    this.getImportFileSubscription = this.studentApiService
      .getImportFile(fileId, registrationRoute)
      .subscribe((importData: StudentImportQueue) => {
        this.importData = importData;
      });
  }

  private getImportFileOptions(callback?: () => void) {
    this.studentApiService.getImportFiles().subscribe((importFiles: StudentImportFile[]) => {
      this.importFiles = importFiles;
      if (callback) {
        callback();
      }
    });
  }

  private getFirstRegistrationRouteOptions() {
    this.dynamicFieldService.fieldUpdateEvent.subscribe(() => {
      const fieldDefinition = this.dynamicFieldService.getDefinition('student', 'registrationRoute');
      this.registrationRouteOptions =
        fieldDefinition && fieldDefinition.validationStyle && fieldDefinition.validationStyle.options
          ? fieldDefinition.validationStyle.options
          : [];
    });
  }

  private cancelImportFileRetry(): void {
    if (this.fileImportStatusRetrySubscription) {
      this.fileImportStatusRetrySubscription.unsubscribe();
    }
    this.getImportFileRetrySubscriptions.forEach((sub) => sub.unsubscribe());
  }
}
