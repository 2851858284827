import { StudentAcademicTabComponent } from './student-academic-tab/student-academic-tab.component';
import { StudentContactHistoryTabComponent } from './student-contact-history-tab/student-contact-history-tab.component';
import { StudentDetailTabComponent } from './student-detail-tab/student-detail-tab.component';
import { StudentFilesTabComponent } from './student-files-tab/student-files-tab.component';
import { StudentMemoTabComponent } from './student-memo-tab/student-memo-tab.component';
import { StudentMessageTabComponent } from './student-message-tab/student-message-tab.component';
import { StudentOverviewTabComponent } from './student-overview-tab/student-overview-tab.component';
import { StudentPreferencesTabComponent } from './student-preferences-tab/student-preferences-tab.component';
import { StudentProgressTabComponent } from './student-progress-tab/student-progress-tab.component';
import { StudentSeminarsTabComponent } from './student-seminar-tab/student-seminars-tab.component';

export const STUDENT_DETAIL_TABS = [
  StudentMessageTabComponent,
  StudentAcademicTabComponent,
  StudentMemoTabComponent,
  StudentFilesTabComponent,
  StudentProgressTabComponent,
  StudentOverviewTabComponent,
  StudentDetailTabComponent,
  StudentContactHistoryTabComponent,
  StudentPreferencesTabComponent,
  StudentSeminarsTabComponent,
];
