import {
  Job,
  JobFavoriteListItem,
  JobList,
  JobListItem,
  JobSearchParams,
  JobSuggestResponse,
  JobUserCheckResponse,
  SituationMemo,
} from '@agent-ds/shared/interfaces';
import { JobSettingMail } from '@agent-ds/shared/interfaces/job-setting-mail';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';

@Injectable({
  providedIn: 'root',
})
export class JobApiService extends ApiService {
  jobFavoritesChanged: Subject<{ jobId: number; favorite: boolean }> = new Subject<{ jobId: number; favorite: boolean }>();
  constructor(private http: HttpClient, private userMapper: UserTeamInjectorProvider) {
    super();
  }

  getDetail(jobId: number): Observable<Job> {
    return this.http.get<Job>('/api/job/' + jobId);
  }

  getList(search?: JobSearchParams): Observable<JobList> {
    const jobs = this.http.get<JobList>('/api/job/search', {
      params: (search ? toAsialRequestParameters(search) : {}) as { [param: string]: string | string[] },
    });
    return this.userMapper.getRequestAndProcessList(jobs, 'jobs');
  }

  addJob(departmentId: number): Observable<any> {
    return this.http.post(`/api/job`, { departmentId: departmentId }, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  getSuggestions(keyword: string, ids?: number[], enterpriseIds?: number[]): Observable<JobSuggestResponse[]> {
    return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
      ? this.http.get<JobSuggestResponse[]>(`/api/job/suggest`, {
          params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds } as any,
        })
      : of([]);
  }

  getSuggestionsRecruitment(keyword: string, ids?: number[], enterpriseIds?: number[]): Observable<JobSuggestResponse[]> {
    return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
      ? this.http.get<JobSuggestResponse[]>(`/api/job/suggest/recruitment`, {
          params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds } as any,
        })
      : of([]);
  }

  getFavorites(): Observable<JobFavoriteListItem[]> {
    return this.http.get<JobFavoriteListItem[]>('/api/favorite-job/');
  }

  addFavorite(jobId: number): Observable<void> {
    return this.http.post<void>(`/api/favorite-job/${jobId}`, null).pipe(
      tap(() => {
        this.jobFavoritesChanged.next({
          jobId: jobId,
          favorite: true,
        });
      }),
    );
  }

  deleteFavorite(jobId: number): Observable<JobListItem[]> {
    return this.http.delete<JobListItem[]>(`/api/favorite-job/${jobId}`).pipe(
      tap(() => {
        this.jobFavoritesChanged.next({
          jobId: jobId,
          favorite: false,
        });
      }),
    );
  }

  getMemos(id: number, sort?: string, order?: 'asc' | 'desc'): Observable<SituationMemo[]> {
    return this.http.get<SituationMemo[]>(`/api/job/${id}/memos`, { params: { sort: sort || 'registerDate', order: order || 'desc' } });
  }

  addMemo(id: number, memo: SituationMemo): Observable<any> {
    return this.http.post(`/api/job/${id}/memos`, memo);
  }

  updateMemo(id: number, memo: SituationMemo): Observable<any> {
    return this.http.put(`/api/job/${id}/memos/${memo.id}`, memo);
  }

  deleteMemo(id: number, memoId: number): Observable<any> {
    return this.http.delete(`/api/job/${id}/memos/${memoId}`);
  }

  copyJob(id: number): Observable<any> {
    return this.http.post(`/api/job/${id}/copy`, {}, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  downloadPdf(id: number) {
    return this.http.get(`/api/job/${id}/file/pdf`, {
      responseType: 'blob',
      observe: 'response',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    });
  }

  downloadExcel(id: number) {
    return this.http.get(`/api/job/${id}/file/excel`, {
      responseType: 'blob',
      observe: 'response',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      },
    });
  }

  getJobsAsPdf(jobsIds: number[]): Observable<HttpResponse<Blob>> {
    return this.http.get(`/api/job/bulk/file/pdf`, {
      responseType: 'blob',
      observe: 'response',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      params: (jobsIds ? toAsialRequestParameters({ jobIds: jobsIds }) : {}) as { [param: string]: string | string[] },
    });
  }

  ckeckJobUsers(jobIds: number[], status?: string[]): Observable<JobUserCheckResponse[]> {
    return this.http.get<JobUserCheckResponse[]>('/api/job/check/job-user', {
      params: toAsialRequestParameters({ jobIds: jobIds, status: status }) as { [param: string]: string | string[] },
    });
  }

  update(id: number, jobData: object): Observable<any> {
    return this.http.put(`/api/job/${id}`, jobData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  // 開催日程別メール配信取得
  getJobSettingMail(id: number): Observable<JobSettingMail> {
    return this.http.get<JobSettingMail>(`/api/job/${id}/setting-mail`, {});
  }

  // 開催日程別メール配信更新
  updateJobSettingMail(id: number, jobSettingMail: JobSettingMail) {
    return this.http.put(`/api/job/${id}/setting-mail`, jobSettingMail, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }
}
