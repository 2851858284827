import { AuthService, DialogService } from '@agent-ds/shared/services';
import { Component, OnInit } from '@angular/core';
import { PasswordChangeDialogComponent } from '../../password-change-dialog/password-change-dialog.component';

@Component({
  selector: 'ag-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit {
  userMenuShown: boolean;
  bodyClick = () => {
    this.userMenuShown = false;
    document.removeEventListener('click', this.bodyClick);
  };

  constructor(public readonly authService: AuthService, public readonly dialogService: DialogService) {}

  ngOnInit() {}

  userClick(event: MouseEvent): void {
    if (!this.userMenuShown) {
      event.stopPropagation();
      this.userMenuShown = true;
      document.addEventListener('click', this.bodyClick);
    }
  }

  onChangePwClicked(): void {
    this.dialogService.open(PasswordChangeDialogComponent);
  }
}
