import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { formatPrice } from '@agent-ds/shared/util/util';
import { TemplateRef } from '@angular/core';

export const COMPANY_CONTRACTS_TABLE_CONFIG: (
  statusTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  dateTransform: (date: string) => string,
) => TableConfig = (statusTemplate, actionsTemplate, dateTransform) => ({
  head: {
    sticky: true,
    config: [
      {
        dynamicStyle: {
          'background-color': (data) => (data.dynamicData.status === '無効' ? '#F7F7F7' : null),
        },
        columns: [
          {
            fields: [
              {
                name: 'frontId',
                title: '契約ID',
                sortable: false,
              },
              {
                name: 'status',
                hidden: true,
                cellTemplate: statusTemplate,
              },
            ],
            style: {
              width: '85px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.companyName',
                title: '契約先企業名',
                sortable: false,
                style: {
                  'font-size': '14px',
                  'line-height': '14px',
                  color: '#333333',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                },
              },
              {
                name: 'dynamicData.departmentName',
                title: '契約先部署名',
                sortable: false,
              },
            ],
            style: {
              flex: 1,
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.contractType',
                title: '契約',
                sortable: false,
              },
            ],
            style: {
              width: '54px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.contractClassification',
                title: '契約区分',
                sortable: false,
              },
            ],
            style: {
              width: '80px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.fee1Price',
                title: '固定額',
                sortable: false,
                formatter: (data) => formatPrice(data.dynamicData.fee1Price),
                style: {
                  'font-size': '14px',
                  'margin-bottom': '5px',
                },
              },
              {
                name: 'dynamicData.fee2Price',
                hidden: true,
                sortable: false,
                formatter: (data) => formatPrice(data.dynamicData.fee2Price),
                style: {
                  'font-size': '14px',
                },
              },
            ],
            style: {
              width: '114px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.signedDate',
                title: '締結日',
                sortable: false,
                style: {
                  'font-size': '14px',
                  'line-height': '14px',
                },
                formatter: (data) => dateTransform(data.dynamicData.signedDate),
              },
            ],
            style: {
              width: '142px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.contractCategory',
                title: '契約種別',
                sortable: false,
              },
            ],
            style: {
              width: '65px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.approvalClassification',
                title: '承認区分',
                sortable: false,
              },
            ],
            style: {
              width: '75px',
              'padding-right': '15px',
            },
          },
          {
            fields: [
              {
                name: 'actions',
                hidden: true,
                cellTemplate: actionsTemplate,
              },
            ],
            style: {
              width: '60px',
            },
          },
        ],
      },
    ],
  },
});
