import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, args?: any): any {
    if (!size) {
      return '0B';
    } else if (size < 1024) {
      return size + 'B';
    } else if (size < 104857600) {
      return Math.round(size / 1024) + 'KB';
    } else if (size < 107374182400) {
      return Math.round(size / 1048576) + 'MB';
    } else {
      return Math.round(size / 1073741824) + 'GB';
    }
  }
}
