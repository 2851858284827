// tslint:disable-next-line:max-line-length
import { StudentConfirmFileDialogComponent } from '@agent-ds/student/components/student-confirm-file-dialog/student-confirm-file-dialog.component';
// tslint:disable-next-line:max-line-length
import { StudentRenameFileDialogComponent } from '@agent-ds/student/components/student-rename-file-dialog/student-rename-file-dialog.component';
import { BatchUpdateDialogComponent } from './batch-update-dialog/batch-update-dialog.component';
import { SeminarReservationDialogComponent } from './seminar-reservation-dialog/seminar-reservation-dialog.component';
import { StudentCreateDialogComponent } from './student-create-dialog/student-create-dialog.component';
import { StudentDetailHeaderComponent } from './student-detail-header/student-detail-header.component';
import { StudentSearchWithSubUserComponent } from './student-search-with-sub-user/student-search-with-sub-user.component';
import { StudentSearchWithTodaysCounselingComponent } from './student-search-with-todays-counseling/student-search-with-todays-counseling.component';
import { StudentSearchComponent } from './student-search/student-search.component';

/**
 * 求職者コンポーネント群
 */
export const STUDENT_COMPONENTS = [
  StudentDetailHeaderComponent,
  StudentSearchComponent,
  StudentSearchWithTodaysCounselingComponent,
  StudentSearchWithSubUserComponent,
];

export const STUDENT_DIALOG_COMPONENTS = [
  BatchUpdateDialogComponent,
  StudentCreateDialogComponent,
  StudentConfirmFileDialogComponent,
  StudentRenameFileDialogComponent,
  SeminarReservationDialogComponent,
];
