import { GroupMeta, RowMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SalesDetailPageComponent } from '../sales-detail-page.component';
import { HelperBase } from './helper-base';

const COLUMN_STYLE_FLEX5 = { width: 'unset', flex: 0.5, 'margin-right': '20px', 'min-width': 0 };
const COLUMN_STYLE_NUM = { width: '90px', 'margin-right': '20px' };
const HEADER_STYLE = { 'background-color': '#fafafa' };
const GROUP_TITLE = '計上情報';

@Injectable()
export class AccountingInformationHelper extends HelperBase {
  private editable: GroupMeta = { rows: [] };
  private readonly: GroupMeta = { rows: [] };
  private readonlyWithCancelPrice: GroupMeta = { rows: [] };
  private readonlyWithReadonlyCancelPrice: GroupMeta = { rows: [] };

  private matrix: GroupMeta[][];

  constructor(
    dynamicService: DynamicFieldService,
    datePipe: SafeDatePipe,
    private decimalPipe: DecimalPipe,
    private userTeamService: UserTeamInjectorProvider,
    private userService: UserApiService,
  ) {
    super(dynamicService, datePipe);
  }

  hideOrShowRows(detailPage: SalesDetailPageComponent, area: string): void {
    const groupMeta = detailPage.metadataBottomFields.groups.find((group) => group.title === GROUP_TITLE);
    if (groupMeta) {
      const row = groupMeta.rows.find((r) => r.title.endsWith(area.toUpperCase()));
      if (row) {
        for (let idx = 2; idx < row.fields.length; idx++) {
          row.fields[idx].hidden = detailPage.model.sales[area + 'UserId'] ? false : true;
        }
      }
    }
  }

  onUserChange(detailPage: SalesDetailPageComponent, area: string): void {
    this.hideOrShowRows(detailPage, area);
    this.userTeamService.getUserInfoById(detailPage.model.sales[area + 'UserId']).subscribe((userInfo) => {
      if (userInfo && userInfo.id) {
        detailPage.model.sales[`${area}TeamName`] = userInfo.teamName;
        detailPage.model.sales[area + 'Percentage'] = 100;
        detailPage.model.sales[area + 'Reward'] = detailPage.model.sales.dynamicData.fee;
      } else {
        detailPage.model.sales[`${area}TeamName`] = null;
        detailPage.model.sales[area + 'Percentage'] = null;
        detailPage.model.sales[area + 'Reward'] = null;
        detailPage.model.sales.dynamicData[area + 'CancelPrice'] = null;
      }
    });
  }

  onUserInit(detailPage: SalesDetailPageComponent, area: string): void {
    this.hideOrShowRows(detailPage, area);
    this.userTeamService.getUserInfoById(detailPage.model.sales[area + 'UserId']).subscribe((userInfo) => {
      detailPage.model.sales[area + 'TeamName'] = userInfo && userInfo.id ? userInfo.teamName : null;
    });
  }

  init(detailPage: SalesDetailPageComponent): void {
    detailPage.bottomForm.changed.subscribe((change) => {
      if (change === 'sales.raUserId') {
        this.onUserChange(detailPage, 'ra');
      } else if (change === 'sales.paUserId') {
        this.onUserChange(detailPage, 'pa');
      } else if (change === 'sales.caUserId') {
        this.onUserChange(detailPage, 'ca');
      } else if (change === 'sales.raPercentage') {
        detailPage.model.sales.raReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.raPercentage * 0.01);
      } else if (change === 'sales.paPercentage') {
        detailPage.model.sales.paReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.paPercentage * 0.01);
      } else if (change === 'sales.caPercentage') {
        detailPage.model.sales.caReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.caPercentage * 0.01);
      }
    });

    detailPage.saleSubject.subscribe(() => {
      this.onUserInit(detailPage, 'ra');
      this.onUserInit(detailPage, 'pa');
      this.onUserInit(detailPage, 'ca');
    });
  }

  updateFieldDefinitions(): void {
    this.editable = this.getTemplate(true);
    this.readonly = this.getTemplate(false);
    this.readonlyWithCancelPrice = this.getTemplate(false, true);
    this.readonlyWithReadonlyCancelPrice = this.getTemplate(false, false);

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: undefined
      [this.editable, this.editable, this.editable, this.editable], // Approval state: 1 (Not Approved)
      [
        this.readonlyWithReadonlyCancelPrice,
        this.readonlyWithCancelPrice,
        this.readonlyWithCancelPrice,
        this.readonlyWithReadonlyCancelPrice,
      ], // Approval state: 2 (Approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus]];
  }

  private getTemplate(editable: boolean, hasCancelPriceColumn?: boolean): GroupMeta {
    const template: GroupMeta = {
      // Accounting information
      title: GROUP_TITLE,
      class: 'form__group--golden-title-border',
      rows: [
        {
          title: ' ',
          fields: [
            {
              type: 'label',
              name: 'header-user',
              class: 'auto',
              default: '担当者',
              style: COLUMN_STYLE_FLEX5,
            },
            {
              type: 'label',
              name: 'header-team',
              class: 'auto',
              default: 'チーム',
              style: COLUMN_STYLE_FLEX5,
            },
            {
              type: 'label',
              name: 'header-ratio',
              default: '比率',
              style: COLUMN_STYLE_NUM,
            },
            {
              type: 'label',
              name: 'header-amount',
              default: '売上金額',
              style: COLUMN_STYLE_NUM,
              disabled: true,
            },
          ],
          style: HEADER_STYLE,
        },
        ...this.getDynamicTableRow('ca', editable, hasCancelPriceColumn),
        ...this.getDynamicTableRow('ra', editable, hasCancelPriceColumn, true),
        ...this.getDynamicTableRow('pa', editable, hasCancelPriceColumn),
      ],
    };

    if (hasCancelPriceColumn !== undefined) {
      template.rows[0].fields.push({
        type: 'label',
        name: 'header-cancel-price',
        default: '返金金額',
        style: COLUMN_STYLE_NUM,
        disabled: true,
      });
    }

    return template;
  }

  getDynamicTableRow(responsibleRole: string, editable: boolean, hasCancelPriceColumn?: boolean, isRequired?: boolean): RowMeta[] {
    const uppercaseResponsible = responsibleRole.toUpperCase();

    const userSelect: RowMeta[] = editable
      ? this.dynamicService.getFormRows({
          fieldName: 'sales.' + responsibleRole + 'UserId',
          fieldType: 'user',
          label: '担当' + uppercaseResponsible,
          validationStyle: { required: isRequired },
        })
      : [
          {
            title: '担当' + uppercaseResponsible,
            fields: [
              {
                type: 'label',
                name: 'sales.' + responsibleRole + 'UserId',
                supplier: (data) =>
                  data ? this.userService.getAll().pipe(map((users) => (users.find((user) => user.id === data) || { name: '' }).name)) : '',
              },
            ],
          },
        ];

    userSelect[0].fields[0].style = COLUMN_STYLE_FLEX5;
    if (editable) {
      if (Array.isArray(userSelect[0].fields[0].labelField)) {
        const labels = userSelect[0].fields[0].labelField as {
          title?: string;
          name: string;
          class?: string;
          supplier?: (value: any) => any;
          supplierAsync?: (value: any) => Observable<any>;
          hidden?: boolean;
          hiddenAsValue?: boolean;
        }[];
        const label = labels.find((lab) => lab.name === 'team');
        if (label) {
          label.hiddenAsValue = true;
        }
      }
    }

    const teamDropdown: RowMeta[] = [
      {
        title: '担当' + uppercaseResponsible,
        fields: [
          {
            type: 'label',
            name: 'sales.' + responsibleRole + 'TeamName',
          },
        ],
      },
    ];

    teamDropdown[0].fields[0].style = COLUMN_STYLE_FLEX5;

    const ratio: RowMeta[] = this.getDynamicRowOrLabel(editable, {
      fieldName: 'sales.' + responsibleRole + 'Percentage',
      fieldType: 'number',
      displayType: 'number',
      label: 'ratio',
      unit: '%',
    });

    ratio[0].fields[0].style = COLUMN_STYLE_NUM;

    const amount: RowMeta[] = this.getDynamicRowOrLabel(
      false,
      {
        fieldName: 'sales.' + responsibleRole + 'Reward',
        fieldType: 'number',
        displayType: 'number',
        label: 'amount',
        unit: '円',
      },
      null,
      null,
      null,
      (data) => this.decimalPipe.transform(data),
    );

    amount[0].fields[0].style = COLUMN_STYLE_NUM;

    userSelect[0].fields = userSelect[0].fields
      .concat(teamDropdown[0].fields)
      .concat(ratio[0].fields)
      .concat(amount[0].fields);

    if (hasCancelPriceColumn !== undefined) {
      const cancelPrice: RowMeta[] = this.getDynamicRowOrLabel(
        hasCancelPriceColumn,
        {
          fieldName: 'sales.' + responsibleRole + 'CancelPrice',
          fieldType: 'number',
          displayType: 'number',
          label: 'cancelPrice',
          unit: '円',
        },
        null,
        null,
        null,
        (data) => this.decimalPipe.transform(data),
      );

      cancelPrice[0].fields[0].style = COLUMN_STYLE_NUM;
      userSelect[0].fields = userSelect[0].fields.concat(cancelPrice[0].fields);
    }

    return userSelect;
  }
}
