import { Tab } from '@agent-ds/shared/models';
import { CompanyApiService } from '@agent-ds/shared/services';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'ag-company-memo-tab',
  templateUrl: './company-memo-tab.component.html',
  styleUrls: ['./company-memo-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyMemoTabComponent) }],
})
export class CompanyMemoTabComponent extends Tab {
  @Input() companyId: number;

  constructor(public readonly companyApiService: CompanyApiService) {
    super();
  }
}
