import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { BatchResult, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { Branch, DataFile, StudentDataFile, Team, User } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { FileApiService, MasterApiService, UserApiService } from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ag-student-uploads-page',
  templateUrl: './student-uploads-page.component.html',
  styleUrls: ['./student-uploads-page.component.scss'],
})
export class StudentUploadsPageComponent implements AfterViewInit, OnChanges {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('branch', { static: true }) branchTemplate: TemplateRef<any>;
  @ViewChild('inCharge', { static: true }) inChargeTemplate: TemplateRef<any>;

  initialized = false;

  get branches(): Observable<Branch[]> {
    return this.masterService.getBranches();
  }

  get users(): Observable<User[]> {
    return this.userService.getAll();
  }

  get teams(): Observable<Team[]> {
    return this.masterService.getTeams();
  }

  constructor(
    private readonly fileApiService: FileApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datePipe: SafeDatePipe,
    private readonly userService: UserApiService,
    private readonly masterService: MasterApiService,
  ) {}

  ngAfterViewInit() {
    this.table.tableConfig = this.getTableConfig();
    this.cdr.detectChanges();
    this.initialized = true;
    this.refresh();
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
      this.cdr.detectChanges();
      this.refresh();
    }
  }

  refresh() {
    if (this.table && this.initialized) {
      this.table.reset(true);
      this.table.next();
    }
  }

  onItemSelected(item?: { studentId: number }): void {
    StudentDetailPageComponent.instance.referenceId = item ? item.studentId : null;
    StudentDetailPageComponent.instance.open();
  }

  loadUploads = (page: number, size: number): Observable<BatchResult> => {
    const from = page * size;

    return this.fileApiService.getUnconfirmedFiles(from, size).pipe(map((res) => this.convertUploadList(res)));
  };

  private convertUploadList(uploads: { totalSize: number; files: DataFile[] }): BatchResult {
    return {
      totalSize: uploads.totalSize,
      result: uploads.files,
    };
  }

  private getTableConfig(): TableConfig {
    return {
      head: {
        sticky: true,
        config: [
          {
            columns: [
              {
                fields: [
                  {
                    name: 'createdAt',
                    title: '登録日時',
                    sortable: false,
                    formatter: (data: StudentDataFile) => this.datePipe.transform(data.createdAt, 'yyyy/MM/dd (E) H:m'),
                  },
                ],
                style: {
                  width: '170px',
                },
              },
              {
                fields: [
                  {
                    name: 'studentId',
                    title: '求職者ID',
                    sortable: false,
                  },
                ],
                style: {
                  width: '70px',
                },
              },
              {
                fields: [
                  {
                    name: 'name',
                    title: 'ファイル名',
                    sortable: false,
                  },
                ],
                style: {
                  width: '320px',
                },
              },
              {
                fields: [
                  {
                    name: 'name',
                    title: '支援拠点',
                    sortable: false,
                    cellTemplate: this.branchTemplate,
                  },
                ],
                style: {
                  width: '70px',
                },
              },
              {
                fields: [
                  {
                    name: 'name',
                    title: '担当者',
                    sortable: false,
                    cellTemplate: this.inChargeTemplate,
                  },
                ],
                style: {
                  width: '240px',
                },
              },
              {
                fields: [
                  {
                    name: 'size',
                    title: 'サイズ',
                    sortable: false,
                    formatter: (data: StudentDataFile) => `${Math.round(data.size / 1_000)}KB`,
                  },
                ],
                style: {
                  width: '70px',
                },
              },
              {
                fields: [
                  {
                    name: 'type',
                    title: '種類',
                    sortable: false,
                    formatter: (data: StudentDataFile) => data.name.split('.').pop(),
                  },
                ],
                style: {
                  width: '50px',
                },
              },
            ],
          },
        ],
      },
      body: {
        checkbox: false,
      },
    };
  }
}
