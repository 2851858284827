import { CompanyCreateDialogComponent } from '@agent-ds/company/components';
import { CompanyDetailPageComponent } from '@agent-ds/company/pages/company-detail-page/company-detail-page.component';
import { CompanyTabs } from '@agent-ds/company/pages/company-detail-page/tabs/company-tabs.enum';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { Company, DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, DialogService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { cleanObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { COMPANY_LIST_TABLE_CONFIG } from '../../../shared/components/company-list/company-list-table-config';
import { DialogRef } from '../dialog/dialog-ref';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { BatchResult, TableConfig } from '../page-scroll-table/table-interface';

@Component({
  selector: 'ag-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('companyName', { static: false }) companyNameTemplate: TemplateRef<any>;
  @ViewChild('agStatus', { static: false }) agStatusTemplate: TemplateRef<any>;
  @ViewChild('drStatus', { static: false }) drStatusTemplate: TemplateRef<any>;
  @ViewChild('representative', { static: false }) representativeTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @Input() requestObject = {};
  @Input() options: any = {};
  @Input() extraControls: TemplateRef<any>;
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();

  tableConfig: TableConfig;
  dialogRef: DialogRef;
  initialized = false;
  tabs = CompanyTabs;

  get checkedItems(): Company[] {
    return this.table ? this.table.checkedItems : [];
  }

  get allChecked(): boolean {
    return this.table.allChecked;
  }

  get totalSize(): number {
    return this.table.totalSize;
  }

  private refreshSubscription: Subscription;
  private loginSubscription: Subscription;
  private isIntakeCa = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private companyApiService: CompanyApiService,
    private readonly authService: AuthService,
    private dialog: DialogService,
    private location: Location,
    public readonly userInjector: UserTeamInjectorProvider,
  ) {}

  ngOnInit() {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });
    this.refreshSubscription = this.companyApiService.refreshEvent.subscribe(() => this.ngOnChanges());
    if (!this.options.sort) {
      this.options.sort = { field: 'updatedAt', order: 'desc' };
    }

    this.checkRoute();
  }

  ngAfterViewInit() {
    this.tableConfig = COMPANY_LIST_TABLE_CONFIG(
      this.companyNameTemplate,
      this.agStatusTemplate,
      this.drStatusTemplate,
      this.representativeTemplate,
      this.actionsTemplate,
      (event: MouseEvent, data: Company) => this.onCompanyLinkClick(event, data, CompanyTabs[CompanyTabs.SALES]),
    );
    this.cdr.detectChanges();
    this.initialized = true;
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
      this.cdr.detectChanges();
      if (this.table && this.initialized) {
        this.table.reset(true);
        this.table.next();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private checkRoute() {
    if (this.router.url.endsWith('/enterprises/add')) {
      this.openCompanyCreateDialog();
    }
  }

  public openCompanyCreateDialog(origUrl = this.location.path()) {
    if (this.dialogRef) {
      return;
    }
    CompanyDetailPageComponent.instance.close();
    JobDetailPageComponent.instance.close();
    this.location.go('/enterprises/add');
    this.dialogRef = this.dialog.open(CompanyCreateDialogComponent);

    let closeSubscription: Subscription;
    let navigationSubscription: SubscriptionLike;

    const unsubscribe = () => {
      if (closeSubscription) {
        closeSubscription.unsubscribe();
      }
      if (navigationSubscription) {
        navigationSubscription.unsubscribe();
      }
    };

    closeSubscription = this.dialogRef.afterClosed.subscribe(() => {
      this.location.go(origUrl);
      this.dialogRef = null;
      unsubscribe();
    });

    navigationSubscription = this.location.subscribe(() => {
      this.dialogRef.close();
      unsubscribe();
    });
  }

  onItemSelected(selectedItem): void {
    CompanyDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
    CompanyDetailPageComponent.instance.open();
  }

  onItemChecked(checkedItemEvent): void {
    this.itemChecked.emit(checkedItemEvent);
  }

  onCompanyLinkClick(event: MouseEvent, company: Company, tab: string): void {
    event.stopPropagation();
    if (tab === 'userList') {
      CompanyDetailPageComponent.instance.selectedSideActionIndex = 1;
    } else {
      CompanyDetailPageComponent.instance.tabCode = tab;
    }
    this.onItemSelected(company);
  }

  private convertCompanyList(res: any): BatchResult {
    // default result
    const result = {
      result: [],
      totalSize: res && res.total ? res.total : 0,
    };

    // map all elements to make object root to _source property
    if (res && res.enterprises && res.enterprises.length > 0) {
      result.result = res.enterprises;
    }
    return result;
  }

  loadList = (page: number, size: number, sort?: string, order?: string): Observable<BatchResult> => {
    const params = cleanObject({
      ...this.requestObject,
      from: page * size,
      size: size,
      sort: sort,
      order: order,
    });

    return this.companyApiService.getList(params).pipe(map((res: any) => this.convertCompanyList(res)));
  };
}
