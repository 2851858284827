import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { APPROVAL_STATES, getProgressStatusLabel } from '@agent-ds/shared/constants';
import { CompanyDetail, EnterpriseDepartmentUserType, Job, ProgressItemResponse, SituationMemo } from '@agent-ds/shared/interfaces';
import { SalesListItem } from '@agent-ds/shared/interfaces/sale';
import { DynamicField, FormMeta, Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, MasterApiService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { JobTabs } from '../job-tabs.enum';

const DEFAULT_TRUNCATE_AT = 130;

@Component({
  selector: 'ag-job-overview-tab',
  templateUrl: './job-overview-tab.component.html',
  styleUrls: ['./job-overview-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => JobOverviewTabComponent) }],
})
export class JobOverviewTabComponent extends Tab implements OnInit {
  readonly tabs = JobTabs;
  @Input() job: Job;
  @Input() memos: SituationMemo[] = [];
  @Input() progresses: ProgressItemResponse[] = [];
  @Input() sales: SalesListItem[] = [];
  @Input() company: CompanyDetail;
  @Input() isIntakeCa: boolean;
  truncateAt = DEFAULT_TRUNCATE_AT;

  metadataHead: FormMeta;

  userTypes = EnterpriseDepartmentUserType;

  readonly APPROVAL_STATES = APPROVAL_STATES;
  capitalDef: DynamicField;
  salesAmountDef: DynamicField;
  numberOfEmployeeDef: DynamicField;
  getStatusLabel = getProgressStatusLabel;

  constructor(
    public readonly masterApiService: MasterApiService,
    public readonly userApiService: UserApiService,
    public readonly userTeamProvider: UserTeamInjectorProvider,
    private dynamicService: DynamicFieldService,
    private datePipe: SafeDatePipe,
  ) {
    super();
  }

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataHead = {
        groups: [
          {
            class: 'oneliner',
            rows: [
              {
                title: '求人ステータス',
                fields: [
                  {
                    type: 'label',
                    name: 'dynamicData.status',
                    supplier: (val) => (val ? val : '-'),
                  },
                ],
              },
              {
                title: 'エージェント\n採用人数',
                fields: [
                  {
                    type: 'label',
                    name: 'dynamicData.agentServiceHire',
                    supplier: (val) => (val ? val : '-'),
                  },
                ],
              },
              {
                title: '更新者',
                class: 'fill',
                fields: [
                  {
                    type: 'label',
                    name: 'updateUserId',
                    supplier: () => (this.job ? this.userTeamProvider.getUserTeamNameById(this.job.updateUserId) : '-'),
                  },
                ],
              },
              {
                title: '入力日',
                fields: [
                  {
                    type: 'label',
                    name: 'registeredAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
              {
                title: '更新日',
                fields: [
                  {
                    type: 'label',
                    name: 'lastUpdatedAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
            ],
          },
        ],
      };
      this.capitalDef = this.dynamicService.getDefinition('enterprise', 'capital');
      this.salesAmountDef = this.dynamicService.getDefinition('enterprise', 'salesAmount');
      this.numberOfEmployeeDef = this.dynamicService.getDefinition('enterprise', 'numberEmployees');
    });
  }

  onCompanyLinkClick(id: number): void {
    CompanyDetailPageComponent.instance.referenceId = id;
    CompanyDetailPageComponent.instance.open();
  }

  onStudentLinkClick(id: number): void {
    StudentDetailPageComponent.instance.referenceId = id;
    StudentDetailPageComponent.instance.open();
  }
}
