import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { ProgressKpiResponseSharedData, Team } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DialogService, DynamicFieldService, MasterApiService, ProgressApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ag-progress-report-page',
  templateUrl: './progress-report-page.component.html',
  styleUrls: ['./progress-report-page.component.scss'],
})
export class ProgressReportPageComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: DynamicFormComponent;
  formMetadata: FormMeta;
  formModel: any = {};

  caKpiReports: (ProgressKpiResponseSharedData & { userName?: string })[];
  raKpiReports: (ProgressKpiResponseSharedData & { userName?: string })[];
  caKpiTotal: ProgressKpiResponseSharedData | any = {};
  raKpiTotal: ProgressKpiResponseSharedData | any = {};
  valid: boolean;
  team: Team | null = null;

  constructor(
    private dynamicService: DynamicFieldService,
    private progressApiService: ProgressApiService,
    private dialog: DialogService,
    private masterApiService: MasterApiService,
    private userTeamService: UserTeamInjectorProvider,
  ) {}

  ngOnInit() {
    this.formMetadata = {
      groups: [
        {
          rows: [
            (() => {
              const rows = this.dynamicService.getFormRows({
                fieldName: 'date',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: 'アクション期間',
              });
              const [action1, action2, ...rest] = rows[0].fields[3].actions;
              rows[0].fields[0].validators = { required: true };
              rows[0].fields[2].validators = { required: true };
              rows[0].fields[3].actions = [action1, action2];
              return rows[0];
            })(),
            (() => {
              const rows = this.dynamicService.getFormRows({
                fieldName: 'teamId',
                fieldType: 'team',
                displayType: 'dropdown',
                label: 'チーム',
              });
              rows[0].fields[0].class = 'quarter';
              rows[0].fields[0].validators = { required: true };
              return rows[0];
            })(),
          ],
        },
      ],
    };
  }

  onValidityChange(validity: boolean): void {
    this.valid = validity;
  }

  onSearch(): void {
    if (!this.valid) {
      return;
    }
    const fromDate = new Date(this.formModel.date.from);
    const toDate = new Date(this.formModel.date.to);
    if (fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()) {
      const config: ConfirmDialogConfig = {
        title: '日付期間が間違えています',
        messages: ['同月の日付を指定してください。'],
        style: {
          height: '245px',
          width: '510px',
        },
        buttons: {
          no: '',
          hideNo: true,
          yes: 'OK',
        },
      };
      this.dialog.open(ConfirmDialogComponent, config);
      return;
    }

    forkJoin(
      this.progressApiService.getReportRapa(this.formModel.date, this.formModel.teamId),
      this.progressApiService.getReportCa(this.formModel.date, this.formModel.teamId),
      this.masterApiService.getTeams(),
    ).subscribe(([raKpiReports, caKpiReports, teams]) => {
      this.raKpiReports = raKpiReports.items || [];
      this.caKpiReports = caKpiReports.items || [];
      this.raKpiReports.forEach((report) =>
        this.userTeamService.getUserInfoById(report.userId).subscribe((user) => (report.userName = user.name)),
      );
      this.caKpiReports.forEach((report) =>
        this.userTeamService.getUserInfoById(report.userId).subscribe((user) => (report.userName = user.name)),
      );
      this.raKpiTotal = raKpiReports.total || ({} as any);
      this.caKpiTotal = caKpiReports.total || ({} as any);
      this.team = teams.find((team) => team.id === this.formModel.teamId);
    });
  }
}
