import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExtendedProgressStatusCode, ProgressStatusCode } from '@agent-ds/shared/enums';
import { JobUserCheckResponse, ProgressCheckResponse, ProgressCreateRequest } from '@agent-ds/shared/interfaces';
import { DynamicField, FormMeta } from '@agent-ds/shared/models';
import { DialogService, DynamicFieldService, JobApiService, ProgressApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogConfig } from '../../../shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'ag-progress-create-dialog',
  templateUrl: './progress-create-dialog.component.html',
  styleUrls: ['./progress-create-dialog.component.scss'],
})
export class ProgressCreateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('progressError', { static: false }) progressErrorTemplate: TemplateRef<any>;
  @ViewChild('jobError', { static: true }) jobErrorTemplate: TemplateRef<any>;
  form: FormGroup;
  inClose = false;

  meta: FormMeta;
  model: any = {};
  isModelValid = false;
  jobs: string;
  students: string;

  progressErrorInfo: ProgressCheckResponse[] = [];
  jobErrorInfo: JobUserCheckResponse[] = [];
  progressStatus = ProgressStatusCode;
  extendedProgressStatus = ExtendedProgressStatusCode;
  confirmSubscription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private formBuilder: FormBuilder,
    private jobApiService: JobApiService,
    private progressApiService: ProgressApiService,
    private dialogService: DialogService,
    private dynamicFieldService: DynamicFieldService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    this.form = this.formBuilder.group({
      type: [null, Validators.required],
      hasInterview: false,
    });

    this.model.studentId = this.config.data.studentId;
    this.model.jobId = this.config.data.jobId;
    this.model.registeredAt = new Date();

    if (this.config.data.matchingMode) {
      if (Array.isArray(this.config.data.studentList)) {
        this.students = this.config.data.studentList
          .filter((s) => s)
          .map((st) =>
            [st.frontId, st.dynamicData ? st.dynamicData.lastName : null, st.dynamicData ? st.dynamicData.firstName : null]
              .filter((stData) => stData)
              .join(' '),
          )
          .join('、');
      }

      if (Array.isArray(this.config.data.jobList)) {
        this.jobs = this.config.data.jobList
          .filter((j) => j)
          .map((job) =>
            [job.frontJobId, job.dynamicData ? job.dynamicData.position : null, job.enterpriseName].filter((jbData) => jbData).join(' '),
          )
          .join('、');
      }
    }

    const getRowsWithoutRequiredLabel = (dynamicField: DynamicField, className?: string) => {
      const rows = this.dynamicFieldService.getFormRows(dynamicField, undefined, className);
      rows.forEach((row) => (row.showRequired = false));
      return rows;
    };

    if (this.config.data.matchingMode) {
      this.meta = {
        groups: [
          {
            class: 'no-border title-w80 row-gap-20 no-background no-padding',
            rows: [
              ...getRowsWithoutRequiredLabel({
                fieldName: 'registeredAt',
                fieldType: 'date',
                displayType: 'date+time',
                label: '登録日',
                validationStyle: { required: true },
              }),
            ],
          },
        ],
      };
    } else {
      this.meta = {
        groups: [
          {
            class: 'no-border title-w80 row-gap-20 no-background no-padding',
            rows: [
              ...getRowsWithoutRequiredLabel(
                { fieldType: 'student', fieldName: 'studentId', label: '求職者', validationStyle: { required: true } },
                'three-quarter',
              ),
              (() => {
                const row = getRowsWithoutRequiredLabel(
                  { fieldType: 'job', fieldName: 'jobId', label: '求人', validationStyle: { required: true } },
                  'three-quarter',
                )[0];
                row.fields[0].filters = [
                  {
                    name: 'status',
                    hidden: true,
                    options: [['募集中', '推薦停止']],
                    supplier: () => 0,
                  },
                ];
                if (this.config.data.enterpriseId) {
                  row.fields[0].filters.push({
                    name: 'enterpriseIds',
                    hidden: true,
                    options: [[this.config.data.enterpriseId]],
                    supplier: () => 0,
                  });
                }
                row.fields[0].placeholder = '求人ID、求人名、企業名';
                return row;
              })(),
              ...getRowsWithoutRequiredLabel({
                fieldName: 'registeredAt',
                fieldType: 'date',
                displayType: 'date+time',
                label: '登録日',
                validationStyle: { required: true },
              }),
            ],
          },
        ],
      };
    }
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  create() {
    if (this.form.invalid || !this.isModelValid || this.inClose || this.dialog.inClose) {
      return;
    }

    const studentIds = this.config.data.matchingMode ? this.config.data.studentList.map((student) => student.id) : [this.model.studentId];

    const jobIds = this.config.data.matchingMode ? this.config.data.jobList.map((job) => job.id) : [this.model.jobId];

    this.inClose = true;
    this.progressApiService.checkProgress(studentIds, jobIds).subscribe((res) => {
      if (res && res.length) {
        this.progressErrorInfo = res;
        if (!this.config.data.matchingMode) {
          PopupControlComponent.instance.open({
            cancelText: '中止',
            cancelCallback: () => this.close(),
            confirmText: '上書き',
            content: this.progressErrorTemplate,
            title: '進捗重複',
            confirmCallback: () =>
              forkJoin(
                this.progressErrorInfo.map((error) =>
                  this.progressApiService.overwrite(error.progressId, {
                    type: this.form.value.type,
                    hasInterview: this.form.value.hasInterview ? 1 : 0,
                    registeredAt: this.model.registeredAt,
                  }),
                ),
              ).pipe(
                tap(() => {
                  this.progressApiService.refreshEvent.emit();
                  this.close();
                }),
              ),
          });
        } else {
          const newProgressesRequests = studentIds
            .map((studentId) =>
              jobIds
                .filter((jobId) => !this.progressErrorInfo.some((error) => error.studentId === studentId && error.jobId === jobId))
                .map((jobId) => {
                  return this.progressApiService.addProgress({
                    registeredAt: this.model.registeredAt,
                    type: this.form.value.type,
                    hasInterview: this.form.value.hasInterview ? 1 : 0,
                    studentId: studentId,
                    jobId: jobId,
                  });
                }),
            )
            .flatten();
          PopupControlComponent.instance.open({
            cancelText: '中止',
            cancelCallback: () => this.close(),
            confirmText: null,
            content: this.progressErrorTemplate,
            title: '進捗重複',
            confirmCallback: null,
            multiConfirm: [
              {
                confirmText: '重複を上書きして登録',
                confirmCallback: () => {
                  const overwrites = this.progressErrorInfo.map((error) =>
                    this.progressApiService.overwrite(error.progressId, {
                      type: this.form.value.type,
                      hasInterview: this.form.value.hasInterview ? 1 : 0,
                      registeredAt: this.model.registeredAt,
                    }),
                  );
                  return forkJoin([...overwrites, ...newProgressesRequests]).pipe(
                    tap(() => {
                      this.progressApiService.refreshEvent.emit();
                      this.close();
                    }),
                  );
                },
              },
              {
                confirmText: '重複を除いて登録',
                // disable when all of selected have '進捗重複' errors
                // length of jobIds or studentIds must be 1 if the another has over 1 length
                confirmEnabled: () => !(this.progressErrorInfo.length === jobIds.length + studentIds.length - 1),
                confirmCallback: () => {
                  return forkJoin(newProgressesRequests).pipe(
                    tap(() => {
                      this.progressApiService.refreshEvent.emit();
                      this.close();
                    }),
                  );
                },
                confirmClass: 'btn--green',
              },
            ],
          });
        }
      } else {
        this.save(studentIds, jobIds);
      }
    });
  }

  private save(studentIds: number[], jobIds: number[]): void {
    this.jobApiService.ckeckJobUsers(jobIds).subscribe(
      (jobUsers: JobUserCheckResponse[]) => {
        if (jobUsers && jobUsers.length) {
          this.jobErrorInfo = [];
          jobUsers.forEach((r: JobUserCheckResponse) => {
            if (!r.jobUsers.length) {
              this.jobErrorInfo.push(r);
            }
          });
          if (this.jobErrorInfo.length) {
            PopupControlComponent.subInstance.open({
              title: '必要な情報が入力されていません',
              confirmText: '閉じる',
              content: this.jobErrorTemplate,
              confirmCallback: () => false,
            });
            return;
          } else {
            const requestObject: ProgressCreateRequest = {
              registeredAt: this.model.registeredAt,
              type: this.form.value.type,
              hasInterview: this.form.value.hasInterview ? 1 : 0,
            };
            let request: Observable<any>;
            if (studentIds.length === 1 && jobIds.length > 1) {
              request = this.progressApiService.bulkCreateStudent(studentIds[0], jobIds, requestObject);
            } else if (studentIds.length > 1 && jobIds.length === 1) {
              request = this.progressApiService.bulkCreateJob(jobIds[0], studentIds, requestObject);
            } else {
              request = forkJoin(
                studentIds
                  .map((studentId) =>
                    jobIds.map((jobId) => {
                      return this.progressApiService.addProgress({ ...requestObject, studentId: studentId, jobId: jobId });
                    }),
                  )
                  .flatten(),
              );
            }
            request.subscribe(
              (res) => {
                this.progressApiService.refreshEvent.emit();
                this.inClose = false;
                this.close(res);
              },
              () => (this.inClose = false),
            );
          }
        } else {
          this.inClose = false;
          const config: ConfirmDialogConfig = {
            title: '必要な情報が入力されていません',
            messages: ['担当が設定されていません。'],
            style: {
              height: '245px',
              width: '510px',
            },
            buttons: {
              hideNo: true,
              no: '',
              yes: '閉じる',
            },
          };
          this.dialogService.open(ConfirmDialogComponent, config);
        }
      },
      () => (this.inClose = false),
    );
  }

  close(param?: any) {
    this.dialog.close(param);
  }
}
