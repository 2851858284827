import { LoginPageComponent } from '@agent-ds/shared/pages/login-page/login-page.component';
import { NotFoundPageComponent } from '@agent-ds/shared/pages/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignatureCsvSettingsPageComponent } from './settings/pages';
import { AuthGuard } from './shared/guard';
import { OutlookListComponent } from './shared/pages/outlook-list/outlook-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  { path: 'students', loadChildren: './student/student.module#StudentModule' },
  { path: 'enterprises', loadChildren: './company/company.module#CompanyModule' },
  { path: 'sales', loadChildren: './sales/sales.module#SalesModule' },
  { path: 'jobs', loadChildren: './jobs/jobs.module#JobsModule' },
  { path: 'progresses', loadChildren: './progress/progress.module#ProgressModule' },
  { path: 'counseling', loadChildren: './counseling/counseling.module#CounselingModule' },
  { path: 'matching', loadChildren: './matching/matching.module#MatchingModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsModule' },
  { path: 'login', component: LoginPageComponent, canActivate: [AuthGuard] },
  {
    path: 'signature-csv',
    canActivate: [AuthGuard],
    component: SignatureCsvSettingsPageComponent,
  },
  { path: 'mailerIntegration-login', component: LoginPageComponent, canActivate: [AuthGuard] },
  { path: 'mailerIntegration-mail', component: OutlookListComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
