import { GroupMeta } from '@agent-ds/shared/models';
import { Injectable } from '@angular/core';
import { HelperBase } from './helper-base';

@Injectable()
export class ClosingInformationHelper extends HelperBase {
  private editable: GroupMeta;
  private readonly: GroupMeta;
  private matrix: GroupMeta[][];

  updateFieldDefinitions(): void {
    this.editable = {
      // Closing information
      title: '成約情報',
      class: 'form__group--golden-title-border',
      rows: [
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'salesClassification'), 'sales.dynamicData', 'half'),
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'hireType'), 'sales.dynamicData'),
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'contractDate'), 'sales.dynamicData'),
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'enterDate'), 'sales.dynamicData'),
      ],
    };

    this.readonly = {
      // Closing information
      title: '成約情報',
      class: 'form__group--golden-title-border',
      rows: [
        {
          title: '契約区分',
          fields: [
            {
              type: 'label',
              name: 'sales.dynamicData.salesClassification',
              class: 'auto',
            },
          ],
        },
        {
          title: '雇用形態',
          fields: [
            {
              type: 'label',
              name: 'sales.dynamicData.hireType',
              class: 'auto',
            },
          ],
        },
        {
          title: '成約日',
          fields: [
            {
              type: 'label',
              name: 'sales.dynamicData.contractDate',
              class: 'auto',
              supplier: (val) => this.datePipe.transform(val),
            },
          ],
        },
        {
          title: '入社日',
          fields: [
            {
              type: 'label',
              name: 'sales.dynamicData.enterDate',
              class: 'auto',
              supplier: (val) => this.datePipe.transform(val),
            },
          ],
        },
      ],
    };

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: undefined
      [this.editable, this.editable, this.editable, this.editable], // Approval state: 1 (Not Approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: 2 (Approved)
      [this.readonly, this.readonly, this.readonly, this.readonly], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus]];
  }
}
