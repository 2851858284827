import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { Company, CompanyBillingAddress, EnterpriseDepartment } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CompanyApiService } from '@agent-ds/shared/services/api/company-api.service';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ag-company-billing-address-create-dialog',
  templateUrl: './company-billing-address-create-dialog.component.html',
  styleUrls: ['./company-billing-address-create-dialog.component.scss'],
})
export class CompanyBillingAddressCreateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('deptSelect', { static: true }) deptSelectorTemplate: TemplateRef<any>;
  model: any;

  metadataLeft: FormMeta;
  metadataRight: FormMeta;

  departmentDynamicList = [];
  private validityArray = [false, false];
  valid = false;
  confirmSubscription: Subscription;
  private fieldSubscription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig<{ billing: CompanyBillingAddress; company: Company; departmentList: EnterpriseDepartment[] }>,
    private dynamicService: DynamicFieldService,
    private masterApiService: MasterApiService,
    private companyService: CompanyApiService,
    private datePipe: SafeDatePipe,
  ) {}

  ngOnInit() {
    this.model = this.config.data.billing.dynamicData ? this.config.data.billing : { dynamicData: {} };
    this.validityArray = this.config.data.billing.dynamicData ? [true, true] : [false, false];
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.departmentDynamicList = this.config.data.departmentList.map((dep: EnterpriseDepartment) => dep.dynamicData);

      this.metadataLeft = {
        groups: [
          {
            title: '企業情報',
            class: 'form__group--red-title-border',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('enterprise', 'name'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = () => this.config.data.company.dynamicData.name;
                return row;
              })(),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('enterprise', 'industry'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = () =>
                  this.masterApiService
                    .getFlattenedIndustryTypes()
                    .pipe(
                      map(
                        (industries) =>
                          (industries.find((ind) => ind.code === this.config.data.company.dynamicData.industry) || { name: '' }).name,
                      ),
                    );
                return row;
              })(),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('enterprise', 'marketName'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = () => this.config.data.company.dynamicData.marketName;
                return row;
              })(),
            ],
          },
          {
            title: '部署住所情報',
            class: 'form__group--red-title-border',
            injectToHeader: this.deptSelectorTemplate,
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'companyName'), null, 'full'),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('billingAddress', 'billignBikeCD'),
                })[0];
                row.fields[0].class = 'full';
                row.fields[0].supplier = (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const btoBBillignAddressRegistrationDate = getValue('btoBBillignAddressRegistrationDate');
                  if (btoBBillignAddressRegistrationDate === undefined) {
                    row.fields[0].linkTo = ['billignBikeCD'];
                    row.fields[0].actions = [
                      {
                        type: 'UPDATE_LINKED',
                        title: '企業ID引用',
                      },
                    ];
                  }
                  return callType === SupplierCallType.LINK_CHANGE ? this.config.data.company.id + 40000000 : value ? value : undefined;
                };
                return row;
              })(),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('billingAddress', 'billignAddressCode'),
                })[0];
                row.fields[0].class = 'full';
                row.fields[0].supplier = (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const btoBBillignAddressRegistrationDate = getValue('btoBBillignAddressRegistrationDate');
                  if (btoBBillignAddressRegistrationDate === undefined) {
                    row.fields[0].linkTo = ['billignAddressCode'];
                    row.fields[0].actions = [
                      {
                        type: 'UPDATE_LINKED',
                        title: '請求先ID割当',
                      },
                    ];
                  }
                  const billignBikeCD = getValue('billignBikeCD');
                  return callType === SupplierCallType.LINK_CHANGE
                    ? this.companyService.getBllingAddressCode(String(billignBikeCD)).pipe(map((res) => ({ value: res })))
                    : value
                    ? value
                    : undefined;
                };
                return row;
              })(),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'address')),
            ],
          },
          {
            title: 'BtoB情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'billignAddressStatus')),
              ...(() => {
                const serviceRow = this.dynamicService.getFormRows(
                  this.dynamicService.getDefinition('billingAddress', 'billignAddressService'),
                  null,
                  'full',
                )[0];
                serviceRow.fields[0].linkTo = ['machTransferBankList'];
                const listRow = this.dynamicService.getFormRows(
                  this.dynamicService.getDefinition('billingAddress', 'machTransferBankList'),
                  null,
                  'full',
                )[0];
                listRow.fields[0].supplier = (value, callType, getValue) => {
                  const linkValue = getValue('billignAddressService');
                  switch (linkValue) {
                    case null:
                      return null;
                    case 'AG':
                      // listRow.fields[0].options = ['01_AG用'];
                      return '01_AG用';
                    case 'DR':
                      // listRow.fields[0].options = ['02_DR用'];
                      return '02_DR用';
                    case 'その他':
                      return value ? value : null;
                  }
                };
                // listRow.fields[0].allowOn = { billignAddressService: '有' };
                return [serviceRow, listRow];
              })(),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('billingAddress', 'btoBBillignAddressRegistrationDate'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = (val) => this.datePipe.transform(val);
                return row;
              })(),
            ],
          },
        ],
      };

      this.metadataRight = {
        groups: [
          {
            title: '担当',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'user.id',
                fieldType: 'user',
                label: '担当者',
                validationStyle: { required: true },
              }),
            ],
          },
          {
            title: '部署担当者情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('billingAddress', 'departmentName'),
                'dynamicData',
                'fill',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'contact'), 'dynamicData'),
            ],
          },
          {
            title: '請求書送付情報',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'howToSendInvoice'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('billingAddress', 'remarks'), 'dynamicData'),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onAddressSelect(address: any): void {
    this.model.dynamicData.address = { ...address };
    this.model.dynamicData.companyName = this.config.data.company.dynamicData.name;
  }

  onValidityChange(index: number, validity: boolean): void {
    this.validityArray[index] = validity;
    this.valid = this.validityArray.find((v) => !v) == null;
  }

  create(toBtoB?: any): void {
    if (!this.valid || this.dialog.inClose) {
      return;
    }
    if (this.model.user) {
      this.model.userId = this.model.user.id;
    }
    const emptyAddress = { zip: '', prefecture: null, address1: '', address2: '', address3: '' };
    this.model.dynamicData.address = this.model.dynamicData.address || emptyAddress;
    const emptyContact = { position: '', name: '', phoneticName: '', tel: '', fax: '', email: '', emailAvailable: null, remarks: '' };
    this.model.dynamicData.contact = this.model.dynamicData.contact || emptyContact;
    this.model.toBtoB = toBtoB === undefined ? false : true;
    this.close(this.model);
  }

  close(params?: any): void {
    this.dialog.close(params);
  }
}
