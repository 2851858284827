import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';

import { FavoriteJobListPageComponent } from './pages/favorite-job-list-page/favorite-job-list-page.component';
import { JobDetailPageComponent } from './pages/job-detail-page/job-detail-page.component';
import { JobListPageComponent } from './pages/job-list-page/job-list-page.component';

/**
 * Route定数
 */
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'list', component: JobListPageComponent },
      { path: 'search', component: JobListPageComponent },
      { path: 'add', component: JobListPageComponent },
      { path: 'favorites', component: FavoriteJobListPageComponent },
      { path: ':id', component: JobDetailPageComponent },
      { path: ':id/:tab', component: JobDetailPageComponent },
    ],
  },
];

/**
 * 契約ルーティング
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsRoutingModule {}
