import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { Component } from '@angular/core';

@Component({
  selector: 'ag-student-confirm-file-dialog',
  templateUrl: './student-confirm-file-dialog.component.html',
  styleUrls: ['./student-confirm-file-dialog.component.scss'],
})
export class StudentConfirmFileDialogComponent {
  constructor(public dialog: DialogRef, public config: DialogConfig<{ filename: string }>) {}

  close(reason?: 'resubmit' | 'confirm'): void {
    this.dialog.close(reason);
  }
}
