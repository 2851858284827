import { DashboardType } from '@agent-ds/shared/interfaces';
import { ProgressKpi } from '@agent-ds/shared/interfaces/dashboard';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-job-progress-dash',
  templateUrl: './job-progress-dash.component.html',
  styleUrls: ['./job-progress-dash.component.scss'],
})
export class JobProgressDashComponent implements OnInit, OnChanges {
  @Input() model: { thisYear: ProgressKpi; nextYear: ProgressKpi } = { thisYear: ProgressKpi.empty(), nextYear: ProgressKpi.empty() };
  @Input() dashboardType: DashboardType = DashboardType.RAPA;
  @Input() selectedTeamId: number = null;
  @Input() loginUserId: number;
  @Output() collapsedNextYear: EventEmitter<boolean> = new EventEmitter<boolean>();

  collapsed = false;
  fiscalYear: number = Date.fiscalYear();
  groupTitle = '-';
  isCA = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardType']) {
      switch (this.dashboardType) {
        case DashboardType.RAPA:
        case DashboardType.RA_Manager:
        case DashboardType.RA_Assistant:
          this.groupTitle = '求人進捗';
          break;
        case DashboardType.CA:
        case DashboardType.CA_Manager:
          this.groupTitle = '求職者進捗';
          break;
        default:
          this.groupTitle = '-';
      }
      this.isCA = this.dashboardType === DashboardType.CA || this.dashboardType === DashboardType.CA_Manager;
    }
  }

  getLinkParams(
    status: number,
    supportYear: number[],
  ): {
    status: number;
    supportYear?: number[];
    recruitYear?: number[];
    studentTeamId?: number;
    studentUserId?: number;
    enterpriseUserId?: number;
    enterpriseTeamId?: number;
  } {
    const res: {
      status: number;
      supportYear?: number[];
      recruitYear?: number[];
      studentTeamId?: number;
      studentUserId?: number;
      enterpriseUserId?: number;
      enterpriseTeamId?: number;
    } = {
      status: status,
    };

    switch (this.dashboardType) {
      case DashboardType.CA_Manager:
        res.studentTeamId = this.selectedTeamId;
        res.supportYear = supportYear;
        break;
      case DashboardType.CA:
        res.studentUserId = this.loginUserId;
        res.supportYear = supportYear;
        break;
      case DashboardType.RAPA:
        res.enterpriseUserId = this.loginUserId;
        res.recruitYear = supportYear;
        break;
      case DashboardType.RA_Manager:
        res.enterpriseTeamId = this.selectedTeamId;
        res.recruitYear = supportYear;
        break;
      case DashboardType.RA_Assistant:
        res.enterpriseUserId = this.loginUserId;
        res.recruitYear = supportYear;
        break;
      default:
      // not supported dasboard type
    }
    return res;
  }

  getLinkParamsWithDelayParam(params: any): any {
    return { ...params, isDelay: 1 };
  }
}
