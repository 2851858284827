import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { MapperInterceptor } from '@agent-ds/shared/interceptors/mapper.interceptor';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-batch-update-dialog',
  templateUrl: './batch-update-dialog.component.html',
  styleUrls: ['./batch-update-dialog.component.scss'],
})
export class BatchUpdateDialogComponent implements OnInit, OnDestroy {
  inClose = false;
  metadata: FormMeta = {
    groups: [
      {
        class: 'ou',
        rows: [
          {
            title: '項目',
            fields: [
              {
                type: 'dropdown',
                name: 'field',
                valueField: 'field',
                labelField: 'label',
                options: [
                  { fieldName: 'userId', label: '担当者' },
                  this.dynamicService.getDefinition('student', 'rank'),
                  this.dynamicService.getDefinition('student', 'registrationRoute'),
                  this.dynamicService.getDefinition('student', 'universityLevel'),
                  this.dynamicService.getDefinition('student', 'academicFieldType'),
                  this.dynamicService.getDefinition('student', 'registrationStatus'),
                  this.dynamicService.getDefinition('student', 'interviewStatus'),
                ]
                  .filter((field) => field)
                  .map((field) => ({ label: field.label, field: field.fieldName })),
                class: 'full tall',
              },
            ],
          },
          {
            title: '新しい値',
            fields: [
              ...this.dynamicService.getFormRows({ fieldType: 'user', fieldName: 'userId' })[0].fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'rank'), null, 'full tall')[0].fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRoute'), null, 'full tall')[0]
                .fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'universityLevel'), null, 'full tall')[0]
                .fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicFieldType'), null, 'full tall')[0]
                .fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationStatus'), null, 'full tall')[0]
                .fields,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewStatus'), null, 'full tall')[0]
                .fields,
            ],
          },
        ],
      },
    ],
  };

  model = {};

  public innerFieldSelected = '';
  public fieldValue;
  public finished = false;
  updatedCount = 0;

  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig<{ condition?: {}; studentIds?: [] }>,
    private dynamicService: DynamicFieldService,
    private studentService: StudentApiService,
  ) {}

  ngOnInit() {
    for (const field of this.metadata.groups[0].rows[1].fields) {
      field.showOn = { field: field.name };
    }
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => (this.finished ? this.close() : this.save()));
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  public get fieldSelected(): any {
    return this.innerFieldSelected;
  }

  public set fieldSelected(value: any) {
    this.fieldValue = undefined;
    this.innerFieldSelected = value;
  }

  updateChange(): void {
    this.fieldValue = this.model['field'];
    this.innerFieldSelected = this.model[this.fieldValue];
  }

  public save(): void {
    if (!this.fieldSelected || !this.fieldValue || this.inClose || this.dialog.inClose) {
      return;
    }
    this.inClose = true;
    const condition = this.config.data.condition ? MapperInterceptor.toSearchFormat(this.config.data.condition) : undefined;
    this.studentService.bulkUpdate(this.fieldValue, this.innerFieldSelected, this.config.data.studentIds, condition).subscribe(
      (result) => {
        this.updatedCount = result.id ? result.id.length : 0;
        this.inClose = false;
        this.finished = true;
      },
      () => (this.inClose = false),
    );
  }

  public close(): void {
    this.dialog.close();
  }
}
