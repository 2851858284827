import { PageFloaterComponent } from '@agent-ds/shared/components/page-floater/page-floater.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AuthGuard } from '@agent-ds/shared/guard';
import { CacheInterceptor } from '@agent-ds/shared/interceptors/cache.interceptor';
import { PageInfo } from '@agent-ds/shared/interfaces';
import { ToasterService } from '@agent-ds/shared/services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * 画面情報
   */
  pageInfo: PageInfo = null;
  requestCount = 0;
  guard = AuthGuard;

  constructor(public readonly toasterService: ToasterService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        PageFloaterComponent.closeAll();
        PopupControlComponent.closeAll();
      }
    });
    CacheInterceptor.requestCountChange.subscribe((count: number) => setTimeout(() => (this.requestCount = count), 0));
  }

  /**
   * onActivate
   * @param component 対象コンポーネント
   */
  onActivate(component): void {
    this.pageInfo = component && component.pageInfo ? component.pageInfo : null;
  }
}
