import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { TargetKpi } from '@agent-ds/shared/interfaces/dashboard';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-kpi-setup-dialog',
  templateUrl: './kpi-setup-dialog.component.html',
  styleUrls: ['./kpi-setup-dialog.component.scss'],
})
export class KpiSetupDialogComponent implements OnInit, OnDestroy {
  metadataLeft: FormMeta = { groups: [] };
  metadataRight: FormMeta = { groups: [] };
  updating = false;

  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  constructor(public dialog: DialogRef, public config: DialogConfig, public dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.config.data.model = this.config.data.model || TargetKpi.empty();

    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.update());
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataLeft = {
        groups: [
          {
            class: 'form__group--no-title-border',
            rows: [
              ...(this.config.data.dashboardType === DashboardType.CA || this.config.data.dashboardType === DashboardType.CA_Manager
                ? this.dynamicService.getFormRows(
                    {
                      fieldName: 'counselings',
                      fieldType: 'number',
                      displayType: 'number',
                      unit: '件',
                      label: 'カウ数',
                      validationStyle: { minValue: 0 },
                    },
                    null,
                    'half',
                  )
                : this.dynamicService.getFormRows(
                    {
                      fieldName: 'azs',
                      fieldType: 'number',
                      displayType: 'number',
                      unit: '件',
                      label: 'AZ数',
                      validationStyle: { minValue: 0 },
                    },
                    null,
                    'half',
                  )),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'offerSubmissions',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '打診数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'studentOks',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '本人OK数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'salesDocuments',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '営業書類提出数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'seminarSettings',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: 'セミナー設定数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'seminarCompletions',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: 'セミナー実施数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
            ],
          },
        ],
      };

      this.metadataRight = {
        groups: [
          {
            // class: 'form__group--no-title-border no-border-around no-background',
            class: 'form__group--no-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'firstInterviewSettings',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '１次面接設定数',
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'firstInterviewCompletions',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '１次面接実施数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'finalInterviewSettings',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '最終面接設定数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'finalInterviewCompletions',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '件',
                  label: '最終面接実施数',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'offers',
                  unit: '人',
                  fieldType: 'number',
                  displayType: 'number',
                  label: '内定数',
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'acceptances',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '人',
                  label: '決定人数(SN)',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'sales',
                  fieldType: 'number',
                  displayType: 'number',
                  unit: '万円',
                  label: '決定金額(SK)',
                  validationStyle: { minValue: 0 },
                },
                null,
                'half',
              ),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  close(): void {
    this.dialog.close();
  }

  update() {
    if (this.updating) {
      return;
    }
    let toSave = this.config.data.model;
    if (toSave.sales) {
      toSave = { ...toSave, sales: toSave.sales * 10000 };
    }
    this.config.data.updater(toSave).subscribe(
      (response: HttpResponse<void>) => {
        if (response.ok) {
          this.close();
        }
        this.updating = false;
      },
      () => {
        this.updating = false;
      },
    );

    this.updating = true;
  }
}
