import { SharedModule } from '@agent-ds/shared';
import { NgModule } from '@angular/core';
import { JOB_COMPONENTS, JOB_DIALOG_COMPONENTS } from './components';
import { JobDetailHeaderComponent } from './components/job-detail-header/job-detail-header.component';
import { JobSearchComponent } from './components/job-search/job-search.component';
import { JobsRoutingModule } from './jobs-routing.module';
import { JobDetailPageComponent } from './pages/job-detail-page/job-detail-page.component';
import { JOB_DETAIL_TABS } from './pages/job-detail-page/tabs';

@NgModule({
  declarations: [JOB_COMPONENTS, JobDetailPageComponent, JOB_DETAIL_TABS, JobDetailHeaderComponent, JOB_DIALOG_COMPONENTS],
  exports: [JobDetailPageComponent, JobSearchComponent, JobDetailHeaderComponent],
  imports: [SharedModule, JobsRoutingModule],
  providers: [],
  entryComponents: [JOB_DIALOG_COMPONENTS],
})
export class JobsModule {}
