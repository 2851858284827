import { StudentJobMatchingPageComponent } from '@agent-ds/matching/pages';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { StudentDetailConfig } from '@agent-ds/shared/components/mail-send/configs/student-detail-config';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ProfileImageComponent } from '@agent-ds/shared/components/profile-image/profile-image.component';
import { ProgressItemResponse, StudentDetail } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, StudentApiService } from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ag-student-detail-header',
  templateUrl: './student-detail-header.component.html',
  styleUrls: ['./student-detail-header.component.scss'],
})
export class StudentDetailHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(ProfileImageComponent, { static: false }) imgComponent: ProfileImageComponent;
  @ViewChild('msgContainer', { static: false }) msgContainerEl: ElementRef<HTMLSpanElement>;
  @Input() student: StudentDetail | null = null;
  @Input() readonly: boolean;
  @Input() progresses: ProgressItemResponse[] = [];

  private refreshButtonFlashedTimerId = 0;

  messageShown = false;
  messageEdit: boolean[];
  tempMessages: string[] = [];
  showPlus = false;
  isRefreshButtonFlashed = false;

  detailConfig = new StudentDetailConfig();
  sendConfig = new StudentSendConfig();

  constructor(
    private datePipe: SafeDatePipe,
    private studentService: StudentApiService,
    public readonly location: Location,
    public readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    // イベントを受け取ったときに、ボタンを点滅させる
    this.studentService.notifyConnectionErrorEvent.subscribe(() => {
      // すでに点滅表示用のタイマーが設定されている場合は一度クリアし、点滅解除
      if (this.refreshButtonFlashedTimerId) {
        window.clearTimeout(this.refreshButtonFlashedTimerId);
        this.isRefreshButtonFlashed = false;
      } else {
        // do nothing
      }

      // 点滅設定
      this.isRefreshButtonFlashed = true;
      this.refreshButtonFlashedTimerId = window.setTimeout(() => {
        this.isRefreshButtonFlashed = false;
      }, 10 * 1000);
    });
  }

  ngOnDestroy(): void {
    if (this.studentService.notifyConnectionErrorEvent) {
      this.studentService.notifyConnectionErrorEvent.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['student']) {
      if (
        this.imgComponent &&
        changes['student'].previousValue &&
        changes['student'].currentValue &&
        changes['student'].previousValue.id === changes['student'].currentValue.id
      ) {
        this.imgComponent.reload();
      }
      this.messageShown = false;
      this.messageEdit = [];
      this.tempMessages = [];
      if (this.msgContainerEl) {
        this.msgContainerEl.nativeElement.scrollTop = 0;
      }
    }
    if (changes['progresses']) {
      this.showPlus = this.progresses.find((p) => p.type === 1) != null;
    }
  }

  onMessageDelete(i: number): void {
    PopupControlComponent.instance.open({
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: '確定',
      confirmCallback: () => {
        return this.studentService
          .deleteNote(this.student.id, this.student.studentNotes[i].id)
          .pipe(tap(() => this.studentService.refreshEvent.emit()));
      },
      cancelText: 'キャンセル',
      title: '伝言の削除',
      height: '357px',
      width: '600px',
    });
  }

  onMessageEditDone(i: number, save = false, value: string): void {
    if (save) {
      this.student.studentNotes[i].note = value;
      if (this.student.studentNotes[i].id) {
        this.studentService
          .updateNote(this.student.id, this.student.studentNotes[i])
          .subscribe(() => this.studentService.refreshEvent.emit());
      } else {
        this.studentService.addNote(this.student.id, this.student.studentNotes[i]).subscribe(() => this.studentService.refreshEvent.emit());
      }
    } else if (!this.student.studentNotes[i].id) {
      this.student.studentNotes.splice(i, 1);
    }
    this.messageEdit[i] = false;
  }

  onMessageAdd(): void {
    const baseMessage = this.datePipe.transform(Date.now(), 'yyyy/MM/dd (E) ');
    this.student.studentNotes.unshift({ note: baseMessage });
    this.tempMessages.unshift(baseMessage);
    this.messageEdit.unshift(true);
    this.messageShown = true;
  }

  get loggedInText(): string {
    if (this.student.mypageLoggedInAt) {
      const diff = Math.trunc((Date.now() - Date.parse(this.student.mypageLoggedInAt.toString())) / 86400000);
      return diff < 1 ? '今日' : diff + '日前';
    }
    return '-';
  }

  public get editMode(): boolean {
    return this.messageEdit.reduce((prev, curr) => prev || curr, false);
  }

  openMailSend(config: MailSendConfig = this.detailConfig): void {
    if (this.authService.authInfo.zone !== 'zone3') {
      PopupControlComponent.instance.open({
        title: null,
        content: 'ZONE3専用です。',
        confirmText: 'OK',
        confirmCallback: () => false,
      });
      return;
    }
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      student: {
        id: this.student.id,
        frontId: this.student.frontId,
        firstName: this.student.dynamicData.firstName,
        lastName: this.student.dynamicData.lastName,
        age: null,
        prefecture: null,
        schoolName: null,
        schoolDepartmentName: null,
        studentUser: this.student.studentUsers,
        emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
        emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
        emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
        emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
      },
    });
    MailSendFlowComponent.instance.start();
  }

  openMatching(): void {
    StudentJobMatchingPageComponent.instance.referenceId = this.student.id;
    StudentJobMatchingPageComponent.instance.open();
  }

  refresh(): void {
    this.studentService.refreshEvent.emit();
  }
}
