import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BatchResult, SortOrder, TableConfig, TableField } from './table-interface';

@Component({
  selector: 'ag-page-scroll-table',
  templateUrl: './page-scroll-table.component.html',
  styleUrls: ['./page-scroll-table.component.scss'],
})
export class PageScrollTableComponent implements OnInit, OnChanges {
  @Input() pageSize? = 20;
  @Input() content?: any[] = [];
  @Input() scrollerClass = 'vertical-scroll-only';
  @Input() supplier?: (page: number, pageSize: number, sort: string, sortOrder: SortOrder) => Observable<BatchResult>;
  @Output() readonly itemSelected = new EventEmitter<any>();
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();

  private scrollableElement: HTMLDivElement;

  private page = 0;
  private innerLoading = false;
  private innerTotalSize = 0;
  private innerItemSize: number = null;
  private innerTableConfig: TableConfig;
  private selectedItem: any = null;
  allChecked: boolean;

  public readonly checkedItems = [];
  public readonly sorting = { field: null, order: SortOrder.DESCENDING, defaultField: null, defaultOrder: SortOrder.DESCENDING };
  private readonly fieldsByName: { [key: string]: TableField } = {};

  constructor(private datePipe: SafeDatePipe) {}

  public get loading(): boolean {
    return this.innerLoading;
  }
  public get itemSize(): number {
    return this.innerItemSize;
  }
  public get totalSize(): number {
    return this.innerTotalSize;
  }
  public get currentSize(): number {
    return this.content ? this.content.length : 0;
  }
  public get hasCheckedItems(): boolean {
    return !!this.checkedItems.length;
  }

  public get tableConfig(): TableConfig {
    return this.innerTableConfig;
  }
  @Input()
  public set tableConfig(config: TableConfig) {
    this.innerTableConfig = config;
    if (this.innerTableConfig) {
      if (((this.tableConfig.body || {}).style || {})['height']) {
        this.innerItemSize = parseFloat(this.tableConfig.body.style['height'].toString());
      } else if (((this.tableConfig.body || {}).style || {})['min-height']) {
        this.innerItemSize = parseFloat(this.tableConfig.body.style['min-height'].toString());
      }
      this.tableConfig.head.config.forEach((head) =>
        head.columns.forEach((item) => {
          item.allStyle = { ...(item.style || {}), ...(item.bodyStyle || {}) };
          item.fields.forEach((field) => {
            if (field.defaultSort) {
              this.sorting.defaultField = this.sorting.field = field.name;
              this.sorting.defaultOrder = this.sorting.order = field.defaultSort;
            }
            this.fieldsByName[field.name] = field;
          });
        }),
      );
      if (!this.supplier) {
        this.innerSort();
      }
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['supplier'] && changes['supplier'].previousValue) {
      this.reset(false, false, !!changes['content'].currentValue);
    }
    if (changes['content'] && !this.supplier) {
      this.innerTotalSize = this.content ? this.content.length : 0;
      this.checkedItems.length = 0;
      this.innerSort();
    }
  }

  dynamicStyle(dynamicStyles: { [styleName: string]: (data: any) => string }, data: any): { [styleName: string]: string } {
    if (dynamicStyles && Object.keys(dynamicStyles).length) {
      const executedStyles = {};
      Object.keys(dynamicStyles).forEach((key) => {
        if (dynamicStyles.hasOwnProperty(key)) {
          executedStyles[key] = dynamicStyles[key](data);
        }
      });
      return executedStyles;
    }
    return null;
  }

  defaultFormatter(data: any, fullPath: string): string {
    if (data && fullPath.includes('.')) {
      const nestedPaths = fullPath.split('.');
      let nestedData: any;
      nestedPaths.forEach((path) => {
        nestedData = nestedData ? { ...nestedData }[path] : { ...data }[path];
      });
      return nestedData as string;
    }
    if (data && data[fullPath] != null) {
      if (data[fullPath] instanceof Date || data[fullPath].getDate) {
        return this.datePipe.transform(data[fullPath], 'yyyy/MM/dd');
      }
      return data[fullPath].toString();
    }
    return '';
  }

  onScroll(event: any): void {
    if (event) {
      this.scrollableElement = event.srcElement;
    }
    if (
      this.supplier &&
      this.content.length < this.totalSize &&
      !this.innerLoading &&
      event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95
    ) {
      this.next();
    }
  }

  onSortChange(fieldName: string): void {
    if (this.sorting.field === fieldName && this.sorting.order === SortOrder.ASCENDING) {
      this.sorting.order = SortOrder.DESCENDING;
    } else {
      this.sorting.field = fieldName;
      this.sorting.order = SortOrder.ASCENDING;
    }
    if (this.supplier) {
      this.reset(true);
      this.next();
    } else if (this.content.length) {
      this.innerSort();
    }
  }

  private innerSort(): void {
    if (this.sorting.field && this.content) {
      const field = this.fieldsByName[this.sorting.field];
      this.content.sort((a: any, b: any) => {
        const aVal = field.formatter ? field.formatter(a) : a[this.sorting.field] || a;
        const bVal = field.formatter ? field.formatter(b) : b[this.sorting.field] || b;
        if ((aVal == null && bVal != null) || aVal < bVal) {
          return this.sorting.order === SortOrder.ASCENDING ? -1 : 1;
        } else if ((bVal == null && aVal != null) || aVal > bVal) {
          return this.sorting.order === SortOrder.ASCENDING ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }

  onItemSelect(item: any): void {
    this.itemSelected.emit((this.selectedItem = item));
  }

  onRowClick(row: any, event: MouseEvent, data: any): void {
    if (row && row.clickAction) {
      row.clickAction(event, data);
    }
  }

  onFieldClick(field: TableField, event: MouseEvent, data: any): void {
    if (field.clickAction) {
      field.clickAction(event, data);
    }
  }

  onItemCheck(item: any, event: MouseEvent): void {
    event.stopPropagation();
    const idx = this.checkedItems.indexOf(item);
    if (idx > -1) {
      this.allChecked = false;
      this.checkedItems.splice(idx, 1);
    } else {
      this.checkedItems.push(item);
    }
    this.itemChecked.emit({ item: item, checked: idx < 0 });
  }

  next(): void {
    if (this.supplier && !this.innerLoading) {
      this.innerLoading = true;
      this.supplier(this.page++, this.pageSize, this.sorting.field, this.sorting.order)
        .pipe(first())
        .subscribe(
          (batch) => {
            this.innerLoading = false;
            if (!this.supplier) {
              return; // changed to static content while the request was ongoing
            }
            this.innerTotalSize = batch.totalSize || 0;
            this.content = this.content != null ? this.content.concat(batch.result) : [...batch.result];
            if (this.allChecked) {
              this.checkedItems.push(...batch.result);
            }
          },
          () => (this.innerLoading = false),
        );
    }
  }

  reset(dataOnly?: boolean, noScroll?: boolean, keepContent?: boolean): void {
    if (!keepContent && this.content) {
      this.content.length = 0;
    }
    this.checkedItems.length = 0;
    this.allChecked = false;
    if (!this.innerLoading) {
      this.page = 0;
    }
    if (!noScroll && this.scrollableElement) {
      this.scrollableElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (!dataOnly) {
      this.innerTotalSize = 0;
      this.sorting.field = this.sorting.defaultField || null;
      this.sorting.order = this.sorting.defaultOrder || SortOrder.DESCENDING;
    }
  }

  checkAll(uncheck = false): void {
    this.allChecked = !uncheck;
    if (this.allChecked) {
      this.checkedItems.length = 0;
      this.checkedItems.push(...this.content);
      this.itemChecked.emit({ checked: true });
    } else {
      this.checkedItems.length = 0;
      this.itemChecked.emit({ checked: false });
    }
  }
}
