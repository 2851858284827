import { ExtendedProgressStatusCode, ProgressStatusCode } from '@agent-ds/shared/enums';
import { MapperInterceptor } from '@agent-ds/shared/interceptors/mapper.interceptor';
import {
  DataFile,
  MailBulkResponse,
  MailPrefill,
  MailReplacedTemplateRequest,
  MailSendResponse,
  MailSignature,
  MailTemplateResponse,
  ProgressCheckResponse,
} from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import {
  AuthService,
  CompanyApiService,
  DynamicFieldService,
  FileApiService,
  JobApiService,
  MailApiService,
  ProgressApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { PopupControlComponent } from '../popup-control/popup-control.component';
import { MailPopupType, MailSendConfig } from './config';

@Component({
  selector: 'ag-mail-send',
  templateUrl: './mail-send.component.html',
  styleUrls: ['./mail-send.component.scss'],
})
export class MailSendComponent implements OnInit {
  @ViewChild('fileSelect', { static: false }) fileSelectTemplate: TemplateRef<any>;
  @ViewChild('studentFileSelect', { static: false }) studentFileSelectTemplate: TemplateRef<any>;
  @ViewChild('progressError', { static: false }) progressErrorTemplate: TemplateRef<any>;
  @ViewChild('sendResult', { static: true }) sendResultTemplate: TemplateRef<any>;
  @Output() showPopup = new EventEmitter<MailPopupType>();
  @Output() closed = new EventEmitter();
  @Output() sent = new EventEmitter<any>();
  @Output() shouldOpen = new EventEmitter();

  private sendConfig: MailSendConfig;
  private prefill: MailPrefill;

  meta: FormMeta = { groups: [] };
  model: any = { to: [] };
  files: DataFile[] = [];
  enterpriseFiles: { [key: number]: DataFile[] } = {};
  studentFiles: { [key: number]: DataFile[] } = {};
  validity = false;
  inConfirm = false;
  confirmDisabled = false;

  enterpriseFileIds = [];
  studentFileIds = [];
  enterpriseIdForFile: number;
  studentIdForFiles: number;
  progressErrorInfo: ProgressCheckResponse[] = [];
  sendResultInfo: MailSendResponse | MailBulkResponse | any;
  readonly progressStatus = ProgressStatusCode;
  readonly extendedProgressStatus = ExtendedProgressStatusCode;

  enterpriseInfoById: { [key: number]: string } = {};
  studentInfoById: { [key: number]: string } = {};
  userIdForSignatures: number;
  userSignature: MailSignature[] = [];
  selectedSignature: MailSignature;

  constructor(
    public readonly dynamicService: DynamicFieldService,
    public readonly mailApiService: MailApiService,
    public readonly authService: AuthService,
    public readonly fileService: FileApiService,
    public readonly jobApiService: JobApiService,
    public readonly progressApiService: ProgressApiService,
    public readonly companyApiService: CompanyApiService,
    public readonly userService: UserApiService,
    private datePipe: SafeDatePipe,
  ) {}

  ngOnInit() {}

  public set config(sendConfig: MailSendConfig) {
    this.close(true);
    if (this.sendConfig !== sendConfig) {
      if (this.sendConfig) {
        this.sendConfig.detach();
      }
      this.sendConfig = sendConfig;
      this.sendConfig.attach(this);
      this.sendConfig.initForm();
    }
  }

  public get config(): MailSendConfig {
    return this.sendConfig;
  }

  onValidation(valid: boolean): void {
    this.validity = valid;
  }

  reset(prefill?: MailPrefill): void {
    this.prefill = prefill;
    this.model = {
      studentIds: [],
      enterpriseIds: [],
      ...this.model,
      studentFileIds: [],
      enterpriseFileIds: [],
      cc: [],
      cc_single: [],
      bcc: [],
      to: [],
      from: this.authService.loginUser ? this.authService.loginUser.id : null,
      subject: getValueFromObject(this.prefill, 'subject'),
      body: getValueFromObject(this.prefill, 'body'),
      signature: null,
      signatureBody: null,
      dummyTemplateType: null,
      text: null,
    };
    this.enterpriseIdForFile = null;
    this.studentIdForFiles = null;
    this.enterpriseFiles = {};
    this.studentFiles = {};
    this.enterpriseFileIds = [];
    this.studentFileIds = [];
    this.progressErrorInfo = [];
    this.sendResultInfo = null;
    this.enterpriseInfoById = {};
    this.studentInfoById = {};

    if (getValueFromObject(this.authService.loginUser, 'id') !== this.userIdForSignatures) {
      this.userIdForSignatures = null;
    }

    if (this.sendConfig) {
      if (this.sendConfig.getTemplateId()) {
        // idを指定して、該当するテンプレートを取得する
        const templateId: number = this.sendConfig.getTemplateId() || 0;
        this.mailApiService.getMailTemplate(templateId).subscribe((res: MailTemplateResponse) => {
          this.model.dummyTemplateType = res;

          if (this.model.dummyTemplateType) {
            if (this.model.dummyTemplateType.mailTemplateTypeId === 9) {
              this.userService.getAll().subscribe((users) => {
                this.mailApiService
                  .replaceTemplate(9, {
                    from: users.find((u) => u.id === this.model.from).email,
                    to: this.model.to,
                    subject: this.model.dummyTemplateType.subject,
                    text: this.model.dummyTemplateType.body,
                    studentId: this.model.studentId,
                    jobId: this.model.jobId,
                  })
                  .subscribe((replaced) => {
                    this.model.body = replaced.body;
                    this.model.subject = replaced.subject;
                  });
              });
            } else {
              this.model.body = this.model.dummyTemplateType.body;
              this.model.subject = this.model.dummyTemplateType.subject;
            }
          }
        });
      }
    }
    this.inConfirm = false;
  }

  public update(): void {
    this.model.templateTypeIds = this.sendConfig.getTemplateTypeId();
    this.sendConfig.update();
  }

  confirm(sendAfter = false): void {
    this.confirmDisabled = false;
    this.model.text = this.model.signatureBody ? this.model.body + '\n' + this.model.signatureBody : this.model.body;
    const templateTypeId = this.model.dummyTemplateType
      ? this.model.dummyTemplateType.mailTemplateTypeId
      : this.sendConfig.getTemplateTypeId().length === 1
      ? this.sendConfig.getTemplateTypeId()[0]
      : undefined;
    if (templateTypeId) {
      this.userService.getAll().subscribe((users) => {
        const templateObj: MailReplacedTemplateRequest = {
          from: users.find((u) => u.id === this.model.from).email,
          to: this.model.to,
          subject: this.model.subject,
          text: this.model.text,
        };
        if (this.model.students && this.model.students.length === 1) {
          templateObj['studentId'] = this.model.students[0].id;
        }
        if (this.model.studentId) {
          templateObj['studentId'] = this.model.studentId;
        }
        if (this.model.jobs && this.model.jobs.length) {
          if (this.model.jobs.length === 1) {
            templateObj['jobId'] = this.model.jobs[0].id || this.model.jobId;
          }
          templateObj['jobIds'] = this.model.jobs.map((j) => j.id);
        } else if (this.model.jobId) {
          templateObj['jobId'] = this.model.jobId;
        }
        if (this.model.departmentId) {
          templateObj['enterpriseDepartmentId'] = this.model.departmentId;
        } else if (this.model.department) {
          templateObj['enterpriseDepartmentId'] = this.model.department.id;
        }
        if (this.model.progressId) {
          templateObj['progressId'] = this.model.progressId;
        }
        if (
          this.model.progressStatus != null &&
          this.model.progressSeminarType != null &&
          this.model.progressSeminarAt != null &&
          (this.model.progressStatus === 70 || this.model.progressStatus === 100)
        ) {
          switch (this.model.progressSeminarType) {
            case 1:
              templateObj['additionalReplacements'] = [
                {
                  label: '面接日時',
                  value: this.datePipe.transform(this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                },
              ];
              break;
            case 99:
              templateObj['additionalReplacements'] = [
                {
                  label: '面接日時',
                  value: this.datePipe.transform(this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                },
                {
                  label: '選考ステータス',
                  value: '最終前面接',
                },
              ];
              break;
            case 100:
              templateObj['additionalReplacements'] = [
                {
                  label: '面接日時',
                  value: this.datePipe.transform(this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                },
                {
                  label: '選考ステータス',
                  value: '最終面接',
                },
              ];
              break;
            default:
              break;
          }
        }
        this.confirmDisabled = true;
        this.mailApiService.replaceTemplate(templateTypeId, templateObj).subscribe((res) => {
          this.model.subject = res.subject;
          this.model.text = res.body;
          this.confirmDisabled = false;
          if (sendAfter) {
            this.save();
          }
        });
      });
    } else if (sendAfter) {
      this.save();
    }
    this.inConfirm = true;
    this.sendConfig.initForm();
  }

  cancel(): void {
    this.inConfirm = false;
    this.confirmDisabled = false;
    this.sendConfig.initForm();
  }

  save(): void {
    this.confirmDisabled = true;
    this.userService.getAll().subscribe((users) => {
      const toSave = {
        userId: this.authService.loginUser.id,
        from: users.find((u) => u.id === this.model.from).email,
        to: this.model.to.removeSame(),
        cc: [
          ...this.model.cc_single,
          ...(this.model.cc || []).map((id) => (users.find((u) => u.id === id) || { email: null }).email).filter((v) => v),
        ].removeSame(),
        bcc: (this.model.bcc || []).map((id) => users.find((u) => u.id === id).email).removeSame(),
        enterpriseFileIds: this.model.enterpriseFileIds,
        studentFileIds: this.model.studentFileIds,
        subject: this.model.subject,
        text: this.model.text,
        templateTypeId: this.model.dummyTemplateType
          ? this.model.dummyTemplateType.mailTemplateTypeId
          : this.sendConfig.getTemplateTypeId().length === 1
          ? this.sendConfig.getTemplateTypeId()[0]
          : undefined,
        condition: this.model.condition ? MapperInterceptor.toSearchFormat(this.model.condition) : undefined,
      };
      if (this.prefill) {
        toSave['inReplyTo'] = this.prefill.inReplyTo;
        toSave['references'] = this.prefill.references;
      }
      this.sendConfig.save(toSave);
    });
  }

  afterSend(result: MailSendResponse | MailBulkResponse | any): void {
    this.sent.emit(result);
    PopupControlComponent.subInstance.open({
      confirmText: '閉じる',
      content: this.sendResultTemplate,
      title: '送信',
      confirmCallback: () => false,
    });
    const subs = PopupControlComponent.subInstance.closed.subscribe(() => {
      this.close();
      subs.unsubscribe();
    });
  }

  close(dataOnly = false): void {
    this.model.enterpriseId = null;
    this.model.students = null;
    this.model.studentIds = [];
    this.model.studentId = null;
    this.model.jobs = [];
    this.model.jobId = null;
    this.model.enterpriseIds = [];
    this.model.enterprises = [];
    this.model.department = null;
    this.model.departmentId = null;
    this.model.studentFileIds = [];
    this.model.enterpriseFileIds = [];
    this.model.condition = null;
    if (!dataOnly) {
      this.closed.emit();
    }
  }
}
