import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ag-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  serverError = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.serverError = this.router.url.endsWith('500');
  }
}
