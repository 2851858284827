import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { Company, EnterpriseDepartment, EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { removeEmptyObjects } from '@agent-ds/shared/util/util';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ag-company-department-create-dialog',
  templateUrl: './company-department-create-dialog.component.html',
  styleUrls: ['./company-department-create-dialog.component.scss'],
})
export class CompanyDepartmentCreateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('deptSelect', { static: true }) deptSelectorTemplate: TemplateRef<any>;
  model: any = {};
  modelResponsible = {
    raIds: [],
    paIds: [],
    others: [],
  };
  departmentDynamicList = [];

  metadataLeft: FormMeta;
  metadataResponsible: FormMeta;
  metadataContactInformation: FormMeta[] = [];
  private validityArray = [true, true, true];
  valid = false;
  confirmSubscription: Subscription;
  private fieldSubscription: Subscription;

  readonly CONTACT_LIMIT = 5;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig<{ department: EnterpriseDepartment; company: Company; departmentList: EnterpriseDepartment[] }>,
    private dynamicService: DynamicFieldService,
    private masterApiService: MasterApiService,
  ) {}

  ngOnInit() {
    if (this.config.data.department.dynamicData) {
      this.config.data.department.dynamicData = removeEmptyObjects(this.config.data.department.dynamicData);
    }
    this.model = this.config.data.department.dynamicData
      ? {
          ...this.config.data.department.dynamicData,
          address: this.config.data.department.dynamicData.address ? { ...this.config.data.department.dynamicData.address } : null,
          contact1: this.config.data.department.dynamicData.contact1 ? { ...this.config.data.department.dynamicData.contact1 } : null,
          contact2: this.config.data.department.dynamicData.contact2 ? { ...this.config.data.department.dynamicData.contact2 } : null,
          contact3: this.config.data.department.dynamicData.contact3 ? { ...this.config.data.department.dynamicData.contact3 } : null,
          contact4: this.config.data.department.dynamicData.contact4 ? { ...this.config.data.department.dynamicData.contact4 } : null,
          contact5: this.config.data.department.dynamicData.contact5 ? { ...this.config.data.department.dynamicData.contact5 } : null,
        }
      : {};
    this.validityArray = this.config.data.department.dynamicData ? [true, true, true] : [false, true, true];
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataContactInformation.length = 0;
      const tmpArr = [this.model.contact1, this.model.contact2, this.model.contact3, this.model.contact4, this.model.contact5];
      tmpArr
        .filter((c: any, index: number) => {
          this.model['contact' + (index + 1)] = null;
          return c;
        })
        .forEach((item: any, index: number) => {
          this.model['contact' + (index + 1)] = item;
          this.addContactInfoGroup();
        });

      this.departmentDynamicList = [
        this.config.data.company.dynamicData,
        ...this.config.data.departmentList.map((dep: EnterpriseDepartment) => dep.dynamicData),
      ];

      if (this.config.data.department.enterpriseDepartmentUsers) {
        this.config.data.department.enterpriseDepartmentUsers.forEach((user) => {
          switch (user.type) {
            case EnterpriseDepartmentUserType.RA:
              this.modelResponsible.raIds.push(user.userId);
              break;
            case EnterpriseDepartmentUserType.PA:
              this.modelResponsible.paIds.push(user.userId);
              break;
            default:
              this.modelResponsible.others.push(user.userId);
          }
        });
      }

      this.metadataLeft = {
        groups: [
          {
            title: '企業情報',
            class: 'form__group--red-title-border',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('enterprise', 'name'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].name = 'company.name';
                row.fields[0].type = 'label';
                row.fields[0].supplier = () => this.config.data.company.dynamicData.name;
                return row;
              })(),
              (() => {
                const row = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('enterprise', 'industry'),
                  displayStyle: null,
                  validationStyle: null,
                })[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = () =>
                  this.masterApiService
                    .getFlattenedIndustryTypes()
                    .pipe(
                      map(
                        (industries) =>
                          (industries.find((ind) => ind.code === this.config.data.company.dynamicData.industry) || { name: '' }).name,
                      ),
                    );
                return row;
              })(),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('department', 'name'), null, 'fill'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('department', 'status')),
            ],
          },
          {
            title: '部署住所情報',
            class: 'form__group--red-title-border',
            injectToHeader: this.deptSelectorTemplate,
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('department', 'address')),
          },
          {
            title: '備考',
            class: 'form__group--red-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('department', 'remarks')),
          },
        ],
      };

      this.metadataResponsible = {
        groups: [
          {
            title: '担当',
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'raIds',
                fieldType: 'multi-user',
                label: 'RA',
                validationStyle: { max: 5 },
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'paIds',
                fieldType: 'multi-user',
                label: 'PA',
                validationStyle: { max: 5 },
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'others',
                fieldType: 'multi-user',
                label: 'アシスタント',
                validationStyle: { max: 10 },
              }),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onAddressSelect(address: any): void {
    this.model.address = { ...address };
  }

  onValidityChange(index: number, validity: boolean): void {
    this.validityArray[index] = validity;
    this.valid = this.validityArray.find((v) => !v) == null;
  }

  create(): void {
    if (!this.valid || this.dialog.inClose) {
      return;
    }
    this.config.data.department.dynamicData = { ...this.config.data.department.dynamicData, ...this.model };
    const users = [
      ...this.modelResponsible.raIds.map((id) => ({ type: EnterpriseDepartmentUserType.RA, id: id })),
      ...this.modelResponsible.paIds.map((id) => ({ type: EnterpriseDepartmentUserType.PA, id: id })),
      ...this.modelResponsible.others.map((id) => ({ type: EnterpriseDepartmentUserType.アシ, id: id })),
    ];
    const emptyAddress = { zip: '', prefecture: null, address1: '', address2: '', address3: '' };
    this.config.data.department.dynamicData.address = this.config.data.department.dynamicData.address || emptyAddress;
    const emptyContact = { position: '', name: '', phoneticName: '', tel: '', fax: '', email: '', emailAvailable: null, remarks: '' };
    this.config.data.department.dynamicData.contact1 = this.config.data.department.dynamicData.contact1 || emptyContact;
    this.config.data.department.dynamicData.contact2 = this.config.data.department.dynamicData.contact2 || emptyContact;
    this.config.data.department.dynamicData.contact3 = this.config.data.department.dynamicData.contact3 || emptyContact;
    this.config.data.department.dynamicData.contact4 = this.config.data.department.dynamicData.contact4 || emptyContact;
    this.config.data.department.dynamicData.contact5 = this.config.data.department.dynamicData.contact5 || emptyContact;
    this.close({ dynamicData: this.config.data.department.dynamicData, users: users });
  }

  close(params?: any): void {
    this.dialog.close(params);
  }

  addContactInfo(): void {
    if (this.metadataContactInformation.length < this.CONTACT_LIMIT) {
      this.addContactInfoGroup();
      this.model['contact' + (this.metadataContactInformation.length + 1)] = {};
    }
  }

  removeContactInfo(index: number): void {
    this.model['contact' + (index + 1)] = null;
    for (let i = index + 1; i < this.metadataContactInformation.length; i++) {
      this.model['contact' + i] = this.model['contact' + (i + 1)];
      this.model['contact' + (i + 1)] = null;
    }
    const count = this.metadataContactInformation.length - 1;
    this.metadataContactInformation.length = 0;
    for (let i = 0; i < count; i++) {
      this.addContactInfoGroup();
    }
  }

  private addContactInfoGroup(): void {
    this.metadataContactInformation.push({
      groups: [
        {
          class: 'form__group--red-title-border',
          rows: this.dynamicService.getFormRows(
            this.dynamicService.getDefinition('department', 'contact' + (this.metadataContactInformation.length + 1)),
          ),
        },
      ],
    });
  }
}
