import {
  BulkRecommendationMailSendRequest,
  Mail,
  MailAttachment,
  MailBulkResponse,
  MailPlaceholderResponse,
  MailReplacedTemplateRequest,
  MailSendResponse,
  MailSignature,
  MailTemplateNameResponse,
  MailTemplateResponse,
  MailTemplateTypeResponse,
  MailTemplateUpdateRequest,
  SalesNotificationMailSendRequest,
  StudentMailBulkSendRequest,
} from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class MailApiService extends ApiService {
  private mailTemplates: Observable<MailTemplateResponse[]>;
  private mailTemplatePlaceholders: Observable<MailPlaceholderResponse[]>;
  private mailTemplateTypes: Observable<MailTemplateTypeResponse[]>;

  constructor(private http: HttpClient, private authService: AuthService) {
    super();
    this.authService.isLogined().subscribe((logined) => {
      if (logined) {
        this.mailTemplates = this.mailTemplatePlaceholders = this.mailTemplateTypes = null;
      }
    });
  }

  getMails(
    model: 'students' | 'enterprise',
    id: number,
    from: number = 0,
    size: number = 1000,
    sort: string = 'timestamp',
    order: string = 'desc',
    keyword?: string,
  ): Observable<{ total: number; mails: Mail[] }> {
    return this.http.get<{ total: number; mails: Mail[] }>(`/api/mail/${model}/${id}`, {
      params: { from: from, size: size, sort: sort, order: order, keyword: keyword || '' } as any,
    });
  }

  getAttachmentInfo(id: string): Observable<MailAttachment[]> {
    return this.http.get<MailAttachment[]>(`/api/mail/${id}/attachment/info`);
  }

  getMailTemplateTypes(): Observable<MailTemplateTypeResponse[]> {
    if (!this.mailTemplateTypes) {
      this.mailTemplateTypes = this.http.get<MailTemplateTypeResponse[]>('/api/mail/template/types').pipe(shareReplay(1));
    }
    return this.mailTemplateTypes;
  }

  getMailTemplatePlaceholder(): Observable<MailPlaceholderResponse[]> {
    if (!this.mailTemplatePlaceholders) {
      this.mailTemplatePlaceholders = this.http.get<MailPlaceholderResponse[]>('/api/mail/template/placeholder').pipe(shareReplay(1));
    }
    return this.mailTemplatePlaceholders;
  }

  getMailTemplates(): Observable<MailTemplateResponse[]> {
    if (!this.mailTemplates) {
      this.mailTemplates = this.http.get<MailTemplateResponse[]>('/api/mail/template').pipe(shareReplay(1));
    }
    return this.mailTemplates;
  }

  getMailTemplateNames(): Observable<MailTemplateNameResponse[]> {
    return this.http.get<MailTemplateNameResponse[]>(`/api/mail/template/names`).pipe(shareReplay(1));
  }

  getMailTemplate(id: number): Observable<MailTemplateResponse> {
    return this.http.get<MailTemplateResponse>(`/api/mail/template/id/${id}`).pipe(shareReplay(1));
  }

  addMailTemplate(template: MailTemplateUpdateRequest): Observable<any> {
    return this.http.post('/api/mail/template', template).pipe(tap(() => (this.mailTemplates = null)));
  }

  updateMailTemplate(id: number, template: MailTemplateUpdateRequest): Observable<any> {
    return this.http.put(`/api/mail/template/${id}`, template).pipe(tap(() => (this.mailTemplates = null)));
  }

  sendSalesNotification(request: SalesNotificationMailSendRequest): Observable<any> {
    return this.http.post('/api/mail/sales-notification/send', request).pipe(tap(() => this.refreshEvent.emit()));
  }

  downloadAttachment(id: string, partId: number): void {
    this.http
      .get(`/api/mail/${id}/attachment/${partId}/download`, { responseType: 'blob', observe: 'response' })
      .subscribe((res) => downloadResponse(res));
  }

  getSignature(model: 'user', id: number): Observable<MailSignature[]> {
    return this.http.get<MailSignature[]>(`/api/mail/signature/${model}/${id}`);
  }

  addSignature(model: 'user', id: number, signature: MailSignature): Observable<any> {
    return this.http.post(`/api/mail/signature/${model}/${id}`, signature);
  }

  updateSignature(id: number, signature: MailSignature): Observable<any> {
    return this.http.put(`/api/mail/signature/${id}`, signature);
  }

  deleteSignature(id: number): Observable<any> {
    return this.http.delete(`/api/mail/signature/${id}`);
  }

  sendStudentMail(id: number, mailObj: any): Observable<any> {
    return this.http.post(`/api/mail/students/${id}/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendStudentBulkMail(mailObj: StudentMailBulkSendRequest): Observable<MailBulkResponse> {
    return this.http.post<MailBulkResponse>('/api/mail/bulk/students/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendEnterpriseMail(enterpriseId: number, departmentId: number, mailObj: any): Observable<any> {
    return this.http
      .post(`/api/mail/enterprise/${enterpriseId}/department/${departmentId}/send`, mailObj)
      .pipe(tap(() => this.refreshEvent.emit()));
  }

  sendEnterpriseResumeMail(enterpriseId: number, departmentId: number, mailObj: any): Observable<any> {
    return this.http
      .post(`/api/mail/enterprise/${enterpriseId}/department/${departmentId}/resume/send`, mailObj)
      .pipe(tap(() => this.refreshEvent.emit()));
  }

  replaceTemplate(templateType: number, templateObj: MailReplacedTemplateRequest): Observable<Partial<MailTemplateResponse>> {
    return this.http.post<Partial<MailTemplateResponse>>(`/api/mail/template/replaced/${templateType}`, templateObj);
  }

  bulkRecommend(request: BulkRecommendationMailSendRequest): Observable<MailSendResponse> {
    return this.http.post<MailSendResponse>('/api/mail/bulk/recommendation/send', request).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendJobStudentsBulkMail(mailObj: any): Observable<MailBulkResponse> {
    return this.http.post<MailBulkResponse>('/api/mail/job/students/bulk/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendJobStudentMail(id: number, mailObj: any): Observable<any> {
    return this.http.post(`/api/mail/job/${id}/student/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendJobEnterpriseMail(jobId: number, enterpriseId: number, departmentId: number, mailObj: any): Observable<any> {
    return this.http
      .post(`/api/mail/job/${jobId}/enterprise/${enterpriseId}/department/${departmentId}/send`, mailObj)
      .pipe(tap(() => this.refreshEvent.emit()));
  }

  sendNgNotification(mailObj: any): Observable<any> {
    return this.http.post('/api/mail/ng-notification/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }

  sendSearchResult(mailObj: any): Observable<any> {
    return this.http.post(`/api/mail/bulk/students/search-result/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
  }
}
