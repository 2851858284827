import { TemplateRef } from '@angular/core';

export enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface TableConfig {
  class?: string;
  style?: { [key: string]: string | number };
  head: {
    class?: string;
    style?: { [key: string]: string | number };
    sticky?: boolean;
    hidden?: boolean;
    config: TableRow[];
  };
  body?: {
    class?: string;
    style?: { [key: string]: string | number };
    checkbox?: boolean;
    arrow?: boolean;
  };
}

export interface BatchResult {
  totalSize: number;
  result: any[];
}

export interface TableField {
  style?: { [key: string]: string | number };
  name: string;
  title?: string;
  sortable?: boolean;
  defaultSort?: SortOrder;
  cellTemplate?: TemplateRef<any>;
  formatter?: (data: any) => string;
  hidden?: boolean;
  clickAction?: (event: MouseEvent, data: any) => void;
}

export interface TableColumn {
  fields: TableField[];
  class?: string;
  style?: { [key: string]: string | number };
  bodyStyle?: { [key: string]: string | number };
  allStyle?: { [key: string]: string | number };
}

export interface TableRow {
  hidden?: boolean;
  columns: TableColumn[];
  dynamicStyle?: {
    [styleName: string]: (data: any) => string;
  };
  clickAction?: (event: MouseEvent, data: any) => void;
}
