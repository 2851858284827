import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS } from '@agent-ds/shared/constants';
import { Seminar } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-seminar-reservation-dialog',
  templateUrl: './seminar-reservation-dialog.component.html',
  styleUrls: ['./seminar-reservation-dialog.component.scss'],
})
export class SeminarReservationDialogComponent implements AfterViewInit, OnDestroy {
  model: any = {};
  metadata: FormMeta = { groups: [] };
  isValid = false;

  readonly AREAS = AREAS;
  areas: { id: number; name: string }[] = Object.keys(AREAS).map((key) => ({ id: +key, name: AREAS[key] }));
  seminars: Seminar[] = [];
  selectedSeminar: number;
  isSearchInProgress: boolean;
  private fieldSubScription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private counselingApiService: CounselingApiService,
  ) {}

  ngAfterViewInit() {
    this.model.area = this.areas[0].id;
    this.model.seminarReservationTime = ['10:00'];
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadata = {
        groups: [
          {
            class: 'form__group--no-title-border no-border-around no-background',
            rows: [
              ...(() => {
                const rows = this.dynamicService.getFormRows(
                  {
                    fieldName: 'area',
                    fieldType: 'list',
                    label: 'セミナーエリア',
                    displayType: 'radio',
                    validationStyle: { required: true, options: this.areas },
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].labelField = 'name';
                rows[0].fields[0].valueField = 'id';
                return rows;
              })(),
              ...this.dynamicService.getFormRows(
                {
                  ...this.dynamicService.getDefinition('student', 'seminarReservationDate'),
                  validationStyle: { required: true, minValue: new Date().toAsialDateString(), minDate: new Date().toAsialDateString() },
                  displayType: 'date',
                },
                null,
                'half',
              ),
              ...(() => {
                const rows = this.dynamicService.getFormRows(
                  {
                    ...this.dynamicService.getDefinition('student', 'seminarReservationTime'),
                    fieldType: 'multi-list',
                    displayType: 'checkbox',
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].validators.options = rows[0].fields[0].validators.options.filter(
                  (option) => !isNaN(Date.parse('1970-01-01T' + option)),
                );
                rows[0].fields[0].options = [...rows[0].fields[0].validators.options];
                return rows;
              })(),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'seminarAttributes',
                  fieldType: 'number',
                  label: 'セミナー属性',
                  displayType: 'number',
                  displayStyle: {
                    placeholder: 'セミナー属性番号',
                  },
                },
                null,
                'half',
              ),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
      this.fieldSubScription = null;
    }
  }

  onValidityChange(valid: boolean) {
    this.isValid = valid;
  }

  search(): void {
    if (!this.isValid || this.isSearchInProgress) {
      return;
    }
    this.isSearchInProgress = true;
    this.counselingApiService
      .searchSeminarsForStudent(this.config.data.studentId, {
        area: this.model.area,
        date: this.model.seminarReservationDate,
        times: this.model.seminarReservationTime,
        type: this.model.seminarAttributes,
      })
      .subscribe(
        (seminars) => {
          this.selectedSeminar = null;
          this.seminars = seminars.sort((a, b) => (new Date(a.seminarAt) > new Date(b.seminarAt) ? 1 : 0));
          this.isSearchInProgress = false;
        },
        () => (this.isSearchInProgress = false),
      );
  }

  reserve(): void {
    if (this.selectedSeminar == null) {
      return;
    }
    this.counselingApiService.addSeminarForStudent(this.config.data.studentId, this.selectedSeminar).subscribe(() => this.close(true));
  }

  close(result?: any): void {
    this.dialog.close(result);
  }
}
