export enum DashboardType {
  RAPA = 0,
  RA_Manager = 1,
  RA_Assistant = 2,
  CA = 3,
  CA_Manager = 4,
  INTAKE_CA = 5,
}

export interface User {
  id?: number;
  teamId: number;
  name: string;
  role: number;
  email: string;
  password?: string;
  status?: number;
  dashboardType?: DashboardType;
  managementTeams?: { id: number; name: string; kpiTarget: any }[]; // this seems to be under development and might change
}

export interface UserReference {
  id: number;
  userId: number;
  type: number;
}
