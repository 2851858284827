import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { JobListItem, JobUserCheckResponse } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, JobApiService, ProgressApiService } from '@agent-ds/shared/services';
import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-register-student-dialog',
  templateUrl: './register-student-dialog.component.html',
  styleUrls: ['./register-student-dialog.component.scss'],
})
export class RegisterStudentDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('jobError', { static: true }) jobErrorTemplate: TemplateRef<any>;
  model: any = {};
  metadata: FormMeta = { groups: [] };
  isValid = false;
  status = 0;
  registerResult: { successIds?: number[]; errorIds?: number[] } = {};
  contentHeight: any;
  contentWidth: any;
  progressCheckResponse: number[] = [];
  jobErrorInfo: JobUserCheckResponse[] = [];

  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private progressService: ProgressApiService,
    private jobService: JobApiService,
  ) {}

  ngAfterViewInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => (this.status === 2 ? this.close() : this.register()));
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadata = {
        groups: [
          {
            class: 'form__group--no-title-border no-border-around no-background',
            rows: [
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'student',
                  fieldType: 'student',
                  label: '対象求職者',
                  displayType: 'student',
                  validationStyle: { required: true },
                },
                null,
                'full',
              ),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  register(): void {
    if (!this.isValid || this.status === 1) {
      return;
    }
    this.status = 1;
    const jobIds = (this.config.data as JobListItem[]).map((item) => item.id);
    this.jobService.ckeckJobUsers(jobIds).subscribe((res) => {
      this.jobErrorInfo = [];
      res.forEach((r: JobUserCheckResponse) => {
        if (!r.jobUsers.length) {
          this.jobErrorInfo.push(r);
        }
      });
      if (this.jobErrorInfo.length) {
        PopupControlComponent.subInstance.open({
          title: '必要な情報が入力されていません',
          confirmText: '閉じる',
          content: this.jobErrorTemplate,
          confirmCallback: () => false,
        });
        return;
      } else {
        this.progressService.bulkRegisterStudentNoUpdate(this.model.student, jobIds).subscribe(
          (result) => {
            this.status = 2;
            this.registerResult = result;
          },
          () => (this.status = 0),
        );
      }
    });
  }

  close(): void {
    this.dialog.close();
  }

  onValidityChange(valid: boolean) {
    this.isValid = valid;
  }

  onValueChange(key: string): void {
    if (this.model.student) {
      this.progressService
        .checkProgress([this.model.student], (this.config.data as JobListItem[]).map((item) => item.id))
        .subscribe((res) => {
          this.progressCheckResponse = res.map((r) => r.jobId);
        });
    } else {
      this.progressCheckResponse.length = 0;
    }
  }
}
