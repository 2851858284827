import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { JobStudentMatchingPageComponent } from '@agent-ds/matching/pages';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { JobCompanyConfig } from '@agent-ds/shared/components/mail-send/configs/job-company-config';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { DashboardType, Job } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, JobApiService } from '@agent-ds/shared/services';
import { download, getFileNameFromContentDispositionHeader, getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'ag-job-detail-header',
  templateUrl: './job-detail-header.component.html',
  styleUrls: ['./job-detail-header.component.scss'],
})
export class JobDetailHeaderComponent implements OnInit, OnDestroy {
  @Input() job: Job;
  @Input() readonly: boolean;
  @Input() showOnlyMainInfo: boolean;

  readonly recommendationHeatClasses: { [key: string]: string } = {
    次月: 'badge--yellow-light',
    次月注力: 'badge--yellow-light',
    月内: 'badge--red-light-alt',
  };

  disablePdfButton = false;
  postingConfig = new JobPostingConfig();
  companyConfig = new JobCompanyConfig();
  private isIntakeCa = false;
  private loginSubscription: Subscription;

  constructor(
    private jobService: JobApiService,
    private companyService: CompanyApiService,
    public readonly location: Location,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  onCompanyLinkClick(id: number): void {
    CompanyDetailPageComponent.instance.referenceId = id;
    CompanyDetailPageComponent.instance.open();
  }

  copyJob(): void {
    this.jobService.copyJob(this.job.id).subscribe((result) => {
      this.jobService.refreshEvent.emit();
      JobDetailPageComponent.instance.referenceId = result.id;
    });
  }

  openMatching(): void {
    JobStudentMatchingPageComponent.instance.referenceId = this.job.id;
    JobStudentMatchingPageComponent.instance.open();
  }

  downloadPdf(): void {
    this.disablePdfButton = true;
    this.jobService.downloadPdf(this.job.id).subscribe(
      (resp) => {
        this.disablePdfButton = false;
        download(resp.body, decodeURIComponent(getFileNameFromContentDispositionHeader(resp)));
      },
      () => (this.disablePdfButton = false),
    );
  }

  openMail(config: MailSendConfig): void {
    MailSendFlowComponent.instance.config = config;
    const params = {
      jobs: [
        {
          id: this.job.id,
          frontId: this.job.frontId,
          position: getValueFromObject(this.job.dynamicData, 'position'),
          enterpriseName: this.job.enterpriseName,
          enterpriseId: this.job.enterpriseId,
          frontEnterpriseId: this.job.frontEnterpriseId,
          enterpriseDepartmentId: this.job.enterpriseDepartmentId,
          jobUsers: this.job.enterpriseDepartmentUsers,
        },
      ],
      department: null,
      enterprise: null,
    };
    if (config instanceof JobCompanyConfig) {
      forkJoin([this.companyService.getDetail(this.job.enterpriseId), this.companyService.getDepartments(this.job.enterpriseId)]).subscribe(
        ([company, departments]) => {
          params.department = departments.enterpriseDepartments.find((d) => d.id === this.job.enterpriseDepartmentId);
          params.enterprise = company;
          config.setParams(params);
          MailSendFlowComponent.instance.start();
        },
      );
    } else {
      config.setParams(params);
      MailSendFlowComponent.instance.start();
    }
  }
}
