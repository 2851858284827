import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ApprovalClassification, Company, Contract, EnterpriseDepartment } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { deepClone, getValueFromObject } from '@agent-ds/shared/util/util';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-company-contract-create-dialog',
  templateUrl: './company-contract-create-dialog.component.html',
  styleUrls: ['./company-contract-create-dialog.component.scss'],
})
export class CompanyContractCreateDialogComponent implements OnInit, OnDestroy {
  ApprovalClassification = ApprovalClassification;
  departmentDynamicList = [];
  private validityArray = [false, true, true];
  valid = false;
  confirmSubscription: Subscription;
  private fieldSubscription: Subscription;
  model: any;

  metadata1: FormMeta;
  metadata2: FormMeta;
  metadata3: FormMeta;

  get approvalClassification(): ApprovalClassification | null {
    return getValueFromObject(this.model, 'dynamicData.approvalClassification');
  }

  get actions(): { label: string; action: () => any; type: 'accent' | 'primary' }[] {
    switch (this.approvalClassification) {
      case ApprovalClassification.UNAPPROVED:
        return [
          { label: 'キャンセル', action: () => this.cancel(), type: 'accent' },
          { label: '更新', action: () => this.saveApplication(), type: 'primary' },
          { label: '申請', action: () => this.applyApplication(), type: 'primary' },
        ];
      case ApprovalClassification.WAITING_FOR_RE_APPLICATION:
        return [
          { label: 'キャンセル', action: () => this.cancel(), type: 'accent' },
          { label: '保存', action: () => this.saveApplication(), type: 'primary' },
          { label: '再申請', action: () => this.reApplyApplication(), type: 'primary' },
        ];
      case ApprovalClassification.APPROVAL_PENDING:
        return [
          { label: 'キャンセル', action: () => this.cancel(), type: 'accent' },
          { label: '申請取消', action: () => this.cancelApplication(), type: 'primary' },
          { label: '承認', action: () => this.approveApplication(), type: 'primary' },
          { label: '否認', action: () => this.denyApplication(), type: 'primary' },
        ];
      case ApprovalClassification.APPROVED:
        return [
          { label: 'キャンセル', action: () => this.cancel(), type: 'accent' },
          { label: '承認取消', action: () => this.denyApplication(), type: 'primary' },
        ];
      default:
        return [
          { label: 'キャンセル', action: () => this.cancel(), type: 'accent' },
          { label: '登録', action: () => this.saveApplication(), type: 'primary' },
        ];
    }
  }

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig<{ contract: Contract; company: Company; departmentList: EnterpriseDepartment[] }>,
    private dynamicService: DynamicFieldService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => {
      const action = this.actions.filter((a) => a.type === 'primary').last();
      if (action && this.valid && !this.dialog.inClose) {
        action.action();
      }
    });
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.model = this.config.data.contract.dynamicData
        ? {
            ...this.config.data.contract,
            dynamicData: {
              ...this.config.data.contract.dynamicData,
              address: { ...this.config.data.contract.dynamicData.address },
              contact: { ...this.config.data.contract.dynamicData.contact },
            },
          }
        : { dynamicData: {} };
      this.departmentDynamicList = this.config.data.departmentList.map((dep: EnterpriseDepartment) => dep.dynamicData);
      this.initForm();
    });
  }

  initForm(): void {
    const disabled =
      this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVED ||
      this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVAL_PENDING;
    this.metadata1 = {
      disabled: disabled,
      groups: [
        {
          title: '企業情報',
          class: 'form__group--red-title-border',
          rows: [
            {
              title: '企業ID',
              fields: [
                {
                  name: 'frontId',
                  type: 'label',
                  supplier: () => this.config.data.company.frontId,
                },
              ],
            },
            {
              title: '企業名',
              fields: [
                {
                  name: 'name',
                  type: 'label',
                  supplier: () => this.config.data.company.dynamicData.name,
                },
              ],
            },
          ],
        },
        {
          title: '契約情報',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(
              {
                fieldName: 'teamId',
                fieldType: 'team',
                label: '担当チーム',
                validationStyle: { required: true },
              },
              null,
              'fill',
            ),
            ...this.dynamicService.getFormRows(
              this.dynamicService.getDefinition('contract', 'contractClassification'),
              'dynamicData',
              'fill',
            ),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractCategory'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'signedDate'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'expireDate'), 'dynamicData'),
            ...(() => {
              const ret = [
                ...this.dynamicService.getFormRows(
                  this.dynamicService.getDefinition('contract', 'paymentCutoffDay'),
                  'dynamicData',
                  'fill',
                ),
                ...this.dynamicService.getFormRows(
                  this.dynamicService.getDefinition('contract', 'paymentDateMonth'),
                  'dynamicData',
                  'fill',
                ),
                ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'paymentDateDay'), 'dynamicData', 'fill'),
                ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'paymentSchedule'), 'dynamicData'),
              ];
              if (disabled) {
                ret.forEach((r) => (r.fields[0].type = 'label'));
              }
              return ret;
            })(),
          ],
        },
        {
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'status'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractType'), 'dynamicData'),
          ],
        },
      ],
    };

    this.metadata2 = {
      disabled: disabled,
      groups: [
        {
          title: '契約先情報',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'companyName'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'phoneticCompanyName'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'departmentName'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contact')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'address')),
          ],
        },
      ],
    };

    this.metadata3 = {
      disabled: disabled,
      groups: [
        {
          title: 'フィー',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee1Price'), null, 'quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee1Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee2Price'), null, 'quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee2Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee3Price'), null, 'quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee3Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee4Price'), null, 'quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee4Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee5Price'), null, 'quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee5Remarks'), null, 'fill'),
          ],
        },
        {
          title: '返金',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Period')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Percent')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Period')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Percent')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Period')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Percent')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Period')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Percent')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Remarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Period')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Percent')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Remarks'), null, 'fill'),
          ],
        },
        {
          title: '備考',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractRemarks')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'paymentRemarks')),
          ],
        },
      ],
    };
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onDepartmentSelect(dep: any): void {
    this.model.dynamicData.companyName = this.config.data.company.dynamicData.name;
    this.model.dynamicData.phoneticCompanyName = this.config.data.company.dynamicData.phoneticName;
    this.model.dynamicData.departmentName = dep.name;
    this.model.dynamicData.contact = { ...dep.contact1 };
    this.model.dynamicData.address = { ...dep.address };
  }

  onValidityChange(index: number, validity: boolean): void {
    this.validityArray[index] = validity;
    this.valid =
      this.validityArray.find((v) => !v) == null ||
      this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVED ||
      this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVAL_PENDING;
  }

  cancel(): void {
    this.close();
  }

  close(params?: any): void {
    if (params && params.dynamicData.contractType === 'まき直し') {
      PopupControlComponent.instance.open({
        title: 'まき直し契約の確認',
        content: '過去の契約を無効にしてください',
        cancelText: 'いいえ',
        confirmText: 'はい',
        confirmCallback: () => this.dialog.close(params),
      });
    } else {
      this.dialog.close(params);
    }
  }

  applyApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = ApprovalClassification.APPROVAL_PENDING;
    this.close(obj);
  }

  cancelApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = ApprovalClassification.UNAPPROVED;
    this.close(obj);
  }

  approveApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = ApprovalClassification.APPROVED;
    this.close(obj);
  }

  denyApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = ApprovalClassification.WAITING_FOR_RE_APPLICATION;
    this.close(obj);
  }

  saveApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = this.model.dynamicData.approvalClassification || ApprovalClassification.UNAPPROVED;
    this.close(obj);
  }

  reApplyApplication(): void {
    const obj = deepClone(this.model);
    obj.dynamicData.approvalClassification = ApprovalClassification.APPROVAL_PENDING;
    this.close(obj);
  }
}
