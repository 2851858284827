import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { MailPrefill, StudentDetail } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { StudentApiService } from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'ag-student-message-tab',
  templateUrl: './student-message-tab.component.html',
  styleUrls: ['./student-message-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentMessageTabComponent) }],
})
export class StudentMessageTabComponent extends Tab {
  @Input() student: StudentDetail;
  sendConfig = new StudentSendConfig();

  constructor(public readonly studentApiService: StudentApiService) {
    super();
  }

  openMail(prefill: MailPrefill): void {
    MailSendFlowComponent.instance.config = this.sendConfig;
    this.sendConfig.setParams({
      student: {
        id: this.student.id,
        frontId: this.student.frontId,
        firstName: this.student.dynamicData.firstName,
        lastName: this.student.dynamicData.lastName,
        age: null,
        prefecture: null,
        schoolName: null,
        schoolDepartmentName: null,
        studentUser: this.student.studentUsers,
        emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
        emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
        emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
        emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
      },
    });
    MailSendFlowComponent.instance.start(prefill);
  }
}
