import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { TemplateRef } from '@angular/core';

export const STUDENT_IMPORT_TABLE_CONFIG: (
  errorTypeTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  dateTransform: (date: string) => string,
) => TableConfig = (errorTypeTemplate, actionsTemplate, dateTransform) => ({
  head: {
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'registrationRoute',
                title: '媒体',
                sortable: false,
              },
            ],
            style: {
              width: '275px',
              'margin-right': '15px',
            },
            bodyStyle: {
              'font-family': '"Meiryo UI Bold", sans-serif',
              'font-size': '16px',
              'line-height': '20px',
              'padding-top': '13px',
              'padding-bottom': '13px',
            },
          },
          {
            fields: [
              {
                name: 'firstRegistrationDate',
                title: 'エントリー日',
                sortable: false,
                formatter: (data) => dateTransform(data.firstRegistrationDate),
              },
            ],
            style: {
              width: '215px',
              'margin-right': '15px',
            },
            bodyStyle: {
              'font-size': '14px',
              'line-height': '18px',
              'padding-top': '13px',
              'padding-bottom': '13px',
            },
          },
          {
            fields: [
              {
                name: 'name',
                title: '氏名',
                sortable: false,
              },
            ],
            style: {
              width: '195px',
              'margin-right': '15px',
            },
            bodyStyle: {
              'font-size': '14px',
              'line-height': '18px',
              'padding-top': '13px',
              'padding-bottom': '13px',
            },
          },
          {
            fields: [
              {
                name: 'type',
                title: 'エラー理由',
                sortable: false,
                cellTemplate: errorTypeTemplate,
              },
            ],
            style: {
              flex: '1',
              'min-width': '170px',
              'margin-right': '15px',
            },
            bodyStyle: {
              'padding-top': '13px',
              'padding-bottom': '13px',
              'white-space': 'nowrap',
            },
          },
          {
            fields: [
              {
                name: 'actions',
                title: 'アクション',
                sortable: false,
                cellTemplate: actionsTemplate,
              },
            ],
            style: {
              width: '165px',
            },
            bodyStyle: {
              'padding-top': '13px',
              'padding-bottom': '13px',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: true,
    arrow: true,
  },
});
