import { StudentUploadsPageComponent } from '@agent-ds/student/pages/student-uploads-page/student-uploads-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';
import { ZoneGuard } from '@agent-ds/shared/guard/zone.guard';
import {
  StudentDetailPageComponent,
  StudentImportPageComponent,
  StudentListPageComponent,
  StudentListWithSubUserPageComponent,
  StudentListWithTodaysCounselingPageComponent,
} from './pages';

/**
 * Route定数
 */
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'list', component: StudentListPageComponent },
      { path: 'listWithTodaysCounseling', component: StudentListWithTodaysCounselingPageComponent },
      { path: 'listWithSubUser', component: StudentListWithSubUserPageComponent },
      { path: 'add', component: StudentListPageComponent },
      { path: 'search', component: StudentListPageComponent },
      { path: 'searchWithTodaysCounseling', component: StudentListWithTodaysCounselingPageComponent },
      { path: 'searchWithSubUser', component: StudentListWithSubUserPageComponent },
      { path: 'import', component: StudentImportPageComponent, canActivate: [ZoneGuard] },
      { path: 'uploads', component: StudentUploadsPageComponent, canActivate: [ZoneGuard] },
      { path: ':id', component: StudentDetailPageComponent },
      { path: ':id/:tab', component: StudentDetailPageComponent },
    ],
  },
];

/**
 * 求職者ルーティング
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentRoutingModule {}
