import { ProgressCreateDialogComponent } from './progress-create-dialog/progress-create-dialog.component';
import { ProgressScheduleAdjustDialogComponent } from './progress-schedule-adjust-dialog/progress-schedule-adjust-dialog.component';
import { ProgressSearchComponent } from './progress-search/progress-search.component';

export * from './progress-create-dialog/progress-create-dialog.component';
export * from './progress-schedule-adjust-dialog/progress-schedule-adjust-dialog.component';
export * from './progress-search/progress-search.component';

export const PROGRESS_COMPONENTS = [ProgressSearchComponent];

export const PROGRESS_DIALOG_COMPONENTS = [ProgressCreateDialogComponent, ProgressScheduleAdjustDialogComponent];
