import { ExtendedProgressStatusCode } from '@agent-ds/shared/enums';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, ExportApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-progress-list-export-page',
  templateUrl: './progress-list-export-page.component.html',
  styleUrls: ['./progress-list-export-page.component.scss'],
})
export class ProgressListExportPageComponent implements OnInit, OnDestroy {
  formModel: any = {};
  formMeta: FormMeta;
  isExportInProgress = false;
  private fieldSubscription: Subscription;

  constructor(private dynamicService: DynamicFieldService, private exportApiService: ExportApiService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.formMeta = {
        groups: [
          {
            rows: [
              ...this.dynamicService.getFormRows(
                { fieldName: 'studentTeamId', label: '担当チーム (求職者)', fieldType: 'team' },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'studentUserId', fieldType: 'multi-user', label: '担当者 (求職者)' },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'enterpriseTeamId', label: '担当チーム (求人)', fieldType: 'team' },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'enterpriseUserId', fieldType: 'multi-user', label: '担当者 (求人)' },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'studentId',
                  fieldType: 'multi-student',
                  label: '求職者',
                  displayStyle: { placeholder: '求職者名、求職者カナ、求職者ID' },
                },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows({ fieldName: 'jobId', fieldType: 'multi-job', label: '求人' }, null, 'third'),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'interviewDate'),
                fieldType: 'date-range',
                displayType: 'date-more-advanced',
              }),
              {
                title: '最新ステータス',
                fields: [
                  {
                    name: 'status',
                    type: 'checkbox',
                    multi: true,
                    class: 'medium',
                    valueField: 'value',
                    labelField: 'label',
                    options: [
                      ...[
                        10,
                        20,
                        30,
                        40,
                        50,
                        60,
                        70,
                        80,
                        90,
                        500,
                        501,
                        502,
                        511,
                        512,
                        521,
                        522,
                        130,
                        140,
                        150,
                        160,
                        170,
                        180,
                        190,
                        200,
                      ].map((key) => ({ label: ExtendedProgressStatusCode[key], value: key })),
                    ],
                    actions: [
                      {
                        type: 'RUNNABLE',
                        title: '稼働中',
                        runnable: () => [60, 70, 80, 90, 130, 500, 501, 502, 510, 511, 512, 520, 521, 522],
                      },
                      {
                        type: 'RUNNABLE',
                        title: '進捗中',
                        runnable: () => [130, 500, 501, 502, 510, 511, 512, 520, 521, 522],
                      },
                      {
                        type: 'RUNNABLE',
                        title: '選択解除',
                        runnable: () => [],
                      },
                    ],
                    actionsClass: 'before full mb-3',
                  },
                ],
              },
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  export(): void {
    this.isExportInProgress = true;
    this.exportApiService
      .exportProgresses(this.formModel)
      .subscribe(() => (this.isExportInProgress = false), () => (this.isExportInProgress = false));
  }
}
