import { StudentDetail } from '@agent-ds/shared/interfaces';
import { FormMeta, Tab } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { Component, forwardRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ag-student-academic-tab',
  templateUrl: './student-academic-tab.component.html',
  styleUrls: ['./student-academic-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentAcademicTabComponent) }],
})
export class StudentAcademicTabComponent extends Tab implements OnInit {
  @Input() student: StudentDetail;
  @Input() readonly: boolean;
  metadata: FormMeta;
  valid = true;

  constructor(private dynamicService: DynamicFieldService, private studentApiService: StudentApiService) {
    super();
  }

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadata = {
        disabled: this.readonly,
        groups: [
          {
            title: '学歴',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastAcademicRecord')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicFieldType')),
            ],
          },
          {
            title: '最終',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicHistory1')),
          },
          {
            title: 'No.2',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicHistory2')),
          },
          {
            class: 'form__group--no-title-border form__group--no-margin mt-20',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'universityLevel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicCourseType')),
            ],
          },
          {
            title: '資格',
            rows: [],
          },
          {
            title: 'No.1',
            class: 'form__group--no-title-border form__group--no-margin',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'certificate1')),
          },
          {
            title: 'No.2',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'certificate2')),
          },
          {
            title: 'No.3',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'certificate3')),
          },
          {
            title: 'No.4',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'certificate4')),
          },
          {
            title: 'No.5',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'certificate5')),
          },
          {
            class: 'mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'otherSkill')),
          },
          {
            class: 'mt-20',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingC')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingCPP')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingCS')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingHTML')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingJava')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingJS')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingPerl')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingPHP')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingPython')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingRuby')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingVB')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingGo')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'otherITSkills')),
            ],
          },
          {
            title: '語学',
            rows: [],
          },
          {
            title: 'No.1',
            class: 'form__group--no-title-border form__group--no-margin',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language1')),
          },
          {
            title: 'No.2',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language2')),
          },
          {
            title: 'No.3',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language3')),
          },
          {
            title: 'No.4',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language4')),
          },
          {
            title: 'No.5',
            class: 'form__group--no-title-border form__group--no-margin title-mt-20',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language5')),
          },
        ],
      };
    });
  }

  save(): void {
    this.studentApiService.update(this.student.id, this.student).subscribe((res) => this.studentApiService.refreshEvent.emit());
  }
}
