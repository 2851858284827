import { MailSendComponent } from './mail-send.component';

export enum MailPopupType {
  JOB,
  DEPARTMENT,
  JOB_CHECK,
  ENTERPRISE_RESUME_PASSWORD_CHECK,
  STUDENT,
}

export interface MailPopupConfig {
  type: MailPopupType;
  multiSelect?: boolean;
  bodyArea?: boolean;
  customFilter?: { [key: string]: any };
  stepBack?: number;
  title?: string;
  message?: string;
  confirmText?: string;
}
export abstract class MailSendConfig {
  protected sender: MailSendComponent;

  public attach(sender: MailSendComponent): void {
    this.sender = sender;
  }

  public detach(): void {
    this.sender = null;
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.update();
    }
  }

  public abstract initForm(): void;
  public abstract update(): void;
  public abstract save(toSave: any): void;

  public getTemplateId(): number {
    return null;
  }
  public getTemplateTypeId(): number[] {
    return [];
  }
  public getPopupConfig(): MailPopupConfig[] {
    return [];
  }
  public sendAfterFlow(): boolean {
    return false;
  }
}
