import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentBulkConfig } from '@agent-ds/shared/components/mail-send/configs/student-bulk-config';
import { PageFloaterComponent } from '@agent-ds/shared/components/page-floater/page-floater.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { StudentListComponent } from '@agent-ds/shared/components/student-list/student-list.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { DialogService, ExportApiService } from '@agent-ds/shared/services';
import { cleanObject, parseQueryParam } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { StudentDetailPageComponent } from '../student-detail-page/student-detail-page.component';

@Component({
  selector: 'ag-student-list-with-todays-counseling-page',
  templateUrl: './student-list-with-todays-counseling-page.component.html',
  styleUrls: ['./student-list-with-todays-counseling-page.component.scss'],
})
export class StudentListWithTodaysCounselingPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild('studentList', { static: false }) table: StudentListComponent;
  @ViewChild('export', { static: false }) exportTemplate: TemplateRef<any>;
  protected readonly model = 'students';
  protected readonly detail = StudentDetailPageComponent as any;
  protected readonly exportModel = ExportModel.student;
  public isSearchOpen: boolean;

  listConfiguration: any = { body: { checkbox: true } };

  private intakeCaParamSubscription: Subscription;
  private intakeCaRouterEventSubscription: Subscription;

  private readonly searchUrl: string = 'searchWithTodaysCounseling';
  private readonly listUrl: string = 'listWithTodaysCounseling';

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
    private dialog: DialogService,
  ) {
    super(router, location, activeRoute, exportService);
    this.isSearchOpen = false;
  }

  ngOnInit() {
    this.intakeCaRouterEventSubscription = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
    this.checkRoute();

    if (this.exportModel) {
      this.exportTemplates.length = 0;
      this.exportService.getTemplates(this.exportModel).subscribe((res) => this.exportTemplates.push(...res.templates));
    }

    this.intakeCaParamSubscription = this.activeRoute.queryParams.subscribe((params: Params) => {
      this.searchObj = parseQueryParam(params);
      if (!this.router.url.endsWith(`/${this.model}/${this.searchUrl}`) && !this.router.url.endsWith(`/${this.model}/add`)) {
        setTimeout(() => this.doSearch({}), 0);
      }
    });
    this.origUrl = this.router.routerState.snapshot.url;
    if (!this.origUrl.startsWith(`/${this.model}/${this.listUrl}`)) {
      this.origUrl = `/${this.model}/${this.listUrl}`;
    }
    setTimeout(() => (this.detail.instance.origUrl = this.origUrl));
  }

  ngOnDestroy(): void {
    if (this.intakeCaRouterEventSubscription) {
      this.intakeCaRouterEventSubscription.unsubscribe();
      this.intakeCaRouterEventSubscription = null;
    }
    if (this.intakeCaParamSubscription) {
      this.intakeCaParamSubscription.unsubscribe();
      this.intakeCaParamSubscription = null;
    }
  }

  sendMail(): void {
    const bulkSendConfig = new StudentBulkConfig();
    MailSendFlowComponent.instance.config = bulkSendConfig;
    bulkSendConfig.setParams({ condition: this.searchObj });
    MailSendFlowComponent.instance.start();
  }

  bulkUpdate(): void {
    this.dialog.open(BatchUpdateDialogComponent, {
      data: {
        condition: cleanObject({
          ...this.searchObj,
          from: 0,
          size: this.table.totalSize,
        }),
      },
    });
  }

  protected checkRoute(): void {
    if (this.router.url.endsWith(`/${this.model}/${this.searchUrl}`)) {
      this.searchObj = { keyword: '' };
      this.openSearch();
    } else {
      this.closeSearch();
      if (!this.router.url.endsWith(`/${this.model}/add`)) {
        this.doSearch({});
      }
    }
  }

  protected doSearch(params: any): void {
    this.searchObj = {
      ...this.searchObj,
      ...params,
    };
    // 固定パラメーターの追加
    this.searchObj.sortingDateTo = moment().format('YYYY-MM-DD');
    this.searchObj.sortingDateFrom = moment().format('YYYY-MM-DD');
    this.searchObj.howIntakeCaSearches = 'searchWithTodaysCounseling';

    this.closeSearch();
  }

  openSearch(): void {
    if (this.isSearchOpen) {
      return;
    }
    PageFloaterComponent.closeAll();
    this.location.go(`/${this.model}/${this.searchUrl}`);
    this.isSearchOpen = true;

    const navigationSubscription = this.location.subscribe(() => {
      this.closeSearch();
      navigationSubscription.unsubscribe();
    });
  }
}
