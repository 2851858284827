class CACHEIMPL {
  private readonly cache: { [key: string]: { ttl?: number; until?: number; value?: any } } = {};

  public isRegistered(key: string): boolean {
    return this.cache[key] != null;
  }
  public getTTL(key: string) {
    return this.cache[key] ? this.cache[key].ttl : null;
  }

  public get(key: string): any {
    return this.cache[key] && (this.cache[key].until == null || this.cache[key].until >= Date.now()) ? this.cache[key].value : null;
  }
  public set(key: string, value: any, ttl?: number): any {
    if (!this.cache[key]) {
      this.cache[key] = { ttl: ttl };
    }
    this.cache[key].until = value != null && this.cache[key].ttl ? Date.now() + this.cache[key].ttl : this.cache[key].until;
    this.cache[key].value = value || this.cache[key].value;
  }
  public clear(key?: string): void {
    if (key != null) {
      if (this.cache[key]) {
        delete this.cache[key];
      } else {
        Object.keys(this.cache)
          .filter((cKey) => cKey.startsWith(key))
          .forEach((cKey) => this.clear(cKey));
      }
    } else {
      Object.keys(this.cache).forEach((cKey) => this.clear(cKey));
    }
  }
}
export const CACHE = new CACHEIMPL();
