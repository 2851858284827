import { MAX_FEE_VALUE } from '@agent-ds/sales/sales-detail-page/helpers/order-info-helper';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE, TAX_PERCENTAGE } from '@agent-ds/shared/constants/consts';
import {
  CompanyBillingAddress,
  CompanyDetail,
  Contract,
  EnterpriseDepartmentUserType,
  EnterpriseDepthContacts,
  Team,
  User,
} from '@agent-ds/shared/interfaces';
import { SalesCreateRequest } from '@agent-ds/shared/interfaces/sale';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import {
  AuthService,
  CompanyApiService,
  DialogService,
  DynamicFieldService,
  MailApiService,
  MasterApiService,
  ProgressApiService,
  SalesApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { deepClone } from '@agent-ds/shared/util/util';
import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ag-sales-create-dialog',
  templateUrl: './sales-create-dialog.component.html',
  styleUrls: ['./sales-create-dialog.component.scss'],
})
export class SalesCreateDialogComponent implements OnInit, OnDestroy {
  mainMeta: FormMeta;
  mainModel = {};

  feeMeta: FormMeta;
  feeModel = {};

  emailMeta: FormMeta;
  emailModel = {};

  accountMetas: {
    userMeta: FormMeta;
    teamMeta: FormMeta;
    ratioMeta: FormMeta;
    feeMeta: FormMeta;
  };
  requiredAccountMetas: {
    userMeta: FormMeta;
    teamMeta: FormMeta;
    ratioMeta: FormMeta;
    feeMeta: FormMeta;
  };

  accountModel: {
    ca: object;
    ra: object;
    pa: object;
  };

  contractOptions: any[] = [];
  billingOptions: any[] = [];
  feeSummary: any;

  inClose = false;
  validity = false;
  private validityArray = [false, false, false, false];

  confirmSubscription: Subscription;
  fieldSubScription: Subscription;

  isActive(billing: CompanyBillingAddress) {
    return billing.dynamicData.btoBBillignAddressRegistrationDate && billing.dynamicData.billignAddressStatus === '有効' ? billing : false;
  }

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private companyApiService: CompanyApiService,
    private masterApiService: MasterApiService,
    private userApiService: UserApiService,
    private salesApiService: SalesApiService,
    private mailApiService: MailApiService,
    private authService: AuthService,
    private decimalPipe: DecimalPipe,
    private dialogService: DialogService,
    private progressApiService: ProgressApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.register());
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.mainMeta = {
        groups: [
          {
            title: '送信先',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: 'To',
                fields: [
                  {
                    name: 'mail.to',
                    type: 'label',
                  },
                ],
              },
              {
                title: 'Cc',
                fields: [
                  {
                    name: 'mail.cc',
                    type: 'label',
                    class: 'pre',
                  },
                ],
              },
            ],
          },
          {
            title: '成約情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'salesClassification'), null, 'half'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'hireType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'contractDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'enterDate')),
            ],
          },
          {
            title: '契約先情報',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: '契約先',
                showRequired: true,
                fields: [
                  {
                    name: 'contractId',
                    type: 'dropdown',
                    class: 'fill',
                    valueField: 'id',
                    labelField: 'label',
                    options: [],
                    linkTo: [
                      'contract.companyName',
                      'contract.phoneticCompanyName',
                      'contract.departmentName',
                      'contract.contact.name',
                      'contract.address',
                      'contract.contact.tel',
                      'contract.contact.fax',
                    ],
                    supplier: () =>
                      this.companyApiService.getContracts(this.config.data.enterpriseId).pipe(
                        map((contracts: Contract[]) => {
                          this.contractOptions = [
                            { label: NULL_SELECTED_VALUE, id: null },
                            ...contracts
                              .map((contract) => {
                                contract.dynamicData.id = contract.id;
                                contract.dynamicData.label = [
                                  contract.frontId,
                                  contract.dynamicData.companyName,
                                  contract.dynamicData.departmentName,
                                  contract.dynamicData.contractClassification,
                                ]
                                  .filter((v) => v)
                                  .join(' ');
                                return contract.dynamicData;
                              })
                              .filter((c) => c.status === '有効'),
                          ];
                          return {
                            options: this.contractOptions,
                            // value: contracts && contracts.length ? contracts[0].id : null,
                          };
                        }),
                      ),
                    validators: { required: true },
                  },
                ],
              },
              {
                title: '企業名',
                fields: [
                  {
                    name: 'contract.companyName',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.companyName ? contract.companyName : '';
                    },
                  },
                ],
              },
              {
                title: '企業名カナ',
                fields: [
                  {
                    name: 'contract.phoneticCompanyName',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.phoneticCompanyName ? contract.phoneticCompanyName : '';
                    },
                  },
                ],
              },
              {
                title: '部署名',
                fields: [
                  {
                    name: 'contract.departmentName',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.departmentName ? contract.departmentName : '';
                    },
                  },
                ],
              },
              {
                title: '担当者名',
                fields: [
                  {
                    name: 'contract.contact.name',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.contact && contract.contact.name ? contract.contact.name : '';
                    },
                  },
                ],
              },
              {
                title: '住所',
                fields: [
                  {
                    name: 'contract.address',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.address
                        ? [
                            `${contract.address.zip ? contract.address.zip.slice(0, 3) + '-' + contract.address.zip.slice(3) : ''}`,
                            `${contract.address.prefecture ? contract.address.prefecture : ''}`,
                            `${contract.address.address1 ? contract.address.address1 : ''}`,
                            `${contract.address.address2 ? contract.address.address2 : ''}`,
                            `${contract.address.address3 ? contract.address.address3 : ''}`,
                          ].join(' ')
                        : '';
                    },
                  },
                ],
              },
              {
                title: 'Tel',
                fields: [
                  {
                    name: 'contract.contact.tel',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.contact && contract.contact.tel ? contract.contact.tel : '';
                    },
                  },
                ],
              },
              {
                title: 'Fax',
                fields: [
                  {
                    name: 'contract.contact.fax',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('contractId');
                      const contract = this.contractOptions.find((v) => v.id === linkValue);
                      return contract && contract.contact && contract.contact.fax ? contract.contact.fax : '';
                    },
                  },
                ],
              },
            ],
          },
          {
            title: '請求先情報',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: '請求先',
                showRequired: true,
                fields: [
                  {
                    name: 'enterpriseBillingAddressId',
                    type: 'dropdown',
                    class: 'fill',
                    valueField: 'id',
                    labelField: 'label',
                    options: [],
                    linkTo: [
                      'billing.companyName',
                      'billing.contact.department',
                      'billing.contact.name',
                      'billing.contact.position',
                      'billing.contact.tel',
                      'billing.contact.fax',
                      'billing.contact.address',
                    ],
                    supplier: () =>
                      this.companyApiService.getDepartments(this.config.data.enterpriseId).pipe(
                        map((contacts: EnterpriseDepthContacts) => {
                          this.billingOptions = [
                            { label: NULL_SELECTED_VALUE, id: null },
                            ...(contacts && contacts.enterpriseBillingAddresses && contacts.enterpriseBillingAddresses.length
                              ? contacts.enterpriseBillingAddresses
                                  .filter((billing) => this.isActive(billing))
                                  .map((billing) => {
                                    billing.dynamicData.id = billing.id;
                                    const bservice =
                                      billing.dynamicData.billignAddressService && billing.dynamicData.billignAddressService !== ''
                                        ? billing.dynamicData.billignAddressService + '-'
                                        : '';
                                    billing.dynamicData.label = [
                                      bservice,
                                      billing.dynamicData.companyName,
                                      billing.dynamicData.departmentName,
                                      billing.dynamicData.contact.name,
                                    ]
                                      .filter((v) => v)
                                      .join(' ');
                                    return billing.dynamicData;
                                  })
                              : []),
                          ];
                          return {
                            options: this.billingOptions,
                            // value:
                            //   contacts && contacts.enterpriseBillingAddresses && contacts.enterpriseBillingAddresses.length
                            //     ? contacts.enterpriseBillingAddresses[0].id
                            //     : null,
                          };
                        }),
                      ),
                    validators: { required: true },
                  },
                ],
              },
              {
                title: '請求先名',
                fields: [
                  {
                    name: 'billing.companyName',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      if (billing) {
                        return this.companyApiService.getDetail(this.config.data.enterpriseId).pipe(
                          map((company: CompanyDetail) => {
                            return [
                              // company && company.frontId ? company.frontId : '',
                              billing && billing.companyName ? billing.companyName : '',
                            ]
                              .filter((v) => !!v)
                              .join('-');
                          }),
                        );
                      }
                    },
                  },
                ],
              },
              {
                title: '担当者部署名',
                fields: [
                  {
                    name: 'billing.contact.department',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.departmentName ? billing.departmentName : '';
                    },
                  },
                ],
              },
              {
                title: '担当者名',
                fields: [
                  {
                    name: 'billing.contact.name',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.contact && billing.contact.name ? billing.contact.name : '';
                    },
                  },
                ],
              },
              {
                title: '担当者役職',
                fields: [
                  {
                    name: 'billing.contact.position',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.contact && billing.contact.position ? billing.contact.position : '';
                    },
                  },
                ],
              },
              {
                title: '担当者Tel',
                fields: [
                  {
                    name: 'billing.contact.tel',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.contact && billing.contact.tel ? billing.contact.tel : '';
                    },
                  },
                ],
              },
              {
                title: '担当者Fax',
                fields: [
                  {
                    name: 'billing.contact.fax',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.contact && billing.contact.fax ? billing.contact.fax : '';
                    },
                  },
                ],
              },
              {
                title: '住所',
                fields: [
                  {
                    name: 'billing.contact.address',
                    type: 'label',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue('enterpriseBillingAddressId');
                      const billing = this.billingOptions.find((v) => v.id === linkValue);
                      return billing && billing.address
                        ? [
                            `${billing.address.zip ? billing.address.zip.slice(0, 3) + '-' + billing.address.zip.slice(3) : ''}`,
                            `${billing.address.prefecture ? billing.address.prefecture : ''}`,
                            `${billing.address.address1 ? billing.address.address1 : ''}`,
                            `${billing.address.address2 ? billing.address.address2 : ''}`,
                            `${billing.address.address3 ? billing.address.address3 : ''}`,
                          ].join(' ')
                        : '';
                    },
                  },
                ],
              },
            ],
          },
        ],
      };

      this.feeMeta = {
        groups: [
          {
            title: '受注額情報',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: 'フィー',
                showRequired: true,
                fields: [
                  {
                    name: 'fee',
                    type: 'number',
                    labelAfter: '円',
                    linkTo: ['taxFee', 'chargeFee'],
                    validators: {
                      required: true,
                      maxValue: MAX_FEE_VALUE,
                    },
                    class: 'half',
                  },
                ],
              },
              {
                title: '消費税',
                showRequired: true,
                fields: [
                  {
                    name: 'taxFee',
                    type: 'number',
                    labelAfter: '円',
                    supplier: () => '',
                    validators: {
                      required: true,
                    },
                    class: 'half',
                  },
                  {
                    name: 'fee-action-label',
                    type: 'label',
                    actions: [
                      {
                        title: '計算',
                        type: 'RUNNABLE',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const feeValue = getValue('fee');
                          const fee = +this.removeLocaleFromStringifiedNumber(feeValue != null ? feeValue : '');
                          if (!this.validityArray[1] && (feeValue == null || isNaN(fee) || fee > MAX_FEE_VALUE)) {
                            const config: ConfirmDialogConfig = {
                              title: '入力値が不正です',
                              messages: ['フィーの金額が正しくありません'],
                              style: {
                                height: '245px',
                                width: '510px',
                              },
                              buttons: {
                                no: '',
                                hideNo: true,
                                yes: 'OK',
                              },
                            };
                            this.dialogService.open(ConfirmDialogComponent, config);
                          }
                          ['ca', 'ra', 'pa'].forEach((key) => this.onAccountRatioChange(key as 'ca' | 'ra' | 'pa'));
                          if (isNaN(fee)) {
                            return value;
                          }
                          setValue('chargeFee', Math.round(fee * (1 + TAX_PERCENTAGE)));
                          setValue('taxFee', Math.round(fee * TAX_PERCENTAGE));
                        },
                      },
                    ],
                  },
                ],
              },
              {
                title: '請求額',
                showRequired: true,
                fields: [
                  {
                    name: 'chargeFee',
                    type: 'number',
                    labelAfter: '円',
                    supplier: () => '',
                    validators: {
                      required: true,
                    },
                    class: 'half',
                  },
                ],
              },
            ],
          },
        ],
      };

      this.emailMeta = {
        groups: [
          {
            title: '計上エビデンスフォルダパス',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: 'メールの\n追記文言',
                showRequired: true,
                fields: [
                  {
                    name: 'email',
                    type: 'textarea',
                    class: 'fill',
                    validators: { required: true },
                  },
                ],
              },
            ],
          },
        ],
      };

      this.accountMetas = {
        userMeta: {
          groups: [
            {
              class: 'no-title-column no-border',
              rows: [
                ...((): RowMeta[] => {
                  const dynMeta = this.dynamicService.getFormRows({
                    fieldName: 'user',
                    fieldType: 'user',
                  });

                  // show team name only in dropdown, but not after the user has been selected: Team name is shown then in a separate column
                  if (dynMeta.length && dynMeta[0].fields.length && Array.isArray(dynMeta[0].fields[0].labelField)) {
                    const labels = dynMeta[0].fields[0].labelField as {
                      title?: string;
                      name: string;
                      class?: string;
                      supplier?: (value: any) => any;
                      supplierAsync?: (value: any) => Observable<any>;
                      hidden?: boolean;
                      hiddenAsValue?: boolean;
                    }[];
                    const label = labels.find((lab) => lab.name === 'team');
                    if (label) {
                      label.hiddenAsValue = true;
                    }
                  }
                  return dynMeta;
                })(),
              ],
            },
          ],
        },
        teamMeta: {
          groups: [
            {
              class: 'no-title-column no-border',
              rows: [
                {
                  fields: [
                    {
                      type: 'label',
                      name: 'team',
                      supplier: (value) =>
                        this.masterApiService.getTeams().pipe(
                          map((teams) => {
                            const select = teams.find((team) => team.id === value);
                            return select ? select.name : undefined;
                          }),
                        ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        ratioMeta: {
          groups: [
            {
              class: 'no-title-column no-border',
              rows: [
                {
                  fields: [
                    {
                      name: 'ratio',
                      type: 'number',
                      labelAfter: '%',
                    },
                  ],
                },
              ],
            },
          ],
        },
        feeMeta: {
          groups: [
            {
              class: 'no-title-column no-border',
              rows: [
                {
                  fields: [
                    {
                      name: 'fee',
                      type: 'label',
                      supplier: (value) => [value, value != null ? '円' : ''].join(' '),
                    },
                  ],
                },
              ],
            },
          ],
        },
      };
      this.requiredAccountMetas = deepClone(this.accountMetas);
      this.requiredAccountMetas.userMeta.groups[0].rows[0].fields[0].validators = { required: true };

      const raJobUser = this.config.data.jobUsers.find(
        (jobUser) => jobUser.enterpriseDepartmentUser.type === EnterpriseDepartmentUserType.RA,
      );
      const paJobUser =
        this.config.data.progressType === 1 &&
        this.config.data.jobUsers.find((jobUser) => jobUser.enterpriseDepartmentUser.type === EnterpriseDepartmentUserType.PA);
      this.accountModel = {
        ca: this.config.data.caUserId ? { user: this.config.data.caUserId } : {},
        ra: raJobUser ? { user: raJobUser.enterpriseDepartmentUser.userId } : {},
        pa: paJobUser ? { user: paJobUser.enterpriseDepartmentUser.userId } : {},
      };
      ['ca', 'ra', 'pa'].forEach((key: 'ca' | 'ra' | 'pa') => this.onAccountUserChange(key));
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  asIsOrder(a: any, b: any): number {
    return 1;
  }

  onAccountUserChange(key: 'ca' | 'ra' | 'pa'): void {
    this.userApiService
      .getAll()
      .pipe(
        concatMap((allUsers: User[]) => {
          if (this.accountModel[key]['user'] == null) {
            this.accountModel[key]['team'] = null;
            this.accountModel[key]['ratio'] = null;
            this.accountModel[key]['fee'] = null;
          }

          const users: { ca: User; ra: User; pa: User } = { ca: null, ra: null, pa: null };
          Object.keys(this.accountModel).map(
            (userKey: 'ca' | 'ra' | 'pa') => (users[userKey] = allUsers.find((user) => user.id === this.accountModel[userKey]['user'])),
          );

          const mailToUser = users['ra'];
          if (mailToUser) {
            this.masterApiService.getTeams().subscribe((teams) => {
              const raTeam = teams.find((t) => t.id === mailToUser.teamId);
              if (raTeam && raTeam.manager) {
                this.mainModel['mail'] = {
                  ...this.mainModel['mail'],
                  to: `${raTeam.manager.name} <${raTeam.manager.email}>`,
                };
              } else {
                this.mainModel['mail'] = {
                  ...this.mainModel['mail'],
                  to: null,
                };
              }
            });
          } else {
            this.mainModel['mail'] = {
              ...this.mainModel['mail'],
              to: null,
            };
          }

          this.mainModel['mail'] = {
            ...this.mainModel['mail'],
            cc: Object.values(users)
              .filter((user) => !!user)
              .map((user) => `${user.name} <${user.email}>`)
              .join('\n'),
          };

          if (users[key] && users[key].teamId) {
            return this.masterApiService.getTeams().pipe(
              map((teams: Team[]) => {
                return teams.find((team) => team.id === users[key].teamId);
              }),
            );
          }

          return of(null);
        }),
      )
      .subscribe((team: Team) => {
        if (this.accountModel[key]['user'] == null) {
          this.accountModel[key]['team'] = null;
          this.accountModel[key]['ratio'] = null;
          this.accountModel[key]['fee'] = null;
        } else {
          this.accountModel[key]['team'] = team && team.id;
          this.accountModel[key]['ratio'] = 100;
          this.onAccountRatioChange(key);
        }
      });
  }

  onAccountRatioChange(key: 'ca' | 'ra' | 'pa'): void {
    this.accountModel[key]['fee'] =
      this.feeModel &&
      this.feeModel['fee'] != null &&
      this.accountModel &&
      this.accountModel[key] &&
      this.accountModel[key]['ratio'] != null
        ? this.decimalPipe.transform(
            (parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['fee']), 10) / 100) * this.accountModel[key]['ratio'],
          )
        : null;
  }

  onMainChange(metaKey: string) {
    if (metaKey && metaKey === 'contractId' && this.mainModel && this.mainModel[metaKey] != null) {
      const contract = this.contractOptions.find((v) => v.id === this.mainModel[metaKey]);
      if (contract) {
        this.feeSummary = [
          contract && contract.fee1Price
            ? `${this.decimalPipe.transform(contract.fee1Price)}\nフィー規定備考：${contract.fee1Remarks ? contract.fee1Remarks : ''}`
            : '',
          contract && contract.fee2Price
            ? `${this.decimalPipe.transform(contract.fee2Price)}\nフィー規定備考：${contract.fee2Remarks ? contract.fee2Remarks : ''}`
            : '',
          contract && contract.fee3Price
            ? `${this.decimalPipe.transform(contract.fee3Price)}\nフィー規定備考：${contract.fee3Remarks ? contract.fee3Remarks : ''}`
            : '',
          contract && contract.fee4Price
            ? `${this.decimalPipe.transform(contract.fee4Price)}\nフィー規定備考：${contract.fee4Remarks ? contract.fee4Remarks : ''}`
            : '',
          contract && contract.fee5Price
            ? `${this.decimalPipe.transform(contract.fee5Price)}\nフィー規定備考：${contract.fee5Remarks ? contract.fee5Remarks : ''}`
            : '',
        ]
          .filter((v) => !!v)
          .join('\n\n');

        // commented by request
        // const highestFee = contract
        //   ? contract.fee1Price
        //     ? contract.fee1Price
        //     : contract.fee2Price
        //     ? contract.fee2Price
        //     : contract.fee3Price
        //     ? contract.fee3Price
        //     : contract.fee4Price
        //     ? contract.fee4Price
        //     : contract.fee5Price
        //     ? contract.fee5Price
        //     : null
        //   : null;
        // this.feeModel['fee'] = this.decimalPipe.transform(highestFee);
      }
    }
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  register(): void {
    if (!this.validity || this.inClose) {
      return;
    }

    const salesCreateRequest: SalesCreateRequest = {
      progressId: this.config.data.progressId,
      contractId: this.mainModel['contractId'],
      enterpriseBillingAddressId: this.mainModel['enterpriseBillingAddressId'],
      type: this.config.data.progressType,
      dynamicData: {
        contractDate: new Date(this.mainModel['contractDate']).toAsialDateString(),
        enterDate: new Date(this.mainModel['enterDate']).toAsialDateString(),
        fee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['fee']), 10),
        taxFee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['taxFee']), 10),
        chargeFee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['chargeFee']), 10),
      },
    };
    if (this.accountModel['ca']) {
      salesCreateRequest.caUserId = this.accountModel['ca']['user'];
      salesCreateRequest.caPercentage = this.accountModel['ca']['ratio'];
      salesCreateRequest.caReward = this.accountModel['ca']['fee']
        ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['ca']['fee']), 10)
        : null;
    }
    if (this.accountModel['ra']) {
      salesCreateRequest.raUserId = this.accountModel['ra']['user'];
      salesCreateRequest.raPercentage = this.accountModel['ra']['ratio'];
      salesCreateRequest.raReward = this.accountModel['ra']['fee']
        ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['ra']['fee']), 10)
        : null;
    }
    if (this.accountModel['pa']) {
      salesCreateRequest.paUserId = this.accountModel['pa']['user'];
      salesCreateRequest.paPercentage = this.accountModel['pa']['ratio'];
      salesCreateRequest.paReward = this.accountModel['pa']['fee']
        ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['pa']['fee']), 10)
        : null;
    }
    if (this.mainModel['salesClassification']) {
      salesCreateRequest.dynamicData.salesClassification = this.mainModel['salesClassification'];
    }
    if (this.mainModel['hireType']) {
      salesCreateRequest.dynamicData.hireType = this.mainModel['hireType'];
    }
    this.inClose = true;

    const mailNormalizer = (mailsStr) =>
      mailsStr
        ? mailsStr
            .trim()
            .split('\n')
            .map((v: string) => {
              const reverse = (str) =>
                str
                  .split('')
                  .reverse()
                  .join('');
              const reversed = reverse(v);
              return reverse(reversed.substring(reversed.indexOf('>') + 1, reversed.indexOf('<')).trim());
            })
        : [];
    const from = this.authService.loginUser.email;
    const to = mailNormalizer(this.mainModel['mail']['to']);

    this.salesApiService
      .create(salesCreateRequest)
      .pipe(
        tap((list) => {
          this.progressApiService.refreshEvent.emit();
        }),
      )
      .pipe(
        mergeMap((sales) => {
          if (sales && sales.id != null && this.mainModel && this.mainModel['mail'] && this.mainModel['mail']['to']) {
            return this.mailApiService
              .getMailTemplates()
              .pipe(
                mergeMap((templates) => {
                  const template = templates.find((mailTemplate) => mailTemplate.id === 22);
                  return this.mailApiService.replaceTemplate(template.mailTemplateTypeId, {
                    from: from,
                    to: to,
                    salesId: sales.id,
                    subject: template.subject,
                    text: template.body,
                    additionalReplacements: [{ label: '計上エビデンスフォルダパス', value: this.emailModel['email'] }],
                  });
                }),
              )
              .pipe(
                mergeMap((replaced) =>
                  this.mailApiService.sendSalesNotification({
                    userId: this.authService.loginUser.id,
                    subject: replaced.subject,
                    text: replaced.body,
                    from: from,
                    to: to,
                    salesId: sales.id,
                    cc: mailNormalizer(this.mainModel['mail']['cc']),
                  }),
                ),
              )
              .pipe(map(() => sales));
          } else {
            let content: string;
            if (!this.mainModel['mail'] || !this.mainModel['mail']['to']) {
              content = `求人にRAが登録されていないため、決定申請メールを送信しませんでした。`;
            } else if (!sales || !sales.id) {
              content = `成約データが取得できなかったため、決定申請メールを送信できませんでした。`;
            } else {
              content = `システムエラーが発生し、決定申請メールを送信できませんでした。`;
            }
            PopupControlComponent.instance.open({
              title: '決定申請メール',
              content: content,
              confirmText: 'OK',
              confirmCallback: () => false,
            });
            return EMPTY;
          }
        }),
      )
      .subscribe(
        (sales) => {
          this.inClose = false;
          this.close(sales);
        },
        (e) => {
          PopupControlComponent.instance.open({
            title: '決定申請メール',
            content: `エラーが発生しました。以下のメッセージをシステム管理者にお知らせください。\nメッセージ: ${JSON.stringify(e)}`,
            confirmText: 'OK',
            confirmCallback: () => false,
          });
          this.inClose = false;
        },
      );
  }

  close(param?: any): void {
    this.dialog.close(param);
  }

  private removeLocaleFromStringifiedNumber(str: string): string {
    return str != null ? str.toString().replace(new RegExp(',|/.', 'g'), '') : str;
  }
}
