import { ContactHistory, ContactHistoryAction, ContactHistoryEnterpriseAction } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import {
  AuthService,
  CompanyApiService,
  DynamicFieldService,
  MasterApiService,
  StudentApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { TableConfig } from '../page-scroll-table/table-interface';
import { PopupControlComponent } from '../popup-control/popup-control.component';
import { CONTACT_HISTORY_TABLE_CONFIG } from './contact-history-tab-table-config';

const DEFAULT_TRUNCATE_AT = 300;
const DEFAULT_TRUNCATE_LINES = 3;

@Component({
  selector: 'ag-contact-history',
  templateUrl: './contact-history.component.html',
  styleUrls: ['./contact-history.component.scss'],
})
export class ContactHistoryComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('responsible', { static: false }) responsibleTemplate: TemplateRef<any>;
  @ViewChild('message', { static: false }) messageTemplate: TemplateRef<any>;
  @ViewChild('contactHistoryForm', { static: false }) contactHistoryFormTemplate: TemplateRef<any>;
  @Input() service: StudentApiService | CompanyApiService;
  @Input() referenceId: number;
  @Output() errorGetContacts = new EventEmitter<HttpErrorResponse>();

  contacts: ContactHistory[];
  filteredContacts: ContactHistory[];
  tableConfig: TableConfig;
  private actionId = 0;
  validForm = false;

  actionTypes: any;
  contactHistoryActions: { label: string; value: number }[];

  model: ContactHistory = { message: '', userId: null, actionAt: new Date(), action: 1 };
  metadata: FormMeta = { groups: [] };

  truncateAt: { [key: number]: number | null } = {};
  defaultTruncateAt = DEFAULT_TRUNCATE_AT;
  defaultTruncateLines = DEFAULT_TRUNCATE_LINES;

  private addFn: (id: number, contactRequest: ContactHistory) => Observable<any>;
  private updateFn: (id: number, contactRequest: ContactHistory) => Observable<any>;
  private refreshEventSubscription: Subscription;

  constructor(
    private datePipe: SafeDatePipe,
    private dynamicFieldService: DynamicFieldService,
    private userService: UserApiService,
    private masterApiService: MasterApiService,
    private authService: AuthService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['service'] && this.service) {
      this.addFn = this.service.addContact.bind(this.service);
      this.updateFn = this.service.updateContact.bind(this.service);
      this.actionTypes = this.service instanceof StudentApiService ? ContactHistoryAction : ContactHistoryEnterpriseAction;
      this.contactHistoryActions = Object.keys(this.actionTypes)
        .filter((key: any) => isNaN(key))
        .map((key: string) => ({
          label: key,
          value: this.actionTypes[key],
        }));
    }
    if (changes['referenceId']) {
      this.getContacts();
    }
  }

  ngAfterViewInit() {
    this.tableConfig = CONTACT_HISTORY_TABLE_CONFIG(
      this.responsibleTemplate,
      this.actionsTemplate,
      this.messageTemplate,
      (date) => this.datePipe.transform(date, 'yyyy/MM/dd (E) HH:mm'),
      this.actionTypes,
    );
    this.dynamicFieldService.fieldUpdateEvent.subscribe(() => {
      this.metadata = {
        groups: [
          {
            class: 'ou',
            rows: [
              (() => {
                const row = this.dynamicFieldService.getFormRows(
                  {
                    fieldName: 'actionAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '対応日',
                    validationStyle: { required: true },
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
              {
                title: 'アクション',
                fields: [
                  {
                    type: 'dropdown',
                    name: 'action',
                    class: 'half tall',
                    options: this.contactHistoryActions,
                    labelField: 'label',
                    valueField: 'value',
                  },
                ],
              },
              ...this.dynamicFieldService.getFormRows(
                {
                  fieldName: 'userId',
                  fieldType: 'user',
                  label: '担当',
                  validationStyle: { required: true },
                },
                null,
                'three-quarter',
              ),
              {
                fields: [{ type: 'hr', name: 'hr' }],
              },
              (() => {
                const row = this.dynamicFieldService.getFormRows(
                  {
                    fieldName: 'nextContactAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '次回コンタクト日時',
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
            ],
          },
        ],
      };
    });
    this.refreshEventSubscription = this.service.refreshEvent.subscribe(() => this.getContacts());
  }

  ngOnDestroy() {
    if (this.refreshEventSubscription) {
      this.refreshEventSubscription.unsubscribe();
      this.refreshEventSubscription = null;
    }
  }

  isOpen(id: number): boolean {
    return this.truncateAt[id] === null;
  }

  isTruncated(text: string): boolean {
    return text && (text.length > this.defaultTruncateAt || text.split('\n').length > DEFAULT_TRUNCATE_LINES);
  }

  toggleText(id: number): void {
    this.truncateAt[id] = this.truncateAt[id] !== null ? null : this.defaultTruncateAt;
  }

  findUserTeam(userId: number): Observable<{ userName: string; teamName: string }> {
    return this.userService.getAll().pipe(
      mergeMap((users) => {
        const user = users.find((u) => u.id === userId);
        if (user) {
          return this.masterApiService.getTeams().pipe(
            map((teams) => {
              const team = teams.find((t) => t.id === user.teamId);
              if (team) {
                return { userName: user.name, teamName: team.name };
              } else {
                return { userName: user.name, teamName: '' };
              }
            }),
          );
        }
        return null;
      }),
    );
  }

  getContacts(actionId?: string): void {
    if (this.referenceId) {
      if (actionId != null) {
        this.actionId = Number.parseInt(actionId, 10);
        this.filteredContacts = this.actionId ? this.contacts.filter((contact) => contact.action === this.actionId) : this.contacts;
      } else {
        this.contacts = [];
        this.service.getContacts(this.referenceId).subscribe(
          (contacts: ContactHistory[]) => {
            this.contacts = contacts;
            this.filteredContacts = this.actionId ? this.contacts.filter((contact) => contact.action === this.actionId) : this.contacts;
          },
          (error: HttpErrorResponse) => {
            this.errorGetContacts.emit(error);
          },
        );
      }
    }
  }

  addContact(): void {
    const actionCallback = this.addFn;
    this.model = { message: '', userId: this.authService.loginUser.id, actionAt: new Date(), action: 1 };
    this.onShowForm(actionCallback);
  }

  updateContact(contact: ContactHistory): void {
    const actionCallback = this.updateFn;
    this.model = { ...contact };
    delete this.model.user;
    delete this.model.team;
    this.onShowForm(actionCallback);
  }

  deleteContact(contactId: number): void {
    PopupControlComponent.instance.open({
      title: '対応履歴の削除',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: 'OK',
      cancelText: 'キャンセル',
      width: '600px',
      height: '357px',
      confirmCallback: () => {
        return this.service.deleteContact(this.referenceId, contactId).pipe(
          tap(() => {
            this.contacts.splice(this.contacts.findIndex((contact) => contact.id === contactId), 1);
          }),
        );
      },
    });
  }

  private onShowForm(actionCallback: (id: number, contactRequest: ContactHistory) => Observable<any>) {
    PopupControlComponent.instance.open({
      title: '対応履歴の登録',
      content: this.contactHistoryFormTemplate,
      confirmText: '登録',
      confirmEnabled: () => this.validForm,
      confirmCallback: () => {
        this.validForm = false;
        return actionCallback(this.referenceId, this.model).pipe(
          tap(() => {
            this.getContacts();
          }),
        );
      },
      cancelText: 'キャンセル',
    });
  }
}
