export enum CompanyTabs {
  OVERVIEW,
  DETAIL,
  SECTIONS,
  CONTRACTS,
  PROGRESSES,
  SALES,
  FILES,
  JOBS,
  CONTACTS,
  MESSAGE,
  MEMO,
}

export enum CompanyTabsForIntakeCa {
  OVERVIEW,
  DETAIL,
  JOBS,
  MEMO,
}
