/**
 * ITware dev. environment
 * This dev environment is using a private server in ITware as a tunnel,
 * to access the dev-server at https://dev-api.mach.asial-project.com
 */
export const environment = {
  production: false,
  apiUrl: 'https://api.dev.mach.doda.jp',
  rpaUserIds: [375],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
