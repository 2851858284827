export enum JobTabs {
  OVERVIEW,
  DETAIL,
  PROGRESSES,
  JOB_SETTING_MAIL,
  SALES,
  MEMO,
}

export enum JobTabsForIntakeCa {
  OVERVIEW,
  DETAIL,
  JOB_SETTING_MAIL,
  MEMO,
}
