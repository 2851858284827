import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { DataFile } from '@agent-ds/shared/interfaces';
import { AuthService, DialogService } from '@agent-ds/shared/services';
import { FileApiService } from '@agent-ds/shared/services/api/file-api.service';
import { getValueFromObject } from '@agent-ds/shared/util/util';
// tslint:disable-next-line:max-line-length
import { StudentConfirmFileDialogComponent } from '@agent-ds/student/components/student-confirm-file-dialog/student-confirm-file-dialog.component';
// tslint:disable-next-line:max-line-length
import {
  StudentRenameFileDialogComponent,
  StudentRenameFileDialogConfig,
} from '@agent-ds/student/components/student-rename-file-dialog/student-rename-file-dialog.component';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '../dialog/dialog-config';
import { FileUploadDialogFileData } from '../file-upload-dialog/file-upload-dialog-interface';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { PopupControlComponent } from '../popup-control/popup-control.component';

@Component({
  selector: 'ag-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnChanges {
  @Input() model: 'students' | 'enterprise' = 'students';
  @Input() reference: any;
  ENTERPRISE_SEND_FLAG_OPTION = '送信可';
  readonly STUDENT_SEND_FLAG_OPTION = '求職者へ送信可';
  private referenceId: number;

  constructor(
    public readonly dialogService: DialogService,
    private readonly fileService: FileApiService,
    private readonly authService: AuthService,
  ) {}

  public files: DataFile[] = [];

  private failedFiles: {
    file: File;
    fileId?: number;
    enterpriseSendFlag: number;
    studentSendFlag: number;
  }[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.ENTERPRISE_SEND_FLAG_OPTION = this.model === 'students' ? '送信可' : '企業へ送信可';
    }
    if (changes['reference']) {
      this.referenceId = this.reference ? this.reference.id : null;
      this.getFiles();
    }
  }

  downloadFile(file: DataFile) {
    this.fileService.downloadFile(this.model, this.referenceId, file.id, file.name);
  }

  getFiles(): void {
    if (this.referenceId) {
      this.files = [];
      // cacheinterceptorでレスポンスのハンドリングが実装されているため、エラー処理はここで実装できない
      this.fileService.getFiles(this.model, this.referenceId).subscribe((files: DataFile[]) => (this.files = files));
    }
  }

  uploadFiles(): void {
    this.failedFiles.length = 0;
    const config: DialogConfig<{ options: string[] }> = {
      data: {
        options:
          this.model === 'students'
            ? [this.ENTERPRISE_SEND_FLAG_OPTION]
            : [this.ENTERPRISE_SEND_FLAG_OPTION, this.STUDENT_SEND_FLAG_OPTION],
      },
    };
    this.dialogService.open(FileUploadDialogComponent, config, (filesData: FileUploadDialogFileData[]) => {
      if (filesData && filesData.length) {
        return forkJoin(
          filesData.map((fileData) =>
            this.uploadFile(
              fileData.file,
              fileData.options[this.ENTERPRISE_SEND_FLAG_OPTION] ? 1 : 0,
              fileData.options[this.STUDENT_SEND_FLAG_OPTION] ? 1 : 0,
            ),
          ),
        ).pipe(
          tap((res) => {
            if (this.failedFiles.length) {
              PopupControlComponent.instance.open({
                title: '上書き確認',
                content: '同じファイル名のファイルがすでに存在します。上書きしてもよろしいですか?',
                confirmText: 'はい',
                cancelText: 'いいえ',
                cancelCallback: () => this.getFiles(),
                confirmCallback: () => {
                  return forkJoin(
                    this.failedFiles.map((f) => this.uploadFile(f.file, f.enterpriseSendFlag, f.studentSendFlag, f.fileId)),
                  ).pipe(tap(() => this.getFiles()));
                },
              });
            } else {
              this.getFiles();
            }
          }),
        );
      }
    });
  }

  changeToSendable(file: DataFile): void {
    const config: ConfirmDialogConfig = {
      title: '送信可に変更',
      messages: [`「${file.name}」を「送信可」にします。`, '既存の「送信可」のファイルは上書きされます。よろしいですか？'],
      style: {
        height: '357px',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config, (result: boolean) => {
      if (result) {
        this.fileService.changeToSendable(this.reference.id, file.id).subscribe((updated) => {
          file.name = updated.name;
          file.enterpriseSendFlag = updated.enterpriseSendFlag;
          file.studentSendFlag = updated.studentSendFlag;
          file.studentUploadedFlag = updated.studentUploadedFlag;
        });
      }
    });
  }

  registerFile(file: DataFile): void {
    this.fileService.openFile(this.model, this.referenceId, file.id, file.name);
    const config: DialogConfig<{ filename: string }> = {
      data: {
        filename: file.name,
      },
    };
    this.dialogService.open(StudentConfirmFileDialogComponent, config, (action: 'resubmit' | 'confirm') => {
      if (action === 'resubmit') {
        this.requestResubmit(file);
      } else if (action === 'confirm') {
        this.openRenameFileDialog(file);
      }
    });
  }

  deleteFile(fileId: number): void {
    const config: ConfirmDialogConfig = {
      title: 'データの削除',
      messages: ['削除されたデータを元に戻すことはできません。', 'データを削除してもよろしいですか？'],
      style: {
        height: '357px',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: '確定',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config, (result: boolean) => {
      if (result) {
        return this.fileService.deleteFile(this.model, this.referenceId, fileId).pipe(
          tap(() => {
            this.files.splice(this.files.findIndex((file: DataFile) => file.id === fileId), 1);
          }),
        );
      }
    });
  }

  private uploadFile(file: File, enterpriseSendFlag: number, studentSendFlag: number, fileId?: number): Observable<any> {
    // keep this to make testing easier
    // const parts = file.name.split('.');
    // const ext = parts.length > 1 ? parts.pop() : '';
    // const name = `${parts.join('.')}_${this.reference.dynamicData.lastName}${this.reference.dynamicData.firstName}.${ext}`;
    const name = file.name;
    return this.fileService.uploadFile(this.model, this.referenceId, file, name, fileId, enterpriseSendFlag, studentSendFlag).pipe(
      catchError((error) => {
        if (error.status === 431) {
          const id = (this.files.find((f) => f.name.includes(name)) || { id: null }).id;
          if (id) {
            this.failedFiles.push({ file: file, enterpriseSendFlag: enterpriseSendFlag, studentSendFlag: studentSendFlag, fileId: id });
          }
        } else if (error.status === 480) {
          alert('ウィルスが検出されました。ファイルの内容を確認してください');
        }
        return of('handled');
      }),
    );
  }

  private requestResubmit(file: DataFile): void {
    if (this.authService.authInfo.zone !== 'zone3') {
      PopupControlComponent.instance.open({
        title: null,
        content: 'ZONE3専用です。',
        confirmText: 'OK',
        confirmCallback: () => false,
      });
      return;
    }
    const config = new StudentSendConfig();
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      student: {
        id: this.reference.id,
        frontId: this.reference.frontId,
        firstName: this.reference.dynamicData.firstName,
        lastName: this.reference.dynamicData.lastName,
        age: null,
        prefecture: null,
        schoolName: null,
        schoolDepartmentName: null,
        studentUser: this.reference.studentUsers,
        emailMain: getValueFromObject(this.reference, 'dynamicData.emailMain.email'),
        emailMainAvailable: getValueFromObject(this.reference, 'dynamicData.emailMain.emailAvailable'),
        emailSub: getValueFromObject(this.reference, 'dynamicData.emailSub.email'),
        emailSubAvailable: getValueFromObject(this.reference, 'dynamicData.emailSub.emailAvailable'),
      },
    });
    MailSendFlowComponent.instance.start();
  }

  private openRenameFileDialog(file: DataFile): void {
    const config: DialogConfig<StudentRenameFileDialogConfig> = {
      data: {
        file: file,
        student: this.reference,
      },
    };
    this.dialogService.open(StudentRenameFileDialogComponent, config, (updated?: DataFile) => {
      if (updated) {
        file.name = updated.name;
        file.studentUploadedFlag = updated.studentUploadedFlag;
      }
    });
  }
}
