import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@agent-ds/shared/services';
import { map } from 'rxjs/operators';

export enum USAGE_MODE {
  APP,
  OUTLOOK,
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public static readonly CURRENT_USAGE: USAGE_MODE = window.location.href.includes('mailerIntegration')
    ? USAGE_MODE.OUTLOOK
    : USAGE_MODE.APP;
  private static REQUESTED_PATH: string;
  public static get REQUESTED_URL(): string {
    return AuthGuard.REQUESTED_PATH;
  }
  public static set REQUESTED_URL(url: string) {
    AuthGuard.REQUESTED_PATH = !url || url.endsWith('login') ? '' : url;
  }

  constructor(private authService: AuthService, private router: Router) {
    this.authService.isLogined().subscribe((logined) => {
      if (!logined) {
        AuthGuard.REQUESTED_URL = AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-mail' : this.router.url;
        this.router.navigateByUrl((AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-' : '') + 'login');
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const isOutlook = state.url.includes('mailerIntegration');
    if ((AuthGuard.CURRENT_USAGE === USAGE_MODE.APP && isOutlook) || (AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK && !isOutlook)) {
      return false;
    }
    return this.authService.isLogined().pipe(
      map((allowed) => {
        const isLogin = state.url.match(new RegExp(/^.*login#?\/?$/));
        if (!allowed) {
          if (isLogin) {
            return true;
          }
          AuthGuard.REQUESTED_URL = state.url;
          this.router.navigateByUrl((AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-' : '') + 'login');
          return false;
        } else if (isLogin) {
          this.router.navigateByUrl(AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-mail' : '');
          return false;
        }
        return allowed;
      }),
    );
  }
}
