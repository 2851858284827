import { EventEmitter } from '@angular/core';

export abstract class Tab {
  private innerSelectedIndex: number;
  readonly gotoIndex = new EventEmitter<number>();
  tabIndex: number;

  public set selectedIndex(index: number) {
    if (index === this.tabIndex && this.innerSelectedIndex !== this.tabIndex) {
      this.switchIn();
    } else if (index !== this.tabIndex && this.innerSelectedIndex === this.tabIndex) {
      this.switchOut();
    }
  }

  protected switchIn(): void {}

  protected switchOut(): void {}

  close(): void {}
}
