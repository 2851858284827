import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * ContentType: application/json 設定HTTPインターセプター
 */
@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  /**
   * Content-Type: application/json 設定処理
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body instanceof FormData) {
      return next.handle(req);
    }
    return next.handle(
      req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      }),
    );
  }
}
