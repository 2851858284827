import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { TemplateRef } from '@angular/core';

export const STUDENT_LIST_TABLE_CONFIG: (
  imgTemplate: TemplateRef<any>,
  basicTemplate: TemplateRef<any>,
  messageTemplate: TemplateRef<any>,
  schoolTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  inChargeTemplate: TemplateRef<any>,
  ageGenderTemplate: TemplateRef<any>,
  registrationStatusTemplate: TemplateRef<any>,
  dateFormat: (date: any) => string,
) => TableConfig = (
  imgTemplate,
  basicTemplate,
  messageTemplate,
  schoolTemplate,
  actionsTemplate,
  inChargeTemplate,
  ageGenderTemplate,
  registrationStatusTemplate,
  dateFormat,
) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'rank',
                title: 'ランク',
                cellTemplate: imgTemplate,
              },
            ],
            style: {
              width: '74px',
            },
          },
          {
            fields: [
              {
                name: 'id',
                title: '求職者ID',
                formatter: (data) => data.frontId,
                style: {
                  color: '#9F9F9F',
                },
              },
              {
                name: 'name',
                title: '氏名',
                style: {
                  color: '#007BFF',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '16px',
                  overflow: 'hidden',
                  'text-overflow': 'ellipsis',
                  'white-space': 'nowrap',
                },
                formatter: (data) => data.dynamicData.lastName + ' ' + data.dynamicData.firstName,
              },
              {
                name: 'age',
                hidden: true,
                cellTemplate: ageGenderTemplate,
              },
              {
                name: 'message',
                hidden: true,
                cellTemplate: messageTemplate,
              },
            ],
            style: {
              width: '185px',
            },
          },
          {
            fields: [
              {
                name: 'lastContactDate',
                title: '最終コンタクト日',
                defaultSort: SortOrder.DESCENDING,
                formatter: (data) => dateFormat(data.lastContactedAt),
              },
              {
                name: 'mypageLoginDate',
                title: 'マイページログイン日',
                formatter: (data) => dateFormat(data.mypageLoggedInAt),
              },
            ],
            style: {
              width: '123px',
              'font-size': '11px',
            },
            bodyStyle: {
              height: '70px',
              'font-size': '12px',
            },
          },
          {
            fields: [
              {
                name: 'registrationStatus',
                title: '登録\nステータス',
                cellTemplate: registrationStatusTemplate,
              },
            ],
            style: {
              width: '103px',
              'font-size': '11px',
              'line-height': '12px',
            },
            bodyStyle: {
              height: '70px',
              'font-size': '14px',
            },
          },
          {
            fields: [
              {
                name: 'basic_info',
                title: '基本情報',
                cellTemplate: basicTemplate,
                sortable: false,
              },
            ],
            style: { width: '158px' },
            bodyStyle: { height: '70px' },
          },
          {
            fields: [
              {
                name: 'school_info',
                title: '学校情報',
                cellTemplate: schoolTemplate,
                sortable: false,
              },
            ],
            style: {
              width: '232px',
            },
            bodyStyle: {
              height: '70px',
            },
          },
          {
            fields: [
              {
                name: 'progressCount',
                title: '進捗件数',
                style: {
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '14px',
                  'text-align': 'center',
                },
                sortable: false,
              },
              {
                name: 'activeCount',
                title: '稼働件数',
                style: {
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '14px',
                  'text-align': 'center',
                },
                sortable: false,
              },
            ],
            style: {
              width: '80px',
              'line-height': '12px',
              'font-size': '11px',
            },
            bodyStyle: {
              height: '70px',
            },
          },
          {
            fields: [
              {
                name: 'in_charge',
                title: '担当者',
                sortable: false,
                cellTemplate: inChargeTemplate,
              },
            ],
            style: {
              width: '184px',
            },
            bodyStyle: {
              height: '70px',
            },
          },
          {
            fields: [
              {
                name: 'action',
                title: 'アクション',
                cellTemplate: actionsTemplate,
                style: {
                  'justify-content': 'flex-end',
                  display: 'flex',
                  'align-items': 'center',
                },
                sortable: false,
              },
            ],
            style: {
              flex: 1,
              'padding-right': '85px',
              'padding-left': '10px',
              'justify-content': 'flex-end',
              display: 'flex',
              'align-items': 'center',
              'flex-direction': 'row',
            },
            bodyStyle: {
              'padding-right': '20px',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: true,
    arrow: true,
    style: {
      height: '105px',
    },
  },
});
