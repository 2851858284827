import { StudentJobMatchingPageComponent } from '@agent-ds/matching/pages/student-job-matching-page/student-job-matching-page.component';
import { Job, Student } from '@agent-ds/shared/interfaces';
import { AgePipe } from '@agent-ds/shared/pipes/age.pipe';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, MasterApiService, StudentApiService, UserApiService } from '@agent-ds/shared/services';
import { cleanObject, getValueFromObject } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { StudentCreateDialogComponent } from '@agent-ds/student/components/student-create-dialog/student-create-dialog.component';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { StudentTabs } from '@agent-ds/student/pages/student-detail-page/tabs/student-tabs';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogRef } from '../dialog/dialog-ref';
import { MailSendFlowComponent } from '../mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '../mail-send/config';
import { ProgressBulkConfig } from '../mail-send/configs/progress-bulk-config';
import { StudentBulkConfig } from '../mail-send/configs/student-bulk-config';
import { StudentJobConfig } from '../mail-send/configs/student-job-config';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { BatchResult } from '../page-scroll-table/table-interface';
import { STUDENT_LIST_TABLE_CONFIG } from './student-list-with-todays-counseling-table-config';

@Component({
  selector: 'ag-student-list-with-todays-counseling',
  templateUrl: './student-list-with-todays-counseling.component.html',
  styleUrls: ['./student-list-with-todays-counseling.component.scss'],
})
export class StudentListWithTodaysCounselingComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('img', { static: false }) imgTemplate: TemplateRef<any>;
  @ViewChild('general', { static: false }) generalTemplate: TemplateRef<any>;
  @ViewChild('registrationStatus', { static: false }) registrationStatusTemplate: TemplateRef<any>;
  @ViewChild('basic', { static: false }) basicTemplate: TemplateRef<any>;
  @ViewChild('message', { static: false }) messageTemplate: TemplateRef<any>;
  @ViewChild('school', { static: false }) schoolTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('inCharge', { static: false }) inChargeTemplate: TemplateRef<any>;

  @Input() requestObject = {};
  @Input() options: any = {};
  @Input() extraControls: TemplateRef<any>;
  @Input() job: Job;
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();

  tabs = StudentTabs;
  initialized = false;
  bulkSendConfig = new StudentBulkConfig();
  jobSendConfig = new StudentJobConfig();
  progressBulkSendConfig = new ProgressBulkConfig();

  private dialogRef: DialogRef;

  get checkedItems(): Student[] {
    return this.table ? this.table.checkedItems : [];
  }

  get allChecked(): boolean {
    return this.table.allChecked;
  }

  get totalSize(): number {
    return this.table.totalSize;
  }

  private refreshSubscription: Subscription;

  constructor(
    private studentApiService: StudentApiService,
    private cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private datePipe: SafeDatePipe,
    public readonly masterService: MasterApiService,
    public readonly userService: UserApiService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    this.refreshSubscription = this.studentApiService.refreshEvent.subscribe(() => this.ngOnChanges());
    if (!this.options.sort) {
      this.options.sort = { field: 'updatedAt', order: 'desc' };
    }
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
      this.cdr.detectChanges();
      if (this.table && this.initialized) {
        this.table.reset(true);
        this.table.next();
      }
    }
  }

  ngAfterViewInit() {
    this.table.tableConfig = STUDENT_LIST_TABLE_CONFIG(
      this.imgTemplate,
      this.basicTemplate,
      this.messageTemplate,
      this.schoolTemplate,
      this.actionsTemplate,
      this.inChargeTemplate,
      this.generalTemplate,
      this.registrationStatusTemplate,
      (date: any) => this.datePipe.transform(date, 'yyyy/MM/dd(E)'),
    );

    this.cdr.detectChanges();
    this.initialized = true;
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  onItemSelected(selectedItem): void {
    StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
    StudentDetailPageComponent.instance.open();
  }

  onItemChecked(checkedItemEvent): void {
    this.itemChecked.emit(checkedItemEvent);
  }

  onStudentLinkClick(event: MouseEvent, student: Student, tab: string): void {
    event.stopPropagation();
    if (tab === this.tabs[this.tabs.MATCHES]) {
      StudentJobMatchingPageComponent.instance.referenceId = student.id;
      StudentJobMatchingPageComponent.instance.open();
      // this.router.navigate(['/matching/student-job/', student.id]);
    } else {
      StudentDetailPageComponent.instance.tabCode = tab;
      this.onItemSelected(student);
    }
  }

  public openBatchUpdateDialog() {
    this.dialog.open(BatchUpdateDialogComponent, {
      data: {
        // condition: this.table.allChecked
        //   ? cleanObject({
        //       ...this.requestObject,
        //       from: 0,
        //       size: this.table.totalSize,
        //     })
        //   : undefined,
        studentIds: /* this.table.allChecked ? undefined : */ this.table.checkedItems.map((item) => item.id),
      },
    });
  }

  openMailSend(config: MailSendConfig): void {
    const params = {
      students: /* !this.table.allChecked || config !== this.bulkSendConfig
          ? */ this.table.checkedItems.map((s: Student) => ({
        id: s.id,
        frontId: s.frontId,
        emailMain: getValueFromObject(s.dynamicData, 'emailMain.email'),
        emailMainAvailable: getValueFromObject(s.dynamicData, 'emailMain.emailAvailable'),
        emailSub: getValueFromObject(s.dynamicData, 'emailSub.email'),
        emailSubAvailable: getValueFromObject(s.dynamicData, 'emailSub.emailAvailable'),
        studentUser: s.studentUser,
        firstName: s.dynamicData.firstName,
        lastName: s.dynamicData.lastName,
      })),
      // : null,
      jobs: this.job
        ? [
            {
              id: this.job.id,
              frontId: this.job.frontId,
              position: getValueFromObject(this.job.dynamicData, 'position'),
              enterpriseName: this.job.enterpriseName,
              enterpriseId: this.job.enterpriseId,
              frontEnterpriseId: this.job.frontEnterpriseId,
              enterpriseDepartmentId: this.job.enterpriseDepartmentId,
              jobUsers: this.job.enterpriseDepartmentUsers,
            },
          ]
        : null,
      // condition: this.table.allChecked && config === this.bulkSendConfig ? this.requestObject : null,
      jobId: this.job ? this.job.id : null,
      enterprises: this.job ? [{ id: this.job.enterpriseId, name: this.job.enterpriseName, frontId: this.job.frontEnterpriseId }] : [],
    };
    MailSendFlowComponent.instance.config = config;
    config.setParams(params);
    MailSendFlowComponent.instance.start();
  }

  loadList = (page: number, size: number, sort?: string, order?: string): Observable<BatchResult> => {
    const params = cleanObject({
      ...this.requestObject,
      from: page * size,
      size: size,
      sort: sort,
      order: order,
    });

    return this.studentApiService.getList(params).pipe(map((res: any) => this.convertStudentList(res)));
  };

  private convertStudentList(res: any): BatchResult {
    // default result
    const result = {
      result: [],
      totalSize: res && res.total ? res.total : 0,
    };

    // map all elements to make object root to _source property
    if (res && res.students && res.students.length > 0) {
      result.result = res.students;
    }

    return result;
  }
}
