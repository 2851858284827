import { Directive, DoCheck, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[agClass]',
})
export class ClassDirective implements OnChanges {
  protected elClasses: string[] = [];

  constructor() {}

  @Input('class')
  @HostBinding('class')
  get clazz(): string {
    return this.elClasses.join(' ');
  }
  set clazz(val: string) {
    this.elClasses = val.split(' ');
  }

  @Input('agClass') value: string;
  @Input() classOptions: { [key: string]: string };

  ngOnChanges(): void {
    // unset each configured classes first
    this.elClasses = this.elClasses.filter((c) => {
      for (const value in this.classOptions) {
        if (this.classOptions[value] === c) {
          return false;
        }
      }
      return true;
    });

    // re-add the class vich has a matching value
    for (const valueLiteral in this.classOptions) {
      if (this.value && this.value.toString() === valueLiteral) {
        this.elClasses.push(this.classOptions[valueLiteral]);
        return;
      }
    }

    // if none of the values match, check if there is a default
    if (this.classOptions['default']) {
      this.elClasses.push(this.classOptions['default']);
    }
  }
}
