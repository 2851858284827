import { Company } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DynamicFieldService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-company-detail-tab',
  templateUrl: './company-detail-tab.component.html',
  styleUrls: ['./company-detail-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyDetailTabComponent) }],
})
export class CompanyDetailTabComponent extends Tab implements OnInit, OnChanges {
  @Input() company: Company;

  private validityArray = [true, true, true];
  validity = false;

  metadataHead: FormMeta;
  metadataLeft: FormMeta;
  metadataRight: FormMeta;
  metadataCustomFields: FormMeta;

  constructor(
    private dynamicService: DynamicFieldService,
    private companyService: CompanyApiService,
    private datePipe: SafeDatePipe,
    public readonly userTeamProvider: UserTeamInjectorProvider,
  ) {
    super();
  }

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.fieldInit();
    });
    this.validity = false;
  }

  ngOnChanges(changes: SimpleChanges) {}

  fieldInit(): void {
    this.metadataHead = {
      groups: [
        {
          class: 'oneliner',
          rows: [
            {
              title: '企業ID',
              fields: [
                {
                  type: 'label',
                  name: 'frontId',
                },
              ],
            },
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseStatus'), 'dynamicData'),
            {
              title: '更新者',
              class: 'fill',
              fields: [
                {
                  type: 'label',
                  name: 'updateUserId',
                  supplier: () => (this.company ? this.userTeamProvider.getUserTeamNameById(this.company.updateUserId) : '-'),
                },
              ],
            },
            {
              title: '登録日',
              fields: [
                {
                  type: 'label',
                  name: 'createdAt',
                  supplier: (val) => this.datePipe.transform(val),
                },
              ],
            },
            {
              title: '更新日',
              fields: [
                {
                  type: 'label',
                  name: 'updatedAt',
                  supplier: (val) => this.datePipe.transform(val),
                },
              ],
            },
          ],
        },
      ],
    };
    this.metadataLeft = {
      groups: [
        {
          title: '企業概要1',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'name'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'phoneticName'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseNo')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyClassification')),
          ],
        },
        {
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagement'), null, 'three-quarter'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagementRemarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'agClientStatus')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'rininKRank')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drClientStatus')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drAgreement')),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'hasDr'))[0];
              const textOne = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'drRegisteredEmail'),
                null,
                'full ou',
              )[0];
              const textTwo = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'recruitmentWindow'),
                null,
                'full ou',
              )[0];
              textOne.fields[0].labelBefore = textOne.title;
              textOne.fields[0].validators.required = true;
              textTwo.fields[0].labelBefore = textTwo.title;
              textOne.fields[0].allowOn = textTwo.fields[0].allowOn = { hasDr: '有' };
              row.fields.push(textOne.fields[0], textTwo.fields[0]);
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drOfferAgency')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'plusUsage')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drLandingRoute')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'fairContract')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'gpsContract')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'tokuha')),
          ],
        },
        {
          title: '企業概要2',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'industry'), null, 'full'),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'capital'), null, 'third')[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'capitalVisibility'))[0].fields[0],
              );
              return row;
            })(),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesAmount'), null, 'third')[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesVisibility'))[0].fields[0],
              );
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesGroupAmount'))[0].fields[0],
              );
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesDate')),
            (() => {
              const row = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'numberEmployees'),
                null,
                'third',
              )[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'numberGroupEmployees'))[0].fields[0],
              );
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'establishDate')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'marketName')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'url'), null, 'fill'),
            ...this.dynamicService.getFormRows(
              this.dynamicService.getDefinition('enterprise', 'numberEmployeesNonConsolidated'),
              null,
              'fill',
            ),
          ],
        },
        {
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentTitle'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentName'), null, 'fill'),
          ],
        },
        {
          title: '本社所在地',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'branches'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'address')),
          ],
        },
      ],
    };
    this.metadataRight = {
      groups: [
        {
          title: '担当',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows({
              fieldName: 'userIds',
              fieldType: 'multi-user',
              label: '担当者',
              validationStyle: { required: true, max: 5 },
            }),
          ],
        },
        {
          title: 'その他',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'recommendDescription'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'remarks'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'genderInfo'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'resumePassword'), 'dynamicData'),
          ],
        },
        {
          title: '企業概要3',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyInfo'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyStrength'), 'dynamicData'),
          ],
        },
        {
          title: '8SEGs',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8LastYear'), 'dynamicData', 'full'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8CurrentYear'), 'dynamicData', 'full'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8NextYear'), 'dynamicData', 'full'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8Confirm'), 'dynamicData'),
          ],
        },
      ],
    };
    const customs = this.dynamicService.getDefinitions('enterprise').filter((def) => def.isCustomField);
    if (customs.length) {
      this.metadataCustomFields = {
        groups: [
          {
            title: 'カスタムフィールド',
            class: 'form__group--red-title-border',
            rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
          },
        ],
      };
    }
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  save(): void {
    this.companyService.update(this.company.id, this.company).subscribe((res) => this.companyService.refreshEvent.emit());
  }
}
