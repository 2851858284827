import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'ag-ordered-list',
  templateUrl: './ordered-list.component.html',
  styleUrls: ['./ordered-list.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OrderedListComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => OrderedListComponent), multi: true },
  ],
})
export class OrderedListComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  private static readonly dragElements = [];
  private static dragStepRef: OrderedListComponent;
  private static dragCopy: boolean;

  @Input() labelField?: string;
  @Input() valueField?: string;
  @Input() readonly?: boolean;
  @Input() validators?: { [key: string]: any };
  @Output() valueChange = new EventEmitter<any[]>();

  private valueInner: any[] = [];
  private alive = true;
  selectedItems = [];
  firstSelectedItem: any;

  private propagateChange: any = () => this.valueChange.emit(this.value);
  private propagateTouch: any = () => false;

  get value(): any[] {
    return this.valueInner;
  }

  @Input()
  set value(value: any[]) {
    this.valueInner = value || [];
    this.cdr.detectChanges();
  }

  constructor(private host: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.alive = false;
    this.propagateChange = this.propagateTouch = () => false;
  }

  onItemClick(event: MouseEvent, value: any): void {
    if (!event.ctrlKey && !event.shiftKey) {
      this.selectedItems.length = 0;
      this.firstSelectedItem = value;
    }
    if (event.shiftKey) {
      const firstIdx = this.value.indexOf(this.firstSelectedItem);
      const valIdx = this.value.indexOf(value);
      this.selectedItems.length = 0;
      if (firstIdx > valIdx) {
        this.value.forEach((item, index) => {
          if (index <= firstIdx && index >= valIdx) {
            this.selectedItems.push(item);
          }
        });
      } else if (firstIdx < valIdx) {
        this.value.forEach((item, index) => {
          if (index >= firstIdx && index <= valIdx) {
            this.selectedItems.push(item);
          }
        });
      } else {
        this.selectedItems.push(value);
      }
    } else if (this.selectedItems.includes(value)) {
      this.selectedItems.remove(value);
    } else {
      this.selectedItems.push(value);
    }
    this.cdr.detectChanges();
  }

  onDragStart(event: DragEvent, item: any): void {
    event.dataTransfer.clearData();
    // if (event.ctrlKey) {
    OrderedListComponent.dragCopy = true;
    // }
    OrderedListComponent.dragStepRef = this;
    if (this.selectedItems.length) {
      OrderedListComponent.dragElements.push(...this.selectedItems);
    } else {
      OrderedListComponent.dragElements.push(item);
    }
  }

  onDragEnd(event: DragEvent): void {
    if (!OrderedListComponent.dragCopy && OrderedListComponent.dragStepRef !== this && !this.readonly) {
      OrderedListComponent.dragElements.forEach((item) => this.value.remove(item));
      this.propagateTouch();
      this.propagateChange();
    }
    OrderedListComponent.dragElements.length = 0;
    OrderedListComponent.dragStepRef = null;
    OrderedListComponent.dragCopy = false;
    this.cdr.detectChanges();
  }

  onDrop(event: DragEvent): void {
    if (OrderedListComponent.dragStepRef !== this && !this.readonly) {
      OrderedListComponent.dragStepRef = this;
      OrderedListComponent.dragElements.forEach((item) => this.value.include(item));
      this.propagateTouch();
      this.propagateChange();
      this.cdr.detectChanges();
    }
  }

  onUp(): void {
    this.value.forEach((item, itemIdx) => {
      if (this.selectedItems.includes(item)) {
        let replaceIdx = -1;
        for (replaceIdx = itemIdx - 1; replaceIdx > -1; replaceIdx--) {
          if (!this.selectedItems.includes(this.value[replaceIdx])) {
            break;
          }
        }
        if (replaceIdx > -1) {
          const replaced = this.value[replaceIdx];
          this.value[replaceIdx] = item;
          this.value[itemIdx] = replaced;
        }
      }
    });
    this.cdr.detectChanges();
    this.propagateTouch();
    this.propagateChange();
  }

  onDown(): void {
    for (let itemIdx = this.value.length - 1; itemIdx > -1; itemIdx--) {
      if (this.selectedItems.includes(this.value[itemIdx])) {
        let replaceIdx = this.value.length;
        for (replaceIdx = itemIdx + 1; replaceIdx < this.value.length; replaceIdx++) {
          if (!this.selectedItems.includes(this.value[replaceIdx])) {
            break;
          }
        }
        if (replaceIdx < this.value.length) {
          const replaced = this.value[replaceIdx];
          this.value[replaceIdx] = this.value[itemIdx];
          this.value[itemIdx] = replaced;
        }
      }
    }
    this.cdr.detectChanges();
    this.propagateTouch();
    this.propagateChange();
  }

  writeValue(value: any[]): void {
    this.value = value || [];
  }
  registerOnChange(fn: any): void {
    this.propagateChange = () => {
      fn(this.value);
      this.valueChange.emit(this.value);
    };
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.cdr.detectChanges();
  }
  validate(c: FormControl): ValidationErrors {
    if (!this.alive) {
      return null;
    }
    const valid = {};
    if (this.validators) {
      Object.keys(this.validators).forEach((key) => {
        const ret = typeof this.validators[key] === 'function' ? this.validators[key](c) : null;
        if ((key === 'required' && this.validators[key] && !this.value.length) || ret != null) {
          valid[key] = ret || true;
        }
      });
    }
    return Object.keys(valid).length ? valid : null;
  }
}
