import { FormMeta } from '@agent-ds/shared/models';
import { AuthService, DialogService } from '@agent-ds/shared/services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../dialog/dialog-ref';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'ag-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.scss'],
})
export class PasswordChangeDialogComponent implements OnInit {
  constructor(public readonly authService: AuthService, public readonly dialogService: DialogService, public readonly dialog: DialogRef) {}

  static readonly errorMessages: { [key: string]: string } = {
    newPasswordMatch: '新しいパスワードと新しいパスワード（確認）が一致しません。',
    oldPasswordMatch: '現在のパスワードと現在のパスワード（確認）が一致しません。',
    passwordReuse: '新しいパスワードは現在のパスワードと異なる値を設定してください。',
  };
  @ViewChild(DynamicFormComponent, { static: false }) form: DynamicFormComponent;

  pwChangeMeta: FormMeta = {
    groups: [],
  };

  readonly credentials: {
    oldPassword?: string;
    oldPassword2?: string;
    newPassword?: string;
    newPassword2?: string;
  } = {};

  private clearValidationError = (errName: string, control: FormControl): void => {
    if (!control.errors || !Object.keys(control.errors).includes(errName)) {
      return;
    }
    delete control.errors[errName];
    if (!Object.keys(control.errors).length) {
      control.setErrors(null);
    }
  };

  private myPasswordCompareValidator = (baseFieldName: string, actualControl: FormControl): any => {
    const dataForm = actualControl.parent;
    const baseFieldName2 = `${baseFieldName}2`;
    if (!dataForm) {
      return null;
    }

    const passwordRepeat = dataForm.get(baseFieldName2);
    const password = dataForm.get(baseFieldName).value;
    const confirmPassword = passwordRepeat.value;
    const errName = `${baseFieldName}Match`;

    if (password !== confirmPassword) {
      const error = {};
      error[errName] = true;
      /* for newPassword2 from current field "newPassword" */
      dataForm.controls[baseFieldName2].setErrors(error);
      if (passwordRepeat === actualControl) {
        /* for current field "newPassword2" */
        return error;
      }
    } else {
      this.clearValidationError(errName, dataForm.controls[baseFieldName2]);
    }
    return null;
  };

  private myPasswordReuseValidator = (actualControl: FormControl): any => {
    const dataForm = actualControl.parent;
    if (!dataForm) {
      return null;
    }

    const newPassword = dataForm.get('newPassword');
    const password = dataForm.get('oldPassword').value;
    const confirmPassword = newPassword.value;

    if (password === confirmPassword) {
      const error = {};
      error['passwordReuse'] = true;
      /* for 'newPassword' from current field "oldPassword" */
      dataForm.controls['newPassword'].setErrors(error);
      if (newPassword === actualControl) {
        /* for current field "newPassword" */
        return error;
      }
    } else {
      this.clearValidationError('passwordReuse', dataForm.controls['newPassword']);
    }
    return null;
  };

  ngOnInit() {
    this.pwChangeMeta = {
      groups: [
        {
          class: 'ou',
          rows: [
            {
              title: '現在のパスワード',
              fields: [
                {
                  name: 'oldPassword',
                  type: 'text',
                  class: 'full tall',
                  validators: {
                    required: true,
                    password: true,
                    oldPasswordMatch: (control: FormControl) => this.myPasswordCompareValidator('oldPassword', control),
                    passwordReuse: this.myPasswordReuseValidator,
                  },
                },
              ],
            },
            {
              title: '現在のパスワード（確認）',
              fields: [
                {
                  name: 'oldPassword2',
                  type: 'text',
                  class: 'full tall',
                  validators: {
                    required: true,
                    password: true,
                    oldPasswordMatch: (control: FormControl) => this.myPasswordCompareValidator('oldPassword', control),
                  },
                },
              ],
            },
            {
              fields: [
                {
                  type: 'hr',
                  name: 'line',
                },
              ],
            },
            {
              title: '新しいパスワード',
              fields: [
                {
                  name: 'newPassword',
                  type: 'text',
                  class: 'full tall',
                  validators: {
                    required: true,
                    password: true,
                    newPasswordMatch: (control: FormControl) => this.myPasswordCompareValidator('newPassword', control),
                    passwordReuse: this.myPasswordReuseValidator,
                  },
                },
              ],
            },
            {
              title: '新しいパスワード（確認）',
              fields: [
                {
                  name: 'newPassword2',
                  type: 'text',
                  class: 'full tall',
                  validators: {
                    required: true,
                    password: true,
                    newPasswordMatch: (control: FormControl) => this.myPasswordCompareValidator('newPassword', control),
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }

  public close(): void {
    this.dialog.close(false);
  }

  onChangePassword(): void {
    if (!this.form.myForm.valid) {
      if (!(this.form && this.form.myForm)) {
        return;
      }

      const reportedErrors: { [key: string]: boolean } = {};

      // collect all errors from all controls
      Object.keys(this.form.myForm.controls).forEach((key) => {
        const errors: ValidationErrors = this.form.myForm.get(key).errors;
        if (errors != null) {
          Object.keys(errors).forEach((controlErrorKey) => {
            reportedErrors[controlErrorKey] = true;
          });
        }
      });

      // do not trust form validation, do check again
      if (this.credentials.oldPassword !== this.credentials.oldPassword2) {
        reportedErrors['oldPasswordMatch'] = true;
      }
      if (this.credentials.newPassword !== this.credentials.newPassword2) {
        reportedErrors['newPasswordMatch'] = true;
      }
      if (this.credentials.oldPassword === this.credentials.newPassword) {
        reportedErrors['passwordReuse'] = true;
      }

      // display a single error dialog for all validation errors
      const config: ConfirmDialogConfig = {
        title: '入力値が不正です',
        messages: Object.keys(PasswordChangeDialogComponent.errorMessages)
          .filter((key) => reportedErrors[key])
          .map((key2) => PasswordChangeDialogComponent.errorMessages[key2]),
        style: {
          height: '245px',
          width: '510px',
        },
        buttons: {
          no: 'キャンセル',
          hideNo: true,
          yes: 'OK',
        },
      };
      this.dialogService.open(ConfirmDialogComponent, config);
      return;
    }

    this.authService.password(this.credentials.oldPassword, this.credentials.newPassword).subscribe(
      () => {
        this.changeSuccessful();
      },
      () => {
        this.form.reset();
      },
    );
  }

  changeSuccessful(): void {
    const config: ConfirmDialogConfig = {
      title: 'パスワード変更',
      messages: ['パスワードを変更しました。'],
      style: {
        height: '245px',
        width: '510px',
      },
      buttons: {
        no: 'キャンセル',
        hideNo: true,
        yes: 'OK',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config, () => {
      this.dialog.close(true);
    });
  }
}
