import { SharedModule } from '@agent-ds/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { COUNSELING_COMPONENTS, COUNSELING_DIALOG_COMPONENTS } from './components';
import { CounselingRoutingModule } from './counseling-routing.module';
import { COUNSELING_PAGES } from './pages';
import { CounselingNavService } from './services/counseling-nav.service';

@NgModule({
  declarations: [COUNSELING_COMPONENTS, COUNSELING_PAGES, COUNSELING_DIALOG_COMPONENTS],
  imports: [CommonModule, SharedModule, CounselingRoutingModule],
  entryComponents: [COUNSELING_DIALOG_COMPONENTS],
  providers: [CounselingNavService],
  exports: [COUNSELING_COMPONENTS, COUNSELING_DIALOG_COMPONENTS],
})
export class CounselingModule {}
