import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS, DAYS } from '@agent-ds/shared/constants';
import { SeminarCreateRequest, SeminarCreateResponse } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-new-seminar-registration-dialog',
  templateUrl: './new-seminar-registration-dialog.component.html',
  styleUrls: ['./new-seminar-registration-dialog.component.scss'],
})
export class NewSeminarRegistrationDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('errors', { static: false }) errorsTemplate: TemplateRef<any>;
  model: any = {};
  metadata: FormMeta = { groups: [] };
  isValid = false;

  private readonly daysOfTheWeek: { id: number; name: string }[] = (() => {
    const [sunday, ...restOfDays] = Object.keys(DAYS).map((key) => ({ id: +key, name: DAYS[key] }));
    return [...restOfDays, sunday];
  })();
  private readonly areas: { id: number; name: string }[] = Object.keys(AREAS).map((key) => ({ id: +key, name: AREAS[key] }));

  fieldSubScription: Subscription;
  createErrors: { id: number; area: number; date: string; time: string; type: number }[];
  readonly AREAS = AREAS;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private counselingApiService: CounselingApiService,
  ) {}

  ngAfterViewInit() {
    this.model.area = this.areas[0].id;
    this.model.initialReqruitmentMasterRegistration = true;
    this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadata = {
        groups: [
          {
            class: 'form__group--no-title-border no-border-around no-background',
            rows: [
              ...(() => {
                const rows = this.dynamicService.getFormRows(
                  {
                    fieldName: 'area',
                    fieldType: 'list',
                    label: 'セミナーエリア',
                    displayType: 'radio',
                    validationStyle: { required: true, options: this.areas },
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].labelField = 'name';
                rows[0].fields[0].valueField = 'id';
                return rows;
              })(),
              ...(() => {
                const rows = this.dynamicService.getFormRows(
                  {
                    ...this.dynamicService.getDefinition('student', 'seminarReservationDate'),
                    validationStyle: { required: true },
                    fieldType: 'date-range',
                    displayType: 'date',
                    label: '期間',
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].validators = {
                  ...rows[0].fields[0].validators,
                  required: true,
                  minValue: new Date().toAsialDateString(),
                  minDate: new Date().toAsialDateString(),
                };
                rows[0].fields[2].validators = {
                  ...rows[0].fields[2].validators,
                  required: true,
                  minValue: new Date().toAsialDateString(),
                  minDate: new Date().toAsialDateString(),
                };
                return rows;
              })(),
              ...(() => {
                const rows = this.dynamicService.getFormRows(
                  {
                    fieldName: 'dayOfTheWeek',
                    fieldType: 'multi-list',
                    label: '曜日',
                    displayType: 'checkbox',
                    validationStyle: {
                      required: true,
                      options: this.daysOfTheWeek,
                      minChecked: 1,
                    },
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].labelField = 'name';
                rows[0].fields[0].valueField = 'id';
                return rows;
              })(),
              ...(() => {
                const def = this.dynamicService.getDefinition('student', 'seminarReservationTime');
                const rows = this.dynamicService.getFormRows(
                  {
                    ...def,
                    fieldType: 'multi-list',
                    displayType: 'checkbox',
                    validationStyle: {
                      ...def.validationStyle,
                      required: true,
                      minChecked: 1,
                    },
                    label: '開始時間',
                  },
                  null,
                  'full',
                );
                rows[0].fields[0].validators.options = rows[0].fields[0].validators.options.filter(
                  (option) => !isNaN(Date.parse('1970-01-01T' + option)),
                );
                rows[0].fields[0].options = [...rows[0].fields[0].validators.options];
                return rows;
              })(),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'seminarAttributes',
                  fieldType: 'number',
                  label: 'セミナー属性',
                  displayType: 'number',
                  displayStyle: {
                    placeholder: 'セミナー属性番号',
                  },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'initialReqruitmentMasterRegistration',
                  fieldType: 'list',
                  label: '紹介求人マスタ初期登録',
                  displayType: 'checkbox',
                },
                null,
                'half',
              ),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubScription) {
      this.fieldSubScription.unsubscribe();
      this.fieldSubScription = null;
    }
  }

  create(): void {
    const { from, to } = this.model.seminarReservationDate;
    const requestObj: SeminarCreateRequest = {
      area: this.model.area,
      startDate: new Date(from).toAsialDateString(),
      endDate: new Date(to).toAsialDateString(),
      weekdays: from === to ? this.daysOfTheWeek.map((day) => day.id) : this.model.dayOfTheWeek,
      startTimes: this.model.seminarReservationTime,
      type: this.model.seminarAttributes,
      registerJobs: this.model.initialReqruitmentMasterRegistration ? 1 : 0,
    };
    this.counselingApiService.addSeminar(requestObj).subscribe((res: SeminarCreateResponse) => {
      this.close(res);
      if (res && res.errorItems && res.errorItems.length) {
        this.createErrors = res.errorItems;
        PopupControlComponent.instance.open({
          content: this.errorsTemplate,
          confirmText: null,
          confirmCallback: () => void 0,
          title: 'セミナー予約',
          height: '357px',
          width: '600px',
          cancelText: '閉じる',
          cancelCallback: () => void 0,
        });
      } else if (res && res.successItems && res.successItems.length) {
        PopupControlComponent.instance.open({
          content: 'セミナー登録が完了しました。',
          confirmText: '閉じる',
          confirmCallback: () => void 0,
          title: 'セミナー予約',
          height: '357px',
          width: '600px',
        });
      }
    });
  }

  close(result?: any): void {
    this.dialog.close(result);
  }

  onValidityChange(valid: boolean): void {
    this.isValid = valid;
  }

  onFormChange(event: string): void {
    if (event.includes('seminarReservationDate')) {
      const { from, to } = this.model.seminarReservationDate;
      if (from === to) {
        this.metadata.groups[0].rows[2].showRequired = false;
        this.metadata.groups[0].rows[2].fields[0].disabled = true;
      } else {
        this.metadata.groups[0].rows[2].showRequired = true;
        this.metadata.groups[0].rows[2].fields[0].disabled = false;
      }
    }
  }
}
