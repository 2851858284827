import { formatNumber as numberFormat } from '@angular/common';
import { HttpParameterCodec, HttpResponse } from '@angular/common/http';
import { TemplateRef } from '@angular/core';
import { NULL_SELECTED_VALUE } from '../constants/consts';
import { AddressInfo, DynamicField } from '../models';
import { typeOf } from '../pipes/typeof.pipe';

export function download(data: Blob, fileName?: string) {
  const doc = window.URL.createObjectURL(data);
  const element = document.createElement('a');
  element.href = doc;
  element.setAttribute('download', fileName || 'file');
  const event = document.createEvent('MouseEvents');
  event.initEvent('click', false, true);
  element.dispatchEvent(event);
}

// used source: https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
export function openFile(data: Blob, fileName?: string) {
  if (fileName && fileName.endsWith('.pdf')) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    data = new Blob([data], { type: 'application/pdf' });
  }

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const doc = window.URL.createObjectURL(data);
  const element = document.createElement('a');
  element.href = doc;
  element.target = '_blank';
  element.setAttribute('target', '_blank');
  const event = document.createEvent('MouseEvents');
  event.initEvent('click', false, true);
  element.dispatchEvent(event);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(doc);
  }, 100);
}

export function downloadResponse(data: HttpResponse<Blob>) {
  download(data.body, getFileNameFromContentDispositionHeader(data));
}

export function getFileNameFromContentDispositionHeader(httpResponse: HttpResponse<any>): string {
  const contentDispositionHeader = httpResponse.headers.get('content-disposition');
  if (!contentDispositionHeader) {
    return;
  }
  let namePart = contentDispositionHeader.split(';')[2];
  let result: string;
  if (!namePart) {
    namePart = contentDispositionHeader.split(';')[1];
  }
  result = namePart.trim().split('=')[1];
  if (result && result.startsWith('UTF-8')) {
    result = result.substring(7);
  }
  if (!result) {
    return;
  }
  return decodeURIComponent(result.replace(/"/g, ''));
}

export function cleanObject(obj: any): any {
  for (const propName in obj) {
    if (obj[propName] == null) {
      delete obj[propName];
    }
  }
  return obj;
}

export function toAsialRequestParameters(params: object): object {
  const newParams = {};
  if (params) {
    Object.keys(params).forEach((key: string) => {
      if (params.hasOwnProperty(key) && params[key] != null) {
        if (params[key] instanceof Array) {
          newParams[`${key}[]`] = params[key];
        } else {
          newParams[key] = params[key];
        }
      }
    });
  }
  return newParams;
}

export function isDeepForbidden(object: any): boolean {
  return object instanceof TemplateRef;
}

export function deepClone<T>(object: T, processedRefs?: any[], baseKey?: string): T {
  if (object == null) {
    return object;
  }

  const refs = processedRefs || [object];

  if (Array.isArray(object)) {
    return (object.map((item, index) => {
      let res = null;
      if (!refs.includes(item)) {
        refs.push(item);
        res = deepClone(item, refs, index + '');
        refs.pop();
      }
      return res;
    }) as unknown) as T;
  } else if (typeof object === 'object') {
    let ret = {};
    if (object instanceof Date) {
      ret = new Date(object);
    } else if (isDeepForbidden(object)) {
      ret = object;
    } else {
      Object.keys(object).forEach((key) => {
        let res = null;
        if (!refs.includes(object[key])) {
          refs.push(object[key]);
          res = deepClone(object[key], refs, key);
          refs.pop();
        }
        ret[key] = res;
      });
    }
    return ret as T;
  } else {
    return object;
  }
}

export function multiFill(field: DynamicField, check: boolean = true): DynamicField {
  const checkMod = check && field.fieldType === 'multi-list' && field.validationStyle && field.validationStyle.options;
  const displayMod = field.displayStyle && field.displayStyle.showInJobDescription;
  const validMod = field.validationStyle && field.validationStyle.required;
  const newField = checkMod || displayMod || validMod ? deepClone(field) : field;
  if (checkMod) {
    newField.validationStyle.options.unshift(NULL_SELECTED_VALUE);
  }
  if (displayMod) {
    newField.displayStyle.showInJobDescription = 0;
  }
  if (validMod) {
    newField.validationStyle.required = 0;
  }
  return newField;
}

export function deepCompare(object: any, comp: any, idKey?: string): boolean {
  if (typeOf(object) !== typeOf(comp) || object == null) {
    return object === comp;
  }

  if (idKey && object[idKey] !== undefined && object[idKey] === comp[idKey]) {
    return true;
  }

  if (Array.isArray(object)) {
    if (Array.isArray(comp)) {
      return object.length === comp.length && object.map((item, index) => deepCompare(item, comp[index])).find((v) => !v) == null;
    } else {
      return false;
    }
  } else if (typeof object === 'object') {
    if (typeof comp === 'object') {
      if (object instanceof Date) {
        if (comp instanceof Date) {
          return object.getTime() === comp.getTime();
        } else {
          return false;
        }
      } else {
        const objKeys = Object.keys(object);
        const compKeys = Object.keys(comp);
        return deepCompare(objKeys, compKeys) && objKeys.find((key) => !deepCompare(object[key], comp[key])) == null;
      }
    } else {
      return false;
    }
  } else {
    return object === comp;
  }
}

export function removeEmptyObjects<T>(object: T): T {
  if (object == null) {
    return object;
  }
  if (typeof object === 'object' && !(object instanceof Date)) {
    const notNull = [];
    Object.keys(object).forEach((key) => {
      object[key] = removeEmptyObjects(object[key]);
      if (object[key] != null && object[key] !== '') {
        notNull.push(key);
      } else {
        delete object[key];
      }
    });
    if (!notNull.length) {
      return undefined;
    }
  }
  return object;
}

export function getValueFromObject(value: any, key: string): any {
  if (value == null) {
    return null;
  }
  if (!key) {
    return value;
  }
  const keyParts = key.split('.');
  let val = value;
  for (const part of keyParts) {
    if (val[part] == null) {
      return val[part];
    }
    val = val[part];
  }
  return val;
}

export function setValueInObject(value: any, key: string, valToSet: any): void {
  if (value == null || !key) {
    return;
  }
  const keyParts = key.split('.');
  let val = value;
  for (let i = 0; i < keyParts.length - 1; i++) {
    if (val[keyParts[i]] == null) {
      val[keyParts[i]] = {};
    }
    val = val[keyParts[i]];
  }
  val[keyParts[keyParts.length - 1]] = valToSet;
}

export function formatAddress(address: AddressInfo, long = false): string {
  if (!address || !address.zip) {
    return '';
  }
  const ret = [];
  if (address.zip) {
    ret.push(formatZip(address.zip));
  }
  if (address.prefecture) {
    ret.push(address.prefecture);
  }
  if (address.address1) {
    ret.push(address.address1);
  }
  if (long && address.address2) {
    ret.push(address.address2);
  }
  if (long && address.address3) {
    ret.push(address.address3);
  }
  return ret.join(' ');
}

export function formatZip(zip: string): string {
  if (!zip) {
    return '';
  }
  if (zip.length !== 7) {
    return zip;
  }
  return `${zip.substring(0, 3)}-${zip.substring(3)}`;
}

export function formatNumber(value: number, digits?: string): string {
  return numberFormat(value, 'ja', digits);
}

export function formatPrice(value: number, digits?: string): string {
  return value ? formatNumber(value, digits) + '円' : '';
}

export function parseQueryParam(param: any): any {
  if (param == null) {
    return param;
  }
  if (Array.isArray(param)) {
    return param.map((p) => parseQueryParam(p));
  } else if (typeof param === 'object') {
    return Object.keys(param).reduce((prev, curr) => {
      prev[curr] = parseQueryParam(param[curr]);
      return prev;
    }, {});
  } else {
    return /^(1-9)+(\.\d+)?$/.test(param) ? +param : param === 'true' || param === 'false' ? param === 'true' : param;
  }
}

export function loadScript(src: string, id: string, async: boolean = false): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    let script: HTMLScriptElement = document.head.querySelector(`#${id}`);
    if (!script) {
      // Create script element and set attributes
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = async;
      script.src = src;
      script.id = id;

      // Append the script to the DOM
      document.head.appendChild(script);

      // Resolve the promise once the script is loaded
      script.addEventListener('load', () => {
        resolve(script);
      });

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        reject(new Error(`${script.src} failed to load.`));
      });
    } else {
      resolve(script);
    }
  });
}

export class AsialHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
