import { JobsModule } from '@agent-ds/jobs/jobs.module';
import { SharedModule } from '@agent-ds/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompanyRoutingModule } from './company-routing.module';
import { COMPANY_COMPONENTS, COMPANY_DIALOG_COMPONENTS } from './components';
import { COMPANY_PAGES } from './pages';

@NgModule({
  declarations: [COMPANY_PAGES, COMPANY_COMPONENTS, COMPANY_DIALOG_COMPONENTS],
  imports: [CommonModule, CompanyRoutingModule, SharedModule, JobsModule],
  exports: [COMPANY_PAGES],
  entryComponents: [COMPANY_DIALOG_COMPONENTS],
})
export class CompanyModule {}
