import { ReportCsv } from '@agent-ds/shared/interfaces';
import { FileApiService } from '@agent-ds/shared/services';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-settings-csv-reporting-page',
  templateUrl: './settings-csv-reporting-page.component.html',
  styleUrls: ['./settings-csv-reporting-page.component.scss'],
})
export class SettingsCsvReportingPageComponent implements OnInit {
  reports: ReportCsv[] = [];

  constructor(private fileService: FileApiService) {}

  ngOnInit() {
    this.fileService.getReportCsvList().subscribe((list) => (this.reports = list));
  }

  onDownload(report: ReportCsv): void {
    report.inDownload = true;
    this.fileService.downloadReportCsv(report.id).subscribe(
      (res) => {
        downloadResponse(res);
        report.inDownload = false;
      },
      (err) => (report.inDownload = false),
    );
  }
}
