export const dateUtil = {};

declare global {
  interface DateConstructor {
    yesterday(): { from: Date; to: Date };
    today(): { from: Date; to: Date };
    tomorrow(): { from: Date; to: Date };
    lastWeek(): { from: Date; to: Date };
    currentWeek(): { from: Date; to: Date };
    nextWeek(): { from: Date; to: Date };
    lastMonth(): { from: Date; to: Date };
    currentMonth(): { from: Date; to: Date };
    nextMonth(): { from: Date; to: Date };
    lastYear(useSchool?: boolean): { from: Date; to: Date };
    currentYear(useSchool?: boolean): { from: Date; to: Date };
    nextYear(useSchool?: boolean): { from: Date; to: Date };
    from(date: number | string | Date): Date;
    isValid(date: number | string | Date): boolean;
    fiscalYear(date?: Date): number;

    toAsialDateString(date: number | string | Date): string;
    toAsialDateTimeString(date: number | string | Date): string;
  }

  interface Date {
    addYears(years: number): Date;
    addMonths(months: number): Date;
    addDays(dates: number): Date;
    addHours(hours: number): Date;
    addMinutes(minutes: number): Date;
    addSeconds(seconds: number): Date;
    addMilliseconds(milliseconds: number): Date;
    toStartOfDay(): Date;
    toStartOfWeek(): Date;
    toStartOfMonth(): Date;
    toStartOfYear(): Date;
    toEndOfMonth(): Date;
    isValid(): boolean;
    dateEqual(compare: Date): boolean;

    toAsialDateString(alt?: boolean): string;
    toAsialDateTimeString(): string;
  }
}

Date.from = (date: number | string | Date): Date => {
  if (date) {
    const d = date instanceof Date ? date : new Date(date);
    return d.isValid() ? d : null;
  }
};
Date.isValid = (date: number | string | Date): boolean => {
  if (date) {
    const d = date instanceof Date ? date : new Date(date);
    return d.isValid();
  }
};
Date.toAsialDateString = (date: number | string | Date): string => {
  const d = date instanceof Date ? date : new Date(date);
  if (d.isValid()) {
    return d.toAsialDateString();
  }
};
Date.toAsialDateTimeString = (date: number | string | Date): string => {
  const d = date instanceof Date ? date : new Date(date);
  if (d.isValid()) {
    return d.toAsialDateTimeString();
  }
};
Date.yesterday = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfDay();
  return { from: new Date(tmp).addDays(-1), to: tmp.addMilliseconds(-1) };
};
Date.today = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfDay();
  return { from: tmp, to: new Date(tmp).addDays(1).addMilliseconds(-1) };
};
Date.tomorrow = (): { from: Date; to: Date } => {
  const tmp = new Date().addDays(1).toStartOfDay();
  return { from: tmp, to: new Date(tmp).addDays(1).addMilliseconds(-1) };
};
Date.lastWeek = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfWeek();
  return { from: new Date(tmp).addDays(-7), to: new Date(tmp).addMilliseconds(-1) };
};
Date.currentWeek = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfWeek();
  return { from: tmp, to: new Date(tmp).addDays(7).addMilliseconds(-1) };
};
Date.nextWeek = (): { from: Date; to: Date } => {
  const tmp = new Date().addDays(7).toStartOfWeek();
  return { from: tmp, to: new Date(tmp).addDays(7).addMilliseconds(-1) };
};
Date.lastMonth = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfMonth();
  return { from: new Date(tmp).addMonths(-1), to: tmp.addMilliseconds(-1) };
};
Date.currentMonth = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfMonth();
  return { from: tmp, to: new Date(tmp).addMonths(1).addMilliseconds(-1) };
};
Date.nextMonth = (): { from: Date; to: Date } => {
  const tmp = new Date().toStartOfMonth().addMonths(1);
  return { from: tmp, to: new Date(tmp).addMonths(1).addMilliseconds(-1) };
};
Date.lastYear = (useSchool?: boolean): { from: Date; to: Date } => {
  let tmp = new Date();
  tmp.setDate(1);
  if (useSchool) {
    tmp = tmp.addMonths(-3);
  }
  tmp = tmp.toStartOfYear();
  if (useSchool) {
    tmp = tmp.addMonths(3);
  }
  return { from: new Date(tmp).addYears(-1), to: tmp.addMilliseconds(-1) };
};
Date.currentYear = (useSchool?: boolean): { from: Date; to: Date } => {
  let tmp = new Date();
  tmp.setDate(1);
  if (useSchool) {
    tmp = tmp.addMonths(-3);
  }
  tmp = tmp.toStartOfYear();
  if (useSchool) {
    tmp = tmp.addMonths(3);
  }
  return { from: tmp, to: new Date(tmp).addYears(1).addMilliseconds(-1) };
};
Date.nextYear = (useSchool?: boolean): { from: Date; to: Date } => {
  let tmp = new Date().addYears(1);
  tmp.setDate(1);
  if (useSchool) {
    tmp = tmp.addMonths(-3);
  }
  tmp = tmp.toStartOfYear();
  if (useSchool) {
    tmp = tmp.addMonths(3);
  }
  return { from: tmp, to: new Date(tmp).addYears(1).addMilliseconds(-1) };
};

Date.fiscalYear = (date: Date | string | number = new Date()): number => {
  const d = date instanceof Date ? date : new Date(date);
  d.setDate(1);
  return d.addMonths(-3).getFullYear();
};

Date.prototype.addYears = function(years: number): Date {
  this.setFullYear(this.getFullYear() + years);
  return this;
};
Date.prototype.addMonths = function(months: number): Date {
  this.setMonth(this.getMonth() + months);
  return this;
};
Date.prototype.addDays = function(dates: number): Date {
  this.setDate(this.getDate() + dates);
  return this;
};
Date.prototype.addHours = function(hours: number): Date {
  this.setHours(this.getHours() + hours);
  return this;
};
Date.prototype.addMinutes = function(minutes: number): Date {
  this.setMinutes(this.getMinutes() + minutes);
  return this;
};
Date.prototype.addSeconds = function(seconds: number): Date {
  this.setSeconds(this.getSeconds() + seconds);
  return this;
};
Date.prototype.addMilliseconds = function(milliseconds: number): Date {
  this.setMilliseconds(this.getMilliseconds() + milliseconds);
  return this;
};
Date.prototype.toStartOfDay = function(): Date {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  this.setMilliseconds(0);
  return this;
};
Date.prototype.toStartOfWeek = function(): Date {
  this.toStartOfDay();
  this.addDays(-(this.getDay() + 6) % 7);
  return this;
};
Date.prototype.toStartOfMonth = function(): Date {
  this.toStartOfDay();
  this.setDate(1);
  return this;
};
Date.prototype.toStartOfYear = function(): Date {
  this.toStartOfMonth();
  this.setMonth(0);
  return this;
};
Date.prototype.isValid = function(): boolean {
  return !isNaN(this.getTime()) && !this.toString().startsWith('Invalid');
};
Date.prototype.dateEqual = function(compare: Date): boolean {
  return this.getFullYear() === compare.getFullYear() && this.getMonth() === compare.getMonth() && this.getDate() === compare.getDate();
};
Date.prototype.toAsialDateString = function(alt?: boolean): string {
  return this.isValid()
    ? alt
      ? (() => {
          const mm = this.getMonth() + 1;
          const dd = this.getDate();
          return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
        })()
      : this.toISOString().split('T')[0]
    : this.toString();
};
Date.prototype.toAsialDateTimeString = function(): string {
  return this.toISOString();
};
Date.prototype.toEndOfMonth = function(): Date {
  return this.toStartOfMonth()
    .addMonths(1)
    .addDays(-1);
};
