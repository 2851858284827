import { JobSearchParams } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-company-jobs-tab',
  templateUrl: './company-jobs-tab.component.html',
  styleUrls: ['./company-jobs-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyJobsTabComponent) }],
})
export class CompanyJobsTabComponent extends Tab implements OnChanges {
  @Input() companyId: number;
  @Output() jobSelected = new EventEmitter<any>();
  requestObject: JobSearchParams = {};
  listConfiguration: any = { annualHolidays: false, salary: false, favorites: false, checkbox: false, filterOpen: true };

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
      this.requestObject = {
        enterpriseId: this.companyId,
      };
    }
  }
}
