import { AREAS, NULL_SELECTED_VALUE } from '@agent-ds/shared/constants';
import { FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { multiFill } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-student-search-with-sub-user',
  templateUrl: './student-search-with-sub-user.component.html',
  styleUrls: ['./student-search-with-sub-user.component.scss'],
})
export class StudentSearchWithSubUserComponent implements OnInit, OnDestroy {
  @Input() loginUserName: string;
  @Output() closed = new EventEmitter<void>();
  @Output() search = new EventEmitter<{}>();
  @Output() filterChanged = new EventEmitter<{}>();

  innerSearchModel: any = {};

  get searchModel(): any {
    return this.innerSearchModel;
  }

  @Input()
  set searchModel(model: any) {
    this.innerSearchModel = { ...model };
  }

  leftMeta: FormMeta;
  rightMeta: FormMeta;
  customMeta: FormMeta;

  private fieldSubscription: Subscription;

  constructor(private dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.leftMeta = {
        groups: [
          {
            title: '担当情報',
            rows: [
              ...this.dynamicService.getFormRows(
                { fieldName: 'supportBranch', label: '支援拠点', fieldType: 'multi-branch' },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows({ fieldName: 'teamId', label: '担当チーム', fieldType: 'multi-team' }, null, 'half'),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'userId',
                  fieldType: 'multi-user',
                  label: '担当者',
                },
                null,
                'half',
              ),
              (() => {
                const row = this.dynamicService.getFormRows(
                  {
                    fieldName: 'subUserId',
                    fieldType: 'text',
                    label: '副担当者',
                    displayStyle: null,
                    validationStyle: null,
                  },
                  null,
                  'half',
                )[0];
                row.fields[0].type = 'label';
                row.fields[0].supplier = () => this.loginUserName;
                return row;
              })(),
            ],
          },
          {
            title: '基本情報',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'communicationSkill'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'impressionAndCharm'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'jobHuntingAxis'))),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('student', 'gender'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('student', 'lastAcademicRecord'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('student', 'academicFieldType'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              { title: 'TEL', fields: [{ type: 'text', name: 'tel', class: 'third' }] },
              { title: 'E-MAIL', fields: [{ type: 'text', name: 'email', class: 'full' }] },
              { title: '学校名', fields: [{ type: 'text', name: 'schoolName', class: 'half' }] },
              { title: '学部名', fields: [{ type: 'text', name: 'departmentName', class: 'half' }] },
              ...this.dynamicService.getFormRows(
                multiFill({ ...this.dynamicService.getDefinition('student', 'universityLevel'), fieldType: 'multi-list' }),
                null,
                'third',
              ),
              {
                title: '居住都道府県',
                fields: [
                  {
                    ...this.dynamicService.getFormRows({ fieldName: '', fieldType: 'address' })[0].fields[3],
                    name: 'prefecture',
                    valueField: 'prefecture',
                    class: 'third',
                    transparent: false,
                    multi: true,
                  },
                ],
              },
              { title: 'TOEIC', fields: [{ type: 'number', name: 'toeic', labelAfter: '点以上' }] },
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'academicCourseType'),
                  fieldType: 'multi-list',
                }),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'languageSkill')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'exceptionalExperience')),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'socialType'))),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'hrAnalysis'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'characterAndType1'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'characterAndType2'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'characterAndType3'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'characterAndType4'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'characterAndType5'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
            ],
          },
          {
            title: '希望条件',
            rows: [
              ...this.dynamicService.getFormRows(
                {
                  label: '希望業種',
                  fieldType: 'industry',
                  displayType: 'multi-student',
                  fieldName: 'industry',
                },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                {
                  label: '希望職種',
                  fieldType: 'job-type',
                  displayType: 'multi-student',
                  fieldName: 'jobType',
                },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobTransfer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstPlaceOfAssignment')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'overtimeWork')),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'requestWorkplacePref'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'locationChange'))),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'activityFinish'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itTypeCareer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itTypeArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itAppealFlag')),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'pgExperience'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'salesJobFlag')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'salesJobProduct')),
            ],
          },
          {
            title: '日付',
            rows: [
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'firstRegistrationDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'lastRegistrationDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'interviewDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'updatedAt',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '更新日',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'mypageLoggedinAt',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: 'マイページ最終ログイン日',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'lastContactedAt',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '最終コンタクト日',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'sortingDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'interviewSettingDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'serviceinDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'seminarReservationDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '(旧)セミナー予約日',
              }),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'seminarReservationTime'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                  label: '(旧)セミナー予約時間',
                }),
              ),
              ...(() => {
                const rows = this.dynamicService.getFormRows({
                  label: '(新)セミナーエリア',
                  fieldName: 'seminarArea',
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                  validationStyle: {
                    options: Object.keys(AREAS).map((key) => ({ id: +key, name: AREAS[key] })),
                  },
                });
                rows[0].fields[0].labelField = 'name';
                rows[0].fields[0].valueField = 'id';
                return rows;
              })(),
              ...this.dynamicService.getFormRows({
                fieldName: 'seminarDate',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '(新)セミナー予約日',
              }),
              ...(() => {
                const rows = this.dynamicService.getFormRows({
                  ...this.dynamicService.getDefinition('student', 'seminarReservationTime'),
                  fieldName: 'seminarTime',
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                  label: '(新)セミナー予約時間',
                });
                rows[0].fields[0].validators.options = rows[0].fields[0].validators.options.filter(
                  (option) => !isNaN(Date.parse('1970-01-01T' + option)),
                );
                rows[0].fields[0].options = [...rows[0].fields[0].validators.options];
                return rows;
              })(),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'seminarType',
                  fieldType: 'number',
                  label: '(新)セミナー属性',
                  displayType: 'number',
                  displayStyle: {
                    placeholder: 'セミナー属性番号',
                  },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'inflowClassification'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'seminarParticipationDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationUniversity')),
                null,
                'half',
              ),
            ],
          },
        ],
      };
      this.rightMeta = {
        groups: [
          {
            title: 'ステータス',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill(
                  {
                    ...this.dynamicService.getDefinition('student', 'registrationStatus'),
                    fieldType: 'multi-list',
                  },
                  false,
                ),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'interviewStatus'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'rank'),
                  fieldType: 'multi-list',
                }),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows({ fieldName: 'hasActive', fieldType: 'yes-no', label: '稼働' }),
              ...this.dynamicService.getFormRows({ fieldName: 'hasProgress', fieldType: 'yes-no', label: '進捗' }),
              (() => {
                const gradRow: RowMeta = {
                  title: '卒業年度(最終学歴)',
                  fields: [
                    {
                      type: 'year',
                      name: 'graduateYear',
                      labelAfter: '年',
                    },
                    {
                      type: 'month',
                      name: 'graduateMonth',
                      labelAfter: '月',
                    },
                  ],
                };
                gradRow.fields.push(
                  this.dynamicService.getFormRows({
                    fieldName: 'guraduateType',
                    fieldType: 'multi-list',
                    displayType: 'dropdown',
                    validationStyle: { options: ['卒業見込', '卒業', '中退', '転学'] },
                  })[0].fields[0],
                );
                return gradRow;
              })(),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'supportYear'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'plusMailmag'))),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'plusServiceNg'),
                  fieldType: 'multi-list',
                }),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'nonRecruitmentIntroduction'),
                  fieldType: 'multi-list',
                }),
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows({
                fieldName: 'secondMatch',
                fieldType: 'list',
                label: '2ndマッチ',
                displayType: 'radio',
                validationStyle: {
                  options: ['ON', 'OFF', '未設定'],
                },
              }),
            ],
          },
          {
            title: '登録ルート',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'registrationRoute'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'lastRegistrationRoute'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'registrationRouteDetail')),
                null,
                'full',
              ),
            ],
          },
          {
            title: 'グローバル',
            rows: [
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'nationality')), null, 'full'),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'japaneseCertificateLevel',
                  fieldType: 'certificate-level',
                  label: '日本語能力試験',
                },
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'japaneseLevel'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'abroadFee'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'jobExperience'),
                  fieldType: 'multi-list',
                }),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'visa'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'visaType'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'visaExpiration'))),
            ],
          },
          {
            title: 'プログラミング経験',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingC'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingCPP'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingCS'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingHTML'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingJava'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingJS'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingPerl'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingPHP'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingPython'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingRuby'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingVB'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingGo'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'programmingExperienceLanguage'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'programmingExperience'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'otherITSkills'))),
            ],
          },
          {
            title: 'その他',
            rows: [
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'friendIntroduction'))),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'friendIntroductionId')),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'friendIntroductionName')),
                null,
                'third',
              ),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('student', 'friendIntroductionEntryDate'),
                fieldType: 'date-range',
                displayType: 'date-advanced',
              }),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'friendIntroductionEffectTarget'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'cSetteiReason'))),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'cSetteiReasonWorkplace')),
                null,
                'full',
              ),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'cSetteiReasonDetail')),
                null,
                'full',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'interviewType'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'remindThreeDaysAgo'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'remindDayBefore'))),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'seminarShortCounseling'))),
              ...this.dynamicService.getFormRows(
                multiFill(this.dynamicService.getDefinition('student', 'seminarParticipationHistory')),
                null,
                'full',
              ),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'hookEntryHistory')), null, 'full'),
              ...this.dynamicService.getFormRows(multiFill(this.dynamicService.getDefinition('student', 'pptSms'))),
            ],
          },
        ],
      };
      const customs = this.dynamicService.getDefinitions('student').filter((def) => def.isCustomField && def.isSearchable);
      if (customs.length) {
        this.customMeta = {
          groups: [
            {
              title: 'カスタムフィールド',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onCloseClick(): void {
    this.closed.emit();
  }

  public doSearch(event?: any): void {
    if (!event || !event['isComposing']) {
      this.search.emit({ ...this.searchModel });
    }
  }

  onFormChange(): void {
    this.filterChanged.emit({ ...this.searchModel });
  }
}
