import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { FormMeta, RowMeta } from '@agent-ds/shared/models';
import { CompanyApiService, DynamicFieldService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-company-create-dialog',
  templateUrl: './company-create-dialog.component.html',
  styleUrls: ['./company-create-dialog.component.scss'],
})
export class CompanyCreateDialogComponent implements OnInit, OnDestroy {
  company: { dynamicData: any };
  private validityArray = [true, false, false];
  validity = false;
  inClose = false;

  metadataHead: FormMeta;
  metadataLeft: FormMeta;
  metadataRight: FormMeta;
  metadataCustomFields: FormMeta;

  confirmSubscription: Subscription;
  fieldSubscription: Subscription;

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private companyService: CompanyApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.company = { dynamicData: { enterpriseStatus: '作成中' } };
      this.fieldInit();
    });
    this.validity = false;
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  private fieldInit(): void {
    this.metadataHead = {
      groups: [
        {
          rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseStatus'), null, 'half'),
        },
      ],
    };
    this.metadataLeft = {
      groups: [
        {
          title: '企業概要1',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'name'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'phoneticName'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseNo')),
          ],
        },
        {
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagement'), null, 'half'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagementRemarks'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'agClientStatus')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'rininKRank')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drClientStatus')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drAgreement'), null, 'short'),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'hasDr'))[0];
              const textOne = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'drRegisteredEmail'),
                null,
                'full ou',
              )[0];
              const textTwo = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'recruitmentWindow'),
                null,
                'full ou',
              )[0];
              textOne.fields[0].labelBefore = textOne.title;
              textOne.fields[0].validators.required = true;
              // if (this.company && this.company.dynamicData && this.company.dynamicData.hasDr && this.company.dynamicData.hasDr.length) {
              //   textOne.fields[0].validators = { required: true };
              // }
              textTwo.fields[0].labelBefore = textTwo.title;
              textOne.fields[0].allowOn = textTwo.fields[0].allowOn = { hasDr: '有' };
              row.fields.push(textOne.fields[0], textTwo.fields[0]);
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drOfferAgency')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'plusUsage')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drLandingRoute')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'fairContract'), null, 'short'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'gpsContract')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'tokuha')),
          ],
        },
        {
          title: '企業概要2',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'industry'), null, 'fill'),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'capital'))[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'capitalVisibility'))[0].fields[0],
              );
              return row;
            })(),
            (() => {
              const row = this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesAmount'))[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesVisibility'))[0].fields[0],
              );
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesGroupAmount'))[0].fields[0],
              );
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesDate')),
            (() => {
              const row = this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('enterprise', 'numberEmployees'),
                null,
                'third',
              )[0];
              row.fields.push(
                this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'numberGroupEmployees'))[0].fields[0],
              );
              return row;
            })(),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'establishDate')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'marketName')),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'url'), null, 'fill'),
            ...this.dynamicService.getFormRows(
              this.dynamicService.getDefinition('enterprise', 'numberEmployeesNonConsolidated'),
              null,
              'fill',
            ),
          ],
        },
        {
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentTitle'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentName'), null, 'fill'),
          ],
        },
        {
          title: '本社所在地',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'branches'), null, 'fill'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'address')),
          ],
        },
      ],
    };
    this.metadataRight = {
      groups: [
        {
          title: '担当',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows({
              fieldName: 'userIds',
              fieldType: 'multi-user',
              label: '担当者',
              validationStyle: { required: true, max: 5 },
            }),
          ],
        },
        {
          title: 'その他',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'recommendDescription'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'remarks'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'genderInfo'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'resumePassword'), 'dynamicData', 'half'),
          ],
        },
        {
          title: '企業概要3',
          class: 'form__group--red-title-border',
          rows: [
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyInfo'), 'dynamicData'),
            ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyStrength'), 'dynamicData'),
          ],
        },
      ],
    };
    const customs = this.dynamicService.getDefinitions('enterprise').filter((def) => def.isCustomField);
    if (customs.length) {
      this.metadataCustomFields = {
        groups: [
          {
            title: 'カスタムフィールド',
            class: 'form__group--red-title-border',
            rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
          },
        ],
      };
    }
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  create(): void {
    if (!this.validity || this.inClose || this.dialog.inClose) {
      return;
    }
    this.inClose = true;
    this.companyService.create(this.company).subscribe(
      (res) => {
        this.companyService.refreshEvent.emit();
        this.inClose = false;
        this.close(res);
      },
      () => (this.inClose = false),
    );
  }

  close(param?: any): void {
    this.dialog.close(param);
  }
}
