import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { FormMeta } from '@agent-ds/shared/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-matching-create-dialog',
  templateUrl: './matching-create-dialog.component.html',
  styleUrls: ['./matching-create-dialog.component.scss'],
})
export class MatchingCreateDialogComponent implements OnInit, OnDestroy {
  meta: FormMeta;
  readonly model = { name: null };
  validity = false;

  confirmSubscription: Subscription;

  constructor(public readonly dialog: DialogRef, private config: DialogConfig<{ name?: string }>) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.create());
    if (this.config && this.config.data) {
      this.model.name = this.config.data.name;
    }
    this.meta = {
      groups: [
        {
          class: 'form__group--no-title-border form__group--flex-column no-border-around no-background no-padding margin-bottom',
          rows: [
            {
              title: 'マッチング条件名を入力してください。',
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  validators: { required: true },
                  class: 'half',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  onValidityChange(valid: boolean): void {
    this.validity = valid;
  }

  create(): void {
    if (!this.validity || this.dialog.inClose) {
      return;
    }
    this.dialog.close(this.model);
  }

  close(): void {
    this.dialog.close();
  }
}
