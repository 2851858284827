import { environment } from '@agent-ds/environments/environment';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PasswordChangeDialogComponent } from '@agent-ds/shared/components/password-change-dialog/password-change-dialog.component';
import { AuthGuard } from '@agent-ds/shared/guard';
import { PageInfo, User } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { AuthService, DialogService } from '@agent-ds/shared/services';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ag-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) form: DynamicFormComponent;

  pageInfo: PageInfo = {
    layout: null,
  };

  loginMeta: FormMeta = {
    groups: [
      {
        class: 'ou',
        rows: [
          {
            title: 'メールアドレス',

            fields: [
              {
                name: 'email',
                type: 'text',
                class: 'full tall',
                validators: { required: true },
              },
            ],
          },
          {
            title: 'パスワード',
            fields: [
              {
                name: 'password',
                type: 'text',
                class: 'full tall',
                validators: { required: true, password: true },
              },
            ],
          },
        ],
      },
    ],
  };

  meta: FormMeta = this.loginMeta;

  readonly credentials: {
    email: string;
    password: string;
  } = {
    // TODO: remove prefilled credentials
    email: environment['email'] || '',
    password: environment['password'] || '',
  };

  guard = AuthGuard;

  /**
   * コンストラクタ
   */
  constructor(private authService: AuthService, private router: Router, private dialog: DialogService) {}

  /**
   * 初期化
   */
  ngOnInit() {
    this.authService.isLogined().subscribe((loggedIn) => (this.pageInfo.layout = loggedIn));
  }

  /**
   * ログインする
   */
  onLogin() {
    this.authService.login(this.credentials).subscribe(
      (response: HttpResponse<User>) => {
        if (response.status === 210) {
          const config: ConfirmDialogConfig = {
            title: 'バスワード変更',
            hideClose: true,
            messages: ['パスワードの有効期限が近づいています。\n新しいパスワードを設定してください。'],
            style: {
              height: '245px',
              width: '510px',
            },
            buttons: {
              no: 'キャンセル',
              hideNo: true,
              yes: 'パスワードの変更',
            },
          };
          this.dialog.open(ConfirmDialogComponent, config, () => {
            this.dialog.open(PasswordChangeDialogComponent);
          });
        } else {
          this.router.navigateByUrl(AuthGuard.REQUESTED_URL || '');
        }
      },
      (response: HttpErrorResponse) => {
        if (response.status === 421) {
          const config: ConfirmDialogConfig = {
            title: 'パスワード有効期限切れ',
            messages: ['パスワードの有効期限が切れました。\n管理者にお問い合わせください。'],
            style: {
              height: '245px',
              width: '510px',
            },
            buttons: {
              no: '閉じる',
              hideYes: true,
              yes: 'パスワードの変更',
            },
          };
          this.dialog.open(ConfirmDialogComponent, config, () => {
            this.form.reset();
          });
        }
      },
    );
  }
}
