import { SharedModule } from '@agent-ds/shared';
import { NgModule } from '@angular/core';
import { SETTINGS_PAGES } from './pages';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
  declarations: [SETTINGS_PAGES],
  imports: [SharedModule, SettingsRoutingModule],
})
export class SettingsModule {}
