import { ProgressScheduleAdjustDialogComponent } from '@agent-ds/progress/components';
import { SalesCreateDialogComponent } from '@agent-ds/sales/components';
import { ProgressInrowJobPostingConfig } from '../components/mail-send/configs/progress-inrow-job-posting-config';
import { ProgressRecommendConfig } from '../components/mail-send/configs/progress-recommend-config';
import { ProgressSendInterviewInfoConfig } from '../components/mail-send/configs/progress-send-interview-info-config';
import { ProgressSendResumeConfig } from '../components/mail-send/configs/progress-send-resume-config';
import { ProgressActionType, ProgressStatusCode } from '../enums';
import { ProgressActionColor } from '../enums/progress-action-color.enum';
import { ProgressAction, ProgressItemResponse, ProgressUpdateWithActionRequest } from '../interfaces';
import { NULL_SELECTED_VALUE } from './consts';

const basicRequest = (
  progress: ProgressItemResponse,
  overwrite: Partial<ProgressUpdateWithActionRequest>,
): ProgressUpdateWithActionRequest => {
  return {
    type: progress.type,
    hasInterview: progress.hasInterview,
    n: progress.n,
    seminarType: progress.seminarType,
    isStudentDelay: progress.isStudentDelay,
    seminarAt: progress.seminarAt,
    action: null,
    seminarAtMailSendFlag: 0,
    ...overwrite,
  };
};

const incrementN = (
  progress: ProgressItemResponse,
  overwrite: Partial<ProgressUpdateWithActionRequest>,
): ProgressUpdateWithActionRequest => {
  const requestObject: ProgressUpdateWithActionRequest = basicRequest(progress, overwrite);
  ++requestObject.n;
  return requestObject;
};

export const PROGRESS_ACTIONS: { [key: number]: ProgressAction } = {
  10: {
    label: () => '求人票送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressInrowJobPostingConfig,
  },
  11: {
    label: () => '求人紹介OK',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 11, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  12: {
    label: () => '求人紹介NG（連絡しない）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 12, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  20: {
    label: () => '推薦依頼送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressRecommendConfig,
  },
  21: {
    label: () => '応募OK（求人紹介）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 21, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  22: {
    label: () => '応募NG（求人紹介）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 22, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  30: {
    label: () => '推薦依頼送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressRecommendConfig,
  },
  31: {
    label: () => '営業書類提出',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 31, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  32: {
    label: () => '辞退（企業へ連絡しない）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 32, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  33: {
    label: () => 'NG',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 33, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  40: {
    label: () => '営業OK',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 40, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  41: {
    label: () => '営業NG',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 41, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  42: {
    label: () => '辞退（企業へ連絡しない）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 42, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  51: {
    label: () => 'レジュメ送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressSendResumeConfig,
  },
  52: {
    label: () => '企業書類提出',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 52, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  53: {
    label: () => '辞退（企業へ連絡しない）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 53, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  60: {
    label: () => '企業書類合格（次選考へ）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 60, n: 1, seminarType: 1, seminarAt: null, isStudentDelay: 1 },
    request: basicRequest,
  },
  61: {
    label: () => '企業書類合格（セミナーへ）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 61, n: 0, seminarType: 0, seminarAt: null, isStudentDelay: 1 },
    request: basicRequest,
  },
  62: {
    label: () => '企業書類不合格',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 62, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  63: {
    label: () => '企業書類合格（内定へ）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 63, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  64: {
    label: () => '辞退（企業へ連絡）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 64, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  72: {
    label: () => 'セミナー日時決定',
    type: ProgressActionType.EDITOR,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 72, n: 0, isStudentDelay: 0 },
    seminarValues: [{ label: NULL_SELECTED_VALUE, value: 1 }],
    request: basicRequest,
  },
  73: {
    label: () => 'セミナー辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 73, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  74: {
    label: () => '日程調整',
    type: ProgressActionType.DIALOG,
    color: ProgressActionColor.GREEN,
    dialog: ProgressScheduleAdjustDialogComponent,
    id: 74,
  },
  80: {
    label: () => 'セミナー確認済み',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 80, n: 0, seminarType: 0, isStudentDelay: 0 },
    request: basicRequest,
  },
  81: {
    label: () => 'セミナー辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 81, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  82: {
    label: () => 'セミナー案内送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressSendInterviewInfoConfig,
  },
  90: {
    label: () => 'セミナー合格（次選考へ）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 90, n: 1, seminarType: 1, seminarAt: null, isStudentDelay: 1 },
    request: basicRequest,
  },
  91: {
    label: () => 'セミナー不合格',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 91, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  92: {
    label: () => 'セミナー合格（内定へ）',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 92, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  93: {
    label: () => 'セミナー辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 93, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  94: {
    label: () => 'セミナー案内送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressSendInterviewInfoConfig,
  },
  102: {
    label: (seminarType, n) => getSeminarTypeLabel('面接日時決定', seminarType, n),
    type: ProgressActionType.EDITOR,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 102, isStudentDelay: 0 },
    request: basicRequest,
  },
  104: {
    label: () => '面接辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 104, n: null, seminarType: null, seminarAt: null, isStudentDelay: null },
    request: basicRequest,
  },
  110: {
    label: (seminarType, n) => getSeminarTypeLabel('面接確認済み', seminarType, n),
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 110, isStudentDelay: 0 },
    request: basicRequest,
  },
  111: {
    label: () => '面接辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 111, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  112: {
    label: () => '面接案内送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressSendInterviewInfoConfig,
  },
  120: {
    label: (seminarType, n) => getSeminarTypeLabel('面接合格（次選考へ）', seminarType, n),
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 120, seminarType: 1, seminarAt: null, isStudentDelay: 1 },
    request: incrementN,
  },
  121: {
    label: () => '面接不合格',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 121, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  122: {
    label: (seminarType, n) => getSeminarTypeLabel('面接合格（内定へ）', seminarType, n),
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 122, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  123: {
    label: () => '面接辞退',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 123, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  124: {
    label: () => '面接案内送信',
    type: ProgressActionType.MAIL,
    color: ProgressActionColor.GREEN,
    mailConfig: ProgressSendInterviewInfoConfig,
  },
  130: {
    label: () => '本人OK',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.BLUE,
    baseRequest: { action: 130, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  131: {
    label: () => '本人NG',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 131, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  140: {
    label: () => '書類回収完了',
    type: ProgressActionType.DIALOG,
    color: ProgressActionColor.BLUE,
    dialog: SalesCreateDialogComponent,
    id: 140,
  },
  141: {
    label: () => '本人NG',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 141, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  150: { label: () => '請求処理完了' }, // connected to sales
  151: {
    label: () => '本人NG',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 151, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  152: { label: () => '成約の承認' }, // connected to sales
  153: { label: () => '否認' }, // connected to sales
  // 160: { label: () => '成約承認取り消し' }, // connected to sales
  170: {
    label: () => '求職者へNG連絡',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 170, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  180: {
    label: () => '企業へNG連絡',
    type: ProgressActionType.OPERATION,
    color: ProgressActionColor.GRAY,
    baseRequest: { action: 180, n: null, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
  210: {
    label: () => '削除終了',
    type: ProgressActionType.DELETE,
    color: ProgressActionColor.RED,
    baseRequest: { action: 210, seminarType: null, seminarAt: null, isStudentDelay: 0 },
    request: basicRequest,
  },
};

export function getProgressStatusLabel(status: ProgressStatusCode, seminarType: number, n: number): string {
  if (status === 100 || status === 110 || status === 120) {
    return getSeminarTypeLabel(ProgressStatusCode[status], seminarType, n);
  }
  return ProgressStatusCode[status] ? ProgressStatusCode[status] : '';
}

export function getSeminarTypeLabel(baseLabel: string, seminarType: number, n?: number): string {
  switch (seminarType) {
    case 99:
      return `最終前${baseLabel}`;
    case 100:
      return `最終${baseLabel}`;
    case 1:
    default:
      return `${n}次${baseLabel}`;
  }
}
