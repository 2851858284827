import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { MailTemplateResponse } from '@agent-ds/shared/interfaces';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { map, mergeMap, tap } from 'rxjs/operators';
import { StudentBulkConfig } from './student-bulk-config';

export class ProgressNgEndConfig extends StudentBulkConfig {
  public getTemplateTypeId(): number[] {
    return [20];
  }

  public setParams(params: any) {
    if (this.sender) {
      this.sender.model.jobs = params.jobs || [];
      this.sender.model.progressIds = params.progressIds || [];
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.students) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    metaData.groups[0].rows.push({
      title: 'To (求職者)',
      showRequired: true,
      fields: [
        {
          name: 'to',
          type: 'label',
          supplier: () =>
            this.sender
              ? this.sender.model.students
                  .map((s) =>
                    s.emailMainAvailable
                      ? `【送信不可】${s.lastName + s.firstName} メイン <${s.emailMain}>`
                      : `${s.lastName + s.firstName} メイン <${s.emailMain}>`,
                  )
                  .join(', ')
              : '',
        },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (求職者、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        row.fields.unshift(
          {
            name: 'cc_label',
            type: 'label',
            default: '※E-Mailサブにも送信',
          },
          {
            name: 'cc-hr',
            type: 'hr',
            class: 'full',
          },
        );
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: 'テンプレート選択',
        fields: [
          {
            name: 'dummyTemplateType',
            type: 'autocomplete',
            linkTo: ['subject', 'body'],
            placeholder: 'テンプレート名',
            labelField: 'name',
            class: 'half',
            options: [],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const typeIds = getValue('templateTypeIds');
              return this.sender.mailApiService.getMailTemplateNames().pipe(
                map((res) => {
                  const filtered = res.filter((r) => r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)));
                  return {
                    options: filtered,
                  };
                }),
              );
            },
          },
        ],
      });
    }
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                if (value) {
                  return value;
                }
                const template = getValue('dummyTemplateType');
                if (!template) {
                  return undefined;
                }
                const templateId: number = template ? template.id : 0;
                return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                  map((res: MailTemplateResponse) => {
                    return {
                      value: res ? res.subject : undefined,
                    };
                  }),
                );
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });
    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const template = getValue('dummyTemplateType');
              if (!template) {
                return undefined;
              }
              const templateId: number = template ? template.id : 0;
              return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                map((res: MailTemplateResponse) => {
                  return {
                    value: res ? res.body : undefined,
                  };
                }),
              );
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public save(toSave: any): void {
    toSave.progressIds = this.sender.model.progressIds;
    this.sender.mailApiService
      .sendNgNotification(toSave)
      .pipe(
        mergeMap((res) => this.sender.progressApiService.ngEnd(this.sender.model.progressIds).pipe(tap(() => this.sender.afterSend(res)))),
      )
      .subscribe();
  }
}
