import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

import { GENDERS } from '@agent-ds/shared/constants';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const code = Number(value);
    const found = _.find(GENDERS, (gender) => {
      return gender.code === code;
    });

    if (!found) {
      return '-';
    }

    return found.name;
  }
}
