import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(text: string, truncateAt: number, truncateLines?: number): string {
    let res: string = text;
    if (truncateAt && text && text.length > truncateAt) {
      res = text.slice(0, truncateAt);
    }
    const resLines = truncateLines && res && res.split('\n');
    if (resLines && resLines.length > truncateLines) {
      res = resLines.slice(0, truncateLines).join('\n');
    }
    return res !== text ? res + '...' : res;
  }
}
