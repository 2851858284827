import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import {
  MailPlaceholderResponse,
  MailTemplateResponse,
  MailTemplateTypeResponse,
  MailTemplateUpdateRequest,
} from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { MailApiService } from '@agent-ds/shared/services';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ag-settings-mail-templates-page',
  templateUrl: './settings-mail-templates-page.component.html',
  styleUrls: ['./settings-mail-templates-page.component.scss'],
})
export class SettingsMailTemplatesPageComponent implements OnInit {
  constructor(private mailApiService: MailApiService) {}
  @ViewChild('mailTemplatePopup', { static: true }) mailTemplatePopupTemplate: TemplateRef<any>;
  @ViewChild('mailBodyHeader', { static: true }) mailBodyHeader: TemplateRef<any>;
  @ViewChild('mailTemplateForm', { static: false }) mailTemplateFormComponent: DynamicFormComponent;
  mailTypeGroups: { mailType: MailTemplateTypeResponse; mailTemplates: MailTemplateResponse[] }[] = [];
  mailTemplatePopupModel: any;
  mailTemplatePopupMeta: FormMeta;
  private selectedMailTemplateTypeId: number;
  private subwindow: Window = null;

  private readonly loadRequest = () => forkJoin(this.mailApiService.getMailTemplateTypes(), this.mailApiService.getMailTemplates());
  private readonly populateDataFn = ([mailTypes, mailTemplates]) => {
    this.mailTypeGroups = mailTypes.map((mailType: MailTemplateTypeResponse) => ({
      mailType: mailType,
      mailTemplates: mailTemplates
        .filter((template: MailTemplateResponse) => template.mailTemplateTypeId === mailType.id)
        .map((t) => ({ ...t, status: t.status ? 0 : 1 })),
    }));
  };

  ngOnInit() {
    this.loadRequest().subscribe(this.populateDataFn);
    this.mailTemplatePopupMeta = {
      groups: [
        {
          class: 'ou',
          rows: [
            {
              title: 'テンプレート名',
              style: { 'padding-top': 0, 'padding-bottom': '13px' },
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  validators: { required: true },
                  class: 'tall fill',
                  style: { padding: 0 },
                },
              ],
            },
            {
              title: '件名',
              style: { 'padding-top': 0, 'padding-bottom': '20px' },
              fields: [
                {
                  name: 'subject',
                  type: 'text',
                  validators: { required: true },
                  class: 'tall fill',
                  style: { padding: 0 },
                },
              ],
            },
          ],
        },
        {
          injectToHeader: this.mailBodyHeader,
          class: 'ou form__group--no-margin form__group--no-title-border',
          rows: [
            {
              style: { 'padding-top': 0, 'padding-bottom': '20px' },
              fields: [
                {
                  name: 'body',
                  type: 'textarea',
                  validators: { required: true },
                  class: 'tall fill',
                  style: {
                    padding: 0,
                    'margin-top': '-10px',
                    minRows: 15,
                    maxRows: 15,
                  },
                },
              ],
            },
            {
              style: { 'padding-top': 0 },
              fields: [
                {
                  name: 'status',
                  type: 'checkbox',
                  style: { padding: 0 },
                  labelAfter: 'このテンプレートを非表示にする',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  openMailTemplateEdit(mailTemplateTypeId: number, mailTemplate?: MailTemplateResponse): void {
    this.mailTemplatePopupModel = { ...mailTemplate };
    this.selectedMailTemplateTypeId = mailTemplateTypeId;
    PopupControlComponent.instance.open({
      title: 'メールテンプレート設定',
      cancelText: 'キャンセル',
      confirmText: mailTemplate ? '更新' : '保存',
      width: '800px',
      height: '640px',
      content: this.mailTemplatePopupTemplate,
      confirmEnabled: () => this.mailTemplateFormComponent && this.mailTemplateFormComponent.myForm.valid,
      confirmCallback: () => {
        const requestObj: MailTemplateUpdateRequest = {
          name: this.mailTemplatePopupModel.name.trim(),
          subject: this.mailTemplatePopupModel.subject.trim(),
          body: this.mailTemplatePopupModel.body.trim(),
          status: this.mailTemplatePopupModel.status ? 0 : 1,
          mailTemplateTypeId: this.selectedMailTemplateTypeId,
        };
        const request = mailTemplate
          ? this.mailApiService.updateMailTemplate(mailTemplate.id, requestObj)
          : this.mailApiService.addMailTemplate(requestObj);

        return request.pipe(concatMap(() => this.loadRequest().pipe(tap(this.populateDataFn))));
      },
    });
  }

  openPlaceholderWindow(): void {
    if (this.subwindow && !this.subwindow.closed) {
      this.subwindow.close();
    }
    this.mailApiService.getMailTemplatePlaceholder().subscribe((placeholders: MailPlaceholderResponse[]) => {
      const filtered = placeholders.filter((p) => p.mail_template_type_id === this.selectedMailTemplateTypeId);
      this.subwindow = window.open(
        '',
        '差し込み変数 | Mach',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=750,width=630',
      );
      this.subwindow.document.write(
        `
<html>
  <head>
    <title>差し込み変数 | Mach - doda新卒エージェント</title>
    <link rel="stylesheet" type="text/css" href="/styles.css">
    <style>
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      body {
        width: 100%;
        min-width: 550px;
        overflow: auto;
        padding: 30px;
        font-family: ${[
          '"Meiryo UI"',
          '"游ゴシック Medium"',
          'YuGothic',
          'YuGothicM',
          '"Hiragino Kaku Gothic ProN"',
          '"Hiragino Kaku Gothic Pro"',
          'メイリオ',
          'Meiryo',
          'sans-serif',
        ].join(', ')};
      }

      .detail-header {
        height: 23px;
        padding-left: 7px;
        border-left: 5px solid #1f98e3;
        margin-bottom: 9px;
      }

      .detail-header__title {
        font-weight: bold;
        color: $text;
        font-size: 16px;
        line-height: 20px;
      }

      .detail {
        font-size: 12px;
        line-height: 16px;
        color: #9E9E9E;
        width: 100%;
        border-collapse: collapse;
      }

      .detail__row {
        height: 30px;
      }

      .detail__cell {
        padding: 6px 15px;
        border: 1px solid #e3e3e3;
      }

      .detail__label {
        background-color: #fafafa;
        font-size: 12px;
        line-height: 14px;
        color: #666666;
        width: 130px;
      }
    </style>
  </head>
  <body>
    <div class="detail-header">
      <div class="detail-header__title">差し込み変数一覧</div>
    </div>
    <table class="detail">
      ${filtered
        .map(
          (p) => `
          <tr class="detail__row">
            <td class="detail__cell detail__label">${p.label}</td>
            <td class="detail__cell">${p.value}</td>
          </tr>
          `,
        )
        .join('')}
    </table>
  </body>
</html>
`,
      );
    });
  }
}
