import { environment } from '@agent-ds/environments/environment';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUser, EnterpriseDepartmentUserType, MailTemplateResponse } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupConfig, MailPopupType, MailSendConfig } from '../config';

export class CompanySendConfig extends MailSendConfig {
  private contacts: any[];

  public getTemplateTypeId(): number[] {
    return [16];
  }

  public getPopupConfig(): MailPopupConfig[] {
    return [{ type: MailPopupType.DEPARTMENT }];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.enterpriseId = params.enterpriseId;
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    const enterprise = this.sender.model.enterprise;
    const department = this.sender.model.department;

    metaData.groups[0].rows.push({
      title: 'To (企業・部署)',
      showRequired: true,
      class: 'ou',
      fields: [
        {
          name: 'enterpriseName',
          type: 'label',
          class: 'pre',
          supplier: () =>
            `${enterprise.frontId} ${enterprise.dynamicData.name}${enterprise.dynamicData.enterpriseNo}\n${department.dynamicData.name}`,
        },
        !this.sender.inConfirm
          ? {
              name: 'to',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              class: 'multi-ou',
              style: { 'margin-left': '29px' },
              multi: true,
              options: department
                ? this.contacts.map((c) => {
                    return {
                      label: `${c.name} <${c.email}>`,
                      value: c.email,
                    };
                  })
                : [],
              validators: { required: true },
            }
          : {
              name: 'to',
              type: 'label',
              supplier: () => this.contacts.filter((c) => this.sender.model.to.includes(c.email)).map((c) => `${c.name} <${c.email}>`),
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (企業、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';

        // CCの企業部分のフォーム
        if (!this.sender.inConfirm) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              labelBefore: '企業',
              class: 'full multi-ou sticky-label',
              multi: true,
              options: department
                ? this.contacts.map((c) => {
                    return {
                      label: `${c.name} <${c.email}>`,
                      value: c.email,
                    };
                  })
                : [],
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        } else if (this.sender.model.cc_single.length) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'label',
              class: 'full',
              supplier: () =>
                this.contacts.filter((c) => this.sender.model.cc_single.includes(c.email)).map((c) => `${c.name} <${c.email}>`),
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
            },
          );
        }
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: 'テンプレート選択',
        fields: [
          {
            name: 'dummyTemplateType',
            type: 'autocomplete',
            linkTo: ['subject', 'body'],
            placeholder: 'テンプレート名',
            labelField: 'name',
            class: 'half',
            options: [],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const typeIds = getValue('templateTypeIds');
              return this.sender.mailApiService.getMailTemplateNames().pipe(
                map((res) => {
                  const filtered = res.filter((r) => r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)));
                  return {
                    options: filtered,
                  };
                }),
              );
            },
          },
        ],
      });
    }
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                if (value) {
                  return value;
                }
                const template = getValue('dummyTemplateType');
                if (!template) {
                  return undefined;
                }
                const templateId: number = template ? template.id : 0;
                return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                  map((res: MailTemplateResponse) => {
                    return {
                      value: res ? res.subject : undefined,
                    };
                  }),
                );
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });

    metaData.groups[0].rows.push(
      (() => {
        const row = ({
          title: '添付ファイル',
          fields: [
            {
              type: 'label',
              name: 'enterpriseId',
              class: 'half',
              supplier: () => `${this.sender.model.enterprise.frontId} ${this.sender.model.enterprise.dynamicData.name}`,
            },
          ],
        } as unknown) as RowMeta;
        row.class = 'ou';
        if (!this.sender.inConfirm) {
          row.fields[0].labelBefore = '企業：';
          row.fields[0].linkTo = ['enterpriseFileIds'];
          row.fields[0].actions = [
            {
              title: 'データフォルダから選択',
              type: 'RUNNABLE',
              runnable: () => {
                this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
                PopupControlComponent.instance.open({
                  content: this.sender.fileSelectTemplate,
                  confirmText: '添付',
                  cancelText: 'キャンセル',
                  title: 'データフォルダ',
                  confirmCallback: () => {
                    this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
                  },
                });
              },
            },
          ];
        } else {
          row.fields.length = 0;
        }

        if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
          row.fields.push({
            name: 'enterpriseFileIds',
            type: 'autocomplete',
            labelField: [
              {
                name: 'name',
                class: this.sender.inConfirm ? 'link' : null,
                action: this.sender.inConfirm
                  ? (file) =>
                      this.sender.fileService.downloadFile(
                        'enterprise',
                        +Object.keys(this.sender.enterpriseFiles).find(
                          (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                        ),
                        file.id,
                        file.name,
                      )
                  : null,
              },
            ],
            valueField: 'id',
            multi: true,
            class: 'options-only half',
            options: [],
            style: { 'padding-left': '40px' },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              this.sender.enterpriseFiles = {};
              this.sender.enterpriseInfoById[
                this.sender.model.enterprise.id
              ] = `${this.sender.model.enterprise.frontId} ${this.sender.model.enterprise.name}`;
              return this.sender.fileService.getFiles('enterprise', this.sender.model.enterprise.id).pipe(
                map((res) => {
                  this.sender.enterpriseFiles[this.sender.model.enterprise.id] = res.filter((f) => f.enterpriseSendFlag);
                  return { options: this.sender.enterpriseFiles[this.sender.model.enterprise.id] };
                }),
              );
            },
          });
        }

        if (!this.sender.inConfirm) {
          const studentSelect = this.sender.dynamicService.getFormRows(
            { fieldType: 'multi-student', fieldName: 'studentIds' },
            null,
            'half sticky-label',
          )[0].fields[0];
          studentSelect.labelBefore = '求職者: ';
          studentSelect.valueField = null;
          studentSelect.linkTo = ['studentFileIds'];
          studentSelect.actions = [
            {
              title: 'データフォルダから選択',
              type: 'RUNNABLE',
              runnable: () => {
                this.sender.studentFileIds = [...this.sender.model.studentFileIds];
                PopupControlComponent.instance.open({
                  content: this.sender.studentFileSelectTemplate,
                  confirmText: '添付',
                  cancelText: 'キャンセル',
                  title: 'データフォルダ',
                  confirmCallback: () => {
                    this.sender.model.studentFileIds = [...this.sender.studentFileIds];
                  },
                });
              },
            },
          ];
          row.fields.push(studentSelect);
        }

        if (!this.sender.inConfirm || this.sender.model.studentFileIds.length) {
          row.fields.push({
            name: 'studentFileIds',
            type: 'autocomplete',
            labelField: [
              {
                name: 'name',
                class: this.sender.inConfirm ? 'link' : null,
                action: this.sender.inConfirm
                  ? (file) =>
                      this.sender.fileService.downloadFile(
                        'students',
                        +Object.keys(this.sender.studentFiles).find(
                          (key) => this.sender.studentFiles[key].find((f) => f.id === file.id) != null,
                        ),
                        file.id,
                        file.name,
                      )
                  : null,
              },
            ],
            valueField: 'id',
            multi: true,
            class: 'options-only half',
            options: [],
            style: { 'padding-left': '40px' },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              this.sender.studentFiles = {};
              this.sender.model.studentIds.forEach((s) => (this.sender.studentInfoById[s.id] = `${s.frontId} ${s.lastName}${s.firstName}`));
              return forkJoin(
                this.sender.model.studentIds.map((s) =>
                  this.sender.fileService.getFiles('students', s.id).pipe(
                    map((res) => {
                      this.sender.studentFiles[s.id] = res.filter((f) => f.enterpriseSendFlag);
                      return { options: this.sender.studentFiles[s.id] };
                    }),
                  ),
                ),
              ).pipe(map((res) => ({ options: Object.values(this.sender.studentFiles).flatten() })));
            },
          });
        }
        return row;
      })(),
    );

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const template = getValue('dummyTemplateType');
              if (!template) {
                return undefined;
              }
              const templateId: number = template ? template.id : 0;
              return this.sender.mailApiService.getMailTemplate(templateId).pipe(
                map((res: MailTemplateResponse) => {
                  return {
                    value: res ? res.body : undefined,
                  };
                }),
              );
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public update(): void {
    if (this.sender.model.department) {
      this.sender.model.cc_single = [];
      this.contacts = [
        this.sender.model.department.dynamicData.contact1,
        this.sender.model.department.dynamicData.contact2,
        this.sender.model.department.dynamicData.contact3,
        this.sender.model.department.dynamicData.contact4,
        this.sender.model.department.dynamicData.contact5,
      ].filter((c) => c && c.name && c.email && !c.emailAvailable);

      if (environment.rpaUserIds.includes(this.sender.authService.loginUser.id)) {
        // ログインしているユーザーがRPAの場合、TOにチェックを入れず、CCを設定しない
        // see https://benesse-g.backlog.jp/view/MACH_PJ-157
      } else {
        // 通常のユーザーの場合、TOにチェックを入れて、CC, BCCを設定
        this.contacts.forEach((c, i) => {
          if (!i) {
            this.sender.model.to = [c.email];
          } else {
            this.sender.model.cc_single.push(c.email);
          }
        });
        this.sender.model.cc = this.sender.model.department.enterpriseDepartmentUsers
          .filter(
            (u: EnterpriseDepartmentUser) => u.type === EnterpriseDepartmentUserType.RA || u.type === EnterpriseDepartmentUserType.アシ,
          )
          .sort((a: EnterpriseDepartmentUser, b: EnterpriseDepartmentUser) => (a.type > b.type ? 1 : -1))
          .map((u: EnterpriseDepartmentUser) => u.userId);
      }
      this.sender.model.bcc = [this.sender.authService.loginUser.id];
    }
  }

  save(toSave: any): void {
    this.sender.mailApiService
      .sendEnterpriseMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
      .subscribe((res) => this.sender.afterSend(res));
  }
}
