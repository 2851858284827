import { ProgressDetailComponent } from './progress-detail/progress-detail.component';
import { ProgressListExportPageComponent } from './progress-list-export-page/progress-list-export-page.component';
import { ProgressListPageComponent } from './progress-list-page/progress-list-page.component';
import { ProgressReportPageComponent } from './progress-report-page/progress-report-page.component';

export * from './progress-list-page/progress-list-page.component';
export * from './progress-report-page/progress-report-page.component';
export * from './progress-list-export-page/progress-list-export-page.component';

export const PROGRESS_PAGES = [
  ProgressListPageComponent,
  ProgressDetailComponent,
  ProgressReportPageComponent,
  ProgressListExportPageComponent,
];
