import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { ProgressCreateDialogComponent, ProgressScheduleAdjustDialogComponent } from '@agent-ds/progress/components';
// tslint:disable-next-line:max-line-length
import { BulkProgressChangeDialogConfig } from '@agent-ds/shared/components/bulk-progress-change-dialog/bulk-progress-change-dialog-interface';
// tslint:disable-next-line:max-line-length
import { BulkProgressChangeDialogComponent } from '@agent-ds/shared/components/bulk-progress-change-dialog/bulk-progress-change-dialog.component';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { BatchResult, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { getProgressStatusLabel, NULL_SELECTED_VALUE, PROGRESS_BULK_EDIT, PROGRESS_STATUSES } from '@agent-ds/shared/constants';
import { ProgressActionColor, ProgressActionType, ProgressStatusCode } from '@agent-ds/shared/enums';
import {
  EnterpriseDepartmentUserType,
  Job,
  JobUserCheckResponse,
  MailTemplateResponse,
  ProgressAction,
  ProgressBulkEditAction,
  ProgressBulkEditRequest,
  ProgressHistoryRemarksUpdateRequest,
  ProgressItemJobUserResponse,
  ProgressItemResponse,
  ProgressSearchParams,
  ProgressSearchResponse,
  ProgressStakeholderEmailResponse,
  ProgressUpdateWithActionRequest,
  StudentDetail,
  Team,
  User,
} from '@agent-ds/shared/interfaces';
import { DetailPage, FormMeta, RefreshEvent, RowMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import {
  AuthService,
  DialogService,
  DynamicFieldService,
  JobApiService,
  MailApiService,
  StudentApiService,
} from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { ProgressApiService } from '@agent-ds/shared/services/api/progress-api.service';
import { UserApiService } from '@agent-ds/shared/services/api/user-api.service';
import { cleanObject, deepClone, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, forkJoin, Observable, of, Subscription, SubscriptionLike } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { ProgressDetailComponent } from '../../../progress/pages/progress-detail/progress-detail.component';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../dialog/dialog-ref';
import { MailSendFlowComponent } from '../mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '../mail-send/config';
import { ProgressBulkConfig } from '../mail-send/configs/progress-bulk-config';
import { ProgressConsultConfig } from '../mail-send/configs/progress-consult-config';
import { ProgressInrowJobPostingConfig } from '../mail-send/configs/progress-inrow-job-posting-config';
import { ProgressJobPostingConfig } from '../mail-send/configs/progress-job-posting-config';
import { ProgressNgEndConfig } from '../mail-send/configs/progress-ng-end-config';
import { ProgressOfferConfig } from '../mail-send/configs/progress-offer-config';
import { ProgressRecommendConfig } from '../mail-send/configs/progress-recommend-config';
import { ProgressSendInterviewInfoConfig } from '../mail-send/configs/progress-send-interview-info-config';
import { ProgressSendResumeConfig } from '../mail-send/configs/progress-send-resume-config';
import { PopupControlComponent } from '../popup-control/popup-control.component';
import { SelectComponent } from '../select/select.component';
import { PROGRESS_LIST_TABLE_CONFIG } from './progress-list-table-config';

@Component({
  selector: 'ag-progress-list',
  templateUrl: './progress-list.component.html',
  styleUrls: ['./progress-list.component.scss'],
})
export class ProgressListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild(SelectComponent, { static: false }) progressStatusSelect: SelectComponent;
  @ViewChild('status', { static: false }) statusTemplate: TemplateRef<any>;
  @ViewChild('applicant', { static: false }) applicantTemplate: TemplateRef<any>;
  @ViewChild('applicantContact', { static: false }) applicantContactTemplate: TemplateRef<any>;
  @ViewChild('jobGeneral', { static: false }) jobTemplate: TemplateRef<any>;
  @ViewChild('jobContact', { static: false }) jobContactTemplate: TemplateRef<any>;
  @ViewChild('cellArrow', { static: true }) cellArrowTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('missingInfoDialog', { static: false }) missingInfoDialogTemplate: TemplateRef<any>;

  @Input() requestObject: ProgressSearchParams = { status: [] };
  @Input() disableDetailFullScreen = false;
  @Input() disableBulkUpdateButton = false;
  @Input() student: StudentDetail;
  @Input() job: Job;
  @Output() statusFilterChange = new EventEmitter<number[]>();
  @Output() errorGetList = new EventEmitter<HttpErrorResponse>();

  tableConfig: TableConfig;
  initialized = false;
  ProgressActionType = ProgressActionType;
  ProgressActionColor = ProgressActionColor;
  getStatusLabel = getProgressStatusLabel;
  offerConfig = new ProgressOfferConfig();
  consultConfig = new ProgressConsultConfig();
  bulkConfig = new ProgressBulkConfig();
  recommendConfig = new ProgressRecommendConfig();
  ngEndConfig = new ProgressNgEndConfig();
  ProgressSendInterviewInfoConfig = ProgressSendInterviewInfoConfig;

  readonly PROGRESS_STATUSES = PROGRESS_STATUSES;
  canBulkEdit = false;
  progressTypeSelectOptions = [{ label: NULL_SELECTED_VALUE, value: undefined }, { label: 'AG', value: 0 }, { label: 'Plus', value: 1 }];
  progressStatusCodesSelectOptions = Object.keys(PROGRESS_STATUSES)
    .filter((code) => +code !== 210)
    .map((statusCode) => {
      const code = +statusCode;
      if (code === 100) {
        return [
          { label: '1次面接設定中', value: 503 },
          { label: '2次以降面接設定中', value: 506 },
          // { label: '最終前面接設定中', value: 510 },
          // { label: '最終面接設定中', value: 520 },
        ];
      } else if (code === 110) {
        return [
          { label: '1次面接確認中', value: 504 },
          { label: '2次以降面接確認中', value: 507 },
          { label: '最終前面接確認中', value: 511 },
          { label: '最終面接確認中', value: 521 },
        ];
      } else if (code === 120) {
        return [
          { label: '1次面接結果待ち', value: 505 },
          { label: '2次以降面接結果待ち', value: 508 },
          { label: '最終前面接結果待ち', value: 512 },
          { label: '最終面接結果待ち', value: 522 },
        ];
      }
      return {
        label: PROGRESS_STATUSES[statusCode].label,
        value: code,
      };
    })
    .flatten();
  eventEditors: {
    [progressId: number]: {
      seminarAt: any;
      seminarType: null | 99 | 100 | 1;
      seminarAtMailSendFlag: boolean;
      requestObject: ProgressUpdateWithActionRequest;
      inSave: boolean;
    };
  } = {};
  remarkEditors: {
    [progressId: number]: {
      remarks: string;
      edit: boolean;
      show: boolean;
    };
  } = {};
  stopReasonEditors: {
    [progressId: number]: {
      stopReason: string;
      edit: boolean;
      show: boolean;
    };
  } = {};
  readonly editorMeta: { [key: number]: FormMeta } = {};
  baseEditorMeta: FormMeta;
  dateTime: RowMeta;

  ckeckJobUsersInProgress = false;
  jobUserChecks: JobUserCheckResponse[] | null = null;

  private dialogRef: DialogRef;

  private refreshSubscription: Subscription;
  private itemCheckedSubscription: Subscription;

  public get hasCheckedItems() {
    return this.table && this.table.hasCheckedItems;
  }

  constructor(
    private masterApiService: MasterApiService,
    private userApiService: UserApiService,
    private progressApiService: ProgressApiService,
    private jobApiService: JobApiService,
    public readonly datePipe: SafeDatePipe,
    private cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private location: Location,
    private dynamicService: DynamicFieldService,
    private studentApiService: StudentApiService,
    private mailApiService: MailApiService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.refreshSubscription = this.progressApiService.refreshEvent.subscribe(() => this.refreshData(true, true));
    this.checkRoute();
    this.dateTime = this.dynamicService.getFormRows({ fieldType: 'date', fieldName: 'seminarAt', displayType: 'date+time' })[0];
    this.dateTime.fields.push({
      type: 'radio',
      name: 'seminarType',
      labelField: 'label',
      valueField: 'value',
      options: [{ label: NULL_SELECTED_VALUE, value: 1 }, { label: '最終前面接', value: 99 }, { label: '最終面接', value: 100 }],
    });
    this.baseEditorMeta = {
      groups: [
        {
          class: 'no-title-column no-border no-background',
          rows: [this.dateTime],
        },
      ],
    };
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (
      changes &&
      changes['student'] != null &&
      changes['student'].previousValue !== changes['student'].currentValue &&
      this.progressStatusSelect
    ) {
      this.progressStatusSelect.selectAll();
    }
    if (!changes || (changes['requestObject'] && changes['requestObject'].previousValue !== changes['requestObject'].currentValue)) {
      [...Object.keys(this.eventEditors), ...Object.keys(this.remarkEditors)].removeSame().forEach((key) => this.onCancelClick(+key));
      if (this.table && this.initialized) {
        this.refreshData();
      }
    }
  }

  ngAfterViewInit() {
    this.tableConfig = PROGRESS_LIST_TABLE_CONFIG(
      this.statusTemplate,
      this.applicantTemplate,
      this.applicantContactTemplate,
      this.cellArrowTemplate,
      this.jobTemplate,
      this.jobContactTemplate,
      this.actionsTemplate,
      (date) => this.datePipe.transform(date, 'yyyy/MM/dd (E) HH:mm'),
    );

    this.cdr.detectChanges();
    this.initialized = true;
    if (this.requestObject && this.table) {
      this.refreshData(true);
    }
    if (this.table) {
      this.itemCheckedSubscription = this.table.itemChecked.subscribe(() => this.computeCanBulkEdit());
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.itemCheckedSubscription) {
      this.itemCheckedSubscription.unsubscribe();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public showStudentDelayBtn(status: ProgressStatusCode): boolean {
    return status === 70 || status === 90 || status === 100;
  }

  public showSeminarDate(status: ProgressStatusCode): boolean {
    return status === 80 || status === 90 || status === 110 || status === 120;
  }

  public showEnterpriseDocReceivedDate(status: ProgressStatusCode): boolean {
    return status === 60;
  }

  public showOfferedDate(status: ProgressStatusCode): boolean {
    return status === 130 || status === 140;
  }

  public showInvoiceReceivedDate(status: ProgressStatusCode): boolean {
    return status === 150;
  }

  public showInvoiceProcessedDate(status: ProgressStatusCode): boolean {
    return status === 160;
  }

  public getContactUser(userId: number): Observable<User> {
    return userId != null ? this.userApiService.getAll().pipe(map((users: User[]) => users.find((user) => user.id === userId))) : of(null);
  }

  public getContactTeamName(userId: number): Observable<string> {
    return this.getContactUser(userId).pipe(concatMap((user: User) => (user ? this.getTeamNameForUser(user) : of(null))));
  }

  public getJobContactUser(jobUsers: ProgressItemJobUserResponse[], type: 0 | 1): Observable<User> {
    const jobUserType = type === 0 ? EnterpriseDepartmentUserType.RA : type === 1 ? EnterpriseDepartmentUserType.PA : null;
    const jobUser = jobUsers && jobUsers.length ? jobUsers.find((user) => user.enterpriseDepartmentUser.type === jobUserType) : null;
    return jobUser && jobUser.enterpriseDepartmentUser && jobUser.enterpriseDepartmentUser.userId != null
      ? this.getContactUser(jobUser.enterpriseDepartmentUser.userId)
      : of(null);
  }

  public getJobContactTeamName(jobUsers: ProgressItemJobUserResponse[], type: 0 | 1): Observable<string> {
    return this.getJobContactUser(jobUsers, type).pipe(concatMap((user: User) => (user ? this.getTeamNameForUser(user) : of(null))));
  }

  private getTeamNameForUser(user: User): Observable<string> {
    return this.masterApiService.getTeams().pipe(
      map((teams: Team[]) => {
        const foundTeam = teams.find((team) => team.id === user.teamId);
        return foundTeam ? foundTeam.name : '';
      }),
    );
  }

  public onProgressSelect(progress: ProgressItemResponse): void {
    ProgressDetailComponent.instance.disableFullScreen = this.disableDetailFullScreen;
    ProgressDetailComponent.instance.referenceId = progress ? progress.id : null;
    ProgressDetailComponent.instance.open();
    this.cdr.detectChanges();
  }

  public onProgressFilterChange() {
    this.statusFilterChange.emit();
    this.refreshData();
  }

  public onStudentDelayClick(progress: ProgressItemResponse, event: MouseEvent) {
    event.stopPropagation();
    this.progressApiService
      .updateWithoutAction(progress.id, {
        type: progress.type,
        hasInterview: progress.hasInterview,
        n: progress.n,
        seminarType: progress.seminarType,
        isStudentDelay: progress.isStudentDelay > 0 ? 0 : 1,
        seminarAt: progress.seminarAt,
        dynamicData: progress.dynamicData,
      })
      .subscribe(() => {
        progress.isStudentDelay = progress.isStudentDelay > 0 ? 0 : 1;
        this.progressApiService.refreshEvent.emit();
      });
  }

  public onProgressActionClick(progress: ProgressItemResponse, action: ProgressAction): void {
    if (!progress || !action) {
      return;
    }

    switch (action.type) {
      case ProgressActionType.DELETE:
        this.onDeleteActionClick(progress.id);
        break;
      case ProgressActionType.DIALOG:
        this.onDialogActionClick(progress, action.dialog, action.id);
        break;
      case ProgressActionType.EDITOR:
        this.onEditorActionClick(progress.id, action.request ? action.request(progress, action.baseRequest) : null, action.seminarValues);
        break;
      case ProgressActionType.MAIL:
        this.onMailActionClick(progress, action.mailConfig);
        break;
      case ProgressActionType.OPERATION:
        this.onOperationActionClick(progress.id, action.request ? action.request(progress, action.baseRequest) : null);
        break;
      default:
        return;
    }
  }

  public onMailActionClick(progress: ProgressItemResponse, mailConfig: any): void {
    if (!mailConfig) {
      return;
    }

    const mailConfigInstance = new mailConfig();

    const progressObj: any = {};
    if (progress) {
      if (this.eventEditors[progress.id]) {
        progressObj.progressSeminarAt = new Date(this.eventEditors[progress.id].seminarAt).toAsialDateTimeString() || progress.seminarAt;
        progressObj.progressSeminarType = this.eventEditors[progress.id].seminarType;
      } else {
        progressObj.progressSeminarAt = progress.seminarAt;
        progressObj.progressSeminarType = progress.seminarType;
      }
      progressObj.progressId = progress.id;
      progressObj.progressN = progress.n;
      progressObj.progressHasInterview = progress.hasInterview;
      progressObj.progressSeminarAtMailSendFlag = 1;
      progressObj.progressIsStudentDelay = progress.isStudentDelay;
      progressObj.progressType = progress.type;
      progressObj.progressStatus = progress.status;
    }

    if (mailConfigInstance instanceof ProgressRecommendConfig) {
      this.openMail(mailConfigInstance, progress, true);
    } else if (mailConfigInstance instanceof ProgressInrowJobPostingConfig || mailConfigInstance instanceof ProgressJobPostingConfig) {
      forkJoin(
        this.studentApiService.getSuggestions(undefined, [progress.studentId]),
        this.jobApiService.getSuggestions(undefined, [progress.jobId]),
      ).subscribe(([students, jobs]) => {
        MailSendFlowComponent.instance.config = mailConfigInstance;
        mailConfigInstance.setParams({
          students: students,
          jobs: jobs,
          ...progressObj,
        });
        MailSendFlowComponent.instance.start();
        const sentSub = MailSendFlowComponent.instance.sent.subscribe(() => {
          this.refreshData(true, true);
          if (progress && this.eventEditors[progress.id]) {
            this.eventEditors[progress.id] = null;
          }
          sentSub.unsubscribe();
        });
        const closedSub = MailSendFlowComponent.instance.closed.subscribe(() => {
          sentSub.unsubscribe();
          closedSub.unsubscribe();
        });
      });
    } else if (mailConfigInstance instanceof ProgressSendInterviewInfoConfig) {
      this.progressApiService.getStakeholders([progress.id]).subscribe((stakeholders: ProgressStakeholderEmailResponse[]) => {
        if (stakeholders.length) {
          MailSendFlowComponent.instance.config = mailConfigInstance;
          mailConfigInstance.setParams({
            stakeholders: stakeholders[0],
            progressType: progress.type,
            studentId: progress.studentId,
            jobId: progress.jobId,
            enterpriseId: progress.enterprise.id,
            ...progressObj,
          });
          MailSendFlowComponent.instance.start();
          if (progress.status === 70 || progress.status === 80 || progress.status === 100 || progress.status === 110) {
            const sub = MailSendFlowComponent.instance.sent.subscribe(() => {
              this.progressApiService
                .updateWithAction(progress.id, {
                  type: progress.type,
                  hasInterview: progress.hasInterview,
                  n:
                    progress.status === 70 && (progressObj.progressSeminarType === 99 || progressObj.progressSeminarType === 100)
                      ? progress.n + 1
                      : progress.n,
                  seminarType:
                    progressObj.progressSeminarType === 1 && progressObj.progressStatus === 70 ? 0 : progressObj.progressSeminarType,
                  seminarAt: progressObj.progressSeminarAt,
                  isStudentDelay: progress.isStudentDelay,
                  action:
                    progress.status === 70 && progressObj.progressSeminarType === 1
                      ? 75
                      : progress.status === 70 && (progressObj.progressSeminarType === 99 || progressObj.progressSeminarType === 100)
                      ? 76
                      : progress.status === 80
                      ? 82
                      : progress.status === 100
                      ? 105
                      : progress.status === 110
                      ? 112
                      : null,
                  seminarAtMailSendFlag: 0,
                })
                .subscribe(() => {
                  // this.refreshData(true, true);
                  this.progressApiService.refreshEvent.emit();
                  if (progress && this.eventEditors[progress.id]) {
                    this.eventEditors[progress.id] = null;
                  }
                });
              sub.unsubscribe();
            });
            const closedSub = MailSendFlowComponent.instance.closed.subscribe(() => {
              sub.unsubscribe();
              closedSub.unsubscribe();
            });
          }
        }
      });
    } else if (mailConfigInstance instanceof ProgressSendResumeConfig) {
      if (this.authService.authInfo.zone !== 'zone3') {
        PopupControlComponent.instance.open({
          title: null,
          content: 'ZONE3専用です。',
          confirmText: 'OK',
          confirmCallback: () => false,
        });
        return;
      }
      forkJoin(
        this.studentApiService.getSuggestions(undefined, [progress.studentId]),
        this.jobApiService.getSuggestions(undefined, [progress.jobId]),
        this.jobApiService.ckeckJobUsers([progress.jobId]),
      ).subscribe(([students, jobs, checkedJobs]) => {
        MailSendFlowComponent.instance.config = mailConfigInstance;
        mailConfigInstance.setParams({
          student: students.length ? students[0] : [],
          enterpriseId: progress.enterprise.id,
          jobs: [{ ...jobs[0], jobUsers: checkedJobs[0].jobUsers }],
          ...progressObj,
        });
        MailSendFlowComponent.instance.start();
        const sentSub = MailSendFlowComponent.instance.sent.subscribe(() => {
          this.refreshData(true, true);
          if (progress && this.eventEditors[progress.id]) {
            this.eventEditors[progress.id] = null;
          }
          sentSub.unsubscribe();
        });
        const closedSub = MailSendFlowComponent.instance.closed.subscribe(() => {
          sentSub.unsubscribe();
          closedSub.unsubscribe();
        });
      });
    }
  }

  private onEditorActionClick(
    progressId: number,
    requestObject: ProgressUpdateWithActionRequest,
    seminarValues: { label: string; value: number }[],
  ): void {
    if (!requestObject) {
      return;
    }

    if (seminarValues) {
      this.editorMeta[progressId] = deepClone(this.baseEditorMeta);
      this.editorMeta[progressId].groups[0].rows[0].fields.last().options = seminarValues;
    } else {
      this.editorMeta[progressId] = this.baseEditorMeta;
    }

    this.eventEditors[progressId] = {
      seminarAt: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
      seminarType: 1,
      seminarAtMailSendFlag: false,
      requestObject: requestObject,
      inSave: false,
    };
  }

  private onOperationActionClick(progressId: number, requestObject: ProgressUpdateWithActionRequest): void {
    if (!requestObject) {
      return;
    }

    this.progressApiService.updateWithAction(progressId, requestObject).subscribe((resp: { id: number }) => {
      // this.refreshData(true, true);
      this.progressApiService.refreshEvent.emit();
    });
  }

  private onDialogActionClick(progress: ProgressItemResponse, dialog: Type<any>, actionId: number): void {
    if (!dialog) {
      return;
    }

    let dataObj = {};
    if (actionId === 140) {
      this.progressApiService.getContractInfo(progress.id).subscribe((info) => {
        if (info.approvedContractIds.length && info.enterpriseBillingAddressIds.length) {
          dataObj = {
            progressId: progress.id,
            enterpriseId: progress.enterprise.id,
            progressType: progress.type,
            jobUsers: progress.job.jobUsers,
            caUserId: progress.student.caUserId,
          };
          const dRef = this.dialog.open(dialog, { data: dataObj });
          const dCloseSubscription = dRef.afterClosed.subscribe((res) => {
            if (res) {
              this.refreshData(true, true);
            }
            dCloseSubscription.unsubscribe();
          });
        } else {
          PopupControlComponent.instance.open({
            title: '必要な情報が入力されていません',
            content: '承認済みの契約が登録されていないか、\n請求先が登録されていません。',
            confirmText: '閉じる',
            confirmCallback: () => false,
          });
        }
      });
      return;
    } else if (actionId === 74) {
      dataObj = {
        studentIds: [progress.studentId],
        jobId: progress.jobId,
        progressStatus: progress.status,
        progressN: progress.n,
        progressIds: [progress.id],
        progressType: progress.type,
        title: dialog === ProgressScheduleAdjustDialogComponent ? '求職者へ日程調整送信' : null,
      };
    }

    const dialogRef = this.dialog.open(dialog, { data: dataObj });
    const dialogCloseSubscription = dialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.refreshData(true, true);
      }
      dialogCloseSubscription.unsubscribe();
    });
  }

  private onDeleteActionClick(progressId: number): void {
    this.progressApiService.delete(progressId).subscribe(() => this.progressApiService.refreshEvent.emit(RefreshEvent.DELETE));
  }

  public onSaveClick(progress: ProgressItemResponse) {
    if (this.eventEditors[progress.id] && this.eventEditors[progress.id].inSave) {
      return;
    }

    let eventRequestObject: ProgressUpdateWithActionRequest | null = null;
    let remarksRequestObject: ProgressHistoryRemarksUpdateRequest | null = null;
    let remarksRequest: Observable<{ id: number }> | null = null;
    let eventRequest: Observable<{ id: number }> | null = null;

    if (this.remarkEditors[progress.id]) {
      const remarks = this.remarkEditors[progress.id].remarks || '';
      remarksRequestObject = { remarks: remarks };
      remarksRequest = this.progressApiService.updateHistoryRemarks(progress.id, progress.latestRevision, remarksRequestObject);
    }

    if (this.eventEditors[progress.id]) {
      this.eventEditors[progress.id].inSave = true;
      const event = this.eventEditors[progress.id];

      if (event.requestObject && event.seminarAt) {
        eventRequestObject = {
          ...event.requestObject,
          ...{
            seminarAt: new Date(event.seminarAt).toAsialDateTimeString(),
            seminarType: event.seminarType || 1,
            seminarAtMailSendFlag: event.seminarAtMailSendFlag ? 0 : 1,
          },
        };
        if (progress.status === 70) {
          if (eventRequestObject.seminarType === 1) {
            eventRequestObject.seminarType = 0;
            eventRequestObject.action = 72;
            eventRequestObject.n = progress.n;
          } else if (eventRequestObject.seminarType === 99 || eventRequestObject.seminarType === 100) {
            eventRequestObject.action = 74;
            eventRequestObject.n = (progress.n || 0) + 1;
          }
        }
        eventRequest = this.progressApiService.updateWithAction(progress.id, eventRequestObject);
        if (remarksRequest) {
          remarksRequest = this.progressApiService.updateHistoryRemarks(
            progress.id,
            (progress.latestRevision || 0) + 1,
            remarksRequestObject,
          );
        }
      }

      if (!event.seminarAtMailSendFlag) {
        this.progressApiService.getStakeholders([progress.id]).subscribe(
          (stakeholders: ProgressStakeholderEmailResponse[]) => {
            const stakeholder = stakeholders && stakeholders.length ? stakeholders[0] : null;
            if (stakeholders && stakeholder.studentInfo.mainAvailable === '不可') {
              PopupControlComponent.instance.open({
                title: 'エラー',
                content: 'E-MAIL(メイン)が「送信不可」です。',
                confirmText: '閉じる',
                confirmCallback: () => {
                  this.eventEditors[progress.id].inSave = false;
                },
              });
            } else if (stakeholder) {
              this.mailApiService
                .getMailTemplates()
                .pipe(
                  map((templates: MailTemplateResponse[]) =>
                    templates.find((template) => template.id === (progress.type === 0 ? 14 : progress.type === 1 ? 19 : -1)),
                  ),
                )
                .subscribe(
                  (template: MailTemplateResponse) => {
                    if (template) {
                      const mailRequestObject = {
                        to: stakeholder.studentInfo.mainAvailable !== '不可' ? [stakeholder.studentInfo.main] : null,
                        cc: [
                          stakeholder.studentInfo.subAvailable !== '不可' ? stakeholder.studentInfo.sub : null,
                          ...stakeholder.jobUsersInfo.filter((jobUser) => jobUser.type === 0).map((jobUser) => jobUser.email),
                        ].filter((v) => v),
                        bcc: [
                          ...stakeholder.jobUsersInfo
                            .filter(
                              (jobUser) =>
                                jobUser.type ===
                                (progress.type === 0
                                  ? EnterpriseDepartmentUserType.RA
                                  : progress.type === 1
                                  ? EnterpriseDepartmentUserType.PA
                                  : -1),
                            )
                            .map((jobUser) => jobUser.email),
                        ].filter((v) => v),
                        userId: this.authService.loginUser.id,
                        from: this.authService.loginUser.email,
                        subject: template.subject,
                        text: template.body,
                        templateTypeId: 8,
                        jobId: progress.jobId,
                      };
                      const mailRequest = this.mailApiService
                        .replaceTemplate(mailRequestObject.templateTypeId, {
                          from: mailRequestObject.from,
                          to: mailRequestObject.to,
                          subject: mailRequestObject.subject,
                          text: mailRequestObject.text,
                          studentId: progress.studentId,
                          jobId: progress.jobId,
                        })
                        .pipe(
                          mergeMap((res) => {
                            mailRequestObject.subject = res.subject;
                            mailRequestObject.text = res.body;
                            return this.mailApiService.sendStudentMail(progress.studentId, mailRequestObject);
                          }),
                        );

                      if (eventRequest && remarksRequest) {
                        eventRequest = eventRequest.pipe(mergeMap(() => remarksRequest.pipe(mergeMap(() => mailRequest))));
                      } else if (remarksRequest) {
                        eventRequest = remarksRequest.pipe(mergeMap(() => mailRequest));
                      } else {
                        eventRequest = eventRequest.pipe(mergeMap(() => mailRequest));
                      }
                      eventRequest.subscribe(
                        () => {
                          this.refreshData(true, true);
                          this.eventEditors[progress.id].inSave = false;
                          this.onCancelClick(progress.id);
                        },
                        () => {
                          this.eventEditors[progress.id].inSave = false;
                        },
                      );
                    } else {
                      this.eventEditors[progress.id].inSave = false;
                    }
                  },
                  () => {
                    this.eventEditors[progress.id].inSave = false;
                  },
                );
            } else {
              this.eventEditors[progress.id].inSave = false;
            }
          },
          () => {
            this.eventEditors[progress.id].inSave = false;
          },
        );
      } else if (eventRequest || remarksRequest) {
        if (eventRequest && remarksRequest) {
          eventRequest = eventRequest.pipe(mergeMap(() => remarksRequest));
        } else if (remarksRequest) {
          eventRequest = remarksRequest;
        }
        eventRequest.subscribe(() => {
          // this.refreshData(true, true);
          this.progressApiService.refreshEvent.emit();
          this.eventEditors[progress.id].inSave = false;
          this.onCancelClick(progress.id);
        });
      } else {
        this.eventEditors[progress.id].inSave = false;
      }
    } else {
      remarksRequest.subscribe(() => {
        this.refreshData(true, true);
        this.onCancelClick(progress.id);
      });
    }
  }

  public onStopReasonSaveClick(progress: ProgressItemResponse) {
    if (!this.stopReasonEditors[progress.id]) {
      this.onCancelClick(progress.id);
      return;
    }

    const stopReasonText = this.stopReasonEditors[progress.id].stopReason || '';

    this.progressApiService
      .updateWithoutAction(progress.id, {
        type: progress.type,
        hasInterview: progress.hasInterview,
        n: progress.n,
        seminarType: progress.seminarType,
        seminarAt: progress.seminarAt != null ? new Date(progress.seminarAt).toAsialDateTimeString() : null,
        isStudentDelay: progress.isStudentDelay,
        dynamicData: {
          ...progress.dynamicData,
          stopReason: stopReasonText,
        },
      })
      .subscribe(
        () => {
          this.refreshData(true, true);
          this.onCancelClick(progress.id);
        },
        (error: HttpErrorResponse) => {
          const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createErrorDialog(error);
          this.dialog.open(ConfirmDialogComponent, dialogConfig);
        },
      );
  }

  public onCancelClick(progressId: number) {
    if (this.eventEditors[progressId]) {
      delete this.eventEditors[progressId];
    }
    if (this.remarkEditors[progressId]) {
      delete this.remarkEditors[progressId];
    }
    if (this.stopReasonEditors[progressId]) {
      delete this.stopReasonEditors[progressId];
    }
  }

  public editRemarks(progressId: number, remarks: string) {
    this.remarkEditors[progressId] = {
      remarks: remarks,
      edit: true,
      show: true,
    };
  }

  public toggleRemarksDisplay(progressId: number) {
    if (!this.remarkEditors[progressId]) {
      this.remarkEditors[progressId] = {
        remarks: '',
        edit: false,
        show: true,
      };
      return;
    }
    this.remarkEditors[progressId].show = !this.remarkEditors[progressId].show;
  }

  public editStopReason(progressId: number, stopReason: string) {
    this.stopReasonEditors[progressId] = {
      stopReason: stopReason,
      edit: true,
      show: true,
    };
  }

  public toggleStopReasonDisplay(progressId: number) {
    if (!this.stopReasonEditors[progressId]) {
      this.stopReasonEditors[progressId] = {
        stopReason: '',
        edit: false,
        show: true,
      };
      return;
    }
    this.stopReasonEditors[progressId].show = !this.stopReasonEditors[progressId].show;
  }

  loadProgress = (page, pageSize, sortingField, sortingOrder): Observable<BatchResult> => {
    return this.progressApiService
      .getList(
        cleanObject({
          ...this.requestObject,
          status: this.requestObject ? this.requestObject.status || [] : [],
          sort: sortingField,
          order: sortingOrder,
          from: page * pageSize,
          size: pageSize,
        }),
      )
      .pipe(
        map((resp: ProgressSearchResponse) => ({
          result: resp.progresses,
          totalSize: resp.total ? resp.total : 0,
        })),
        catchError((error: HttpErrorResponse) => {
          // ネットワークに繋がらなかったときはエラーを通知する
          this.errorGetList.emit(error);
          return EMPTY;
        }),
      );
  };

  private refreshData(dataOnly?: boolean, noScroll?: boolean) {
    this.table.reset(dataOnly, noScroll);
    this.table.next();
    this.computeCanBulkEdit();
  }

  public isValidSelectedItemsForBulkScheduleAdjust() {
    const selectedProgresses = this.table && this.table.checkedItems;
    if (!selectedProgresses || !selectedProgresses.length) {
      return false;
    }
    if (selectedProgresses.length === 1 && (selectedProgresses[0].status === 70 || selectedProgresses[0].status === 100)) {
      return true;
    }

    const isJobIdSame = selectedProgresses
      .map((progress: ProgressItemResponse) => progress.jobId)
      .every((jobId) => jobId === selectedProgresses[0].jobId);
    const isStatusSame = selectedProgresses
      .map((progress: ProgressItemResponse) => progress.status)
      .every(
        (status) =>
          status === selectedProgresses[0].status && (selectedProgresses[0].status === 70 || selectedProgresses[0].status === 100),
      );
    let isNSame = true;
    if (selectedProgresses[0].status === 100) {
      isNSame = selectedProgresses.map((progress: ProgressItemResponse) => progress.n).every((n) => n === selectedProgresses[0].n);
    }
    return isJobIdSame && isStatusSame && isNSame;
  }

  public openProgressBulkScheduleAdjustDialog(): void {
    if (!this.isValidSelectedItemsForBulkScheduleAdjust()) {
      return;
    }

    const selectedProgresses: ProgressItemResponse[] = this.table && this.table.checkedItems;
    if (!selectedProgresses || !selectedProgresses.length) {
      return;
    }

    const dialogRef = this.dialog.open(ProgressScheduleAdjustDialogComponent, {
      data: {
        studentIds: selectedProgresses.map((progress) => progress.studentId),
        jobId: selectedProgresses[0].jobId,
        progressStatus: selectedProgresses[0].status,
        progressN: selectedProgresses[0].n,
        progressSeminarType: selectedProgresses[0].seminarType,
        progressIds: selectedProgresses.map((progress) => progress.id),
        progressType: selectedProgresses[0].type,
      },
    });
    const dialogCloseSubscription = dialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.refreshData(true, true);
      }
      dialogCloseSubscription.unsubscribe();
    });
  }

  public openProgressBulkDeclineDialog(): void {
    if (!this.hasCheckedItems) {
      return;
    }

    const config: ConfirmDialogConfig = {
      title: '一括辞退',
      messages: ['一括辞退をしてもよろしいですか'],
      style: {
        height: '245px',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialog.open(ConfirmDialogComponent, config, (result: boolean) => {
      if (result) {
        return this.progressApiService
          .bulkDecline(this.table.checkedItems.map((item) => (item && item['id'] ? item['id'] : null)).filter((v) => v != null))
          .pipe(
            tap(() => {
              // this.refreshData(true, true);
              this.progressApiService.refreshEvent.emit();
            }),
          );
      }
    });
  }

  public openProgressBulkEditDialog(): void {
    if (!this.checkCanBulkEdit()) {
      return;
    }

    const config: BulkProgressChangeDialogConfig = {
      data: {
        options: deepClone(PROGRESS_BULK_EDIT[this.table.checkedItems[0].status]),
      },
    };
    this.dialog.open(BulkProgressChangeDialogComponent, config, (result: ProgressBulkEditAction) => {
      if (result) {
        this.openProgressBulkEditConfirmationDialog(result);
      }
    });
  }

  private openProgressBulkEditConfirmationDialog(result: ProgressBulkEditAction) {
    const config: ConfirmDialogConfig = {
      title: '一括変更',
      messages: [`選択した全ての進捗ステータスを「${result.label(undefined, 1)}」へ一括で変更します。よろしいですか？`],
      style: {
        height: '245px',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialog.open(ConfirmDialogComponent, config, (confirmed: boolean) => {
      if (!confirmed) {
        return;
      }

      const progressIds = this.table.checkedItems.map((item) => (item && item['id'] ? item['id'] : null)).filter((v) => v != null);
      const request: ProgressBulkEditRequest = {
        toStatus: result.toStatus,
        fromStatus: result.fromStatus,
        stopReason: result.stopReason,
        remarks: result.remarks,
      };
      return this.progressApiService.bulkEdit(progressIds, request).pipe(
        tap(() => {
          this.canBulkEdit = false;
          this.refreshData(true, true);
          this.progressApiService.refreshEvent.emit();
        }),
      );
    });
  }

  private checkCanBulkEdit(): boolean {
    if (!this.hasCheckedItems) {
      return false;
    }

    const status = this.table.checkedItems[0].status;

    return Object.keys(PROGRESS_BULK_EDIT).includes('' + status) && this.checkedItemsStatusIs(status);
  }

  directTransition(progress: ProgressItemResponse, transition: ProgressBulkEditAction) {
    const requestDto: ProgressBulkEditRequest = {
      fromStatus: transition.fromStatus,
      toStatus: transition.toStatus,
      remarks: transition.remarks,
      stopReason: transition.stopReason,
    };
    const directSubs = this.progressApiService.bulkEdit([progress.id], requestDto).subscribe(() => {
      this.refreshData(true, true);
      this.progressApiService.refreshEvent.emit();
      directSubs.unsubscribe();
    });
  }

  private checkRoute() {
    if (this.router.url.endsWith('/progresses/add')) {
      this.openProgressCreateDialog();
    }
  }

  checkedItemsStatusIs(status: number): boolean {
    return this.table ? this.table.checkedItems.every((i) => i.status === status) : false;
  }

  public openProgressCreateDialog(origUrl = this.location.path()): void {
    if (this.dialogRef) {
      return;
    }
    this.location.go('/progresses/add');
    ProgressDetailComponent.instance.close();
    this.dialogRef = this.dialog.open(ProgressCreateDialogComponent, {
      data: {
        studentId: this.student ? this.student.id : null,
        jobId: this.job ? this.job.id : null,
        enterpriseId: this.requestObject ? this.requestObject.enterpriseId : null,
      },
    });
    let closeSubscription: Subscription;
    let navigationSubscription: SubscriptionLike;
    const unsubscribe = () => {
      if (closeSubscription) {
        closeSubscription.unsubscribe();
      }
      if (navigationSubscription) {
        navigationSubscription.unsubscribe();
      }
    };
    closeSubscription = this.dialogRef.afterClosed.subscribe(() => {
      this.location.go(origUrl);
      this.dialogRef = null;
      unsubscribe();
    });
    navigationSubscription = this.location.subscribe(() => {
      this.dialogRef.close();
      unsubscribe();
    });
  }

  openMail(config: MailSendConfig, progress?: ProgressItemResponse, refreshProgressList?: boolean): void {
    const jobIds = [];
    const studentIds = [];
    const enterprises = [];
    const progressIds = [];
    let students = [];
    let jobs = [];
    if (progress) {
      progressIds.push(progress.id);
      jobIds.push(progress.jobId);
      studentIds.push(progress.studentId);
      enterprises.push({ frontId: progress.enterprise.frontId, id: progress.enterprise.id, name: progress.enterprise.name });
    } else {
      this.table.checkedItems.forEach((item: ProgressItemResponse) => {
        if (!(config instanceof ProgressNgEndConfig) || item.status === 170) {
          progressIds.push(item.id);
          jobIds.push(item.jobId);
          studentIds.push(item.studentId);
          enterprises.push({ frontId: item.enterprise.frontId, id: item.enterprise.id, name: item.enterprise.name });
        }
      });
    }
    const obs = [];
    if (this.student) {
      students.push({
        id: this.student.id,
        frontId: this.student.frontId,
        emailMain: getValueFromObject(this.student.dynamicData, 'emailMain.email'),
        emailMainAvailable: getValueFromObject(this.student.dynamicData, 'emailMain.emailAvailable'),
        emailSub: getValueFromObject(this.student.dynamicData, 'emailSub.email'),
        emailSubAvailable: getValueFromObject(this.student.dynamicData, 'emailSub.emailAvailable'),
        studentUser: this.student.studentUsers,
        firstName: this.student.dynamicData.firstName,
        lastName: this.student.dynamicData.lastName,
      });
    } else {
      obs.push(this.studentApiService.getSuggestions(undefined, studentIds.removeSame()).pipe(tap((res) => (students = res))));
    }
    if (this.job && !(config instanceof ProgressRecommendConfig)) {
      jobs.push({
        id: this.job.id,
        frontId: this.job.frontId,
        position: getValueFromObject(this.job.dynamicData, 'position'),
        enterpriseName: this.job.enterpriseName,
        enterpriseId: this.job.enterpriseId,
        frontEnterpriseId: this.job.frontEnterpriseId,
        enterpriseDepartmentId: this.job.enterpriseDepartmentId,
        jobUsers: this.job.enterpriseDepartmentUsers,
      });
    } else {
      obs.push(this.jobApiService.getSuggestions(undefined, jobIds.removeSame()).pipe(tap((res) => (jobs = res))));
    }
    const progressObj: any = {};
    if (progress) {
      if (this.eventEditors[progress.id]) {
        progressObj.progressSeminarAt = new Date(this.eventEditors[progress.id].seminarAt).toAsialDateTimeString();
        progressObj.progressSeminarType = this.eventEditors[progress.id].seminarType;
      } else {
        progressObj.progressSeminarAt = progress.seminarAt;
        progressObj.progressSeminarType = progress.seminarType;
      }
      progressObj.progressId = progress.id;
      progressObj.progressN = progress.n;
      progressObj.progressHasInterview = progress.hasInterview;
      progressObj.progressSeminarAtMailSendFlag = 1;
      progressObj.progressIsStudentDelay = progress.isStudentDelay;
      progressObj.progressType = progress.type;
      progressObj.progressStatus = progress.status;
    }
    forkJoin(obs).subscribe(() => {
      MailSendFlowComponent.instance.config = config;
      config.setParams({
        progressIds: progressIds,
        students: students,
        jobs: jobs,
        jobId: this.job ? this.job.id : null,
        enterprises: enterprises.removeSame('id'),
        ...progressObj,
      });
      MailSendFlowComponent.instance.start();
      if (refreshProgressList) {
        const sentSub = MailSendFlowComponent.instance.sent.subscribe(() => {
          this.refreshData(true, true);
          if (progress && this.eventEditors[progress.id]) {
            this.eventEditors[progress.id] = null;
          }
          sentSub.unsubscribe();
        });
        const closedSub = MailSendFlowComponent.instance.closed.subscribe(() => {
          sentSub.unsubscribe();
          closedSub.unsubscribe();
        });
      }
    });
  }

  onStudentLinkClick(event: MouseEvent, studentId: number): void {
    this.onLinkClick(event, StudentDetailPageComponent.instance, studentId);
  }

  onCompanyLinkClick(event: MouseEvent, companyId: number): void {
    this.onLinkClick(event, CompanyDetailPageComponent.instance, companyId);
  }

  onJobLinkClick(event: MouseEvent, jobId: number): void {
    this.onLinkClick(event, JobDetailPageComponent.instance, jobId);
  }

  private onLinkClick(event: MouseEvent, detail: DetailPage, id: number): void {
    event.stopPropagation();
    detail.referenceId = id;
    detail.open();
  }

  private computeCanBulkEdit(): void {
    this.canBulkEdit = this.checkCanBulkEdit();
  }
}
