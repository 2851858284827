import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { TabGroupComponent } from '@agent-ds/shared/components/tab-group';
import { ProgressItemResponse, StudentDetail } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { ProgressApiService, StudentApiService } from '@agent-ds/shared/services';
import { deepClone } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentTabs } from './tabs/student-tabs';

@Component({
  selector: 'ag-student-detail-page',
  templateUrl: './student-detail-page.component.html',
  styleUrls: ['./student-detail-page.component.scss'],
})
export class StudentDetailPageComponent extends DetailPage implements OnInit, OnDestroy {
  @ViewChild(TabGroupComponent, { static: false }) tabGroup: TabGroupComponent;
  public sideActions: SideActionConfig[] = [{ phases: ['1000px', '100%'] }];
  protected tabs = StudentTabs;
  protected urlTag = 'students';

  student: StudentDetail;
  progresses: ProgressItemResponse[] = [];

  StudentTabs = StudentTabs;

  public readonly studentDetailLoaded: EventEmitter<StudentDetail> = new EventEmitter();

  constructor(
    private studentApiService: StudentApiService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    private progressApiService: ProgressApiService,
  ) {
    super(router, studentApiService, activeRoute);
  }

  protected fill(onRefresh?: boolean): void {
    this.updateUrl();
    if (this.referenceId && this.studentApiService) {
      this.studentApiService.getDetail(this.referenceId).subscribe((student: StudentDetail) => {
        if (this.tabCode.toUpperCase() === this.tabs[this.tabs.MATCHES]) {
          this.tabCode = this.tabs[this.tabs.OVERVIEW];
        }
        if (!onRefresh) {
          this.studentDetailLoaded.emit(student);
        }
        this.student = student;
      });
      this.progressApiService
        .getList({ studentId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 5 })
        .subscribe((result) => {
          this.progresses = result.progresses || [];
        });
    }
  }
}
