import { GroupMeta } from '@agent-ds/shared/models';
import { HelperBase } from './helper-base';

export class RemarkHelper extends HelperBase {
  metadata: GroupMeta = { rows: [] };

  updateFieldDefinitions(): void {
    this.metadata = {
      // Remarks
      title: '備考',
      class: 'form__group--golden-title-border',
      rows: [...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'remarks'), 'sales.dynamicData', 'fill')],
    };
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.metadata];
  }
}
