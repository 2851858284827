import { Tab } from '@agent-ds/shared/models';
import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ag-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<HTMLElement>;
  @ContentChild(Tab, { static: false }) tabChild: Tab;
  @Input() label: string;
  constructor() {}

  ngOnInit() {}
}
