import { KeyValueChangeRecord, KeyValueChanges, KeyValueDiffer } from '@angular/core';
import { typeOf } from '../pipes/typeof.pipe';
import { deepClone, isDeepForbidden } from './util';

export class NestedValueChanges<K, V> implements KeyValueChanges<K, V> {
  added: KeyValueChangeRecord<K, V>[];
  removed: KeyValueChangeRecord<K, V>[];
  changed: KeyValueChangeRecord<K, V>[];

  constructor(added: KeyValueChangeRecord<K, V>[], removed: KeyValueChangeRecord<K, V>[], changed: KeyValueChangeRecord<K, V>[]) {
    this.added = added || [];
    this.changed = changed || [];
    this.removed = removed || [];
  }
  forEachItem(fn: (r: KeyValueChangeRecord<K, V>) => void): void {
    this.added.forEach((item) => fn(item));
    this.changed.forEach((item) => fn(item));
    this.removed.forEach((item) => fn(item));
  }
  forEachPreviousItem(fn: (r: KeyValueChangeRecord<K, V>) => void): void {
    throw new Error('Method not implemented.');
  }
  forEachChangedItem(fn: (r: KeyValueChangeRecord<K, V>) => void): void {
    this.changed.forEach((item) => fn(item));
  }
  forEachAddedItem(fn: (r: KeyValueChangeRecord<K, V>) => void): void {
    this.added.forEach((item) => fn(item));
  }
  forEachRemovedItem(fn: (r: KeyValueChangeRecord<K, V>) => void): void {
    this.removed.forEach((item) => fn(item));
  }
}

export class NestedDiffer<V> implements KeyValueDiffer<string, V> {
  private reference: Map<string, V> | { [key: string]: V };
  private skipKeys = [];
  private processedObjs: any[];
  private processedRefs: any[];

  constructor(skipKeys?: string[]) {
    this.skipKeys = skipKeys || this.skipKeys;
  }

  diff(object: any): KeyValueChanges<any, any> {
    this.processedObjs = [object];
    this.processedRefs = [this.reference];
    const diff = this.innerDiff(object, this.reference, null);
    this.reference = diff ? deepClone(object) : this.reference;
    return diff;
  }
  private innerDiff(object: any, reference: any, baseKey?: string): KeyValueChanges<any, V> {
    const changes = {
      added: [],
      removed: [],
      changed: [],
    };
    if (typeof object === 'object' || typeof reference === 'object') {
      object = object || {};
      reference = reference || {};
      const objKeys = Object.keys(object);
      const refKeys = Object.keys(reference);
      objKeys.forEach((key) => {
        if (this.skipKeys.includes(key)) {
          return;
        }
        const path = baseKey ? baseKey + '.' + key : key;
        const isObjNested = typeOf(object[key]) === 'object' && !(object[key] instanceof Date);
        const isRefNested = typeOf(reference[key]) === 'object' && !(reference[key] instanceof Date);
        if (!refKeys.includes(key)) {
          changes.added.push({ key: path, currentValue: object[key], previousValue: undefined });
          if (isObjNested && !this.processedObjs.includes(object[key]) && !isDeepForbidden(object[key])) {
            this.processedObjs.push(object[key]);
            const d = this.innerDiff(object[key], undefined, path);
            this.processedObjs.pop();
            if (d) {
              d.forEachAddedItem((item) => changes.added.push(item));
            }
          }
        } else if (object[key] !== reference[key]) {
          if (Array.isArray(object[key]) && Array.isArray(reference[key])) {
            if (
              object[key].length !== reference[key].length ||
              object[key].find((item: any, index: any) => this.innerDiff(item, reference[key][index], `${path}.${index}`) != null) != null
            ) {
              changes.changed.push({ key: path, currentValue: object[key], previousValue: reference[key] });
            }
          } else if (isObjNested || isRefNested) {
            let d = null;
            const nestedObjKeyValue = isObjNested ? object[key] : undefined;
            const nestedRefKeyValue = isRefNested ? reference[key] : undefined;
            if (
              nestedObjKeyValue !== nestedRefKeyValue &&
              (!this.processedObjs.includes(nestedObjKeyValue) || !this.processedRefs.includes(nestedRefKeyValue))
            ) {
              this.processedObjs.push(nestedObjKeyValue || {});
              this.processedRefs.push(nestedRefKeyValue || {});
              d = this.innerDiff(nestedObjKeyValue, nestedRefKeyValue, path);
              this.processedObjs.pop();
              this.processedRefs.pop();
            }
            if (d) {
              changes.changed.push({ key: path, currentValue: nestedObjKeyValue, previousValue: nestedRefKeyValue });
              d.forEachAddedItem((item) => changes.added.push(item));
              d.forEachRemovedItem((item) => changes.removed.push(item));
              d.forEachChangedItem((item) => changes.changed.push(item));
            }
          } else if (
            !(object[key] instanceof Date) ||
            !(reference[key] instanceof Date) ||
            object[key].getTime() !== reference[key].getTime()
          ) {
            changes.changed.push({ key: path, currentValue: object[key], previousValue: reference[key] });
          }
        }
      });
      refKeys.forEach((key) => {
        if (this.skipKeys.includes(key)) {
          return;
        }
        const path = baseKey ? baseKey + '.' + key : key;
        if (!objKeys.includes(key)) {
          changes.removed.push({ key: path, currentValue: undefined, previousValue: reference[key] });
          if (typeOf(reference[key]) === 'object' && !this.processedRefs.includes(reference[key]) && !isDeepForbidden(reference[key])) {
            this.processedRefs.push(reference[key]);
            const d = this.innerDiff(undefined, reference[key], path);
            this.processedRefs.pop();
            if (d) {
              d.forEachRemovedItem((item) => changes.removed.push(item));
            }
          }
        }
      });
    } else if (baseKey && object !== reference) {
      changes.changed.push({ key: baseKey, currentValue: object, previousValue: reference });
    }
    return changes.added.length + changes.removed.length + changes.changed.length > 0
      ? new NestedValueChanges(changes.added, changes.removed, changes.changed)
      : null;
  }
}
