import { GroupMeta, SupplierCallType } from '@agent-ds/shared/models';
import { Injectable } from '@angular/core';
import { HelperBase } from './helper-base';

@Injectable()
export class OverviewHelper extends HelperBase {
  meta: GroupMeta;

  updateFieldDefinitions(): void {
    this.meta = {
      // Overview
      title: '概要',
      class: 'form__group--golden-title-border',
      rows: [
        {
          title: '企業',
          fields: [
            {
              type: 'label',
              name: 'enterprise.frontId',
              class: 'auto',
              supplier: (value: any, callType: SupplierCallType, getter: (key: string, override?: boolean) => any) => {
                const enterprise = getter('enterprise', true);
                return enterprise ? `${enterprise.frontId} - ${enterprise.dynamicData.name}` : '';
              },
            },
            // {
            //   type: 'label',
            //   name: 'label-dash',
            //   class: 'auto',
            //   default: '-',
            // },
            // {
            //   type: 'label',
            //   class: 'auto',
            //   name: 'enterprise.dynamicData.name',
            // },
          ],
        },
        {
          title: '部署',
          fields: [
            {
              type: 'label',
              name: 'department.dynamicData.name',
              class: 'auto',
            },
          ],
        },
        {
          title: '求人',
          fields: [
            {
              type: 'label',
              name: 'job.frontId',
              class: 'auto',
              supplier: (value: any, callType: SupplierCallType, getter: (key: string, override?: boolean) => any) => {
                const job = getter('job', true);
                return job ? `${job.frontId} - ${job.dynamicData.position}` : '';
              },
            },
            // {
            //   type: 'label',
            //   name: 'label-dash',
            //   class: 'auto',
            //   default: '-',
            // },
            // {
            //   type: 'label',
            //   name: 'job.dynamicData.position',
            //   class: 'auto',
            // },
          ],
        },
        {
          title: '求職者',
          fields: [
            {
              type: 'label',
              name: 'student.frontId',
              class: 'auto',
              supplier: (value: any, callType: SupplierCallType, getter: (key: string, override?: boolean) => any) => {
                const student = getter('student', true);
                return student
                  ? [
                      student.frontId,
                      [[student.dynamicData.lastName, student.dynamicData.firstName].join(''), student.dynamicData.academicFieldType].join(
                        '　',
                      ),
                    ].join(' - ')
                  : '';
              },
            },
            // {
            //   type: 'label',
            //   name: 'label-dash',
            //   class: 'auto',
            //   default: '-',
            // },
            // {
            //   type: 'label',
            //   name: 'student.dynamicData.lastName',
            // },
            // {
            //   type: 'label',
            //   name: 'student.dynamicData.firstName',
            // },
            // {
            //   type: 'label',
            //   name: 'student.dynamicData.academicFieldType',
            // },
          ],
        },
      ],
    };
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.meta];
  }
}
