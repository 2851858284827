import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { CacheInterceptor } from './cache.interceptor';
import { ContentTypeInterceptor } from './content-type.intercptor';
import { MapperInterceptor } from './mapper.interceptor';
import { UrlInterceptor } from './url.intercptor';

/**
 * HttpInterceptorプロバイダー
 */
export const CORE_HTTP_INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MapperInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UrlInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ContentTypeInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
];
