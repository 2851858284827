export enum ProgressStatusCode {
  RAレコメンド中 = 10,
  本人OK待ち = 20,
  営業書類提出前 = 30,
  営業書類結果待ち = 40,
  企業書類提出待ち = 50,
  企業書類結果待ち = 60,
  セミナー設定中 = 70,
  セミナー確認中 = 80,
  セミナー結果待ち = 90,
  面接設定中 = 100,
  面接確認中 = 110,
  面接結果待ち = 120,
  本人意思待ち = 130,
  内定承諾 = 140,
  請求処理中 = 150,
  完了 = 160,
  NG連絡前 = 170,
  辞退連絡前 = 180,
  NG終了 = 190,
  辞退終了 = 200,
  削除終了 = 210,
}

export enum HistoryStatus {
  RAレコメンド = 10,
  求人紹介OK = 20,
  本人OK = 30,
  営業書類提出 = 40,
  企業書類提出 = 60,
  セミナー実施 = 581,
  セミナー確認 = 90,
  内定 = 130,
  入社 = 140,
  最終前面接実施 = 700,
  最終面接実施 = 710,
  完了 = 160,
  NG連絡前 = 170,
  辞退連絡前 = 180,
  NG終了 = 190,
  辞退終了 = 200,
  一次面接実施 = 600,
  一次面接確認 = 601,
  二次面接実施 = 610,
  二次面接確認 = 611,
  三次面接実施 = 620,
  三次面接確認 = 621,
  四次以降面接実施 = 630,
}

export enum ExtendedProgressStatusCode {
  RAレコメンド中 = 10,
  本人OK待ち = 20,
  営業書類提出前 = 30,
  営業書類結果待ち = 40,
  企業書類提出待ち = 50,
  企業書類結果待ち = 60,
  セミナー設定中 = 70,
  セミナー確認中 = 80,
  セミナー結果待ち = 90,
  本人意思待ち = 130,
  内定承諾 = 140,
  請求処理中 = 150,
  完了 = 160,
  NG連絡前 = 170,
  辞退連絡前 = 180,
  NG終了 = 190,
  辞退終了 = 200,
  面接設定中 = 500,
  面接確認中 = 501,
  面接結果待ち = 502,
  最終前面接設定中 = 510,
  最終前面接確認中 = 511,
  最終前面接結果待ち = 512,
  最終面接設定中 = 520,
  最終面接確認中 = 521,
  最終面接結果待ち = 522,
}
