import { StudentDetail } from '@agent-ds/shared/interfaces';
import { FormMeta, Tab } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { Component, forwardRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ag-student-preferences-tab',
  templateUrl: './student-preferences-tab.component.html',
  styleUrls: ['./student-preferences-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentPreferencesTabComponent) }],
})
export class StudentPreferencesTabComponent extends Tab implements OnInit {
  @Input() student: StudentDetail | null = null;
  @Input() readonly: boolean;
  metadataLeft: FormMeta;
  metadataRight: FormMeta;
  metadataOther: FormMeta;

  constructor(private dynamicService: DynamicFieldService, private studentApiService: StudentApiService) {
    super();
  }

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataLeft = {
        disabled: this.readonly,
        groups: [
          {
            title: 'カウンセリング情報',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'activityFinish')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'activityFinishRemarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'socialType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'communicationSkill')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'impressionAndCharm')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobHuntingAxis')),
              (() => {
                const rows = this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'councelInformation'));
                rows[0].fields[0].style = { minRows: 15, maxRows: 15 };
                return rows[0];
              })(),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hrAnalysis')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'characterAndType1')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'characterAndType2')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'characterAndType3')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'characterAndType4')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'characterAndType5')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itTypeCareer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itTypeArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'itAppealFlag')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'pgExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingExperienceLanguage')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'programmingExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'salesJobFlag')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'salesJobProduct')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobTransfer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstPlaceOfAssignment')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'overtimeWork')),
            ],
          },
          {
            title: '希望条件',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestIndustry1')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestIndustry2')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestIndustry3')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestIndustryRemarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestJobType1')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestJobType2')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestJobType3')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestJobTypeRemarks')),
            ],
          },
        ],
      };
      this.metadataRight = {
        disabled: this.readonly,
        groups: [
          {
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'languageSkill')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'exceptionalExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hearingInformation')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'studentActivityUniversity')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'studentActivityParttime1')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'researchContent')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'reasonRepeating')),
            ],
          },
          {
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestWorkplacePref')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'requestWorkplaceRemarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'locationChange')),
            ],
          },
          {
            title: '履歴書用',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'licenseHeld')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hobbiesAndSkills')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'selfPromotion')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'effortsDuringStudentDays')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'initiativesInAcademicAndEtc')),
            ],
          },
        ],
      };
      this.metadataOther = {
        disabled: this.readonly,
        groups: [
          {
            title: 'その他情報',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'cSetteiReason')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'cSetteiReasonWorkplace')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'cSetteiReasonDetail')),
            ],
          },
        ],
      };
    });
  }

  save(): void {
    this.studentApiService.update(this.student.id, this.student).subscribe((res) => this.studentApiService.refreshEvent.emit());
  }

  onChangedMetadataLeft(value): void {
    if (this.student && this.student.dynamicData && value === 'hrAnalysis') {
      // 対応表 HR Anaryst診断結果から、性格・タイプ1-3を自動で埋める実装
      // https://benesse-g.backlog.jp/view/MACH_PJ-12#comment-1327513400
      if (this.student.dynamicData.hrAnalysis === 'イメージタイプ') {
        this.student.dynamicData.characterAndType1 = '主張';
        this.student.dynamicData.characterAndType2 = '他者・社会';
        this.student.dynamicData.characterAndType3 = '感性・行動';
      } else if (this.student.dynamicData.hrAnalysis === 'プロセスタイプ') {
        this.student.dynamicData.characterAndType1 = '主張';
        this.student.dynamicData.characterAndType2 = '他者・社会';
        this.student.dynamicData.characterAndType3 = '理性・思考';
      } else if (this.student.dynamicData.hrAnalysis === 'フォロータイプ') {
        this.student.dynamicData.characterAndType1 = '受容';
        this.student.dynamicData.characterAndType2 = '他者・社会';
        this.student.dynamicData.characterAndType3 = '感性・行動';
      } else if (this.student.dynamicData.hrAnalysis === 'オフィサータイプ') {
        this.student.dynamicData.characterAndType1 = '受容';
        this.student.dynamicData.characterAndType2 = '他者・社会';
        this.student.dynamicData.characterAndType3 = '理性・思考';
      } else if (this.student.dynamicData.hrAnalysis === 'アピールタイプ') {
        this.student.dynamicData.characterAndType1 = '主張';
        this.student.dynamicData.characterAndType2 = '自分';
        this.student.dynamicData.characterAndType3 = '感性・行動';
      } else if (this.student.dynamicData.hrAnalysis === 'エフェクトタイプ') {
        this.student.dynamicData.characterAndType1 = '主張';
        this.student.dynamicData.characterAndType2 = '自分';
        this.student.dynamicData.characterAndType3 = '理性・思考';
      } else if (this.student.dynamicData.hrAnalysis === 'アジャストタイプ') {
        this.student.dynamicData.characterAndType1 = '受容';
        this.student.dynamicData.characterAndType2 = '自分';
        this.student.dynamicData.characterAndType3 = '感性・行動';
      } else if (this.student.dynamicData.hrAnalysis === 'トリックタイプ') {
        this.student.dynamicData.characterAndType1 = '受容';
        this.student.dynamicData.characterAndType2 = '自分';
        this.student.dynamicData.characterAndType3 = '理性・思考';
      } else {
        // do nothing
      }
    } else {
      // do nothing
    }
  }
}
