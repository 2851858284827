import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NULL_SELECTED_VALUE } from '../constants/consts';
import { typeOf } from '../pipes/typeof.pipe';
import { AsialHttpParamEncoder, removeEmptyObjects } from '../util/util';

@Injectable()
export class MapperInterceptor implements HttpInterceptor {
  private encoder = new AsialHttpParamEncoder();

  static toSearchFormat(object: any): any {
    if (typeOf(object) === 'object') {
      Object.keys(object).forEach((key) => {
        if (Array.isArray(object[key])) {
          object[key].forEach((item: any, index: number) => (object[key][index] = this.toSearchFormat(item)));
        } else if (typeOf(object[key]) === 'object') {
          if (object[key].from !== undefined || object[key].to !== undefined) {
            if (object[key].from) {
              object[key + 'From'] = object[key].from;
            }
            if (object[key].to) {
              object[key + 'To'] = object[key].to;
            }
            delete object[key];
          } else {
            object[key] = this.toSearchFormat(object[key]);
          }
        } else {
          object[key] = this.toSearchFormat(object[key]);
        }
      });
    } else if (object === null) {
      return NULL_SELECTED_VALUE;
    }
    return removeEmptyObjects(object);
  }

  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body) {
      return next.handle(req.clone({ body: this.deepSafeMap(req.body) }));
    } else if (req.params['map']) {
      const params = req.params['map'] as Map<string, any[]>;
      let newParams = {};
      params.forEach((value, key) => (newParams[key] = value && value.length === 1 ? value[0] : value));
      if (
        req.url.endsWith('/search') ||
        req.url.includes('/suggest') ||
        req.url.includes('/export') ||
        req.url.endsWith('/amount') ||
        req.url.endsWith('/csv')
      ) {
        newParams = MapperInterceptor.toSearchFormat(newParams);
      }
      newParams = this.deepSafeMap(newParams, true);
      return next.handle(req.clone({ params: new HttpParams({ fromObject: newParams, encoder: this.encoder }) }));
    }
    return next.handle(req);
  }

  /**
   * Although it remaps the param object, use it's return value since it's possible to be changed to use a deep cloned param
   * @param object any request object
   */
  deepSafeMap<T>(object: T, mapComponent = false): any {
    if (object == null) {
      return object;
    }

    if (object instanceof Date && object.isValid()) {
      if (object.getUTCHours() || object.getUTCMinutes() || object.getUTCSeconds() || object.getUTCMilliseconds()) {
        return object.toAsialDateTimeString();
      } else {
        return object.toAsialDateString();
      }
    } else if (Array.isArray(object)) {
      object.forEach((item, index) => (object[index] = this.deepSafeMap(item, mapComponent)));
    } else if (typeof object === 'object') {
      Object.keys(object).forEach((key) => {
        object[key] = this.deepSafeMap(object[key], mapComponent);
      });
    }
    return object;
  }
}
