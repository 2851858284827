import { AddressPipe } from './address.pipe';
import { AgePipe } from './age.pipe';
import { BasedDatePipe } from './based-date.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterPipe } from './filter.pipe';
import { FindPipe } from './find.pipe';
import { GenderPipe } from './gender.pipe';
import { SafeDatePipe } from './safe-date.pipe';
import { TruncatePipe } from './truncate.pipe';
import { TypeofPipe } from './typeof.pipe';
import { UndefinedPipe } from './undefined.pipe';

export const SHARED_PIPES = [
  GenderPipe,
  FilterPipe,
  AgePipe,
  TruncatePipe,
  TypeofPipe,
  FileSizePipe,
  BasedDatePipe,
  FindPipe,
  AddressPipe,
  UndefinedPipe,
  SafeDatePipe,
];
