import { CompanyContractCreateDialogComponent } from '@agent-ds/company/components';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { Company, Contract, EnterpriseDepartment, EnterpriseDepthContacts } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DialogService } from '@agent-ds/shared/services';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { COMPANY_CONTRACTS_TABLE_CONFIG } from './company-contracts-tab-table-config';

@Component({
  selector: 'ag-company-contracts-tab',
  templateUrl: './company-contracts-tab.component.html',
  styleUrls: ['./company-contracts-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyContractsTabComponent) }],
})
export class CompanyContractsTabComponent extends Tab implements OnChanges, AfterViewInit {
  @ViewChild('status', { static: false }) statusTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @Input() company: Company;

  tableConfig: TableConfig;
  contracts: Contract[];
  filteredContracts: Contract[];
  departments: EnterpriseDepartment[];
  activeFilter = false;

  constructor(
    private companyApiService: CompanyApiService,
    private datePipe: SafeDatePipe,
    private dialog: DialogService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['company']) {
      this.loadContracts();
    }
  }

  ngAfterViewInit() {
    this.tableConfig = COMPANY_CONTRACTS_TABLE_CONFIG(this.statusTemplate, this.actionsTemplate, (date) =>
      this.datePipe.transform(date, 'yyyy/MM/dd (E)'),
    );
    this.cdr.detectChanges();
  }

  private loadContracts() {
    if (this.company) {
      this.companyApiService.getDepartments(this.company.id).subscribe((response: EnterpriseDepthContacts) => {
        this.departments = response.enterpriseDepartments;
      });
      this.companyApiService.getContracts(this.company.id).subscribe((response: Contract[]) => {
        this.contracts = response;
        this.filter();
      });
    }
  }

  onFilterChange(value: boolean): void {
    this.activeFilter = value;
    this.filter();
  }

  filter(): void {
    this.filteredContracts = this.activeFilter ? this.contracts : this.contracts.filter((dep) => dep.dynamicData.status !== '無効');
  }

  openCompanyContractCreateDialog() {
    this.dialog.open(
      CompanyContractCreateDialogComponent,
      {
        data: { contract: {}, company: this.company, departmentList: this.departments },
      },
      (result) => {
        if (!result) {
          return;
        }
        return this.companyApiService.addContract(this.company.id, result).pipe(tap(() => this.loadContracts()));
      },
    );
  }

  openCompanyContractEditDialog(data: Contract) {
    this.dialog.open(
      CompanyContractCreateDialogComponent,
      {
        data: { contract: data, company: this.company, departmentList: this.departments },
      },
      (result) => {
        if (!result) {
          return;
        }
        return this.companyApiService.updateContract(this.company.id, data.id, result).pipe(tap(() => this.loadContracts()));
      },
    );
  }
}
