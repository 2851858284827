import {
  CompanyBillingAddressCreateDialogComponent,
  CompanyDepartmentCreateDialogComponent,
  CompanySeminarAttendanceSendDialogComponent,
} from '@agent-ds/company/components';
import { JobCreateDialogComponent } from '@agent-ds/jobs/components/job-create-dialog/job-create-dialog.component';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { Company, CompanyBillingAddress, EnterpriseDepartment, EnterpriseDepthContacts } from '@agent-ds/shared/interfaces';
import { ExportModel, ExportTemplate } from '@agent-ds/shared/interfaces/export';
import { Tab } from '@agent-ds/shared/models';
import { CompanyApiService, DialogService, ExportApiService, JobApiService } from '@agent-ds/shared/services';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  COMPANY_SECTIONS_BILLING_ADDRESS_TABLE_CONFIG,
  COMPANY_SECTIONS_DEPARTMENT_TABLE_CONFIG,
} from './company-sections-tab-table-config';

@Component({
  selector: 'ag-company-sections-tab',
  templateUrl: './company-sections-tab.component.html',
  styleUrls: ['./company-sections-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanySectionsTabComponent) }],
})
export class CompanySectionsTabComponent extends Tab implements OnChanges, AfterViewInit {
  @ViewChild('departmentTable', { static: false }) departmentTable: PageScrollTableComponent;
  @ViewChild('billingAddressesTable', { static: false }) billingAddressesTable: PageScrollTableComponent;
  @ViewChild('status', { static: false }) statusTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('actionsBottom', { static: false }) actionsBottomTemplate: TemplateRef<any>;
  @ViewChild('billingActions', { static: false }) billingActionsTemplate: TemplateRef<any>;
  @Input() company: Company;
  @Output() selectedJobId = new EventEmitter<number>();

  departmentsTableConfig: TableConfig;
  billingAddressesTableConfig: TableConfig;
  departments: EnterpriseDepartment[];
  filteredDepartments: EnterpriseDepartment[];
  billingAddresses: CompanyBillingAddress[];
  filteredBillingAddresses: CompanyBillingAddress[];
  progressExportTemplates: ExportTemplate[] = [];

  activeFilter = false;
  activeBllingAddressFilter = false;

  constructor(
    private companyApiService: CompanyApiService,
    private dialog: DialogService,
    private cdr: ChangeDetectorRef,
    private jobService: JobApiService,
    private exportService: ExportApiService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['company']) {
      this.loadDepartments();
    }
  }

  ngAfterViewInit() {
    this.departmentsTableConfig = COMPANY_SECTIONS_DEPARTMENT_TABLE_CONFIG(
      this.statusTemplate,
      this.actionsTemplate,
      this.actionsBottomTemplate,
    );
    this.billingAddressesTableConfig = COMPANY_SECTIONS_BILLING_ADDRESS_TABLE_CONFIG(this.billingActionsTemplate);
    this.exportService.getTemplates(ExportModel.progress).subscribe((res) => (this.progressExportTemplates = res.templates));
    this.cdr.detectChanges();
  }

  loadDepartments(): void {
    if (this.company) {
      this.companyApiService.getDepartments(this.company.id).subscribe((response: EnterpriseDepthContacts) => {
        this.departments = response.enterpriseDepartments;
        this.billingAddresses = response.enterpriseBillingAddresses;
        this.filter();
        this.filterBillingAddress();
      });
    }
  }

  onFilterChange(value: boolean): void {
    this.activeFilter = value;
    this.filter();
  }
  filter(): void {
    this.filteredDepartments = this.activeFilter ? this.departments : this.departments.filter((dep) => dep.dynamicData.status !== '廃止');
  }

  onFilterChangeBillingAddress(value: boolean): void {
    this.activeBllingAddressFilter = value;
    this.filterBillingAddress();
  }
  filterBillingAddress(): void {
    this.filteredBillingAddresses = this.activeBllingAddressFilter
      ? this.billingAddresses
      : this.billingAddresses.filter((dep) => dep.dynamicData.billignAddressStatus !== '無効');
  }

  createJob(department: EnterpriseDepartment): void {
    this.jobService.addJob(department.id).subscribe((res) => {
      this.jobService.refreshEvent.emit();
      JobDetailPageComponent.instance.referenceId = res.id;
      JobDetailPageComponent.instance.open();
    });
  }

  editDepartment(data: EnterpriseDepartment): void {
    this.openDepartmentCreateDialog({
      data: { department: data, company: this.company, departmentList: this.departments },
    });
  }

  editListSend(data: EnterpriseDepartment): void {
    this.exportService.getTemplates(ExportModel.progress).subscribe((res) => {
      this.progressExportTemplates = res.templates;
      this.openSeminarAttendanceSendDialog({
        data: { company: this.company, department: data, progressExportTemplates: this.progressExportTemplates },
      });
    });
  }

  editBillingAddress(data: CompanyBillingAddress): void {
    this.openBillingAddressCreateDialog({ data: { billing: data, company: this.company, departmentList: this.departments } });
  }

  openDepartmentCreateDialog(config?: DialogConfig): void {
    this.dialog.open(
      CompanyDepartmentCreateDialogComponent,
      config || {
        data: { department: {}, company: this.company, departmentList: this.departments },
      },
      (result) => {
        if (!result) {
          return;
        }
        if (config) {
          return this.companyApiService
            .updateDepartment(this.company.id, config.data.department.id, result)
            .pipe(tap(() => this.loadDepartments()));
        } else {
          return this.companyApiService.addDepartment(this.company.id, result).pipe(tap(() => this.loadDepartments()));
        }
      },
    );
  }

  openBillingAddressCreateDialog(config?: DialogConfig): void {
    this.dialog.open(
      CompanyBillingAddressCreateDialogComponent,
      config || {
        data: { billing: {}, company: this.company, departmentList: this.departments },
      },
      (result) => {
        if (!result) {
          return;
        }
        if (config) {
          return this.companyApiService
            .updateBillingAddress(this.company.id, config.data.billing.id, result)
            .pipe(tap(() => this.loadDepartments()));
        } else {
          return this.companyApiService.addBillingAddress(this.company.id, result).pipe(tap(() => this.loadDepartments()));
        }
      },
    );
  }

  openSeminarAttendanceSendDialog(config?: DialogConfig): void {
    this.dialog.open(
      CompanySeminarAttendanceSendDialogComponent,
      config || {
        data: { company: this.company, department: {}, progressExportTemplates: this.progressExportTemplates },
      },
      (result) => {
        if (!result) {
          return;
        }
        this.companyApiService.refreshEvent.emit();
      },
    );
  }
}
