import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AuthInterceptor } from '@agent-ds/shared/interceptors/auth.interceptor';
import { StudentImportErrorDetail, StudentReimportRequest } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DialogService, DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ag-student-import-detail',
  templateUrl: './student-import-detail.component.html',
  styleUrls: ['./student-import-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentImportDetailComponent implements OnInit, OnChanges {
  @Input() studentImportErrorId: number | null = null;
  @Output() closeWithRefresh: EventEmitter<void> = new EventEmitter<void>();
  detail: StudentImportErrorDetail | null = null;
  headMeta: FormMeta;
  leftMeta: FormMeta;
  rightMeta: FormMeta;
  bottomMeta: FormMeta;
  customMeta: FormMeta;
  validity = true;
  private validityArray = [true, true, true, true, true];

  constructor(
    private studentApiService: StudentApiService,
    private dynamicService: DynamicFieldService,
    private chRef: ChangeDetectorRef,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.headMeta = {
        groups: [
          {
            class: 'oneliner',
            rows: [
              {
                title: '登録ステータス',
                fields: [
                  {
                    type: 'label',
                    name: 'registrationStatus',
                    default: '仮登録',
                  },
                ],
              },
              ...this.dynamicService.getFormRows(
                {
                  fieldName: this.dynamicService.getDefinition('student', 'interviewStatus').fieldName,
                  fieldType: 'list',
                  displayType: 'dropdown',
                  label: this.dynamicService.getDefinition('student', 'interviewStatus').label,
                  validationStyle: this.dynamicService.getDefinition('student', 'interviewStatus').validationStyle,
                },
                'dynamicData',
              ),
            ],
          },
        ],
      };
      this.leftMeta = {
        groups: [
          {
            title: '氏名・生年月日',
            rows: [
              {
                title: '求職者氏名',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstName'))[0].fields,
                ],
                showRequired: true,
              },
              {
                title: 'フリガナ',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticLastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticFirstName'))[0].fields,
                ],
                showRequired: true,
              },
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'gender')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'birthday')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportYear')),
            ],
          },
          {
            title: '住所・住居',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'address')),
          },
          {
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationAddress')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationTel')),
            ],
          },
          {
            title: '職歴概要',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskTypeFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevelFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel03')),
            ],
          },
          {
            title: 'グローバル',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nationality'), null, 'full'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'motherLanguage'), null, 'full'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'japaneseLevel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'abroadFee')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'visa')),
            ],
          },
        ],
      };
      this.rightMeta = {
        groups: [
          {
            title: '担当',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportBranch')),
              ...this.dynamicService.getFormRows(
                {
                  label: '担当者',
                  fieldType: 'user',
                  fieldName: 'user',
                },
                null,
                'three-quarter',
              ),
            ],
          },
          {
            title: 'ステータス',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'rank')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mediumId')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'acquisitionRoute')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusMailmag')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusServiceNg')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nonRecruitmentIntroduction')),
            ],
          },
          {
            title: '連絡先',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailMain')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailSub')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mobileTel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'houseTel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'contactNotes')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'skypeId')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lineId')),
            ],
          },
          {
            title: '登録・媒体',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstRegistrationDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastRegistrationRoute')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastRegistrationDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRoute')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRouteDetail')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewSettingDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'sortingDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'serviceinDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationTime')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'inflowClassification')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'pptSms')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarShortCounseling')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationDate')),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationUniversity'),
                null,
                'full',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroduction')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionId')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionName')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionEntryDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionEffectTarget')),
            ],
          },
        ],
      };
      this.bottomMeta = {
        groups: [
          {
            title: '備考',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewClassification')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'implementationCategory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'snsType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'chanceToLearnAboutCompany'), null, 'half'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hookEntryHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindThreeDaysAgo')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindDayBefore')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'officialRegistrationPatrol'), null, 'half'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'officialRegistrationPatrolDesiredTime'),
                null,
                'half',
              ),
            ],
          },
          {
            title: '学歴',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastAcademicRecord')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicFieldType')),
            ],
          },
          {
            title: '最終',
            rows: [],
          },
          {
            title: 'No.1',
            class: 'form__group--no-title-border form__group--no-margin',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicHistory1')),
          },
          {
            title: 'No.2',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicHistory2')),
          },
          {
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'universityLevel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicCourseType')),
            ],
          },
          {
            title: '語学',
            rows: [],
          },
          {
            title: 'No.1',
            class: 'form__group--no-title-border form__group--no-margin',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language1')),
          },
          {
            title: 'No.2',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language2')),
          },
          {
            title: 'No.3',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language3')),
          },
          {
            title: 'No.4',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language4')),
          },
          {
            title: 'No.5',
            class: 'form__group--no-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'language5')),
          },
          {
            title: 'カウンセリング情報',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hearingInformation')),
          },
        ],
      };
      const customs = this.dynamicService.getDefinitions('student').filter((def) => def.isCustomField);
      if (customs.length) {
        this.customMeta = {
          groups: [
            {
              title: 'カスタムフィールド',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.studentImportErrorId && changes['studentImportErrorId'].previousValue !== changes['studentImportErrorId'].currentValue) {
      this.getDetail();
    }
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  update(): void {
    const request: StudentReimportRequest = {
      userId:
        this.detail.importedStudent.studentUsers && this.detail.importedStudent.studentUsers.length
          ? this.detail.importedStudent.studentUsers[0].userId
          : null,
      dynamicData: this.detail.importedStudent.dynamicData,
    };
    this.studentApiService.addImportError(this.studentImportErrorId, request).subscribe(() => {
      this.detail = null;
      this.closeWithRefresh.emit();
    });
  }

  delete(): void {
    PopupControlComponent.instance.open({
      title: '削除',
      cancelText: 'キャンセル',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: 'OK',
      confirmCallback: () => {
        this.studentApiService.deleteImportError([this.studentImportErrorId]).subscribe(() => {
          this.detail = null;
          this.closeWithRefresh.emit();
        });
      },
    });
  }

  private getDetail(): void {
    this.detail = null;
    this.studentApiService.getImportErrorDetail(this.studentImportErrorId).subscribe((detail: StudentImportErrorDetail) => {
      this.detail = detail;
      if (this.detail.errorFields) {
        const fieldKeys = Object.keys(this.detail.errorFields);
        const fields = {};
        const messages = fieldKeys.map((key) => {
          const message = Array.isArray(this.detail.errorFields[key])
            ? this.detail.errorFields[key].join(',\n')
            : this.detail.errorFields[key];
          fields[key] = message;
          return message;
        });
        AuthInterceptor.FIELD_ERROR_EVENT.emit(fields);
        const dialogConfig = new ConfirmDialogConfig();
        dialogConfig.title = '';
        dialogConfig.buttons = { yes: 'OK', no: '', hideNo: true };
        dialogConfig.messages = messages;
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
      }
      if (this.detail.importedStudent.studentUsers && this.detail.importedStudent.studentUsers.length) {
        this.detail.importedStudent.dynamicData.user = this.detail.importedStudent.studentUsers[0].userId;
      }
      this.chRef.detectChanges();
    });
  }
}
