import { ProgressSearchParams } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-company-progress-tab',
  templateUrl: './company-progress-tab.component.html',
  styleUrls: ['./company-progress-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyProgressTabComponent) }],
})
export class CompanyProgressTabComponent extends Tab implements OnChanges {
  @Input() companyId: number | null = null;
  requestObject: ProgressSearchParams;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
      this.requestObject = {
        enterpriseId: [this.companyId],
      };
    }
  }
}
