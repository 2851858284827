import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(value: string | number | Date, compare: string | number | Date = new Date()): number {
    if (!value) {
      return 0;
    }
    const self = new Date(value);
    const other = new Date(compare);

    if (!self.isValid() || !other.isValid()) {
      return null;
    }

    return self.getFullYear() >= other.getFullYear()
      ? 0
      : other.getFullYear() -
          self.getFullYear() -
          +(self.getMonth() > other.getMonth() || (self.getMonth() === other.getMonth() && self.getDate() > other.getDate()));
  }
}
