export class Toast {
  key: string;
  title: string;
  message: string;
  color?: string;

  constructor(key: string, title: string, message: string, color = 'red') {
    this.key = key;
    this.title = title;
    this.message = message;
    this.color = color;
  }
}
