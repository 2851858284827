import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[agClickOutside]',
})
export class ClickOutsideDirective implements OnDestroy {
  private attached = false;

  constructor(private elementRef: ElementRef) {
    this.attach();
  }

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  public onClickHandler = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  };

  attach(): void {
    if (!this.attached) {
      document.addEventListener('click', this.onClickHandler);
      this.attached = true;
    }
  }

  detach(): void {
    if (this.attached) {
      document.removeEventListener('click', this.onClickHandler);
      this.attached = false;
    }
  }

  ngOnDestroy() {
    this.detach();
  }
}
