import { getProgressStatusLabel, NULL_SELECTED_VALUE, PROGRESS_STATUSES } from '@agent-ds/shared/constants';
import { ProgressActionType } from '@agent-ds/shared/enums';
import {
  CompanyDetail,
  ContactHistory,
  ContactHistoryAction,
  EnterpriseDepartmentUserType,
  Job,
  MailTemplateResponse,
  OutlookResponse,
  Progress,
  ProgressAction,
  ProgressHistoryRemarksUpdateRequest,
  ProgressItemResponse,
  ProgressStakeholderEmailResponse,
  ProgressUpdateWithActionRequest,
  StudentDetail,
} from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import {
  AuthService,
  CompanyApiService,
  DynamicFieldService,
  JobApiService,
  MailApiService,
  ProgressApiService,
  StudentApiService,
} from '@agent-ds/shared/services';
import { OutlookApiService } from '@agent-ds/shared/services/api/outlook-api.service';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { deepClone } from '@agent-ds/shared/util/util';
import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

interface ProgressWithAsyncDetails extends Progress {
  student$?: Observable<StudentDetail>;
  enterprise$?: Observable<CompanyDetail>;
  job$?: Observable<Job>;
  latestRevision: number;
  latestRemarks: string;
  isStudentDelayActionInProgress: boolean;
  isOperationActionInProgress: boolean;
}

@Component({
  selector: 'ag-outlook-list',
  templateUrl: './outlook-list.component.html',
  styleUrls: ['./outlook-list.component.scss'],
})
export class OutlookListComponent implements OnInit, OnDestroy {
  private paramSubscription: Subscription;
  private messageId: string;
  errorState = false;
  studentEditMeta: FormMeta;
  contactMeta: FormMeta;
  response: OutlookResponse = { parsed: true, type: 'student', data: { studentIds: [] } };
  selectableStudents: StudentDetail[] = [];
  selectedStudent: StudentDetail;
  selectedDynamic: any = {};
  companies: CompanyDetail[] = [];
  jobs: Job[] = [];
  progresses: ProgressWithAsyncDetails[] = [];
  headerCallbackState = false;

  contactHistoryActions = Object.keys(ContactHistoryAction)
    .filter((key: any) => isNaN(key))
    .map((key: string) => ({
      label: key,
      value: ContactHistoryAction[key],
    }));
  contactModel: ContactHistory = { message: '', userId: null, actionAt: null, action: 1 };
  getStatusLabel = getProgressStatusLabel;
  PROGRESS_STATUSES = PROGRESS_STATUSES;
  PROGRESS_ACTION_TYPE = ProgressActionType;
  WINDOW = window;
  eventEditors: {
    [progressId: number]: {
      seminarAt: any;
      seminarType: null | 99 | 100 | 1;
      seminarAtMailSendFlag: boolean;
      requestObject: ProgressUpdateWithActionRequest;
      inSave: boolean;
    };
  } = {};
  private baseEditorMeta: FormMeta;
  readonly editorMeta: { [key: number]: FormMeta } = {};

  private progressByStudentPage = 0;
  private totalProgressCount = 0;
  private innerLoading = false;

  constructor(
    private outlookService: OutlookApiService,
    private studentService: StudentApiService,
    private companyService: CompanyApiService,
    private jobService: JobApiService,
    private progressService: ProgressApiService,
    public readonly userInjector: UserTeamInjectorProvider,
    private dynamicService: DynamicFieldService,
    private authService: AuthService,
    private mailService: MailApiService,
    private zone: NgZone,
    private host: ElementRef<HTMLElement>,
  ) {}

  ngOnInit() {
    Office.onReady(() => {
      if (!Office.context.mailbox) {
        console.warn('Office mailbox is not available.');
        return;
      }
      // Set up ItemChanged event
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => this.zone.run(() => this.messageChanged()));
      this.messageChanged();
    });
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.studentEditMeta = {
        groups: [
          {
            class: 'no-border no-background',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationStatus')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewStatus')),
            ],
          },
        ],
      };
      this.contactMeta = {
        groups: [
          {
            title: '対応履歴',
            class: 'no-border no-background form__group--no-title-border',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows(
                  {
                    fieldName: 'actionAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '対応日',
                    validationStyle: { required: true },
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
              {
                title: 'アクション',
                fields: [
                  {
                    type: 'dropdown',
                    name: 'action',
                    class: 'half tall',
                    options: this.contactHistoryActions,
                    labelField: 'label',
                    valueField: 'value',
                  },
                ],
              },
              {
                title: '担当',
                fields: [
                  {
                    type: 'label',
                    name: 'userId',
                    supplier: () => this.userInjector.getUserTeamNameById(this.contactModel.userId),
                  },
                ],
              },
              (() => {
                const row = this.dynamicService.getFormRows(
                  {
                    fieldName: 'nextContactAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '次回コンタクト日時',
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
              {
                title: '内容',
                fields: [
                  {
                    type: 'textarea',
                    name: 'message',
                    class: 'half tall',
                  },
                ],
              },
            ],
          },
        ],
      };
    });

    const dateTime = this.dynamicService.getFormRows({ fieldType: 'date', fieldName: 'seminarAt', displayType: 'date+time' })[0];
    dateTime.fields.push({
      type: 'radio',
      name: 'seminarType',
      labelField: 'label',
      valueField: 'value',
      options: [{ label: NULL_SELECTED_VALUE, value: 1 }, { label: '最終前面接', value: 99 }, { label: '最終面接', value: 100 }],
    });
    this.baseEditorMeta = {
      groups: [
        {
          class: 'no-title-column no-border no-background no-padding outlook',
          rows: [dateTime],
        },
      ],
    };

    this.host.nativeElement.onscroll = (event: any): void => {
      if (
        this.progresses.length < this.totalProgressCount &&
        !this.innerLoading &&
        event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95
      ) {
        this.getNextProgresses();
      }
    };
  }

  private getMessageInfo(): void {
    this.progressByStudentPage = this.totalProgressCount = 0;
    this.innerLoading = false;
    this.errorState = false;

    this.outlookService.getMessageInfo(this.messageId).subscribe(
      (res) => {
        this.selectableStudents.length = this.companies.length = this.jobs.length = this.progresses.length = 0;
        this.selectStudent(null);

        if (!res || !res.parsed) {
          this.errorState = true;
          return;
        }
        this.errorState = false;
        this.response = res;

        if (this.response.data.studentIds) {
          forkJoin(this.response.data.studentIds.map((id) => this.studentService.getDetail(id)))
            .pipe(first())
            .subscribe((details) => {
              this.selectableStudents = details;
              if (this.selectableStudents.length === 1) {
                this.selectStudent(this.selectableStudents[0]);
              }
            });
        }
        if (this.response.data.enterpriseIds) {
          forkJoin(this.response.data.enterpriseIds.map((id) => this.companyService.getDetail(id)))
            .pipe(first())
            .subscribe((details) => (this.companies = details));
        }
        if (this.response.data.jobIds) {
          forkJoin(this.response.data.jobIds.map((id) => this.jobService.getDetail(id)))
            .pipe(first())
            .subscribe((details) => {
              this.jobs = details;
              forkJoin(this.jobs.map((detail) => this.companyService.getDetail(detail.enterpriseId)))
                .pipe(first())
                .subscribe((enterprises) =>
                  this.companies.push(...enterprises.filter((ent) => !this.companies.some((c) => c.id === ent.id))),
                );
            });
        }
        if (this.response.data.progressIds) {
          forkJoin(this.response.data.progressIds.map((id) => this.progressService.getDetail(id)))
            .pipe(first())
            .subscribe((details: ProgressWithAsyncDetails[]) => {
              details.forEach((detail) => {
                detail.student$ = this.studentService.getDetail(detail.studentId);
                detail.enterprise$ = this.companyService.getDetail(detail.enterpriseId);
                detail.job$ = this.jobService.getDetail(detail.jobId);
                const latestHistory = detail.progressHistories && detail.progressHistories[0];
                detail.latestRevision = latestHistory && latestHistory.revision ? latestHistory.revision : 1;
                detail.latestRemarks = latestHistory && latestHistory.remarks ? latestHistory.remarks : '';

                detail.enterprise$.pipe(first()).subscribe((ent) => {
                  if (this.companies.length > 0 && this.companies.some((c) => c.id === ent.id)) {
                    return;
                  }
                  this.companies.push(ent);
                });
              });
              this.progresses = details;
            });
        }
      },
      () => (this.errorState = true),
    );
  }

  private isPatternMatchOfMessageId(value: string): boolean {
    return value.match(/@mach.doda.jp/) || value.match(/^.*-.*-.*_.*/g) ? true : false;
  }

  private getHeaderCallback(asyncResult: Office.AsyncResult<string>): void {
    const startReferencesNum = asyncResult.value.search(/\nReferences:/);
    const endReferencesNum = asyncResult.value.slice(startReferencesNum).search(/\r\n.*:/g);
    this.messageId = asyncResult.value
      .slice(startReferencesNum, startReferencesNum + endReferencesNum)
      .replace(/\nReferences:| |\<|\>/g, '')
      .split('\r\n')
      .filter((id: string) => this.isPatternMatchOfMessageId(id))
      .reduce((res: string, value) => (res += value), '');
    this.headerCallbackState = true;
  }

  private _getMessageInfo() {
    Office.context.mailbox.item.getAllInternetHeadersAsync(this.getHeaderCallback.bind(this));
    const id = setInterval(() => {
      if (this.headerCallbackState) {
        if (!this.messageId) {
          this.errorState = true;
        } else {
          this.getMessageInfo();
        }
        clearInterval(id);
      }
    }, 500);
  }

  private messageChanged(): void {
    const newId = (Office.context.mailbox.item.internetMessageId || '').replace(/\<|\>/g, '');
    if (newId === this.messageId) {
      return;
    }
    this.messageId = newId;
    !this.isPatternMatchOfMessageId(newId) ? this._getMessageInfo() : this.getMessageInfo();
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
      this.paramSubscription = null;
    }
  }

  selectStudent(student: StudentDetail): void {
    this.progressByStudentPage = this.totalProgressCount = 0;
    this.innerLoading = false;
    this.selectedStudent = student;
    this.selectedDynamic = this.selectedStudent ? { ...this.selectedStudent.dynamicData } : {};
    this.contactModel = { message: '', userId: this.authService.loginUser.id, actionAt: null, action: 1 };
    if (!this.progresses.length && this.selectedStudent) {
      this.getNextProgresses();
    }
  }

  private getNextProgresses(): void {
    if (this.innerLoading || !this.selectedStudent) {
      return;
    }
    this.innerLoading = true;

    this.progressService
      .getList({
        studentId: [this.selectedStudent.id],
        sort: 'updatedAt',
        order: 'desc',
        from: 20 * this.progressByStudentPage++,
        size: 20,
      })
      .pipe(first())
      .subscribe((progresses) => {
        this.innerLoading = false;
        this.totalProgressCount = this.totalProgressCount || progresses.total;
        this.progresses.push(
          ...progresses.progresses
            .filter((p) => !this.progresses.some((pr) => pr.id === p.id))
            .map((p) => {
              const pas = (p as unknown) as ProgressWithAsyncDetails;
              pas.student$ = this.studentService.getDetail(p.studentId);
              pas.enterprise$ = p.enterprise ? this.companyService.getDetail(p.enterprise.id) : of(null);
              pas.job$ = this.jobService.getDetail(p.jobId);
              pas.enterpriseId = p.enterprise.id;
              return pas;
            }),
        );
      });
  }

  updateStudent(): void {
    this.studentService.update(this.selectedStudent.id, this.selectedStudent).subscribe((res) =>
      this.studentService.getDetail(this.selectedStudent.id).subscribe((detail) => {
        this.selectStudent(detail);
        for (let i = 0; i < this.selectableStudents.length; i++) {
          if (this.selectableStudents[i].id === this.selectedStudent.id) {
            this.selectableStudents[i] = this.selectedStudent;
            break;
          }
        }
      }),
    );
  }

  createContact(): void {
    this.studentService
      .addContact(this.selectedStudent.id, this.contactModel)
      .subscribe((res) => (this.contactModel = { message: '', userId: this.authService.loginUser.id, actionAt: null, action: 1 }));
  }

  get loggedInText(): string {
    if (this.selectedStudent && this.selectedStudent.mypageLoggedInAt) {
      const diff = Math.trunc((Date.now() - Date.parse(this.selectedStudent.mypageLoggedInAt.toString())) / 86400000);
      return diff < 1 ? '今日' : diff + '日前';
    }
    return '-';
  }

  public onStudentDelayClick(progress: ProgressWithAsyncDetails, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    progress.isStudentDelayActionInProgress = true;
    this.progressService
      .updateWithoutAction(progress.id, {
        type: progress.type,
        hasInterview: progress.hasInterview,
        n: progress.n,
        seminarType: progress.seminarType,
        isStudentDelay: progress.isStudentDelay > 0 ? 0 : 1,
        seminarAt: progress.seminarAt,
        dynamicData: progress.dynamicData,
      })
      .subscribe(
        () => {
          progress.isStudentDelay = progress.isStudentDelay > 0 ? 0 : 1;
          progress.isStudentDelayActionInProgress = false;
        },
        () => (progress.isStudentDelayActionInProgress = false),
      );
  }

  public onProgressSaveClick(progress: ProgressWithAsyncDetails): void {
    if (this.eventEditors[progress.id] && this.eventEditors[progress.id].inSave) {
      return;
    }

    let eventRequestObject: ProgressUpdateWithActionRequest | null = null;
    let remarksRequestObject: ProgressHistoryRemarksUpdateRequest | null = null;
    let remarksRequest: Observable<{ id: number }> | null = null;
    let eventRequest: Observable<{ id: number }> | null = null;

    if (progress.latestRemarks !== undefined) {
      const remarks = progress.latestRemarks || '';
      remarksRequestObject = { remarks: remarks };
      remarksRequest = this.progressService.updateHistoryRemarks(progress.id, progress.latestRevision, remarksRequestObject);
    }

    if (this.eventEditors[progress.id]) {
      this.eventEditors[progress.id].inSave = true;
      const event = this.eventEditors[progress.id];

      if (event.requestObject && event.seminarAt) {
        eventRequestObject = {
          ...event.requestObject,
          ...{
            seminarAt: event.seminarAt != null ? new Date(event.seminarAt).toAsialDateTimeString() : null,
            seminarType: event.seminarType || 1,
            seminarAtMailSendFlag: event.seminarAtMailSendFlag ? 0 : 1,
          },
        };
        if (progress.status === 70) {
          if (eventRequestObject.seminarType === 1) {
            eventRequestObject.seminarType = 0;
            eventRequestObject.action = 72;
            eventRequestObject.n = progress.n;
          } else if (eventRequestObject.seminarType === 99 || eventRequestObject.seminarType === 100) {
            eventRequestObject.action = 74;
            eventRequestObject.n = (progress.n || 0) + 1;
          }
        }
        eventRequest = this.progressService.updateWithAction(progress.id, eventRequestObject);
        if (remarksRequest) {
          remarksRequest = this.progressService.updateHistoryRemarks(progress.id, (progress.latestRevision || 0) + 1, remarksRequestObject);
        }
      }

      if (!event.seminarAtMailSendFlag) {
        this.progressService.getStakeholders([progress.id]).subscribe(
          (stakeholders: ProgressStakeholderEmailResponse[]) => {
            const stakeholder = stakeholders && stakeholders.length ? stakeholders[0] : null;
            if (stakeholders && stakeholder.studentInfo.mainAvailable === '不可') {
              alert('E-MAIL(メイン)が「送信不可」です。');
              this.eventEditors[progress.id].inSave = false;
            } else if (stakeholder) {
              this.mailService
                .getMailTemplates()
                .pipe(
                  map((templates: MailTemplateResponse[]) =>
                    templates.find((template) => template.id === (progress.type === 0 ? 14 : progress.type === 1 ? 19 : -1)),
                  ),
                )
                .subscribe(
                  (template: MailTemplateResponse) => {
                    if (template) {
                      const mailRequestObject = {
                        to: stakeholder.studentInfo.mainAvailable !== '不可' ? [stakeholder.studentInfo.main] : null,
                        cc: [
                          stakeholder.studentInfo.subAvailable !== '不可' ? stakeholder.studentInfo.sub : null,
                          ...stakeholder.jobUsersInfo.filter((jobUser) => jobUser.type === 0).map((jobUser) => jobUser.email),
                        ].filter((v) => v),
                        bcc: [
                          ...stakeholder.jobUsersInfo
                            .filter(
                              (jobUser) =>
                                jobUser.type ===
                                (progress.type === 0
                                  ? EnterpriseDepartmentUserType.RA
                                  : progress.type === 1
                                  ? EnterpriseDepartmentUserType.PA
                                  : -1),
                            )
                            .map((jobUser) => jobUser.email),
                        ].filter((v) => v),
                        userId: this.authService.loginUser.id,
                        from: this.authService.loginUser.email,
                        subject: template.subject,
                        text: template.body,
                        templateTypeId: 8,
                        jobId: progress.jobId,
                      };
                      const mailRequest = this.mailService
                        .replaceTemplate(mailRequestObject.templateTypeId, {
                          from: mailRequestObject.from,
                          to: mailRequestObject.to,
                          subject: mailRequestObject.subject,
                          text: mailRequestObject.text,
                          studentId: progress.studentId,
                          jobId: progress.jobId,
                        })
                        .pipe(
                          mergeMap((res) => {
                            mailRequestObject.subject = res.subject;
                            mailRequestObject.text = res.body;
                            return this.mailService.sendStudentMail(progress.studentId, mailRequestObject);
                          }),
                        );

                      if (eventRequest && remarksRequest) {
                        eventRequest = eventRequest.pipe(mergeMap(() => remarksRequest.pipe(mergeMap(() => mailRequest))));
                      } else if (remarksRequest) {
                        eventRequest = remarksRequest.pipe(mergeMap(() => mailRequest));
                      } else {
                        eventRequest = eventRequest.pipe(mergeMap(() => mailRequest));
                      }
                      eventRequest.subscribe(
                        () => {
                          this.refreshProgress(progress.id, () => {
                            this.eventEditors[progress.id].inSave = false;
                            this.onProgressEditorCancelClick(progress.id);
                          });
                        },
                        () => {
                          this.eventEditors[progress.id].inSave = false;
                        },
                      );
                    } else {
                      this.eventEditors[progress.id].inSave = false;
                    }
                  },
                  () => {
                    this.eventEditors[progress.id].inSave = false;
                  },
                );
            } else {
              this.eventEditors[progress.id].inSave = false;
            }
          },
          () => {
            this.eventEditors[progress.id].inSave = false;
          },
        );
      } else if (eventRequest || remarksRequest) {
        if (eventRequest && remarksRequest) {
          eventRequest = eventRequest.pipe(mergeMap(() => remarksRequest));
        } else if (remarksRequest) {
          eventRequest = remarksRequest;
        }
        eventRequest.subscribe(() => {
          this.refreshProgress(progress.id, () => {
            this.eventEditors[progress.id].inSave = false;
            this.onProgressEditorCancelClick(progress.id);
          });
        });
      } else {
        this.eventEditors[progress.id].inSave = false;
      }
    } else {
      progress.isOperationActionInProgress = true;
      remarksRequest.subscribe(() => {
        this.refreshProgress(progress.id, () => this.onProgressEditorCancelClick(progress.id));
      });
    }
  }

  public onProgressEditorCancelClick(progressId: number) {
    if (this.eventEditors[progressId] && !this.eventEditors[progressId].inSave) {
      delete this.eventEditors[progressId];
    }
    const progress = this.progresses.find((p) => p.id === progressId);
    if (progress) {
      progress.isOperationActionInProgress = false;
    }
  }

  public onProgressActionClick(progress: ProgressWithAsyncDetails, action: ProgressAction): void {
    if (!progress || !action) {
      return;
    }

    switch (action.type) {
      case ProgressActionType.DELETE:
        this.onDeleteActionClick(progress);
        break;
      case ProgressActionType.DIALOG:
        this.openBrowser('jobs/' + progress.jobId);
        break;
      case ProgressActionType.MAIL:
        this.handleUnsupportedAction();
        break;
      case ProgressActionType.EDITOR:
        this.onEditorActionClick(
          progress.id,
          action.request ? action.request((progress as unknown) as ProgressItemResponse, action.baseRequest) : null,
          action.seminarValues,
        );
        break;
      case ProgressActionType.OPERATION:
        this.onOperationActionClick(
          progress,
          action.request ? action.request((progress as unknown) as ProgressItemResponse, action.baseRequest) : null,
        );
        break;
      default:
        return;
    }
  }

  private onDeleteActionClick(progress: ProgressWithAsyncDetails): void {
    progress.isOperationActionInProgress = true;
    this.progressService.delete(progress.id).subscribe(
      () => {
        const index = this.progresses.findIndex((p) => p.id === progress.id);
        progress.isOperationActionInProgress = false;
        this.progresses.splice(index, 1);
      },
      () => (progress.isOperationActionInProgress = false),
    );
  }

  private onEditorActionClick(
    progressId: number,
    requestObject: ProgressUpdateWithActionRequest,
    seminarValues: { label: string; value: number }[],
  ): void {
    if (!requestObject) {
      return;
    }

    if (seminarValues) {
      this.editorMeta[progressId] = deepClone(this.baseEditorMeta);
      this.editorMeta[progressId].groups[0].rows[0].fields.last().options = seminarValues;
    } else {
      this.editorMeta[progressId] = this.baseEditorMeta;
    }

    this.eventEditors[progressId] = {
      seminarAt: null,
      seminarType: 1,
      seminarAtMailSendFlag: false,
      requestObject: requestObject,
      inSave: false,
    };
  }

  private onOperationActionClick(progress: ProgressWithAsyncDetails, requestObject: ProgressUpdateWithActionRequest): void {
    if (!requestObject) {
      return;
    }
    progress.isOperationActionInProgress = true;
    this.progressService.updateWithAction(progress.id, requestObject).subscribe(
      () => {
        this.refreshProgress(progress.id, () => (progress.isOperationActionInProgress = false));
      },
      () => (progress.isOperationActionInProgress = false),
    );
  }

  private refreshProgress(progressId: number, success?: () => void): void {
    if (!this.progresses.some((progress) => progress.id === progressId)) {
      return;
    }

    this.progressService.getDetail(progressId).subscribe((progress) => {
      const index = this.progresses.findIndex((p) => p.id === progressId);
      this.progresses[index].status = progress.status;
      this.progresses[index].n = progress.n;
      this.progresses[index].seminarType = progress.seminarType;
      const latestHistory = progress.progressHistories && progress.progressHistories[0];
      this.progresses[index].latestRevision = latestHistory && latestHistory.revision ? latestHistory.revision : 1;
      this.progresses[index].latestRemarks = latestHistory && latestHistory.remarks ? latestHistory.remarks : '';
      success();
    });
  }

  openBrowser(link: string): void {
    Office.context.ui['openBrowserWindow'](window.location.origin + '/' + link);
  }

  private handleUnsupportedAction(): void {
    // TODO: Change to japanese text
    alert('Please continue your work in a browser.');
  }
}
