import { TemplateRef } from '@angular/core';
import { SortOrder, TableConfig } from '../page-scroll-table/table-interface';

export const PROGRESS_LIST_TABLE_CONFIG: (
  statusTemplate: TemplateRef<any>,
  applicantTemplate: TemplateRef<any>,
  applicantContactTemplate: TemplateRef<any>,
  cellArrowTemplate: TemplateRef<any>,
  jobTemplate: TemplateRef<any>,
  jobContactTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  dateTransform: (date: string) => string,
) => TableConfig = (
  statusTemplate,
  applicantTemplate,
  applicantContactTemplate,
  cellArrowTemplate,
  jobTemplate,
  jobContactTemplate,
  actionsTemplate,
  dateTransform,
) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'status',
                title: 'ステータス',
                cellTemplate: statusTemplate,
              },
            ],
            style: {
              width: '210px',
              'margin-bottom': '3px',
            },
            bodyStyle: {
              'border-right': '1px solid #D9D9D9',
              display: 'flex',
              'justify-content': 'center',
              'flex-direction': 'column',
              padding: '0',
            },
          },
          {
            fields: [
              {
                name: 'student.frontId',
                hidden: true,
                sortable: false,
                style: {
                  color: '#9F9F9F',
                },
              },
              {
                name: 'applicant',
                title: '応募者   求職者担当者',
                sortable: false,
                cellTemplate: applicantTemplate,
                style: {
                  display: 'flex',
                  'align-items': 'flex-end',
                },
              },
              {
                name: 'contact',
                hidden: true,
                sortable: false,
                cellTemplate: applicantContactTemplate,
                style: {
                  'margin-top': '2px',
                },
              },
            ],
            style: {
              width: '200px',
              'padding-left': '11px',
              'margin-right': '20px',
              'margin-bottom': '3px',
            },
            bodyStyle: {
              'margin-top': '5px',
              'margin-bottom': '5px',
              'padding-top': '2px',
              'padding-bottom': '2px',
            },
          },
          {
            fields: [
              {
                name: 'arrow',
                hidden: true,
                sortable: false,
                cellTemplate: cellArrowTemplate,
              },
            ],
            style: {
              width: '31px',
              'margin-bottom': '3px',
            },
            bodyStyle: {
              'margin-top': '5px',
              'margin-bottom': '5px',
              'padding-top': '2px',
              'padding-bottom': '2px',
            },
          },
          {
            fields: [
              {
                name: 'job',
                title: '応募先   RA/PA担当者',
                sortable: false,
                cellTemplate: jobTemplate,
              },
              {
                name: 'contact',
                hidden: true,
                sortable: false,
                cellTemplate: jobContactTemplate,
                style: {
                  color: '#9F9F9F',
                },
              },
            ],
            style: {
              flex: 1,
              'min-width': '1px',
              'margin-bottom': '3px',
            },
            bodyStyle: {
              'margin-top': '15px',
              'margin-bottom': '5px',
              'padding-top': '2px',
              'padding-bottom': '2px',
            },
          },
          {
            fields: [
              {
                name: 'updatedAt',
                title: '更新日',
                defaultSort: SortOrder.DESCENDING,
                formatter: (data) => dateTransform(data.updatedAt),
                style: {
                  color: '#9F9F9F',
                  position: 'absolute',
                  top: '4px',
                  right: '8px',
                },
              },
            ],
            style: {
              'margin-bottom': '3px',
            },
            bodyStyle: {
              'margin-top': '5px',
              'margin-bottom': '5px',
              'padding-top': '2px',
              'padding-bottom': '2px',
            },
          },
        ],
      },
      {
        columns: [
          {
            fields: [
              {
                name: 'update',
                title: '最新進捗状況',
                cellTemplate: actionsTemplate,
                style: {
                  display: 'flex',
                  'flex-direction': 'column',
                },
              },
              {
                name: 'isStudentDelay',
                title: '学生起因',
                style: {
                  display: 'none',
                },
              },
            ],
            style: {
              flex: 1,
              'margin-top': '3px',
              display: 'grid',
              'grid-template-columns': 'auto 1fr',
              'grid-column-gap': '20px',
            },
            bodyStyle: {
              display: 'flex',
              'justify-content': 'flex-start',
              padding: '2px',
              background: '#F8F8F8',
              'padding-left': '40px',
              'padding-right': '30px',
              'margin-top': '0',
              'border-top': '1px solid #d9d9d9',
              'max-width': '100%',
            },
          },
        ],
        dynamicStyle: {
          margin: () => '1px',
        },
        clickAction: (event, data) => event.stopPropagation(),
      },
    ],
    style: {
      padding: '3px 0',
    },
  },
  body: {
    checkbox: true,
    arrow: true,
  },
});
