import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupConfig, MailPopupType } from '../config';
import { StudentBulkConfig } from './student-bulk-config';

export class StudentJobConfig extends StudentBulkConfig {
  public getTemplateTypeId(): number[] {
    return [2];
  }

  public getTemplateId(): number {
    return 1;
  }

  public getPopupConfig(): MailPopupConfig[] {
    return [{ type: MailPopupType.JOB, title: '一斉打診', message: '一斉打診する求人を選択してください。' }];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.jobs = params.jobs || [];
      this.sender.model.jobId = params.jobId;
      if (this.sender.model.jobs && this.sender.model.jobs.length) {
        this.sender.model.enterprises = this.sender.model.jobs.map((j) => ({
          id: j.enterpriseId,
          frontId: j.frontEnterpriseId,
          name: j.enterpriseName,
        }));
      }
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.students) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    metaData.groups[0].rows.push({
      title: 'To (求職者)',
      showRequired: true,
      fields: [
        {
          name: 'to',
          type: 'label',
          supplier: () =>
            this.sender
              ? this.sender.model.students
                  .map((s) =>
                    s.emailMainAvailable
                      ? `【送信不可】${s.lastName + s.firstName} メイン <${s.emailMain}>`
                      : `${s.lastName + s.firstName} メイン <${s.emailMain}>`,
                  )
                  .join(', ')
              : '',
        },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (求職者、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        row.fields.unshift(
          {
            name: 'cc_label',
            type: 'label',
            default: '※E-Mailサブにも送信',
          },
          {
            name: 'cc-hr',
            type: 'hr',
            class: 'full',
          },
          {
            name: 'cc2_label',
            type: 'label',
            default: '※求職者担当にも送信',
          },
        );
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const template = getValue('dummyTemplateType');
                return template ? value || template.subject : undefined;
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = ({
          title: '添付ファイル',
          fields: [
            {
              type: 'label',
              name: 'jobs',
              linkTo: ['enterpriseFileIds'],
              supplier: () => this.sender.model.jobs.map((j: any) => `${j.frontEnterpriseId} ${j.enterpriseName}`).join(', '),
            },
          ],
        } as unknown) as RowMeta;
        row.class = 'ou';
        if (!this.sender.inConfirm) {
          row.fields[0].labelBefore = '選択した企業(求人)：';
          row.fields[0].linkTo = ['enterpriseFileIds'];
        } else {
          row.fields.length = 0;
        }

        if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
          row.fields.push({
            name: 'enterpriseFileIds',
            type: 'autocomplete',
            labelField: [
              {
                name: 'name',
                class: this.sender.inConfirm ? 'link' : null,
                action: this.sender.inConfirm
                  ? (file) =>
                      this.sender.fileService.downloadFile(
                        'enterprise',
                        +Object.keys(this.sender.enterpriseFiles).find(
                          (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                        ),
                        file.id,
                        file.name,
                      )
                  : null,
              },
            ],
            labelBefore: '企業：',
            valueField: 'id',
            multi: true,
            class: 'options-only half',
            options: [],
            actions: [
              {
                title: 'データフォルダから選択',
                type: 'RUNNABLE',
                runnable: () => {
                  this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
                  PopupControlComponent.instance.open({
                    content: this.sender.fileSelectTemplate,
                    confirmText: '添付',
                    cancelText: 'キャンセル',
                    title: 'データフォルダ',
                    confirmCallback: () => {
                      this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
                    },
                  });
                },
              },
            ],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              this.sender.enterpriseFiles = {};
              this.sender.model.jobs.forEach(
                (j) => (this.sender.enterpriseInfoById[j.enterpriseId] = `${j.frontEnterpriseId} ${j.enterpriseName}`),
              );
              return forkJoin(
                this.sender.model.jobs.map((j) => {
                  return this.sender.fileService.getFiles('enterprise', j.enterpriseId).pipe(
                    map((res) => {
                      this.sender.enterpriseFiles[j.enterpriseId] = res.filter((f) => f.studentSendFlag);
                      return { options: this.sender.enterpriseFiles[j.enterpriseId] };
                    }),
                  );
                }),
              ).pipe(map((res) => ({ options: Object.values(this.sender.enterpriseFiles).flatten() })));
            },
          });
        }
        return row;
      })(),
    );

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const template = getValue('dummyTemplateType');
              return template ? value || template.body : undefined;
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups.unshift({
        title: '選択した求人',
        class: 'no-title-column',
        rows: [
          {
            fields: this.sender.model.jobs.map((job: any, index: string) => ({
              name: 'jobs' + index,
              type: 'textarea',
              class: 'quarter',
              disabled: true,
              supplier: () => `${job.frontId}\n${job.position}\n${job.enterpriseName}`,
            })),
          },
        ],
      });
      metaData.groups[0].rows[0].fields.push({
        type: 'label',
        name: 'dummy',
        actions: [
          {
            type: 'RUNNABLE',
            title: '求人を再選択',
            runnable: () => this.sender.showPopup.emit(MailPopupType.JOB),
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public save(toSave: any): void {
    toSave.jobIds = this.sender.model.jobs.map((j) => j.id);
    this.sender.progressApiService.checkProgress(this.sender.model.students.map((s) => s.id), toSave.jobIds).subscribe((res) => {
      if (res && res.length) {
        this.sender.progressErrorInfo = res;
        PopupControlComponent.instance.open({
          cancelText: '中止',
          cancelCallback: () => this.sender.close(),
          confirmText: null,
          content: this.sender.progressErrorTemplate,
          title: '進捗重複',
          confirmCallback: null,
          multiConfirm: [
            {
              confirmText: '重複案件は上書きしないで送信のみ行う',
              confirmCallback: () =>
                this.sender.mailApiService.sendJobStudentsBulkMail(toSave).pipe(tap((result) => this.sender.afterSend(result))),
            },
            {
              confirmText: '重複案件は上書き',
              confirmCallback: () =>
                this.sender.mailApiService
                  .sendJobStudentsBulkMail(toSave)
                  .pipe(
                    mergeMap((result) =>
                      this.sender.progressApiService
                        .bulkCreate(toSave.jobIds, this.sender.model.students.map((s) => s.id))
                        .pipe(tap(() => this.sender.afterSend(result))),
                    ),
                  ),
            },
            {
              confirmText: '重複案件を除いて送信',
              confirmEnabled: () =>
                // disable when all of selected have '進捗重複' errors
                this.sender &&
                this.sender.progressErrorInfo &&
                this.sender.model.students &&
                !(this.sender.progressErrorInfo.length === toSave.jobIds.length * this.sender.model.students.length),
              confirmCallback: () => {
                const studentsWithJobIds: { [email: string]: { studentId: number; jobIds: number[] } } = {};
                this.sender.model.students.forEach((student) => {
                  studentsWithJobIds[student.emailMain] = {
                    studentId: student.id,
                    jobIds: toSave.jobIds.filter(
                      (jobId) => !this.sender.progressErrorInfo.some((error) => error.studentId === student.id && error.jobId === jobId),
                    ),
                  };
                });
                const sendMailObs = [];
                const progressCreateCombinations = [];

                const dests: [] = toSave.to.filter((email) => studentsWithJobIds[email].jobIds.length > 0);

                dests.forEach((email) => {
                  sendMailObs.push(
                    this.sender.mailApiService.sendJobStudentsBulkMail({
                      ...toSave,
                      to: [email],
                      jobIds: studentsWithJobIds[email].jobIds,
                    }),
                  );
                  progressCreateCombinations.push({
                    studentId: studentsWithJobIds[email].studentId,
                    jobIds: studentsWithJobIds[email].jobIds,
                  });
                });

                if (dests.length > 0) {
                  return forkJoin(sendMailObs).pipe(
                    mergeMap((result) =>
                      this.sender.progressApiService
                        .bulkCreateCombination(progressCreateCombinations)
                        .pipe(tap(() => this.sender.afterSend(result))),
                    ),
                  );
                }
              },
              confirmClass: 'btn--green',
            },
          ],
        });
      } else {
        this.sender.mailApiService
          .sendJobStudentsBulkMail(toSave)
          .subscribe((result) =>
            this.sender.progressApiService
              .bulkCreate(toSave.jobIds, this.sender.model.students.map((s) => s.id))
              .subscribe(() => this.sender.afterSend(result)),
          );
      }
    });
  }
}
