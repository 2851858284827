import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { JobTabs } from '@agent-ds/jobs/pages/job-detail-page/tabs/job-tabs.enum';
import { JobStudentMatchingPageComponent } from '@agent-ds/matching/pages/job-student-matching-page/job-student-matching-page.component';
import { RAPACA_VALUE_BADGE_CLASSES } from '@agent-ds/shared/constants';
import {
  DashboardType,
  EnterpriseDepartmentUserType,
  IndustryType,
  JobList,
  JobListItem,
  JobSearchParams,
  JobType,
} from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DialogService, JobApiService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { DecimalPipe, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobCreateDialogComponent } from '../../../jobs/components/job-create-dialog/job-create-dialog.component';
import { DialogRef } from '../dialog/dialog-ref';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { BatchResult, SortOrder, TableColumn, TableConfig, TableRow } from '../page-scroll-table/table-interface';
import { JOBS_TABLE_CONFIG } from './jobs-table-config';

const oneDay = 24 * 60 * 60 * 1000;
enum JobRibbonType {
  'NEW' = 1,
  '再オープン' = 2,
}

const JobRibbonClasses: { [key: number]: string } = {
  1: 'ribbon__label__red',
  2: 'ribbon__label__orange',
};

const RecommendationHeatClasses: { [key: string]: string } = {
  次月: 'badge--yellow-light',
  次月注力: 'badge--yellow-light',
  月内: 'badge--red-light-alt',
};

@Component({
  selector: 'ag-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('newArrival', { static: false }) newArrivalTemplate: TemplateRef<any>;
  @ViewChild('personsInCharge', { static: false }) personsInChargeTemplate: TemplateRef<any>;
  @ViewChild('company', { static: false }) companyTemplate: TemplateRef<any>;
  @ViewChild('position', { static: false }) positionTemplate: TemplateRef<any>;
  @ViewChild('heat', { static: false }) heatTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('favorites', { static: false }) favoritesTemplate: TemplateRef<any>;
  @Input() customActionsTemplate: TemplateRef<any>;
  @Input() requestObject: JobSearchParams = {};
  @Input() options: any = {};
  @Input() dynamicColumns: TableColumn[];
  @Input() dynamicRows: TableRow[];
  @Input() extraControls: TemplateRef<any>;
  @Input() matchingMode: boolean;
  @Input() content?: any[];
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();
  jobLoader?: (page: number, pageSize: number, sort: string, sortOrder: SortOrder) => Observable<BatchResult>;

  private dialogRef: DialogRef;

  classMap = {
    中堅以上: 'badge--green-light',
    中小: 'badge--blue',
    販サOS介護: 'badge--orange-seg',
    IT注力: 'badge--purple-light2',
  };

  constructor(
    public readonly masterApiService: MasterApiService,
    private service: JobApiService,
    private readonly datePipe: SafeDatePipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly authService: AuthService,
    private cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private location: Location,
    private router: Router,
  ) {}

  tableConfig: TableConfig;
  readonly jobDepartmentUserType = EnterpriseDepartmentUserType;
  readonly jobRibbonType = JobRibbonType;
  readonly jobRibbonClasses = JobRibbonClasses;
  readonly recommendationHeatClasses = RecommendationHeatClasses;
  readonly RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
  jobTypes: JobType[] = [];
  industryTypes: IndustryType[] = [];
  filterOpen = false;
  initialized = false;

  get checkedItems(): JobListItem[] {
    return this.table ? this.table.checkedItems : [];
  }

  get allChecked(): boolean {
    return this.table.allChecked;
  }

  get totalSize(): number {
    return this.table ? this.table.totalSize : 0;
  }

  private refreshSubscription: Subscription;
  private loginSubscription: Subscription;
  private isIntakeCa: boolean;

  ngOnInit(): void {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });

    this.refreshSubscription = this.service.refreshEvent.subscribe(() => this.ngOnChanges());
    this.masterApiService
      .getJobTypes()
      .subscribe((types) => (this.jobTypes = types.flatten((subType, level) => (subType['_selectLevel'] = level), 'jobTypes')));
    this.masterApiService
      .getIndustryTypes()
      .subscribe((types) => (this.industryTypes = types.flatten((subType, level) => (subType['_selectLevel'] = level), 'industryTypes')));

    if (!this.options.sort) {
      this.options.sort = { field: 'updatedAt', order: 'desc' };
    }

    if (this.router.url.endsWith('/jobs/add')) {
      this.openJobCreateDialog('jobs/list');
    }

    this.jobLoader = this.content ? null : this.loadJobs;
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || (changes['requestObject'] && changes['requestObject'].previousValue !== changes['requestObject'].currentValue)) {
      this.cdr.detectChanges();
      if (this.table && this.initialized) {
        this.table.reset(true);
        this.table.next();
      }
    }
    if (changes && changes['content'] && changes['content'].previousValue !== changes['content'].currentValue) {
      this.jobLoader = changes['content'].currentValue ? null : this.loadJobs;
    }
    if (changes && changes['dynamicColumns'] && !!changes['dynamicColumns'].previousValue !== !!changes['dynamicColumns'].currentValue) {
      this.configureTable();
    }
  }

  ngAfterViewInit() {
    this.configureTable();
    this.cdr.detectChanges();
    this.initialized = true;
    if (this.requestObject && this.table) {
      this.table.reset(true);
      this.table.next();
    }
  }

  configureTable(): void {
    this.tableConfig = JOBS_TABLE_CONFIG(
      this.dynamicColumns,
      this.dynamicRows,
      this.newArrivalTemplate,
      this.personsInChargeTemplate,
      this.companyTemplate,
      this.positionTemplate,
      this.heatTemplate,
      this.favoritesTemplate,
      this.customActionsTemplate || this.actionsTemplate,
      (data) => this.datePipe.transform(data, 'yyyy/MM/dd'),
      (num: any) => this.decimalPipe.transform(num, '0.0-1'),
      (data) => (data ? (this.jobTypes.find((type) => type.code === data.code1) || { name: '' }).name : ''),
      (data) => (this.industryTypes.find((industry) => industry.code === data) || { name: '' }).name,
      this.options,
    );
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private convertJobList(res: JobList): BatchResult {
    // default result
    const result = {
      result: [],
      totalSize: res && res.total ? res.total : 0,
    };

    // map all elements to make object root to _source property
    if (res && res.jobs && res.jobs.length > 0) {
      result.result = res.jobs;
    }

    result.result.forEach((job: JobListItem) => {
      const postedDate = Date.parse(job.postedAt);
      if ((Date.now() - postedDate) / oneDay < 7 && job.dynamicData && job.dynamicData.status === '募集中') {
        job.ribbon = job.postingCount === 1 ? 1 : job.postingCount > 1 ? 2 : undefined;
      }

      if (job.jobUserFavorite) {
        const currentUserId = this.authService.loginUser ? this.authService.loginUser.id : undefined;
        job.favorite = job.jobUserFavorite.find((userFavorite) => userFavorite.userId === currentUserId) !== undefined;
      }
    });

    return result;
  }

  private cleanObject(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] == null || obj[propName] === '') {
        delete obj[propName];
      }
    }

    return obj;
  }

  loadJobs = (page: number, size: number, sort?: string, order?: string): Observable<BatchResult> => {
    const params: JobSearchParams = this.cleanObject({
      ...this.requestObject,
      from: page * size,
      size: size,
      sort: sort === 'updatedAt' && this.matchingMode ? 'matching' : sort,
      order: order,
    });

    if (this.options && this.options.filterOpen && !this.filterOpen) {
      params.status = ['作成中', '企業確認待ち', '企業確認済み', '募集中', '推薦停止'];
    }

    if (!params.sort) {
      params.sort = this.options.sort.field;
      params.order = this.options.sort.order;
    }

    return this.service.getList(params).pipe(map((res: JobList) => this.convertJobList(res)));
  };

  filterOpenChanged(): void {
    this.filterOpen = !this.filterOpen;
    this.table.reset(true);
    this.table.next();
  }

  onItemSelected(selectedItem): void {
    JobDetailPageComponent.instance.referenceId = selectedItem.id;
    JobDetailPageComponent.instance.open();
  }

  onItemChecked(checkedItemEvent): void {
    this.itemChecked.emit(checkedItemEvent);
  }

  onCompanyLinkClick(companyId: number): void {
    CompanyDetailPageComponent.instance.referenceId = companyId;
    CompanyDetailPageComponent.instance.open();
  }

  onJobLinkClick(job: JobListItem, tab: string): void {
    if (tab === 'userList') {
      JobDetailPageComponent.instance.selectedSideActionIndex = 1;
    } else if (tab === 'matching') {
      JobStudentMatchingPageComponent.instance.referenceId = job.id;
      JobStudentMatchingPageComponent.instance.open();
      return;
    } else {
      JobDetailPageComponent.instance.tabCode = tab;
    }
    this.onItemSelected(job);
  }

  onFavoriteClicked(job: JobListItem) {
    if (job.favorite) {
      this.service.deleteFavorite(job.id).subscribe(() => {
        job.favorite = false;
      });
    } else {
      this.service.addFavorite(job.id).subscribe(() => {
        job.favorite = true;
      });
    }
  }

  openJobCreateDialog(origUrl = this.location.path()): void {
    if (this.dialogRef) {
      return;
    }
    this.location.go('/jobs/add');
    JobDetailPageComponent.instance.close();
    this.dialogRef = this.dialog.open(JobCreateDialogComponent, { data: { enterpriseId: this.requestObject.enterpriseId } });
    let closeSubscription: Subscription;
    let navigationSubscription: SubscriptionLike;
    const unsubscribe = () => {
      if (closeSubscription) {
        closeSubscription.unsubscribe();
      }
      if (navigationSubscription) {
        navigationSubscription.unsubscribe();
      }
    };
    closeSubscription = this.dialogRef.afterClosed.subscribe((res) => {
      this.location.go(origUrl);
      this.dialogRef = null;
      if (res && res.id) {
        JobDetailPageComponent.instance.tabCode = JobTabs[JobTabs.DETAIL];
        this.onItemSelected(res);
      }
      unsubscribe();
    });
    navigationSubscription = this.location.subscribe(() => {
      this.dialogRef.close();
      unsubscribe();
    });
  }
}
