import { JobUserCheckResponse, MailPrefill } from '@agent-ds/shared/interfaces';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MailPopupConfig, MailPopupType, MailSendConfig } from '../mail-send/config';
import { MailSendComponent } from '../mail-send/mail-send.component';
import { SideActionConfig } from '../page-floater/page-floater-interface';
import { PageFloaterComponent } from '../page-floater/page-floater.component';
import { PopupControlComponent } from '../popup-control/popup-control.component';

@Component({
  selector: 'ag-mail-send-flow',
  templateUrl: './mail-send-flow.component.html',
  styleUrls: ['./mail-send-flow.component.scss'],
})
export class MailSendFlowComponent implements OnInit {
  private static INSTANCE: MailSendFlowComponent;
  public static get instance(): MailSendFlowComponent {
    return MailSendFlowComponent.INSTANCE;
  }

  @ViewChild(PageFloaterComponent, { static: false }) mailFloater: PageFloaterComponent;
  @ViewChild(MailSendComponent, { static: true }) mailSend: MailSendComponent;
  @ViewChild('jobSelect', { static: true }) jobSelectTemplate: TemplateRef<any>;
  @ViewChild('jobError', { static: false }) jobErrorTemplate: TemplateRef<any>;
  @ViewChild('jobWarning', { static: false }) jobWarningTemplate: TemplateRef<any>;
  @ViewChild('depSelect', { static: true }) depSelectTemplate: TemplateRef<any>;
  @ViewChild('studentSelect', { static: true }) studentSelectTemplate: TemplateRef<any>;
  @Output() sent = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();

  popupMessage: string;

  mailSideActions: SideActionConfig[] = [{ phases: ['1000px'] }];
  popupMeta: FormMeta = {
    groups: [
      {
        class: 'no-background no-border',
        rows: [],
      },
    ],
  };
  popupSendMeta: FormMeta = {
    groups: [
      {
        class: 'no-background no-border',
        rows: [],
      },
    ],
  };
  popupModel: any = {};
  private jobCheckObs: Observable<any>;
  jobErrorInfo: JobUserCheckResponse[] = [];
  jobWarningInfo: JobUserCheckResponse[] = [];

  private currentFlowIndex = null;

  constructor() {
    if (MailSendFlowComponent.INSTANCE == null) {
      MailSendFlowComponent.INSTANCE = this;
    }
  }

  ngOnInit() {}

  public set config(sendConfig: MailSendConfig) {
    this.mailSend.config = sendConfig;
  }

  public start(prefill?: MailPrefill): void {
    this.closeFlow();
    this.mailSend.reset(prefill);
    this.mailSend.update();
    this.currentFlowIndex = -1;
    this.next();
  }

  public close(closeSender = false): void {
    if (closeSender) {
      this.mailSend.close();
      return;
    }
    this.closeFlow();
    this.mailFloater.close();
    this.closed.emit();
  }

  public closeFlow(): void {
    this.currentFlowIndex = null;
    PopupControlComponent.closeAll();
  }

  private prev(step: number = 1): void {
    if (this.currentFlowIndex != null) {
      this.currentFlowIndex -= step;
      const config = this.mailSend.config.getPopupConfig()[this.currentFlowIndex];
      if (config) {
        setTimeout(() => this.openPopup(config.type));
      } else {
        this.closeFlow();
      }
    } else {
      PopupControlComponent.closeAll();
    }
  }

  private next(): void {
    if (this.currentFlowIndex != null) {
      const config = this.mailSend.config.getPopupConfig()[++this.currentFlowIndex];
      if (config) {
        setTimeout(() => this.openPopup(config.type));
      } else if (!this.mailSend.config.sendAfterFlow()) {
        this.closeFlow();
        this.mailSend.config.initForm();
        this.mailFloater.open();
      } else {
        this.mailSend.confirm(true);
      }
    } else {
      PopupControlComponent.closeAll();
    }
  }

  public openPopup(type: MailPopupType): void {
    const popupConfig = this.mailSend.config.getPopupConfig().find((c) => c.type === type);
    if (!popupConfig) {
      return;
    }
    this.popupSendMeta.groups[0].rows = [];
    if (type === MailPopupType.JOB) {
      this.popupMeta.groups[0].rows = this.mailSend.dynamicService.getFormRows({
        fieldName: 'jobs',
        fieldType: popupConfig.multiSelect ? 'multi-job' : 'job',
        displayType: 'email',
        label: '求人',
      });
      this.popupMeta.groups[0].rows[0].fields[0].filters = [
        {
          name: 'status',
          hidden: true,
          options: [['募集中', '推薦停止']],
          supplier: () => 0,
        },
      ];
      this.popupMeta.groups[0].rows[0].fields[0].specialOption = null;
      this.showJobSelect(popupConfig);
    } else if (type === MailPopupType.DEPARTMENT) {
      this.popupMeta.groups[0].rows = [];
      const enterpriseRow = this.mailSend.dynamicService.getFormRows(
        { fieldName: 'enterpriseId', fieldType: 'enterprise', label: '企業' },
        null,
        'half',
      )[0];
      enterpriseRow.hidden = this.mailSend.model.enterpriseId != null;
      enterpriseRow.fields[0].linkTo = ['department'];
      this.popupMeta.groups[0].rows.push(enterpriseRow, {
        title: '部署',
        fields: [
          {
            name: 'department',
            type: 'dropdown',
            class: 'half tall',
            labelField: 'dynamicData.name',
            options: [],
            allowOn: { enterpriseId: null },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const linkValue = getValue('enterpriseId');
              return linkValue
                ? this.mailSend.companyApiService.getDepartments(linkValue).pipe(
                    map((v) => ({
                      options: v.enterpriseDepartments,
                    })),
                  )
                : { options: [] };
            },
            validators: { required: true },
          },
        ],
      });
      this.showDepSelect(popupConfig);
    } else if (type === MailPopupType.JOB_CHECK) {
      this.runJobCheck(popupConfig);
    } else if (type === MailPopupType.ENTERPRISE_RESUME_PASSWORD_CHECK) {
      this.runEnterpriseResumePasswordCheck();
    } else if (type === MailPopupType.STUDENT) {
      this.popupMeta.groups[0].rows = this.mailSend.dynamicService.getFormRows({
        fieldName: 'students',
        fieldType: popupConfig.multiSelect ? 'multi-student' : 'student',
        displayType: 'email',
        label: '対象求職者',
      });
      if (popupConfig.bodyArea) {
        this.popupSendMeta.groups[0].rows = [
          {
            title: 'メールの\n追記文言',
            fields: [
              {
                type: 'textarea',
                name: 'body',
                class: 'full',
              },
            ],
          },
        ];
      }
      this.showStudentSelect(popupConfig);
    }
  }

  private runEnterpriseResumePasswordCheck(allowFail = false): void {
    if (this.mailSend.model.enterpriseId == null) {
      return;
    }
    this.mailSend.companyApiService.getDetail(this.mailSend.model.enterpriseId).subscribe((enterprise) => {
      if (enterprise.dynamicData.resumePassword == null) {
        PopupControlComponent.subInstance.open({
          title: 'レジュメ送信',
          content: 'レジュメ送信用パスワードが設定されていません。',
          confirmText: '閉じる',
          confirmCallback: () => {
            if (allowFail) {
              this.mailSend.model.enterprise = enterprise;
              this.mailSend.config.update();
              this.next();
            }
          },
        });
      } else {
        this.mailSend.model.enterprise = enterprise;
        this.mailSend.config.update();
        this.next();
      }
    });
  }

  private showJobSelect(popupConfig: MailPopupConfig): void {
    this.popupModel.jobs = popupConfig.multiSelect ? [...this.mailSend.model.jobs] : this.mailSend.model.jobs[0];
    this.popupMessage = popupConfig.message;
    PopupControlComponent.instance.open({
      content: this.jobSelectTemplate,
      confirmText: popupConfig.confirmText || 'メールを作成',
      cancelText: 'キャンセル',
      title: popupConfig.title || 'レジュメ送信',
      closeCallback: () => this.prev(popupConfig.stepBack),
      confirmEnabled: () => this.popupModel.jobs && (!popupConfig.multiSelect || this.popupModel.jobs.length) && !this.jobCheckObs,
      confirmCallback: () => {
        this.mailSend.model.jobs = popupConfig.multiSelect ? [...this.popupModel.jobs] : [this.popupModel.jobs];
        this.jobCheckObs = this.mailSend.jobApiService.ckeckJobUsers(this.mailSend.model.jobs.map((j: { id: any }) => j.id), [
          '募集中',
          '推薦停止',
        ]);
        this.jobCheckObs.subscribe((res) => {
          this.jobErrorInfo = [];
          this.jobWarningInfo = [];
          this.jobCheckObs = null;
          res.forEach((r: JobUserCheckResponse) => {
            if (
              !r.status.length ||
              !r.jobUsers.length ||
              (popupConfig.customFilter &&
                popupConfig.customFilter.jobUserType &&
                r.jobUsers.find((u) => u.type === popupConfig.customFilter.jobUserType) == null)
            ) {
              this.jobErrorInfo.push(r);
            }
            if (r.status.includes('推薦停止')) {
              this.jobWarningInfo.push(r);
            }
            const job = this.mailSend.model.jobs.find((j) => j.id === r.jobId);
            if (job) {
              job.jobUsers = r.jobUsers;
            }
          });

          if (this.jobErrorInfo.length) {
            PopupControlComponent.subInstance.open({
              title: '必要な情報が入力されていません',
              confirmText: '閉じる',
              content: this.jobErrorTemplate,
              confirmCallback: () => false,
            });
            return;
          } else if (this.jobWarningInfo.length) {
            PopupControlComponent.subInstance.open({
              title: '求人票送信',
              content: this.jobWarningTemplate,
              cancelText: 'キャンセル',
              confirmText: 'OK',
              confirmCallback: () => {
                this.mailSend.model.enterpriseId = this.mailSend.model.jobs[0].enterpriseId;
                this.runEnterpriseResumePasswordCheck(true);
              },
            });
            return;
          } else if (!popupConfig.multiSelect) {
            this.mailSend.model.enterpriseId = this.mailSend.model.jobs[0].enterpriseId;
            this.runEnterpriseResumePasswordCheck(true);
          } else {
            this.mailSend.config.initForm();
            this.next();
          }
        });
        return true;
      },
    });
  }

  private showDepSelect(popupConfig: MailPopupConfig): void {
    this.popupModel.enterpriseId = this.mailSend.model.enterpriseId;
    this.popupModel.department = null;
    PopupControlComponent.instance.open({
      content: this.depSelectTemplate,
      confirmText: '選択',
      cancelText: 'キャンセル',
      title: '部署選択',
      closeCallback: () => this.prev(popupConfig.stepBack),
      confirmEnabled: () => this.popupModel.department && (!popupConfig.multiSelect || this.popupModel.department.length),
      confirmCallback: () => {
        this.mailSend.model.department = this.popupModel.department;
        this.mailSend.model.departmentId = this.popupModel.department.id;
        return this.mailSend.companyApiService.getDetail(this.mailSend.model.enterpriseId).pipe(
          tap((comp) => {
            this.mailSend.model.enterprise = comp;
            this.mailSend.config.update();
            this.next();
          }),
        );
      },
    });
  }

  private showStudentSelect(popupConfig: MailPopupConfig): void {
    this.popupModel.students = popupConfig.multiSelect ? [...this.mailSend.model.students] : (this.mailSend.model.students || [])[0];
    this.popupMessage = popupConfig.message;
    this.popupModel.body = null;
    PopupControlComponent.instance.open({
      content: this.studentSelectTemplate,
      confirmText: popupConfig.confirmText || '送信して一括推薦',
      cancelText: 'キャンセル',
      title: popupConfig.title || '一括推薦',
      cancelCallback: () => this.prev(popupConfig.stepBack),
      confirmEnabled: () => this.popupModel.students && (!popupConfig.multiSelect || this.popupModel.students.length),
      confirmCallback: () => {
        this.mailSend.model.students = popupConfig.multiSelect ? [...this.popupModel.students] : [this.popupModel.students];
        if (this.mailSend.model.students.length === 1) {
          this.mailSend.model.studentId = this.mailSend.model.students[0].id;
        }
        if (this.popupModel.body) {
          this.mailSend.model.body = this.popupModel.body;
        }
        this.next();
      },
    });
  }

  private runJobCheck(popupConfig: MailPopupConfig): void {
    this.jobCheckObs = this.mailSend.jobApiService.ckeckJobUsers(this.mailSend.model.jobs.map((j: { id: any }) => j.id), [
      '募集中',
      '推薦停止',
    ]);
    this.jobCheckObs.subscribe((res) => {
      this.jobErrorInfo = [];
      this.jobCheckObs = null;
      this.jobWarningInfo = [];
      res.forEach((r: JobUserCheckResponse) => {
        if (
          !r.status.length ||
          !r.jobUsers.length ||
          (popupConfig.customFilter &&
            popupConfig.customFilter.jobUserType &&
            r.jobUsers.find((u) => u.type === popupConfig.customFilter.jobUserType) == null)
        ) {
          // comment this out if you don't have jobs without errors
          this.jobErrorInfo.push(r);
        }
        if (r.status.includes('推薦停止')) {
          this.jobWarningInfo.push(r);
        }
        const job = this.mailSend.model.jobs.find((j) => j.id === r.jobId);
        if (job) {
          job.jobUsers = r.jobUsers;
        }
      });

      if (this.jobErrorInfo.length) {
        PopupControlComponent.instance.open({
          title: '必要な情報が入力されていません',
          confirmText: '閉じる',
          content: this.jobErrorTemplate,
          confirmCallback: () => false,
        });
        return;
      } else if (this.jobWarningInfo.length) {
        PopupControlComponent.instance.open({
          title: popupConfig.title || '求人票送信',
          content: this.jobWarningTemplate,
          cancelText: 'キャンセル',
          confirmText: 'OK',
          confirmCallback: () => {
            this.mailSend.config.update();
            this.next();
          },
        });
        return;
      } else {
        this.mailSend.config.update();
        this.next();
      }
    });
  }
}
