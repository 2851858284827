import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { getProgressStatusLabel } from '@agent-ds/shared/constants';
import {
  ContactHistory,
  ContactHistoryAction,
  DataFile,
  IndustryType,
  JobType,
  ProgressItemResponse,
  StudentDetail,
} from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { FileApiService, StudentApiService, UserApiService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { StudentTabs } from '../student-tabs';

@Component({
  selector: 'ag-student-overview-tab',
  templateUrl: './student-overview-tab.component.html',
  styleUrls: ['./student-overview-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentOverviewTabComponent) }],
})
export class StudentOverviewTabComponent extends Tab implements OnInit, OnChanges {
  @Input() student: StudentDetail;
  @Input() progresses: ProgressItemResponse[] = [];
  readonly tabs = StudentTabs;

  jobTypes: JobType[] = [];
  industryTypes: IndustryType[] = [];
  files: DataFile[] = [];
  contacts: ContactHistory[] = [];
  progressesMain: ProgressItemResponse[] = [];
  progressesMainPlus: ProgressItemResponse[] = [];
  progressesSub: ProgressItemResponse[] = [];
  progressesSubPlus: ProgressItemResponse[] = [];
  readonly contactAction = ContactHistoryAction;
  getStatusLabel = getProgressStatusLabel;

  constructor(
    private studentService: StudentApiService,
    private masterService: MasterApiService,
    public fileService: FileApiService,
    private userService: UserApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.masterService.getFlattenedJobTypes().subscribe((jobs) => (this.jobTypes = jobs));
    this.masterService.getFlattenedIndustryTypes().subscribe((types) => (this.industryTypes = types));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['student'] && this.student) {
      this.files.length = 0;
      this.contacts.length = 0;
      this.fileService.getFiles('students', this.student.id).subscribe((result) => (this.files = result));
      this.studentService.getContacts(this.student.id).subscribe((result) => (this.contacts = result));
    }
    if (changes['progresses'] && this.progresses) {
      this.progressesMain = this.progresses.filter((prog) => [60, 70, 80, 90, 100, 110, 120, 130].includes(prog.status));
      this.progressesMainPlus = this.progressesMain.filter((prog) => prog.type === 1);
      this.progressesSub = this.progresses.filter((prog) => [100, 110, 120, 130].includes(prog.status));
      this.progressesSubPlus = this.progressesSub.filter((prog) => prog.type === 1);
    }
  }

  findUserTeam(userId: number): Observable<{ userName: string; teamName: string }> {
    return this.userService.getAll().pipe(
      mergeMap((users) => {
        const user = users.find((u) => u.id === userId);
        if (user) {
          return this.masterService.getTeams().pipe(
            map((teams) => {
              const team = teams.find((t) => t.id === user.teamId);
              if (team) {
                return { userName: user.name, teamName: team.name };
              } else {
                return { userName: user.name, teamName: '' };
              }
            }),
          );
        }
        return null;
      }),
    );
  }

  downloadFile(file: DataFile) {
    this.fileService.downloadFile('students', this.student.id, file.id, file.name);
  }

  onCompanyLinkClick(id: number): void {
    if (id) {
      CompanyDetailPageComponent.instance.referenceId = id;
      CompanyDetailPageComponent.instance.open();
    }
  }

  onJobLinkClick(id: number): void {
    if (id) {
      JobDetailPageComponent.instance.referenceId = id;
      JobDetailPageComponent.instance.open();
    }
  }
}
