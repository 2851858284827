import { Company } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'ag-company-files-tab',
  templateUrl: './company-files-tab.component.html',
  styleUrls: ['./company-files-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyFilesTabComponent) }],
})
export class CompanyFilesTabComponent extends Tab {
  @Input() company: Company;

  constructor() {
    super();
  }
}
