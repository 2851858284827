import { SharedModule } from '@agent-ds/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DASHBOARD_COMPONENTS, DASHBOARD_DIALOG_COMPONENTS } from './components';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DASHBOARD_PAGES } from './pages';

@NgModule({
  declarations: [DASHBOARD_PAGES, DASHBOARD_COMPONENTS, DASHBOARD_DIALOG_COMPONENTS],
  imports: [CommonModule, SharedModule, DashboardRoutingModule],
  entryComponents: [DASHBOARD_DIALOG_COMPONENTS],
})
export class DashboardModule {}
