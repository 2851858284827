import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { DataFile, Job, MailTemplateNameResponse } from '@agent-ds/shared/interfaces';
import { JobSettingMail } from '@agent-ds/shared/interfaces/job-setting-mail';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { DialogService, DynamicFieldService, FileApiService, JobApiService, MailApiService } from '@agent-ds/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EMPTY, forkJoin, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'ag-job-mail-tab',
  templateUrl: './job-mail-tab.component.html',
  styleUrls: ['./job-mail-tab.component.scss'],
})
export class JobMailTabComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private dynamicService: DynamicFieldService,
    private mailApiService: MailApiService,
    private fileApiService: FileApiService,
    private jobApiService: JobApiService,
    private dialogService: DialogService,
  ) {}
  @ViewChild('fileSelect', { static: false }) fileSelectTemplate: TemplateRef<any>;
  @Input() job: Job;

  headMeta: FormMeta = {
    groups: [],
  };

  // APIレスポンスのモデル
  jobSettingMail: JobSettingMail = JobMailTabComponent.getJobSettingMailDefault();

  // 添付ファイルのフォームが受け付ける型がAPIのレスポンスとは合わないので、変換するためのモデル
  dynamicFormModel: { template: { id: number }; enterpriseDataFileIds: number[] } = {
    template: { id: 0 },
    enterpriseDataFileIds: [],
  };

  timingList: { label: string; value: number }[] = [
    { label: '-----', value: null },
    { label: '3営業日前', value: -3 },
    { label: '2営業日前', value: -2 },
    { label: '1営業日前', value: -1 },
    { label: '当日', value: 0 },
    { label: '1営業日後', value: 1 },
    { label: '2営業日後', value: 2 },
    { label: '3営業日後', value: 3 },
  ];

  // 添付ファイルHTMLテンプレート内で利用する変数
  enterpriseFiles: { [key: number]: DataFile[] } = {};
  enterpriseFileIds: number[] = [];
  enterpriseIdForFile: number;
  enterpriseInfoById: { [key: number]: string } = {};

  private fieldSubscription: Subscription;

  // APIレスポンスのデフォルトを生成
  static getJobSettingMailDefault(): JobSettingMail {
    return {
      mailTemplateId: 0,
      enterpriseDataFileIds: [],
      settings: [...Array(10)].map((_, index) => {
        return {
          eventDateAt: null,
          eventDateTimeFrom: null,
          eventDateTimeTo: null,
          eventDateType: [],
          url: null,
          sendMailTiming: null,
        };
      }),
    };
  }

  ngOnInit(): void {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.init();
    });
  }

  private init(): void {
    // メールテンプレート選択、添付ファイル選択部分の dynacmic form
    this.headMeta = {
      groups: [
        {
          title: '開催日程別メール配信',
          class: 'form__group--green-title-border',
          rows: [
            {
              title: '使用するテンプレート',
              fields: [
                {
                  name: 'template', // [model]で指定したobjectが持っているキーを指定
                  type: 'autocomplete',
                  placeholder: 'テンプレート名',
                  labelField: 'name', // ストリームのnameプロパティの値を表示する設定
                  class: 'half',
                  options: [],
                  supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                    return this.mailApiService.getMailTemplateNames().pipe(
                      map((res: MailTemplateNameResponse[]) => {
                        const filtered = res.filter((r) => r.status === 1 && r.mailTemplateTypeId === 1);
                        return {
                          options: filtered,
                        };
                      }),
                    );
                  },
                },
              ],
            },
            (() => {
              const row = ({
                title: '添付ファイル',
                class: 'ou',
                fields: [
                  // 添付ファイルを選択するフィールド
                  {
                    type: 'label',
                    name: 'sendFile',
                    class: 'half',
                    linkTo: ['enterpriseDataFileIds'],
                    actions: [
                      {
                        title: 'データフォルダから選択',
                        type: 'RUNNABLE',
                        runnable: () => {
                          this.enterpriseFileIds = [...this.dynamicFormModel.enterpriseDataFileIds];
                          PopupControlComponent.instance.open({
                            content: this.fileSelectTemplate,
                            confirmText: '添付',
                            cancelText: 'キャンセル',
                            title: 'データフォルダ',
                            confirmCallback: () => {
                              this.dynamicFormModel.enterpriseDataFileIds = [...this.enterpriseFileIds];
                            },
                          });
                        },
                      },
                    ],
                  },
                  // 添付済みのファイルを画面に表示するフィールド
                  {
                    name: 'enterpriseDataFileIds', // model[name]が反映されるため、modelにも同じ名前のキーが必要
                    type: 'autocomplete',
                    labelField: [
                      {
                        name: 'name',
                        class: null,
                        action: null,
                      },
                    ],
                    valueField: 'id',
                    multi: true,
                    class: 'options-only half',
                    options: [],
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      if (!this.job) {
                        return;
                      }
                      const observal = this.fileApiService.getFiles('enterprise', this.job.enterpriseId).pipe(
                        map((res) => {
                          // 求職者への送信可能データファイルを取得
                          this.enterpriseFiles[this.job.enterpriseId] = res.filter((f) => f.studentSendFlag);
                          return { options: this.enterpriseFiles[this.job.enterpriseId] };
                        }),
                      );
                      return observal;
                    },
                  },
                ],
              } as unknown) as RowMeta;

              return row;
            })(),
          ],
        },
      ],
    };
  }

  ngOnChanges(changes): void {
    if (changes.job && this.job) {
      this.enterpriseIdForFile = this.job.enterpriseId;

      // forkJoin は rxjs での Promise.all
      forkJoin(
        // 求職者への送信可能データファイルを取得
        this.fileApiService.getFiles('enterprise', this.job.enterpriseId).pipe(
          map((res) => res),
          catchError((error) => {
            const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
              '開催日種別メール配信に添付可能なファイル情報の取得に失敗しました。',
              error.message,
            ]);
            this.dialogService.open(ConfirmDialogComponent, dialogConfig);
            return EMPTY;
          }),
        ),
        // APIから開催日程メール配信情報を取得
        this.jobApiService.getJobSettingMail(this.job.id).pipe(
          map((res) => res),
          catchError((error) => {
            // エラー時は初期値で埋めておく
            this.jobSettingMail = JobMailTabComponent.getJobSettingMailDefault();
            this.dynamicFormModel = {
              template: { id: 0 },
              enterpriseDataFileIds: [],
            };

            // エラー時のダイアログ表示
            const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
              '開催日種別メール配信情報の取得に失敗しました。',
              error.message,
            ]);
            this.dialogService.open(ConfirmDialogComponent, dialogConfig);
            return EMPTY;
          }),
        ),
      ).subscribe((response: [DataFile[], JobSettingMail]) => {
        // どちらも成功したとき

        // ファイル一覧の取得
        const data: DataFile[] = response[0];
        this.enterpriseFiles[this.job.enterpriseId] = data.filter((file: DataFile) => {
          return file.studentSendFlag;
        });

        // 開催日程別メール配信の取得
        this.jobSettingMail = response[1];

        // 設定枠に入れる値がなければ、デフォルト値を入れておく
        [...Array(10)].map((_, index) => {
          if (this.jobSettingMail.settings[index]) {
            // do nothing
          } else {
            this.jobSettingMail.settings[index] = JobMailTabComponent.getJobSettingMailDefault().settings[index];
          }
        });

        // dynacmicForm のメールテンプレートが求めている型に変換する
        this.dynamicFormModel = {
          template: { id: this.jobSettingMail.mailTemplateId },
          // JobSettingMail API側で設定されているファイルを求職者に送信可能なファイルのみに絞る処理
          enterpriseDataFileIds: this.enterpriseFiles[this.job.enterpriseId]
            .filter((file) => {
              return file.studentSendFlag && this.jobSettingMail.enterpriseDataFileIds.includes(file.id);
            })
            .map((file) => {
              return file.id;
            }),
        };
      });
    }
  }

  ngOnDestroy(): void {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  // settingオブジェクトのキーバリューから、url以外のいずれかが入力されている && いずれかが入力されていない場合 falseを出力
  isSettingValidated(setting: JobSettingMail['settings'][0]): boolean {
    if (
      setting.eventDateAt &&
      setting.eventDateTimeFrom &&
      setting.eventDateTimeTo &&
      setting.eventDateType.length &&
      setting.sendMailTiming != null // 0 と null/undefined を分けたいので、!=で比較
    ) {
      // すべて入力されている
      return true;
    } else if (
      !setting.eventDateAt &&
      !setting.eventDateTimeFrom &&
      !setting.eventDateTimeTo &&
      !setting.eventDateType.length &&
      setting.sendMailTiming == null //  0 と null/undefined を分けたいので、==で比較
    ) {
      // すべて入力されていない
      return true;
    } else {
      // いずれかが入力されている && いずれかが入力されていない
      return false;
    }
  }

  // 時間指定のfromとtoの整合性（fromがtoより大きくないか）を確認
  isEventDateTimeValidated(setting: JobSettingMail['settings'][0]): boolean {
    if (!setting.eventDateTimeFrom || !setting.eventDateTimeTo) {
      // どちらかが入力されていないければ、バリデーションを実施しない
      return true;
    }
    const date: Date = new Date();
    const eventDateTimeFromDate: Date = new Date(`${date.toDateString()} ${setting.eventDateTimeFrom}`);
    const eventDateTimeToDate: Date = new Date(`${date.toDateString()} ${setting.eventDateTimeTo}`);

    // どちらかが日付オブジェクトではなかった場合の確認
    if (Number.isNaN(eventDateTimeFromDate.getDate()) || Number.isNaN(eventDateTimeToDate.getDate())) {
      return false;
    }

    return eventDateTimeFromDate < eventDateTimeToDate;
  }

  // 設定をリセットする
  onResetSetting(index: number): void {
    this.jobSettingMail.settings[index] = JobMailTabComponent.getJobSettingMailDefault().settings[0];
  }

  // バリデーションを実施し、更新ボタンを押せるようにする
  get validity(): boolean {
    const validity = this.jobSettingMail.settings.reduce((previousValue, setting) => {
      return previousValue && this.isSettingValidated(setting) && this.isEventDateTimeValidated(setting);
    }, true);

    return validity;
  }

  save(): void {
    // dynamicformのモデルから、APIリクエスト用のモデルに受け渡し
    this.jobSettingMail.mailTemplateId = this.dynamicFormModel.template ? this.dynamicFormModel.template.id : null;
    this.jobSettingMail.enterpriseDataFileIds = this.dynamicFormModel.enterpriseDataFileIds;

    // putリクエスト用にオブジェクトを生成する。settingsは入力されているものしか送信したくないため。
    const putRequestObject: JobSettingMail = {
      mailTemplateId: this.dynamicFormModel.template ? this.dynamicFormModel.template.id : null,
      enterpriseDataFileIds: this.dynamicFormModel.enterpriseDataFileIds,
      // 入力されている、urlが入力されているsettingを抽出する
      settings: this.jobSettingMail.settings.filter((setting: JobSettingMail['settings'][0]) => {
        return !!setting.eventDateAt || !!setting.url;
      }),
    };

    this.jobApiService.updateJobSettingMail(this.job.id, putRequestObject).subscribe(
      (response) => {
        // 更新後に再読み込みする
        this.jobApiService.refreshEvent.emit();
      },
      (error: HttpErrorResponse) => {
        // bad requestは既存のダイアログを表示
        if (error.status === 400) {
          return;
        }
        // エラー時のダイアログ表示
        error.error.fields = error.error.fields || {};
        const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
          error.error.fields.status || '開催日種別メール配信情報の更新に失敗しました。',
          error.message,
        ]);
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
      },
    );
  }
}
