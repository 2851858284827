import { JobListComponent } from '@agent-ds/shared/components/job-list/job-list.component';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { JobListItem } from '@agent-ds/shared/interfaces';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { ExportApiService, JobApiService } from '@agent-ds/shared/services';
import { download, getFileNameFromContentDispositionHeader, getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobDetailPageComponent } from '../job-detail-page/job-detail-page.component';

@Component({
  selector: 'ag-job-list-page',
  templateUrl: './job-list-page.component.html',
  styleUrls: ['./job-list-page.component.scss'],
})
export class JobListPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild(JobListComponent, { static: true }) jobList: JobListComponent;
  @ViewChild('export', { static: false }) exportTemplate: TemplateRef<any>;
  protected readonly model = 'jobs';
  protected readonly detail = JobDetailPageComponent as any;
  protected readonly exportModel = ExportModel.job;

  listConfiguration: any = { body: { checkbox: true } };
  postingConfig = new JobPostingConfig();
  fileUrl: any;

  constructor(
    protected router: Router,
    protected location: Location,
    protected activeRoute: ActivatedRoute,
    protected exportService: ExportApiService,
    private service: JobApiService,
  ) {
    super(router, location, activeRoute, exportService);
  }

  showExport() {
    PopupControlComponent.instance.open({
      title: 'CSV出力',
      content: this.exportTemplate,
      confirmText: 'ダウンロード',
      confirmEnabled: () => this.exportTemplateId != null,
      confirmCallback: () => this.exportService.export(this.exportModel, this.exportTemplateId, this.searchObj),
      cancelText: '閉じる',
    });
  }

  getJobsAsPdf(jobs: JobListItem[]): void {
    this.service.getJobsAsPdf(jobs.map((job) => job.id)).subscribe((resp) => {
      download(resp.body, getFileNameFromContentDispositionHeader(resp));
    });
  }

  openMail(config: MailSendConfig): void {
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      jobs: this.jobList.table.checkedItems.map((item: JobListItem) => ({
        id: item.id,
        frontId: item.frontJobId,
        position: getValueFromObject(item.dynamicData, 'position'),
        enterpriseName: item.enterpriseName,
        enterpriseId: item.enterpriseId,
        frontEnterpriseId: item.frontEnterpriseId,
        enterpriseDepartmentId: item.enterpriseDepartmentId,
      })),
    });
    MailSendFlowComponent.instance.start();
  }
}
