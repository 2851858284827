import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ContactHistory, ContactHistoryAction, StudentDetail } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DynamicFieldService, MasterApiService, StudentApiService } from '@agent-ds/shared/services';
import { UserApiService } from '@agent-ds/shared/services/api/user-api.service';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Component, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';

enum SAVE_DIALOG_TYPE {
  CONTACT_HISTORY,
  STATUS_INFO,
}
@Component({
  selector: 'ag-student-detail-tab',
  templateUrl: './student-detail-tab.component.html',
  styleUrls: ['./student-detail-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentDetailTabComponent) }],
})
export class StudentDetailTabComponent extends Tab implements OnInit {
  @ViewChild('contactHistoryForm', { static: false }) contactHistoryFormTemplate: TemplateRef<any>;
  @Input() readonly: boolean;
  @Input() set student(student: StudentDetail) {
    this.innerStudent = student;
    if (this.innerStudent) {
      this.innerStudent.publishMypage = !!this.innerStudent.lastMypageNotificationEmailSentAt;
    }
    this.lastRegistrationStatus = getValueFromObject(student, 'dynamicData.registrationStatus');
  }
  get student(): StudentDetail {
    return this.innerStudent;
  }

  headMeta: FormMeta;
  leftMeta: FormMeta;
  rightMeta: FormMeta;
  bottomMeta: FormMeta;
  customMeta: FormMeta;

  private validityArray = [true, true, true, true, true];
  private innerStudent: StudentDetail;
  private lastRegistrationStatus: any;
  validity = true;

  validcontactHistoryForm = false;
  contactHistoryFormModel: ContactHistory = { message: '', userId: null, actionAt: new Date(), action: 1 };
  contactHistoryFormMetadata: FormMeta = { groups: [] };
  private readonly registrationStatusChangeMatches = [
    { from: '本登録', to: 'サービス終了', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: '本登録', to: '音信不通', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: '本登録', to: '紹介停止', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: 'サービス終了', to: '本登録', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: '音信不通', to: '本登録', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: '紹介停止', to: '本登録', dialog: SAVE_DIALOG_TYPE.CONTACT_HISTORY },
    { from: '本登録', to: 'サービス終了', interviewStatus: '面談済み', dialog: SAVE_DIALOG_TYPE.STATUS_INFO },
    { from: '音信不通', to: 'サービス終了', interviewStatus: '面談済み', dialog: SAVE_DIALOG_TYPE.STATUS_INFO },
    { from: '本登録', to: '決定', interviewStatus: '面談済み', dialog: SAVE_DIALOG_TYPE.STATUS_INFO },
    { from: '音信不通', to: '決定', interviewStatus: '面談済み', dialog: SAVE_DIALOG_TYPE.STATUS_INFO },
  ];

  constructor(
    private dynamicService: DynamicFieldService,
    private datePipe: SafeDatePipe,
    private userApiService: UserApiService,
    private studentService: StudentApiService,
    private masterApiService: MasterApiService,
    private authService: AuthService,
  ) {
    super();
  }

  private getUpdateUserNameById(id: number): Observable<string> {
    return this.userApiService.getAll().pipe(
      mergeMap((users) => {
        const user = users.find((u) => u.id === id);
        if (user) {
          return this.masterApiService.getTeams().pipe(
            map((teams) => {
              const team = teams.find((t) => t.id === user.teamId);
              if (team) {
                return user.name + ' ' + team.name;
              } else {
                return user.name;
              }
            }),
          );
        }
        return null;
      }),
    );
  }

  ngOnInit() {
    // tslint:disable-next-line:no-this-assignment
    const self = this;
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.headMeta = {
        disabled: this.readonly,
        groups: [
          {
            class: 'oneliner',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationStatus'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewStatus'), 'dynamicData'),
              {
                title: '更新者',
                fields: [
                  {
                    type: 'label',
                    name: 'updateUserId',
                    supplier: () => (this.student ? this.getUpdateUserNameById(this.student.updateUserId) : '-'),
                  },
                ],
              },
              {
                title: '登録日',
                fields: [
                  {
                    type: 'label',
                    name: 'registeredAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
              {
                title: '更新日',
                fields: [
                  {
                    type: 'label',
                    name: 'lastUpdatedAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
            ],
          },
          {
            class: 'oneliner continuation',
            rows: [
              {
                title: 'マイページ発行',
                fields: [
                  {
                    type: 'checkbox',
                    get disabled() {
                      return !!(self.student && self.student.lastMypageNotificationEmailSentAt);
                    },
                    name: 'publishMypage',
                    supplier: () => {
                      return this.student && this.student.publishMypage;
                    },
                  },
                ],
              },
            ],
          },
        ],
      };
      this.leftMeta = {
        disabled: this.readonly,
        groups: [
          {
            title: '氏名・生年月日',
            rows: [
              {
                title: '求職者氏名',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstName'))[0].fields,
                ],
                showRequired: true,
              },
              {
                title: 'フリガナ',
                fields: [
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticLastName'))[0].fields,
                  ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'phoneticFirstName'))[0].fields,
                ],
                showRequired: true,
              },
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'gender')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'birthday')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportYear')),
            ],
          },
          {
            title: '住所・住居',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'address')),
          },
          {
            class: 'mt-10',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationAddress')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'onVacationTel')),
            ],
          },
          {
            title: '職歴概要',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskTypeFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevelFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReasonFinal')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason07')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason10')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason01')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'approach03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskType03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'riskLevel03')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'declineReason03')),
            ],
          },
          {
            title: 'グローバル',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nationality')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'motherLanguage')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'japaneseLevel')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'abroadFee')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'jobExperience')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'visa')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'visaType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'visaExpiration')),
            ],
          },
        ],
      };
      this.rightMeta = {
        disabled: this.readonly,
        groups: [
          {
            title: '担当',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'supportBranch'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                {
                  label: '担当者',
                  fieldType: 'user',
                  fieldName: 'userId',
                },
                null,
                'three-quarter',
              ),
              ...this.dynamicService.getFormRows(
                {
                  label: '副担当者（最大5人）',
                  fieldType: 'multi-user',
                  fieldName: 'subUserIds',
                },
                null,
                'three-quarter',
              ),
            ],
          },
          {
            title: 'ステータス',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'rank'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mediumId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'acquisitionRoute'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusMailmag'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'plusServiceNg'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'nonRecruitmentIntroduction'), 'dynamicData'),
            ],
          },
          {
            title: '連絡先',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailMain'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'emailSub'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'mobileTel'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'houseTel'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'contactNotes'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'skypeId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'lineId'), 'dynamicData'),
            ],
          },
          {
            title: '登録・媒体',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRoute'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'firstRegistrationDate'), 'dynamicData'),
              (() => {
                const field = this.dynamicService.getDefinition('student', 'lastRegistrationRoute');
                return {
                  title: field ? field.label : '最終登録ルート',
                  fields: [
                    {
                      type: 'label',
                      name: 'dynamicData.lastRegistrationRoute',
                    },
                  ],
                };
              })() as RowMeta,
              (() => {
                const field = this.dynamicService.getDefinition('student', 'lastRegistrationDate');
                return {
                  title: field ? field.label : '最終エントリー日',
                  fields: [
                    {
                      type: 'label',
                      name: 'dynamicData.lastRegistrationDate',
                      supplier: (val) => this.datePipe.transform(val),
                    },
                  ],
                };
              })() as RowMeta,
              (() => {
                const field = this.dynamicService.getDefinition('student', 'registrationRouteHistory');
                return {
                  title: field ? field.label : '登録ルート履歴',
                  fields: [
                    {
                      type: 'label',
                      name: 'dynamicData.registrationRouteHistory',
                    },
                  ],
                };
              })() as RowMeta,
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRouteDetail'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewType'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewSettingDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'sortingDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'counselingReservationTime'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'serviceinDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarReservationTime'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'inflowClassification'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'pptSms'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationDate'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationType'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarShortCounseling'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationDate'),
                'dynamicData',
              ),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'oncampusSeminarParticipationUniversity'),
                'dynamicData',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroduction'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionId'), 'dynamicData'),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'friendIntroductionName'), 'dynamicData'),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'friendIntroductionEntryDate'),
                'dynamicData',
              ),
              ...this.dynamicService.getFormRows(
                this.dynamicService.getDefinition('student', 'friendIntroductionEffectTarget'),
                'dynamicData',
              ),
            ],
          },
        ],
      };
      this.bottomMeta = {
        disabled: this.readonly,
        groups: [
          {
            title: '備考',
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remarks')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'telephoneInterviewClassification')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'implementationCategory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'snsType')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'chanceToLearnAboutCompany')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'seminarParticipationHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'hookEntryHistory')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindThreeDaysAgo')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'remindDayBefore')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'officialRegistrationPatrol')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'officialRegistrationPatrolDesiredTime')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'counselingSettings')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'newCounselingSeminar')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'workingAreaRatioMetropolitanArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'workingAreaRatioKansaiArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'workingAreaRatioChubuArea')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'desiredCourse')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'aspiringIndustryBeforeCow')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'aspiringJobBeforeCow')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'desiredTimeToEndJobHunting')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'unofficialOffer')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'japaneseLanguageProficiencyTest')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'preCowChiefComplaint')),
            ],
          },
        ],
      };
      const customs = this.dynamicService.getDefinitions('student').filter((def) => def.isCustomField);
      if (customs.length) {
        this.customMeta = {
          disabled: this.readonly,
          groups: [
            {
              title: 'カスタムフィールド',
              rows: (customs.map((c) => this.dynamicService.getFormRows(c)).flatten() as unknown) as RowMeta[],
            },
          ],
        };
      }

      const contactHistoryActions = Object.keys(ContactHistoryAction)
        .filter((key: any) => isNaN(key))
        .map((key: string) => ({
          label: key,
          value: ContactHistoryAction[key],
        }));
      this.contactHistoryFormMetadata = {
        groups: [
          {
            class: 'ou',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows(
                  {
                    fieldName: 'actionAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '対応日',
                    validationStyle: { required: true },
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
              {
                title: 'アクション',
                fields: [
                  {
                    type: 'dropdown',
                    name: 'action',
                    class: 'half tall',
                    options: contactHistoryActions,
                    labelField: 'label',
                    valueField: 'value',
                  },
                ],
              },
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'userId',
                  fieldType: 'user',
                  label: '担当',
                  validationStyle: { required: true },
                },
                null,
                'three-quarter',
              ),
              {
                fields: [{ type: 'hr', name: 'hr' }],
              },
              (() => {
                const row = this.dynamicService.getFormRows(
                  {
                    fieldName: 'nextContactAt',
                    fieldType: 'date',
                    displayType: 'date+today+time',
                    label: '次回コンタクト日時',
                  },
                  null,
                  'tall',
                )[0];
                row.fields[0].class = 'half ' + row.fields[0].class;
                return row;
              })(),
            ],
          },
        ],
      };
      this.contactHistoryFormModel = { message: '', userId: this.authService.loginUser.id, actionAt: new Date(), action: 5 };
    });
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  save(): void {
    const currentRegistrationStatus = getValueFromObject(this.student, 'dynamicData.registrationStatus');
    const currentInterviewStatus = getValueFromObject(this.student, 'dynamicData.interviewStatus');
    const isContactHistoryDialogNeeded = this.registrationStatusChangeMatches.some(
      (match) =>
        match.dialog === SAVE_DIALOG_TYPE.CONTACT_HISTORY &&
        match.from === this.lastRegistrationStatus &&
        match.to === currentRegistrationStatus,
    );
    const isStatusDialogNeeded = this.registrationStatusChangeMatches.some(
      (match) =>
        match.dialog === SAVE_DIALOG_TYPE.STATUS_INFO &&
        match.from === this.lastRegistrationStatus &&
        match.to === currentRegistrationStatus &&
        match.interviewStatus &&
        match.interviewStatus === currentInterviewStatus,
    );
    if (isStatusDialogNeeded) {
      this.openStatusDialog(currentRegistrationStatus, isContactHistoryDialogNeeded);
    } else if (isContactHistoryDialogNeeded) {
      this.openContactHistoryDialog(currentRegistrationStatus);
    } else {
      this.studentService.update(this.student.id, this.student).subscribe(() => {
        this.studentService.refreshEvent.emit();
        this.lastRegistrationStatus = currentRegistrationStatus;
      });
    }
  }

  private openContactHistoryDialog(currentRegistrationStatus: string): void {
    this.contactHistoryFormModel = { message: '', userId: this.authService.loginUser.id, actionAt: new Date(), action: 5 };
    PopupControlComponent.instance.open({
      title: '対応履歴の登録',
      content: this.contactHistoryFormTemplate,
      confirmText: '登録',
      confirmEnabled: () => this.validcontactHistoryForm,
      confirmCallback: () => {
        this.validcontactHistoryForm = false;
        const studentUpdateRequest = this.studentService.update(this.student.id, this.student);
        const studentAddContactRequest = this.studentService.addContact(this.student.id, this.contactHistoryFormModel);
        return studentAddContactRequest.pipe(
          concatMap(() =>
            studentUpdateRequest.pipe(
              tap(() => {
                this.studentService.refreshEvent.emit();
                this.lastRegistrationStatus = currentRegistrationStatus;
              }),
            ),
          ),
        );
      },
      cancelText: 'キャンセル',
      cancelCallback: () => {
        PopupControlComponent.subInstance.open({
          content: 'データの更新はキャンセルされました。',
          title: null,
          confirmText: 'OK',
          confirmCallback: () => false,
        });
      },
    });
  }

  private openStatusDialog(currentRegistrationStatus: string, openContactHistoryDialogAfterOk = false): void {
    PopupControlComponent.instance.open({
      title: '登録ステータスの変更',
      content: `登録ステータスを「${currentRegistrationStatus}」に変更し、求職者にメールが配信されます。`,
      confirmText: 'OK',
      cancelText: 'キャンセル',
      confirmCallback: () =>
        openContactHistoryDialogAfterOk
          ? this.openContactHistoryDialog(currentRegistrationStatus)
          : this.studentService.update(this.student.id, this.student).pipe(
              tap(() => {
                this.studentService.refreshEvent.emit();
                this.lastRegistrationStatus = currentRegistrationStatus;
              }),
            ),
    });
  }
}
