import { SalesSearchRequest } from '@agent-ds/sales/dto/SalesSearchRequest';
import { Tab } from '@agent-ds/shared/models';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-job-sales-tab',
  templateUrl: './job-sales-tab.component.html',
  styleUrls: ['./job-sales-tab.component.scss'],
})
export class JobSalesTabComponent extends Tab implements OnChanges {
  @Input() jobId: number;
  requestObject: SalesSearchRequest;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.jobId != null && changes['jobId'].currentValue !== changes['jobId'].previousValue) {
      this.requestObject = {
        jobId: [this.jobId],
      };
    }
  }
}
