import { CompanySearchRequest } from '@agent-ds/company/dto/CompanySearchRequest';
import {
  CompanyDetail,
  CompanyList,
  ContactHistory,
  Contract,
  EnterpriseDepthContacts,
  EnterpriseSuggestResponse,
  ImportCompleted,
  SituationMemo,
} from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { REPORT_SUCCESS_HEADER } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getBllingAddressCode(billignBikeCb?: string): Observable<string> {
    // const ans = this.http.get<string>('/api/enterprise/search/billing-address-code?bllingAddressCode='+bllingAddressCode);
    const ans = this.http.get<string>('/api/enterprise/search/billing-address-code', { params: { billignBikeCb: billignBikeCb } });
    return ans;
  }

  getList(search?: CompanySearchRequest): Observable<CompanyList> {
    return this.http.get<CompanyList>('/api/enterprise/search', { params: (search ? search : {}) as any });
  }

  getSuggestions(keyword: string, ids?: number[]): Observable<EnterpriseSuggestResponse[]> {
    return keyword || (ids && ids.length)
      ? this.http.get<EnterpriseSuggestResponse[]>(`/api/enterprise/suggest`, {
          params: { name: keyword || undefined, id: ids } as any,
        })
      : of([]);
  }

  getDetail(id: number): Observable<CompanyDetail> {
    return this.http.get<CompanyDetail>('/api/enterprise/' + id);
  }

  create(companyData: object): Observable<any> {
    return this.http.post('/api/enterprise', companyData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  update(id: number, companyData: object): Observable<any> {
    return this.http.put('/api/enterprise/' + id, companyData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  getDepartments(enterpriseId: number): Observable<EnterpriseDepthContacts> {
    return this.http.get<EnterpriseDepthContacts>(`/api/enterprise/${enterpriseId}/departments`);
  }

  addDepartment(enterpriseId: number, request: { dynamicData: any; users: { type: number; id: number }[] }): Observable<any> {
    return this.http.post(`/api/enterprise/${enterpriseId}/department`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  updateDepartment(
    enterpriseId: number,
    id: number,
    request: { dynamicData: any; users: { type: number; id: number }[] },
  ): Observable<any> {
    return this.http.put(`/api/enterprise/${enterpriseId}/department/${id}`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  addBillingAddress(enterpriseId: number, request: { dynamicData: any; userId: number }): Observable<any> {
    return this.http.post(`/api/enterprise/${enterpriseId}/billing-address`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  updateBillingAddress(enterpriseId: number, id: number, request: { dynamicData: any; userId: number }): Observable<any> {
    return this.http.put(`/api/enterprise/${enterpriseId}/billing-address/${id}`, request, {
      headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' },
    });
  }

  getContracts(id: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`/api/enterprise/${id}/contracts`);
  }

  addContract(enterpriseId: number, request: { dynamicData: any; teamId: number }): Observable<any> {
    return this.http.post(`/api/enterprise/${enterpriseId}/contract`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  updateContract(enterpriseId: number, id: number, request: { dynamicData: any; teamId: number }): Observable<any> {
    return this.http.put(`/api/enterprise/${enterpriseId}/contract/${id}`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  getContacts(id: number, actionId?: number): Observable<ContactHistory[]> {
    return this.http.get<ContactHistory[]>(
      `/api/enterprise/${id}/contact-histories`,
      actionId ? { params: { action: `${actionId}` } } : undefined,
    );
  }

  addContact(id: number, request: ContactHistory): Observable<any> {
    return this.http.post(`/api/enterprise/${id}/contact-history`, request);
  }

  updateContact(id: number, request: ContactHistory): Observable<any> {
    return this.http.put(`/api/enterprise/${id}/contact-history/${request.id}`, request);
  }

  deleteContact(id: number, contactId: number): Observable<any> {
    return this.http.delete(`/api/enterprise/${id}/contact-history/${contactId}`);
  }

  getMemos(id: number): Observable<SituationMemo[]> {
    return this.http.get<SituationMemo[]>(`/api/enterprise/${id}/inner-info`);
  }

  addMemo(id: number, info: SituationMemo): Observable<any> {
    return this.http.post(`/api/enterprise/${id}/inner-info`, info);
  }

  updateMemo(id: number, info: SituationMemo): Observable<any> {
    return this.http.put(`/api/enterprise/${id}/inner-info/${info.id}`, info);
  }

  deleteMemo(id: number, infoId: number): Observable<any> {
    return this.http.delete(`/api/enterprise/${id}/inner-info/${infoId}`);
  }

  downloadImportExampleFile(): void {
    this.http.get('/api/enterprise/csv/header', { responseType: 'blob', observe: 'response' }).subscribe((res) => downloadResponse(res));
  }

  uploadCompanyImportFile(file: File): Observable<ImportCompleted> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ImportCompleted>('/api/enterprise/csv/import', formData);
  }

  downloadEnterpriseArchiveFile(id: number, params: any): void {
    this.http
      .get(`/api/mail/enterprise/${id}/archived-files`, { params: params, responseType: 'blob', observe: 'response' })
      .subscribe((res) => downloadResponse(res));
  }
}
