import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';
import { SalesDetailPageComponent } from './sales-detail-page/sales-detail-page.component';
import { SalesListPageComponent } from './sales-list-page/sales-list-page.component';
import { SalesReportPageComponent } from './sales-report-page/sales-report-page.component';

/**
 * Route定数
 */
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'list', component: SalesListPageComponent },
      { path: 'search', component: SalesListPageComponent },
      { path: 'report', component: SalesReportPageComponent },
      { path: ':id', component: SalesDetailPageComponent },
    ],
  },
];

/**
 * 契約ルーティング
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesRoutingModule {}
