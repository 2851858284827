import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { StudentImportDiff } from '@agent-ds/shared/interfaces';
import { FieldMeta, FormMeta, RowMeta, strip } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { DynamicFieldService, MasterApiService, StudentApiService } from '@agent-ds/shared/services';
import { deepClone, deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { forkJoin, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'ag-student-import-compare',
  templateUrl: './student-import-compare.component.html',
  styleUrls: ['./student-import-compare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentImportCompareComponent implements OnChanges {
  @Input() studentImportErrorId: number | null = null;
  @Input() duplicatedStudentId: number | null = null;
  @Input() errorType: 3 | 4 | null = null;
  @Output() closeWithRefresh: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('diffCsvForm', { static: false }) diffCsvForm: DynamicFormComponent;
  @ViewChild('diffDbForm', { static: false }) diffDbForm: DynamicFormComponent;
  @ViewChild('basicCsvForm', { static: false }) basicCsvForm: DynamicFormComponent;
  @ViewChild('basicDbForm', { static: false }) basicDbForm: DynamicFormComponent;
  compareData: StudentImportDiff | null = null;
  basicMetaGroups: {
    firstRegistrationDate: { dbMeta: FormMeta; csvMeta: FormMeta };
    registrationRoute: { dbMeta: FormMeta; csvMeta: FormMeta };
    registrationRouteDetail: { dbMeta: FormMeta; csvMeta: FormMeta };
    registrationRouteHistory: { dbMeta: FormMeta; csvMeta: FormMeta };
    registrationStatus: { dbMeta: FormMeta; csvMeta: FormMeta };
    name: { dbMeta: FormMeta; csvMeta: FormMeta };
    phoneticName: { dbMeta: FormMeta; csvMeta: FormMeta };
    gender: { dbMeta: FormMeta; csvMeta: FormMeta };
    birthday: { dbMeta: FormMeta; csvMeta: FormMeta };
    emailMain: { dbMeta: FormMeta; csvMeta: FormMeta };
    emailSub: { dbMeta: FormMeta; csvMeta: FormMeta };
    mobileTel: { dbMeta: FormMeta; csvMeta: FormMeta };
    houseTel: { dbMeta: FormMeta; csvMeta: FormMeta };
    contactNotes: { dbMeta: FormMeta; csvMeta: FormMeta };
    studentUserId: { dbMeta: FormMeta; csvMeta: FormMeta };
  } | null = null;
  differenceMetaGroups: any | null = null;
  isInitInProgress: boolean;

  validity = true;
  private validityArray = [true, true, true, true];

  constructor(
    private studentApiService: StudentApiService,
    private dynamicFieldService: DynamicFieldService,
    private masterApiService: MasterApiService,
    private chRef: ChangeDetectorRef,
    private datePipe: SafeDatePipe,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['studentImportErrorId'] && changes['studentImportErrorId'].previousValue !== changes['studentImportErrorId'].currentValue) ||
      (changes['duplicatedStudentId'] && changes['duplicatedStudentId'].previousValue !== changes['duplicatedStudentId'].currentValue)
    ) {
      this.getCompareData();
    }
  }

  asIsOrder(a: any, b: any): number {
    return 1;
  }

  update(): void {
    if (!this.compareData || !this.compareData.csvStudent || !this.compareData.csvStudent.dynamicData) {
      return;
    }
    const studentUpdateModel = deepClone(this.compareData.dbStudent);

    // Commented out per request https://github.com/asial/mach-frontend/issues/1416
    //
    // if ((this.errorType === 3 && this.compareData.duplicateCount >= 3) || this.errorType === 4) {
    //   Object.keys(this.compareData.csvStudent.dynamicData).forEach((key) => {
    //     if (
    //       this.compareData.csvStudent.dynamicData[key] &&
    //       this.compareData.csvStudent.dynamicData[key] instanceof Object &&
    //       Object.keys(this.compareData.csvStudent.dynamicData[key]).length
    //     ) {
    //       if (studentUpdateModel.dynamicData[key] == null) {
    //         studentUpdateModel.dynamicData[key] = {};
    //       }
    //       Object.keys(this.compareData.csvStudent.dynamicData[key]).forEach((fieldKey) => {
    //         if (!studentUpdateModel.dynamicData[key][fieldKey] && this.compareData.csvStudent.dynamicData[key][fieldKey]) {
    //           studentUpdateModel.dynamicData[key][fieldKey] = this.compareData.csvStudent.dynamicData[key][fieldKey];
    //         }
    //       });
    //     } else if (!studentUpdateModel.dynamicData[key] && this.compareData.csvStudent.dynamicData[key]) {
    //       studentUpdateModel.dynamicData[key] = this.compareData.csvStudent.dynamicData[key];
    //     }
    //   });
    // }

    if (studentUpdateModel.dynamicData.firstRegistrationDate) {
      studentUpdateModel.dynamicData.lastRegistrationDate = this.compareData.csvStudent.dynamicData.firstRegistrationDate;
      delete studentUpdateModel.dynamicData.firstRegistrationDate;
    }
    if (studentUpdateModel.dynamicData.registrationRoute) {
      studentUpdateModel.dynamicData.lastRegistrationRoute = this.compareData.csvStudent.dynamicData.registrationRoute;
      delete studentUpdateModel.dynamicData.registrationRoute;
    }

    this.studentApiService
      .updateImportError(this.studentImportErrorId, this.duplicatedStudentId, {
        dynamicData: studentUpdateModel.dynamicData,
        userId: studentUpdateModel.dynamicData.studentUserId,
      })
      .subscribe(() => {
        this.compareData = null;
        this.closeWithRefresh.emit();
      });
  }

  delete() {
    PopupControlComponent.instance.open({
      title: '削除',
      cancelText: 'キャンセル',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: 'OK',
      confirmCallback: () => {
        this.studentApiService.deleteImportError([this.studentImportErrorId]).subscribe(() => {
          this.compareData = null;
          this.closeWithRefresh.emit();
        });
      },
    });
  }

  onModelChange(metaGroups: object, metaKey: string): void {
    if (!this.isInitInProgress) {
      this.changeFieldDifferenceClass(metaGroups, metaKey);
    }
  }

  copyRow(metaGroups: object, fieldName: string): void {
    if (fieldName === 'name') {
      this.compareData.dbStudent.dynamicData.lastName = this.compareData.csvStudent.dynamicData.lastName;
      this.compareData.dbStudent.dynamicData.firstName = this.compareData.csvStudent.dynamicData.firstName;
    } else if (fieldName === 'phoneticName') {
      this.compareData.dbStudent.dynamicData.phoneticLastName = this.compareData.csvStudent.dynamicData.phoneticLastName;
      this.compareData.dbStudent.dynamicData.phoneticFirstName = this.compareData.csvStudent.dynamicData.phoneticFirstName;
    } else if (
      typeOf(this.compareData.csvStudent.dynamicData[fieldName]) === 'object' &&
      !Array.isArray(this.compareData.csvStudent.dynamicData[fieldName])
    ) {
      this.compareData.dbStudent.dynamicData[fieldName] = { ...this.compareData.csvStudent.dynamicData[fieldName] };
    } else {
      this.compareData.dbStudent.dynamicData[fieldName] = this.compareData.csvStudent.dynamicData[fieldName];
    }

    if (!metaGroups) {
      return;
    }
    const modelKeys = [];
    Object.keys(metaGroups).forEach((metaKey) => {
      if (metaKey === fieldName && metaGroups[metaKey].csvMeta) {
        metaGroups[metaKey].csvMeta.groups[0].rows.forEach((row: RowMeta) => {
          row.fields.forEach((field: FieldMeta) => {
            modelKeys.push(field.name);
          });
        });
      }
    });
    modelKeys.forEach((modelKey) => this.changeFieldDifferenceClass(metaGroups, modelKey));
  }

  private getCompareData(): void {
    this.compareData = null;
    this.dynamicFieldService.fieldUpdateEvent
      .pipe(concatMap(() => this.studentApiService.getImportErrorDiff(this.studentImportErrorId, this.duplicatedStudentId)))
      .subscribe((compareData: StudentImportDiff) => {
        this.isInitInProgress = true;
        this.compareData = compareData;
        this.compareData.dbStudent.dynamicData = {
          ...this.compareData.dbStudent.dynamicData,
          studentUserId: this.compareData.dbStudentUserId,
        };
        this.compareData.csvStudent.dynamicData = {
          ...this.compareData.csvStudent.dynamicData,
          studentUserId: this.compareData.csvStudentUserId,
        };
        this.initBasicForm();
        this.initDiffForm();

        this.generateSubTitles(this.basicMetaGroups);
        this.generateSubTitles(this.differenceMetaGroups);

        this.chRef.detectChanges();

        forkJoin(this.masterApiService.getSchools(), this.masterApiService.getLanguageCerts(), this.masterApiService.getCertificates())
          .pipe(concatMap(() => timer(500)))
          .subscribe(() => (this.isInitInProgress = false));
      });
  }

  private initBasicForm(): void {
    this.basicMetaGroups = {
      firstRegistrationDate: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: [
                (() => {
                  const row = this.dynamicFieldService.getFormRows(
                    this.dynamicFieldService.getDefinition('student', 'firstRegistrationDate'),
                  )[0];
                  row.fields[0].type = 'label';
                  row.fields[0].supplier = () =>
                    this.compareData
                      ? this.datePipe.transform(this.compareData.dbStudent.dynamicData['firstRegistrationDate'], 'yyyy/MM/dd')
                      : '';
                  return row;
                })(),
              ],
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: [
                (() => {
                  const row = this.dynamicFieldService.getFormRows(
                    this.dynamicFieldService.getDefinition('student', 'firstRegistrationDate'),
                  )[0];
                  row.fields[0].type = 'label';
                  row.fields[0].supplier = () =>
                    this.compareData
                      ? this.datePipe.transform(this.compareData.csvStudent.dynamicData['firstRegistrationDate'], 'yyyy/MM/dd')
                      : '';
                  return row;
                })(),
              ],
            },
          ],
        },
      },
      registrationRoute: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: [
                (() => {
                  const row = this.dynamicFieldService.getFormRows(
                    this.dynamicFieldService.getDefinition('student', 'registrationRoute'),
                  )[0];
                  row.fields[0].type = 'label';
                  return row;
                })(),
              ],
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: [
                (() => {
                  const row = this.dynamicFieldService.getFormRows(
                    this.dynamicFieldService.getDefinition('student', 'registrationRoute'),
                  )[0];
                  row.fields[0].type = 'label';
                  return row;
                })(),
              ],
            },
          ],
        },
      },
      registrationRouteDetail: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteDetail')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteDetail')),
            },
          ],
        },
      },
      registrationRouteHistory: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteHistory')),
            },
          ],
        },
        csvMeta: null,
      },
      registrationStatus: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationStatus')),
            },
          ],
        },
        csvMeta: null,
      },
      name: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: [
                {
                  title: '求職者氏名',
                  showRequired: true,
                  fields: [{ name: 'lastName', type: 'text' }, { name: 'firstName', type: 'text' }],
                },
              ],
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: [{ fields: [{ name: 'lastName', type: 'text' }, { name: 'firstName', type: 'text' }] }],
            },
          ],
        },
      },
      phoneticName: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: [
                {
                  title: 'フリガナ',
                  showRequired: true,
                  fields: [{ name: 'phoneticLastName', type: 'text' }, { name: 'phoneticFirstName', type: 'text' }],
                },
              ],
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: [
                { showRequired: true, fields: [{ name: 'phoneticLastName', type: 'text' }, { name: 'phoneticFirstName', type: 'text' }] },
              ],
            },
          ],
        },
      },
      gender: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'gender')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'gender')),
            },
          ],
        },
      },
      birthday: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'birthday')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'birthday')),
            },
          ],
        },
      },
      emailMain: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailMain')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailMain')),
            },
          ],
        },
      },
      emailSub: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailSub')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailSub')),
            },
          ],
        },
      },
      mobileTel: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'mobileTel')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'mobileTel')),
            },
          ],
        },
      },
      houseTel: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'houseTel')),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'houseTel')),
            },
          ],
        },
      },
      contactNotes: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'contactNotes')),
            },
          ],
        },
        csvMeta: null,
      },
      studentUserId: {
        dbMeta: {
          groups: [
            {
              class: 'title-nowrap no-border-around title-w225',
              rows: this.dynamicFieldService.getFormRows({
                label: '担当者',
                fieldType: 'user',
                fieldName: 'studentUserId',
              }),
            },
          ],
        },
        csvMeta: {
          groups: [
            {
              class: 'title-nowrap no-border no-title-column',
              rows: this.dynamicFieldService.getFormRows({
                label: '担当者',
                fieldType: 'user',
                fieldName: 'studentUserId',
              }),
            },
          ],
        },
      },
    };
    this.basicMetaGroups.registrationRouteHistory.dbMeta.groups.forEach((group) =>
      group.rows.forEach((row) => row.fields.forEach((field) => (field.disabled = true))),
    );

    this.addFixHeightToTextAreaFields(this.basicMetaGroups.registrationRouteDetail.dbMeta.groups);
    this.addFixHeightToTextAreaFields(this.basicMetaGroups.registrationRouteDetail.csvMeta.groups);
    this.addFixHeightToTextAreaFields(this.basicMetaGroups.contactNotes.dbMeta.groups);

    this.generateFieldDifferenceClass(this.basicMetaGroups);

    this.finishMetaFormat(this.basicMetaGroups, true);
  }

  private generateFieldDifferenceClass(metaGroups: object): void {
    if (!metaGroups) {
      return;
    }
    const modelKeys = [];
    Object.keys(metaGroups).forEach((metaKey) => {
      if (metaGroups[metaKey].csvMeta) {
        metaGroups[metaKey].csvMeta.groups[0].rows.forEach((row: RowMeta) => {
          row.fields.forEach((field: FieldMeta) => {
            modelKeys.push(field.name);
          });
        });
      }
    });
    modelKeys.forEach((modelKey) => this.changeFieldDifferenceClass(metaGroups, modelKey, metaGroups === this.differenceMetaGroups));
  }

  private changeFieldDifferenceClass(metaGroups: object, changedModelFieldKey: string, changeHidden?: boolean): void {
    const modelKey = changedModelFieldKey.includes('.') ? changedModelFieldKey.split('.')[0] : changedModelFieldKey;
    const fieldKey = changedModelFieldKey.includes('.') ? changedModelFieldKey.split('.')[1] : null;
    let metaKey;

    switch (modelKey) {
      case 'lastName':
        metaKey = 'name';
        break;
      case 'firstName':
        metaKey = 'name';
        break;
      case 'phoneticLastName':
        metaKey = 'phoneticName';
        break;
      case 'phoneticFirstName':
        metaKey = 'phoneticName';
        break;
      default:
        metaKey = modelKey;
        break;
    }

    let dbValue =
      modelKey === 'firstRegistrationDate'
        ? new Date(this.compareData.dbStudent.dynamicData['firstRegistrationDate']).getTime()
        : this.compareData.dbStudent.dynamicData[modelKey];
    let csvValue =
      modelKey === 'firstRegistrationDate'
        ? new Date(this.compareData.csvStudent.dynamicData['firstRegistrationDate']).getTime()
        : this.compareData.csvStudent.dynamicData[modelKey];
    const value = dbValue || csvValue;

    if (value !== undefined) {
      if (value instanceof Object) {
        dbValue = { ...dbValue };
        csvValue = { ...csvValue };
        let dbVal = fieldKey ? dbValue[fieldKey] : dbValue;
        let csvVal = fieldKey ? csvValue[fieldKey] : csvValue;
        if (dbVal) {
          dbVal = new Date(dbVal).isValid() ? new Date(dbVal).getTime() : dbVal;
        }
        if (csvVal) {
          csvVal = new Date(csvVal).isValid() ? new Date(csvVal).getTime() : csvVal;
        }

        if (['schoolCode', 'subDepartmentCode', 'departmentCode'].includes(fieldKey)) {
          switch (fieldKey) {
            case 'schoolCode':
              dbVal = dbValue['schoolName'];
              csvVal = csvValue['schoolName'];
              break;
            case 'subDepartmentCode':
              dbVal = dbValue['subDepartmentName'];
              csvVal = csvValue['subDepartmentName'];
              break;
            case 'departmentCode':
              dbVal = dbValue['departmentName'];
              csvVal = csvValue['departmentName'];
              break;
            default:
              break;
          }
        }
        if ((dbVal && csvVal && !deepCompare(dbVal, csvVal)) || (!dbVal && csvVal) || (!csvVal && dbVal)) {
          this.objectFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.addFieldClass, changeHidden);
        } else {
          this.objectFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.removeFieldClass, changeHidden);
        }
        this.detect();
      } else if (value) {
        if (dbValue) {
          dbValue = new Date(dbValue).isValid() ? new Date(dbValue).getTime() : dbValue;
        }
        if (csvValue) {
          csvValue = new Date(csvValue).isValid() ? new Date(csvValue).getTime() : csvValue;
        }
        if ((dbValue && csvValue && !deepCompare(dbValue, csvValue)) || (!dbValue && csvValue) || (dbValue && !csvValue)) {
          this.simpleFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.addFieldClass, changeHidden);
        } else {
          this.simpleFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.removeFieldClass, changeHidden);
        }
        this.detect();
      }
    }
  }

  private detect(): void {
    if (this.basicCsvForm) {
      Object.keys(this.basicMetaGroups).forEach(
        (metaKey) => (this.basicMetaGroups[metaKey].csvMeta = { ...this.basicMetaGroups[metaKey].csvMeta }),
      );
      this.basicCsvForm.detect();
    }
    if (this.basicDbForm) {
      Object.keys(this.basicMetaGroups).forEach(
        (metaKey) => (this.basicMetaGroups[metaKey].dbMeta = { ...this.basicMetaGroups[metaKey].dbMeta }),
      );
      this.basicDbForm.detect();
    }
    if (this.diffCsvForm) {
      Object.keys(this.differenceMetaGroups).forEach(
        (metaKey) => (this.differenceMetaGroups[metaKey].csvMeta = { ...this.differenceMetaGroups[metaKey].csvMeta }),
      );
      this.diffCsvForm.detect();
    }
    if (this.diffDbForm) {
      Object.keys(this.differenceMetaGroups).forEach(
        (metaKey) => (this.differenceMetaGroups[metaKey].dbMeta = { ...this.differenceMetaGroups[metaKey].dbMeta }),
      );
      this.diffDbForm.detect();
    }
  }

  private addFieldClass(field: FieldMeta, changeHidden?: boolean): void {
    field.class = !field.class ? 'red-border' : field.class.includes('red-border') ? field.class : `${field.class} red-border`;
    if (changeHidden) {
      field.hidden = false;
    }
  }

  private removeFieldClass(field: FieldMeta, changeHidden?: boolean): void {
    field.class =
      field.class && field.class.includes('red-border')
        ? field.class
            .split('red-border')
            .map((v) => v.trim())
            .join('')
        : field.class;
    if (changeHidden) {
      field.hidden = true;
    }
  }

  private objectFieldClassMapper(
    metaGroups: object,
    metaKey: string,
    modelKey: string,
    fieldKey: string,
    fieldOperation: (field: FieldMeta, changeHidden?: boolean) => void,
    changeHidden?: boolean,
  ): void {
    if (!metaGroups || !metaGroups[metaKey]) {
      return;
    }
    Object.keys(metaGroups[metaKey]).forEach((metaGroup) => {
      metaGroups[metaKey][metaGroup].groups[0].rows.forEach((row: RowMeta) => {
        row.fields.forEach((field: FieldMeta) => {
          if (strip(field.name) === (fieldKey ? `${modelKey}.${fieldKey}` : modelKey)) {
            fieldOperation(field, changeHidden);
          }
        });
      });
    });
  }

  private simpleFieldClassMapper(
    metaGroups: object,
    metaKey: string,
    modelKey: string,
    fieldKey: string,
    fieldOperation: (field: FieldMeta, changeHidden?: boolean) => void,
    changeHidden?: boolean,
  ): void {
    if (metaKey !== 'firstRegistrationDate' && !metaGroups[metaKey]) {
      return;
    }

    if (!metaGroups || !metaGroups[metaKey]) {
      return;
    }
    Object.keys(metaGroups[metaKey]).forEach((metaGroup) => {
      const field = metaGroups[metaKey][metaGroup].groups[0].rows[0].fields.find((fieldEl) => {
        const fieldDef = this.dynamicFieldService.getDefinition('student', modelKey);
        if (fieldDef && fieldDef.fieldType === 'date' && fieldDef.displayType.includes('time')) {
          return fieldEl.name === `${modelKey}.${fieldKey}`;
        }
        return fieldEl.name === modelKey || (metaKey === 'firstRegistrationDate' && fieldEl.name === 'firstRegistrationDate');
      });
      if (field) {
        fieldOperation(field, changeHidden);
      }
      metaGroups[metaKey][metaGroup].groups[0].rows[0].hidden =
        metaGroups[metaKey][metaGroup].groups[0].rows[0].fields.find((f) => !field.hidden) == null;
    });
  }

  private initDiffForm(): void {
    if (
      !this.compareData ||
      !this.compareData.dbStudent ||
      !this.compareData.dbStudent.dynamicData ||
      !this.compareData.csvStudent ||
      !this.compareData.csvStudent.dynamicData
    ) {
      return;
    }
    Object.keys(this.compareData.csvStudent.dynamicData)
      .sort((a, b) => a.localeCompare(b))
      .forEach((key) => {
        if (
          !['firstRegistrationDate', 'registrationRoute', 'lastRegistrationDate', 'lastRegistrationRoute'].includes(key) &&
          ((this.compareData.csvStudent.dynamicData[key] &&
            this.compareData.dbStudent.dynamicData[key] &&
            this.compareData.dbStudent.dynamicData[key] !== this.compareData.csvStudent.dynamicData[key]) ||
            (!this.compareData.csvStudent.dynamicData[key] && this.compareData.dbStudent.dynamicData[key]) ||
            (this.compareData.csvStudent.dynamicData[key] && !this.compareData.dbStudent.dynamicData[key]))
        ) {
          if (
            ![
              ...Object.keys(this.basicMetaGroups),
              'firstRegistrationDate',
              'lastName',
              'firstName',
              'phoneticLastName',
              'phoneticFirstName',
            ].includes(key)
          ) {
            this.differenceMetaGroups = this.differenceMetaGroups ? this.differenceMetaGroups : {};
            this.differenceMetaGroups[key] = {
              dbMeta: {
                groups: [
                  {
                    class: 'title-nowrap no-border-around title-w225 border-top',
                    rows: [...this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', key))],
                  },
                ],
              },
              csvMeta: {
                groups: [
                  {
                    class: 'title-nowrap no-border no-title-column border-top',
                    rows: [...this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', key))],
                  },
                ],
              },
            };
            this.addFixHeightToTextAreaFields(this.differenceMetaGroups[key].csvMeta.groups);
            this.addFixHeightToTextAreaFields(this.differenceMetaGroups[key].dbMeta.groups);
          }
        }
      });

    this.generateFieldDifferenceClass(this.differenceMetaGroups);

    this.finishMetaFormat(this.differenceMetaGroups);
  }

  private addFixHeightToTextAreaFields(groups, minRows = 15, maxRows = 15): void {
    groups.forEach((group) =>
      group.rows.forEach((row) =>
        row.fields.forEach((field) => {
          if (field.type === 'textarea') {
            field.style = {
              ...field.style,
              minRows: minRows,
              maxRows: maxRows,
            };
          }
        }),
      ),
    );
  }

  onValidityChange(validity: boolean, index: number): void {
    this.validityArray[index] = validity;
    this.validity = this.validityArray.find((v) => !v) == null;
  }

  finishMetaFormat(metaGroups: { [key: string]: any }, titleCleanOnly = false): void {
    Object.values(metaGroups).forEach((meta) => {
      if (meta.csvMeta) {
        meta.csvMeta.groups.forEach((group, gIndex) => {
          group.rows.forEach((row, rIndex) => {
            delete row.title;
            if (!titleCleanOnly) {
              row.hidden = row.fields.every((f) => f.hidden);
              const dbRow = getValueFromObject(meta, `dbMeta.groups.${gIndex}.rows.${rIndex}`);
              if (dbRow) {
                dbRow.hidden = row.hidden;
              }
            }
          });
          const isEveryRowHidden = group.rows.every((r) => r.hidden);
          if (!isEveryRowHidden) {
            group.rows.forEach((row) => {
              row.hidden = false;
              row.fields.forEach((f) => (f.hidden = false));
            });
            meta.dbMeta.groups[gIndex].rows.forEach((row) => {
              row.hidden = false;
              row.fields.forEach((f) => (f.hidden = false));
            });
          }
        });
      }
    });
  }

  toShowCopy(meta: FormMeta): boolean {
    return meta != null && meta.groups != null && meta.groups.find((g) => g.rows.find((r) => !r.hidden) != null) != null;
  }

  private generateSubTitles(metaGroups: { [key: string]: any }): void {
    Object.keys(metaGroups).forEach(
      (metaKey) =>
        metaGroups[metaKey].dbMeta &&
        metaGroups[metaKey].dbMeta.groups.forEach((group, groupIndex) => {
          group.rows.forEach((row, rowIndex) => {
            if (row.title && row.title.includes('\n')) {
              const subTitle = row.title.split('\n')[1];
              row.title = row.title.split('\n')[0];
              row.subTitle = subTitle;
              metaGroups[metaKey].csvMeta.groups[groupIndex].rows[rowIndex].title = row.title;
              metaGroups[metaKey].csvMeta.groups[groupIndex].rows[rowIndex].subTitle = row.subTitle;
            }
          });
        }),
    );
  }
}
