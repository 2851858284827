import { SystemConfigResponse } from '@agent-ds/shared/interfaces';
import { MasterApiService } from '@agent-ds/shared/services';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'ag-settings-system-config-page',
  templateUrl: './settings-system-config-page.component.html',
  styleUrls: ['./settings-system-config-page.component.scss'],
})
export class SettingsSystemConfigPageComponent implements OnInit {
  systemConfigs: SystemConfigResponse[] = [];
  isUpdateInProgress = false;
  private editors: { [key: number]: number } = {};

  constructor(private masterApiService: MasterApiService) {}

  ngOnInit() {
    this.masterApiService.getSystemConfigs().subscribe((systemConfigs: SystemConfigResponse[]) => {
      this.systemConfigs = systemConfigs;
    });
  }

  isChange(): boolean {
    return !!Object.keys(this.editors).length;
  }

  onChange(config: SystemConfigResponse, value: number): void {
    this.editors[config.id] = value;
    if (this.editors[config.id] === config.value) {
      delete this.editors[config.id];
    }
  }

  onSave(): void {
    if (!this.isChange()) {
      return;
    }
    this.isUpdateInProgress = true;
    forkJoin(
      Object.keys(this.editors).map((configId) => this.masterApiService.updateSystemConfig(+configId, { value: this.editors[configId] })),
    )
      .pipe(concatMap(() => this.masterApiService.getSystemConfigs()))
      .subscribe(
        (systemConfigs: SystemConfigResponse[]) => {
          this.systemConfigs = systemConfigs;
          this.editors = {};
          this.isUpdateInProgress = false;
        },
        () => {
          this.isUpdateInProgress = false;
        },
      );
  }
}
