import { SettingsCsvReportingPageComponent } from './settings-csv-reporting-page/settings-csv-reporting-page.component';
import { SettingsCustomFieldsPageComponent } from './settings-custom-fields-page/settings-custom-fields-page.component';
import { SettingsFieldDefinitionsPageComponent } from './settings-field-definitions-page/settings-field-definitions-page.component';
import { SettingsListPageComponent } from './settings-list-page/settings-list-page.component';
import { SettingsMailTemplatesPageComponent } from './settings-mail-templates-page/settings-mail-templates-page.component';
import { SettingsSystemConfigPageComponent } from './settings-system-config-page/settings-system-config-page.component';
import { SettingsTeamsPageComponent } from './settings-teams-page/settings-teams-page.component';
import { SettingsUsersPageComponent } from './settings-users-page/settings-users-page.component';
import { SignatureCsvSettingsPageComponent } from './signature-csv-settings-page/signature-csv-settings-page.component';

export * from './settings-csv-reporting-page/settings-csv-reporting-page.component';
export * from './settings-custom-fields-page/settings-custom-fields-page.component';
export * from './settings-field-definitions-page/settings-field-definitions-page.component';
export * from './settings-list-page/settings-list-page.component';
export * from './settings-mail-templates-page/settings-mail-templates-page.component';
export * from './settings-system-config-page/settings-system-config-page.component';
export * from './settings-teams-page/settings-teams-page.component';
export * from './settings-users-page/settings-users-page.component';
export * from './signature-csv-settings-page/signature-csv-settings-page.component';

export const SETTINGS_PAGES = [
  SettingsCustomFieldsPageComponent,
  SettingsFieldDefinitionsPageComponent,
  SettingsListPageComponent,
  SettingsMailTemplatesPageComponent,
  SettingsSystemConfigPageComponent,
  SettingsTeamsPageComponent,
  SettingsUsersPageComponent,
  SignatureCsvSettingsPageComponent,
  SettingsCsvReportingPageComponent,
];
