import { CounselingContentType, CounselingNavService } from '@agent-ds/counseling/services/counseling-nav.service';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, ExportApiService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-counseling-page',
  templateUrl: './counseling-page.component.html',
  styleUrls: ['./counseling-page.component.scss'],
})
export class CounselingPageComponent implements OnInit, OnDestroy {
  title: string;
  breadcrumbItems: { name: string; action?: () => void }[] = [];
  shownContent: CounselingContentType;
  lastSelectedArea: { id: number; name: string };
  isIntakeCa = false;

  private navSubscription: Subscription;
  private navigationData: any;
  private loginSubscription: Subscription;

  constructor(
    private navService: CounselingNavService,
    private router: Router,
    private exportService: ExportApiService,
    public readonly authService: AuthService,
  ) {}

  ngOnInit() {
    // 副担当者のログインかどうかを確認
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      this.isIntakeCa = this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
      return;
    });

    this.navSubscription = this.navService.changePageContent.subscribe(
      (subject: { content: CounselingContentType; area: { id: number; name: string }; data?: any }) => {
        this.shownContent = subject.content;
        this.lastSelectedArea = subject.area;
        this.navigationData = subject.data;
        switch (subject.content) {
          case CounselingContentType.seminarList:
            this.breadcrumbItems = [{ name: 'TOP', action: () => this.router.navigate(['/']) }, { name: 'セミナー一覧' }];
            this.title = 'セミナー管理';
            break;
          case CounselingContentType.attendeeList:
            this.breadcrumbItems = [
              { name: 'TOP', action: () => this.router.navigate(['/']) },
              {
                name: 'セミナー一覧',
                action: () => this.navService.changePageContent.next({ content: CounselingContentType.seminarList, area: subject.area }),
              },
              { name: 'セミナー出席予定者一覧' },
            ];
            this.title = 'セミナー管理 │ セミナー出席予定者一覧';
            break;
          case CounselingContentType.jobList:
            this.breadcrumbItems = [
              { name: 'TOP', action: () => this.router.navigate(['/']) },
              {
                name: 'セミナー一覧',
                action: () => this.navService.changePageContent.next({ content: CounselingContentType.seminarList, area: subject.area }),
              },
              { name: this.navigationData && this.navigationData.seminar ? 'セミナー紹介求人一覧' : 'セミナー紹介求人マスタ登録' },
            ];
            this.title = `セミナー管理 │ ${
              this.navigationData && this.navigationData.seminar ? 'セミナー紹介求人一覧' : 'セミナー紹介求人マスタ登録'
            }`;
            break;
          default:
            this.breadcrumbItems = [];
            this.title = '';
            break;
        }
      },
    );
  }

  onExportStudentsForSeminarClick(): void {
    if (this.navigationData && this.navigationData.seminar && this.navigationData.seminar.id !== null) {
      this.exportService.exportStudentsForSeminar(this.navigationData.seminar.id).subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
      this.navSubscription = null;
    }
    this.navService.changePageContent.next({ content: CounselingContentType.seminarList });
  }
}
