import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ag-file-upload-area',
  templateUrl: './file-upload-area.component.html',
  styleUrls: ['./file-upload-area.component.scss'],
})
export class FileUploadAreaComponent implements OnInit {
  public dragActive = false;

  @Output() filesChanged = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public setInputDrag(value: boolean): void {
    this.dragActive = value;
  }

  public onInputChanged(event): void {
    this.filesChanged.emit(event.srcElement.files);
  }
}
