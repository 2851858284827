import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

export enum SupplierCallType {
  MODEL_CHANGE,
  LINK_CHANGE,
  INIT,
  UPDATE,
  MODEL_LINK_CHANGE,
}

export interface FormMeta {
  class?: string;
  disabled?: boolean;
  initTouched?: boolean;
  groups: GroupMeta[];
}

export interface GroupMeta {
  title?: string;
  class?: string;
  injectToHeader?: TemplateRef<any>;
  rows: RowMeta[];
  collapsable?: boolean;
  collapsedTitle?: string;
  toggleButtonLabel?: string;
  toggledButtonLabel?: string;
  toggledButtonAction?: () => void;
  expanded?: boolean;
}

export interface RowMeta {
  class?: string;
  title?: string;
  subTitle?: string;
  showRequired?: boolean;
  fields: FieldMeta[];
  style?: any;
  hidden?: boolean;
}

export interface FieldMeta {
  name: string;
  hidden?: boolean;
  disabled?: boolean;
  labelBefore?: string;
  labelAfter?: string;
  class?: string;
  linkTo?: string[];
  multi?: boolean;
  options?: any[];
  /** {modelKey: valueKey} */
  valueField?: string | { [key: string]: string };
  labelField?:
    | string
    | {
        title?: string;
        name: string;
        class?: string;
        supplier?: (value: any) => any;
        supplierAsync?: (value: any) => Observable<any>;
        hidden?: boolean;
        action?: (item: any) => void;
        skipInFilter?: boolean;
      }[];
  default?: any;
  transparent?: boolean;
  specialOption?: string;
  placeholder?: string;
  customTooltipErrorMessage?: string;
  supplier?: (
    value?: any,
    callType?: SupplierCallType,
    getValue?: (key: string, override?: boolean) => any,
    setValue?: (key: string, value: any) => void,
    filters?: { [key: string]: any },
  ) => { value?: any; options?: any[] } | Observable<{ value?: any; options?: any[] }> | any;
  actions?: ActionMeta[];
  actionsClass?: string;
  clickAction?: (event: any) => void;
  type:
    | 'label'
    | 'hr'
    | 'text'
    | 'textarea'
    | 'number'
    | 'dropdown'
    | 'radio'
    | 'checkbox'
    | 'date'
    | 'time'
    | 'year'
    | 'month'
    | 'yearmonth'
    | 'autocomplete'
    | 'zip'
    | 'list';
  validators?: any;
  style?: any;
  filters?: {
    name: string;
    class?: string;
    labelBefore?: string;
    options: any[];
    valueField?: string;
    labelField?: string;
    linkTo?: string[];
    transparent?: boolean;
    hidden?: boolean;
    supplier: (linkValue?: any) => { value?: any; options?: any[] } | Observable<{ value?: any; options?: any[] }> | any;
  }[];
  showOn?: { [key: string]: any };
  allowOn?: { [key: string]: any };
  manualInputOn?: string;
}

export interface ActionMeta {
  title: string;
  type: 'RUNNABLE' | 'UPDATE_LINKED' | 'REVEAL' | 'EMPTY' | 'SELECT';
  runnable?: (getValue: (key: string, override?: boolean) => any, setValue: (key: string, value: any) => void, value?: any) => any;
  options?: string[];
  showOn?: { [key: string]: any };
  allowOn?: { [key: string]: any };
}

export function isPartial(field: FieldMeta): boolean {
  return isPartialKey(field.name);
}

export function isPartialKey(key: string): boolean {
  return key.match(/\.\d+$/) != null;
}

export function isNestedSupplied(obj: { value?: any; options?: any } | any): boolean {
  return obj && typeof obj === 'object' && Object.keys(obj).find((key) => key === 'value' || key === 'options') != null;
}

export function strip(key: string): string {
  return isPartialKey(key) ? key.substring(0, key.lastIndexOf('.')) : key;
}
