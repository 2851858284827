import { ActionMeta, GroupMeta, RowMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { SalesDetailPageComponent } from '../sales-detail-page.component';
import { HelperBase } from './helper-base';

export const MAX_FEE_VALUE = 1000000000;

@Injectable()
export class OrderInformationHelper extends HelperBase {
  private calculateTaxActionsProp: { actions?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] } = { subscribers: [] };
  set calculateTaxActions(value: ActionMeta[]) {
    this.setupActionProperty(this.calculateTaxActionsProp, value);
  }

  private notApproved: GroupMeta = { rows: [] };
  private approved: GroupMeta = { rows: [] };
  private disabled: GroupMeta = { rows: [] };
  private textarea: GroupMeta = { rows: [] };

  private matrix: GroupMeta[][];
  private contractOptions: any[] = [];

  constructor(dynamicService: DynamicFieldService, datePipe: SafeDatePipe, private decimalPipe: DecimalPipe) {
    super(dynamicService, datePipe);
  }

  init(detailPage: SalesDetailPageComponent): void {
    detailPage.contractSubject.subscribe((contractOptions: any[]) => (this.contractOptions = contractOptions));
  }

  updateFieldDefinitions(): void {
    this.approved = {
      // Order information
      title: '受注額情報',
      class: 'form__group--golden-title-border',
      rows: [
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'invoiceNo'), 'sales.dynamicData', 'half'),
        ...this.dynamicService.getFormRows(
          {
            ...this.dynamicService.getDefinition('sales', 'fee'),
            validationStyle: { required: false, maxValue: MAX_FEE_VALUE },
          },
          'sales.dynamicData',
          'half',
        ),
        ...this.getFormRowsWith1RowActions(
          {
            ...this.dynamicService.getDefinition('sales', 'taxFee'),
            validationStyle: { required: false },
          },
          this.calculateTaxActionsProp,
          'sales.dynamicData',
          'half',
        ),
        ...this.dynamicService.getFormRows(
          {
            ...this.dynamicService.getDefinition('sales', 'chargeFee'),
            validationStyle: { required: false },
          },
          'sales.dynamicData',
          'half',
        ),
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'feeChangeDate'), 'sales.dynamicData'),
      ],
    };

    this.notApproved = {
      // Order information
      title: '受注額情報',
      class: 'form__group--golden-title-border',
      rows: [
        ...this.dynamicService.getFormRows(
          { ...this.dynamicService.getDefinition('sales', 'fee'), validationStyle: { required: true, maxValue: MAX_FEE_VALUE } },
          'sales.dynamicData',
          'half',
        ),
        ...this.getFormRowsWith1RowActions(
          this.dynamicService.getDefinition('sales', 'taxFee'),
          this.calculateTaxActionsProp,
          'sales.dynamicData',
          'half',
        ),
        ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'chargeFee'), 'sales.dynamicData', 'half'),
      ],
    };

    this.textarea = {
      title: 'フィー一覧',
      class: 'form__group--margin-spacer form__group--no-title-border form__group--inline-title no-title-column',
      rows: [
        (() => {
          const row = this.dynamicService.getFormRows({
            fieldName: 'contract-priceInformation',
            fieldType: 'text',
            displayType: 'textarea',
          })[0];
          row.fields[0].transparent = true;
          row.fields[0].disabled = true;
          row.fields[0].class = 'full text-area-no-disable-look';
          row.style = { 'border-width': '1px' };
          return row;
        })(),
      ],
    };

    this.disabled = {
      // Order information
      title: '受注額情報',
      class: 'form__group--golden-title-border',
      rows: [
        ...this.getDynamicRowOrLabel(false, this.dynamicService.getDefinition('sales', 'invoiceNo'), null, 'sales.dynamicData', 'half'),
        ...this.getDynamicRowOrLabel(
          false,
          {
            ...this.dynamicService.getDefinition('sales', 'fee'),
            validationStyle: { required: false, maxValue: MAX_FEE_VALUE },
          },
          null,
          'sales.dynamicData',
          'half',
          (val) => this.decimalPipe.transform(val),
        ),
        ...this.getDynamicRowOrLabel(
          false,
          {
            ...this.dynamicService.getDefinition('sales', 'taxFee'),
            validationStyle: { required: false },
          },
          null,
          'sales.dynamicData',
          'half',
          (val) => this.decimalPipe.transform(val),
        ),
        ...this.getDynamicRowOrLabel(
          false,
          {
            ...this.dynamicService.getDefinition('sales', 'chargeFee'),
            validationStyle: { required: false },
          },
          null,
          'sales.dynamicData',
          'half',
          (val) => this.decimalPipe.transform(val),
        ),
        ...this.getDynamicRowOrLabel(false, this.dynamicService.getDefinition('sales', 'feeChangeDate'), null, 'sales.dynamicData'),
      ],
    };

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [this.notApproved, this.notApproved, this.notApproved, this.disabled], // Approval state: undefined
      [this.notApproved, this.notApproved, this.notApproved, this.disabled], // Approval state: 1 (Not Approved)
      [this.approved, this.approved, this.approved, this.disabled], // Approval state: 2 (Approved)
      [this.approved, this.approved, this.approved, this.disabled], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus], this.textarea];
  }
}
