import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-settings-list-page',
  templateUrl: './settings-list-page.component.html',
  styleUrls: ['./settings-list-page.component.scss'],
})
export class SettingsListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
