import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@agent-ds/shared/guard';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';

/**
 * Route定数
 */
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardPageComponent,
  },
];

/**
 * 求職者ルーティング
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
