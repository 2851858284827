import { Job, ProgressSearchParams } from '@agent-ds/shared/interfaces';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-job-progress-tab',
  templateUrl: './job-progress-tab.component.html',
  styleUrls: ['./job-progress-tab.component.scss'],
})
export class JobProgressTabComponent implements OnInit, OnChanges {
  @Input() job: Job;
  requestObject: ProgressSearchParams;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.job != null && changes['job'].currentValue !== changes['job'].previousValue) {
      this.requestObject = {
        jobId: [this.job.id],
      };
    }
  }
}
