import { SharedModule } from '@agent-ds/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PROGRESS_COMPONENTS, PROGRESS_DIALOG_COMPONENTS } from './components';
import { PROGRESS_PAGES } from './pages';
import { ProgressRoutingModule } from './progress-routing.module';

@NgModule({
  declarations: [PROGRESS_PAGES, PROGRESS_COMPONENTS, PROGRESS_DIALOG_COMPONENTS],
  exports: [PROGRESS_PAGES],
  imports: [CommonModule, ProgressRoutingModule, SharedModule],
  entryComponents: [PROGRESS_DIALOG_COMPONENTS],
})
export class ProgressModule {}
