import { OutlookResponse } from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OutlookApiService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public getMessageInfo(messageId: string): Observable<OutlookResponse> {
    return this.http.get<OutlookResponse>('/api/mail/integration/' + messageId);
  }
}
