import { EventEmitter, Input, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideActionConfig } from '../components/page-floater/page-floater-interface';
import { ApiService } from './api-service';

export enum RefreshEvent {
  DELETE,
}
export enum NotifyEvent {
  CONNECTION_ERROR,
}

export abstract class DetailPage implements OnInit, OnDestroy {
  private static readonly INSTANCES: DetailPage[] = [];
  public static get instance(): DetailPage {
    return DetailPage.INSTANCES.find((instance) => instance instanceof this);
  }

  @Output() readonly opened = new EventEmitter<void>();
  @Output() readonly closed = new EventEmitter<void>();
  public abstract sideActions: SideActionConfig[];
  protected abstract tabs: any;
  protected abstract urlTag: string;

  protected fullMode = true;
  protected slimMode: boolean;
  protected readonly = false;
  public tabCode = '';
  private innerReferenceId: number;

  public origUrl = '';
  public fullModeUrl = '';
  public disableFullScreen: boolean;
  public selectedSideActionIndex = 0;
  public data: any;

  protected routeSubscription: Subscription;
  protected refreshSubscription: Subscription;

  public static closeAll(except?: Type<DetailPage>[]): void {
    DetailPage.INSTANCES.filter((ins) => !except || except.find((e) => ins instanceof e) == null).forEach((page) => page.close());
  }

  public static resetAll(): void {
    DetailPage.INSTANCES.forEach((page) => page.reset());
  }

  public get referenceId(): number {
    return this.innerReferenceId;
  }

  @Input()
  public set referenceId(referenceId: number) {
    this.disableFullScreen = false;
    this.innerReferenceId = referenceId;
    if (this.innerReferenceId) {
      setTimeout(() => this.fill(), 0);
    }
  }

  public get inFullMode(): boolean {
    return this.fullMode;
  }
  @Input()
  public set inFullMode(fullMode: boolean) {
    const toUpdate = this.fullMode !== fullMode;
    this.fullMode = fullMode;
    if (toUpdate) {
      this.updateUrl();
    }
  }

  public get inSlimMode(): boolean {
    return this.slimMode;
  }
  @Input()
  public set inSlimMode(slimMode: boolean) {
    this.slimMode = slimMode;
  }

  public get isReadonly(): boolean {
    return this.readonly;
  }
  @Input()
  public set isReadonly(readonly: boolean) {
    this.readonly = readonly;
  }

  public get selectedTabIndex(): number {
    return this.tabs[(this.tabCode || '').toUpperCase()] || 0;
  }

  public set selectedTabIndex(index: number) {
    this.tabCode = !this.tabs[index] || this.tabs[index] === 'OVERVIEW' ? '' : this.tabs[index].toLowerCase();
    this.updateUrl();
  }

  constructor(protected router: Router, protected service: ApiService, protected activeRoute: ActivatedRoute) {
    if (!DetailPage.INSTANCES.find((instance) => instance instanceof this.constructor)) {
      DetailPage.INSTANCES.push(this);
    }
    this.routeSubscription = this.activeRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        this.referenceId = params['id'];
      }
      if (params['tab']) {
        this.tabCode = params['tab'];
      }
    });
    this.refreshSubscription = this.service.refreshEvent.subscribe((event: RefreshEvent) => this.fill(true, event));
  }

  public open(): void {
    this.opened.emit();
    this.origUrl = window.location.href;
  }

  public close(): void {
    this.closed.emit();
  }

  public reset(): void {
    this.referenceId = undefined;
  }

  protected updateUrl(): void {
    setTimeout(() => {
      this.fullModeUrl = this.referenceId ? `${this.urlTag}/${this.referenceId + (this.tabCode ? '/' + this.tabCode : '')}` : this.urlTag;
      if (this.inFullMode) {
        window.history.replaceState({}, undefined, this.fullModeUrl);
      } else if (this.origUrl) {
        window.history.replaceState({}, undefined, this.origUrl);
      }
    });
  }

  ngOnInit() {
    this.fill();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
  }

  protected abstract fill(onRefresh?: boolean, data?: RefreshEvent): void;
}
