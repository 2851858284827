import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '@agent-ds/shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanyModule } from './company/company.module';
import { CounselingModule } from './counseling/counseling.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { JobsModule } from './jobs/jobs.module';
import { MatchingModule } from './matching/matching.module';
import { ProgressModule } from './progress/progress.module';
import { SalesModule } from './sales/sales.module';
import { SettingsModule } from './settings/settings.module';
import { StudentModule } from './student/student.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    DashboardModule,
    CounselingModule,
    StudentModule,
    CompanyModule,
    SalesModule,
    JobsModule,
    ProgressModule,
    SettingsModule,
    MatchingModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ja' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeJa, 'ja');
  }
}
