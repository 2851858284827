import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { MatchingCreateDialogComponent } from '@agent-ds/matching/components/matching-create-dialog/matching-create-dialog.component';
import { ProgressCreateDialogComponent } from '@agent-ds/progress/components';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { Job } from '@agent-ds/shared/interfaces';
import { JobMatching } from '@agent-ds/shared/interfaces/job-matching';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { MatchingApiService } from '@agent-ds/shared/services/api/matching-api.service';
import { deepClone } from '@agent-ds/shared/util/util';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ag-job-student-matching-page',
  templateUrl: './job-student-matching-page.component.html',
  styleUrls: ['./job-student-matching-page.component.scss'],
})
export class JobStudentMatchingPageComponent extends DetailPage implements OnInit, AfterViewInit {
  @ViewChild('matchingJobDetails', { static: false }) jobDetails: JobDetailPageComponent;
  @ViewChild('matchingStudentList', { static: false }) studentList: any;
  studentSearchObj: any = { keyword: '' };
  matchings: JobMatching[] = [];
  selectedMatching: JobMatching;
  job: Job;
  showList: boolean;
  listConfiguration: any = { body: { checkbox: true, hideCreateButton: true, hideBatchUpdateButton: true } };
  private dialogRef: DialogRef;

  public sideActions: SideActionConfig[] = [{ phases: ['100%'] }];
  protected tabs: any = {};
  protected urlTag = 'matching/job-student';

  constructor(
    private matchingApi: MatchingApiService,
    private dialog: DialogService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected service: StudentApiService,
  ) {
    super(router, service, activeRoute);
    this.refreshSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.jobDetails.jobDetailLoaded.subscribe((job) => {
      this.job = job;

      // マッチング条件で検索項目のデフォルト値を設定する
      this.studentSearchObj = {
        universityLevel: job.dynamicData.universityLevel ? [...job.dynamicData.universityLevel] : [],
        // ['男性', '女性', 'その他'] から ['男性', '女性', '回答しない'] への変換
        gender: (() => {
          if (job.dynamicData.gender) {
            return [...job.dynamicData.gender].map((value) => {
              return value === 'その他' ? '回答しない' : value;
            });
          } else {
            return [];
          }
        })(),
        academicFieldType: job.dynamicData.academicFieldType ? [...job.dynamicData.academicFieldType] : [],
        supportYear: job.dynamicData.recruitYear ? [job.dynamicData.recruitYear] : [],
        // lastAcademicRecord: job.dynamicData.lastAcademicRecord ? [...job.dynamicData.lastAcademicRecord] : [],
        registrationStatus: ['本登録'],
        communicationSkill: job.dynamicData.communicationSkill,
        impressionAndCharm: job.dynamicData.impressionAndCharm,
        ...this.studentSearchObj,
      };
      this.loadMatchings(job.id);
      this.updateUrl();
    });
  }

  protected fill(onRefresh?: boolean): void {
    if (this.referenceId) {
      this.showList = false;
      this.studentSearchObj = {};
      this.jobDetails.referenceId = this.referenceId;
      this.jobDetails.open();
    }
  }

  loadMatchings(jobId: number, selectedId?: number): void {
    this.matchingApi.getMatchingsForJob(jobId).subscribe((matchings) => {
      this.matchings = matchings;
      this.selectedMatching = this.matchings.find((m) => m.id === selectedId);
    });
  }

  onFilterChanged(changes: any): void {
    if (this.selectedMatching) {
      this.studentSearchObj = {
        ...deepClone(this.studentSearchObj),
        ...changes,
      };
      this.selectedMatching = undefined;
    } else {
      this.studentSearchObj = {
        ...this.studentSearchObj,
        ...changes,
      };
    }
  }

  matchingSelected(selected: boolean, matching: JobMatching): void {
    this.selectedMatching = matching;
    this.studentSearchObj = this.selectedMatching && selected ? deepClone(this.selectedMatching.matchingParameter) : { keyword: '' };
  }

  saveMatching(): void {
    this.dialog.open(MatchingCreateDialogComponent, null, (dialogModel) => {
      if (dialogModel) {
        const matching: JobMatching = {
          name: dialogModel.name,
          jobId: this.job.id,
          matchingParameter: this.studentSearchObj,
        };
        return this.matchingApi.saveMatchingForJob(matching).pipe(tap((res) => this.loadMatchings(this.job.id, res.id)));
      }
    });
  }

  deleteMatching(matching) {
    const config: ConfirmDialogConfig = {
      title: '求人検索マッチング条件の削除',
      messages: ['マッチング条件を削除してもよろしいですか？'],
      style: {
        height: '245px',
        width: '510px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialog.open(ConfirmDialogComponent, config, (confirmed) => {
      if (confirmed) {
        return this.matchingApi.deleteJobMatching(matching.id).pipe(
          tap(() => {
            this.loadMatchings(this.job.id);
          }),
        );
      }
    });
  }

  public doStudentSearch(params: any): void {
    this.showList = true;
    setTimeout(
      () =>
        (this.studentSearchObj = {
          ...this.studentSearchObj,
          ...params,
        }),
    );
  }

  createProgress(): void {
    this.dialogRef = this.dialog.open(ProgressCreateDialogComponent, {
      data: {
        jobList: [
          {
            frontJobId: this.job.frontId,
            ...this.job,
          },
        ],
        studentList: this.studentList.checkedItems,
        matchingMode: true,
      },
    });
  }

  toSearch(): void {
    DetailPage.closeAll([JobStudentMatchingPageComponent]);
    this.showList = false;
  }
}
