import { JobsModule } from '@agent-ds/jobs/jobs.module';
import { SharedModule } from '@agent-ds/shared';
import { StudentModule } from '@agent-ds/student/student.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatchingCreateDialogComponent } from './components/matching-create-dialog/matching-create-dialog.component';
import { MatchingRoutingModule } from './matching-routing.module';
import { MATCHING_PAGES } from './pages';

@NgModule({
  declarations: [MATCHING_PAGES, MatchingCreateDialogComponent],
  exports: [MATCHING_PAGES],
  imports: [CommonModule, SharedModule, MatchingRoutingModule, JobsModule, StudentModule],
  providers: [],
  entryComponents: [MatchingCreateDialogComponent],
})
export class MatchingModule {}
