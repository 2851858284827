import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ClickOutsideDirective } from '@agent-ds/shared/directives/click-outside.directive';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'ag-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectComponent), multi: true },
  ],
})
export class SelectComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, OnDestroy {
  @ViewChild(ClickOutsideDirective, { static: true }) clickDir: ClickOutsideDirective;
  /**
   * Use the hidden _selectLevel(: number > 0) field in an option to indent it, like a tree.
   */
  @Input() options = [];
  @Input() allLabel?: string;
  @Input() allSelected?: boolean;
  @Input() allIsEmpty?: boolean;
  @Input() confirmLabel?: string;
  @Input() labelField?: string;
  @Input() valueField?: string | { [key: string]: string };
  @Input() value: any;
  @Input() readonly?: boolean;
  @Input() multi = false;
  @Input() placeholder?: string;
  @Input() validators?: { [key: string]: any };
  @Input() extraClass?: string;
  @Output() valueChange = new EventEmitter<any[]>();

  closed = true;
  tempSelected: { [key: string]: boolean } = {};
  getValueFromObject = getValueFromObject;

  private alive = true;
  private propagateChange: any = () => this.valueChange.emit(this.value);
  private propagateTouch: any = () => false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.alive = true;
    this.clickDir.detach();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] || changes['value']) {
      this.refreshTemp();
    }
    if (changes['multi'] && changes['multi'].previousValue !== changes['multi'].currentValue) {
      this.value = !changes['multi'].currentValue ? (Array.isArray(this.value) ? this.value[0] : null) : this.value ? [this.value] : [];
    }
  }

  ngOnDestroy() {
    this.alive = false;
    this.propagateChange = this.propagateTouch = () => false;
  }

  open(): void {
    if (this.disabled) {
      return;
    }
    if (!this.closed) {
      this.onOutsideClick(!this.confirmLabel);
    } else {
      this.closed = false;
      this.clickDir.attach();
      this.detect();
    }
  }

  onOutsideClick(confirm?: boolean): void {
    if (!this.closed) {
      if (confirm) {
        this.confirm();
      } else {
        this.refreshTemp();
      }
      this.closed = true;
      this.clickDir.detach();
      this.detect();
    }
  }

  onSpecificValueChanged(index: number): void {
    this.tempSelected['all'] = false;
    if (this.multi || !this.compare(this.value, this.options[index])) {
      if (!this.multi) {
        Object.keys(this.tempSelected).forEach((key) => (this.tempSelected[key] = false));
      }
      this.tempSelected[index] = !this.tempSelected[index];
      this.confirm(null, this.multi);
    } else if (!this.multi) {
      this.closed = true;
      this.clickDir.detach();
      this.detect();
    }
  }

  compare(value: any, option: any): boolean {
    return typeof this.valueField === 'string'
      ? value === getValueFromObject(option, this.valueField) ||
          (value == null && NULL_SELECTED_VALUE === getValueFromObject(option, this.valueField))
      : typeOf(this.valueField) === 'object' && value != null
      ? Object.keys(this.valueField).find((key) => value[this.valueField[key]] === option[this.valueField[key]]) != null
      : value === option || (value == null && option === NULL_SELECTED_VALUE) || deepCompare(value, option, 'id');
  }

  compareAll(value: any[], option: any): boolean {
    return value.find((val) => this.compare(val, option)) !== undefined;
  }

  private refreshTemp(): void {
    this.tempSelected['all'] = this.allLabel && this.allSelected;
    this.options.forEach(
      (option, index) =>
        (this.tempSelected[index] =
          !this.tempSelected['all'] &&
          (!this.multi ? this.compare(this.value, option) : this.value && this.compareAll(this.value, option))),
    );
  }

  get disabled(): boolean {
    return (
      this.readonly ||
      !this.options ||
      !this.options.length ||
      (this.options.length === 1 &&
        (this.labelField ? getValueFromObject(this.options[0], this.labelField) : this.options[0]) === NULL_SELECTED_VALUE)
    );
  }

  get selectedLabel(): string {
    if (this.multi) {
      if (this.allLabel && this.allSelected) {
        return this.allLabel;
      }
      return this.options
        .filter((item) => this.compareAll(this.value, item))
        .map((item) =>
          this.labelField
            ? getValueFromObject(item, this.labelField) ||
              (typeof this.valueField === 'string' ? getValueFromObject(item, this.valueField) : item)
            : item,
        )
        .join(', ');
    } else {
      let selected = this.options.find((option) => this.compare(this.value, option));
      if (
        selected == null &&
        this.options.length &&
        (this.labelField ? getValueFromObject(this.options[0], this.labelField) : this.options[0]) === NULL_SELECTED_VALUE
      ) {
        selected = this.options[0];
      }
      if (selected) {
        return this.labelField ? getValueFromObject(selected, this.labelField) : selected;
      }
    }
    return this.placeholder;
  }

  public selectAll(unselect = false): void {
    this.tempSelected['all'] = !unselect;
    Object.keys(this.tempSelected).forEach((key) => (this.tempSelected[key] = !unselect));
    if (this.closed || !this.confirmLabel) {
      this.confirm(null, !this.closed);
    }
  }

  toggle(key: string | number): void {
    this.tempSelected[key] = !this.tempSelected[key];
    this.detect();
  }

  confirm(force = false, suppressEvent = false): void {
    this.propagateTouch();
    if (!this.multi) {
      const selected = Object.keys(this.tempSelected).find((key) => this.tempSelected[key]);
      this.value = selected
        ? typeof this.valueField === 'string'
          ? getValueFromObject(this.options[selected], this.valueField)
          : this.options[selected]
        : null;
      this.options.forEach((option, index) => (this.tempSelected[index] = false));
    } else {
      if (this.value == null) {
        this.value = [];
      }
      this.value.length = 0;
      if (this.tempSelected['all']) {
        this.allSelected = true;
        this.options.forEach((option, index) => {
          this.tempSelected[index] = false;
          if (!this.allIsEmpty) {
            this.value.push(typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option);
          }
        });
      } else {
        delete this.tempSelected['all'];
        this.allSelected = false;
        Object.keys(this.tempSelected)
          .filter((key) => this.tempSelected[key])
          .forEach((key) => this.value.push(typeof this.valueField === 'string' ? this.options[key][this.valueField] : this.options[key]));
      }
    }
    if (!suppressEvent) {
      this.propagateChange();
    }
    if ((!this.multi && !this.confirmLabel) || force) {
      this.closed = true;
      this.clickDir.detach();
    }
    this.refreshTemp();
    this.detect();
  }

  writeValue(value: any[] | any): void {
    this.value = this.multi && !Array.isArray(value) ? [] : value;
    this.refreshTemp();
    this.detect();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = () => {
      fn(this.value);
      this.valueChange.emit(this.value);
    };
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
  validate(c: FormControl): ValidationErrors {
    if (!this.alive) {
      return null;
    }
    const valid = {};
    if (this.validators) {
      Object.keys(this.validators).forEach((key) => {
        const ret = typeof this.validators[key] === 'function' ? this.validators[key](c) : null;
        if (
          (key === 'required' &&
            this.validators[key] &&
            (this.value == null || (this.multi && !this.value.length) || this.value === NULL_SELECTED_VALUE)) ||
          (key === 'min' && this.multi && this.value.length < this.validators[key]) ||
          (key === 'max' && this.multi && this.value.length > this.validators[key]) ||
          ret != null
        ) {
          valid[key] = ret || true;
        }
      });
    }
    return Object.keys(valid).length ? valid : null;
  }

  detect(): void {
    if (this.alive) {
      this.cdr.detectChanges();
    }
  }
}
