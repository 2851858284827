import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BulkProgressChangeDialogComponent } from './bulk-progress-change-dialog/bulk-progress-change-dialog.component';
import { ChipComponent } from './chip/chip.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContactHistoryComponent } from './contact-history/contact-history.component';
import { DialogComponent } from './dialog/dialog.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileUploadAreaComponent } from './file-upload-area/file-upload-area.component';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { JobListComponent } from './job-list/job-list.component';
import { GlobalHeaderComponent, GlobalMenuComponent, LayoutComponent } from './layout';
import { MailSendFlowComponent } from './mail-send-flow/mail-send-flow.component';
import { MailSendComponent } from './mail-send/mail-send.component';
import { MailComponent } from './mail/mail.component';
import { MemoListComponent } from './memo-list/memo-list.component';
import { MemoComponent } from './memo-list/memo/memo.component';
import { OrderedListComponent } from './ordered-list/ordered-list.component';
import { PageFloaterComponent } from './page-floater/page-floater.component';
import { PageScrollTableComponent } from './page-scroll-table/page-scroll-table.component';
import { PasswordChangeDialogComponent } from './password-change-dialog/password-change-dialog.component';
import { PersonsInChargeComponent } from './persons-in-charge/persons-in-charge.component';
import { PopupControlComponent } from './popup-control/popup-control.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { ProgressListComponent } from './progress-list/progress-list.component';
import { SalesListComponent } from './sales-list/sales-list.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SegsComponent } from './segs/segs.component';
import { SelectComponent } from './select/select.component';
import { StudentListWithSubUserComponent } from './student-list-with-sub-user/student-list-with-sub-user.component';
import { StudentListWithTodaysCounselingComponent } from './student-list-with-todays-counseling/student-list-with-todays-counseling.component';
import { StudentListComponent } from './student-list/student-list.component';
import { TabComponent, TabGroupComponent } from './tab-group';

export const SHARED_COMPONENTS = [
  GlobalHeaderComponent,
  GlobalMenuComponent,
  LayoutComponent,
  PageScrollTableComponent,
  PageFloaterComponent,
  TabGroupComponent,
  TabComponent,
  PopupControlComponent,
  SearchBarComponent,
  AutocompleteComponent,
  FileUploadAreaComponent,
  SelectComponent,
  DynamicFormComponent,
  ProfileImageComponent,
  ProgressListComponent,
  MailComponent,
  FileListComponent,
  ContactHistoryComponent,
  MemoListComponent,
  MemoComponent,
  JobListComponent,
  SalesListComponent,
  PersonsInChargeComponent,
  MailSendComponent,
  MailSendFlowComponent,
  CompanyListComponent,
  StudentListComponent,
  StudentListWithTodaysCounselingComponent,
  StudentListWithSubUserComponent,
  OrderedListComponent,
  ChipComponent,
  SegsComponent,
];

export * from './progress-list/progress-list.component';

export const SHARED_DIALOG_COMPONENTS = [
  DialogComponent,
  BulkProgressChangeDialogComponent,
  ConfirmDialogComponent,
  FileUploadDialogComponent,
  PasswordChangeDialogComponent,
];
