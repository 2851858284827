import { Injectable } from '@angular/core';

/**
 * 永続ストレージ
 */
@Injectable({
  providedIn: 'root',
})
export class PermanentStorageService {
  /**
   * キー値のプレフィクス
   */
  private prefix = 'agent-ds';

  /**
   * キー値を取得する
   */
  private key(key: string): string {
    return `${this.prefix}:${key}`;
  }

  /**
   * 取得する
   */
  get(key: string): any {
    const data = localStorage.getItem(this.key(key));

    if (!data) {
      return null;
    }

    try {
      return JSON.parse(data);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * 保存する
   */
  set(key: string, value: any) {
    return localStorage.setItem(this.key(key), JSON.stringify(value));
  }

  /**
   * 削除する
   */
  remove(key: string) {
    return localStorage.removeItem(this.key(key));
  }
}
