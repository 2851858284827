export class Kpi {
  counselings: FactsAndTargets;
  azs: FactsAndTargets;
  offerSubmissions: FactsAndTargets;
  studentOks: FactsAndTargets;
  salesDocuments: FactsAndTargets;
  seminarSettings: FactsAndTargets;
  seminarCompletions: FactsAndTargets;
  firstInterviewSettings: FactsAndTargets;
  firstInterviewCompletions: FactsAndTargets;
  finalInterviewSettings: FactsAndTargets;
  finalInterviewCompletions: FactsAndTargets;
  offers: FactsAndTargets;
  acceptances: FactsAndTargets;
  sales: FactsAndTargets;
  cancels: Cancels;

  static empty(): Kpi {
    return {
      counselings: null,
      azs: null,
      offerSubmissions: null,
      studentOks: null,
      salesDocuments: null,
      seminarSettings: null,
      seminarCompletions: null,
      firstInterviewSettings: null,
      firstInterviewCompletions: null,
      finalInterviewSettings: null,
      finalInterviewCompletions: null,
      offers: null,
      acceptances: null,
      sales: null,
      cancels: null,
    };
  }
}

export class TargetKpi {
  counselings: number;
  azs: number;
  offerSubmissions: number;
  studentOks: number;
  salesDocuments: number;
  seminarSettings: number;
  seminarCompletions: number;
  firstInterviewSettings: number;
  firstInterviewCompletions: number;
  finalInterviewSettings: number;
  finalInterviewCompletions: number;
  offers: number;
  acceptances: number;
  sales: number;

  static empty(): TargetKpi {
    return {
      counselings: 0,
      azs: 0,
      offerSubmissions: 0,
      studentOks: 0,
      salesDocuments: 0,
      seminarSettings: 0,
      seminarCompletions: 0,
      firstInterviewSettings: 0,
      firstInterviewCompletions: 0,
      finalInterviewSettings: 0,
      finalInterviewCompletions: 0,
      offers: 0,
      acceptances: 0,
      sales: 0,
    };
  }
}

export class ProgressKpi {
  raRecommending: ProgressKpiElement;
  waitingStudentOk: ProgressKpiElement;
  salesDocumentBeforeSubmit: ProgressKpiElement;
  salesDocumentWaitingResult: ProgressKpiElement;
  enterpriseDocumentWaitingSubmit: ProgressKpiElement;
  enterpriseDocumentWaitingResult: ProgressKpiElement;
  seminarSetting: ProgressKpiElement;
  seminarConfirming: ProgressKpiElement;
  seminarCompletion: ProgressKpiElement;
  firstInterviewSetting: ProgressKpiElement;
  firstInterviewConfirming: ProgressKpiElement;
  firstInterviewCompletion: ProgressKpiElement;
  secondInterviewSetting: ProgressKpiElement;
  secondInterviewConfirming: ProgressKpiElement;
  secondInterviewCompletion: ProgressKpiElement;
  semiFinalInterviewSetting: ProgressKpiElement;
  semiFinalInterviewConfirming: ProgressKpiElement;
  semiFinalInterviewCompletion: ProgressKpiElement;
  finalInterviewSetting: ProgressKpiElement;
  finalInterviewConfirming: ProgressKpiElement;
  finalInterviewCompletion: ProgressKpiElement;
  waitingStudentDecision: ProgressKpiElement;
  acceptance: ProgressKpiElement;
  declineBeforeEnd: ProgressKpiElement;
  ngBeforeEnd: ProgressKpiElement;

  static empty(): ProgressKpi {
    return {
      raRecommending: null,
      waitingStudentOk: null,
      salesDocumentBeforeSubmit: null,
      salesDocumentWaitingResult: null,
      enterpriseDocumentWaitingSubmit: null,
      enterpriseDocumentWaitingResult: null,
      seminarSetting: null,
      seminarConfirming: null,
      seminarCompletion: null,
      firstInterviewSetting: null,
      firstInterviewConfirming: null,
      firstInterviewCompletion: null,
      secondInterviewSetting: null,
      secondInterviewConfirming: null,
      secondInterviewCompletion: null,
      semiFinalInterviewSetting: null,
      semiFinalInterviewConfirming: null,
      semiFinalInterviewCompletion: null,
      finalInterviewSetting: null,
      finalInterviewConfirming: null,
      finalInterviewCompletion: null,
      waitingStudentDecision: null,
      acceptance: null,
      declineBeforeEnd: null,
      ngBeforeEnd: null,
    };
  }
}

export interface ProgressKpiElement {
  total: number;
  delay: number;
}

export interface FactsAndTargets {
  actual: number;
  target: number;
  rate: number;
}

export interface Cancels {
  total: number;
  amount: number;
}

// Generated by https://quicktype.io

export class EnterprisesKpi {
  total: number;
  totalWithJobs: number;
  progresses: JobsBeforeOperation;
  students: EnterprisesKpiStudents;
  todayInterviews: number;
  tomorrowInterviews: number;
  jobsUnderRecruitment: JobsBeforeOperation;
  jobsInProgress: JobsBeforeOperation;
  jobsBeforeOperation: JobsBeforeOperation;
  jobsInOperation: JobsBeforeOperation;
  jobStatus: JobStatus;
  enterpriseStatus: EnterpriseStatus;
  jobProgressStatus: JobProgressStatus;
  jobYesterdayStatus: JobYesterdayStatus;

  static empty(): EnterprisesKpi {
    return {
      enterpriseStatus: null,
      jobProgressStatus: { setting: null, confirming: null, completion: null },
      jobYesterdayStatus: { recruitment: null, stop: null, end: null },
      jobStatus: null,
      jobsBeforeOperation: null,
      jobsInOperation: null,
      jobsInProgress: null,
      jobsUnderRecruitment: null,
      progresses: null,
      students: null,
      todayInterviews: null,
      tomorrowInterviews: null,
      total: null,
      totalWithJobs: null,
    };
  }
}

export interface JobProgressStatus {
  setting: number;
  confirming: number;
  completion: number;
}

export interface JobYesterdayStatus {
  recruitment: number;
  stop: number;
  end: number;
}
export interface EnterpriseStatus {
  making: number;
  waitingConfirmation: number;
  confirmation: number;
  approachesToRevival: number;
  noAdoption: number;
  suspension: number;
}

export interface JobStatus {
  making: number;
  waitingConfirmation: number;
  recruitment: number;
  stop: number;
  end: number;
}

export interface JobsBeforeOperation {
  agTotal: number;
  agRankA: number;
  agRankB: number;
  agRankC: number;
  plusTotal: number;
}

export interface EnterprisesKpiStudents {
  agTotal: number;
  plusTotal: number;
}

// Generated by https://quicktype.io

export class StudentsKpi {
  students: number;
  progresses: number;
  todayInterviews: number;
  tomorrowInterviews: number;
  studentsIntroductable: StudentsKpiStudents;
  studentsBeforeOperation: StudentsKpiStudents;
  studentsInOperation: StudentsKpiStudents;
  newJobs: number;
  nextActionStudents: number;

  static empty(): StudentsKpi {
    return {
      newJobs: null,
      nextActionStudents: null,
      progresses: null,
      students: null,
      studentsBeforeOperation: null,
      studentsInOperation: null,
      studentsIntroductable: null,
      todayInterviews: null,
      tomorrowInterviews: null,
    };
  }
}

export interface StudentsKpiStudents {
  total: number;
  rankA: number;
  rankB: number;
  others: number;
}
