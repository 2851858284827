import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../components/popup-control/popup-control.component';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ZoneGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLogined().pipe(
      map(() => {
        if (this.authService.authInfo.zone === 'zone3') {
          return true;
        } else {
          PopupControlComponent.instance.open({
            title: null,
            content: 'ZONE3専用です。',
            confirmText: 'OK',
            confirmCallback: () => false,
          });
          return false;
        }
      }),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
