import { ActionMeta, DynamicField, GroupMeta, RowMeta } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { Injectable } from '@angular/core';
import { SalesDetailPageComponent } from '../sales-detail-page.component';

@Injectable()
export abstract class HelperBase {
  constructor(protected dynamicService: DynamicFieldService, protected datePipe: SafeDatePipe) {}

  private counter = 0;

  abstract updateFieldDefinitions(): void;
  abstract getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[];

  public init(detailPage: SalesDetailPageComponent): void {}

  public getMeta(fieldsUpToDate: boolean, approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    if (!fieldsUpToDate) {
      this.updateFieldDefinitions();
    }
    return this.getMetadata(approvalStatus, cancellationStaus);
  }

  protected getDynamicRowOrLabel(
    editable: boolean,
    dynamicField: DynamicField,
    actions?: { action?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] },
    baseKey?: string,
    className?: string,
    formatter?: (data: any) => any,
  ): RowMeta[] {
    if (!dynamicField) {
      return [];
    }
    let res: RowMeta[];
    if (!editable) {
      const key = (baseKey ? baseKey + '.' : '') + dynamicField.fieldName;
      res = [
        {
          title: dynamicField.label,
          fields: [
            {
              type: 'label',
              name: key,
              class: className,
              supplier: (data, callType, getter) => {
                if (dynamicField.unit) {
                  const value = getter(key, true);
                  return value ? (formatter ? formatter(value) + dynamicField.unit : value + dynamicField.unit) : value;
                }
                return data;
              },
            },
          ],
        },
      ];
    } else {
      if (actions) {
        res = this.getFormRowsWith1RowActions(dynamicField, actions, baseKey, className);
      } else {
        res = this.dynamicService.getFormRows(dynamicField, baseKey, className);
      }
    }

    return res;
  }

  protected getFormRowsWith1RowActions(
    dynamicField: DynamicField,
    actionsMeta?: { action?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] },
    baseKey?: string,
    className?: string,
  ): RowMeta[] {
    if (!dynamicField) {
      return [];
    }
    const res: RowMeta[] = this.dynamicService.getFormRows(dynamicField, baseKey, className);

    if (actionsMeta && actionsMeta.subscribers && res[0]) {
      actionsMeta.subscribers.push(res[0]);
    }
    return res;
  }

  protected setupAction(actionsMeta: { actions?: ActionMeta[]; sameBlock?: boolean; overwrite?: boolean; subscribers: RowMeta[] }): void {
    if (actionsMeta && actionsMeta.sameBlock) {
      if (actionsMeta.subscribers) {
        actionsMeta.subscribers.forEach((row) => {
          if (row.fields && row.fields.length) {
            row.fields[row.fields.length - 1].actions =
              row.fields[row.fields.length - 1].actions && !actionsMeta.overwrite
                ? row.fields[row.fields.length - 1].actions.concat(actionsMeta.actions)
                : actionsMeta.actions;
          }
        });
      }
    } else {
      // create another field in the same row
      if (actionsMeta && actionsMeta.subscribers) {
        actionsMeta.subscribers.forEach((row) => {
          if (row.fields) {
            row.fields.push({
              name: 'action-field-' + this.counter++,
              type: 'label',
              actions: actionsMeta.actions,
            });
          }
        });
      }
    }
  }

  // removes actions (extra buttons) from dynamic form field elements added via setupAction(). This method assumes, the user has not changed
  // values of .sameBlock or .actions since the actions were set up, otherwise it is likely to fail.
  protected removeAction(actionsMeta: { actions?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] }): void {
    if (actionsMeta && actionsMeta.sameBlock) {
      if (actionsMeta.subscribers) {
        actionsMeta.subscribers.forEach((row) => {
          if (row.fields.length) {
            if (row.fields[row.fields.length - 1].actions) {
              row.fields[row.fields.length - 1].actions = row.fields[row.fields.length - 1].actions.filter(
                // keep only those actions, which are not the same
                (item) => !actionsMeta.actions.find((action) => action === item),
              );
            }
          }
        });
      }
    } else {
      if (actionsMeta && actionsMeta.subscribers) {
        actionsMeta.subscribers.forEach((row) => {
          row.fields = row.fields.filter(
            (field) => !(field.actions && field.actions.length && actionsMeta.actions.find((action) => action === field.actions[0])),
          );
        });
      }
    }
  }

  protected setupActionProperty(
    actionsMeta: { actions?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] },
    value: ActionMeta[],
  ): void {
    if (actionsMeta) {
      if (actionsMeta.actions) {
        // avoid adding the same action multiple times
        this.removeAction(actionsMeta);
      }
      actionsMeta.actions = value;
      if (actionsMeta.actions) {
        this.setupAction(actionsMeta);
      }
    }
  }
}
