import { deepCompare, getValueFromObject } from './util';

export const arrayUtil = {};

declare global {
  interface Array<T> {
    removeSame(keyCheck?: string): T[];
    flatten(nestedModifier?: (nested: T, level: number) => void, childKey?: string, level?: number, result?: T[]): T[];
    groupBy(key: string): { [key: string]: T[] };
    remove(ref: any, useDeepCompare?: boolean): T[];
    include(ref: any, useDeepCompare?: boolean): T[];
    placeTop(ref: any, useDeepCompare?: boolean): T[];
    last(): T | null;
  }
}

Array.prototype.removeSame = function<T>(keyCheck?: string): T[] {
  const checked = {};
  return this.filter((item: T) => {
    const check = keyCheck ? item[keyCheck] : item;
    if (checked[check]) {
      return false;
    }
    checked[check] = true;
    return true;
  });
};

Array.prototype.flatten = function<T>(
  nestedModifier?: (nested: T, level: number) => void,
  childKey?: string,
  level?: number,
  result?: T[],
): T[] {
  const res = result || [];
  this.forEach((item: T) => {
    if (Array.isArray(item)) {
      item.flatten(nestedModifier, childKey, level || 0, res);
    } else {
      if (nestedModifier) {
        nestedModifier(item, level);
      }
      res.push(item);
      const check = childKey ? item[childKey] : item;
      if (Array.isArray(check)) {
        check.flatten(nestedModifier, childKey, (level || 0) + 1, res);
      }
    }
  });
  return res;
};

Array.prototype.groupBy = function<T>(key: string) {
  return this.reduce((objectsByKeyValue: { [key: string]: T[] }, obj: T) => {
    const value = getValueFromObject(obj, key);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
};

Array.prototype.remove = function<T>(ref: any, useDeepCompare?: boolean): T[] {
  const ind = this.findIndex((item) => item === ref || (useDeepCompare && deepCompare(item, ref)));
  if (ind > -1) {
    this.splice(ind, 1);
  }
  return this;
};

Array.prototype.include = function<T>(ref: any, useDeepCompare?: boolean): T[] {
  const ind = this.findIndex((item) => item === ref || (useDeepCompare && deepCompare(item, ref)));
  if (ind < 0) {
    this.push(ref);
  }
  return this;
};

Array.prototype.placeTop = function<T>(ref: any, useDeepCompare?: boolean): T[] {
  return this.remove(ref, useDeepCompare).push(ref);
};

Array.prototype.last = function<T>(): T | null {
  return this.length ? this[this.length - 1] : undefined;
};
