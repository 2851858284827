import { CompanyDetail, Enterprise, EnterpriseDepartmentUser } from './company';

export class JobSearchParams {
  from?: number;
  size?: number;
  sort?: string;
  order?: 'asc' | 'desc';

  enterpriseId?: number;
  studentId?: number;
  status?: string[];
  keyword?: string;
}

// Generated by https://quicktype.io

export interface JobList {
  total: number;
  jobs: JobListItem[];
}

export interface JobListItem {
  syncTriggerAt: string;
  id: number;
  registeredAt: null;
  //  "@timestamp":             string;
  postingCount: number;
  updatedAt: string;
  deletedAt: null;
  registerUserId: number;
  jobUserFavorite?: JobUserFavorite[];
  salesCount: number;
  dynamicData: any;
  //  enterpriseDepartment:     EnterpriseDepartment[];
  createdAt: string;
  //  "@version":               string;
  enterpriseDepartmentId: number;
  lastUpdatedAt: null;
  //  enterprise:               Enterprise[];
  enterpriseDepartmentUser: EnterpriseDepartmentUser[];
  enterpriseDepartmentUsers: EnterpriseDepartmentUser[];
  type: string;
  postedAt: null;
  updateUserId: number;
  frontJobId: string;
  frontEnterpriseId?: string;
  enterpriseId?: number;
  enterpriseName?: string;
  companyClassification?: string;
  //  tags?:                    Tag[];
  favorite?: boolean; // this is a calculated field in the frontend - using job.dynamicdata.jobUserFavorites
  ribbon?: number; // this is a calculated field in the frontend - using lastUpdatedAt and status fields
}

export interface JobFavoriteListItem {
  id: number;
  frontId: string;
  enterpriseId: number;
  frontEnterpriseId: string;
  enterpriseName: string;
  enterpriseDepartmentId: number;
  enterpriseDepartmentUsers: any[];
  companyClassification: string;
  segs8CurrentYear: string;
  dynamicData: any;
  postingCount: number;
  postedAt: null;
  registeredAt: string;
  registerUserId: number;
  lastUpdatedAt: string;
  updateUserId: number;
}

export interface JobUserFavorite {
  jobId?: number;
  id?: number;
  deletedAt?: null;
  userId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Job {
  id: number;
  frontId: string;
  enterpriseId: number;
  frontEnterpriseId: string;
  enterpriseName: string;
  enterpriseDepartmentId: number;
  enterpriseDepartmentUsers: { id: number; userId: number; type: number }[];
  enterprise: Enterprise;
  company: CompanyDetail;
  companyClassification: string;
  segs8LastYear: string;
  segs8CurrentYear: string;
  dynamicData: any;
  registeredAt: Date;
  registereUserId: number;
  lastUpdatedAt: Date;
  updateUserId: number;
  postingCount: number;
  postedAt: number;
  jobUsers: {
    id: number;
    enterpriseDepartmentUserId: number;
    type?: number;
    userId?: number;
  }[];
}

export interface JobAttachmentHistory {
  id: number;
  studentId: string;
  mailId: number;
  name: string;
}

export interface JobType {
  id: number;
  code: string;
  name: string;
  jobTypes: JobType[];
}

export interface JobSuggestResponse {
  id: number;
  frontId: string;
  position: string;
  enterpriseName: string;
  enterpriseId: number;
  frontEnterpriseId: string;
  enterpriseDepartmentId: number;
}

export interface JobUserCheckResponse {
  jobId: number;
  frontJobId: string;
  position: string;
  jobUsers: {
    id: number;
    enterpriseDepartmentUserId: number;
    type?: number;
    userId?: number;
  }[];
  status: string[];
}
