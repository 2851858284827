import { SalesListComponent } from '@agent-ds/shared/components/sales-list/sales-list.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SalesDetailPageComponent } from '../sales-detail-page/sales-detail-page.component';

@Component({
  selector: 'ag-sales-list-page',
  templateUrl: './sales-list-page.component.html',
  styleUrls: ['./sales-list-page.component.scss'],
})
export class SalesListPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild(SalesListComponent, { static: false }) list: SalesListComponent;
  protected readonly model = 'sales';
  protected readonly detail = SalesDetailPageComponent as any;
  protected readonly exportModel = null;
  private loginSubscription: Subscription;

  exportInProgress: boolean;
  isIntakeCa = false;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
    protected authService: AuthService,
  ) {
    super(router, location, activeRoute, exportService);
    // 副担当者のログインかどうかを確認
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      this.isIntakeCa = this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  runExport(): void {
    if (this.list.table.allChecked || (this.list.table.checkedItems && this.list.table.checkedItems.length)) {
      this.exportService
        .exportSales(this.list.table.checkedItems.map((item) => item.id))
        .subscribe(() => (this.exportInProgress = false), () => (this.exportInProgress = false));
    } else {
      this.exportService
        .exportSales(null, this.searchObj)
        .subscribe(() => (this.exportInProgress = false), () => (this.exportInProgress = false));
    }
  }
}
