import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { PopupConfig } from './popup-interface';

@Component({
  selector: 'ag-popup-control',
  templateUrl: './popup-control.component.html',
  styleUrls: ['./popup-control.component.scss'],
})
export class PopupControlComponent implements OnInit {
  private static INSTANCE: PopupControlComponent;
  public static get instance(): PopupControlComponent {
    return PopupControlComponent.INSTANCE;
  }

  private static SUBINSTANCE: PopupControlComponent;
  public static get subInstance(): PopupControlComponent {
    return PopupControlComponent.SUBINSTANCE;
  }

  @Output() closed = new EventEmitter();
  private innerConfig: PopupConfig;
  private innerOutlet: Portal<any>;
  private inCloseFlag: boolean;

  public static closeAll(): void {
    PopupControlComponent.INSTANCE.close();
    PopupControlComponent.SUBINSTANCE.close();
  }

  constructor(private elementRef: ElementRef<HTMLElement>, private viewContainerRef: ViewContainerRef) {
    if (!PopupControlComponent.INSTANCE) {
      PopupControlComponent.INSTANCE = this;
    } else if (!PopupControlComponent.SUBINSTANCE) {
      PopupControlComponent.SUBINSTANCE = this;
    }
  }

  @HostListener('keydown', ['$event'])
  keyHandler(event: KeyboardEvent): void {
    if (!this.isOpen) {
      return;
    }
    if (event.code === 'Escape') {
      event.stopPropagation();
      this.close();
    } else if (event.code === 'Enter' && !event['isComposing'] && (!this.config.confirmEnabled || this.config.confirmEnabled())) {
      event.stopPropagation();
      this.confirm(this.config.confirmCallback);
    }
  }

  public get config(): PopupConfig {
    return this.innerConfig;
  }
  public get outlet(): Portal<any> {
    return this.innerOutlet;
  }
  get isText(): boolean {
    return this.config.content && typeof this.config.content === 'string';
  }
  get confirmText(): string {
    return typeof this.config.confirmText === 'function' ? this.config.confirmText() : this.config.confirmText;
  }

  get inClose(): boolean {
    return this.inCloseFlag;
  }

  get isOpen(): boolean {
    return this.elementRef.nativeElement.className.includes('active');
  }

  ngOnInit() {
    this.elementRef.nativeElement.tabIndex = -1;
  }

  open(config: PopupConfig): void {
    this.innerConfig = config;
    this.inCloseFlag = false;
    if (!this.config || !this.config.content || typeof this.config.content === 'string') {
      this.innerOutlet = null;
    } else if (this.config.content instanceof TemplateRef) {
      this.innerOutlet = new TemplatePortal(this.config.content, this.viewContainerRef);
    } else {
      this.innerOutlet = new ComponentPortal(this.config.content);
    }
    if (!this.isOpen) {
      this.elementRef.nativeElement.className += ' active';
    }
    this.elementRef.nativeElement.focus();
  }

  close(callbackOnly = false): void {
    if (this.isOpen) {
      if (!callbackOnly) {
        this.elementRef.nativeElement.className = this.elementRef.nativeElement.className.replace(/ ?active/g, '');
        this.closed.emit();
        this.innerOutlet = null;
      }
      if (this.config && this.config.closeCallback) {
        this.config.closeCallback();
      }
    }
  }

  cancel(): void {
    if (this.config.cancelCallback) {
      this.config.cancelCallback();
    }
    this.close();
  }

  confirm(confirmCallback?: () => boolean | void | Observable<any>): void {
    if (this.inClose) {
      return;
    }
    this.inCloseFlag = true;
    const ret = (confirmCallback || this.config.confirmCallback)();
    if (ret instanceof Observable) {
      ret.subscribe(
        () => {
          this.close(!this.inClose);
          this.inCloseFlag = false;
        },
        () => (this.inCloseFlag = false),
      );
    } else {
      if (!ret) {
        this.close(!this.inClose);
      }
      this.inCloseFlag = false;
    }
  }
}
