import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { TemplateRef } from '@angular/core';

export const FAV_JOBS_TABLE_CONFIG: (
  companyTemplate: TemplateRef<any>,
  positionTemplate: TemplateRef<any>,
  heatTemplate: TemplateRef<any>,
  dateFormatter: (date: any) => string,
) => TableConfig = (companyTemplate, positionTemplate, heatTemplate, dateFormatter) => {
  const resp: TableConfig = {
    head: {
      style: {
        padding: 0,
      },
      sticky: true,
      config: [
        {
          columns: [
            {
              fields: [
                {
                  name: 'status',
                  title: '求人ステータス',
                  formatter: (data) => (data.dynamicData ? data.dynamicData.status : ''),
                },
              ],
              style: {
                width: '114px',
                'padding-right': '20px',
                'padding-top': '6px',
                'padding-bottom': '6px',
              },
              bodyStyle: {
                'padding-top': '5px',
                'padding-bottom': '6.5px',
              },
            },
            {
              fields: [
                {
                  name: 'recommendationDateKind',
                  title: '熱度',
                  cellTemplate: heatTemplate,
                  formatter: (data) => (data.dynamicData ? data.dynamicData.recommendationDateKind : ''),
                },
              ],
              style: {
                width: '90px',
              },
            },
            {
              fields: [
                {
                  name: 'enterpriseName',
                  title: '企業名',
                  cellTemplate: companyTemplate,
                },
              ],
              style: {
                width: '405px',
                'padding-right': '20px',
                'padding-top': '6px',
                'padding-bottom': '6.5px',
              },
              bodyStyle: {
                'padding-top': '5px',
                'padding-bottom': '5px',
              },
            },
            {
              fields: [
                {
                  name: 'position',
                  title: 'ポジション名',
                  cellTemplate: positionTemplate,
                  formatter: (data) => (data.dynamicData ? data.dynamicData.position : ''),
                },
              ],
              style: {
                flex: 1,
                'padding-right': '20px',
                'padding-top': '6px',
                'padding-bottom': '6.5px',
              },
              bodyStyle: {
                'padding-top': '5px',
                'padding-bottom': '5px',
                'min-width': '1px',
              },
            },
            {
              fields: [
                {
                  name: 'registeredAt',
                  title: '登録日',
                  style: {
                    'margin-bottom': '1px',
                  },
                  defaultSort: SortOrder.DESCENDING,
                  formatter: (data) => (data.registeredAt ? dateFormatter(data.registeredAt) : ''),
                },
              ],
              style: {
                width: '80px',
                'padding-right': '20px',
                'padding-top': '6px',
                'padding-bottom': '6.5px',
              },
              bodyStyle: {
                'padding-top': '5px',
                'padding-bottom': '5px',
              },
            },
          ],
          dynamicStyle: {
            'min-height': () => '50px',
          },
        },
      ],
    },
    body: {
      checkbox: true,
    },
  };
  return resp;
};
