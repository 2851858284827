import { APPROVAL_CANCEL_STATES, APPROVAL_STATES } from '@agent-ds/shared/constants';
import { DynamicField, FormMeta, RowMeta } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { deepClone, multiFill } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-sales-search',
  templateUrl: './sales-search.component.html',
  styleUrls: ['./sales-search.component.scss'],
})
export class SalesSearchComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();
  @Output() search = new EventEmitter<{}>();

  innerSearchModel: any = {};

  get searchModel(): any {
    return this.innerSearchModel;
  }

  @Input()
  set searchModel(model: any) {
    this.innerSearchModel = { ...model };
  }

  private fieldSubscription: Subscription;

  metadataLeft: FormMeta = { groups: [] };
  metadataRight: FormMeta = { groups: [] };

  constructor(private dynamicService: DynamicFieldService) {}

  getMultiDropdownField(field: DynamicField): RowMeta {
    const row: RowMeta = this.dynamicService.getFormRows(field)[0];
    row.fields[0].valueField = 'code';
    row.fields[0].labelField = 'name';
    return row;
  }

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataLeft = {
        groups: [
          {
            title: '求職者担当',
            class: 'form__group--golden-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'caUserTeamId',
                fieldType: 'multi-team',
                displayType: 'dropdown',
                label: '担当チーム',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'caUserId',
                fieldType: 'multi-user',
                displayType: 'multi-user',
                label: '担当者',
              }),
            ],
          },
          {
            title: '承認区分',
            class: 'form__group--golden-title-border',
            rows: [
              this.getMultiDropdownField({
                label: '承認区分',
                fieldType: 'multi-list',
                displayType: 'dropdown',
                fieldName: 'status',
                validationStyle: { options: APPROVAL_STATES },
              }),
              this.getMultiDropdownField({
                label: 'キャンセル承認区分',
                fieldType: 'multi-list',
                displayType: 'dropdown',
                fieldName: 'cancelStatus',
                validationStyle: { options: APPROVAL_CANCEL_STATES },
              }),
            ],
          },
          {
            title: '企業・求人・求職者情報',
            class: 'form__group--golden-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'enterpriseId',
                fieldType: 'multi-enterprise',
                displayType: 'multi-enterprise',
                label: '企業',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'jobId',
                fieldType: 'multi-job',
                displayType: 'multi-job',
                label: '求人',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'studentId',
                fieldType: 'multi-student',
                displayType: 'multi-student',
                label: '求職者',
              }),
            ],
          },
        ],
      };
      this.metadataRight = {
        groups: [
          {
            title: '求人担当',
            class: 'form__group--golden-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'paraUserTeamId',
                fieldType: 'multi-team',
                displayType: 'dropdown',
                label: '担当チーム',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'paraUserId',
                fieldType: 'multi-user',
                displayType: 'multi-user',
                label: '担当者',
              }),
            ],
          },
          {
            title: '成約情報',
            class: 'form__group--golden-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'salesId',
                  fieldType: 'number',
                  displayType: 'number',
                  label: '成約ID',
                },
                null,
                'quarter',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('sales', 'invoiceConfirmation'),
                  displayType: 'checkbox',
                  fieldType: 'multi-list',
                }),
                null,
                'medium',
              ),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'billingLinkageStatus')),
            ],
          },
          {
            title: '日付',
            class: 'form__group--golden-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('sales', 'contractDate'),
                  fieldType: 'date-range',
                  displayType: 'date-advanced',
                }),
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('sales', 'enterDate'),
                  fieldType: 'date-range',
                  displayType: 'date-advanced',
                }),
              ),
              ...this.dynamicService.getFormRows({
                fieldName: 'statusApprovedDate',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '承認日',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'cancelStatusApprovedDate',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: 'キャンセル承認日',
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'salesOrCancelApprovedDate',
                fieldType: 'date-range',
                displayType: 'date-advanced',
                label: '成約日・\nキャンセル承認日',
              }),
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onCloseClick() {
    this.closed.emit();
  }

  public doSearch(event?: any): void {
    if (!event || !event['isComposing']) {
      this.search.emit({ ...this.searchModel });
    }
  }
}
