import { SalesAmount } from '@agent-ds/shared/interfaces/sale';
import { MasterApiService, SalesApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-sales-report-page',
  templateUrl: './sales-report-page.component.html',
  styleUrls: ['./sales-report-page.component.scss'],
})
export class SalesReportPageComponent implements OnInit {
  constructor(
    private salesService: SalesApiService,
    public readonly masterApiService: MasterApiService,
    public readonly userTeamInjector: UserTeamInjectorProvider,
  ) {}

  private condition: {
    contractDate?: { from?: string; to?: string };
    interviewDate?: { from?: string; to?: string };
  } = {};
  model: SalesAmount = { ca: { users: [], total: null }, rapa: { users: [], total: null } };

  ngOnInit() {}

  updateReportView(condition: any): void {
    this.condition = condition;
    this.salesService.getReport(condition).subscribe((salesAmount: SalesAmount) => {
      this.model = salesAmount;
    });
  }

  getSearchQueryParams(userId, progressType, agentType, canceled) {
    const params: any = {
      hasProgress: 1,
      progressType: progressType,
      status: 2,
    };
    if (agentType === 1) {
      params.paraUserId = userId;
    }
    if (agentType === 3) {
      params.caUserId = userId;
    }
    if (this.condition.contractDate) {
      params.contractOrCancelDateFrom = this.condition.contractDate.from;
      params.contractOrCancelDateTo = this.condition.contractDate.to;
    }
    if (this.condition.interviewDate) {
      params.interviewDateFrom = this.condition.interviewDate.from;
      params.interviewDateTo = this.condition.interviewDate.to;
    }
    if (canceled) {
      params.cancelStatus = 3;
    }
    return params;
  }
}
