import { SituationMemo } from '@agent-ds/shared/interfaces';
import { CompanyApiService, JobApiService, StudentApiService } from '@agent-ds/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-memo-list',
  templateUrl: './memo-list.component.html',
  styleUrls: ['./memo-list.component.scss'],
})
export class MemoListComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() service: StudentApiService | CompanyApiService | JobApiService;
  @Input() referenceId: number;
  @Input() newText: string;
  @Input() title: string;
  @Output() errorGetMemos: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  isCreateNewMemoInProgress = false;
  memos: SituationMemo[] = [];
  private refreshEventSubscription: Subscription;

  constructor() {}

  ngAfterViewInit() {
    this.refreshEventSubscription = this.service.refreshEvent.subscribe(() => this.getMemos());
  }

  ngOnDestroy() {
    if (this.refreshEventSubscription) {
      this.refreshEventSubscription.unsubscribe();
      this.refreshEventSubscription = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referenceId']) {
      this.getMemos();
    }
  }

  createNewMemo() {
    this.isCreateNewMemoInProgress = true;
  }

  cancelNewMemo() {
    this.isCreateNewMemoInProgress = false;
  }

  getMemos() {
    if (this.referenceId) {
      this.memos = [];
      this.service.getMemos(this.referenceId).subscribe(
        (memos: SituationMemo[]) => (this.memos = memos),
        (error: HttpErrorResponse) => {
          this.errorGetMemos.emit(error);
        },
      );
    }
  }

  removeMemo(index: number) {
    this.memos.splice(index, 1);
  }
}
