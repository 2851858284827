/* tslint:disable:max-line-length */
import { CompanyBillingAddressCreateDialogComponent } from './company-billing-address-create-dialog/company-billing-address-create-dialog.component';
import { CompanyContractCreateDialogComponent } from './company-contract-create-dialog/company-contract-create-dialog.component';
import { CompanyCreateDialogComponent } from './company-create-dialog/company-create-dialog.component';
import { CompanyDepartmentCreateDialogComponent } from './company-department-create-dialog/company-department-create-dialog.component';
import { CompanyDetailHeaderComponent } from './company-detail-header/company-detail-header.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { CompanySeminarAttendanceSendDialogComponent } from './company-seminar-attendance-send-dialog/company-seminar-attendance-send-dialog.component';

export * from './company-department-create-dialog/company-department-create-dialog.component';
export * from './company-seminar-attendance-send-dialog/company-seminar-attendance-send-dialog.component';
export * from './company-billing-address-create-dialog/company-billing-address-create-dialog.component';
export * from './company-contract-create-dialog/company-contract-create-dialog.component';
export * from './company-create-dialog/company-create-dialog.component';

export const COMPANY_COMPONENTS = [CompanyDetailHeaderComponent, CompanySearchComponent];

export const COMPANY_DIALOG_COMPONENTS = [
  CompanySeminarAttendanceSendDialogComponent,
  CompanyBillingAddressCreateDialogComponent,
  CompanyContractCreateDialogComponent,
  CompanyCreateDialogComponent,
  CompanyDepartmentCreateDialogComponent,
];
