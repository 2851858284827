import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'undef',
})
export class UndefinedPipe implements PipeTransform {
  transform(value: any, defaultString: string = '-'): any {
    if (value == null || value === '') {
      return defaultString;
    }
    return value;
  }
}
