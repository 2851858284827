import { AuthGuard } from '@agent-ds/shared/guard';
import { ZoneGuard } from '@agent-ds/shared/guard/zone.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyDetailPageComponent, CompanyImportPageComponent, CompanyListPageComponent } from './pages';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'list', component: CompanyListPageComponent },
      { path: 'search', component: CompanyListPageComponent },
      { path: 'add', component: CompanyListPageComponent },
      { path: 'import', component: CompanyImportPageComponent, canActivate: [ZoneGuard] },
      { path: ':id', component: CompanyDetailPageComponent },
      { path: ':id/:tab', component: CompanyDetailPageComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
