import {
  CounselingBatchResponse,
  Job,
  JobListItem,
  Seminar,
  SeminarCreateRequest,
  SeminarCreateResponse,
  SeminarStudentApplyRequest,
  SeminarStudentSeminarResponse,
  SeminarThanksMailRequest,
  Student,
} from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CounselingApiService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getSeminarsForStudent(studentId: number): Observable<SeminarStudentSeminarResponse[]> {
    return this.http.get<SeminarStudentSeminarResponse[]>(`/api/seminar/student/${studentId}`);
  }

  deleteSeminarForStudent(seminarId: number, studentId: number): Observable<any> {
    return this.http.delete(`/api/seminar/student/${studentId}/seminar/${seminarId}`);
  }

  addSeminarForStudent(studentId: number, seminarId: number): Observable<any> {
    return this.http.post(`/api/seminar/student/${studentId}`, { seminarId: seminarId });
  }

  searchSeminarsForStudent(
    studentId: number,
    params: { area: number; date: string; times: string[]; type?: number },
  ): Observable<Seminar[]> {
    return this.http.get<Seminar[]>(`/api/seminar/student/${studentId}/search`, {
      params: toAsialRequestParameters(params) as any,
    });
  }

  getSeminarJobsForStudent(studentId: number, seminarId: number): Observable<Job[]> {
    return this.http.get<Job[]>(`/api/seminar/student/${studentId}/seminar/${seminarId}/job`);
  }

  addSeminarJobsForStudent(studentId: number, seminarId: number, jobId: number): Observable<any> {
    return this.http.post(`/api/seminar/student/${studentId}/seminar/${seminarId}/job`, { jobId: jobId });
  }

  deleteSeminarJobsForStudent(studentId: number, seminarId: number, jobId: number): Observable<any> {
    return this.http.delete(`/api/seminar/student/${studentId}/seminar/${seminarId}/job/${jobId}`);
  }

  updateSeminarJobApplicationsForStudent(
    studentId: number,
    seminarId: number,
    requestObj: SeminarStudentApplyRequest,
  ): Observable<CounselingBatchResponse & { mailErrorIds?: number[] }> {
    return this.http.put<CounselingBatchResponse & { mailErrorIds?: number[] }>(
      `/api/seminar/student/${studentId}/seminar/${seminarId}/application`,
      requestObj,
    );
  }

  getSeminars(area: number, startDate: string, endDate: string): Observable<Seminar[]> {
    return this.http.get<Seminar[]>('/api/seminar', {
      params: {
        area: area,
        startDate: new Date(startDate).toAsialDateString(true),
        endDate: new Date(endDate).toAsialDateString(true),
      } as any,
    });
  }

  addSeminar(requestObj: SeminarCreateRequest): Observable<SeminarCreateResponse> {
    return this.http.post<SeminarCreateResponse>('/api/seminar', requestObj);
  }

  deleteSeminar(seminarId: number): Observable<any> {
    return this.http.delete(`/api/seminar/${seminarId}`);
  }

  deleteSeminars(seminarIds: number[]): Observable<CounselingBatchResponse> {
    return this.http.request<CounselingBatchResponse>('delete', '/api/seminar', {
      params: toAsialRequestParameters({ seminarIds: seminarIds }) as any,
    });
  }

  getSeminarMasterJobs(areaId: number): Observable<JobListItem[]> {
    return this.http.get<JobListItem[]>(`/api/seminar/job-master/${areaId}`);
  }

  addSeminarMasterJob(areaId: number, jobId: number): Observable<any> {
    return this.http.post(`/api/seminar/job-master/${areaId}`, { jobId: jobId });
  }

  deleteSeminarMasterJob(areaId: number, jobId: number): Observable<any> {
    return this.http.delete(`/api/seminar/job-master/${areaId}/${jobId}`);
  }

  deleteSeminarMasterJobs(areaId: number, jobIds: number[]): Observable<CounselingBatchResponse> {
    return this.http.request<CounselingBatchResponse>('delete', `/api/seminar/job-master/${areaId}`, {
      params: toAsialRequestParameters({ jobIds: jobIds }) as any,
    });
  }

  getSeminarJobs(seminarId: number): Observable<JobListItem[]> {
    return this.http.get<JobListItem[]>(`/api/seminar/${seminarId}/job`);
  }

  addSeminarJob(seminarId: number, jobId: number): Observable<any> {
    return this.http.post(`/api/seminar/${seminarId}/job`, { jobId: jobId });
  }

  deleteSeminarJob(seminarId: number, jobId: number): Observable<any> {
    return this.http.delete(`/api/seminar/${seminarId}/job/${jobId}`);
  }

  deleteSeminarJobs(seminarId: number, jobIds: number[]): Observable<CounselingBatchResponse> {
    return this.http.request<CounselingBatchResponse>('delete', `/api/seminar/${seminarId}/job`, {
      params: toAsialRequestParameters({ jobIds: jobIds }) as any,
    });
  }

  getStudentsForSeminar(seminarId: number): Observable<Student[]> {
    return this.http.get<Student[]>(`/api/seminar/${seminarId}/student`);
  }

  addStudentsThanksMailForSeminar(seminarId: number, requestObj: SeminarThanksMailRequest): Observable<CounselingBatchResponse> {
    return this.http.post<CounselingBatchResponse>(`/api/seminar/${seminarId}/student/thanks-mail`, requestObj);
  }
}
