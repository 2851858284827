import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SideActionConfig } from './page-floater-interface';
/*
  This widget needs a relative positioned container to calculate it's dimensions properly.
  The container can be any parent (ex. the router container div.content)
 */
@Component({
  selector: 'ag-page-floater',
  templateUrl: './page-floater.component.html',
  styleUrls: ['./page-floater.component.scss'],
})
export class PageFloaterComponent implements OnInit, OnDestroy {
  private static readonly INSTANCES: PageFloaterComponent[] = [];
  private static readonly DEFAULT_ZINDEX = 5000;
  private static readonly INSTANCES_CHANGE = new EventEmitter();

  @Input() closeOnShrink = false;
  @Input() sideActions: SideActionConfig[] | null = null;
  @Input() origUrl?: string;
  @Input() fullModeUrl?: string;
  @Input() disableFullScreen: boolean;
  @Output() selectedSideActionIndexChange = new EventEmitter<number>();
  @Output() transformed = new EventEmitter<string>();

  private innerSelectedSideActionIndex = 0;
  private innerPhaseIndex = -1;

  private instanceChangeSubscription: Subscription;

  public static closeAll(): void {
    [...PageFloaterComponent.INSTANCES].forEach((ins) => ins.close());
  }

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.host.nativeElement.tabIndex = -1;
    this.host.nativeElement.style.visibility = 'hidden';
    this.host.nativeElement.style.width = '0px';
    this.host.nativeElement.style.height = '0px';
    this.host.nativeElement.style.zIndex = PageFloaterComponent.DEFAULT_ZINDEX + '';
    this.instanceChangeSubscription = PageFloaterComponent.INSTANCES_CHANGE.subscribe(() => this.updateZindex());
  }

  ngOnDestroy() {
    this.instanceChangeSubscription.unsubscribe();
    this.instanceChangeSubscription = null;
  }

  @HostListener('keydown', ['$event'])
  keyHandler(event: KeyboardEvent): void {
    if (this.innerPhaseIndex < 0) {
      return;
    }
    if (event.code === 'Escape') {
      event.stopPropagation();
      this.close();
    } else if (event.ctrlKey) {
      if (event.key === ']' || event.code === 'BracketRight') {
        event.stopPropagation();
        this.shrink();
      } else if (event.key === '[' || event.code === 'BracketLeft') {
        if (event.shiftKey) {
          if (this.fullModeUrl) {
            event.stopPropagation();
            window.open(this.fullModeUrl);
          }
        } else {
          event.stopPropagation();
          this.grow();
        }
      }
    }
  }

  public get phaseIndex(): number {
    return this.innerPhaseIndex;
  }

  public get selectedSideActionIndex(): number | null {
    if (this.innerSelectedSideActionIndex === null && this.sideActions && this.sideActions.length) {
      return 0;
    }
    return this.innerSelectedSideActionIndex !== null ? this.innerSelectedSideActionIndex : null;
  }

  @Input()
  public set selectedSideActionIndex(index: number) {
    if (index === this.innerSelectedSideActionIndex) {
      return;
    }
    this.innerSelectedSideActionIndex = index;
    this.selectedSideActionIndexChange.emit(index);
    this.move(this.phases[0]);
  }

  public get phases(): string[] {
    return this.sideActions &&
      this.sideActions.length &&
      this.sideActions[this.selectedSideActionIndex] &&
      this.sideActions[this.selectedSideActionIndex].phases
      ? this.sideActions[this.selectedSideActionIndex].phases
      : [];
  }

  grow(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    if (this.disableFullScreen && this.phases[this.innerPhaseIndex + 1] === '100%') {
      return;
    }
    this.innerPhaseIndex++;
    this.host.nativeElement.style.visibility = '';
    this.host.nativeElement.style.height = '';
    this.move(this.phases[this.innerPhaseIndex] || this.phases[this.phases.length - 1]);
  }

  shrink(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    if (this.closeOnShrink) {
      this.innerPhaseIndex = 0;
    }
    if (--this.innerPhaseIndex < 0) {
      this.host.nativeElement.style.visibility = 'hidden';
      this.host.nativeElement.style.height = '0px';
    }
    this.move(this.phases[this.innerPhaseIndex] || '0px');

    if (this.innerPhaseIndex < 0) {
      this.innerSelectedSideActionIndex = 0;
      this.selectedSideActionIndexChange.emit(0);
      PageFloaterComponent.INSTANCES.remove(this);
      PageFloaterComponent.INSTANCES_CHANGE.emit();
    }
  }

  public first(): void {
    if (this.innerPhaseIndex < 0) {
      PageFloaterComponent.INSTANCES.placeTop(this);
      PageFloaterComponent.INSTANCES_CHANGE.emit();
    }
    this.innerPhaseIndex = -1;
    this.grow();
  }

  public last(): void {
    if (this.innerPhaseIndex < 0) {
      PageFloaterComponent.INSTANCES.placeTop(this);
      PageFloaterComponent.INSTANCES_CHANGE.emit();
    }
    this.innerPhaseIndex = this.phases.length - 2;
    this.grow();
  }

  public open(): void {
    if (this.innerPhaseIndex < 0) {
      this.grow();
    }
    PageFloaterComponent.INSTANCES.placeTop(this);
    PageFloaterComponent.INSTANCES_CHANGE.emit();
  }

  public close(): void {
    if (this.innerPhaseIndex > -1) {
      this.innerPhaseIndex = 0;
      this.shrink();
      PageFloaterComponent.INSTANCES.remove(this);
      PageFloaterComponent.INSTANCES_CHANGE.emit();
    }
  }

  private move(phase: string): void {
    this.host.nativeElement.style.width = phase;
    this.host.nativeElement.focus();
    this.transformed.emit(this.host.nativeElement.style.width);
  }

  private updateZindex(): void {
    this.host.nativeElement.style.zIndex = PageFloaterComponent.DEFAULT_ZINDEX + PageFloaterComponent.INSTANCES.indexOf(this) + 1 + '';
  }
}
