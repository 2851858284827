import { Tab } from '@agent-ds/shared/models';
import { CompanyApiService } from '@agent-ds/shared/services';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'ag-company-contact-history-tab',
  templateUrl: './company-contact-history-tab.component.html',
  styleUrls: ['./company-contact-history-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyContactHistoryTabComponent) }],
})
export class CompanyContactHistoryTabComponent extends Tab {
  @Input() companyId: number;

  constructor(public readonly companyApiService: CompanyApiService) {
    super();
  }
}
