import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeDate',
})
export class SafeDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    try {
      return super.transform(value, format, timezone, locale);
    } catch (e) {
      console.warn('param is not a valid date representation');
      return '';
    }
  }
}
