import { DynamicField } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { Component, OnInit } from '@angular/core';
import { first, tap } from 'rxjs/operators';

enum TypeOptionKey {
  SINGLE_LINE_TEXT = '単一行テキスト',
  MULTI_LINE_TEXT = '複数行テキスト',
  NUMBER = '数値',
  SINGLE_DROPDOWN = '単一選択（ドロップダウン）',
  SINGLE_RADIO = '単一選択（ラジオボタン）',
  MULTI_DROPDOWN = '複数選択（ドロップダウン）',
  SINGLE_CHECKBOX = '複数選択（チェックボックス）',
  DATE = '日付',
}
interface DynamicFieldEdit {
  isEdit: boolean;
  allOptions: string[];
  newOption: string;
  newLabel: string;
  isSearchable: boolean;
  type: string;
  isUpdateInProgress: boolean;
  model: string;
}

@Component({
  selector: 'ag-settings-custom-fields-page',
  templateUrl: './settings-custom-fields-page.component.html',
  styleUrls: ['./settings-custom-fields-page.component.scss'],
})
export class SettingsCustomFieldsPageComponent implements OnInit {
  studentFields: DynamicField[] = [];
  enterpriseFields: DynamicField[] = [];
  jobFields: DynamicField[] = [];
  editors: {
    [id: number]: DynamicFieldEdit;
  } = {};
  readonly NEW_FIELD_EDITOR_KEY = {
    student: 'NEW_STUDENT_FIELD',
    enterprise: 'NEW_ENTERPRISE_FIELD',
    job: 'NEW_JOB_FIELD',
  };
  readonly TYPE_OPTIONS = {
    [TypeOptionKey.SINGLE_LINE_TEXT]: { fieldType: 'text', displayType: 'text' },
    [TypeOptionKey.MULTI_LINE_TEXT]: { fieldType: 'text', displayType: 'textarea' },
    [TypeOptionKey.NUMBER]: { fieldType: 'number', displayType: 'number' },
    [TypeOptionKey.SINGLE_DROPDOWN]: { fieldType: 'list', displayType: 'dropdown' }, //
    [TypeOptionKey.SINGLE_RADIO]: { fieldType: 'list', displayType: 'radio' }, //
    [TypeOptionKey.MULTI_DROPDOWN]: { fieldType: 'multi-list', displayType: 'dropdown' }, //
    [TypeOptionKey.SINGLE_CHECKBOX]: { fieldType: 'multi-list', displayType: 'checkbox' }, //
    [TypeOptionKey.DATE]: { fieldType: 'date', displayType: 'date' },
  };

  constructor(private dynamicFieldService: DynamicFieldService) {}

  ngOnInit() {
    this.dynamicFieldService.fieldUpdateEvent.pipe(first()).subscribe(() => {
      const filter = (field: DynamicField) => field.isCustomField;
      this.studentFields = (this.dynamicFieldService.getDefinitions('student') || []).filter(filter);
      this.enterpriseFields = (this.dynamicFieldService.getDefinitions('enterprise') || []).filter(filter);
      this.jobFields = (this.dynamicFieldService.getDefinitions('job') || []).filter(filter);
    });
  }

  asIsOrder(a: any, b: any): number {
    return 1;
  }

  toType(field: DynamicField): string {
    if (field.fieldType === 'text' && field.displayType === 'text') {
      return TypeOptionKey.SINGLE_LINE_TEXT;
    } else if (field.fieldType === 'text' && field.displayType === 'textarea') {
      return TypeOptionKey.MULTI_LINE_TEXT;
    } else if (field.fieldType === 'number' && field.displayType === 'number') {
      return TypeOptionKey.NUMBER;
    } else if (field.fieldType === 'list' && field.displayType === 'dropdown') {
      return TypeOptionKey.SINGLE_DROPDOWN;
    } else if (field.fieldType === 'list' && field.displayType === 'radio') {
      return TypeOptionKey.SINGLE_RADIO;
    } else if (field.fieldType === 'multi-list' && field.displayType === 'dropdown') {
      return TypeOptionKey.MULTI_DROPDOWN;
    } else if (field.fieldType === 'multi-list' && field.displayType === 'checkbox') {
      return TypeOptionKey.SINGLE_CHECKBOX;
    } else if (field.fieldType === 'date' && field.displayType === 'date') {
      return TypeOptionKey.DATE;
    }
    return null;
  }

  isEditorValid(id: any): boolean {
    if (!this.editors || !this.editors[id]) {
      return false;
    }
    return (
      this.editors[id].newLabel &&
      this.editors[id].newLabel.trim() &&
      this.editors[id].type &&
      this.TYPE_OPTIONS[this.editors[id].type] &&
      (this.TYPE_OPTIONS[this.editors[id].type].fieldType === 'list' || this.TYPE_OPTIONS[this.editors[id].type].fieldType === 'multi-list'
        ? this.editors[id].allOptions && !!this.editors[id].allOptions.length
        : true)
    );
  }

  onSave(field: DynamicField, editor: DynamicFieldEdit): void {
    if (editor.isUpdateInProgress || !this.isEditorValid(field.id)) {
      return;
    }
    editor.isUpdateInProgress = true;
    let requestObj: any = {
      model: field.model,
      fieldType: this.TYPE_OPTIONS[editor.type].fieldType,
      displayType: this.TYPE_OPTIONS[editor.type].displayType,
      label: editor.newLabel.trim(),
      isSearchable: editor.isSearchable ? 1 : 0,
    };
    if (
      this.TYPE_OPTIONS[this.editors[field.id].type].fieldType === 'list' ||
      this.TYPE_OPTIONS[this.editors[field.id].type].fieldType === 'multi-list'
    ) {
      requestObj = { ...requestObj, validationStyle: { options: editor.allOptions } };
    }
    const request =
      (field.id as number | string) === this.NEW_FIELD_EDITOR_KEY.student ||
      (field.id as number | string) === this.NEW_FIELD_EDITOR_KEY.enterprise ||
      (field.id as number | string) === this.NEW_FIELD_EDITOR_KEY.job
        ? this.dynamicFieldService.addDefinition(requestObj).pipe(tap(() => (editor.isEdit = false)))
        : this.dynamicFieldService.updateDefinition(field.id, requestObj);
    request.subscribe(
      (updatedDynamicField: DynamicField) => {
        editor.newOption = '';
        editor.isUpdateInProgress = false;
        const fields =
          field.model === 'student'
            ? this.studentFields
            : field.model === 'enterprise'
            ? this.enterpriseFields
            : field.model === 'job'
            ? this.jobFields
            : [];
        const index = fields.findIndex((f) => f.id === field.id);
        if (index > -1) {
          fields[index] = { ...updatedDynamicField };
        } else {
          fields.push(updatedDynamicField);
        }
        editor.isEdit = false;
      },
      () => {
        editor.isUpdateInProgress = false;
      },
    );
  }

  toggleIsSearchable(field: DynamicField): void {
    this.editors[field.id] = {
      ...this.editors[field.id],
      isUpdateInProgress: true,
    };
    this.dynamicFieldService
      .updateDefinition(field.id, {
        fieldType: field.fieldType,
        label: field.label,
        displayType: field.displayType,
        validationStyle: field.validationStyle,
        isSearchable: field.isSearchable ? 0 : 1,
      })
      .subscribe(
        (updatedDynamicField: DynamicField) => {
          this.editors[field.id].isUpdateInProgress = false;
          const fields =
            field.model === 'student'
              ? this.studentFields
              : field.model === 'enterprise'
              ? this.enterpriseFields
              : field.model === 'job'
              ? this.jobFields
              : [];
          const index = fields.findIndex((f) => f.id === field.id);
          if (index > -1) {
            fields[index] = { ...updatedDynamicField };
          }
        },
        () => {
          this.editors[field.id].isUpdateInProgress = false;
        },
      );
  }
}
