import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SHARED_COMPONENTS, SHARED_DIALOG_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { CORE_HTTP_INTERCEPTORS } from './interceptors';
import { SHARED_PAGES } from './pages';
import { SHARED_PIPES } from './pipes';
import { UserTeamInjectorProvider } from './services/api/providers/user-team-injector.provider';
export * from './util';

@NgModule({
  declarations: [SHARED_COMPONENTS, SHARED_PAGES, SHARED_PIPES, SHARED_DIRECTIVES, SHARED_DIALOG_COMPONENTS],
  exports: [
    CommonModule,
    FormsModule,
    TextFieldModule,
    SHARED_COMPONENTS,
    SHARED_PAGES,
    SHARED_PIPES,
    SHARED_DIRECTIVES,
    ReactiveFormsModule,
  ],
  imports: [CommonModule, RouterModule, ScrollingModule, PortalModule, FormsModule, TextFieldModule, ReactiveFormsModule],
  providers: [CORE_HTTP_INTERCEPTORS, SHARED_PIPES, DecimalPipe, UserTeamInjectorProvider, DatePipe],
  entryComponents: [SHARED_DIALOG_COMPONENTS],
})
export class SharedModule {}
