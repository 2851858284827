import { Injectable } from '@angular/core';
import { Toast } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private readonly toastRefs: Toast[] = [];
  private readonly TIMEOUT = 10000;
  private readonly timeouts: { [key: string]: any } = {};

  constructor() {}

  public get toasts(): Toast[] {
    return this.toastRefs;
  }

  public addToast(toast: Toast): void {
    this.removeToast(toast.key);
    this.toastRefs.push(toast);
    this.timeouts[toast.key] = setTimeout(() => this.removeToast(toast.key), this.TIMEOUT);
  }

  public removeToast(key: string): void {
    const existingIndex = this.toastRefs.findIndex((t) => t.key === key);
    if (existingIndex > -1) {
      clearTimeout(this.timeouts[key]);
      this.toastRefs.splice(existingIndex, 1);
    }
  }
}
