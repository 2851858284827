import { AuthGuard, USAGE_MODE } from '@agent-ds/shared/guard';
import { PageInfo } from '@agent-ds/shared/interfaces';
import { Component, Input } from '@angular/core';

/**
 * レイアウト
 */
@Component({
  selector: 'ag-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  useLayout = true;

  @Input()
  set pageInfo(pageInfo: PageInfo) {
    this.useLayout = (pageInfo ? pageInfo.layout : true) && AuthGuard.CURRENT_USAGE === USAGE_MODE.APP;
  }

  constructor() {}
}
