import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';
import { ConfirmDialogConfig } from './confirm-dialog-config';

@Component({
  selector: 'ag-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  providers: [{ provide: ConfirmDialogConfig, useExisting: forwardRef(() => DialogConfig) }],
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  confirmSubscription: Subscription;

  constructor(public dialog: DialogRef, public config: ConfirmDialogConfig) {}
  public close(result?) {
    this.dialog.close(result);
  }
  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.close(true));
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }
}
