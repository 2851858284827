import { getSeminarTypeLabel } from '@agent-ds/shared/constants/progress-actions';
import { ProgressBulkEditAction } from '@agent-ds/shared/interfaces';

export const PROGRESS_TRANSITIONS: { [fromStatus: number]: { [toStatus: number]: ProgressBulkEditAction } } = {
  40: {
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 40,
      toStatus: 50,
      transitionNumber: 3,
    },
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 40,
      toStatus: 70,
      transitionNumber: 1,
    },
    100: {
      label: (seminarType, n) => getSeminarTypeLabel('面接設定中', seminarType, n || 1),
      fromStatus: 40,
      toStatus: 100,
      transitionNumber: 2,
    },
    170: {
      label: () => 'NG連絡前',
      fromStatus: 40,
      toStatus: 170,
      transitionNumber: null,
      needsReason: true,
    },
  },
  50: {
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 50,
      toStatus: 70,
      transitionNumber: 4,
    },
    100: {
      label: (seminarType, n) => getSeminarTypeLabel('面接設定中', seminarType, n || 1),
      fromStatus: 50,
      toStatus: 100,
      transitionNumber: 5,
    },
  },
  60: {
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 60,
      toStatus: 70,
      transitionNumber: 6,
    },
    100: {
      label: (seminarType, n) => getSeminarTypeLabel('面接設定中', seminarType, n || 1),
      fromStatus: 60,
      toStatus: 100,
      transitionNumber: 7,
    },
    170: {
      label: () => 'NG連絡前',
      fromStatus: 60,
      toStatus: 170,
      transitionNumber: null,
      needsReason: true,
    },
  },
  70: {
    30: {
      label: () => '営業書類提出前',
      fromStatus: 70,
      toStatus: 30,
      transitionNumber: 9,
      hasRemarks: true,
    },
    200: {
      label: () => '辞退終了',
      fromStatus: 70,
      toStatus: 200,
      transitionNumber: 9,
      hasRemarks: false,
      needsReason: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 70,
      toStatus: 50,
      transitionNumber: 10,
      hasRemarks: true,
    },
  },
  80: {
    30: {
      label: () => '営業書類提出前',
      fromStatus: 80,
      toStatus: 30,
      transitionNumber: null,
      hasRemarks: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 80,
      toStatus: 50,
      transitionNumber: null,
      hasRemarks: true,
    },
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 80,
      toStatus: 70,
      transitionNumber: null,
      hasRemarks: true,
    },
  },
  90: {
    170: {
      label: () => 'NG連絡前',
      fromStatus: 90,
      toStatus: 170,
      transitionNumber: 8,
      needsReason: true,
    },
    40: {
      label: () => '営業書類結果待ち',
      fromStatus: 90,
      toStatus: 40,
      transitionNumber: 11,
      hasRemarks: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 90,
      toStatus: 50,
      transitionNumber: 11,
      hasRemarks: true,
    },
    60: {
      label: () => '企業書類結果待ち',
      fromStatus: 90,
      toStatus: 60,
      transitionNumber: 11,
      hasRemarks: true,
    },
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 90,
      toStatus: 70,
      transitionNumber: 11,
      hasRemarks: true,
    },
  },
  100: {
    30: {
      label: () => '営業書類提出前',
      fromStatus: 100,
      toStatus: 30,
      transitionNumber: 9,
      hasRemarks: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 100,
      toStatus: 50,
      transitionNumber: 10,
      hasRemarks: true,
    },
    200: {
      label: () => '辞退終了',
      fromStatus: 100,
      toStatus: 200,
      transitionNumber: 9,
      hasRemarks: false,
      needsReason: true,
    },
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 100,
      toStatus: 70,
      transitionNumber: 10,
      hasRemarks: true,
    },
  },
  110: {
    30: {
      label: () => '営業書類提出前',
      fromStatus: 110,
      toStatus: 30,
      transitionNumber: null,
      hasRemarks: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 110,
      toStatus: 50,
      transitionNumber: null,
      hasRemarks: true,
    },
    70: {
      label: () => 'セミナー設定中',
      fromStatus: 110,
      toStatus: 70,
      transitionNumber: null,
      hasRemarks: true,
    },
  },
  120: {
    40: {
      label: () => '営業書類結果待ち',
      fromStatus: 120,
      toStatus: 40,
      transitionNumber: null,
      hasRemarks: true,
    },
    50: {
      label: () => '企業書類提出待ち',
      fromStatus: 120,
      toStatus: 50,
      transitionNumber: null,
      hasRemarks: true,
    },
    60: {
      label: () => '企業書類結果待ち',
      fromStatus: 120,
      toStatus: 60,
      transitionNumber: null,
      hasRemarks: true,
    },
    170: {
      label: () => 'NG連絡前',
      fromStatus: 120,
      toStatus: 170,
      transitionNumber: 10,
      needsReason: true,
    },
  },
};

export const PROGRESS_BULK_EDIT: { [key: number]: ProgressBulkEditAction[] } = {};
Object.keys(PROGRESS_TRANSITIONS).forEach(
  (fromStatus) => (PROGRESS_BULK_EDIT[fromStatus] = [...Object.values<ProgressBulkEditAction>(PROGRESS_TRANSITIONS[fromStatus])]),
);
