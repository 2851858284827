export interface School {
  id: number;
  code: string;
  name: string;
  yomi: string;
  letter1: string;
  universityClass: number;
  closed: boolean;
  words: string[];
  departments: SchoolDepartment[];
}

export interface SchoolDepartment {
  id: number;
  schoolId?: number;
  code: string;
  name: string;
  closed: boolean;
  subDepartments: SchoolSubDepartment[];
}

export interface SchoolSubDepartment {
  id: number;
  schoolDepartmentId?: number;
  code: string;
  name: string;
  closed: boolean;
}

export class HighSchool {
  id: number;
  code: string;
  name: string;
  closed: boolean;
}
