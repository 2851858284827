import { ActionMeta, GroupMeta, RowMeta } from '@agent-ds/shared/models';
import { HelperBase } from './helper-base';

import * as moment from 'moment';

export class BillingExtraInformationHelper extends HelperBase {
  private endOfMonthActionProp: { actions?: ActionMeta[]; sameBlock?: boolean; subscribers: RowMeta[] } = {
    subscribers: [],
    sameBlock: true,
    actions: [
      {
        title: '当月末',
        type: 'RUNNABLE',
        runnable: () => new Date().toEndOfMonth(),
      },
    ],
  };

  private endOfNextMonthActionProp: { actions?: ActionMeta[]; sameBlock?: boolean; overwrite?: boolean; subscribers: RowMeta[] } = {
    subscribers: [],
    sameBlock: true,
    overwrite: true,
    actions: [
      {
        title: '来月末',
        type: 'RUNNABLE',
        runnable: () => new Date().addMonths(1).toEndOfMonth(),
      },
    ],
  };

  private editable: GroupMeta = { rows: [] };

  private matrix: GroupMeta[][] = [];

  updateFieldDefinitions(): void {
    this.editable = this.getTemplate(true);

    this.matrix = [
      // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
      [null, null, null, null], // Approval state: undefined
      [null, null, null, null], // Approval state: 1 (Not Approved)
      [this.editable, this.editable, this.editable, this.editable], // Approval state: 2 (Approved)
      [null, null, null, null], // Approval state: 3 (Denied)
    ];
  }

  getMetadata(approvalStatus: number, cancellationStaus: number): GroupMeta[] {
    return [this.matrix[approvalStatus][cancellationStaus]];
  }

  private getTemplate(editable: boolean): GroupMeta {
    const res: GroupMeta = {
      // Billing information
      title: '請求情報',
      class: 'form__group--golden-title-border',
      rows: [
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'invoiceConfirmation'),
          null,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'invoiceDate'),
          this.endOfMonthActionProp,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'transferDate'),
          this.endOfNextMonthActionProp,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'transferConfirmation'),
          null,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(
          editable,
          this.dynamicService.getDefinition('sales', 'billingLinkageStatus'),
          null,
          'sales.dynamicData',
        ),
        ...this.getDynamicRowOrLabel(
          false,
          {
            ...this.dynamicService.getDefinition('sales', 'billingLinkageDate'),
            validationStyle: { required: false },
          },
          null,
          'sales.dynamicData',
          'half',
          (val) => val,
        ),
      ],
    };

    this.setupAction(this.endOfMonthActionProp);
    this.setupAction(this.endOfNextMonthActionProp);
    return res;
  }
}
