import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { TemplateRef } from '@angular/core';

export const SEMINAR_ATTENDEE_LIST_TABLE_CONFIG: (
  thanksMailTypeTemplate: TemplateRef<any>,
  studentTemplate: TemplateRef<any>,
  schoolTemplate: TemplateRef<any>,
) => TableConfig = (thanksMailTypeTemplate, studentTemplate, schoolTemplate) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'thanksMailType',
                title: 'サンクスメール',
                cellTemplate: thanksMailTypeTemplate,
                sortable: false,
              },
            ],
            style: {
              width: '320px',
            },
            bodyStyle: {
              padding: '3px',
            },
          },
          {
            fields: [
              {
                name: 'frontId',
                title: '求職者ID',
                style: { color: '#9F9F9F' },
                sortable: false,
              },
              {
                name: 'dynamicData.phoneticLastName',
                title: '氏名',
                cellTemplate: studentTemplate,
                sortable: false,
              },
            ],
            style: {
              width: '215px',
              'margin-left': '9px',
            },
          },
          {
            fields: [
              {
                name: 'school',
                title: '学校情報',
                cellTemplate: schoolTemplate,
                sortable: false,
              },
            ],
            style: {
              flex: 1,
            },
          },
        ],
      },
    ],
  },

  body: {
    checkbox: false,
    arrow: true,
  },
});
