import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CACHE } from '../util/cache';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private static setTimer: any;
  private static requestCount = 0;
  public static readonly requestCountChange = new EventEmitter<number>();
  public static set ongoingRequestCount(count: number) {
    CacheInterceptor.requestCount = count;
    if (CacheInterceptor.setTimer) {
      clearTimeout(CacheInterceptor.setTimer);
      CacheInterceptor.setTimer = null;
    }
    if (count) {
      CacheInterceptor.requestCountChange.emit(CacheInterceptor.requestCount);
    } else {
      CacheInterceptor.setTimer = setTimeout(() => CacheInterceptor.requestCountChange.emit(CacheInterceptor.requestCount), 300);
    }
  }

  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET' && req.url.includes('/file')) {
      return next.handle(req);
    }
    const url = req.method === 'GET' ? req.urlWithParams : req.urlWithParams + JSON.stringify(req.body);
    const isRegistered = CACHE.isRegistered(url);
    const countIt =
      !req.url.includes('profile-image') && !req.url.includes('search') && !req.url.includes('suggest') && !req.url.includes('dashboard');
    if (isRegistered) {
      const lastResponse = CACHE.get(url);
      if (lastResponse) {
        return lastResponse instanceof Observable
          ? lastResponse
          : new Observable((observer) => {
              lastResponse instanceof HttpResponse ? observer.next(lastResponse) : observer.error(lastResponse);
              observer.complete();
            });
      }
    }

    const subject = new ReplaySubject();
    const setter = (event: HttpEvent<any>) => {
      subject.next(event);
      if (event && event.type !== 0) {
        if (isRegistered) {
          CACHE.set(url, event, CACHE.getTTL(req.url));
        } else {
          CACHE.clear(url);
        }
      }
    };
    const decrement = () => {
      if (countIt) {
        CacheInterceptor.requestCountChange.emit(--CacheInterceptor.requestCount);
      }
      if (!isRegistered) {
        CACHE.clear(url);
      }
      subject.complete();
    };
    const requestHandle = next.handle(req).pipe(
      tap((event) => setter(event)),
      catchError((error) => {
        setter(error);
        return throwError(error);
      }),
      finalize(() => decrement()),
    );
    CACHE.set(url, subject, CACHE.getTTL(req.url));
    if (countIt) {
      CacheInterceptor.requestCountChange.emit(++CacheInterceptor.requestCount);
    }
    return requestHandle;
  }
}
