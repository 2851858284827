import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { AuthInterceptor } from '@agent-ds/shared/interceptors/auth.interceptor';
import { ActionMeta, FieldMeta, FormMeta, isNestedSupplied, isPartial, strip, SupplierCallType } from '@agent-ds/shared/models/form';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { TruncatePipe } from '@agent-ds/shared/pipes/truncate.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { NestedDiffer } from '@agent-ds/shared/util';
import { getValueFromObject, setValueInObject } from '@agent-ds/shared/util/util';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueChangeRecord,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ag-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements DoCheck, OnDestroy {
  public myForm: FormGroup = new FormGroup({});
  @ViewChild(FormGroupDirective, { static: false }) fgDirective: FormGroupDirective;
  @ViewChild('form', { static: false }) formEl: ElementRef<HTMLFormElement>;

  @Input() metadata: FormMeta;
  @Input() model: any;

  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() validated: EventEmitter<boolean> = new EventEmitter();
  @Output() focused: EventEmitter<string> = new EventEmitter();
  @Output() changed: EventEmitter<string> = new EventEmitter();
  @Output() blured: EventEmitter<string> = new EventEmitter();

  private valueChangeSubscription: Subscription;
  private errorSubscription: Subscription;

  private modelDiffer: KeyValueDiffer<string, any>;
  private metadataDiffer: KeyValueDiffer<string, any>;
  private noCheckNeeded: boolean;
  transparentModel = {};
  fieldsByName: { [key: string]: FieldMeta } = {};
  private namesByValueKeys: { [key: string]: FieldMeta } = {};
  private partialKeysByName: { [key: string]: string[] } = {};
  private afterFieldInit: { [key: string]: boolean } = {};
  private linksInAction: { [key: string]: { [key: string]: boolean } } = {};
  groupsExpanded: boolean[] = [];
  labelsToggled: { [key: string]: boolean } = {};
  labelToggleLength = 400;
  private checkTimeout: any;
  private alive = true;

  boundGetValue = (key: string, override?: boolean) => this.getValue(this.fieldsByName[key] || { name: key, type: null }, override);
  boundSetValue = (key: string, value: any) => this.setControlValue(key, value);

  constructor(
    public readonly fb: FormBuilder,
    public readonly differs: KeyValueDiffers,
    public readonly datePipe: SafeDatePipe,
    public readonly truncatePipe: TruncatePipe,
    private cdr: ChangeDetectorRef,
  ) {
    this.modelDiffer = new NestedDiffer();
    this.metadataDiffer = this.differs.find({}).create(); // new NestedDiffer(['options', 'showRequired']);
    this.errorSubscription = AuthInterceptor.FIELD_ERROR_EVENT.subscribe((event: { [key: string]: string }) => {
      const fieldNames = Object.keys(this.fieldsByName).concat(Object.keys(this.partialKeysByName));
      Object.keys(event).forEach((key) => {
        const expandedKey = 'dynamicData.' + key;
        const found = fieldNames.find((name) => name === key || name === expandedKey || 'dynamicData.' + name === key);
        if (found) {
          if (this.fieldsByName[found]) {
            // this.myForm.controls[found].setErrors({ message: event[key] });
            this.myForm.controls[found].markAsTouched();
          } else {
            this.partialKeysByName[found].forEach((pKey) => {
              this.myForm.controls[pKey].setErrors({});
              this.myForm.controls[pKey].markAsTouched({});
            });
          }
        }
      });
    });
  }

  private checkUpdate(prop: KeyValueChangeRecord<any, any>) {
    let field = this.fieldsByName[prop.key];
    let currentValue = prop.currentValue;
    let control = this.myForm.controls[prop.key];
    if (!field && this.namesByValueKeys[prop.key]) {
      field = this.namesByValueKeys[prop.key];
      currentValue = this.getValue(field, typeOf(field.valueField) === 'object');
      control = this.myForm.controls[field.name];
    }
    if (field) {
      const sup = field.supplier
        ? field.supplier(currentValue, SupplierCallType.MODEL_CHANGE, this.boundGetValue, this.boundSetValue)
        : undefined;
      if (sup instanceof Observable) {
        const subs = sup.subscribe((res) => {
          this.suppliedUpdateOnField(field, control, res, currentValue);
          setTimeout(() => {
            subs.unsubscribe();
            this.detect();
          }, 0);
        });
      } else if (sup) {
        this.suppliedUpdateOnField(field, control, sup, currentValue);
      } else {
        const partial = isPartial(field);
        let value: any;
        if ((field.type === 'date' || field.type === 'time') && currentValue && partial) {
          value = Date.from(currentValue);
        } else if (field.type === 'year' && currentValue && partial) {
          value = new Date(currentValue).getFullYear();
        } else if (field.type === 'month' && currentValue && partial) {
          value = new Date(currentValue).getMonth() + 1;
        } else if (field.type === 'zip') {
          const place = parseInt(field.name.substring(field.name.length - 1), 10);
          value = currentValue ? (place ? currentValue.substring(3) : currentValue.substring(0, 3)) : null;
        } else if (field.multi && field.type !== 'radio') {
          value = currentValue ? (Array.isArray(currentValue) ? currentValue : Object.keys(currentValue)) : [];
        } else {
          value = currentValue;
        }

        if (
          value == null &&
          field.default != null &&
          (!field.multi || Array.isArray(field.default)) &&
          (!field.options || field.options[0] !== NULL_SELECTED_VALUE)
        ) {
          value = field.default;
        }

        if (control) {
          control.patchValue(this.prepareControlValue(field, value));
        }
        if (field.transparent) {
          this.setValue(field, value);
        }
        this.updateLinks(field.name, field, SupplierCallType.MODEL_LINK_CHANGE);
      }
    } else if (this.partialKeysByName[prop.key]) {
      Object.keys(this.partialKeysByName[prop.key]).forEach((key) =>
        this.checkUpdate({ key: this.partialKeysByName[prop.key][key], currentValue: currentValue, previousValue: null }),
      );
    }
  }

  private suppliedUpdateOnField(field: FieldMeta, control: AbstractControl, sup: any, currentValue: any): void {
    let val = currentValue;
    if (isNestedSupplied(sup)) {
      field.options = sup.options || field.options;
      if (sup.value !== undefined) {
        val = sup.value;
      }
    } else if (sup !== undefined) {
      val = sup;
    }
    if (control) {
      control.patchValue(this.prepareControlValue(field, val));
    }
    if (field.transparent || typeOf(field.valueField) !== 'object') {
      this.setValue(field, val);
    }
    this.updateLinks(field.name, field, SupplierCallType.MODEL_LINK_CHANGE);
  }

  ngDoCheck() {
    const metadataChanges = this.metadataDiffer.diff(this.metadata);
    if (metadataChanges) {
      this.noCheckNeeded = true;
      this.createForm();
    } else if (!this.noCheckNeeded) {
      this.noCheckNeeded = true;
      const modelChanges = this.modelDiffer.diff(this.model);
      if (!modelChanges) {
        return;
      }
      modelChanges.forEachItem((prop) => {
        // empty transparent model to avoid collision with past and current values
        delete this.transparentModel[prop.key];
      });
      modelChanges.forEachItem((prop) => {
        this.checkUpdate(prop);
      });
      this.checkAllowedState();
      this.detect();
    }
    this.noCheckNeeded = false;
  }

  public detect(): void {
    if (this.alive) {
      this.cdr.detectChanges();
    }
  }

  createForm(): void {
    this.fieldsByName = {};
    this.namesByValueKeys = {};
    this.partialKeysByName = {};
    this.transparentModel = {};
    this.linksInAction = {};
    this.groupsExpanded = [];
    this.labelsToggled = {};
    const group = {};

    let i = 0;
    for (const fieldGroup of this.metadata.groups) {
      this.groupsExpanded[i++] = fieldGroup.expanded;
      for (const row of fieldGroup.rows) {
        for (const field of row.fields) {
          if (!field) {
            continue;
          }
          field.validators = field.validators ? { ...field.validators } : {};

          if (field.type === 'label' || field.type === 'hr') {
            field.transparent = true;
          } else if (field.type === 'date') {
            field.validators.maxValue = field.validators.maxValue || '2500-01-01';
            field.validators.minValue = field.validators.minValue || '1900-01-01';
          } else if (field.type === 'yearmonth') {
            field.validators.maxValue = field.validators.maxValue || '2500-01';
            field.validators.minValue = field.validators.minValue || '1900-01';
          } else if (field.type === 'year') {
            field.validators.maxValue = field.validators.maxValue || 9999;
          } else if (field.type === 'month') {
            field.validators.maxValue = field.validators.maxValue || 12;
            field.validators.minValue = field.validators.minValue || 1;
          }

          this.fieldsByName[field.name] = field;
          this.linksInAction[field.name] = {};

          if (typeOf(field.valueField) === 'object') {
            const parts = strip(field.name).split('.');
            parts.pop();
            const baseKey = parts.join('.');
            Object.keys(field.valueField).forEach((key) => (this.namesByValueKeys[key ? `${baseKey}.${key}` : baseKey] = field));
          }
          const params = [];
          let value: any;
          const partial = isPartial(field);
          if (partial) {
            const key = strip(field.name);
            const arr = this.partialKeysByName[key] || [];
            arr.push(field.name);
            this.partialKeysByName[key] = arr;
          }
          if (this.model) {
            let val = this.getValue(field, typeOf(field.valueField) === 'object');
            if (
              (val == null ||
                (field.options &&
                  (!field.options.length ||
                    ((field.options.length === 1 &&
                      field.options[0] != null &&
                      NULL_SELECTED_VALUE === field.options[0][field.valueField as string]) ||
                      field.options[0])))) &&
              field.supplier
            ) {
              const sup = field.supplier(
                typeOf(field.valueField) === 'object' ? val : this.getValue(field, true),
                SupplierCallType.INIT,
                this.boundGetValue,
                this.boundSetValue,
              );
              if (sup instanceof Observable) {
                const subs = sup.subscribe((res) => {
                  if (isNestedSupplied(res)) {
                    field.options = res.options || field.options;
                    if (res.value !== undefined) {
                      this.setControlValue(field.name, res.value);
                    }
                  } else if (res !== undefined) {
                    this.setControlValue(field.name, res);
                  }
                  setTimeout(() => {
                    subs.unsubscribe();
                    this.detect();
                  }, 0);
                });
              } else if (sup && sup.options) {
                field.options = sup.options || field.options;
                val = sup.value || val;
                setTimeout(() => this.detect());
              } else {
                val = sup || val || this.getValue(field, true);
                if (field.transparent) {
                  this.setValue(field, val);
                }
              }
            } else if (field.transparent) {
              val = val || this.getValue(field, true);
              this.setValue(field, val);
            }
            if ((field.type === 'date' || field.type === 'time') && val && partial) {
              value = Date.from(val);
            } else if (field.type === 'year' && val && partial) {
              value = new Date(val).getFullYear();
            } else if (field.type === 'month' && val && partial) {
              value = new Date(val).getMonth() + 1;
            } else if (field.type === 'zip') {
              const place = parseInt(field.name.substring(field.name.length - 1), 10);
              value = val ? (place ? val.substring(3) : val.substring(0, 3)) : null;
            } else if (field.multi && field.type !== 'radio') {
              value = val ? (Array.isArray(val) ? val : Object.keys(val)) : [];
            } else {
              value = val;
            }
          } else if (value == null && field.default && (!field.multi || Array.isArray(field.default))) {
            value = field.default;
          } else if (value == null && field.multi) {
            value = [];
          }

          // add object for formbuilder
          if (field.multi && field.type === 'checkbox') {
            group[field.name] = this.fb.array(
              field.options.map((option) => ({
                value: value.includes(typeof field.valueField === 'string' ? option[field.valueField] : option),
                disabled: this.metadata.disabled || field.disabled || (field.allowOn && !this.isDependencyOk(field.allowOn)),
              })),
              this.getValidators(field.validators),
            );
          } else {
            params.push({
              value: this.prepareControlValue(field, value),
              disabled: this.metadata.disabled || field.disabled || (field.allowOn && !this.isDependencyOk(field.allowOn)),
            });

            // add validators to form builder
            if (field.validators) {
              params.push(this.getValidators(field.validators));
            }

            // add field to group
            group[field.name] = params;

            if (field.type === 'radio' && field.specialOption) {
              const foundInOption = field.options.find(
                (option) => value === (typeof field.valueField === 'string' ? option[field.valueField] : option),
              );
              if (!foundInOption && value != null) {
                params[0].value = field.specialOption;
              }
              group[field.name + '.special'] = [
                {
                  value: foundInOption && value != null ? null : value,
                  disabled:
                    params[0].value !== field.specialOption ||
                    this.metadata.disabled ||
                    field.disabled ||
                    (field.allowOn && !this.isDependencyOk(field.allowOn)),
                },
              ];
            }

            setTimeout(() => (this.afterFieldInit[field.name] = true), 500);
          }
        }
      }
    }

    this.myForm = this.fb.group(group);

    Promise.all(Object.values(this.fieldsByName).map((field) => this.updateLinks(field.name, field, SupplierCallType.INIT))).then(() =>
      this.detect(),
    );

    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
    this.valueChangeSubscription = this.myForm.valueChanges.subscribe(() => {
      this.validated.emit(this.myForm.valid);
    });

    setTimeout(() => {
      if (this.metadata.initTouched) {
        this.myForm.markAllAsTouched();
      }
      this.myForm.updateValueAndValidity();
    }, 200);
  }

  updateModel(key: string): void {
    const field = this.fieldsByName[key];
    let val = Object.keys(this.myForm.value).length ? this.myForm.value[key] : this.getValue(field);

    if (typeOf(field.valueField) === 'object' && typeof val !== 'object') {
      return;
    }

    const baseValue = this.getValue(field);
    const partial = isPartial(field);

    if ((field.type === 'date' || field.type === 'time') && !val) {
      val = null;
    }
    if (field.multi && field.type === 'checkbox') {
      val = field.options
        .filter((item, index) => val[index])
        .map((option) => (typeof field.valueField === 'string' ? option[field.valueField] : option));
    } else if (field.type === 'radio' && field.specialOption && val === field.specialOption) {
      val = this.myForm.controls[key + '.special'].value || '';
    } else if (field.type === 'date' && partial) {
      if (val) {
        val = new Date(val);
        const tmp = baseValue ? new Date(baseValue) : new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0);
        val.setHours(tmp.getHours());
        val.setMinutes(tmp.getMinutes());
      } else {
        this.setControlValue(strip(key) + '.1', null);
      }
    } else if (field.type === 'time' && partial) {
      val = val ? val.split(':').map((v: string) => Number.parseInt(v, 10)) : [0, 0];
      const tmp = baseValue ? new Date(baseValue) : new Date();
      tmp.setHours(val[0]);
      tmp.setMinutes(val[1]);
      tmp.setSeconds(0);
      tmp.setMilliseconds(0);
      val = tmp;
      if (!baseValue) {
        this.setControlValue(strip(key) + '.0', tmp);
      }
    } else if (field.type === 'yearmonth') {
      val = val ? new Date(val) : null;
    } else if (field.type === 'year' && partial && val) {
      const tmp = baseValue ? new Date(baseValue) : new Date();
      tmp.setFullYear(val);
      tmp.setDate(1);
      val = tmp;
    } else if (field.type === 'month' && partial) {
      if (val != null && val !== '') {
        const tmp = baseValue ? new Date(baseValue) : new Date();
        tmp.setMonth(val - 1);
        tmp.setDate(1);
        val = tmp;
      }
    } else if (field.type === 'zip') {
      const arrVal = [null, null];
      const baseKey = strip(key);
      const keys = this.partialKeysByName[baseKey];
      keys.forEach((pKey) => (arrVal[parseInt(pKey.substring(pKey.length - 1), 10)] = this.myForm.value[pKey]));
      val = arrVal.join('');
    }

    if (field.multi && val[0] === NULL_SELECTED_VALUE) {
      val = [null, ...val.slice(1)];
    }

    if (typeOf(field.valueField) === 'object' && this.afterFieldInit[key] && val != null) {
      const valObj = val || {};
      const strippedKey = strip(key);
      const lastKey = strippedKey.split('.').pop();
      const valKeys = Object.keys(field.valueField);
      let skipSafeSave = false;
      valKeys.forEach((modelKey) => {
        skipSafeSave = skipSafeSave || !modelKey || modelKey === lastKey;
        const setVal = field.valueField[modelKey] ? valObj[field.valueField[modelKey]] : modelKey ? valObj[modelKey] : valObj;
        this.setValue(field, setVal, modelKey ? key.replace(/\.[^.]+?$/, '.' + modelKey) : key, true);
      });
      if (!skipSafeSave) {
        this.setValue(field, valObj[lastKey], null, true);
      }
      if (field.transparent) {
        this.setValue(field, val);
      }
    } else if (typeOf(field.valueField) === 'object' && val == null) {
      // this branch intends to set every field with null values instead of undefined which would be filteres from update request
      // otherwise we could remove val != null condition above
      Object.keys(field.valueField).forEach((modelKey) => {
        this.setValue(field, null, modelKey ? key.replace(/\..+?$/, '.' + modelKey) : key, true);
      });
    } else if (typeOf(field.valueField) !== 'object' || field.transparent || val == null) {
      this.setValue(field, val);
    }

    this.afterFieldInit[key] = true;

    if (!field.actions || !field.actions.find((action) => action.type === 'UPDATE_LINKED')) {
      this.updateLinks(key, field).then(() => this.detect());
    }

    if (partial && (val == null || val === NULL_SELECTED_VALUE)) {
      const nKey = this.partialKeysByName[strip(key)].reverse().find((pKey) => pKey < key && this.myForm.value[pKey] != null);
      if (nKey) {
        this.updateModel(nKey);
        return;
      }
    }

    this.checkAllowedState();

    if (!field.transparent || typeOf(field.valueField) === 'object') {
      this.modelDiffer.diff(this.model);
      this.changed.emit(key);
    }
  }

  async updateLinks(key: string, fieldDef?: FieldMeta, mode: SupplierCallType = SupplierCallType.LINK_CHANGE): Promise<void> {
    const field = fieldDef || this.fieldsByName[key];
    if (field.linkTo) {
      for (const link of field.linkTo) {
        const linkField = this.fieldsByName[link];
        if (!linkField) {
          console.warn(`no linkField found for key ${link}`);
          continue;
        }
        if (linkField.supplier) {
          const sup = linkField.supplier(
            this.getValue(linkField, typeOf(linkField.valueField) === 'object'),
            mode,
            this.boundGetValue,
            this.boundSetValue,
          );
          if (sup instanceof Observable) {
            sup.toPromise().then((res) => {
              this.suppliedUpdateOnLink(field, linkField, res);
            });
          } else {
            this.suppliedUpdateOnLink(field, linkField, sup);
          }
        } else {
          (linkField.actions || []).filter((a) => a.type === 'SELECT').forEach((a) => this.onAction(linkField.name, a, null));
        }
      }
    }
  }

  private suppliedUpdateOnLink(field: FieldMeta, linkField: FieldMeta, sup: any): void {
    let value = sup;
    let options: any[];
    if (isNestedSupplied(sup)) {
      options = sup.options || options;
      value = sup.value;
    }
    if (linkField.options) {
      linkField.options = options || linkField.options;
    }
    if (value !== undefined && !this.linksInAction[linkField.name][field.name]) {
      this.linksInAction[field.name][linkField.name] = true;
      this.setControlValue(linkField.name, value);
    }
    this.linksInAction[linkField.name][field.name] = false;

    (linkField.actions || []).filter((a) => a.type === 'SELECT').forEach((a) => this.onAction(linkField.name, a, null));
  }

  onAction(key: string, action: ActionMeta, value?: string): void {
    switch (action.type) {
      case 'UPDATE_LINKED':
        this.updateLinks(key);
        break;
      case 'REVEAL':
        this.fieldsByName[key].hidden = false;
        break;
      case 'RUNNABLE':
      case 'EMPTY':
      case 'SELECT':
        if (action.runnable) {
          const ret = action.runnable(this.boundGetValue, this.boundSetValue, value);
          if (ret !== undefined) {
            this.setControlValue(key, ret);
          }
        }
        break;
    }
    this.detect();
  }

  onKey(event: KeyboardEvent): void {
    if (!event['isComposing']) {
      if (event.code === 'Escape') {
        this.onBlur();
        this.formEl.nativeElement.focus();
      } else if (event.code === 'Enter' && event.target['tagName'] === 'INPUT') {
        event.stopPropagation();
      }
    }
  }

  onBlur(key?: string): void {
    if (!key) {
      (document.activeElement as HTMLElement).blur();
    }
    this.blured.emit(key);
  }

  onFocus(key: string): void {
    this.focused.emit(key);
  }

  onSubmit(event?: any): void {
    if (event) {
      if (event['isComposing']) {
        return;
      }
      event.stopPropagation();
    }
    if (this.myForm.valid) {
      this.onBlur();
      this.submitted.emit();
    }
  }

  close(): void {
    const overlays = document.body.getElementsByClassName('cdk-overlay-backdrop');
    if (overlays.length) {
      (overlays[0] as HTMLElement).click();
    }
  }

  reset(): void {
    this.fgDirective.resetForm();
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  private getValueByKey(key: string): any {
    return this.getValue({ name: key, type: 'text' });
  }

  getValue(field: FieldMeta, overrideTransparency?: boolean): any {
    const value =
      field.transparent && !overrideTransparency ? this.transparentModel[field.name] : getValueFromObject(this.model, strip(field.name));
    if (value == null && field.transparent && field.default) {
      return field.default;
    }
    return value;
  }

  private setValue(field: FieldMeta, value: any, key?: string, overrideTransparency?: boolean): void {
    if (field.transparent && !overrideTransparency) {
      this.transparentModel[field.name] = value;
    } else {
      setValueInObject(this.model, strip(key || field.name), value === NULL_SELECTED_VALUE ? null : value);
      this.detect();
    }
  }

  private setControlValue(key: string, value: any, tries = 0): void {
    if (tries >= 5) {
      if (!this.fieldsByName[key] || this.fieldsByName[key].transparent) {
        this.transparentModel[key] = value;
        this.detect();
      }
      return;
    }
    if (this.myForm.controls[key]) {
      this.myForm.controls[key].setValue(this.prepareControlValue(this.fieldsByName[key], value));
      this.updateModel(key);
    } else {
      setTimeout(() => this.setControlValue(key, value, ++tries), 100);
    }
  }

  private checkAllowedState(): void {
    if (this.checkTimeout) {
      clearTimeout(this.checkTimeout);
    }
    this.checkTimeout = setTimeout(() => {
      Object.keys(this.fieldsByName).forEach((key) => {
        const control = this.myForm.controls[key];
        const field = this.fieldsByName[key];
        if (control && field) {
          const disabled = this.metadata.disabled || field.disabled || (field.allowOn && !this.isDependencyOk(field.allowOn));
          if (control && control.disabled !== disabled) {
            if (disabled) {
              control.disable();
            } else {
              control.enable();
            }
          }
          if (field.type === 'radio' && field.specialOption) {
            const specialDisabled = disabled || control.value !== field.specialOption;
            const specialControl = this.myForm.controls[key + '.special'];
            if (specialControl && specialControl.disabled !== specialDisabled) {
              if (specialDisabled) {
                specialControl.disable();
              } else {
                specialControl.enable();
              }
            }
          }
        }
      });
    }, 300);
  }

  isDependencyOk(conditions: { [key: string]: any }): boolean {
    const keys = Object.keys(conditions);
    return (
      keys.length === 0 ||
      keys.find(
        (key) =>
          !this.checkCondition(this.fieldsByName[key] ? this.getValue(this.fieldsByName[key]) : this.getValueByKey(key), conditions[key]),
      ) == null
    );
  }

  private checkCondition(value: any, condition: any): boolean {
    return (
      value != null &&
      (condition == null ||
        (Array.isArray(value)
          ? value.find((v: any) => this.checkCondition(v, condition)) != null
          : Array.isArray(condition)
          ? condition.find((c) => this.checkCondition(value, c)) != null
          : typeOf(condition) === 'object'
          ? Object.keys(condition).find((k) => this.checkCondition(value[k], condition[k])) != null
          : value === condition))
    );
  }

  /**
   * Returns an array of validators for FormBuilder
   * @param validators Array of validator config objects
   * @return Array of Validators which can be type of Validator, ValidatorFn
   */
  private getValidators(validators: { [key: string]: any }): ValidatorFn[] {
    return validators
      ? Object.keys(validators)
          .map((validator) => {
            let fn: ValidatorFn;
            if (validator === 'pattern') {
              fn = Validators.pattern(validators.pattern);
            } else if (validator === 'required' && validators.required) {
              fn = Validators.required;
            } else if (validator === 'email' && validators.email) {
              fn = Validators.email;
            } else if (validator === 'minValue') {
              fn = Validators.min(validators.minValue);
            } else if (validator === 'maxValue') {
              fn = Validators.max(validators.maxValue);
            } else if (validator === 'minDate') {
              fn = (control) => {
                const valid = control.value && new Date(control.value) >= new Date(validators.minDate);
                return !valid ? { [validator]: `Date must be later than ${validators.minDate}.` } : null;
              };
            } else if (validator === 'minChecked') {
              fn = (control) => {
                const valid = control.value && control.value.length && control.value.filter((v) => !!v).length >= validators.minChecked;
                return !valid ? { [validator]: 'Not enough checkbox is checked in this form control.' } : null;
              };
            } else if (validator === 'max') {
              fn = Validators.maxLength(validators.max);
            } else if (typeof validators[validator] === 'function') {
              fn = (control: FormControl | null) => {
                const err = validators[validator](control);
                return err != null ? { [validator]: err } : null;
              };
            }

            if (fn) {
              return (control: FormControl) => (control && control.disabled ? null : fn(control));
            }

            return null;
          })
          .filter((val) => val != null)
      : null;
  }

  private prepareControlValue(field: FieldMeta, value: any): any {
    if (field.type === 'date') {
      return this.datePipe.transform(value, 'yyyy-MM-dd');
    } else if (field.type === 'time') {
      return typeof value === 'string' && value.match(/^\d\d:\d\d$/) ? value : this.datePipe.transform(value, 'HH:mm');
    } else if (field.type === 'yearmonth') {
      return this.datePipe.transform(value, 'yyyy-MM');
    } else if (
      field.options &&
      field.options.length &&
      (field.options[0] === NULL_SELECTED_VALUE ||
        (typeof field.labelField === 'string' && field.options[0][field.labelField] === NULL_SELECTED_VALUE)) &&
      value == null
    ) {
      if (field.type === 'radio' && field.specialOption && this.myForm.controls[field.name + '.special']) {
        this.myForm.controls[field.name + '.special'].patchValue(null);
      }
      return typeof field.valueField !== 'string' ? field.options[0] : NULL_SELECTED_VALUE;
    } else if (field.multi && field.type === 'checkbox' && Array.isArray(value)) {
      value = field.options.map((option) =>
        option === NULL_SELECTED_VALUE
          ? value.includes(null) || value.includes(NULL_SELECTED_VALUE)
          : value.includes(typeof field.valueField === 'string' ? option[field.valueField] : option),
      );
    } else if (field.type === 'radio' && field.specialOption) {
      const foundInOption = field.options.find(
        (option) => value === (typeof field.valueField === 'string' ? option[field.valueField] : option),
      );
      if (!foundInOption && value != null && this.myForm.controls[field.name + '.special']) {
        this.myForm.controls[field.name + '.special'].patchValue(value);
        value = field.specialOption;
      } else if (this.myForm.controls[field.name + '.special']) {
        this.myForm.controls[field.name + '.special'].patchValue(null);
      }
    }
    return value;
  }
}
