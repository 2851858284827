import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DataFile, Student } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-student-files-tab',
  templateUrl: './student-files-tab.component.html',
  styleUrls: ['./student-files-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentFilesTabComponent) }],
})
export class StudentFilesTabComponent extends Tab implements OnInit, OnChanges {
  @Input() student: Student;
  private studentId: number;

  constructor(private studentApiService: StudentApiService, private dialogService: DialogService) {
    super();
  }

  files: DataFile[] = [];
  jobFiles: DataFile[] = [];

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['student']) {
      this.jobFiles = [];
      this.studentId = this.student ? this.student.id : null;

      if (this.studentId) {
        this.studentApiService.getJobFiles(this.studentId).subscribe(
          (files: DataFile[]) => {
            this.jobFiles = files;
          },
          (error) => {
            console.error('getJobFiles error', error);
            if (!error.status) {
              // ネットワークに繋がらなかったときはエラーを通知する
              this.showErrorDialog();
              this.studentApiService.notifyConnectionErrorEvent.emit();
            } else {
              // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
            }
          },
        );
      }
    }
  }

  downloadJobFile(file: DataFile) {
    this.studentApiService.downloadJobFile(this.studentId, file.id);
  }

  private showErrorDialog() {
    const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
      'データフォルダ求人履歴管理の取得中に通信エラーが発生しました。',
      '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
    ]);
    this.dialogService.open(ConfirmDialogComponent, dialogConfig);
  }
}
