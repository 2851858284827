import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { CompanySendConfig } from '@agent-ds/shared/components/mail-send/configs/company-send-config';
import { MailPrefill } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { Component, forwardRef, Input } from '@angular/core';

@Component({
  selector: 'ag-company-message-tab',
  templateUrl: './company-message-tab.component.html',
  styleUrls: ['./company-message-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyMessageTabComponent) }],
})
export class CompanyMessageTabComponent extends Tab {
  @Input() companyId: number;
  sendConfig = new CompanySendConfig();

  constructor() {
    super();
  }

  openMail(prefill: MailPrefill): void {
    MailSendFlowComponent.instance.config = this.sendConfig;
    this.sendConfig.setParams({ enterpriseId: this.companyId });
    MailSendFlowComponent.instance.start(prefill);
  }
}
