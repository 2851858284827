import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupConfig, MailPopupType } from '../config';
import { StudentJobConfig } from './student-job-config';

export class ProgressOfferConfig extends StudentJobConfig {
  public getTemplateId(): number {
    return 4;
  }

  public getTemplateTypeId(): number[] {
    return [4];
  }

  public getPopupConfig(): MailPopupConfig[] {
    return [{ type: MailPopupType.JOB_CHECK }];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.progressIds = params.progressIds || [];
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.students) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    metaData.groups[0].rows.push({
      title: 'To (求職者)',
      showRequired: true,
      fields: [
        {
          name: 'to',
          type: 'label',
          supplier: () =>
            this.sender.model.students
              .map((s) =>
                s.emailMainAvailable
                  ? `【送信不可】${s.lastName + s.firstName} メイン <${s.emailMain}>`
                  : `${s.lastName + s.firstName} メイン <${s.emailMain}>`,
              )
              .join(', '),
        },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (求職者、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        row.fields.unshift(
          {
            name: 'cc_label',
            type: 'label',
            default: '※E-Mailサブにも送信',
          },
          {
            name: 'cc-hr',
            type: 'hr',
            class: 'full',
          },
          {
            name: 'cc2_label',
            type: 'label',
            default: '※求職者担当にも送信',
          },
        );
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const template = getValue('dummyTemplateType');
                return template ? value || template.subject : undefined;
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });

    const fileRow = {
      title: '添付ファイル',
      class: 'ou',
      fields: [],
    };
    metaData.groups[0].rows.push(fileRow);

    if (this.sender.inConfirm) {
      fileRow.fields.push({
        type: 'label',
        name: 'zip',
        class: 'link',
        clickAction: () =>
          this.sender.jobApiService.getJobsAsPdf(this.sender.model.jobs.map((j) => j.id)).subscribe((res) => downloadResponse(res)),
        default: '求人票.pdf',
      });
    }
    const entField = this.sender.dynamicService.getFormRows(
      {
        fieldType: 'multi-enterprise',
        label: '添付ファイル',
        fieldName: 'enterprises',
      },
      null,
      'half',
    )[0].fields[0];
    if (!this.sender.inConfirm) {
      fileRow.fields.push(entField);
      entField.valueField = null;
      entField.labelBefore = '企業：';
      entField.linkTo = ['enterpriseFileIds'];
      entField.actions = [
        {
          title: 'データフォルダから選択',
          type: 'RUNNABLE',
          allowOn: { enterpriseIds: null },
          runnable: () => {
            this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
            PopupControlComponent.instance.open({
              content: this.sender.fileSelectTemplate,
              confirmText: '添付',
              cancelText: 'キャンセル',
              title: 'データフォルダ',
              confirmCallback: () => {
                this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
              },
            });
          },
        },
      ];
    }

    if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
      fileRow.fields.push({
        name: 'enterpriseFileIds',
        type: 'autocomplete',
        labelField: [
          {
            name: 'name',
            class: this.sender.inConfirm ? 'link' : null,
            action: this.sender.inConfirm
              ? (file) =>
                  this.sender.fileService.downloadFile(
                    'enterprise',
                    +Object.keys(this.sender.enterpriseFiles).find(
                      (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                    ),
                    file.id,
                    file.name,
                  )
              : null,
          },
        ],
        labelBefore: this.sender.inConfirm ? '企業：' : null,
        valueField: 'id',
        multi: true,
        style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
        class: 'options-only half',
        options: [],
        supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
          const enterprises = getValue('enterprises');
          this.sender.model.enterpriseIds = enterprises
            ? enterprises.map((e) => {
                this.sender.enterpriseInfoById[e.id] = `${e.frontId} ${e.name}`;
                return e.id;
              })
            : [];
          const ids = this.sender.model.enterpriseIds;
          const selectedFiles = (Object.values(this.sender.enterpriseFiles).flatten() as any).filter((f) =>
            this.sender.model.enterpriseFileIds.includes(f.id),
          );
          if (!ids || !ids.length) {
            return { options: selectedFiles };
          } else {
            return forkJoin(
              ids.map((id) =>
                this.sender.fileService.getFiles('enterprise', id).pipe(
                  map((res) => {
                    this.sender.enterpriseFiles[id] = res.filter((f) => f.studentSendFlag);
                    return this.sender.enterpriseFiles[id];
                  }),
                ),
              ),
            ).pipe(map((val) => ({ options: val.flatten() })));
          }
        },
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const template = getValue('dummyTemplateType');
              return template ? value || template.body : undefined;
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups.unshift({
        title: '選択した求人',
        class: 'no-title-column',
        rows: [
          {
            fields: this.sender.model.jobs.map((job: any, index: string) => ({
              name: 'jobs' + index,
              type: 'textarea',
              class: 'quarter',
              disabled: true,
              supplier: () => `${job.frontId}\n${job.position}\n${job.enterpriseName}`,
            })),
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public save(toSave: any): void {
    toSave.jobIds = this.sender.model.jobs.map((j) => j.id);
    this.sender.progressApiService.checkProgress(this.sender.model.students.map((s) => s.id), toSave.jobIds).subscribe((res) => {
      if (res && res.length) {
        this.sender.progressErrorInfo = res;
        PopupControlComponent.instance.open({
          cancelText: '中止',
          cancelCallback: () => this.sender.close(),
          confirmText: '重複案件は上書き',
          content: this.sender.progressErrorTemplate,
          title: '進捗重複',
          confirmCallback: () =>
            this.sender.mailApiService
              .sendJobStudentsBulkMail(toSave)
              .pipe(
                mergeMap((result) =>
                  this.sender.progressApiService
                    .bulkRegisterStudentsByProgressIds(this.sender.model.progressIds)
                    .pipe(tap(() => this.sender.afterSend(result))),
                ),
              ),
          multiConfirm: [
            {
              confirmText: '重複案件は上書きしないで送信のみ行う',
              confirmCallback: () =>
                this.sender.mailApiService.sendJobStudentsBulkMail(toSave).pipe(tap((result) => this.sender.afterSend(result))),
            },
          ],
        });
      } else {
        this.sender.mailApiService.sendJobStudentsBulkMail(toSave).subscribe((result) => {
          this.sender.progressApiService
            .bulkRegisterStudentsByProgressIds(this.sender.model.progressIds)
            .subscribe(() => this.sender.afterSend(result));
        });
      }
    });
  }
}
