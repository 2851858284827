import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { StudentSuggestResponse } from '@agent-ds/shared/interfaces';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { ProgressJobPostingConfig } from './progress-job-posting-config';

export class ProgressInrowJobPostingConfig extends ProgressJobPostingConfig {
  public setParams(params: any): void {
    if (this.sender && params.students) {
      this.sender.model.student = params.students[0] || this.sender.model.student;
      this.sender.model.studentId = this.sender.model.student ? this.sender.model.student.id : null;
    }
    this.sender.model.progressId = params.progressId;
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.student) {
      return;
    }
    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    const student: StudentSuggestResponse = this.sender.model.student;

    this.sender.model.cc_single_hidden = student.emailSub != null;

    metaData.groups[0].rows.push({
      title: 'To (求職者)',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'to',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              multi: true,
              disabled: student.emailMainAvailable != null,
              options: student.emailMain
                ? [
                    {
                      label: student.emailMainAvailable
                        ? `【送信不可】${student.lastName + student.firstName} メイン <${student.emailMain}>`
                        : `${student.lastName + student.firstName} メイン <${student.emailMain}>`,
                      value: student.emailMain,
                    },
                  ]
                : [],
              validators: { required: true },
            }
          : {
              name: 'to',
              type: 'label',
              supplier: () => `${student.lastName + student.firstName} メイン <${student.emailMain}>`,
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (求職者、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        if (!this.sender.inConfirm) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              class: 'full',
              multi: true,
              disabled: !student.emailSub || student.emailSubAvailable != null,
              options: student.emailSub
                ? [
                    {
                      label: student.emailSubAvailable
                        ? `【送信不可】${student.lastName + student.firstName} サブ <${student.emailSub}>`
                        : `${student.lastName + student.firstName} サブ <${student.emailSub}>`,
                      value: student.emailSub,
                    },
                  ]
                : [],
              showOn: { cc_single_hidden: null },
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
              showOn: { cc_single_hidden: null },
            },
            {
              name: 'cc_single_hidden',
              type: 'text',
              hidden: true,
            },
          );
        } else if (this.sender.model.cc_single.length) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'label',
              class: 'full',
              supplier: () => (student.emailSub ? `${student.lastName + student.firstName} サブ <${student.emailSub}>` : ''),
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
              showOn: student ? { cc_single_hidden: null } : null,
            },
          );
        }
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const template = getValue('dummyTemplateType');
                return template ? value || template.subject : undefined;
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => this.sender.model.subject,
            },
      ],
    });

    const fileRow = {
      title: '添付ファイル',
      class: 'ou',
      fields: [],
    };
    metaData.groups[0].rows.push(fileRow);

    if (this.sender.inConfirm) {
      fileRow.fields.push({
        type: 'label',
        name: 'zip',
        class: 'link',
        clickAction: () =>
          this.sender.jobApiService.getJobsAsPdf(this.sender.model.jobs.map((j) => j.id)).subscribe((res) => downloadResponse(res)),
        default: '求人票.pdf',
      });
    }
    const entField = this.sender.dynamicService.getFormRows(
      {
        fieldType: 'multi-enterprise',
        label: '添付ファイル',
        fieldName: 'enterprises',
      },
      null,
      'half',
    )[0].fields[0];
    if (!this.sender.inConfirm) {
      fileRow.fields.push(entField);
      entField.valueField = null;
      entField.labelBefore = '企業：';
      entField.linkTo = ['enterpriseFileIds'];
      entField.actions = [
        {
          title: 'データフォルダから選択',
          type: 'RUNNABLE',
          allowOn: { enterpriseIds: null },
          runnable: () => {
            this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
            PopupControlComponent.instance.open({
              content: this.sender.fileSelectTemplate,
              confirmText: '添付',
              cancelText: 'キャンセル',
              title: 'データフォルダ',
              confirmCallback: () => {
                this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
              },
            });
          },
        },
      ];
    }

    if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
      fileRow.fields.push({
        name: 'enterpriseFileIds',
        type: 'autocomplete',
        labelField: [
          {
            name: 'name',
            class: this.sender.inConfirm ? 'link' : null,
            action: this.sender.inConfirm
              ? (file) =>
                  this.sender.fileService.downloadFile(
                    'enterprise',
                    +Object.keys(this.sender.enterpriseFiles).find(
                      (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                    ),
                    file.id,
                    file.name,
                  )
              : null,
          },
        ],
        labelBefore: this.sender.inConfirm ? '企業：' : null,
        valueField: 'id',
        multi: true,
        style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
        class: 'options-only half',
        options: [],
        supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
          const enterprises = getValue('enterprises');
          this.sender.model.enterpriseIds = enterprises
            ? enterprises.map((e) => {
                this.sender.enterpriseInfoById[e.id] = `${e.frontId} ${e.name}`;
                return e.id;
              })
            : [];
          const ids = this.sender.model.enterpriseIds;
          const selectedFiles = (Object.values(this.sender.enterpriseFiles).flatten() as any).filter((f) =>
            this.sender.model.enterpriseFileIds.includes(f.id),
          );
          if (!ids || !ids.length) {
            return { options: selectedFiles };
          } else {
            return forkJoin(
              ids.map((id) =>
                this.sender.fileService.getFiles('enterprise', id).pipe(
                  map((res) => {
                    this.sender.enterpriseFiles[id] = res.filter((f) => f.studentSendFlag);
                    return this.sender.enterpriseFiles[id];
                  }),
                ),
              ),
            ).pipe(map((val) => ({ options: val.flatten() })));
          }
        },
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const template = getValue('dummyTemplateType');
              return template ? value || template.body : undefined;
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }, ...res];
                      return {
                        value: value ? res.find((s) => s.id === value.id) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }

    if (!this.sender.inConfirm) {
      metaData.groups.unshift({
        title: '選択した求人',
        class: 'no-title-column',
        rows: [
          {
            fields: this.sender.model.jobs.map((job: any, index: string) => ({
              name: 'jobs' + index,
              type: 'textarea',
              class: 'quarter',
              disabled: true,
              supplier: () => `${job.frontId}\n${job.position}\n${job.enterpriseName}`,
            })),
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public update(): void {
    if (!this.sender.model.student) {
      return;
    }
    if (this.sender.model.student.emailMain && !this.sender.model.student.emailMainAvailable) {
      this.sender.model.to = [this.sender.model.student.emailMain];
    }
    if (this.sender.model.student.emailSub && !this.sender.model.student.emailSubAvailable) {
      this.sender.model.cc_single = [this.sender.model.student.emailSub];
    }
    if (this.sender.model.student.studentUser && this.sender.model.student.studentUser.length) {
      this.sender.model.cc = [this.sender.model.student.studentUser[0].userId];
    }
  }

  public save(toSave: any): void {
    toSave.jobIds = this.sender.model.jobs.map((j) => j.id);
    this.sender.mailApiService.sendJobStudentsBulkMail(toSave).subscribe((result) => {
      this.sender.progressApiService
        .updateWithAction(this.sender.model.progressId, {
          action: 13,
          type: this.sender.model.progressType,
          hasInterview: this.sender.model.hasInterview,
          n: this.sender.model.n,
          seminarType: this.sender.model.seminarType,
          seminarAt: this.sender.model.seminarAt,
          seminarAtMailSendFlag: this.sender.model.seminarAtMailSendFlag,
          isStudentDelay: this.sender.model.isStudentDelay,
        })
        .subscribe(() => this.sender.afterSend(result));
    });
  }
}
