import { SideActionConfig } from '@agent-ds/shared/components/page-floater/page-floater-interface';
import { TableColumn, TableRow } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { APPLICATION_TYPES } from '@agent-ds/shared/constants';
import { CounselingBatchResponse, Job, SeminarStudentApplyRequest } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, JobApiService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobRegistrationDialogComponent } from '../job-registration-dialog/job-registration-dialog.component';

@Component({
  selector: 'ag-seminar-job-application',
  templateUrl: './seminar-job-application.component.html',
  styleUrls: ['./seminar-job-application.component.scss'],
})
export class SeminarJobApplicationComponent extends DetailPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('selector', { static: false }) selectorTemplate: TemplateRef<any>;
  @ViewChild('applicationsSummary', { static: false }) applicationsSummaryTemplate: TemplateRef<any>;
  @ViewChild('errors', { static: false }) errorsTemplate: TemplateRef<any>;
  @ViewChild('remarks', { static: false }) remarksTemplate: TemplateRef<any>;

  jobs: (Job & { application?: number; isSpecific?: number })[] = [];
  navSubscription: Subscription;
  seminarId: any;
  area: any;
  selectorColumnConfig: TableColumn[];
  remarksRowConfig: TableRow[];
  requestObject: any = {};
  model: { [key: number]: any } = [];
  applications: { id: number; name: string }[] = Object.keys(APPLICATION_TYPES).map((key) => ({
    id: +key,
    name: APPLICATION_TYPES[key],
  }));
  readOnly: boolean;
  readonly APPLICATION_TYPES = APPLICATION_TYPES;
  listConfiguration = {
    body: {
      hideCreateButton: true,
      arrow: false,
      hideRefreshButton: true,
      hideActionsHeader: true,
      hideAllSort: true,
      noSorting: true,
    },
    new: false,
    favorites: false,
    persons_in_charge: false,
    recruitYear: false,
  };
  errorJobs = [];
  applicationsSummaryJobs: { apply: (Job & { application?: number })[]; caNg: (Job & { application?: number })[] };

  public sideActions: SideActionConfig[] = [{ phases: ['100%'] }];
  protected tabs: any = {};
  protected urlTag = '';

  private jobRegistrationDialogSubscription: Subscription;
  private jobRefreshSubscription: Subscription;

  constructor(
    private counselingApiService: CounselingApiService,
    private chRef: ChangeDetectorRef,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    private dialog: DialogService,
    private jobApiService: JobApiService,
  ) {
    super(router, counselingApiService, activeRoute);
  }

  ngOnInit() {
    this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(() => this.getJobs());
    this.jobRefreshSubscription = this.jobApiService.refreshEvent.subscribe(() => this.getJobs());
  }

  ngAfterViewInit() {
    this.selectorColumnConfig = [
      {
        fields: [
          {
            name: 'application',
            title: '応募・辞退',
            cellTemplate: this.selectorTemplate,
            sortable: false,
          },
        ],
        style: {
          width: '150px',
          'margin-right': '10px',
        },
        bodyStyle: {
          padding: '3px',
        },
      },
    ];
    this.remarksRowConfig = [
      {
        hidden: true,
        columns: [
          {
            fields: [
              {
                name: 'remarks',
                hidden: true,
                cellTemplate: this.remarksTemplate,
                sortable: false,
              },
            ],
            style: {
              'margin-right': '10px',
              width: '100%',
            },
            bodyStyle: {
              padding: '4px 11px',
            },
          },
        ],
        clickAction: (event: MouseEvent, data: any) => event.stopPropagation(),
      },
    ];
    this.chRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.jobRegistrationDialogSubscription) {
      this.jobRegistrationDialogSubscription.unsubscribe();
      this.jobRegistrationDialogSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    if (this.jobRefreshSubscription) {
      this.jobRefreshSubscription.unsubscribe();
      this.jobRefreshSubscription = null;
    }
  }

  protected fill(onRefresh?: boolean): void {
    this.getJobs();
  }

  saveApplications(): void {
    if (!this.jobs || !this.jobs.length) {
      return;
    }
    this.applicationsSummaryJobs = {
      apply: this.jobs.filter((job) => this.model[job.id].application === 1),
      caNg: this.jobs.filter((job) => this.model[job.id].application === 2),
    };
    PopupControlComponent.instance.open({
      title: '応募、CA NG、辞退',
      content: this.applicationsSummaryTemplate,
      confirmText: '確定',
      confirmCallback: () => {
        PopupControlComponent.instance.close();
        const studentApplyRequestObj: SeminarStudentApplyRequest = {
          jobs: Object.keys(this.model).map((jobId) => ({
            jobId: +jobId,
            application: this.model[jobId].application,
            remarks: this.model[jobId].job.remarks,
          })),
        };
        return this.counselingApiService
          .updateSeminarJobApplicationsForStudent(this.referenceId, this.data.seminar.id, studentApplyRequestObj)
          .pipe(
            tap((res: CounselingBatchResponse & { mailErrorIds?: number[] }) => {
              this.errorJobs = [];
              if (res.errorIds && res.errorIds.length) {
                this.errorJobs = res.errorIds.map((id) => this.jobs.find((job) => job.id === id));
                PopupControlComponent.instance.open({
                  content: this.errorsTemplate,
                  confirmText: '閉じる',
                  confirmCallback: () => {
                    if (res.mailErrorIds && res.mailErrorIds.length) {
                      this.openMailErrorPopup();
                    }
                    return null;
                  },
                  title: '求人応募',
                  height: '357px',
                  width: '600px',
                });
              } else if (res.mailErrorIds && res.mailErrorIds.length) {
                this.openMailErrorPopup();
              }
              this.data.seminar.isApplied = 1;
              this.counselingApiService.refreshEvent.next();
            }),
          );
      },
      cancelText: 'キャンセル',
      height: '640px',
      width: '800px',
    });
  }

  deleteJob(job: Job): void {
    if (!job || this.referenceId == null || !this.data || !this.data.seminar) {
      return;
    }
    PopupControlComponent.instance.open({
      title: 'データの削除',
      content: '選択した求人をセミナー紹介求人から削除します。\nよろしいですか？',
      confirmText: '確定',
      confirmCallback: () =>
        this.counselingApiService
          .deleteSeminarJobsForStudent(this.referenceId, this.data.seminar.id, job.id)
          .pipe(tap(() => this.counselingApiService.refreshEvent.next())),
      cancelText: 'キャンセル',
      height: '357px',
      width: '600px',
    });
  }

  openSeminarJobRegistrationDialog(): void {
    if (this.referenceId == null || !this.data || !this.data.seminar) {
      return;
    }
    const data: any = {
      seminarId: this.data.seminar.id,
      studentId: this.referenceId,
      addRequestFn: this.counselingApiService.addSeminarJobsForStudent.bind(this.counselingApiService),
    };
    const jobRegistrationDialogRef = this.dialog.open(JobRegistrationDialogComponent, { data: data });
    this.jobRegistrationDialogSubscription = jobRegistrationDialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.counselingApiService.refreshEvent.next();
      }
    });
  }

  private openMailErrorPopup(): void {
    PopupControlComponent.instance.open({
      content: '推薦依頼メールの送信に失敗しました。',
      confirmText: '閉じる',
      confirmCallback: () => null,
      title: '求人応募',
      height: '357px',
      width: '600px',
    });
  }

  private getJobs(): void {
    this.jobs = [];
    this.model = [];
    if (this.referenceId && this.data && this.data.seminar) {
      this.readOnly = this.readonly = this.data.seminar && !!this.data.seminar.isApplied;
      this.counselingApiService.getSeminarJobsForStudent(this.referenceId, this.data.seminar.id).subscribe((jobs) => {
        jobs.forEach((job: any) => {
          this.model[job.id] = { application: 3, job: job };
          // Transform so that job list can display these values
          job.frontJobId = job.frontId;
          job.enterprise = { dynamicData: { industry: job.dynamicData.industry } };
        });
        this.jobs = jobs;
      });
    }
  }
}
