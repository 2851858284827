import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { StudentListPageComponent } from '@agent-ds/student/pages/student-list-page/student-list-page.component';
import { StudentListWithSubUserPageComponent } from '@agent-ds/student/pages/student-list-with-sub-user-page/student-list-with-sub-user-page.component';
import { StudentListWithTodaysCounselingPageComponent } from '@agent-ds/student/pages/student-list-with-todays-counseling-page/student-list-with-todays-counseling-page.component';
import { StudentUploadsPageComponent } from '@agent-ds/student/pages/student-uploads-page/student-uploads-page.component';
import { STUDENT_DETAIL_TABS } from './student-detail-page/tabs';
import { StudentImportCompareComponent } from './student-import-page/student-import-compare/student-import-compare.component';
import { StudentImportDetailComponent } from './student-import-page/student-import-detail/student-import-detail.component';
import { StudentImportPageComponent } from './student-import-page/student-import-page.component';

export * from './student-list-page/student-list-page.component';
export * from './student-list-with-todays-counseling-page/student-list-with-todays-counseling-page.component';
export * from './student-list-with-sub-user-page/student-list-with-sub-user-page.component';
export * from './student-detail-page/student-detail-page.component';
export * from './student-import-page/student-import-page.component';

/**
 * ページコンポーネント群
 */
export const STUDENT_PAGES = [
  StudentListPageComponent,
  StudentListWithTodaysCounselingPageComponent,
  StudentListWithSubUserPageComponent,
  StudentDetailPageComponent,
  StudentImportPageComponent,
  StudentImportCompareComponent,
  StudentImportDetailComponent,
  StudentUploadsPageComponent,
  STUDENT_DETAIL_TABS,
];
