import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { ContactHistory, ContactHistoryAction, ContactHistoryEnterpriseAction } from '@agent-ds/shared/interfaces';
import { TemplateRef } from '@angular/core';

export const CONTACT_HISTORY_TABLE_CONFIG: (
  responsibleTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  messageTemplate: TemplateRef<any>,
  dateTransform: (date: string | Date) => string,
  actions: ContactHistoryAction | ContactHistoryEnterpriseAction,
) => TableConfig = (responsibleTemplate, actionsTemplate, messageTemplate, dateTransform, actions) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'actionAt',
                title: '対応日',
                formatter: (data: ContactHistory) => dateTransform(data.actionAt),
                sortable: false,
              },
            ],
            style: {
              width: '184px',
            },
          },
          {
            fields: [
              {
                name: 'action',
                title: 'アクション',
                formatter: (data: ContactHistory) => actions[data.action],
                sortable: false,
              },
            ],
            style: {
              width: '229px',
            },
            bodyStyle: {
              'font-family': '"Meiryo UI Bold", sans-serif',
              'font-size': '14px',
              'font-weight': '600',
            },
          },
          {
            fields: [
              {
                name: 'team',
                title: '担当',
                cellTemplate: responsibleTemplate,
                sortable: false,
              },
            ],
            style: {
              width: '227px',
            },
          },
          {
            fields: [
              {
                name: 'nextContactAt',
                title: '次回コンタクト日',
                formatter: (data: ContactHistory) => dateTransform(data.nextContactAt),
                sortable: false,
              },
            ],
            style: {
              width: '174px',
            },
          },
          {
            fields: [
              {
                name: 'delete',
                hidden: true,
                cellTemplate: actionsTemplate,
              },
            ],
            style: {
              'flex-grow': '1',
              'text-align': 'right',
            },
          },
        ],
      },
      {
        hidden: true,
        columns: [
          {
            fields: [
              {
                name: 'message',
                hidden: true,
                cellTemplate: messageTemplate,
              },
            ],
            style: {
              width: '100%',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: false,
  },
});
