import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { Branch, DashboardType, StudentSuggestResponse } from '@agent-ds/shared/interfaces';
import { RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { AgePipe } from '@agent-ds/shared/pipes/age.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DynamicField, DynamicFieldCreateRequest, DynamicFieldResponse, DynamicFieldUpdateRequest } from '../../models/dynamic-field';
import { AuthService } from '../auth.service';
import { CompanyApiService } from './company-api.service';
import { JobApiService } from './job-api.service';
import { MasterApiService } from './master-api.service';
import { StudentApiService } from './student-api.service';
import { UserApiService } from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicFieldService {
  private innerDynamicFields: DynamicFieldResponse;
  private fieldsByName: { [key: string]: DynamicField } = {};
  private readonly innerFieldUpdateEvent = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private agePipe: AgePipe,
    private authService: AuthService,
    private masterService: MasterApiService,
    private userService: UserApiService,
    private studentService: StudentApiService,
    private companyService: CompanyApiService,
    private jobService: JobApiService,
  ) {
    this.authService.isLogined().subscribe((loggedIn) => {
      if (loggedIn) {
        this.fetchFieldDefinitions();
      }
    });
  }

  public get fieldUpdateEvent(): Observable<any> {
    return this.innerFieldUpdateEvent;
  }

  public fetchFieldDefinitions(): void {
    this.http.get<DynamicFieldResponse>('/api/master/field-definitions').subscribe((result) => {
      this.innerDynamicFields = result;
      Object.keys(this.innerDynamicFields).forEach((sectionKey) => {
        this.innerDynamicFields[sectionKey].forEach((field: DynamicField) => {
          field.label = field.label.replace('※', '\n※');
          this.fieldsByName[`${sectionKey}-${field.fieldName}`] = field;
        });
      });
      this.innerFieldUpdateEvent.next();
    });
  }

  public getDefinitions(
    model: 'student' | 'enterprise' | 'department' | 'billingAddress' | 'contract' | 'job' | 'progress' | 'sales',
  ): DynamicField[] {
    return this.innerDynamicFields[model];
  }

  public getDefinition(
    model: 'student' | 'enterprise' | 'department' | 'billingAddress' | 'contract' | 'job' | 'progress' | 'sales',
    field: string,
  ): DynamicField {
    const fieldObj = this.fieldsByName[`${model}-${field}`];
    if (!fieldObj) {
      console.warn(`No such dynamic field: ${model}-${field}`);
    }
    return fieldObj;
  }

  public addDefinition(field: DynamicFieldCreateRequest): Observable<DynamicField> {
    return this.http.post<DynamicField>('/api/master/custom-field', field).pipe(tap(() => this.fetchFieldDefinitions()));
  }

  public updateDefinition(id: number, requestObj: DynamicFieldUpdateRequest): Observable<DynamicField> {
    return this.http.put<DynamicField>(`/api/master/custom-field/${id}`, requestObj).pipe(tap(() => this.fetchFieldDefinitions()));
  }

  public deleteDefinition(id: number): Observable<any> {
    return this.http.delete(`/api/master/custom-field/${id}`).pipe(tap(() => this.fetchFieldDefinitions()));
  }

  public updateRegularFieldOption(fieldId: number, option: string): Observable<any> {
    return this.http.put(`/api/master/regular-field/${fieldId}/option`, { option: option }).pipe(tap(() => this.fetchFieldDefinitions()));
  }

  public updateRegularFieldOptionsOrder(fieldId: number, options: string[]): Observable<any> {
    return this.http
      .put(`/api/master/regular-field/${fieldId}/option/order`, { options: options })
      .pipe(tap(() => this.fetchFieldDefinitions()));
  }

  public getFormGroup(dynamicField: DynamicField, baseKey?: string): { title?: string; class?: string; rows: RowMeta[] } {
    if (!dynamicField) {
      console.warn('Dynamic field is empty');
      return { rows: [] };
    }
    return {
      title: dynamicField.label,
      rows: this.getFormRows(dynamicField, baseKey),
    };
  }

  public getFormRows(dynamicField: DynamicField, baseKey?: string, className?: string, customFilter?: (value: any) => boolean): RowMeta[] {
    if (!dynamicField) {
      console.warn('Dynamic field is empty');
      return [];
    }
    const key = (baseKey ? baseKey + '.' : '') + dynamicField.fieldName;
    const ret: RowMeta[] = [];
    const baseRow = {
      title: dynamicField.label,
      subTitle:
        dynamicField.fieldType !== 'number' && dynamicField.validationStyle && dynamicField.validationStyle.max
          ? dynamicField.fieldType.startsWith('multi')
            ? `最大${dynamicField.validationStyle.max}人`
            : dynamicField.validationStyle.max + '字まで'
          : null,
      showRequired:
        dynamicField.validationStyle &&
        (dynamicField.validationStyle.required ||
          (dynamicField.validationStyle[dynamicField.fieldType] && dynamicField.validationStyle[dynamicField.fieldType].required)),
      style: dynamicField.displayStyle,
      class: dynamicField.displayStyle && dynamicField.displayStyle.showInJobDescription ? 'bg-yellow' : null,
    };
    const baseField = {
      name: key,
      validators: { ...dynamicField.validationStyle },
      default: dynamicField.defaultValue && JSON.stringify(dynamicField.defaultValue) !== '{}' ? dynamicField.defaultValue : null,
      class: className,
      placeholder: dynamicField.displayStyle ? dynamicField.displayStyle.placeholder : null,
    };
    const options = (dynamicField.validationStyle ? dynamicField.validationStyle.options : []) || [];
    switch (dynamicField.fieldType) {
      case 'text':
      case 'number':
      case 'url':
        if (dynamicField.displayType === 'number-range') {
          ret.push({
            ...baseRow,
            fields: [
              {
                type: dynamicField.displayType.split('-')[0] as any,
                name: key + '.from',
                class: baseField.class,
                labelAfter: dynamicField.unit,
              },
              {
                type: 'label',
                name: key + '.label',
                transparent: true,
                default: '~',
              },
              {
                type: dynamicField.displayType.split('-')[0] as any,
                name: key + '.to',
                class: baseField.class,
                labelAfter: dynamicField.unit,
              },
            ],
          });
        } else {
          ret.push({
            ...baseRow,
            fields: [
              {
                ...baseField,
                type: dynamicField.displayType as any,
                class: dynamicField.displayType === 'textarea' ? 'fill' : baseField.class,
                labelAfter: dynamicField.unit,
              },
            ],
          });
        }
        break;
      case 'list':
      case 'multi-list':
      case 'branch':
      case 'multi-branch':
      case 'team':
        const isNested = dynamicField.fieldType.includes('branch') || dynamicField.fieldType === 'team';
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: (dynamicField.displayType || 'dropdown').replace(/\+.*/, '') as any,
              options: [
                ...((dynamicField.validationStyle && dynamicField.validationStyle.required) ||
                dynamicField.displayType === 'checkbox' ||
                dynamicField.fieldType.startsWith('multi')
                  ? []
                  : [NULL_SELECTED_VALUE]),
                ...options,
              ],
              labelField: isNested ? 'name' : null,
              valueField: isNested ? 'id' : null,
              class: dynamicField.displayType === 'radio+text' ? 'medium' : baseField.class,
              labelAfter: dynamicField.unit,
              specialOption: dynamicField.displayType === 'radio+text' ? 'その他' : null,
              multi: dynamicField.fieldType.startsWith('multi'),
              placeholder: baseField.placeholder || dynamicField.fieldType === 'team' ? 'チーム名' : '項目を選択',
              supplier:
                dynamicField.fieldType === 'team'
                  ? () =>
                      this.masterService.getTeams().pipe(
                        map((res) => ({
                          options: [
                            ...(dynamicField.validationStyle && dynamicField.validationStyle.required
                              ? []
                              : isNested
                              ? [{ name: NULL_SELECTED_VALUE, id: null }]
                              : [NULL_SELECTED_VALUE]),
                            ...res,
                          ],
                        })),
                      )
                  : dynamicField.fieldType.includes('branch')
                  ? () =>
                      this.masterService.getBranches().pipe(
                        map((res) => ({
                          options: [
                            ...(dynamicField.validationStyle && dynamicField.validationStyle.required
                              ? []
                              : isNested
                              ? [{ name: NULL_SELECTED_VALUE, id: null }]
                              : [NULL_SELECTED_VALUE]),
                            ...res,
                          ],
                        })),
                      )
                  : undefined,
            },
          ],
        });
        break;
      case 'date':
        if (dynamicField.displayType === 'yearmonth') {
          ret.push({
            ...baseRow,
            fields: [
              {
                ...baseField,
                type: 'yearmonth',
                name: key,
              },
            ],
          });
        } else if (dynamicField.displayType === 'year+month') {
          ret.push({
            ...baseRow,
            fields: [
              {
                ...baseField,
                type: 'year',
                name: key + '.0',
                labelAfter: '年',
              },
              {
                ...baseField,
                type: 'month',
                name: key + '.1',
                labelAfter: '月',
              },
            ],
          });
        } else {
          const timeToo = dynamicField.displayType.includes('time');
          const dateToo = dynamicField.displayType.includes('date');
          const todayToo = dynamicField.displayType.includes('today');
          if (dateToo || dynamicField.displayType === 'birthday') {
            ret.push({
              ...baseRow,
              fields: [
                {
                  ...baseField,
                  type: 'date',
                  name: key + (timeToo ? '.0' : ''),
                  actions: todayToo && !timeToo ? [{ type: 'RUNNABLE', title: '今日', runnable: () => new Date().toStartOfDay() }] : null,
                  linkTo: dynamicField.displayType === 'birthday' ? [key + '.age'] : null,
                },
              ],
            });
          }
          if (timeToo) {
            ret[0].fields.push({
              ...baseField,
              type: 'time',
              name: key + (dateToo ? '.1' : ''),
              actions: todayToo
                ? [
                    {
                      type: 'RUNNABLE',
                      title: '今日',
                      runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                        const today = new Date();
                        setValue(key + (dateToo ? '.0' : ''), today);
                        return today;
                      },
                    },
                  ]
                : null,
            });
          }
          if (dynamicField.displayType === 'birthday') {
            ret[0].fields.push({
              ...baseField,
              type: 'label',
              name: key + '.age',
              default: '--',
              labelAfter: '歳',
              transparent: true,
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const linkValue = getValue(key + (timeToo ? '.0' : ''));
                return typeof linkValue === 'string' || (linkValue instanceof Date && !isNaN(linkValue.getTime()))
                  ? this.agePipe.transform(linkValue)
                  : '-';
              },
            });
          }
        }
        break;
      case 'student':
      case 'multi-student':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              multi: dynamicField.fieldType.startsWith('multi'),
              placeholder: baseField.placeholder || '求職者名、求職者カナ、求職者ID',
              options: options,
              class: baseField.class + ' full',
              specialOption: '1',
              labelField: [
                { name: 'frontId', class: 'hollow w-59', skipInFilter: true },
                { name: 'name', class: 'w-100', supplier: (s: StudentSuggestResponse) => s.lastName + s.firstName, skipInFilter: true },
                {
                  name: 'schoolName',
                  class: 'full',
                  skipInFilter: true,
                },
                {
                  name: 'departmentName',
                  class: 'full',
                  skipInFilter: true,
                },
                {
                  name: 'dummy',
                  class: 'hollow',
                  supplier: () => '担当',
                  skipInFilter: true,
                },
                {
                  name: 'responsible',
                  class: 'w-131',
                  supplierAsync: (s: StudentSuggestResponse) => {
                    if (s.studentUser && s.studentUser.length) {
                      return this.userService
                        .getAll()
                        .pipe(map((users) => (users.find((u) => u.id === s.studentUser[0].userId) || { name: '-' }).name));
                    } else {
                      return of('');
                    }
                  },
                  skipInFilter: true,
                },
              ],
              valueField: dynamicField.displayType === 'email' ? null : 'id',
              supplier: (value: any) => {
                // 通常の求職者の場合、通常のサジェストAPIを呼び、インテークCAの場合、専用のサジェストAPIを呼ぶ関数
                const suggetWrapper = (keyword: string, ids?: number[]) => {
                  if (this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA) {
                    return this.studentService.getSuggestionsForIntakeCa(keyword, ids);
                  } else {
                    return this.studentService.getSuggestions(keyword, ids);
                  }
                };

                if (Array.isArray(value)) {
                  return value.length && typeOf(value[0]) === 'object'
                    ? suggetWrapper(undefined, value.map((v) => v.id)).pipe(map((res) => ({ options: res })))
                    : suggetWrapper(undefined, value).pipe(map((res) => ({ options: res })));
                } else if (typeOf(value) === 'object') {
                  return suggetWrapper(undefined, [value.id]).pipe(map((res) => ({ options: res })));
                } else if (typeof value === 'number') {
                  return suggetWrapper(undefined, [value]).pipe(map((res) => ({ options: res })));
                } else {
                  return suggetWrapper(value).pipe(map((res) => ({ options: res })));
                }
              },
            },
          ],
        });
        break;
      case 'enterprise':
      case 'multi-enterprise':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              multi: dynamicField.fieldType.startsWith('multi'),
              options: options,
              class: baseField.class + ' full',
              placeholder: baseField.placeholder || '企業ID、企業No.、企業名、企業カナ',
              specialOption: '1',
              labelField: [
                { name: 'frontId', title: '企業ID', class: 'hollow w-50', skipInFilter: true },
                { name: 'name', title: '企業名', class: 'full', skipInFilter: true },
                { name: 'enterpriseNo', title: '企業No.', class: 'w-84', skipInFilter: true },
              ],
              valueField: 'id',
              supplier: (value: any) => {
                if (Array.isArray(value)) {
                  return this.companyService
                    .getSuggestions(undefined, typeof value[0] === 'number' ? value : value.map((v) => v.id))
                    .pipe(map((res) => ({ options: res })));
                } else if (typeof value === 'number') {
                  return this.companyService.getSuggestions(undefined, [value]).pipe(map((res) => ({ options: res })));
                } else {
                  return this.companyService.getSuggestions(value).pipe(map((res) => ({ options: res })));
                }
              },
            },
          ],
        });
        break;
      case 'job':
      case 'multi-job':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              multi: dynamicField.fieldType.startsWith('multi'),
              options: options,
              class: baseField.class + ' full',
              placeholder: baseField.placeholder || 'ID、求人名、企業名',
              specialOption: '1',
              labelField: [
                { name: 'frontId', class: 'hollow w-55', skipInFilter: true },
                { name: 'position', class: 'full', skipInFilter: true },
                { name: 'enterpriseName', class: 'full', skipInFilter: true },
              ],
              valueField: dynamicField.displayType === 'email' ? null : 'id',
              supplier: (value: any, callType: SupplierCallType, getter, setter, filters) => {
                const fn = (filters &&
                (filters.status === '募集中' || (filters.status && filters.status.length && filters.status.includes('募集中')))
                  ? this.jobService.getSuggestionsRecruitment
                  : this.jobService.getSuggestions
                ).bind(this.jobService);
                const enterpriseIds = filters ? filters.enterpriseIds : undefined;
                if (Array.isArray(value)) {
                  return value.length && typeOf(value[0]) === 'object'
                    ? fn(undefined, value.map((v) => v.id)).pipe(map((res) => ({ options: res })))
                    : fn(undefined, value).pipe(map((res) => ({ options: res })));
                } else if (typeOf(value) === 'object') {
                  return fn(undefined, [value.id]).pipe(map((res) => ({ options: res })));
                } else if (typeof value === 'number') {
                  return fn(undefined, [value]).pipe(map((res) => ({ options: res })));
                } else {
                  return fn(value, undefined, enterpriseIds).pipe(map((res) => ({ options: res })));
                }
              },
            },
          ],
        });
        break;
      case 'multi-team':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              multi: true,
              options: options,
              class: baseField.class + ' full',
              customTooltipErrorMessage: '該当する担当チームが見つかりません',
              placeholder: baseField.placeholder || 'チーム名',
              labelField: 'name',
              valueField: 'id',
              supplier: () => this.masterService.getTeams().pipe(map((res) => ({ options: res }))),
            },
          ],
        });
        break;
      case 'station':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              options: options,
              placeholder: baseField.placeholder || '駅名',
              labelField: 'name',
              valueField: 'name',
              manualInputOn: '',
              supplier: (value: string) =>
                value ? this.masterService.searchStations(value).pipe(map((res) => ({ options: res }))) : undefined,
            },
          ],
        });
        break;
      case 'user':
      case 'multi-user':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'autocomplete',
              multi: dynamicField.fieldType.startsWith('multi'),
              options: options,
              class: baseField.class + ' full',
              customTooltipErrorMessage: '該当する担当者が見つかりません',
              placeholder: baseField.placeholder || '氏名、E-mail',
              labelField: [
                { name: 'name', class: dynamicField.displayType === 'email' ? '' : 'full' },
                ...(dynamicField.displayType === 'email'
                  ? [
                      {
                        name: 'email',
                        supplier: (user) => user.email,
                      },
                    ]
                  : []),
                {
                  name: 'team',
                  class: 'full hollow',
                  supplierAsync: (usr) =>
                    this.masterService
                      .getTeams()
                      .pipe(map((teams) => (teams.find((team) => team.id === usr.teamId) || { name: usr.teamId }).name)),
                },
                {
                  name: 'email',
                  hidden: true,
                },
              ],
              valueField: 'id',
              filters: [
                {
                  name: 'office',
                  labelBefore: '拠点',
                  options: [],
                  labelField: 'name',
                  class: 'w-104',
                  linkTo: ['teamId'],
                  transparent: true,
                  supplier: () =>
                    this.masterService.getBranches().pipe(
                      map((branches) => ({
                        options: [{ id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }, ...branches],
                        value: NULL_SELECTED_VALUE,
                      })),
                    ),
                },
                {
                  name: 'teamId',
                  labelBefore: '部署',
                  options: [],
                  labelField: 'name',
                  valueField: 'id',
                  class: 'w-152',
                  supplier: (linkValue: Branch) =>
                    linkValue
                      ? this.masterService.getTeams().pipe(
                          map((teams) => ({
                            options: [
                              { id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE },
                              ...teams.filter((t) => t.branch.id === linkValue.id),
                            ],
                            value: NULL_SELECTED_VALUE,
                          })),
                        )
                      : { options: [{ id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }], value: NULL_SELECTED_VALUE },
                },
                {
                  name: 'status',
                  hidden: true,
                  options: [[1, 3]],
                  supplier: () => 0,
                },
              ],
              supplier: (value: string) =>
                this.userService.getAll().pipe(
                  map((res) => ({
                    options: res.filter((u) => (customFilter ? customFilter(u) : true)),
                  })),
                ),
            },
          ],
        });
        break;
      case 'industry':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'dropdown',
              options: [],
              valueField: 'code',
              labelField: 'name',
              placeholder: baseField.placeholder || '項目を選択',
              multi: dynamicField.displayType && dynamicField.displayType.startsWith('multi'),
              supplier: (value: any) =>
                this.masterService.getFlattenedIndustryTypes().pipe(
                  map((res) => ({
                    options: [
                      ...((dynamicField.validationStyle && dynamicField.validationStyle.required) ||
                      (dynamicField.displayType && dynamicField.displayType.startsWith('multi'))
                        ? []
                        : [{ name: NULL_SELECTED_VALUE, code: NULL_SELECTED_VALUE }]),
                      ...res,
                    ],
                  })),
                ),
            },
          ],
        });
        break;
      case 'job-type':
        ret.push({
          ...baseRow,
          fields:
            !dynamicField.displayType || !dynamicField.displayType.startsWith('multi')
              ? [
                  {
                    ...baseField,
                    type: 'dropdown',
                    placeholder: baseField.placeholder || '項目を選択',
                    name: key + '.code1',
                    options: [],
                    valueField: 'code',
                    labelField: 'name',
                    linkTo: [key + '.code2'],
                    supplier: (value: any) => {
                      return this.masterService.getJobTypes().pipe(
                        map((res) => {
                          return {
                            options:
                              dynamicField.validationStyle && dynamicField.validationStyle.required
                                ? res
                                : [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE, jobTypes: [] }, ...res],
                          };
                        }),
                      );
                    },
                  },
                  {
                    ...baseField,
                    type: 'dropdown',
                    placeholder: baseField.placeholder || '項目を選択',
                    name: key + '.code2',
                    options: [],
                    default: NULL_SELECTED_VALUE,
                    valueField: 'code',
                    labelField: 'name',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                      const linkValue = getValue(key + '.code1');
                      return linkValue
                        ? this.masterService.getJobTypes().pipe(
                            map((res) => {
                              const mainType = res.find((r) => r.code === linkValue) || { jobTypes: [] };
                              return {
                                value: mainType ? (mainType.jobTypes.find((j) => j.code === value) || { code: null }).code : null,
                                options: mainType.jobTypes
                                  ? [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }, ...mainType.jobTypes]
                                  : [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }],
                              };
                            }),
                          )
                        : { options: [], value: null };
                    },
                  },
                ]
              : [
                  {
                    ...baseField,
                    type: 'dropdown',
                    placeholder: baseField.placeholder || '項目を選択',
                    name: key,
                    multi: true,
                    options: [],
                    valueField: 'code',
                    labelField: 'name',
                    supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) =>
                      this.masterService.getJobTypes().pipe(
                        map((res) => ({
                          options: res.flatten((subType, level) => (subType['_selectLevel'] = level), 'jobTypes'),
                        })),
                      ),
                  },
                ],
        });
        break;
      case 'address':
        ret.push(
          {
            ...baseRow,
            title: baseField.name === 'onVacationAddress' ? '休暇中郵便番号・都道府県' : '郵便番号・都道府県',
            fields: [
              {
                ...baseField,
                type: 'zip',
                name: key + '.zip.0',
              },
              {
                ...baseField,
                type: 'label',
                name: key + '.txt',
                default: '-',
                transparent: true,
              },
              {
                ...baseField,
                type: 'zip',
                name: key + '.zip.1',
                linkTo: [key + '.prefecture'],
                actions: [
                  {
                    type: 'UPDATE_LINKED',
                    title: '検索',
                  },
                ],
              },
              {
                ...baseField,
                type: 'dropdown',
                name: key + '.prefecture',
                linkTo: [key + '.address1', key + '.address2'],
                valueField: { prefecture: 'prefecture' },
                labelField: 'prefecture',
                transparent: true,
                options: [
                  { prefecture: NULL_SELECTED_VALUE },
                  { prefecture: '北海道' },
                  { prefecture: '青森県' },
                  { prefecture: '岩手県' },
                  { prefecture: '宮城県' },
                  { prefecture: '秋田県' },
                  { prefecture: '山形県' },
                  { prefecture: '福島県' },
                  { prefecture: '茨城県' },
                  { prefecture: '栃木県' },
                  { prefecture: '群馬県' },
                  { prefecture: '埼玉県' },
                  { prefecture: '千葉県' },
                  { prefecture: '東京都' },
                  { prefecture: '神奈川県' },
                  { prefecture: '新潟県' },
                  { prefecture: '富山県' },
                  { prefecture: '石川県' },
                  { prefecture: '福井県' },
                  { prefecture: '山梨県' },
                  { prefecture: '長野県' },
                  { prefecture: '岐阜県' },
                  { prefecture: '静岡県' },
                  { prefecture: '愛知県' },
                  { prefecture: '三重県' },
                  { prefecture: '滋賀県' },
                  { prefecture: '京都府' },
                  { prefecture: '大阪府' },
                  { prefecture: '兵庫県' },
                  { prefecture: '奈良県' },
                  { prefecture: '和歌山県' },
                  { prefecture: '鳥取県' },
                  { prefecture: '島根県' },
                  { prefecture: '岡山県' },
                  { prefecture: '広島県' },
                  { prefecture: '山口県' },
                  { prefecture: '徳島県' },
                  { prefecture: '香川県' },
                  { prefecture: '愛媛県' },
                  { prefecture: '高知県' },
                  { prefecture: '福岡県' },
                  { prefecture: '佐賀県' },
                  { prefecture: '長崎県' },
                  { prefecture: '熊本県' },
                  { prefecture: '大分県' },
                  { prefecture: '宮崎県' },
                  { prefecture: '鹿児島県' },
                  { prefecture: '沖縄県' },
                ],
                placeholder: baseField.placeholder || '項目を選択',
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.zip.1');
                  return callType === SupplierCallType.LINK_CHANGE
                    ? this.masterService
                        .getAddressByZip(linkValue)
                        .pipe(
                          map((result) =>
                            result.results && result.results.length
                              ? result.results[0]
                              : typeOf(value) === 'object'
                              ? value
                              : value
                              ? { prefecture: value }
                              : undefined,
                          ),
                        )
                    : typeOf(value) === 'object'
                    ? value
                    : value
                    ? { prefecture: value }
                    : undefined;
                },
              },
            ],
          },
          {
            ...baseRow,
            title: baseField.name === 'onVacationAddress' ? '休暇中市区町村' : '市区町村',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.address1',
                class: 'fill',
                supplier: (value: string, callType: SupplierCallType, getValue?: (key: string, override?: boolean) => any) => {
                  const linkValue = getValue(key + '.prefecture');
                  return linkValue ? (linkValue.address1 ? linkValue.address1 : undefined) : undefined;
                },
              },
            ],
          },
          {
            ...baseRow,
            title: baseField.name === 'onVacationAddress' ? '休暇中丁目番地号' : '丁目番地号',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.address2',
                class: 'fill',
                supplier: (value: string, callType: SupplierCallType, getValue?: (key: string, override?: boolean) => any) => {
                  const linkValue = getValue(key + '.prefecture');
                  return linkValue ? (linkValue.address2 ? linkValue.address2 : undefined) : undefined;
                },
              },
            ],
          },
          {
            ...baseRow,
            title: baseField.name === 'onVacationAddress' ? '休暇中建物名・部屋番号' : '建物名・部屋番号',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.address3',
                class: 'fill',
              },
            ],
          },
        );
        break;
      case 'from-station':
        ret.push({
          ...baseRow,
          fields: [
            {
              type: 'dropdown',
              name: key + '.method',
              options: [NULL_SELECTED_VALUE, '徒歩', 'バス'],
            },
            {
              type: 'number',
              name: key + '.minute',
              labelAfter: '分',
            },
          ],
        });
        break;
      case 'tel':
      case 'email':
        ret.push({
          ...baseRow,
          fields: [
            {
              type: 'text',
              name: `${key}.${dynamicField.fieldType}`,
              class: 'fill',
              validators:
                baseField.validators && baseField.validators[dynamicField.fieldType]
                  ? baseField.validators[dynamicField.fieldType]
                  : baseField.validators,
            },
            {
              type: 'dropdown',
              name: `${key}.${dynamicField.fieldType}Available`,
              options: [NULL_SELECTED_VALUE, '不可'],
              validators: baseField.validators && baseField.validators[dynamicField.fieldType] ? null : baseField.validators,
            },
          ],
        });
        break;
      case 'academic':
        ret.push(
          {
            ...baseRow,
            title: '学校名',
            fields: [
              {
                ...baseField,
                type: 'autocomplete',
                name: key + '.schoolCode',
                class: 'half',
                valueField: { schoolName: 'name', schoolCode: 'code' },
                labelField: 'name',
                linkTo: [key + '.departmentCode'],
                options: [],
                transparent: true,
                manualInputOn: 'name',
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const root = getValue ? getValue(key) : null;
                  const schoolName = root ? root.schoolName : null;
                  return this.masterService.suggestSchools(typeof value === 'string' ? value : schoolName).pipe(
                    map((res) => ({
                      value: res.find((r) => r.code === value) || schoolName ? { name: schoolName } : undefined,
                      options: res,
                    })),
                  );
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '学部',
            fields: [
              {
                ...baseField,
                type: 'autocomplete',
                name: key + '.departmentCode',
                class: 'half',
                valueField: { departmentName: 'name', departmentCode: 'code' },
                labelField: 'name',
                linkTo: [key + '.subDepartmentCode'],
                options: [],
                transparent: true,
                manualInputOn: 'name',
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.schoolCode');
                  const root = getValue ? getValue(key) : null;
                  const departmentName = root ? root.departmentName : null;
                  return linkValue
                    ? {
                        value:
                          (value && linkValue.departments ? linkValue.departments : []).find((r) => r.code === value) || departmentName
                            ? { name: departmentName }
                            : null,
                        options: linkValue.departments,
                      }
                    : departmentName
                    ? { name: departmentName }
                    : null;
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '学科・専攻',
            fields: [
              {
                ...baseField,
                type: 'autocomplete',
                name: key + '.subDepartmentCode',
                class: 'half',
                valueField: { subDepartmentName: 'name', subDepartmentCode: 'code' },
                labelField: 'name',
                options: [],
                manualInputOn: 'name',
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.departmentCode');
                  const root = getValue ? getValue(key) : null;
                  const subDepartmentName = root ? root.subDepartmentName : null;
                  return linkValue
                    ? {
                        value:
                          (value && linkValue.subDepartments ? linkValue.subDepartments : []).find((r) => r.code === value) ||
                          subDepartmentName
                            ? { name: subDepartmentName }
                            : null,
                        options: linkValue.subDepartments,
                      }
                    : subDepartmentName
                    ? { name: subDepartmentName }
                    : null;
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '卒業年',
            fields: [
              {
                ...baseField,
                type: 'year',
                name: key + '.graduateYear',
                labelAfter: '年',
              },
              {
                ...baseField,
                type: 'month',
                name: key + '.graduateMonth',
                labelAfter: '月',
              },
              {
                ...baseField,
                type: 'radio',
                name: key + '.graduateType',
                multi: true,
                options: [NULL_SELECTED_VALUE, '卒業見込', '卒業', '中退', '転学'],
              },
            ],
          },
        );
        break;
      case 'certificate':
        ret.push(
          {
            ...baseRow,
            title: '資格',
            fields: [
              {
                ...baseField,
                type: 'autocomplete',
                name: key + '.code',
                class: 'half',
                valueField: { name: 'name', code: 'code' },
                labelField: 'name',
                linkTo: [key + '.gradeCode'],
                options: [],
                supplier: (value: string) =>
                  this.masterService.getCertificates().pipe(
                    map((certs) => {
                      const opts = (certs || []).removeSame('code');
                      return {
                        value: opts.find((r) => r.code === value),
                        options: opts,
                      };
                    }),
                  ),
              },
            ],
          },
          {
            ...baseRow,
            title: '資格等級',
            fields: [
              {
                ...baseField,
                type: 'dropdown',
                name: key + '.gradeCode',
                class: 'half',
                valueField: { gradeName: 'gradeName', gradeCode: 'gradeCode' },
                labelField: 'gradeName',
                options: [],
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.code');
                  return linkValue
                    ? this.masterService.getCertificates().pipe(
                        map((certs) => {
                          const opts = certs.filter((cert) => cert.code === linkValue);
                          return {
                            value: opts.find((opt) => opt.gradeCode === value),
                            options:
                              baseField.validators && baseField.validators.required
                                ? opts
                                : [{ gradeName: NULL_SELECTED_VALUE, gradeCode: null }, ...opts],
                          };
                        }),
                      )
                    : { options: [] };
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '取得年月',
            fields: [
              {
                ...baseField,
                type: 'year',
                name: key + '.issueYear',
                labelAfter: '年',
                allowOn: { [key + '.code']: null },
              },
              {
                ...baseField,
                type: 'month',
                name: key + '.issueMonth',
                labelAfter: '月',
                allowOn: { [key + '.code']: null },
              },
            ],
          },
        );
        break;
      case 'language':
        ret.push(
          {
            ...baseRow,
            title: '言語',
            fields: [
              {
                ...baseField,
                type: 'dropdown',
                name: key + '.language',
                class: 'half',
                labelField: 'name',
                valueField: { language: 'code' },
                linkTo: [key + '.code'],
                options: [],
                transparent: true,
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string, override?: boolean) => any) => {
                  const linkValue = getValue(key);
                  return linkValue !== undefined
                    ? this.masterService.getLanguageCerts().pipe(
                        map((res) => {
                          const langs = res.map((cert) => cert.language).removeSame('id');
                          const foundBoth = linkValue
                            ? res.find((cert) => cert.code === linkValue.code || cert.language.code === value)
                            : null;
                          const foundSelected = foundBoth || res.find((cert) => cert.language.code === value);
                          const foundForLink = foundBoth || (linkValue ? res.find((cert) => cert.code === linkValue.code) : null);
                          return {
                            value: (foundBoth || foundSelected || foundForLink || { language: null }).language,
                            options:
                              baseField.validators && baseField.validators.required
                                ? langs
                                : [{ name: NULL_SELECTED_VALUE, code: null }, ...langs],
                          };
                        }),
                      )
                    : this.masterService.getLanguages().pipe(
                        map((res) => ({
                          value: undefined,
                          options:
                            baseField.validators && baseField.validators.required
                              ? res
                              : [{ name: NULL_SELECTED_VALUE, code: null }, ...res],
                        })),
                      );
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '語学資格の種類',
            fields: [
              {
                ...baseField,
                type: 'dropdown',
                name: key + '.code',
                class: 'half',
                valueField: { name: 'name', code: 'code' },
                labelField: 'name',
                linkTo: [key + '.gradeCode', key + '.language'],
                options: [],
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.language');
                  return linkValue || value
                    ? this.masterService.getLanguageCerts().pipe(
                        map((certs) => {
                          const opts = linkValue
                            ? certs.filter((cert) => cert.language && cert.language.id === linkValue.id).removeSame('code')
                            : certs.filter((cert) => cert.code === value);
                          return {
                            value: opts.find((opt) => opt.code === value) || null,
                            options:
                              baseField.validators && baseField.validators.required
                                ? opts
                                : [{ name: NULL_SELECTED_VALUE, code: null }, ...opts],
                          };
                        }),
                      )
                    : { options: [], value: null };
                },
              },
            ],
          },
          {
            ...baseRow,
            title: 'レベル',
            fields: [
              {
                ...baseField,
                type: 'dropdown',
                name: key + '.gradeCode',
                class: 'half',
                valueField: { gradeName: 'gradeName', gradeCode: 'gradeCode' },
                labelField: 'gradeName',
                options: [],
                supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                  const linkValue = getValue(key + '.code');
                  const langLinkValue = getValue(key + '.language');
                  return linkValue && langLinkValue
                    ? this.masterService.getLanguageCerts().pipe(
                        map((certs) => {
                          const opts = certs.filter((cert) => cert.code === linkValue && cert.language.id === langLinkValue.id);
                          return {
                            value: opts.find((opt) => opt.gradeCode === value) || null,
                            options:
                              baseField.validators && baseField.validators.required
                                ? opts
                                : [{ gradeName: NULL_SELECTED_VALUE, gradeCode: null }, ...opts],
                          };
                        }),
                      )
                    : { options: [], value: linkValue ? undefined : null };
                },
              },
            ],
          },
          {
            ...baseRow,
            title: '点数',
            fields: [
              {
                ...baseField,
                type: 'number',
                name: key + '.score',
                labelAfter: '点',
                allowOn: { [key + '.gradeCode']: null },
              },
            ],
          },
          {
            ...baseRow,
            title: '取得年月',
            fields: [
              {
                ...baseField,
                type: 'year',
                name: key + '.issueYear',
                labelAfter: '年',
                allowOn: { [key + '.language']: { code: null } },
              },
              {
                ...baseField,
                type: 'month',
                name: key + '.issueMonth',
                labelAfter: '月',
                allowOn: { [key + '.language']: { code: null } },
              },
            ],
          },
        );
        break;
      case 'company-contact':
        ret.push(
          {
            ...baseRow,
            title: '役職',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.position',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '氏名',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.name',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: 'カナ',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.phoneticName',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: 'Tel',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.tel',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: 'Fax',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.fax',
                class: 'fill',
              },
            ],
          },
          ...this.getFormRows(
            {
              label: 'E-mail',
              fieldName: key,
              fieldType: 'email',
              displayStyle: baseRow.style,
              validationStyle: baseField.validators,
            },
            '',
          ),
          {
            ...baseRow,
            title: '備考',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.remarks',
                class: 'fill',
              },
            ],
          },
        );
        break;
      case 'date-range':
      case 'time-range':
        ret.push({
          ...baseRow,
          fields: [
            {
              type: dynamicField.displayType.split('-')[0] as any,
              name: key + '.from',
              linkTo: [key + '-actions'],
            },
            {
              type: 'label',
              name: key + '.label',
              transparent: true,
              default: '~',
            },
            {
              type: dynamicField.displayType.split('-')[0] as any,
              name: key + '.to',
              linkTo: [key + '-actions'],
            },
            {
              name: key + '-actions',
              transparent: true,
              type: 'label',
              hidden: true,
              actions:
                dynamicField.displayType === 'date-advanced'
                  ? [
                      {
                        type: 'RUNNABLE',
                        title: '先月',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.lastMonth();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '今月',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.currentMonth();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'SELECT',
                        title: 'その他',
                        options: ['昨日', '今日', '明日', '来月', '昨年', '今年', '来年'],
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          let tmp = { from: null, to: null };
                          switch (value) {
                            case '昨日':
                              tmp = Date.yesterday();
                              break;
                            case '今日':
                              tmp = Date.today();
                              break;
                            case '明日':
                              tmp = Date.tomorrow();
                              break;
                            case '来月':
                              tmp = Date.nextMonth();
                              break;
                            case '昨年':
                              tmp = Date.lastYear(true);
                              break;
                            case '今年':
                              tmp = Date.currentYear(true);
                              break;
                            case '来年':
                              tmp = Date.nextYear(true);
                              break;
                          }
                          if (tmp.from && tmp.to) {
                            setValue(key + '.from', tmp.from);
                            setValue(key + '.to', tmp.to);
                          } else {
                            tmp = {
                              from: new Date(getValue(key + '.from') || undefined),
                              to: new Date(getValue(key + '.to') || undefined),
                            };
                            const selectKey = key + '-actions.actions.' + 2;
                            if ((!tmp.from.isValid() && tmp.to.isValid()) || (tmp.from.isValid() && !tmp.to.isValid())) {
                              return;
                            }
                            let compare = Date.yesterday();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '昨日');
                              return;
                            }
                            compare = Date.today();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '今日');
                              return;
                            }
                            compare = Date.tomorrow();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '明日');
                              return;
                            }
                            compare = Date.nextMonth();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '来月');
                              return;
                            }
                            compare = Date.lastYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '昨年');
                              return;
                            }
                            compare = Date.currentYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '今年');
                              return;
                            }
                            compare = Date.nextYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '来年');
                              return;
                            }
                            setValue(selectKey, null);
                          }
                        },
                      },
                    ]
                  : dynamicField.displayType === 'date-more-advanced'
                  ? [
                      {
                        type: 'RUNNABLE',
                        title: '先週',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.lastWeek();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '今週',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.currentWeek();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '来週',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.nextWeek();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '先月',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.lastMonth();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '今月',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.currentMonth();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'RUNNABLE',
                        title: '来月',
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          const tmp = Date.nextMonth();
                          setValue(key + '.from', tmp.from);
                          setValue(key + '.to', tmp.to);
                        },
                      },
                      {
                        type: 'SELECT',
                        title: 'その他',
                        options: ['昨日', '今日', '明日', '昨年', '今年', '来年'],
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          let tmp = { from: null, to: null };
                          switch (value) {
                            case '昨日':
                              tmp = Date.yesterday();
                              break;
                            case '今日':
                              tmp = Date.today();
                              break;
                            case '明日':
                              tmp = Date.tomorrow();
                              break;
                            case '昨年':
                              tmp = Date.lastYear(true);
                              break;
                            case '今年':
                              tmp = Date.currentYear(true);
                              break;
                            case '来年':
                              tmp = Date.nextYear(true);
                              break;
                          }
                          if (tmp.from && tmp.to) {
                            setValue(key + '.from', tmp.from);
                            setValue(key + '.to', tmp.to);
                          } else {
                            tmp = {
                              from: new Date(getValue(key + '.from') || undefined),
                              to: new Date(getValue(key + '.to') || undefined),
                            };
                            const selectKey = key + '-actions.actions.' + 6;
                            if ((!tmp.from.isValid() && tmp.to.isValid()) || (tmp.from.isValid() && !tmp.to.isValid())) {
                              return;
                            }
                            let compare = Date.yesterday();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '昨日');
                              return;
                            }
                            compare = Date.today();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '今日');
                              return;
                            }
                            compare = Date.tomorrow();
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '明日');
                              return;
                            }
                            compare = Date.lastYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '昨年');
                              return;
                            }
                            compare = Date.currentYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '今年');
                              return;
                            }
                            compare = Date.nextYear(true);
                            if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                              setValue(selectKey, '来年');
                              return;
                            }
                            setValue(selectKey, null);
                          }
                        },
                      },
                    ]
                  : dynamicField.displayType === 'date-seminar-list'
                  ? [
                      {
                        type: 'SELECT',
                        title: '期間',
                        options: ['今週', '来週', '今月', '先月〜今月', '今年'],
                        runnable: (getValue: (key: string) => any, setValue: (key: string, value: any) => void, value?: any) => {
                          let tmp = { from: null, to: null };
                          switch (value) {
                            case '今週': // 今週 this week
                              tmp = Date.currentWeek();
                              break;
                            case '来週': // 来週 next week
                              tmp = Date.nextWeek();
                              break;
                            case '今月': // 今月 this month 1st to end of month
                              tmp = Date.currentMonth();
                              break;
                            case '先月〜今月': // 先月〜今月 last month-this month, Last month 1-end of this month
                              const { from, ...restOfLastMonth } = Date.lastMonth();
                              const { to, ...restOfCurrentMonth } = Date.currentMonth();
                              tmp = { from: from, to: to };
                              break;
                            case '今年': // 今年 this year, January 1-December 31
                              tmp = Date.currentYear();
                              break;
                          }
                          if (tmp.from && tmp.to) {
                            setValue(key + '.from', tmp.from);
                            setValue(key + '.to', tmp.to);
                          }
                        },
                      },
                    ]
                  : null,
            },
          ],
        });
        break;
      case 'screening-info':
        ret.push(
          {
            ...baseRow,
            title: '会場名',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.location',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '会場住所',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.address',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '会場最寄駅',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.nearestStation',
              },
            ],
          },
          {
            ...baseRow,
            title: '所要時間',
            fields: [
              {
                ...baseField,
                type: 'text',
                name: key + '.duration',
              },
            ],
          },
          {
            ...baseRow,
            title: '緊急連絡先',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.emergencyContact',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '持参物',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.preparation',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: 'その他対応事項',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.remarks',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '対応事項1',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.request1',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '対応事項2',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.request2',
                class: 'fill',
              },
            ],
          },
          {
            ...baseRow,
            title: '対応事項3',
            fields: [
              {
                ...baseField,
                type: 'textarea',
                name: key + '.request3',
                class: 'fill',
              },
            ],
          },
        );
        break;
      case 'interview-info':
        ret.push({
          ...baseRow,
          title: null,
          fields: [
            {
              ...baseField,
              type: 'textarea',
              name: key + '.description',
              class: 'fill',
            },
          ],
        });
        break;
      case 'yes-no':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'radio',
              labelField: 'label',
              valueField: 'value',
              options: [
                { label: NULL_SELECTED_VALUE, value: NULL_SELECTED_VALUE },
                { label: 'あり', value: 1 },
                { label: 'なし', value: 0 },
              ],
            },
          ],
        });
        break;
      case 'certificate-level':
        ret.push({
          ...baseRow,
          fields: [
            {
              ...baseField,
              type: 'dropdown',
              multi: true,
              valueField: 'gradeCode',
              labelField: 'gradeName',
              placeholder: baseField.placeholder || '項目を選択',
              options: [],
              supplier: (value: any) =>
                this.masterService.getLanguageCerts().pipe(
                  map((certs) => ({
                    options: certs
                      .filter((cert) => cert.code === '69012')
                      .removeSame('gradeCode')
                      .map((cert) => ({ gradeCode: cert.gradeCode, gradeName: cert.gradeName })),
                  })),
                ),
            },
          ],
        });
        break;
    }
    return ret;
  }
}
