import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { MailSignature } from '@agent-ds/shared/interfaces';
import { ExportModel, ExportModelLabel, ExportTemplate } from '@agent-ds/shared/interfaces/export';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, ExportApiService, MailApiService } from '@agent-ds/shared/services';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ag-signature-csv-settings-page',
  templateUrl: './signature-csv-settings-page.component.html',
  styleUrls: ['./signature-csv-settings-page.component.scss'],
})
export class SignatureCsvSettingsPageComponent implements OnInit {
  @ViewChild('signatureEditButton', { static: true }) signatureEditButtonTemplate: TemplateRef<any>;
  @ViewChild('csvEditButton', { static: true }) csvEditButtonTemplate: TemplateRef<any>;
  @ViewChild('signatureEditPopup', { static: true }) signatureEditPopupTemplate: TemplateRef<any>;
  @ViewChild('csvEditPopup', { static: true }) csvEditPopupTemplate: TemplateRef<any>;
  @ViewChild('signatureForm', { static: false }) signatureFormComponent: DynamicFormComponent;
  @ViewChild('csvForm', { static: false }) csvFormComponent: DynamicFormComponent;

  signatureTableConfig: TableConfig;
  csvTableConfig: TableConfig;

  signatureMeta: FormMeta = {
    groups: [
      {
        class: 'ou',
        rows: [
          {
            title: '署名タイトル',
            fields: [
              {
                name: 'title',
                type: 'text',
                class: 'full tall',
                validators: { required: true },
              },
            ],
          },
          {
            title: '内容',
            fields: [
              {
                name: 'body',
                type: 'textarea',
                class: 'full',
                validators: { required: true },
                style: {
                  minRows: 15,
                  maxRows: 15,
                },
              },
            ],
          },
        ],
      },
    ],
  };

  templateMeta: FormMeta = {
    groups: [
      {
        class: 'ou',
        rows: [
          {
            title: '出力データ',
            fields: [
              {
                name: 'model',
                type: 'radio',
                valueField: 'value',
                labelField: 'label',
                linkTo: ['baseColumns', 'colums'],
                options: Object.values(ExportModel).map((v) => ({ label: ExportModelLabel[v], value: v })),
                validators: { required: true },
              },
            ],
          },
          {
            title: 'タイトル',
            fields: [
              {
                name: 'name',
                type: 'text',
                class: 'full tall',
                validators: { required: true },
              },
            ],
          },
          {
            title: '出力フィールド\t\t\t\t※ 改行で区切ってください\t\t\t利用可能なフィールド一覧',
            class: 'top',
            fields: [
              {
                name: 'textColumns',
                type: 'textarea',
                class: 'fill mr-50',
                validators: { required: true },
                style: {
                  minRows: 15,
                  maxRows: 15,
                },
                supplier: (value: string, callType: SupplierCallType, getValue: (key: string, override?: boolean) => any) => {
                  const model = getValue('model');
                  if (value && value.split('\n').find((v) => !this.templateFields[model].includes(v))) {
                    return null;
                  }
                },
              },
              {
                name: 'baseColumns',
                type: 'textarea',
                transparent: true,
                disabled: true,
                class: 'fill',
                style: {
                  minRows: 15,
                  maxRows: 15,
                },
                supplier: (value: any, callType: SupplierCallType, getValue: (key: string, override?: boolean) => any) => {
                  const model = getValue('model');
                  return this.templateFields[model] ? this.templateFields[model].join('\n') : '';
                },
              },
            ],
          },
          {
            fields: [
              {
                name: 'isPublic',
                type: 'checkbox',
                labelAfter: '他のユーザーにも公開する',
              },
            ],
          },
        ],
      },
    ],
  };

  signatures: MailSignature[] = [];
  templates: ExportTemplate[] = [];

  selectedSignature: MailSignature;
  selectedTemplate: ExportTemplate;

  templateFields: { [key: string]: string[] } = {};

  constructor(
    private datePipe: SafeDatePipe,
    private mailService: MailApiService,
    private exportService: ExportApiService,
    public readonly authService: AuthService,
  ) {}

  ngOnInit() {
    this.signatureTableConfig = {
      head: {
        config: [
          {
            columns: [
              {
                fields: [
                  {
                    name: 'title',
                    title: '署名タイトル',
                    sortable: false,
                  },
                ],
                style: {
                  flex: 1,
                },
              },
              {
                fields: [
                  {
                    name: 'actions',
                    hidden: true,
                    cellTemplate: this.signatureEditButtonTemplate,
                  },
                ],
              },
            ],
          },
        ],
      },
      body: {
        checkbox: false,
        arrow: false,
      },
    };

    this.csvTableConfig = {
      head: {
        config: [
          {
            columns: [
              {
                fields: [
                  {
                    name: 'model',
                    title: '出力データ',
                    formatter: (data) => ExportModelLabel[data.model],
                  },
                ],
                style: {
                  width: '110px',
                },
              },
              {
                fields: [
                  {
                    name: 'isPublic',
                    title: '公開非公開',
                    formatter: (data) => (data.isPublic ? '公開' : '非公開'),
                    sortable: false,
                  },
                ],
                style: {
                  width: '110px',
                },
              },
              {
                fields: [
                  {
                    name: 'name',
                    title: 'タイトル',
                    sortable: false,
                  },
                ],
                style: {
                  flex: 1,
                },
              },
              {
                fields: [
                  {
                    name: 'updatedAt',
                    title: '更新日時',
                    formatter: (data) => this.datePipe.transform(data.updatedAt, 'yyyy/MM/dd HH:mm'),
                    defaultSort: SortOrder.DESCENDING,
                  },
                ],
                style: {
                  width: '115px',
                },
              },
              {
                fields: [
                  {
                    name: 'actions',
                    title: '',
                    sortable: false,
                    cellTemplate: this.csvEditButtonTemplate,
                  },
                ],
                style: {
                  width: '75px',
                },
              },
            ],
          },
        ],
      },
      body: {
        checkbox: false,
        arrow: false,
      },
    };

    if (this.authService.loginUser && this.authService.loginUser.role === 1) {
      forkJoin([
        this.exportService.getFields(ExportModel.student),
        this.exportService.getFields(ExportModel.enterprise),
        this.exportService.getFields(ExportModel.job),
        this.exportService.getFields(ExportModel.progress),
      ]).subscribe(([student, enterprise, job, progress]) => {
        this.templateFields[ExportModel.student] = student;
        this.templateFields[ExportModel.enterprise] = enterprise;
        this.templateFields[ExportModel.job] = job;
        this.templateFields[ExportModel.progress] = progress;
      });
    }

    this.load();
  }

  private load(): void {
    if (this.authService.loginUser) {
      this.mailService.getSignature('user', this.authService.loginUser.id).subscribe((res) => (this.signatures = res));
      if (this.authService.loginUser.role === 1) {
        forkJoin([
          this.exportService.getTemplates(ExportModel.student),
          this.exportService.getTemplates(ExportModel.enterprise),
          this.exportService.getTemplates(ExportModel.job),
          this.exportService.getTemplates(ExportModel.progress),
        ]).subscribe(([student, enterprise, job, progress]) => {
          this.templates.length = 0;
          this.templates.push(...student.templates, ...enterprise.templates, ...job.templates, ...progress.templates);
        });
      }
    }
  }

  openSignatureEdit(signature?: MailSignature): void {
    this.selectedSignature = signature || (({} as unknown) as MailSignature);
    PopupControlComponent.instance.open({
      title: '署名設定',
      cancelText: this.selectedSignature.id ? '削除' : 'キャンセル',
      cancelClass: this.selectedSignature.id ? 'red' : null,
      cancelCallback: () => {
        if (this.selectedSignature.id) {
          this.mailService
            .deleteSignature(this.selectedSignature.id)
            .pipe(tap(() => this.load()))
            .subscribe();
        }
      },
      confirmText: '保存',
      width: '700px',
      height: '546px',
      content: this.signatureEditPopupTemplate,
      confirmEnabled: () => this.signatureFormComponent && this.signatureFormComponent.myForm.valid,
      confirmCallback: () => {
        if (this.selectedSignature.id) {
          return this.mailService.updateSignature(this.selectedSignature.id, this.selectedSignature).pipe(tap(() => this.load()));
        } else {
          return this.mailService.addSignature('user', this.authService.loginUser.id, this.selectedSignature).pipe(tap(() => this.load()));
        }
      },
    });
  }

  openTemplateEdit(template?: ExportTemplate): void {
    this.selectedTemplate = template || (({ columns: [] } as unknown) as ExportTemplate);
    this.selectedTemplate['textColumns'] = this.selectedTemplate.columns.join('\n');
    PopupControlComponent.instance.open({
      title: 'CSV出力設定',
      cancelText: this.selectedTemplate.id ? '削除' : 'キャンセル',
      cancelClass: this.selectedTemplate.id ? 'red' : null,
      cancelCallback: () => {
        if (this.selectedTemplate.id) {
          this.exportService
            .deleteTemplate(this.selectedTemplate.id)
            .pipe(tap(() => this.load()))
            .subscribe();
        }
      },
      confirmText: '保存',
      width: '800px',
      height: '653px',
      content: this.csvEditPopupTemplate,
      confirmEnabled: () => this.csvFormComponent && this.csvFormComponent.myForm.valid,
      confirmCallback: () => {
        this.selectedTemplate.columns = this.selectedTemplate['textColumns'].split('\n');
        if (this.selectedTemplate.id) {
          return this.exportService.updateTemplate(this.selectedTemplate.id, this.selectedTemplate).pipe(tap(() => this.load()));
        } else {
          return this.exportService.addTemplate(this.selectedTemplate).pipe(tap(() => this.load()));
        }
      },
    });
  }
}
