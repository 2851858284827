import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ExtendedProgressStatusCode, HistoryStatus } from '@agent-ds/shared/enums';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, MasterApiService } from '@agent-ds/shared/services';
import { multiFill } from '@agent-ds/shared/util/util';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-progress-search',
  templateUrl: './progress-search.component.html',
  styleUrls: ['./progress-search.component.scss'],
})
export class ProgressSearchComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();
  @Output() search = new EventEmitter<{}>();

  innerSearchModel: any = {};
  private historyStatuses = [10, 20, 30, 40, 60, 581, 90, 600, 601, 610, 611, 620, 621, 630, 130, 160, 700, 710, 170, 180, 190, 200];

  get searchModel(): any {
    return this.innerSearchModel;
  }

  @Input()
  set searchModel(model: any) {
    this.innerSearchModel = { ...model };
  }

  leftMeta: FormMeta;
  rightMeta: FormMeta;
  validityArray = [true, true];
  valid = true;

  private fieldSubscription: Subscription;

  constructor(private dynamicService: DynamicFieldService, private masterApiService: MasterApiService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.leftMeta = {
        groups: [
          {
            title: '求職者情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                { fieldName: 'studentTeamId', label: '担当チーム', fieldType: 'multi-team', validationStyle: { max: 3 } },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'studentUserId', fieldType: 'multi-user', label: '担当者', validationStyle: { max: 3 } },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'studentSubUserId', fieldType: 'multi-user', label: '副担当者', validationStyle: { max: 5 } },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                {
                  fieldName: 'studentId',
                  fieldType: 'multi-student',
                  label: '求職者',
                  displayStyle: { placeholder: '求職者名、求職者カナ、求職者ID' },
                  validationStyle: { max: 3 },
                },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('student', 'registrationRoute'),
                  fieldType: 'multi-list',
                }),
                null,
                'half',
              ),
            ],
          },
          {
            title: '進捗プロセス',
            class: 'form__group--green-title-border',
            rows: [
              (() => {
                const row = this.dynamicService.getFormRows({
                  fieldName: 'history',
                  fieldType: 'date-range',
                  displayType: 'date-more-advanced',
                  label: '進捗日',
                })[0];
                row.fields.push({
                  type: 'hr',
                  name: 'line',
                  class: 'full min-full my-7',
                });
                row.fields.push({
                  type: 'label',
                  name: 'buttons',
                  class: 'standalone-actions full',
                  actions: [
                    {
                      title: '全選択',
                      type: 'RUNNABLE',
                      runnable: () => {
                        this.innerSearchModel.historyStatus = this.historyStatuses;
                        return;
                      },
                    },
                    {
                      title: '選択解除',
                      type: 'RUNNABLE',
                      runnable: () => {
                        this.innerSearchModel.historyStatus = [];
                        return;
                      },
                    },
                  ],
                  actionsClass: 'before',
                });
                row.fields.push({
                  name: 'historyStatus',
                  type: 'checkbox',
                  multi: true,
                  class: 'medium',
                  labelField: 'label',
                  valueField: 'value',
                  options: this.historyStatuses.map((key) => ({ label: HistoryStatus[key], value: key })),
                });
                return row;
              })(),
            ],
          },
          {
            title: '離職状況確認',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('progress', 'workStatus'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
              ...this.dynamicService.getFormRows({
                ...this.dynamicService.getDefinition('progress', 'workStatusDate'),
                fieldType: 'date-range',
                displayType: 'date-more-advanced',
              }),
              ...this.dynamicService.getFormRows(
                multiFill({
                  ...this.dynamicService.getDefinition('progress', 'workStatusCheckMethod'),
                  fieldType: 'multi-list',
                  displayType: 'checkbox',
                }),
              ),
            ],
          },
        ],
      };
      this.rightMeta = {
        groups: [
          {
            title: '企業',
            class: 'form__group--green-title-border',
            rows: this.dynamicService.getFormRows(
              { fieldName: 'enterpriseId', fieldType: 'multi-enterprise', label: '企業' },
              null,
              'half',
            ),
          },
          {
            title: '求人情報',
            class: 'form__group--green-title-border',
            rows: [
              ...this.dynamicService.getFormRows(
                { fieldName: 'enterpriseTeamId', label: '担当チーム', fieldType: 'multi-team', validationStyle: { max: 3 } },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'enterpriseUserId', fieldType: 'multi-user', label: '担当者', validationStyle: { max: 3 } },
                null,
                'half',
              ),
              ...this.dynamicService.getFormRows(
                { fieldName: 'jobId', fieldType: 'multi-job', label: '求人', validationStyle: { max: 3 } },
                null,
                'half',
              ),
            ],
          },
          {
            title: '最新ステータス',
            class: 'form__group--green-title-border',
            rows: [
              {
                title: '最新ステータス',
                fields: [
                  {
                    name: 'status',
                    type: 'checkbox',
                    multi: true,
                    class: 'medium',
                    valueField: 'value',
                    labelField: 'label',
                    options: [
                      ...[
                        10,
                        20,
                        30,
                        40,
                        50,
                        60,
                        70,
                        80,
                        90,
                        500,
                        501,
                        502,
                        511,
                        512,
                        521,
                        522,
                        130,
                        140,
                        150,
                        160,
                        170,
                        180,
                        190,
                        200,
                      ].map((key) => ({ label: ExtendedProgressStatusCode[key], value: key })),
                    ],
                    actions: [
                      {
                        type: 'RUNNABLE',
                        title: '稼働中',
                        runnable: () => [60, 70, 80, 90, 130, 500, 501, 502, 510, 511, 512, 520, 521, 522],
                      },

                      {
                        type: 'RUNNABLE',
                        title: '進捗中',
                        runnable: () => [130, 500, 501, 502, 510, 511, 512, 520, 521, 522],
                      },
                      {
                        type: 'RUNNABLE',
                        title: '選択解除',
                        runnable: () => [],
                      },
                    ],
                    actionsClass: 'before full mb-3',
                  },
                ],
              },
              ...this.dynamicService.getFormRows({
                label: 'セミナー・面接日',
                fieldType: 'date-range',
                displayType: 'date-more-advanced',
                fieldName: 'seminarDate',
              }),
              {
                title: '学生起因滞留',
                fields: [
                  {
                    name: 'isStudentDelay',
                    type: 'checkbox',
                    multi: true,
                    valueField: 'value',
                    labelField: 'label',
                    options: [{ label: NULL_SELECTED_VALUE, value: 0 }, { label: '学生起因滞留', value: 1 }],
                  },
                ],
              },
              {
                title: '進捗区分',
                fields: [
                  {
                    name: 'type',
                    type: 'radio',
                    valueField: 'value',
                    labelField: 'label',
                    options: [
                      { label: NULL_SELECTED_VALUE, value: NULL_SELECTED_VALUE },
                      { label: 'AG', value: 0 },
                      { label: 'Plus', value: 1 },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onValidityChange(index: number, validity: boolean): void {
    this.validityArray[index] = validity;
    this.valid = this.validityArray.find((v) => !v) == null;
  }

  onCloseClick(): void {
    this.closed.emit();
  }

  public doSearch(event?: any): void {
    if (!this.valid || (event && event['isComposing'])) {
      return;
    }
    const history = this.searchModel.history || {};
    const historyStatus = this.searchModel.historyStatus || [];
    const workStatusDate = this.searchModel.workStatusDate || {};
    const workStatus = this.searchModel.workStatus || {};
    const workStatusCheckMethod = this.searchModel.workStatusCheckMethod || {};
    if ((history.from || history.to || historyStatus.length) && (!historyStatus.length || (!history.from && !history.to))) {
      PopupControlComponent.instance.open({
        title: '必要な情報が入力されていません',
        content: '進捗プロセスを検索条件に指定する際は、「期間」、「進捗ステータス」の両方を指定してください。',
        confirmText: '閉じる',
        confirmCallback: () => false,
      });
    } else if (
      (workStatus.length || workStatusDate.from || workStatusDate.to || workStatusCheckMethod.length) &&
      (!workStatus.length || !workStatusCheckMethod.length || (!workStatusDate.from && !workStatusDate.to))
    ) {
      PopupControlComponent.instance.open({
        title: '必要な情報が入力されていません',
        content: '離職状況確認を検索条件に指定する際は、「就業状況」、「調査日」、「調査方法」の全てを指定してください。',
        confirmText: '閉じる',
        confirmCallback: () => false,
      });
    } else {
      this.search.emit({ ...this.searchModel });
    }
  }
}
