import { DynamicField } from '@agent-ds/shared/models';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

interface DynamicFieldEdit {
  isEdit: boolean;
  allOptions: string[];
  newOptions: string[];
  newOption: string;
  isUpdateInProgress: boolean;
}

@Component({
  selector: 'ag-settings-field-definitions-page',
  templateUrl: './settings-field-definitions-page.component.html',
  styleUrls: ['./settings-field-definitions-page.component.scss'],
})
export class SettingsFieldDefinitionsPageComponent implements OnInit, OnDestroy {
  studentFields: DynamicField[] = [];
  enterpriseFields: DynamicField[] = [];
  jobFields: DynamicField[] = [];
  editors: {
    [id: number]: DynamicFieldEdit;
  } = {};

  fieldSubscription: Subscription;

  constructor(private dynamicFieldService: DynamicFieldService) {}

  ngOnInit() {
    this.fieldSubscription = this.dynamicFieldService.fieldUpdateEvent.subscribe(() => {
      const filter = (field: DynamicField) => !field.isCustomField;
      this.studentFields = (this.dynamicFieldService.getDefinitions('student') || []).filter(filter);
      this.enterpriseFields = (this.dynamicFieldService.getDefinitions('enterprise') || []).filter(filter);
      this.jobFields = (this.dynamicFieldService.getDefinitions('job') || []).filter(filter);
    });
  }

  ngOnDestroy() {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = null;
    }
  }

  onSave(field: DynamicField, editor: DynamicFieldEdit): void {
    editor.isUpdateInProgress = true;
    const request =
      editor.newOptions && editor.newOptions.length
        ? forkJoin(editor.newOptions.map((option) => this.dynamicFieldService.updateRegularFieldOption(field.id, option))).pipe(
            concatMap(() => this.dynamicFieldService.updateRegularFieldOptionsOrder(field.id, editor.allOptions)),
          )
        : this.dynamicFieldService.updateRegularFieldOptionsOrder(field.id, editor.allOptions);
    request.subscribe(
      () => {
        editor.newOption = '';
        editor.newOptions = [];
        editor.isUpdateInProgress = false;
        editor.isEdit = false;
      },
      () => {
        editor.isUpdateInProgress = false;
      },
    );
  }
}
