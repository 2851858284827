import { CompanyContractCreateDialogComponent } from '@agent-ds/company/components';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import {
  APPROVAL_CANCEL_STATES,
  APPROVAL_STATES,
  GENDER_VALUE_BADGE_CLASSES,
  RAPACA_VALUE_BADGE_CLASSES,
} from '@agent-ds/shared/constants';
import {
  Company,
  ContactHistory,
  ContactHistoryEnterpriseAction,
  Contract,
  EnterpriseDepartment,
  EnterpriseDepartmentUserType,
  EnterpriseDepthContacts,
  JobListItem,
  SituationMemo,
} from '@agent-ds/shared/interfaces';
import { SalesListItem } from '@agent-ds/shared/interfaces/sale';
import { DynamicField, FormMeta, Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DialogService, DynamicFieldService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyTabs } from '../company-tabs.enum';

@Component({
  selector: 'ag-company-overview-tab',
  templateUrl: './company-overview-tab.component.html',
  styleUrls: ['./company-overview-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanyOverviewTabComponent) }],
})
export class CompanyOverviewTabComponent extends Tab implements OnInit, OnChanges {
  readonly tabs = CompanyTabs;
  @Input() company: Company;
  @Input() contactHistory: ContactHistory[];
  @Input() jobs: JobListItem[];
  @Input() innerInfo: SituationMemo[];
  @Input() sales: SalesListItem[];
  @Input() contracts: Contract[];
  @Input() isIntakeCa: boolean;
  @Output() selectedJobId = new EventEmitter<number>();

  departments: EnterpriseDepartment[];

  capitalDef: DynamicField;
  metadataHead: FormMeta;

  readonly APPROVAL_STATES = APPROVAL_STATES;
  readonly APPROVAL_CANCEL_STATES = APPROVAL_CANCEL_STATES;
  readonly RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
  readonly GENDER_VALUE_BADGE_CLASSES = GENDER_VALUE_BADGE_CLASSES;
  readonly contactAction = ContactHistoryEnterpriseAction;
  readonly jobDepartmentUserType = EnterpriseDepartmentUserType;

  constructor(
    public readonly masterApiService: MasterApiService,
    public readonly userTeamProvider: UserTeamInjectorProvider,
    private datePipe: SafeDatePipe,
    private dynamicService: DynamicFieldService,
    private dialog: DialogService,
    private companyApiService: CompanyApiService,
  ) {
    super();
  }

  userTeamWithJobDepType(userId: number, type: number): Observable<{ name: string; teamName: string }> {
    return this.jobDepartmentUserType[type] ? this.userTeamProvider.getUserTeamInfoById(userId) : of(null);
  }

  ngOnInit() {
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.metadataHead = {
        groups: [
          {
            class: 'oneliner',
            rows: [
              {
                title: '企業ID',
                fields: [
                  {
                    type: 'label',
                    name: 'frontId',
                  },
                ],
              },
              {
                title: '法人ステータス',
                fields: [
                  {
                    type: 'label',
                    name: 'dynamicData.enterpriseStatus',
                  },
                ],
              },
              {
                title: '更新者',
                class: 'fill',
                fields: [
                  {
                    type: 'label',
                    name: 'updateUserId',
                    supplier: () => (this.company ? this.userTeamProvider.getUserTeamNameById(this.company.updateUserId) : '-'),
                  },
                ],
              },
              {
                title: '登録日',
                fields: [
                  {
                    type: 'label',
                    name: 'createdAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
              {
                title: '更新日',
                fields: [
                  {
                    type: 'label',
                    name: 'updatedAt',
                    supplier: (val) => this.datePipe.transform(val),
                  },
                ],
              },
            ],
          },
        ],
      };
      this.capitalDef = this.dynamicService.getDefinition('enterprise', 'capital');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['company'] && this.company) {
      this.companyApiService.getDepartments(this.company.id).subscribe((response: EnterpriseDepthContacts) => {
        this.departments = response.enterpriseDepartments;
      });
    }
  }

  openCompanyContractEditDialog(data: Contract) {
    this.dialog.open(
      CompanyContractCreateDialogComponent,
      {
        data: { contract: data, company: this.company, departmentList: this.departments },
      },
      (result) => {
        if (!result) {
          return;
        }
        return this.companyApiService.updateContract(this.company.id, data.id, result);
      },
    );
  }

  onStudentLinkClick(id: number): void {
    StudentDetailPageComponent.instance.referenceId = id;
    StudentDetailPageComponent.instance.open();
  }

  onJobLinkClick(id: number): void {
    JobDetailPageComponent.instance.referenceId = id;
    JobDetailPageComponent.instance.open();
  }
}
