import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { SalesSearchRequest } from '@agent-ds/sales/dto/SalesSearchRequest';
import { SalesDetailPageComponent } from '@agent-ds/sales/sales-detail-page/sales-detail-page.component';
import {
  APPROVAL_CANCEL_STATES,
  APPROVAL_STATES,
  GENDER_VALUE_BADGE_CLASSES,
  RAPACA_VALUE_BADGE_CLASSES,
} from '@agent-ds/shared/constants';
import { SalesListItem } from '@agent-ds/shared/interfaces/sale';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { FindPipe } from '@agent-ds/shared/pipes/find.pipe';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, SalesApiService } from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { BatchResult, TableConfig } from '../page-scroll-table/table-interface';
import { SALE_LIST_TABLE_CONFIG } from './sales-list-table-config';

@Component({
  selector: 'ag-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.scss'],
})
export class SalesListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('ag_t', { static: false }) agTemplate: TemplateRef<any>;
  @ViewChild('row_header_company_t', { static: false }) rowHeaderCompanyTemplate: TemplateRef<any>;
  @ViewChild('row_header_job_seeker_t', { static: false }) rowHeaderJobSeekerTemplate: TemplateRef<any>;
  @ViewChild('responsible_persons_t', { static: false }) personInChargeTemplate: TemplateRef<any>;
  @Input() requestObject: SalesSearchRequest = {};
  @Input() options: any = {};
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();

  readonly RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
  readonly GENDER_VALUE_BADGE_CLASSES = GENDER_VALUE_BADGE_CLASSES;

  tableConfig: TableConfig;
  initialized = false;

  private refreshSubscription: Subscription;

  constructor(
    private salesApiService: SalesApiService,
    private dialog: DialogService,
    private datePipe: SafeDatePipe,
    private decimalPipe: DecimalPipe,
    private statusPipe: FindPipe,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.refreshSubscription = this.salesApiService.refreshEvent.subscribe(() => this.ngOnChanges());
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  private cleanObject(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] == null || obj[propName] === '') {
        delete obj[propName];
      }
    }

    return obj;
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
      this.cdr.detectChanges();
      if (this.table && this.initialized) {
        this.table.reset();
        this.table.next();
      }
    }
  }

  public get hasCheckedItems() {
    return this.table && this.table.hasCheckedItems;
  }

  private convertSalesList(res: any): BatchResult {
    // default result
    const result = {
      result: [],
      totalSize: res && res.total ? res.total : 0,
    };

    // map all elements to make object root to _source property
    if (res && res.saleses && res.saleses.length > 0) {
      result.result = res.saleses;
    }
    return result;
  }

  loadList = (page: number, size: number, sort?: string, order?: string): Observable<BatchResult> => {
    const params = this.cleanObject({
      ...this.requestObject,
      from: page * size,
      size: size,
      sort: sort,
      order: order,
    });

    return this.salesApiService.getList(params).pipe(map((res: any) => this.convertSalesList(res)));
  };

  ngAfterViewInit(): void {
    this.tableConfig = SALE_LIST_TABLE_CONFIG(
      this.agTemplate,
      this.rowHeaderCompanyTemplate,
      this.rowHeaderJobSeekerTemplate,
      this.personInChargeTemplate,
      (date: any) => this.datePipe.transform(date, 'yyyy/MM/dd'),
      (status: any) => (this.statusPipe.transform(APPROVAL_STATES, status, 'code') || { name: '-' }).name,
      (cancelStatus: any) =>
        cancelStatus === 1 ? '-' : (this.statusPipe.transform(APPROVAL_CANCEL_STATES, cancelStatus, 'code') || { name: '-' }).name,
      (num: any) => this.decimalPipe.transform(num, '0.0-2'),
      this.options,
    );

    this.cdr.detectChanges();
    this.initialized = true;
    if (this.requestObject && this.table) {
      this.table.reset(true);
      this.table.next();
    }
  }

  onSaleSelect(sale: SalesListItem): void {
    SalesDetailPageComponent.instance.referenceId = sale ? sale.id : null;
    SalesDetailPageComponent.instance.open();
    this.cdr.detectChanges();
  }

  onCompanyLinkClick(event: MouseEvent, id: number): void {
    this.onLinkClick(event, CompanyDetailPageComponent.instance, id);
  }

  onJobLinkClick(event: MouseEvent, id: number): void {
    this.onLinkClick(event, JobDetailPageComponent.instance, id);
  }

  onStudentLinkClick(event: MouseEvent, id: number): void {
    this.onLinkClick(event, StudentDetailPageComponent.instance, id);
  }

  onLinkClick(event: MouseEvent, instance: DetailPage, id: number): void {
    event.stopPropagation();
    if (id) {
      instance.referenceId = id;
      instance.open();
    }
  }

  public openSalesLinkBtoBDialog(): void {
    if (!this.hasCheckedItems) {
      return;
    }

    const config: ConfirmDialogConfig = {
      title: '請求書連携 → BtoB',
      messages: [
        `次の制約をBtoBへ連携し、請求書を作成します。よろしいですか？`,
        `\n`,
        this.table.checkedItems.map((item) => (item && item['id'] ? item['frontId'] : null)).join('\n'),
      ],
      style: {
        height: 'auto',
        width: '600px',
      },
      buttons: {
        no: 'キャンセル',
        yes: 'OK',
      },
    };
    this.dialog.open(ConfirmDialogComponent, config, (result: boolean) => {
      if (result) {
        return this.salesApiService
          .linkBtoBSales(this.table.checkedItems.map((item) => (item && item['id'] ? item['id'] : null)).filter((v) => v != null))
          .pipe(
            tap((datas) => {
              let messages = '';
              if (datas) {
                for (const data of datas) {
                  const status = data.status ? '成功' : '失敗';
                  messages += `=====\n発行先ID: ${data.privateCustCd}、\n請求名: ${data.invName}、\nステータス: ${status}、\nメッセージ: ${data.message}\n=====\n\n`;
                }
              }
              const config2: ConfirmDialogConfig = {
                title: 'BtoB請求書連携結果',
                messages: [messages],
                style: {
                  height: 'auto',
                  width: '600px',
                },
                buttons: {
                  no: null,
                  yes: 'OK',
                  hideNo: true,
                },
              };
              this.dialog.open(ConfirmDialogComponent, config2, (result2: boolean) => {
                if (result2) {
                  this.salesApiService.refreshEvent.emit();
                }
              });
            }),
          );
      }
    });
  }
}
