export enum ExportModel {
  student = 'student',
  enterprise = 'enterprise',
  job = 'job',
  progress = 'progress',
}

export const ExportModelLabel: { [key: string]: string } = {
  [ExportModel.student]: '求職者',
  [ExportModel.enterprise]: '企業',
  [ExportModel.job]: '求人',
  [ExportModel.progress]: '進捗',
};

export interface ExportTemplate {
  id?: number;
  model: ExportModel;
  name: string;
  columns: string[];
  isPublic: boolean;
  updatedAt?: string | Date;
}

export interface ExportTemplateListResponse {
  total: number;
  templates: ExportTemplate[];
}
