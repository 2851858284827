import {
  ContactHistory,
  DataFile,
  JobAttachmentHistory,
  StudentDetail,
  StudentImportDiff,
  StudentImportErrorDeleteRequest,
  StudentImportErrorDetail,
  StudentImportFile,
  StudentImportQueue,
  StudentList,
  StudentNote,
  StudentReimportRequest,
  StudentSuggestResponse,
  StudentUpdateRequest,
} from '@agent-ds/shared/interfaces';
import { SituationMemo } from '@agent-ds/shared/interfaces/memo';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { CACHE } from '@agent-ds/shared/util/cache';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { StudentSearchRequest } from '@agent-ds/student/dto/studentSearchRequest';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class StudentApiService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(search: StudentSearchRequest): Observable<StudentList> {
    return this.http.get<StudentList>('/api/students/search', { params: search as any });
  }

  getDetail(id: number): Observable<StudentDetail> {
    return this.http.get<StudentDetail>('/api/students/' + id).pipe(
      tap((student) => {
        student.userId = student.studentUsers && student.studentUsers.length ? student.studentUsers[0].userId : null;
        // 副担当者のIDを抽出
        if (student.studentSubUsers && student.studentSubUsers.length) {
          student.subUserIds = student.studentSubUsers.map((studentSubUser) => {
            return studentSubUser.userId;
          });
        } else {
          // do nothing
        }
        return student;
      }),
    );
  }

  create(studentData: object): Observable<any> {
    return this.http.post('/api/students', studentData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  update(id: number, studentData: object): Observable<any> {
    return this.http.put('/api/students/' + id, studentData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  bulkUpdate(key: string, value: any, studentIds?: number[], condition?: object): Observable<any> {
    return this.http.put('/api/students/bulk-update', {
      key: key,
      value: value,
      studentIds: studentIds,
      condition: condition,
    });
  }

  getMemos(id: number): Observable<SituationMemo[]> {
    return this.http.get<SituationMemo[]>(`/api/students/${id}/memos`);
  }

  addMemo(id: number, memo: SituationMemo): Observable<any> {
    return this.http.post(`/api/students/${id}/memos`, memo);
  }

  updateMemo(id: number, memo: SituationMemo): Observable<any> {
    return this.http.put(`/api/students/${id}/memos/${memo.id}`, memo);
  }

  deleteMemo(id: number, memoId: number): Observable<any> {
    return this.http.delete(`/api/students/${id}/memos/${memoId}`);
  }

  getContacts(id: number, actionId?: number): Observable<ContactHistory[]> {
    return this.http.get<ContactHistory[]>(
      `/api/students/${id}/contact-histories`,
      actionId ? { params: { action: `${actionId}` } } : undefined,
    );
  }

  addContact(id: number, request: ContactHistory): Observable<any> {
    return this.http.post(`/api/students/${id}/contact-histories`, request);
  }

  updateContact(id: number, request: ContactHistory): Observable<any> {
    return this.http.put(`/api/students/${id}/contact-histories/${request.id}`, request);
  }

  deleteContact(id: number, contactId: number): Observable<any> {
    return this.http.delete(`/api/students/${id}/contact-histories/${contactId}`);
  }

  getJobs(id: number): Observable<JobAttachmentHistory[]> {
    return this.http.get<JobAttachmentHistory[]>(`/api/students/${id}/job-offers`);
  }

  getJobFiles(id: number): Observable<DataFile[]> {
    return this.http.get<DataFile[]>(`/api/students/${id}/job-files`);
  }

  downloadJob(id: number, attachmentId: number): void {
    this.http
      .get(`/api/students/${id}/job-offer/${attachmentId}`, { responseType: 'blob' })
      .subscribe((res) => window.open(window.URL.createObjectURL(res)));
  }

  downloadJobFile(id: number, fileId: number): void {
    this.http
      .get(`/api/students/${id}/job-file/${fileId}`, {
        responseType: 'blob',
        observe: 'response',
        headers: { 'Content-Type': 'application/json' },
      })
      .subscribe((res) => downloadResponse(res));
  }

  addJob(id: string, file: File): void {
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.http.post(`/api/students/${id}/job-offer`, formData).subscribe();
  }

  deleteJob(id: string, attachmentId: string): void {
    this.http.delete(`/api/students/${id}/job-offer/${attachmentId}`);
  }

  getProfileImage(id: number, size?: number): Observable<string> {
    const url = `/api/students/${id}/profile-image/${size}`;
    CACHE.set(url, null, 300000);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res) => window.URL.createObjectURL(res)),
      catchError((error) => of('assets/images/profile.png')),
    );
  }

  getProfileImages(ids: number[], size?: number): Observable<string[]> {
    const observables = [];
    for (const id of ids) {
      observables.push(this.getProfileImage(id, size));
    }
    return merge(observables);
  }

  addNote(studentId: number, note: StudentNote): Observable<any> {
    return this.http.post(`/api/students/${studentId}/note`, note);
  }

  updateNote(studentId: number, note: StudentNote): Observable<any> {
    return this.http.put(`/api/students/${studentId}/note/${note.id}`, note);
  }

  deleteNote(studentId: number, noteId: number): Observable<any> {
    return this.http.delete(`/api/students/${studentId}/note/${noteId}`);
  }

  getSuggestions(keyword: string, ids?: number[]): Observable<StudentSuggestResponse[]> {
    return keyword || (ids && ids.length)
      ? this.http.get<StudentSuggestResponse[]>(`/api/students/suggest`, { params: { name: keyword || undefined, id: ids } as any })
      : of([]);
  }

  getSuggestionsForIntakeCa(keyword: string, ids?: number[]): Observable<StudentSuggestResponse[]> {
    return keyword || (ids && ids.length)
      ? this.http.get<StudentSuggestResponse[]>(`/api/students/suggestForIntakeCa`, {
          params: { name: keyword || undefined, id: ids } as any,
        })
      : of([]);
  }

  downloadImportExampleFile(): void {
    this.http.get('/api/students-import/header', { responseType: 'blob', observe: 'response' }).subscribe((res) => downloadResponse(res));
  }

  uploadStudentImportFile(file: File): Observable<{ id: number }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<{ id: number }>('/api/students-import/file', formData);
  }

  deleteStudentImportFiles(): Observable<any> {
    return this.http.delete('/api/students-import/file');
  }

  getImportFiles(): Observable<StudentImportFile[]> {
    return this.http.get<StudentImportFile[]>('/api/students-import/files');
  }

  getImportFile(fileId: number, registrationRoute: string | null = null): Observable<StudentImportQueue> {
    return this.http.get<StudentImportQueue>(`/api/students-import/file/${fileId}`, {
      params: registrationRoute ? { registrationRoute: registrationRoute } : null,
    });
  }

  getImportErrorDetail(studentImportErrorId: number): Observable<StudentImportErrorDetail> {
    return this.http.get<StudentImportErrorDetail>(`/api/students-import/error/${studentImportErrorId}`);
  }

  getImportErrorDiff(studentImportErrorId: number, studentId: number): Observable<StudentImportDiff> {
    return this.http.get<StudentImportDiff>(`/api/students-import/error/${studentImportErrorId}/diff/${studentId}`);
  }

  addImportError(studentImportErrorId: number, studentReimportRequest: StudentReimportRequest | null = null): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`/api/students-import/error/${studentImportErrorId}`, studentReimportRequest);
  }

  updateImportError(
    studentImportErrorId: number,
    studentId: number,
    studentUpdateRequest: StudentUpdateRequest,
  ): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/students-import/error/${studentImportErrorId}/update/${studentId}`, studentUpdateRequest);
  }

  deleteImportError(importErrorIds: number[]): Observable<any> {
    const request: StudentImportErrorDeleteRequest = { importErrorIds: importErrorIds };
    return this.http.request('delete', '/api/students-import/error', { body: request });
  }
}
