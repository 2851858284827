import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ag-segs',
  templateUrl: './segs.component.html',
  styleUrls: ['./segs.component.scss'],
})
export class SegsComponent implements OnInit {
  @Input() companyClassification: string = null;
  @Input() segs8LastYear: string = null;
  @Input() segs8CurrentYear: string = null;

  classMap = {
    中堅以上: 'badge--green-light',
    中小: 'badge--blue',
    販サOS介護: 'badge--orange-seg',
    IT注力: 'badge--purple-light2',
  };

  classMapSeg = {
    '①ロイヤル高': 'badge--dark1',
    '②ロイヤル低': 'badge--dark2',
    '③一般進捗有': 'badge--dark3',
    '④一般進捗無': 'badge--dark4',
    '⑤離反短期': 'badge--dark5',
    '⑥離反長期': 'badge--dark6',
    '⑦取引無': 'badge--dark7',
    '⑧取引NG': 'badge--dark8',
  };

  thisMonth = moment().format('MM');
  lastYear = moment().format('YY');
  currentYear = moment()
    .add(+1, 'year')
    .format('YY');

  constructor(public readonly masterApiService: MasterApiService) {
    if (
      this.thisMonth === '01' ||
      this.thisMonth === '02' ||
      this.thisMonth === '03' ||
      this.thisMonth === '04' ||
      this.thisMonth === '05' ||
      this.thisMonth === '06'
    ) {
      this.lastYear = moment()
        .add(-1, 'year')
        .format('YY');
      this.currentYear = moment().format('YY');
    }
  }

  ngOnInit(): void {}
}
