import {
  Branch,
  Certificate,
  HighSchool,
  IndustryType,
  JobType,
  Language,
  LanguageCertificate,
  School,
  Skill,
  Station,
  SystemConfigResponse,
  SystemConfigUpdateRequest,
  Team,
} from '@agent-ds/shared/interfaces';
import { AddressInfo } from '@agent-ds/shared/models';
import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService, REPORT_SUCCESS_HEADER } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class MasterApiService {
  private branches: Observable<Branch[]>;
  private certificates: Observable<Certificate[]>;
  private highSchools: Observable<HighSchool[]>;
  private industryTypes: Observable<IndustryType[]>;
  private jobTypes: Observable<JobType[]>;
  private languageCerts: Observable<LanguageCertificate[]>;
  private schools: Observable<School[]>;
  private skills: Observable<Skill[]>;
  private teams: Observable<Team[]>;

  private languages: ReplaySubject<Language[]> = new ReplaySubject(1);
  private flattenedIndustryTypes: ReplaySubject<IndustryType[]> = new ReplaySubject(1);
  private flattenedJobTypes: ReplaySubject<JobType[]> = new ReplaySubject(1);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isLogined().subscribe((logined) => {
      if (logined) {
        this.branches = this.certificates = this.highSchools = null;
        this.industryTypes = this.jobTypes = this.languageCerts = this.schools = this.skills = this.teams = null;
      }
    });
  }

  getBranches(): Observable<Branch[]> {
    if (!this.branches) {
      this.branches = this.http.get<Branch[]>('/api/master/branches').pipe(shareReplay(1));
    }
    return this.branches.pipe(first());
  }

  getCertificates(): Observable<Certificate[]> {
    if (!this.certificates) {
      this.certificates = this.http.get<Certificate[]>('/api/master/certificates').pipe(shareReplay(1));
    }
    return this.certificates.pipe(first());
  }

  getHighSchools(): Observable<HighSchool[]> {
    if (!this.highSchools) {
      this.highSchools = this.http.get<HighSchool[]>('/api/master/high-schools').pipe(shareReplay(1));
    }
    return this.highSchools.pipe(first());
  }

  getIndustryTypes(): Observable<IndustryType[]> {
    if (!this.industryTypes) {
      this.industryTypes = this.http.get<IndustryType[]>('/api/master/industry-types/relations').pipe(
        tap((res) => this.flattenedIndustryTypes.next(res.flatten((subType, level) => (subType['_selectLevel'] = level), 'industryTypes'))),
        shareReplay(1),
      );
    }
    return this.industryTypes.pipe(first());
  }

  getFlattenedIndustryTypes(): Observable<IndustryType[]> {
    return this.flattenedIndustryTypes.pipe(first());
  }

  getJobTypes(): Observable<JobType[]> {
    if (!this.jobTypes) {
      this.jobTypes = this.http.get<JobType[]>('/api/master/job-types/relations').pipe(
        tap((res) => this.flattenedJobTypes.next(res.flatten((subType, level) => (subType['_selectLevel'] = level), 'jobTypes'))),
        shareReplay(1),
      );
    }
    return this.jobTypes.pipe(first());
  }

  getFlattenedJobTypes(): Observable<JobType[]> {
    return this.flattenedJobTypes.pipe(first());
  }

  getLanguageCerts(): Observable<LanguageCertificate[]> {
    if (!this.languageCerts) {
      this.languageCerts = this.http.get<LanguageCertificate[]>('/api/master/language-certificates').pipe(shareReplay(1));
      this.languageCerts.subscribe(
        (res) => this.languages.next(res.map((cert) => cert.language).removeSame('id')),
        () => this.languages.next([]),
      );
    }
    return this.languageCerts.pipe(first());
  }

  getLanguages(): Observable<Language[]> {
    if (!this.languageCerts) {
      this.getLanguageCerts();
    }
    return this.languages.pipe(first());
  }

  searchStations(keyword: string): Observable<Station[]> {
    return this.http.get<Station[]>('/api/master/schools/relations?keyword=' + keyword);
  }

  getSchools(): Observable<School[]> {
    if (!this.schools) {
      this.schools = this.http.get<School[]>('/api/master/schools/relations').pipe(shareReplay(1));
    }
    return this.schools.pipe(first());
  }

  suggestSchools(keyword: string): Observable<School[]> {
    return keyword ? this.http.get<School[]>('/api/master/schools/suggest', { params: { keyword: keyword } }) : of([]);
  }

  getSkills(): Observable<Skill[]> {
    if (!this.skills) {
      this.skills = this.http.get<Skill[]>('/api/master/skills/relations').pipe(shareReplay(1));
    }
    return this.skills.pipe(first());
  }

  getTeams(): Observable<Team[]> {
    if (!this.teams) {
      this.teams = this.http.get<Team[]>('/api/master/teams').pipe(shareReplay(1));
    }
    return this.teams.pipe(first());
  }

  addTeam(team: { managerId: number; branchId: number; name: string }): Observable<{ id: number }> {
    return this.http.post<{ id: number }>('/api/master/teams', team).pipe(tap(() => (this.teams = null)));
  }

  updateTeam(id: number, team: { managerId: number; branchId: number; name: string }): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/master/teams/${id}`, team).pipe(tap(() => (this.teams = null)));
  }

  getAddressByZip(
    zip: string,
  ): Observable<{
    results: AddressInfo[];
  }> {
    if (zip) {
      CACHE.set('/api/address/search/zip', null, 300000);
      return this.http.get<any>('/api/address/search/zip', { params: { zipCode: zip } });
    } else {
      return of({ results: [] });
    }
  }

  getSystemConfigs(): Observable<SystemConfigResponse[]> {
    return this.http.get<SystemConfigResponse[]>('/api/master/system-config');
  }

  updateSystemConfig(id: number, requestObj: SystemConfigUpdateRequest): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/master/system-config/${id}`, requestObj, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }
}
