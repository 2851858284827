import { ONE_DAY } from '@agent-ds/shared/constants/consts';
import { Kpi } from '@agent-ds/shared/interfaces/dashboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'ag-kpi-target',
  templateUrl: './kpi-target.component.html',
  styleUrls: ['./kpi-target.component.scss'],
})
export class KpiTargetComponent implements OnInit {
  @Input() model: Kpi = Kpi.empty();
  @Input() dashboardType = 0;
  @Output() setupClick = new EventEmitter<any>();

  today: Date = new Date();

  constructor() {
    of()
      .pipe(delay(new Date().addDays(1).toStartOfDay()))
      .subscribe(() => {
        this.today = new Date();
        interval(ONE_DAY).subscribe(() => (this.today = new Date()));
      });
  }

  ngOnInit(): void {}

  setupKpi(event: any): void {
    this.setupClick.emit(event);
  }
}
