import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, FileApiService } from '@agent-ds/shared/services';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-second-match-dialog',
  templateUrl: './second-match-dialog.component.html',
  styleUrls: ['./second-match-dialog.component.scss'],
})
export class SecondMatchDialogComponent implements OnInit, OnDestroy {
  metadata: FormMeta = { groups: [] };
  dateRange: { from: string; to: string } = { from: null, to: null };

  confirmSubscription: Subscription;

  constructor(
    public readonly dialog: DialogRef,
    public readonly config: DialogConfig,
    private dynamicService: DynamicFieldService,
    private service: FileApiService,
  ) {}

  ngOnInit() {
    this.confirmSubscription = this.dialog.confirmed.subscribe(() => this.download());
    this.metadata = {
      groups: [
        {
          title: '開始日',
          class: 'form__group--no-title-border form__group--inline-title no-border-around no-title-column',
          rows: [...this.dynamicService.getFormRows({ fieldName: 'from', fieldType: 'date', displayType: 'date' })],
        },
        {
          title: '終了日',
          class: 'form__group--no-title-border form__group--inline-title no-border-around no-title-column',
          rows: [...this.dynamicService.getFormRows({ fieldName: 'to', fieldType: 'date', displayType: 'date' })],
        },
      ],
    };
  }

  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }

  close(): void {
    this.dialog.close();
  }

  download(): void {
    if (!(this.dateRange.from && this.dateRange.to)) {
      return;
    }
    this.service.downloadSecondMatchCsv(this.dateRange.from, this.dateRange.to).subscribe((resp: HttpResponse<Blob>) => {
      downloadResponse(resp);
      this.close();
    });
  }
}
