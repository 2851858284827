import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { Company, EnterpriseDepartment } from '@agent-ds/shared/interfaces';
import { ExportTemplate } from '@agent-ds/shared/interfaces/export';
import { CompanyApiService, DialogService } from '@agent-ds/shared/services';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';

interface DateSetting {
  senderDate: string;
  senderTime: string;
  seminarDate: string;
}

interface DayOfWeekSetting {
  label: string;
  checked: boolean;
}

interface DelivaryTimingSetting {
  label: string;
  checked: boolean;
  time: string;
}

@Component({
  selector: 'ag-company-seminar-attendance-send-dialog',
  templateUrl: './company-seminar-attendance-send-dialog.component.html',
  styleUrls: ['./company-seminar-attendance-send-dialog.component.scss'],
})
export class CompanySeminarAttendanceSendDialogComponent implements OnInit {
  readonly dayOfWeekLabels: ReadonlyArray<string> = ['月', '火', '水', '木', '金'];
  readonly deliveryTimingLabels: ReadonlyArray<string> = ['前日', '2営業日前', '3営業日前', '4営業日前', '5営業日前'];
  readonly minDate = moment()
    .add(1, 'days')
    .startOf('days');
  readonly defaultTime = '09:00';
  readonly dateSettingCount = 10;

  exportTemplateId: number | null = null;
  exportTemplates: ExportTemplate[] = [];
  company: Company;
  department: EnterpriseDepartment;

  formData = {
    dateSettings: [] as DateSetting[],
    dayOfWeeks: [] as DayOfWeekSetting[],
    dayOfWeekTime: '',
    deliveryTimings: [] as DelivaryTimingSetting[],
  };

  get isValid() {
    if (this.formData.dateSettings.some((d) => ((d.senderDate && !d.seminarDate) || (!d.senderDate && d.seminarDate) ? true : false))) {
      return false;
    }
    const dateSettings = this.formData.dateSettings.filter((d) => d.senderDate && d.seminarDate);
    if (dateSettings.length > 0) {
      const compare = dateSettings.every((d) => {
        const senderDate = moment(d.senderDate).startOf('days');
        const seminarDate = moment(d.seminarDate).startOf('days');
        return (
          this.minDate.isSameOrBefore(senderDate) && this.minDate.isSameOrBefore(seminarDate) && senderDate.isSameOrBefore(seminarDate)
        );
      });
      if (!compare) {
        return false;
      }
    }

    return true;
  }

  constructor(
    public dialog: DialogRef,
    private config: DialogConfig<{ company: Company; department: EnterpriseDepartment; progressExportTemplates: ExportTemplate[] }>,
    private companyService: CompanyApiService,
    private dialogService: DialogService,
  ) {
    for (let i = 0; i < this.dateSettingCount; i++) {
      this.formData.dateSettings.push({ senderDate: '', senderTime: '', seminarDate: '' });
    }
    for (const label of this.dayOfWeekLabels) {
      this.formData.dayOfWeeks.push({ label: label, checked: false });
    }
    for (const label of this.deliveryTimingLabels) {
      this.formData.deliveryTimings.push({ label: label, checked: false, time: '' });
    }
  }

  ngOnInit() {
    this.company = this.config.data.company;
    this.department = this.config.data.department;
    if (Array.isArray(this.config.data.progressExportTemplates)) {
      this.exportTemplates.push(...this.config.data.progressExportTemplates);
    }
    this.initForm();
  }

  create(): void {
    if (this.dialog.inClose) {
      return;
    }

    if (!this.checkResumePassword()) {
      this.openConfirmDialog();
      return;
    }

    const users = Array.isArray(this.department.enterpriseDepartmentUsers)
      ? this.department.enterpriseDepartmentUsers.map((d) => ({ type: d.type, id: d.userId }))
      : [];
    const requestData = {
      dynamicData: this.createDynamicData(),
      users: users,
    };

    this.companyService.updateDepartment(this.company.id, this.department.id, requestData).subscribe((res) => {
      this.close(res);
    });
  }

  close(params?: any): void {
    this.dialog.close(params);
  }

  private initForm(): void {
    if (_.isNumber(this.department.dynamicData.listExportTemplateId)) {
      this.exportTemplateId = this.department.dynamicData.listExportTemplateId;
    }

    for (let i = 0; i < this.formData.dateSettings.length; i++) {
      this.setDateSettingValue(
        this.formData.dateSettings[i],
        this.department.dynamicData[`listSenderDate${i + 1}`],
        this.department.dynamicData[`listSeminarDate${i + 1}`],
      );
    }

    if (Array.isArray(this.department.dynamicData.listDayOfWeek)) {
      const dayOfWeeks = this.department.dynamicData.listDayOfWeek as string[];
      for (const d of this.formData.dayOfWeeks) {
        d.checked = dayOfWeeks.includes(d.label);
      }
    }

    this.formData.dayOfWeekTime = this.getTimeString(this.department.dynamicData.listDayOfWeekTime);

    for (let i = 0; i < this.formData.deliveryTimings.length; i++) {
      const deliveryTimingTime = this.department.dynamicData[`listDeliveryTimingTime${i + 1}`];
      this.formData.deliveryTimings[i].checked = this.department.dynamicData[`listDeliveryTiming${i + 1}`] === '有';
      this.formData.deliveryTimings[i].time = deliveryTimingTime ? this.getTimeString(deliveryTimingTime) : '';
    }
  }

  private createDynamicData(): any {
    const dynamicData = {} as any;
    dynamicData.listExportTemplateId = this.exportTemplateId;
    for (let i = 0; i < this.formData.dateSettings.length; i++) {
      dynamicData[`listSenderDate${i + 1}`] = this.getSenderDate(this.formData.dateSettings[i]);
      dynamicData[`listSeminarDate${i + 1}`] = this.getSeminarDate(this.formData.dateSettings[i]);
    }

    dynamicData.listDayOfWeek = this.formData.dayOfWeeks.filter((value) => value.checked).map((value) => value.label);
    dynamicData.listDayOfWeekTime = this.convertTimeToISOString(this.formData.dayOfWeekTime);

    for (let i = 0; i < this.formData.deliveryTimings.length; i++) {
      dynamicData[`listDeliveryTiming${i + 1}`] = this.formData.deliveryTimings[i].checked ? '有' : null;
      dynamicData[`listDeliveryTimingTime${i + 1}`] = this.convertTimeToISOString(this.formData.deliveryTimings[i].time);
    }

    dynamicData.listSettingDate = moment()
      .startOf('days')
      .toISOString();

    return dynamicData;
  }

  private setDateSettingValue(dateSetting: DateSetting, senderDate: any, seminarDate: any): void {
    if (senderDate) {
      const date = moment(senderDate);
      dateSetting.senderDate = date.format('YYYY-MM-DD');
      dateSetting.senderTime = date.format('HH:mm');
    }
    if (seminarDate) {
      const date = moment(seminarDate);
      dateSetting.seminarDate = date.format('YYYY-MM-DD');
    }
  }

  private getSenderDate(dateSetting: DateSetting): string | null {
    if (!dateSetting.senderDate) {
      return null;
    }
    const time = dateSetting.senderTime ? dateSetting.senderTime : this.defaultTime;
    const dateTime = moment(`${dateSetting.senderDate} ${time}`, 'YYYY-MM-DD HH:mm');
    return dateTime.toISOString();
  }

  private getSeminarDate(dateSetting: DateSetting): string | null {
    return dateSetting.seminarDate ? moment(dateSetting.seminarDate).toISOString() : null;
  }

  private getTimeString(value: any): string | null {
    return value ? moment(value).format('HH:mm') : null;
  }

  private convertTimeToISOString(value: string): string | null {
    return value ? moment(value, 'HH:mm').toISOString() : null;
  }

  private checkResumePassword(): boolean {
    const dynamicData = this.company.dynamicData as any;
    return dynamicData && dynamicData.resumePassword;
  }

  private openConfirmDialog(): void {
    const config: ConfirmDialogConfig = {
      title: '必要な情報が入力されていません',
      messages: ['パスワードが登録されていません'],
      style: {
        height: '110px',
        width: '510px',
      },
      buttons: {
        hideNo: true,
        no: '',
        yes: '閉じる',
      },
    };
    this.dialogService.open(ConfirmDialogComponent, config);
  }
}
