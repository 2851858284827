import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { DataFile, Student } from '@agent-ds/shared/interfaces';
import { DialogService, FileApiService } from '@agent-ds/shared/services';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

interface RenameForm extends FormGroup {
  controls: {
    prefix: FormControl;
    custom: FormControl;
  };
  value: {
    prefix: string;
    custom: string;
  };
}

export interface StudentRenameFileDialogConfig {
  file: DataFile;
  student: Student;
}

@Component({
  selector: 'ag-student-rename-file-dialog',
  templateUrl: './student-rename-file-dialog.component.html',
  styleUrls: ['./student-rename-file-dialog.component.scss'],
})
export class StudentRenameFileDialogComponent implements OnDestroy, OnInit {
  @ViewChild('customInput', { static: true })
  customInput: ElementRef<HTMLInputElement>;

  renameFileForm: RenameForm;
  inClose = false;

  public readonly CUSTOM_OPTION = 'CUSTOM';
  private readonly FIRST_OPTION = '【仮】【履歴書等】';
  private readonly TO_BE_VERIFIED_PREFIX = '【要確認書類】';
  private renameSubscription: Subscription;

  constructor(
    public readonly dialog: DialogRef,
    public readonly config: DialogConfig<StudentRenameFileDialogConfig>,
    private readonly fileService: FileApiService,
    private readonly dialogService: DialogService,
  ) {}

  ngOnDestroy(): void {
    this.cancelSubscription();
  }

  ngOnInit(): void {
    this.renameFileForm = new FormGroup({
      prefix: new FormControl(this.FIRST_OPTION, Validators.required),
      custom: new FormControl(null),
    }) as RenameForm;
    this.renameFileForm.controls.prefix.valueChanges.subscribe((value) => {
      if (value === this.CUSTOM_OPTION) {
        this.renameFileForm.controls.custom.clearValidators();
        if (this.customInput) {
          this.customInput.nativeElement.focus();
        }
      } else {
        this.renameFileForm.controls.custom.setValidators(Validators.required);
      }
    });
    this.renameFileForm.controls.custom.valueChanges.subscribe((value) => {
      if (value) {
        this.renameFileForm.controls.prefix.setValue(this.CUSTOM_OPTION);
      }
    });
  }

  renameFile(): void {
    if (!this.renameFileForm.valid || this.inClose || this.dialog.inClose) {
      return;
    }
    this.inClose = true;
    const fileName = this.calculateFileName();
    this.cancelSubscription();
    this.renameSubscription = this.fileService
      .renameStudentUploadedFile(this.config.data.student.id, this.config.data.file.id, fileName)
      .subscribe(
        (updated) => {
          this.inClose = false;
          this.close(updated);
        },
        (errorResp) => {
          this.inClose = false;
          if (errorResp.error && errorResp.error.fields && errorResp.error.fields.file === '同じ名前のファイルが存在しています') {
            const config: ConfirmDialogConfig = {
              title: 'ファイルの保存',
              messages: ['当該求職者のフォルダには、同じ名前のファイルが既に存在します。', '違うファイル名にして保存してください。'],
              buttons: {
                no: '',
                hideNo: true,
                yes: '閉じる',
              },
            };
            this.dialogService.open(ConfirmDialogComponent, config);
          }
        },
      );
  }

  close(file?: DataFile): void {
    this.cancelSubscription();
    this.dialog.close(file);
  }

  private calculateFileName(): string {
    const original = this.config.data.file.name;
    let prefix = this.renameFileForm.value.prefix;
    if (prefix === this.CUSTOM_OPTION) {
      prefix = `【${this.renameFileForm.value.custom}】`;
    }
    return original.replace(/【<[^>]*>】/, '').replace(this.TO_BE_VERIFIED_PREFIX, prefix);
  }

  private cancelSubscription(): void {
    if (this.renameSubscription) {
      this.renameSubscription.unsubscribe();
    }
  }
}
