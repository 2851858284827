import { CompanyDetailPageComponent } from './company-detail-page/company-detail-page.component';
import { COMPANY_DETAIL_TABS } from './company-detail-page/tabs';
import { CompanyImportPageComponent } from './company-import-page/company-import-page.component';
import { CompanyListPageComponent } from './company-list-page/company-list-page.component';

export * from './company-detail-page/company-detail-page.component';
export * from './company-list-page/company-list-page.component';
export * from './company-import-page/company-import-page.component';

export const COMPANY_PAGES = [CompanyListPageComponent, CompanyDetailPageComponent, CompanyImportPageComponent, COMPANY_DETAIL_TABS];
