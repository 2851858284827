import { RAPACA_VALUE_BADGE_CLASSES } from '@agent-ds/shared/constants';
import { EnterpriseDepartmentUser, EnterpriseDepartmentUserType, EnterpriseDepthContacts, Job } from '@agent-ds/shared/interfaces';
import { CompanyApiService, UserApiService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export enum PersonGroupType {
  DEPARTMENT = 'DEPARTMENT',
  BRANCH = 'BRANCH',
}

@Component({
  selector: 'ag-persons-in-charge',
  templateUrl: './persons-in-charge.component.html',
  styleUrls: ['./persons-in-charge.component.scss'],
})
export class PersonsInChargeComponent implements OnInit, OnChanges {
  constructor(
    private enterpriseApi: CompanyApiService,
    public readonly masterApiService: MasterApiService,
    private userApiService: UserApiService,
  ) {}
  @Input() companyId: number;
  @Input() job: Job;
  @Input() groupType: PersonGroupType = PersonGroupType.DEPARTMENT;

  public readonly RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
  public readonly departmentUserType = EnterpriseDepartmentUserType;

  groupsToShow: { title: string; users: { name: string; teamName: number; types: number[] }[] }[];

  ngOnInit() {
    this.fill();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['companyId'] || changes['job']) {
      this.fill();
    }
  }

  private fill(): void {
    const companyId = this.job ? this.job.enterpriseId : this.companyId;
    if (companyId) {
      if (this.groupType === PersonGroupType.BRANCH) {
        this.enterpriseApi.getDepartments(companyId).subscribe((contacts: EnterpriseDepthContacts) => {
          this.userApiService.getAll().subscribe((users) => {
            this.masterApiService.getTeams().subscribe((teams) => {
              const depUserGroups = (((this.job
                ? [contacts.enterpriseDepartments.find((dep) => dep.id === this.job.enterpriseDepartmentId)]
                : contacts.enterpriseDepartments
              )
                .filter((dep) => dep)
                .map((element) => element.enterpriseDepartmentUsers)
                .flatten() as unknown) as EnterpriseDepartmentUser[]).groupBy('userId');
              const teamUserGroups = Object.values(depUserGroups)
                .map((depUserGroup) => users.find((u) => depUserGroup.find((du) => du.userId === u.id) != null))
                .groupBy('teamId');
              const allTeamIds = Object.keys(teamUserGroups).map((id) => +id);
              const allTeams = teams.filter((t) => allTeamIds.includes(t.id));
              const allBranches = allTeams.map((t) => t.branch).removeSame('id');
              const branchTeamGroups = allTeams.groupBy('branch.id');

              this.groupsToShow = [];
              allBranches.forEach((branch) => {
                const groupToShow = { title: branch.name, users: [] };
                this.groupsToShow.push(groupToShow);
                const branchTeams = branchTeamGroups[branch.id] || [];
                branchTeams.forEach((team) =>
                  teamUserGroups[team.id].forEach((user) => {
                    groupToShow.users.push({
                      name: user.name,
                      teamName: team.name,
                      types: depUserGroups[user.id].map((depUser) => depUser.type).removeSame(),
                    });
                  }),
                );
                groupToShow.users.sort((a, b) => {
                  const aHighestRank = Math.min(...a.types.map((t) => (t === EnterpriseDepartmentUserType.アシ ? 40 : t)));
                  const bHighestRank = Math.min(...b.types.map((t) => (t === EnterpriseDepartmentUserType.アシ ? 40 : t)));
                  return aHighestRank === bHighestRank ? 0 : aHighestRank < bHighestRank ? -1 : 1;
                });
              });
            });
          });
        });
      } else {
        this.enterpriseApi.getDepartments(companyId).subscribe((contacts: EnterpriseDepthContacts) => {
          const departments = (this.job
            ? [contacts.enterpriseDepartments.find((dep) => dep.id === this.job.enterpriseDepartmentId)]
            : contacts.enterpriseDepartments
          ).filter((dep) => dep);
          this.userApiService.getAll().subscribe((users) => {
            this.masterApiService.getTeams().subscribe((teams) => {
              this.groupsToShow = [];
              departments.forEach((element) => {
                const groupToShow = { title: element.dynamicData.name, users: [] };
                this.groupsToShow.push(groupToShow);
                // ensure the user withe same id will appear only once in each department
                const groups = element.enterpriseDepartmentUsers.groupBy('userId');
                Object.keys(groups).forEach((key) => {
                  const numKey = +key;
                  const user = users.find((u) => u.id === numKey);
                  const team = teams.find((t) => t.id === user.teamId);
                  groupToShow.users.push({ name: user.name, teamName: team.name, types: groups[key].map((g) => g.type).removeSame() });
                });
                groupToShow.users.sort((a, b) => {
                  const aHighestRank = Math.min(...a.types.map((t) => (t === EnterpriseDepartmentUserType.アシ ? 40 : t)));
                  const bHighestRank = Math.min(...b.types.map((t) => (t === EnterpriseDepartmentUserType.アシ ? 40 : t)));
                  return aHighestRank === bHighestRank ? 0 : aHighestRank < bHighestRank ? -1 : 1;
                });
              });
            });
          });
        });
      }
    }
  }
}
