import { CompanyListComponent } from '@agent-ds/shared/components/company-list/company-list.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetailPageComponent } from '../company-detail-page/company-detail-page.component';

@Component({
  selector: 'ag-company-list-page',
  templateUrl: './company-list-page.component.html',
  styleUrls: ['./company-list-page.component.scss'],
})
export class CompanyListPageComponent extends ListPage implements OnInit, OnDestroy {
  @ViewChild(CompanyListComponent, { static: false }) table: CompanyListComponent;
  @ViewChild('export', { static: false }) exportTemplate: TemplateRef<any>;
  protected readonly model = 'enterprises';
  protected readonly detail = CompanyDetailPageComponent as any;
  protected readonly exportModel = ExportModel.enterprise;

  listConfiguration: any = { body: { checkbox: true } };

  readonly exportTypes = [{ label: '企業', value: ExportModel.enterprise }, { label: '契約', value: 'contract' }];
  selectedExportType: string;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
  ) {
    super(router, location, activeRoute, exportService);
  }

  showExport(): void {
    PopupControlComponent.instance.open({
      title: 'CSV出力',
      content: this.exportTemplate,
      confirmText: 'ダウンロード',
      confirmEnabled: () => this.exportTemplateId != null || this.selectedExportType === 'contract',
      confirmCallback: () => {
        if (this.selectedExportType === this.exportModel) {
          return this.exportService.export(this.exportModel, this.exportTemplateId, this.searchObj);
        } else {
          return this.exportService.exportContracts(this.searchObj);
        }
      },
      cancelText: '閉じる',
    });
  }
}
