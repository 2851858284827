import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'basedDate',
})
export class BasedDatePipe implements PipeTransform {
  private readonly day = 86400000;

  constructor(private datePipe: DatePipe) {}

  transform(date: any, args?: any): any {
    const d = new Date(date);
    const now = new Date();
    if (now.getTime() - d.getTime() < this.day && d.getDate() === now.getDate()) {
      return this.datePipe.transform(d, 'HH:mm');
    } else {
      return this.datePipe.transform(d, 'yyyy/MM/dd (E) HH:mm');
    }
  }
}
