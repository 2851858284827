import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'ag-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnInit, OnDestroy, AfterContentInit {
  @ContentChildren(TabComponent) private tabs!: QueryList<TabComponent>;
  @Output() selectedIndexChange = new EventEmitter<number>();

  private innerSelectedIndex = 0;
  private tabSubscriptions: Subscription[] = [];
  tabList: TabComponent[] = [];
  portals: TemplatePortal[] = [];

  constructor(public viewContainerRef: ViewContainerRef) {}

  public get selectedIndex(): number {
    return this.innerSelectedIndex;
  }

  @Input()
  public set selectedIndex(index: number) {
    this.innerSelectedIndex = index;
    this.selectedIndexChange.emit(this.innerSelectedIndex);
    this.tabList.forEach((tab) => {
      if (tab.tabChild) {
        tab.tabChild.selectedIndex = index;
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.empty();
  }

  ngAfterContentInit() {
    this.tabs.changes.subscribe(() => {
      this.init();
    });
    this.init();
  }

  private init(): void {
    this.empty();
    this.tabList = this.tabs.toArray();
    this.portals = this.tabs.map((tab, tabIndex) => {
      if (tab.tabChild) {
        tab.tabChild.tabIndex = tabIndex;
        this.tabSubscriptions.push(tab.tabChild.gotoIndex.subscribe((index: number) => (this.selectedIndex = index)));
      }
      return new TemplatePortal(tab.templateRef, this.viewContainerRef);
    });
  }

  private empty(): void {
    this.tabSubscriptions.forEach((sub) => sub.unsubscribe());
    this.tabSubscriptions.length = 0;
    this.portals.forEach((portal) => {
      if (portal.isAttached) {
        portal.detach();
      }
    });
  }
}
