import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { Team } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DynamicFieldService, MasterApiService } from '@agent-ds/shared/services';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ag-settings-teams-page',
  templateUrl: './settings-teams-page.component.html',
  styleUrls: ['./settings-teams-page.component.scss'],
})
export class SettingsTeamsPageComponent implements OnInit {
  @ViewChild('editButton', { static: true }) editButtonTemplate: TemplateRef<any>;
  @ViewChild('teamName', { static: true }) teamNameTemplate: TemplateRef<any>;
  @ViewChild('branchName', { static: true }) branchNameTemplate: TemplateRef<any>;
  @ViewChild('managerName', { static: true }) managerNameTemplate: TemplateRef<any>;
  @ViewChild('teamPopup', { static: true }) teamPopupTemplate: TemplateRef<any>;
  @ViewChild('teamForm', { static: false }) teamFormComponent: DynamicFormComponent;

  tableConfig: TableConfig;
  teams: Team[] = [];
  teamPopupModel: {
    managerId: number;
    branchId: number;
    name: string;
  };
  teamPopupMeta: FormMeta;

  constructor(private masterApiService: MasterApiService, private dynamicService: DynamicFieldService) {}

  ngOnInit() {
    this.masterApiService.getTeams().subscribe((teams: Team[]) => {
      this.teams = teams;
    });
    this.teamPopupMeta = {
      groups: [
        {
          class: 'no-border no-background title-align-top title-w90 row-gap-20',
          rows: [
            {
              title: 'チーム名',
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  validators: { required: true },
                  class: 'tall three-quarter',
                },
              ],
            },
            (() => {
              const rows = this.dynamicService.getFormRows({
                fieldName: 'managerId',
                fieldType: 'user',
                label: 'チーム代表',
              });
              rows[0].fields[0].class = 'tall three-quarter';
              rows[0].fields[0].validators = { required: true };
              return rows[0];
            })(),
            (() => {
              const rows = this.dynamicService.getFormRows({
                fieldName: 'branchId',
                fieldType: 'branch',
                label: 'エリア',
                displayType: 'radio',
                validationStyle: { required: true },
              });
              rows[0].fields[0].class = 'vertical';
              rows[0].fields[0].validators = { required: true };
              rows[0].showRequired = false;
              return rows[0];
            })(),
          ],
        },
      ],
    };
    this.tableConfig = {
      head: {
        style: {
          padding: '7px 0',
        },
        config: [
          {
            columns: [
              {
                fields: [
                  {
                    name: 'name',
                    title: 'チーム名',
                    sortable: false,
                    cellTemplate: this.teamNameTemplate,
                  },
                ],
                style: {
                  width: '285px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'manager.name',
                    title: 'チーム代表',
                    cellTemplate: this.managerNameTemplate,
                    sortable: false,
                  },
                ],
                style: {
                  width: '220px',
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'branch.name',
                    title: 'エリア',
                    cellTemplate: this.branchNameTemplate,
                    sortable: false,
                  },
                ],
                style: {
                  flex: 1,
                  'padding-right': '15px',
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
              {
                fields: [
                  {
                    name: 'actions',
                    title: '',
                    sortable: false,
                    cellTemplate: this.editButtonTemplate,
                  },
                ],
                style: {
                  width: '48px',
                  'padding-right': 0,
                },
                bodyStyle: {
                  'padding-top': '7px',
                  'padding-bottom': '7px',
                },
              },
            ],
          },
        ],
      },
      body: {
        checkbox: false,
        arrow: false,
        class: 'no-hover',
        style: {
          height: '34px',
        },
      },
    };
  }

  openTeamEdit(team?: Team): void {
    this.teamPopupModel = {
      branchId: (team && team.branch && team.branch.id) || null,
      managerId: (team && team.manager && team.manager.id) || null,
      name: (team && team.name) || null,
    };
    PopupControlComponent.instance.open({
      title: 'チーム設定',
      cancelText: 'キャンセル',
      confirmText: team ? '更新' : '保存',
      width: '800px',
      height: '450px',
      content: this.teamPopupTemplate,
      confirmEnabled: () => this.teamFormComponent && this.teamFormComponent.myForm.valid,
      confirmCallback: () => {
        const request = team
          ? this.masterApiService.updateTeam(team.id, this.teamPopupModel)
          : this.masterApiService.addTeam(this.teamPopupModel);
        return request.pipe(concatMap(() => this.masterApiService.getTeams().pipe(tap((teams: Team[]) => (this.teams = teams)))));
      },
    });
  }
}
