import { EnterpriseDepartmentUserType, EnterpriseSuggestResponse } from '@agent-ds/shared/interfaces';
import { StudentBulkConfig } from './student-bulk-config';

export class ProgressBulkConfig extends StudentBulkConfig {
  public setParams(params: any) {
    if (this.sender) {
      this.sender.model.enterpriseIds = params.enterprises.map((e: EnterpriseSuggestResponse) => {
        this.sender.enterpriseInfoById[e.id] = `${e.frontId} ${e.name}`;
        return e.id;
      });
      this.sender.model.enterpriseId = params.enterprises[0].id;
      this.sender.model.jobId = params.jobId;
      this.sender.model.jobs = params.jobs;
    }
    super.setParams(params);
  }

  public update(): void {
    if (this.sender.model.jobs && this.sender.model.jobs[0]) {
      this.sender.model.cc = this.sender.model.jobs[0].jobUsers
        .filter((u) => u.type === EnterpriseDepartmentUserType.アシ)
        .map((u) => u.userId);
    }
    super.update();
  }
}
