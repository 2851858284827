import { KpiSetupDialogComponent } from '@agent-ds/dashboard/components/kpi-setup-dialog/kpi-setup-dialog.component';
import { SecondMatchDialogComponent } from '@agent-ds/dashboard/components/second-match-dialog/second-match-dialog.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { EnterprisesKpi, Kpi, ProgressKpi, StudentsKpi, TargetKpi } from '@agent-ds/shared/interfaces/dashboard';
import { AuthService, DialogService } from '@agent-ds/shared/services';
import { DashboardApiService } from '@agent-ds/shared/services/api/dashboard-api.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ag-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  selectedManagementTeam: number;
  visualDashboardType: DashboardType;
  realDashboardType: DashboardType;
  kpi: Kpi = Kpi.empty();
  progress: { thisYear: ProgressKpi; nextYear: ProgressKpi } = { thisYear: ProgressKpi.empty(), nextYear: ProgressKpi.empty() };
  enterprises: EnterprisesKpi = EnterprisesKpi.empty();
  students: StudentsKpi = StudentsKpi.empty();
  loginSubscription: Subscription;
  collapsedChild = false;
  userHasManagementRole = false;
  userIsCA = false;
  isIntakeCa = false;

  constructor(public readonly authService: AuthService, private dasboardService: DashboardApiService, private dialog: DialogService) {}

  ngOnInit() {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      if (logined) {
        this.updateData(
          this.authService.loginUser.dashboardType === DashboardType.RA_Manager ||
            this.authService.loginUser.dashboardType === DashboardType.CA_Manager
            ? this.authService.loginUser.managementTeams && this.authService.loginUser.managementTeams.length
              ? this.authService.loginUser.managementTeams[0].id
              : null
            : null,
          this.authService.loginUser.dashboardType || DashboardType.RAPA,
        );
      }
    });
  }

  kpi10000tap(kpi: Kpi): Kpi {
    kpi.sales.actual = kpi.sales.actual ? kpi.sales.actual / 10000 : kpi.sales.actual;
    kpi.sales.target = kpi.sales.target ? kpi.sales.target / 10000 : kpi.sales.target;
    kpi.cancels.amount = kpi.cancels.amount ? kpi.cancels.amount / 10000 : kpi.cancels.amount;
    return kpi;
  }

  updateData(newTeamId: number, newDashboardType: number = this.realDashboardType): void {
    // 副担当者であれば、ダッシュボードを表示しない
    if (newDashboardType === DashboardType.INTAKE_CA) {
      this.isIntakeCa = true;
      return;
    }

    // update only if something has changed
    if (newTeamId !== this.selectedManagementTeam || newDashboardType !== this.realDashboardType) {
      this.selectedManagementTeam = newTeamId;
      this.realDashboardType = newDashboardType;
      // if the user is in CA or RA management role, but he didn't select a managedTeam,
      // he will see a plain CA or RA dashboard rather than a CA_Manager or RA_Manager dashboard
      if (!this.selectedManagementTeam) {
        switch (this.realDashboardType) {
          case DashboardType.CA_Manager:
            this.visualDashboardType = DashboardType.CA;
            break;
          case DashboardType.RA_Manager:
            this.visualDashboardType = DashboardType.RAPA;
            break;
          default:
            this.visualDashboardType = this.realDashboardType;
        }
      } else {
        this.visualDashboardType = this.realDashboardType;
      }

      const thisYear = Date.fiscalYear();
      this.userHasManagementRole = this.realDashboardType === 1 || this.realDashboardType === 4;
      this.userIsCA = this.realDashboardType === 3 || this.realDashboardType === 4;

      switch (this.visualDashboardType) {
        case DashboardType.RA_Manager:
        case DashboardType.CA_Manager:
          this.dasboardService
            .getTeamKPI(this.selectedManagementTeam, this.visualDashboardType)
            .subscribe((kpi: Kpi) => (this.kpi = this.kpi10000tap(kpi)));
          this.dasboardService
            .getTeamProgressWithNextYear(this.selectedManagementTeam, this.visualDashboardType, thisYear)
            .subscribe((kpi: ProgressKpi) => {
              this.progress.thisYear = kpi;
            });
          this.dasboardService
            .getTeamProgress(this.selectedManagementTeam, this.visualDashboardType, thisYear + 2)
            .subscribe((kpi: ProgressKpi) => {
              this.progress.nextYear = kpi;
            });

          if (this.visualDashboardType === DashboardType.RA_Manager) {
            this.dasboardService.getTeamEnterprises(this.selectedManagementTeam).subscribe((kpi: EnterprisesKpi) => {
              this.enterprises = kpi;
            });
          } else {
            this.dasboardService.getTeamStudents(this.selectedManagementTeam).subscribe((kpi: StudentsKpi) => {
              this.students = kpi;
            });
          }
          break;
        default:
          this.dasboardService.getKPI(this.visualDashboardType).subscribe((kpi: Kpi) => (this.kpi = this.kpi10000tap(kpi)));
          this.dasboardService.getProgressWithNextYear(this.visualDashboardType, thisYear).subscribe((kpi: ProgressKpi) => {
            this.progress.thisYear = kpi;
          });
          this.dasboardService.getProgress(this.visualDashboardType, thisYear + 2).subscribe((kpi: ProgressKpi) => {
            this.progress.nextYear = kpi;
          });

          if (this.visualDashboardType === DashboardType.RAPA) {
            this.dasboardService.getEnterprises().subscribe((kpi: EnterprisesKpi) => {
              this.enterprises = kpi;
            });
          } else if (this.visualDashboardType === DashboardType.CA) {
            this.dasboardService.getStudents().subscribe((kpi: StudentsKpi) => {
              this.students = kpi;
            });
          } else {
            this.dasboardService.getEnterprisesOfAssistant().subscribe((kpi: EnterprisesKpi) => {
              this.enterprises = kpi;
            });
          }
          break;
      }
    }
  }

  kpiSetupClicked(): void {
    this.dialog.open(KpiSetupDialogComponent, {
      data: {
        model: Object.keys(this.kpi).reduce((result: TargetKpi, key: string) => {
          if (result[key] !== undefined) {
            result[key] = this.kpi[key] && this.kpi[key].target ? this.kpi[key].target : 0;
          }
          return result;
        }, TargetKpi.empty()),
        dashboardType: this.visualDashboardType,
        selectedManagementTeam: this.selectedManagementTeam,
        updater: this.updateKpi,
      },
    });
  }

  updateKpi = (newKpi: TargetKpi): Observable<HttpResponse<void>> => {
    const kpi = { kpiTarget: newKpi };
    const res = this.selectedManagementTeam
      ? this.dasboardService.updateTeamKpi(this.selectedManagementTeam, kpi)
      : this.dasboardService.updateKpi(kpi);

    return res.pipe(
      tap(() => {
        Object.keys(newKpi).forEach((key) => {
          this.kpi[key].target = newKpi[key];
          if (key === 'sales' && this.kpi[key].target) {
            this.kpi[key].target /= 10000;
          }
        });
      }),
    );
  };

  secondMatchClicked(): void {
    this.dialog.open(SecondMatchDialogComponent, {
      data: {},
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }
}
