import { Mail, MailPrefill } from '@agent-ds/shared/interfaces';
import { CompanyApiService, DialogService, MailApiService, StudentApiService } from '@agent-ds/shared/services';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'ag-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
})
export class MailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: 'students' | 'enterprise' = 'students';
  @Input() referenceId: number;
  @Input() service: StudentApiService | CompanyApiService;
  @Output() send = new EventEmitter<MailPrefill>();
  searchInputValue: string;
  quote: boolean;

  sortValues = [{ title: '日付 降順', sort: 'timestamp', order: 'desc' }];
  sortValue: { title: string; sort: string; order: string } = this.sortValues[0];

  mails: Mail[] = [];
  incoming: Mail[] = [];
  outgoing: Mail[] = [];
  total = 0;
  private page = 0;
  private innerLoading = false;

  selectedList: Mail[] = this.mails;
  selectedMail: Mail;

  private refreshSubscription: Subscription;
  private serviceRefreshSubscription: Subscription;

  constructor(private mailApiService: MailApiService, private dialogService: DialogService) {}

  ngOnInit() {
    this.refreshSubscription = this.mailApiService.refreshEvent.subscribe(() => this.ngOnChanges());
    this.serviceRefreshSubscription = this.service ? this.service.refreshEvent.subscribe(() => this.init()) : null;
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.serviceRefreshSubscription) {
      this.serviceRefreshSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (!changes || changes['referenceId']) {
      if (this.referenceId) {
        this.init();
      } else {
        this.total = 0;
        this.mails.length = this.incoming.length = this.outgoing.length = 0;
      }
    }
  }

  onListChanges(list: Mail[]): void {
    this.selectedList = list;
    this.selectedMail = null;
  }

  onSelectedChanges(mail: Mail): void {
    this.selectedMail = mail;
    this.quote = false;
    if (!this.selectedMail.attachments && this.selectedMail.mime.attachments) {
      this.mailApiService
        .getAttachmentInfo(mail.message.mail.messageId)
        .subscribe((res) => (mail.attachments = res), (err) => (mail.attachments = []));
    }
  }

  onSearchChange(value: string, run = false): void {
    this.searchInputValue = value;
    if (run) {
      this.init();
    }
  }

  onSortChange(val: { title: string; sort: string; order: string }): void {
    this.sortValue = val;
    this.init();
  }

  onScroll(event: any): void {
    if (
      this.mails.length < this.total &&
      !this.innerLoading &&
      event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95
    ) {
      this.init(false);
    }
  }

  onReply(): void {
    const prefill = this.generatePrefill('Re');
    prefill.inReplyTo = this.selectedMail.message.mail.messageId;
    this.send.emit(prefill);
  }

  onForward(): void {
    const prefill = this.generatePrefill('Fwd');
    this.send.emit(prefill);
  }

  private generatePrefill(subjectPrefix: string): MailPrefill {
    return {
      subject: `${subjectPrefix}: ${this.selectedMail.mime.subject}`,
      body: this.quote ? '> ' + this.selectedMail.mime.text.replace(/(\r?\n|\n)/g, '$1> ') : null,
      references: this.selectedMail.mime.references,
    };
  }

  downloadAttachment(partId: number): void {
    this.mailApiService.downloadAttachment(this.selectedMail.message.mail.messageId, partId);
  }

  init(reset = true): void {
    if (this.innerLoading) {
      return;
    }
    if (reset) {
      this.page = 0;
    }
    this.innerLoading = true;
    this.mailApiService
      .getMails(this.model, this.referenceId, this.page++ * 20, 20, this.sortValue.sort, this.sortValue.order, this.searchInputValue)
      .subscribe(
        (res) => {
          if (reset) {
            this.mails = res.mails;
            this.incoming = this.mails.filter((mail) => !mail.isSend);
            this.outgoing = this.mails.filter((mail) => mail.isSend);
            this.selectedMail = null;
            this.selectedList = this.mails;
          } else {
            this.mails.push(...res.mails);
            this.incoming.push(...res.mails.filter((mail) => !mail.isSend));
            this.outgoing.push(...res.mails.filter((mail) => mail.isSend));
          }
          this.total = res.total;
          this.innerLoading = false;
        },
        (error) => {
          console.error('get mail error', error);

          if (!error.status) {
            // ネットワークに繋がらなかったときはエラーを通知する
            this.showErrorDialog();
          } else {
            // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
          }

          // エラーが起きたため、一度メールタブの内容をクリアする
          this.mails = [];
          this.incoming = [];
          this.outgoing = [];
          this.selectedMail = null;
          this.selectedList = [];
          this.total = 0;
          this.innerLoading = false;
        },
      );
  }

  getTargetAsText(target: { address: string; name: string }[]): string {
    return target ? target.map((t) => (t.name ? `${t.name} <${t.address}>` : t.address)).join(',') : '';
  }

  private showErrorDialog() {
    const dialogConfig = new ConfirmDialogConfig();
    dialogConfig.zIndex = 8000;
    dialogConfig.title = 'エラーが発生しました。';
    dialogConfig.isError = true;
    dialogConfig.buttons = { yes: 'OK', no: '', hideNo: true };
    dialogConfig.messages = ['メール取得中に通信エラーが発生しました。', 'メールタブの最新情報に更新ボタンを押して再度取得してください。'];
    this.dialogService.open(ConfirmDialogComponent, dialogConfig);
  }
}
