import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { formatAddress, getValueFromObject } from '@agent-ds/shared/util/util';
import { TemplateRef } from '@angular/core';

export const COMPANY_SECTIONS_DEPARTMENT_TABLE_CONFIG: (
  statusTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  actionsBottomTemplate: TemplateRef<any>,
) => TableConfig = (statusTemplate, actionsTemplate, actionsBottomTemplate) => ({
  head: {
    config: [
      {
        dynamicStyle: {
          'background-color': (data) => (data.status === '廃止' ? '#F7F7F7' : null),
        },
        columns: [
          {
            fields: [
              {
                name: 'dynamicData.status',
                hidden: true,
                cellTemplate: statusTemplate,
              },
            ],
            style: {
              width: '30px',
              'padding-left': '0',
              'align-items': 'center',
              'justify-content': 'center',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.name',
                title: '部署ID・所属部署',
                sortable: false,
                style: {
                  'margin-bottom': '2px',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '14px',
                  'line-height': '16px',
                },
                formatter: (data) => [data.id, data.dynamicData.name].filter((t) => t).join(' '),
              },
              {
                name: 'dynamicData.contact1.name',
                title: '役職・担当者氏名',
                formatter: (data) =>
                  [getValueFromObject(data, 'dynamicData.contact1.position'), getValueFromObject(data, 'dynamicData.contact1.name')]
                    .filter((t) => t)
                    .join('・'),
                sortable: false,
              },
            ],
            style: {
              width: '190px',
              'padding-right': '20px',
            },
          },
          {
            fields: [
              {
                name: 'address',
                title: '住所',
                sortable: false,
                formatter: (data) =>
                  data.dynamicData.address && data.dynamicData.address.zip ? `〒${formatAddress(data.dynamicData.address, true)}` : '',
                style: {
                  'margin-bottom': '2px',
                },
              },
              {
                name: 'tel',
                title: 'Tel / Fax',
                sortable: false,
                formatter: (data) =>
                  data.dynamicData.contact1 ? `${data.dynamicData.contact1.tel || '-'} / ${data.dynamicData.contact1.fax || '-'}` : '',
              },
            ],
            style: {
              width: '525px',
              'padding-right': '20px',
            },
          },
          {
            fields: [
              {
                name: 'actions',
                hidden: true,
                cellTemplate: actionsTemplate,
              },
            ],
            style: {
              flex: '1',
              'align-items': 'flex-end',
            },
          },
        ],
      },
      {
        hidden: true,
        dynamicStyle: {
          'background-color': (data) => (data.status === '廃止' ? '#F7F7F7' : null),
          'border-top': () => '1px solid #EBEBEB',
        },
        columns: [
          {
            fields: [
              {
                name: 'static.0',
                hidden: true,
              },
            ],
            style: {
              width: '30px',
              'padding-left': '0',
              'align-items': 'center',
              'justify-content': 'center',
            },
          },
          {
            fields: [
              {
                name: 'static.1',
                style: {
                  'margin-bottom': '2px',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '12px',
                  'line-height': '16px',
                  'font-weight': 'bold',
                  color: '#3B3B3B',
                },
                formatter: () => 'リスト送付',
              },
            ],
            style: {
              width: '190px',
              'padding-right': '20px',
            },
          },

          {
            fields: [
              {
                name: 'dynamicData.listExportTemplateId',
                formatter: (data) => (data.dynamicData.listExportTemplateId ? '設定あり' : '設定なし'),
                style: {
                  'margin-bottom': '2px',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '12px',
                  'line-height': '16px',
                  'font-weight': 'bold',
                  color: '#3B3B3B',
                },
              },
            ],
            style: {
              width: '525px',
              'padding-right': '20px',
            },
          },
          {
            fields: [
              {
                name: 'actions',
                hidden: true,
                cellTemplate: actionsBottomTemplate,
              },
            ],
            style: {
              flex: '1',
              'align-items': 'flex-end',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: false,
    arrow: false,
    class: '',
  },
});

/*
sales.enterpriseBillingAddress.dynamicData.billignBikeCD +
sales.enterpriseBillingAddress.dynamicData.billignAddressCode;
*/
export const COMPANY_SECTIONS_BILLING_ADDRESS_TABLE_CONFIG: (actionsTemplate: TemplateRef<any>) => TableConfig = (actionsTemplate) => ({
  head: {
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'dynamicData.billignBikeCD',
                title: '発行先コード',
                formatter: (data) =>
                  [getValueFromObject(data, 'dynamicData.billignBikeCD'), getValueFromObject(data, 'dynamicData.billignAddressCode')]
                    .filter((t) => t)
                    .join(''),
                sortable: false,
              },
              {
                name: 'dynamicData.companyName',
                title: '請求先名',
                sortable: false,
                style: {
                  'margin-bottom': '2px',
                  'font-family': '"Meiryo UI Bold", sans-serif',
                  'font-size': '14px',
                  'line-height': '16px',
                },
              },
              {
                name: 'dynamicData.contact.name',
                title: '部署・役職・担当者氏名',
                formatter: (data) =>
                  [
                    getValueFromObject(data, 'dynamicData.departmentName'),
                    getValueFromObject(data, 'dynamicData.contact.position'),
                    getValueFromObject(data, 'dynamicData.contact.name'),
                  ]
                    .filter((t) => t)
                    .join('・'),
                sortable: false,
              },
            ],
            style: {
              width: '197px',
              'padding-left': '30px',
              'padding-right': '20px',
            },
          },
          {
            fields: [
              {
                name: 'dynamicData.billignAddressService',
                title: '請求先サービス',
                sortable: false,
                formatter: (data) => (data.dynamicData.billignAddressService ? `${data.dynamicData.billignAddressService}` : '-'),
              },
              {
                name: 'dynamicData.btoBBillignAddressRegistrationDate',
                title: 'BtoB請求先登録日',
                sortable: false,
                formatter: (data) =>
                  data.dynamicData.btoBBillignAddressRegistrationDate ? `${data.dynamicData.btoBBillignAddressRegistrationDate}` : '-',
              },
              {
                name: 'dynamicData.howToSendInvoice',
                title: '請求書送付方法',
                sortable: false,
                formatter: (data) => (data.dynamicData.howToSendInvoice ? `${data.dynamicData.howToSendInvoice}` : '-'),
              },
            ],
            style: {
              width: '197px',
              'padding-left': '30px',
              'padding-right': '20px',
            },
          },
          {
            fields: [
              {
                name: 'address',
                title: '住所',
                formatter: (data) =>
                  data.dynamicData.address && data.dynamicData.address.zip ? `〒${formatAddress(data.dynamicData.address, true)}` : '',
                sortable: false,
                style: {
                  'margin-bottom': '2px',
                },
              },
              {
                name: 'tel',
                title: 'Tel / Fax',
                sortable: false,
                formatter: (data) =>
                  data.dynamicData.contact ? `${data.dynamicData.contact.tel || '-'} / ${data.dynamicData.contact.fax || '-'}` : '',
              },
            ],
            style: {
              flex: '1',
            },
          },
          {
            fields: [
              {
                name: 'actions',
                hidden: true,
                cellTemplate: actionsTemplate,
              },
            ],
            style: {
              flex: '1',
              'align-items': 'flex-end',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: false,
    arrow: false,
    class: '',
  },
});
