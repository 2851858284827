import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType, ProgressStakeholderEmailResponse } from '@agent-ds/shared/interfaces';
import { FormMeta, RowMeta, SupplierCallType } from '@agent-ds/shared/models';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailSendConfig } from '../config';

export class ProgressSendInterviewInfoConfig extends MailSendConfig {
  public getTemplateId(): number {
    if (this.sender.model.progressType === 0) {
      return 15;
    } else if (this.sender.model.progressType === 1) {
      return 20;
    }
    return;
  }

  public getTemplateTypeId(): number[] {
    return [9];
  }

  public setParams(params: any): void {
    if (this.sender) {
      this.sender.model.stakeholders = params.stakeholders || this.sender.model.stakeholders;
      this.sender.model.progressId = params.progressId || this.sender.model.progressId;
      this.sender.model.progressType = params.progressType;
      this.sender.model.progressStatus = params.progressStatus;
      this.sender.model.progressSeminarType = params.progressSeminarType;
      this.sender.model.progressSeminarAt = params.progressSeminarAt;
      this.sender.model.studentId = params.studentId || this.sender.model.studentId;
      this.sender.model.jobId = params.jobId || this.sender.model.jobId;
      this.sender.model.enterpriseId = params.enterpriseId || this.sender.model.enterpriseId;
    }
    super.setParams(params);
  }

  public initForm(): void {
    if (!this.sender.model.stakeholders) {
      return;
    }
    const stakeholders: ProgressStakeholderEmailResponse = this.sender.model.stakeholders;

    const metaData: FormMeta = {
      disabled: this.sender.inConfirm,
      groups: [
        {
          title: '新規メール作成',
          rows: [],
        },
      ],
    };

    this.sender.model.cc_single_hidden = stakeholders.studentInfo.sub != null && stakeholders.studentInfo.sub !== '';

    metaData.groups[0].rows.push({
      title: 'To (求職者)',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'to',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              multi: true,
              disabled: stakeholders.studentInfo.mainAvailable === '不可',
              options: stakeholders.studentInfo.main
                ? [
                    {
                      label: `${stakeholders.studentInfo.mainAvailable === '不可' ? '【送信不可】' : ''}${stakeholders.studentInfo
                        .lastName + stakeholders.studentInfo.firstName} メイン <${stakeholders.studentInfo.main}>`,
                      value: stakeholders.studentInfo.main,
                    },
                  ]
                : [],
              validators: { required: true },
            }
          : {
              name: 'to',
              type: 'label',
              supplier: () =>
                `${stakeholders.studentInfo.mainAvailable === '不可' ? '【送信不可】' : ''}${stakeholders.studentInfo.lastName +
                  stakeholders.studentInfo.firstName} メイン <${stakeholders.studentInfo.main}>`,
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Cc (求職者、社員)',
            fieldName: 'cc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.class = 'ou';
        row.fields[0].labelBefore = '社員：';
        if (!this.sender.inConfirm) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'checkbox',
              labelField: 'label',
              valueField: 'value',
              class: 'full',
              multi: true,
              disabled: stakeholders.studentInfo.subAvailable === '不可',
              options: stakeholders.studentInfo.sub
                ? [
                    {
                      label: `${stakeholders.studentInfo.subAvailable === '不可' ? '【送信不可】' : ''}${stakeholders.studentInfo.lastName +
                        stakeholders.studentInfo.firstName} サブ <${stakeholders.studentInfo.sub}>`,
                      value: stakeholders.studentInfo.sub,
                    },
                  ]
                : [],
              showOn: { cc_single_hidden: null },
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
              showOn: { cc_single_hidden: null },
            },
          );
        } else if (this.sender.model.cc_single.length) {
          row.fields.unshift(
            {
              name: 'cc_single',
              type: 'label',
              class: 'full',
              supplier: () =>
                stakeholders.studentInfo.sub
                  ? `${stakeholders.studentInfo.subAvailable === '不可' ? '【送信不可】' : ''}${stakeholders.studentInfo.lastName +
                      stakeholders.studentInfo.firstName} サブ <${stakeholders.studentInfo.sub}>`
                  : '',
            },
            {
              name: 'cc-hr',
              type: 'hr',
              class: 'full',
              showOn: stakeholders ? { cc_single_hidden: null } : null,
            },
          );
        }
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'multi-user',
            label: 'Bcc (社員)',
            fieldName: 'bcc',
            displayType: 'email',
          },
          null,
          'half sticky-label',
        )[0];
        row.fields[0].labelBefore = '社員：';
        return row;
      })(),
    );
    metaData.groups[0].rows.push(
      (() => {
        const row = this.sender.dynamicService.getFormRows(
          {
            fieldType: 'user',
            // displayType: 'email',
            label: '差出人',
            fieldName: 'from',
            validationStyle: { required: true },
          },
          null,
          'half',
        )[0];
        row.fields[0].linkTo = !this.sender.inConfirm ? ['signature'] : null;
        row.fields[0].specialOption = '';
        return row;
      })(),
    );
    metaData.groups[0].rows.push({
      title: '件名',
      showRequired: true,
      fields: [
        !this.sender.inConfirm
          ? {
              name: 'subject',
              type: 'text',
              class: 'full',
              validators: { required: true },
              supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
                const template = getValue('dummyTemplateType');
                return template ? value || template.subject : undefined;
              },
            }
          : {
              type: 'label',
              name: 'subject',
              supplier: () => (this.sender ? this.sender.model.subject : ''),
            },
      ],
    });
    metaData.groups[0].rows.push(
      (() => {
        const row: RowMeta = {
          title: '添付ファイル',
          fields: [
            {
              name: 'enterpriseId',
              type: 'label',
              class: 'half',
              supplier: () =>
                this.sender && this.sender.model.enterpriseId
                  ? this.sender.companyApiService
                      .getSuggestions(undefined, [this.sender.model.enterpriseId])
                      .pipe(map((res) => `${res[0].frontId} ${res[0].name}`))
                  : '',
            },
          ],
        };
        row.class = 'ou';
        if (!this.sender.inConfirm) {
          row.fields[0].labelBefore = '企業：';
          row.fields[0].linkTo = ['enterpriseFileIds'];
          row.fields[0].actions = [
            {
              title: 'データフォルダから選択',
              type: 'RUNNABLE',
              allowOn: { enterpriseId: null },
              runnable: () => {
                this.sender.enterpriseFileIds = [...this.sender.model.enterpriseFileIds];
                PopupControlComponent.instance.open({
                  content: this.sender.fileSelectTemplate,
                  confirmText: '添付',
                  cancelText: 'キャンセル',
                  title: 'データフォルダ',
                  confirmCallback: () => {
                    this.sender.model.enterpriseFileIds = [...this.sender.enterpriseFileIds];
                  },
                });
              },
            },
          ];
        } else {
          row.fields.length = 0;
        }

        if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
          row.fields.push({
            name: 'enterpriseFileIds',
            type: 'autocomplete',
            labelField: [
              {
                name: 'name',
                class: this.sender.inConfirm ? 'link' : null,
                action: this.sender.inConfirm
                  ? (file) =>
                      this.sender.fileService.downloadFile(
                        'enterprise',
                        +Object.keys(this.sender.enterpriseFiles).find(
                          (key) => this.sender.enterpriseFiles[key].find((f) => f.id === file.id) != null,
                        ),
                        file.id,
                        file.name,
                      )
                  : null,
              },
            ],
            labelBefore: this.sender.inConfirm ? '企業：' : null,
            valueField: 'id',
            multi: true,
            style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
            class: 'options-only half',
            options: [],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string, override?: boolean) => any) => {
              const id = getValue('enterpriseId', true);
              if (!this.sender) {
                return { options: [] };
              }
              const selectedFiles = (Object.values(this.sender.enterpriseFiles).flatten() as any).filter((f) =>
                this.sender.model.enterpriseFileIds.includes(f.id),
              );
              if (id === this.sender.enterpriseIdForFile) {
                return { options: selectedFiles.concat(this.sender.enterpriseFiles[id]) };
              }
              if (!id) {
                return { options: selectedFiles };
              } else {
                this.sender.enterpriseIdForFile = id;
                return this.sender.fileService.getFiles('enterprise', id).pipe(
                  map((res) => {
                    this.sender.enterpriseFiles[id] = res.filter((f) => f.studentSendFlag);
                    return { options: selectedFiles.concat(this.sender.enterpriseFiles[id]) };
                  }),
                );
              }
            },
          });
          if (!this.sender.inConfirm || this.sender.model.studentFileIds.length) {
            row.fields.push({
              name: 'file-hr',
              type: 'hr',
              class: 'full',
            });
          }
        }
        if (!this.sender.inConfirm || this.sender.model.studentFileIds.length) {
          row.fields.push({
            name: 'studentFileIds',
            type: !this.sender.inConfirm ? 'checkbox' : 'autocomplete',
            class: 'multi-ou sticky-label full',
            labelField: !this.sender.inConfirm
              ? 'name'
              : [
                  {
                    name: 'name',
                    class: this.sender.inConfirm ? 'link' : null,
                    action: this.sender.inConfirm
                      ? (file) => this.sender.fileService.downloadFile('students', this.sender.model.studentId, file.id, file.name)
                      : null,
                  },
                ],
            valueField: 'id',
            labelBefore: '求職者：',
            multi: true,
            options: [],
            supplier: () => ({ options: this.sender.files }),
          });
        }
        return row;
      })(),
    );

    if (!this.sender.inConfirm) {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'body',
            type: 'textarea',
            class: 'full',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const template = getValue('dummyTemplateType');
              return template ? value || template.body : undefined;
            },
          },
        ],
      });
      metaData.groups[0].rows.push({
        title: '署名',
        class: 'ou',
        fields: [
          {
            name: 'signature',
            type: 'dropdown',
            labelField: 'title',
            class: 'half',
            options: [],
            linkTo: ['signatureBody'],
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const id = this.sender.authService.loginUser.id;
              if (id === this.sender.userIdForSignatures) {
                return { options: this.sender.userSignature };
              }
              return id
                ? this.sender.mailApiService.getSignature('user', id).pipe(
                    map((res) => {
                      this.sender.userIdForSignatures = id;
                      this.sender.userSignature = [
                        { title: NULL_SELECTED_VALUE, id: null, body: null },
                        ...(this.sender.model.progressType === 1 ? [] : res),
                      ];
                      return {
                        value: value ? (this.sender.model.progressType === 1 ? null : res.find((s) => s.id === value.id)) : undefined,
                        options: this.sender.userSignature,
                      };
                    }),
                  )
                : { options: [] };
            },
          },
          {
            name: 'signatureBody',
            type: 'textarea',
            class: 'full',
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              const sign = getValue('signature');
              const ret = sign === this.sender.selectedSignature ? undefined : sign ? sign.body : '';
              this.sender.selectedSignature = sign;
              return ret;
            },
          },
        ],
      });
    } else {
      metaData.groups[0].rows.push({
        title: '本文',
        showRequired: true,
        fields: [
          {
            name: 'text',
            type: 'label',
            class: 'full pre',
            validators: { required: true },
            supplier: (value: any, callType: SupplierCallType, getValue?: (key: string) => any) => {
              if (value) {
                return value;
              }
              const signatureBody = getValue('signatureBody');
              const body = getValue('body');
              return signatureBody ? body + '\n' + signatureBody : body;
            },
          },
        ],
      });
    }
    this.sender.meta = metaData;
  }

  public update(): void {
    const stakeholders: ProgressStakeholderEmailResponse = this.sender.model.stakeholders;

    if (stakeholders.studentInfo && stakeholders.studentInfo.main && !stakeholders.studentInfo.mainAvailable) {
      this.sender.model.to = [stakeholders.studentInfo.main];
    }

    if (stakeholders.studentInfo && stakeholders.studentInfo.sub && !stakeholders.studentInfo.subAvailable) {
      this.sender.model.cc_single = [stakeholders.studentInfo.sub];
    }

    const ccUserIds = stakeholders.jobUsersInfo
      .filter((jobUser) => jobUser.type === EnterpriseDepartmentUserType.アシ)
      .map((jobUser) => jobUser.id);
    if (this.sender.model.progressType === 0 && stakeholders.studentUserInfo && stakeholders.studentUserInfo.id) {
      ccUserIds.push(stakeholders.studentUserInfo.id);
    }
    this.sender.model.cc = ccUserIds;

    let bccUserIds;
    if (this.sender.model.progressType === 1) {
      bccUserIds = stakeholders.jobUsersInfo
        .filter((jobUser) => jobUser.type === EnterpriseDepartmentUserType.PA)
        .map((jobUser) => jobUser.id);
    } else if (this.sender.model.progressType === 0) {
      bccUserIds = stakeholders.jobUsersInfo
        .filter((jobUser) => jobUser.type === EnterpriseDepartmentUserType.RA)
        .map((jobUser) => jobUser.id);
    }
    this.sender.model.bcc = bccUserIds;

    if (this.sender.model.studentId && this.sender.studentIdForFiles !== this.sender.model.studentId) {
      this.sender.studentIdForFiles = this.sender.model.studentId;
      this.sender.fileService.getFiles('students', this.sender.model.studentId).subscribe((res) => {
        this.sender.files.length = 0;
        this.sender.files.push(...res);
        this.initForm();
      });
    }
  }

  public save(toSave: any): void {
    toSave['jobId'] = this.sender.model.jobId;
    this.sender.mailApiService.sendStudentMail(this.sender.model.studentId, toSave).subscribe((res) => this.sender.afterSend(res));
  }
}
