import { SharedModule } from '@agent-ds/shared';
import { NgModule } from '@angular/core';
import { SALES_DIALOG_COMPONENTS } from './components';
import { SalesDetailHeaderComponent } from './components/sales-detail-header/sales-detail-header.component';
import { SalesReportFilterComponent } from './components/sales-report-filter/sales-report-filter.component';
import { SalesSearchComponent } from './components/sales-search/sales-search.component';
import { SALES_DETAIL_HELPERS } from './sales-detail-page/helpers';
import { SalesDetailPageComponent } from './sales-detail-page/sales-detail-page.component';
import { SalesListPageComponent } from './sales-list-page/sales-list-page.component';
import { SalesReportPageComponent } from './sales-report-page/sales-report-page.component';
import { SalesRoutingModule } from './sales-routing.module';

@NgModule({
  declarations: [
    SalesListPageComponent,
    SalesDetailPageComponent,
    SalesDetailHeaderComponent,
    SalesSearchComponent,
    SalesReportPageComponent,
    SalesReportFilterComponent,
    SALES_DIALOG_COMPONENTS,
  ],
  imports: [SharedModule, SalesRoutingModule],
  exports: [SalesDetailPageComponent],
  providers: [SALES_DETAIL_HELPERS],
  entryComponents: [SALES_DIALOG_COMPONENTS],
})
export class SalesModule {}
