import { SalesSearchRequest } from '@agent-ds/sales/dto/SalesSearchRequest';
import { Tab } from '@agent-ds/shared/models';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ag-company-sales-tab',
  templateUrl: './company-sales-tab.component.html',
  styleUrls: ['./company-sales-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => CompanySalesTabComponent) }],
})
export class CompanySalesTabComponent extends Tab implements OnChanges {
  @Input() companyId: number | null = null;
  requestObject: SalesSearchRequest;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
      this.requestObject = {
        enterpriseId: [this.companyId],
      };
    }
  }
}
