import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class DialogRef {
  private readonly afterClosedSubject = new Subject<any>();
  private readonly beforeClose: (params?: any) => boolean | void | Observable<any>;
  public readonly confirmed = new EventEmitter();
  private inCloseFlag: boolean;

  get afterClosed(): Observable<any> {
    return this.afterClosedSubject;
  }

  get inClose(): boolean {
    return this.inCloseFlag;
  }

  constructor(beforeClose?: (params?: any) => void | Observable<any>) {
    this.beforeClose = beforeClose;
  }

  close(result?: any): void {
    if (this.inClose) {
      return;
    }
    this.inCloseFlag = true;
    if (this.beforeClose) {
      const ret = this.beforeClose(result);
      if (ret instanceof Observable) {
        ret.subscribe(
          () => {
            this.afterClosedSubject.next(result);
            this.inCloseFlag = false;
          },
          () => {
            this.inCloseFlag = false;
          },
        );
      } else {
        this.inCloseFlag = false;
        if (!ret) {
          this.afterClosedSubject.next(result);
        }
      }
    } else {
      this.inCloseFlag = false;
      this.afterClosedSubject.next(result);
    }
  }
}
