import { SearchCategory } from '@agent-ds/shared/enums';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, DynamicFieldService } from '@agent-ds/shared/services';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChildren('select') selectInputs: QueryList<ElementRef<HTMLSelectElement>>;
  SearchCategory = SearchCategory;
  selectedSearchCategory: SearchCategory | null = null;
  showDropdown: boolean;
  focusedSearchBar: boolean;
  focusedInput: boolean;
  inputValue = '';
  loginSubscription: Subscription;
  isIntakeCa = false;

  readonly checkValuesByCategories = {
    [SearchCategory.JobSeeker]: [],
    [SearchCategory.Company]: [],
    [SearchCategory.JobOffer]: [],
  };

  constructor(private router: Router, private dynamicFieldService: DynamicFieldService, private readonly authService: AuthService) {}

  ngOnInit() {
    this.loginSubscription = this.authService.isLogined().subscribe((logined) => {
      // 副担当者かどうかを確認
      this.isIntakeCa = this.authService.loginUser && this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
    });

    this.dynamicFieldService.fieldUpdateEvent.subscribe(() => {
      this.checkValuesByCategories[SearchCategory.JobSeeker].length = 0;
      this.checkValuesByCategories[SearchCategory.JobOffer].length = 0;
      const studentRegField = this.dynamicFieldService.getDefinition('student', 'registrationStatus');
      if (studentRegField && studentRegField.validationStyle && studentRegField.validationStyle.options) {
        this.checkValuesByCategories[SearchCategory.JobSeeker].push({
          key: 'registrationStatus',
          options: [
            { value: '', label: '登録ステータス' },
            ...studentRegField.validationStyle.options.map((o) => ({ value: o, label: o })),
          ],
        });
      }

      const jobStatusField = this.dynamicFieldService.getDefinition('job', 'status');
      if (jobStatusField && jobStatusField.validationStyle && jobStatusField.validationStyle.options) {
        this.checkValuesByCategories[SearchCategory.JobOffer].push({
          key: 'status',
          options: [{ value: '', label: '求人ステータス' }, ...jobStatusField.validationStyle.options.map((o) => ({ value: o, label: o }))],
        });
      }
      const jobScreeningField = this.dynamicFieldService.getDefinition('job', 'screeningArea');
      if (jobScreeningField && jobScreeningField.validationStyle && jobScreeningField.validationStyle.options) {
        this.checkValuesByCategories[SearchCategory.JobOffer].push({
          key: 'screeningArea',
          options: [{ value: '', label: '選考エリア' }, ...jobScreeningField.validationStyle.options.map((o) => ({ value: o, label: o }))],
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = null;
    }
  }

  onSearchCategoryClick(searchCategory: SearchCategory): void {
    this.focusedSearchBar = true;
    this.selectedSearchCategory = this.selectedSearchCategory === searchCategory ? null : searchCategory;
    this.showDropdown = this.hasDropdownContent(this.selectedSearchCategory);
    this.focusInput();
  }

  onSearchBarClick(): void {
    this.showDropdown = !!this.selectedSearchCategory && this.hasDropdownContent(this.selectedSearchCategory);
    this.focusedSearchBar = true;
    if (!this.selectedSearchCategory) {
      this.selectedSearchCategory = SearchCategory.JobSeeker;
    }
  }

  onOutsideClick(): void {
    this.searchInput.nativeElement.blur();
    this.focusedInput = false;
    this.focusedSearchBar = false;
    this.showDropdown = false;
  }

  onSearch(event?: any): void {
    if (event && event['isComposing']) {
      return;
    }
    const obj = {};
    if (this.inputValue) {
      obj['keyword'] = this.inputValue;
    }
    this.selectInputs.forEach((item) => {
      if (item.nativeElement.value) {
        obj[item.nativeElement.name] = [item.nativeElement.value];
      }
    });
    if (!this.selectedSearchCategory || this.selectedSearchCategory === SearchCategory.JobSeeker) {
      this.router.navigate(['/students/list'], { queryParams: obj });
    } else if (this.selectedSearchCategory === SearchCategory.Company) {
      this.router.navigate(['/enterprises/list'], { queryParams: obj });
    } else {
      this.router.navigate(['/jobs/list'], { queryParams: obj });
    }
    this.onOutsideClick();
  }

  private focusInput(): void {
    this.searchInput.nativeElement.focus();
    this.focusedInput = true;
  }

  private hasDropdownContent(searchCategory: SearchCategory): boolean {
    return searchCategory === SearchCategory.JobSeeker || searchCategory === SearchCategory.JobOffer;
  }
}
