import { CompanyContactHistoryTabComponent } from './company-contact-history-tab/company-contact-history-tab.component';
import { CompanyContractsTabComponent } from './company-contracts-tab/company-contracts-tab.component';
import { CompanyDetailTabComponent } from './company-detail-tab/company-detail-tab.component';
import { CompanyFilesTabComponent } from './company-files-tab/company-files-tab.component';
import { CompanyJobsTabComponent } from './company-jobs-tab/company-jobs-tab.component';
import { CompanyMemoTabComponent } from './company-memo-tab/company-memo-tab.component';
import { CompanyMessageTabComponent } from './company-message-tab/company-message-tab.component';
import { CompanyOverviewTabComponent } from './company-overview-tab/company-overview-tab.component';
import { CompanyProgressTabComponent } from './company-progress-tab/company-progress-tab.component';
import { CompanySalesTabComponent } from './company-sales-tab/company-sales-tab.component';
import { CompanySectionsTabComponent } from './company-sections-tab/company-sections-tab.component';

export const COMPANY_DETAIL_TABS = [
  CompanyContactHistoryTabComponent,
  CompanyContractsTabComponent,
  CompanyDetailTabComponent,
  CompanyFilesTabComponent,
  CompanyJobsTabComponent,
  CompanyMemoTabComponent,
  CompanyMessageTabComponent,
  CompanyOverviewTabComponent,
  CompanyProgressTabComponent,
  CompanySalesTabComponent,
  CompanySectionsTabComponent,
];
