import { Pipe, PipeTransform } from '@angular/core';
import { getValueFromObject } from '../util/util';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], searchFor: any | any[], field?: string, negate?: boolean): any {
    if (!value) {
      return [];
    }
    return value.filter((item) => {
      const check = getValueFromObject(item, field);
      const result = Array.isArray(searchFor) ? searchFor.includes(check) : check === searchFor;
      return negate ? !result : result;
    });
  }
}
