import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { User } from '../interfaces';
import { PermanentStorageService } from './permanent-storage.service';

const AUTH_SERVICE_KEY_LOGINED = 'auth-logined';
export const REPORT_SUCCESS_HEADER = 'X-REPORT-SUCCESS';
export const HIDE_ERROR_MESSAGE_HEADER = 'X-HIDE-ERROR-MESSAGE-HEADER';
export const HIDE_ERROR_DIALOG_HEADER = 'X-HIDE-ERROR-DIALOG-HEADER';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly logined = new ReplaySubject<boolean>(1);

  /**
   * コンストラクタ
   */
  constructor(private http: HttpClient, private storage: PermanentStorageService) {
    if (!this.hasToken()) {
      this.logout();
    }
  }

  get loginUser(): User {
    return this.loginUserRef;
  }
  get authInfo(): { zone: 'zone3' | 'other' } {
    return this.authInfoRef;
  }
  private loginUserRef: User;
  private loginUserGetObservable: Observable<any>;
  private authInfoRef: { zone: 'zone3' | 'other' };

  /**
   * ログイン済みか
   */
  isLogined(): Observable<boolean> {
    if (this.hasToken()) {
      if (!this.loginUserRef) {
        if (!this.loginUserGetObservable) {
          this.loginUserGetObservable = this.user.pipe(
            tap(() => {
              this.logined.next(true);
              this.loginUserGetObservable = null;
            }),
          );
          this.loginUserGetObservable.subscribe();
        }
      }
    }

    return this.logined;
  }

  /**
   * ログインする
   */
  login(credentials: { email: string; password: string }): Observable<HttpResponse<User>> {
    return this.http
      .post<User>('/api/auth/login', credentials, {
        observe: 'response',
      })
      .pipe(
        concatMap((resp) => {
          this.loginUserRef = resp.body;
          this.storage.set(AUTH_SERVICE_KEY_LOGINED, true);
          return this.http.get<any>('/api/auth/info').pipe(
            map((info) => {
              this.authInfoRef = info;
              this.logined.next(true);
              return resp;
            }),
          );
        }),
      );
  }

  /**
   * ログアウトする
   */
  logout() {
    if (this.hasToken()) {
      this.http.post('/api/auth/logout', {}).subscribe();
      this.storage.remove(AUTH_SERVICE_KEY_LOGINED);
      this.loginUserRef = null;
    }
    this.logined.next(false);
  }

  password(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.put('/api/auth/password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  private get user(): Observable<User> {
    return forkJoin([this.http.get<any>('/api/auth/info'), this.http.get<User>('/api/auth/user')]).pipe(
      map(([info, user]) => {
        this.authInfoRef = info;
        this.loginUserRef = user;
        return user;
      }),
    );
  }

  /**
   * トークンを保持しているか
   */
  hasToken(): boolean {
    return this.storage.get(AUTH_SERVICE_KEY_LOGINED);
  }
}
