import { CompanyDetail, JobListItem } from '@agent-ds/shared/interfaces';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ag-company-detail-header',
  templateUrl: './company-detail-header.component.html',
  styleUrls: ['./company-detail-header.component.scss'],
})
export class CompanyDetailHeaderComponent implements OnInit {
  @Input() company: CompanyDetail;
  @Input() showOnlyMainInfo: boolean;
  @Input() jobs: JobListItem[];

  constructor(public readonly masterApiService: MasterApiService) {}

  ngOnInit(): void {}
}
