import { CounselingContentType, CounselingNavService } from '@agent-ds/counseling/services/counseling-nav.service';
import { JobListComponent } from '@agent-ds/shared/components/job-list/job-list.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { CounselingBatchResponse, JobListItem, Seminar } from '@agent-ds/shared/interfaces';
import { DialogService, ExportApiService, JobApiService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { download, getFileNameFromContentDispositionHeader } from '@agent-ds/shared/util/util';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobRegistrationDialogComponent } from '../job-registration-dialog/job-registration-dialog.component';

@Component({
  selector: 'ag-seminar-job-lister',
  templateUrl: './seminar-job-lister.component.html',
  styleUrls: ['./seminar-job-lister.component.scss'],
})
export class SeminarJobListerComponent implements OnInit, OnDestroy {
  @ViewChild(JobListComponent, { static: false }) jobList: JobListComponent;
  area: { id: number; name: string };
  seminar: Seminar;
  requestObject = {};
  listConfiguration = {
    body: {
      checkbox: true,
      hideCreateButton: true,
      arrow: false,
      hideRefreshButton: true,
      hideCheckboxHeader: true,
      hideActionsHeader: true,
      hideAllSort: true,
      noSorting: true,
    },
    favorites: false,
    persons_in_charge: false,
    recruitYear: false,
    actions: false,
  };
  jobs: JobListItem[] = [];

  private navSubscription: Subscription;
  private jobRegistrationDialogSubscription: Subscription;
  private counselingRefreshSubscription: Subscription;
  private jobRefreshSubscription: Subscription;

  constructor(
    private navService: CounselingNavService,
    private dialog: DialogService,
    private counselingApiService: CounselingApiService,
    private jobApiService: JobApiService,
    private exportApiService: ExportApiService,
  ) {}

  ngOnInit(): void {
    this.navSubscription = this.navService.changePageContent.subscribe(
      (subject: { content: CounselingContentType; area?: { id: number; name: string }; data?: any }) => {
        this.area = subject.area ? subject.area : null;
        this.seminar = subject.data && subject.data.seminar ? subject.data.seminar : null;
        this.getJobs();
      },
    );
    this.counselingRefreshSubscription = this.counselingApiService.refreshEvent.subscribe(() => this.getJobs());
    this.jobRefreshSubscription = this.jobApiService.refreshEvent.subscribe(() => this.getJobs());
  }

  ngOnDestroy(): void {
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
      this.navSubscription = null;
    }
    if (this.jobRegistrationDialogSubscription) {
      this.jobRegistrationDialogSubscription.unsubscribe();
      this.jobRegistrationDialogSubscription = null;
    }
    if (this.counselingRefreshSubscription) {
      this.counselingRefreshSubscription.unsubscribe();
      this.counselingRefreshSubscription = null;
    }
    if (this.jobRefreshSubscription) {
      this.jobRefreshSubscription.unsubscribe();
      this.jobRefreshSubscription = null;
    }
  }

  onDelete(): void {
    if (!this.jobList.checkedItems.length) {
      return;
    }
    let deleteRequest;
    if (this.seminar) {
      deleteRequest = this.counselingApiService.deleteSeminarJobs(this.seminar.id, this.jobList.checkedItems.map((job) => job.id));
    } else if (this.area) {
      deleteRequest = this.counselingApiService.deleteSeminarMasterJobs(this.area.id, this.jobList.checkedItems.map((job) => job.id));
    }
    if (deleteRequest) {
      PopupControlComponent.instance.open({
        content: 'セミナー紹介求人を削除します。よろしいですか？',
        confirmText: 'OK',
        confirmCallback: () =>
          deleteRequest.pipe(
            tap((res: CounselingBatchResponse) => {
              if (res && res.successIds && res.successIds.length) {
                this.counselingApiService.refreshEvent.next();
              }
              if (res && res.errorIds && res.errorIds.length) {
                PopupControlComponent.subInstance.open({
                  content: 'データ削除に失敗しました。\nデータがないか既に削除されている可能性があります。',
                  confirmText: '閉じる',
                  confirmCallback: () => false,
                  title: 'セミナー紹介求人削除',
                  height: '357px',
                  width: '600px',
                });
              }
            }),
          ),
        title: 'セミナー紹介求人削除',
        height: '357px',
        width: '600px',
        cancelText: 'キャンセル',
      });
    }
  }

  onExportJobsPdf(): void {
    if (!this.jobList.checkedItems.length || this.jobList.checkedItems.length > 30) {
      return;
    }
    this.jobApiService.getJobsAsPdf(this.jobList.checkedItems.map((job) => job.id)).subscribe((resp) => {
      download(resp.body, getFileNameFromContentDispositionHeader(resp));
    });
  }

  onExportJobsForSeminar(): void {
    if (!this.jobList.checkedItems.length || this.jobList.checkedItems.length > 30) {
      return;
    }
    this.exportApiService.exportJobsForSeminar(this.seminar.id, this.jobList.checkedItems.map((job) => job.id)).subscribe();
  }

  openSeminarJobRegistrationDialog(): void {
    const data: any = {};
    if (this.seminar) {
      data.seminarId = this.seminar.id;
      data.addRequestFn = this.counselingApiService.addSeminarJob.bind(this.counselingApiService);
    } else if (this.area) {
      data.areaId = this.area.id;
      data.addRequestFn = this.counselingApiService.addSeminarMasterJob.bind(this.counselingApiService);
    }

    const jobRegistrationDialogRef = this.dialog.open(JobRegistrationDialogComponent, { data: data });
    this.jobRegistrationDialogSubscription = jobRegistrationDialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.counselingApiService.refreshEvent.next();
      }
    });
  }

  private getJobs(): void {
    if (this.seminar) {
      this.getSeminarJobs();
    } else if (this.area) {
      this.getSeminarMasterJobs();
    }
  }

  private getSeminarMasterJobs(): void {
    if (!this.area) {
      return;
    }
    this.counselingApiService.getSeminarMasterJobs(this.area.id).subscribe((masterJobs: JobListItem[]) => {
      if (this.jobList && this.jobList.table) {
        this.jobList.table.checkAll(true);
      }
      masterJobs.forEach((job: any) => {
        // Transform so that job list can display these values
        job.frontJobId = job.frontId;
        job.enterprise = { dynamicData: { industry: job.dynamicData.industry } };
      });
      this.jobs = masterJobs;
    });
  }

  private getSeminarJobs(): void {
    if (!this.seminar) {
      return;
    }
    this.counselingApiService.getSeminarJobs(this.seminar.id).subscribe((jobs) => {
      if (this.jobList && this.jobList.table) {
        this.jobList.table.checkAll(true);
      }
      jobs.forEach((job: any) => {
        // Transform so that job list can display these values
        job.frontJobId = job.frontId;
        job.enterprise = { dynamicData: { industry: job.dynamicData.industry } };
      });
      this.jobs = jobs;
    });
  }
}
