import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AuthGuard } from '@agent-ds/shared/guard';
import { loadScript } from '@agent-ds/shared/util/util';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function init() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (AuthGuard.CURRENT_USAGE) {
  const replaceState = window.history.replaceState;
  const pushState = window.history.pushState;
  loadScript('https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js', 'office-js').then((res) =>
    Office.onReady(() => {
      window.history.replaceState = replaceState;
      window.history.pushState = pushState;
      init();
    }),
  );
} else {
  init();
}
