export const TAX_PERCENTAGE = 0.1;
export const ONE_MINUTE = 60000;
export const ONE_DAY = 86400000;
export const NULL_SELECTED_VALUE = '-----';

export const GENDERS = [
  {
    code: 1,
    name: '男',
  },
  {
    code: 2,
    name: '女',
  },
];

export const APPROVAL_STATES = [
  {
    code: 1,
    name: '未承認',
  },
  {
    code: 2,
    name: '承認',
  },
  {
    code: 3,
    name: '否認',
  },
];

export const APPROVAL_CANCEL_STATES = [
  {
    code: 1,
    name: '未申請',
  },
  {
    code: 2,
    name: '申請済',
  },
  {
    code: 3,
    name: '承認',
  },
];

export const GENDER_VALUE_BADGE_CLASSES: { [key: string]: string } = {
  女: 'badge--pink',
  女性: 'badge--pink',
  男: 'badge--purple',
  男性: 'badge--purple',
  default: 'badge--dark',
};

export const RAPACA_VALUE_BADGE_CLASSES: { [key: string]: string } = {
  RA: 'badge--blue-light',
  PA: 'badge--green-light',
  CA: 'badge--orange-light',
  アシ: 'badge--purple-light',
  default: 'badge--dark',
};

export const AREAS: { [key: number]: string } = {
  1: '関東', // Kanto
  2: '大阪', // Osaka
  3: '名古屋', // Nagoya
  4: '京都', // Kyoto
  5: '神戸', // Kobe
  6: '福岡', // Fukuoka
};

export const DAYS: { [key: number]: string } = {
  0: '日', // Sunday
  1: '月', // Monday
  2: '火', // Tuesday
  3: '水', // Wednesday
  4: '木', // Thursday
  5: '金', // Friday
  6: '土', // Saturday
};

export const THANKS_MAIL_TYPES: { [key: number]: string } = {
  1: 'アンケ+マイペ',
  2: 'マイペのみ',
  3: '御礼のみ',
  4: '送信不要',
};

export const APPLICATION_TYPES = {
  1: '応募', // Apply
  2: 'CA NG', // CA NG
  3: '辞退', // Decline
};
