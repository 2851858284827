import { EnterpriseDepartmentUserType, Team, User, UserReference } from '@agent-ds/shared/interfaces';
import { MappedUser, RaPaCaUserIdHolder } from '@agent-ds/shared/interfaces/mapped-user';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { isArray } from 'util';
import { MasterApiService } from '../master-api.service';
import { UserApiService } from '../user-api.service';

@Injectable()
export class UserTeamInjectorProvider {
  constructor(private masterApiService: MasterApiService, private userApiService: UserApiService) {}

  enterpriseDepartmentUserType = EnterpriseDepartmentUserType;

  private createMappedUserObject(area: string, userId: number, users: User[], teams: Team[]): MappedUser {
    const user = users.find((u) => u.id === userId);

    if (user) {
      const team = teams.find((t) => t.id === user.teamId);
      return {
        area: area,
        id: userId,
        name: user.name,
        teamId: user.teamId,
        teamName: team ? team.name : undefined,
      };
    }

    return {
      area: area,
      id: userId,
    };
  }

  public getRequestAndProcessList<T, V extends RaPaCaUserIdHolder>(
    apiResponseObservable: Observable<T>,
    listElementsAccessor: string,
  ): Observable<T> {
    const teams = this.masterApiService.getTeams();
    const users = this.userApiService.getAll();

    // wait until all Observables finish
    return forkJoin([teams, users, apiResponseObservable]).pipe(
      map(([teamList, userList, response]) => {
        // append entries in .users entries
        if (isArray(response[listElementsAccessor])) {
          response[listElementsAccessor].forEach((s: V) => {
            s.users = [];

            if (s.enterpriseDepartmentUsers) {
              s.enterpriseDepartmentUsers.forEach((user: UserReference) => {
                s.users.push(this.createMappedUserObject(this.enterpriseDepartmentUserType[user.type], user.userId, userList, teamList));
              });
            } else {
              s.users.push(this.createMappedUserObject('PA', s.paUserId, userList, teamList));
              s.users.push(this.createMappedUserObject('RA', s.raUserId, userList, teamList));
              s.users.push(this.createMappedUserObject('CA', s.caUserId, userList, teamList));
            }
          });
        } else {
          console.warn(`Warning!: Expected ${listElementsAccessor} would point to an array of result items in `, response);
        }

        return response;
      }),
    );
  }

  public getUserInfoById(id: number, area?: string): Observable<MappedUser> {
    if (id) {
      const teams = this.masterApiService.getTeams();
      const users = this.userApiService.getAll();

      return forkJoin([teams, users]).pipe(map((results) => this.createMappedUserObject(area, id, results[1], results[0])));
    } else {
      return of(null);
    }
  }

  public getUserTeamNameById(id: number): Observable<string> {
    if (id == null) {
      return of('');
    }
    return this.getUserTeamInfoById(id).pipe(
      map((info) => (info ? (info.name && info.teamName ? `${info.name || ''} ${info.teamName || ''}` : info.name || '') : '')),
    );
  }

  public getUserTeamInfoById(id: number): Observable<{ name: string; teamName?: string }> {
    if (id == null) {
      return of(null);
    }
    return this.userApiService.getAll().pipe(
      mergeMap((users) => {
        const user = users.find((u) => u.id === id);
        if (user) {
          return this.masterApiService.getTeams().pipe(
            map((teams) => {
              const team = teams.find((t) => t.id === user.teamId);
              if (team) {
                return { name: user.name, teamName: team.name };
              } else {
                return { name: user.name };
              }
            }),
          );
        }
        return of(null);
      }),
    );
  }
}
