import { CounselingPageComponent } from '../pages/counseling-page/counseling-page.component';
import { JobRegistrationDialogComponent } from './job-registration-dialog/job-registration-dialog.component';
import { NewSeminarRegistrationDialogComponent } from './new-seminar-registration-dialog/new-seminar-registration-dialog.component';
import { SeminarAttendeeListComponent } from './seminar-attendee-list/seminar-attendee-list.component';
import { SeminarJobApplicationComponent } from './seminar-job-application/seminar-job-application.component';
import { SeminarJobListerComponent } from './seminar-job-lister/seminar-job-lister.component';
import { SeminarListerComponent } from './seminar-lister/seminar-lister.component';

export const COUNSELING_COMPONENTS = [
  CounselingPageComponent,
  SeminarAttendeeListComponent,
  SeminarListerComponent,
  SeminarJobListerComponent,
  SeminarJobApplicationComponent,
];
export const COUNSELING_DIALOG_COMPONENTS = [JobRegistrationDialogComponent, NewSeminarRegistrationDialogComponent];
