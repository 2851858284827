import { SeminarJobApplicationComponent } from '@agent-ds/counseling/components/seminar-job-application/seminar-job-application.component';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS } from '@agent-ds/shared/constants';
import { SeminarStudentSeminarResponse, StudentDetail } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { DatePipe } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SeminarReservationDialogComponent } from '../../../../components/seminar-reservation-dialog/seminar-reservation-dialog.component';
import { STUDENT_SEMINAR_LIST_TABLE_CONFIG } from './student-seminar-list-table-config';

@Component({
  selector: 'ag-student-seminars-tab',
  templateUrl: './student-seminars-tab.component.html',
  styleUrls: ['./student-seminars-tab.component.scss'],
  providers: [{ provide: Tab, useExisting: forwardRef(() => StudentSeminarsTabComponent) }],
})
export class StudentSeminarsTabComponent extends Tab implements OnInit, OnChanges, OnDestroy {
  @ViewChild('actions', { static: true }) buttonsTemplate: TemplateRef<any>;
  @Input() student: StudentDetail;
  tableConfig: TableConfig;
  seminars: (SeminarStudentSeminarResponse & { isSeminarAtPastDate?: boolean; areaName?: string })[];
  private dialogSubscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(
    private dialog: DialogService,
    private datePipe: DatePipe,
    private counselingApiService: CounselingApiService,
    private studentApiService: StudentApiService,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.tableConfig = STUDENT_SEMINAR_LIST_TABLE_CONFIG(
      this.buttonsTemplate,
      (date) => this.datePipe.transform(date, 'yyyy/MM/dd (E)'),
      (date) => this.datePipe.transform(date, 'HH:mm〜'),
    );
    this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(() => this.getSeminars());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.student != null && changes['student'].currentValue !== changes['student'].previousValue) {
      this.getSeminars();
    }
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
      this.dialogSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  onReserveSeminar(): void {
    const dialogRef = this.dialog.open(SeminarReservationDialogComponent, { data: { studentId: this.student.id } });
    this.dialogSubscription = dialogRef.afterClosed.subscribe((res) => {
      if (res) {
        this.counselingApiService.refreshEvent.next();
        PopupControlComponent.subInstance.open({
          content: 'セミナー予約が完了しました。',
          confirmText: '閉じる',
          confirmCallback: () => void 0,
          title: 'セミナー予約',
          height: '357px',
          width: '600px',
        });
      }
    });
  }

  onDeleteSeminar(seminar: SeminarStudentSeminarResponse & { isSeminarAtPastDate?: boolean }): void {
    if (seminar.isSeminarAtPastDate || seminar.isApplied) {
      return;
    }
    PopupControlComponent.instance.open({
      content: 'セミナー予約を削除します。よろしいですか？',
      confirmText: 'OK',
      confirmCallback: () =>
        this.counselingApiService.deleteSeminarForStudent(seminar.id, this.student.id).pipe(
          tap(() => {
            const index = this.seminars.findIndex((sem) => sem.id === seminar.id);
            this.seminars.splice(index, 1);
          }),
        ),
      title: 'セミナー予約削除',
      height: '357px',
      width: '600px',
      cancelText: 'キャンセル',
    });
  }

  onSeminarJobOffers(seminar: SeminarStudentSeminarResponse & { isSeminarAtPastDate?: boolean }): void {
    SeminarJobApplicationComponent.instance.referenceId = this.student.id;
    SeminarJobApplicationComponent.instance.data = {
      seminar: seminar,
      studentName: this.student.dynamicData.lastName + this.student.dynamicData.firstName,
    };
    SeminarJobApplicationComponent.instance.open();
  }

  private getSeminars(): void {
    if (this.student == null) {
      return;
    }
    this.seminars = [];
    this.counselingApiService.getSeminarsForStudent(this.student.id).subscribe(
      (response: SeminarStudentSeminarResponse[]) => {
        this.seminars = [];
        this.seminars = response
          .map((res) => ({
            ...res,
            areaName: AREAS[res.area] || '',
            isSeminarAtPastDate: new Date(res.seminarAt) < new Date(),
          }))
          .reverse();
      },
      (error) => {
        console.error('getSeminarsForStudent error', error);

        if (!error.status) {
          // ネットワークに繋がらなかったときはエラーを通知する
          this.showErrorDialog();
          this.studentApiService.notifyConnectionErrorEvent.emit();
        } else {
          // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
        }
      },
    );
  }

  private showErrorDialog() {
    const dialogConfig: ConfirmDialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
      'セミナーの取得中に通信エラーが発生しました。',
      '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
    ]);
    this.dialogService.open(ConfirmDialogComponent, dialogConfig);
  }
}
