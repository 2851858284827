import { SortOrder, TableConfig } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { formatNumber, formatPrice } from '@agent-ds/shared/util/util';
import { TemplateRef } from '@angular/core';

export const COMPANY_LIST_TABLE_CONFIG: (
  companyNameTemplate: TemplateRef<any>,
  agStatusTemplate: TemplateRef<any>,
  drStatusTemplate: TemplateRef<any>,
  representativeTemplate: TemplateRef<any>,
  actionsTemplate: TemplateRef<any>,
  salesAction?: (event: MouseEvent, data: any) => void,
) => TableConfig = (companyNameTemplate, agStatusTemplate, drStatusTemplate, representativeTemplate, actionsTemplate, salesAction) => ({
  head: {
    sticky: true,
    config: [
      {
        columns: [
          {
            fields: [
              {
                name: 'enterpriseId',
                title: '企業ID',
                formatter: (data) => data.frontId,
                style: {
                  color: '#9f9f9f',
                  'font-size': '12px',
                  'line-height': '12px',
                },
              },
              {
                name: 'enterpriseName',
                title: '企業名',
                defaultSort: SortOrder.DESCENDING,
                cellTemplate: companyNameTemplate,
              },
            ],
            style: {
              width: '50px',
              'padding-left': '0',
              'margin-left': '20px',
              'justify-content': 'flex-start',
            },
          },
          {
            fields: [
              {
                name: 'enterpriseNo',
                title: '企業No.',
                formatter: (data) => (data.dynamicData || { enterpriseNo: '' }).enterpriseNo,
                style: {
                  color: '#9f9f9f',
                  'font-size': '12px',
                  'line-height': '12px',
                },
              },
            ],
            style: {
              'margin-left': '20px',
              width: '172px',
            },
            bodyStyle: {
              'justify-content': 'flex-start',
            },
          },
          {
            fields: [
              {
                name: 'agClientStatus',
                title: 'AG顧客ステータス',
                cellTemplate: agStatusTemplate,
              },
              {
                name: 'drClientStatus',
                title: 'DR顧客ステータス',
                cellTemplate: drStatusTemplate,
              },
            ],
            style: {
              'margin-left': '20px',
              width: '140px',
            },
          },
          {
            fields: [
              {
                name: 'rininKRank',
                title: '離任Kランク',
                formatter: (data) => data.dynamicData.rininKRank,
              },
            ],
            style: {
              'margin-left': '20px',
              width: '69px',
            },
          },
          {
            fields: [
              {
                name: 'hasSales',
                formatter: (data) => (data.salesAmount > 0 ? 'あり' : 'なし'),
                title: '成約\n実績',
              },
            ],
            style: {
              'margin-left': '20px',
              width: '40px',
            },
          },
          {
            fields: [
              {
                name: 'salesAmount',
                formatter: (data) => formatPrice(data.salesAmount),
                title: '成約金額合計',
                clickAction: (event, data) => salesAction(event, data),
                style: {
                  cursor: 'pointer',
                },
              },
            ],
            style: {
              'margin-left': '20px',
              width: '94px',
            },
            bodyStyle: {
              'font-family': '"Meiryo UI Bold", sans-serif',
              'text-align': 'center',
              color: '#007BFF',
            },
          },
          {
            fields: [
              {
                name: 'company_representative_information',
                title: '企業担当者情報',
                sortable: false,
                cellTemplate: representativeTemplate,
              },
            ],
            style: {
              flex: 1,
              'margin-left': '20px',
              'min-width': '1px',
            },
          },
          {
            fields: [
              {
                name: 'action',
                title: 'アクション',
                cellTemplate: actionsTemplate,
                sortable: false,
              },
            ],
            style: {
              'margin-left': '20px',
              width: '191px',
            },
            bodyStyle: {
              'padding-right': '20px',
            },
          },
        ],
      },
    ],
  },
  body: {
    checkbox: false,
    arrow: true,
  },
});
